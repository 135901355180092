import { useTranslation } from 'react-i18next'
import { useDialog } from 'boards-web-ui'
import EditText from '../components/EditText'
import { IUseSaveNode } from '../models/IUseEditNode'
import { Node } from '../../../models'

const useEditText: IUseSaveNode = (save) => {
  const { open } = useDialog()
  const { t } = useTranslation()

  const editText = (node: Node) => {
    open(
      <EditText
        initialText={node.text || ''}
        initialTitle={node.title || ''}
        titlePlaceholder={t('add_an_update_title_optional')}
        textPlaceholder={t('node_text_placeholder')}
        onSave={(n) => save(node.id, n)}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }
  return editText
}

export default useEditText
