import React, { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { ButtonLink, ShareIcon, Tooltip, TooltipContent } from 'boards-web-ui'
import useCopyShortcuts from '@hooks/useCopyShortcuts'

import useBulk from '@hooks/bulk/useBulk'
import { TooltipPositions } from '@ui/models/tooltip'
import { IconWrapper } from '@ui/components/IconWrapper'
import useBoard from '../../../../hooks/useBoard'
import useShortcut from '../../../../hooks/useShortcut'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'

import { ReactComponent as SuccessIcon } from '../../../../icons/ActionSuccess.svg'

import styles from './AddShortcutIdButton.module.css'

type Props = PropsWithChildren<{
  alignTooltipLeft?: boolean
}>
const AddShortcutIdButton: React.FC<Props> = ({ alignTooltipLeft }) => {
  const { folder } = useBoard()
  const { sharedFolderFeatureFlag } = useFeatureFlags()
  const { getShortcut } = useShortcut()
  const { copyFolderShortcut } = useCopyShortcuts()
  const shortcut = getShortcut(folder)

  const { allowShareFolderAllowed } = useBoard()
  const { isSelectionModeEnabled } = useBulk()

  const isDisabled = isSelectionModeEnabled

  if (!sharedFolderFeatureFlag || !shortcut || !allowShareFolderAllowed) {
    return null
  }

  const shareBtnClassNames = (
    !isDisabled ? [styles.ShareBtn] : [styles.ShareBtn, styles.Disabled]
  ).join(' ')

  return (
    <Tooltip
      disabled={isDisabled}
      feedback
      position={
        alignTooltipLeft
          ? TooltipPositions.BOTTOM_RIGHT
          : TooltipPositions.BOTTOM
      }
      data={
        <TooltipContent icon={<SuccessIcon />} feedback>
          <Trans i18nKey={'toast_link_copied_to_clipboard'} />
        </TooltipContent>
      }
    >
      <ButtonLink
        className={shareBtnClassNames}
        onClick={() => copyFolderShortcut(folder, false)}
        startIcon={
          <IconWrapper>
            <ShareIcon />
          </IconWrapper>
        }
      >
        <Trans i18nKey={'action_share'} />
      </ButtonLink>
    </Tooltip>
  )
}

export default AddShortcutIdButton
