import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  limit,
  onSnapshot,
  collectionGroup,
  QuerySnapshot,
  DocumentSnapshot,
} from 'firebase/firestore'
import db from '../storage'

type ISnapshotDocument = (snapshot: DocumentSnapshot) => void
type ISnapshot = (snapshot: QuerySnapshot) => void

export const profileListener = (uid: string, onResult: ISnapshotDocument) => {
  const docRef = doc(db, `users/${uid}`)
  return onSnapshot(docRef, onResult)
}

export const userContentSettingsListener = (
  uid: string,
  onResult: ISnapshotDocument,
) => {
  const docRef = doc(db, `users/${uid}/settings/content`)
  return onSnapshot(docRef, onResult)
}

export const ownBoardsListener = (uid: string, snapshot: ISnapshot) => {
  const q = collection(db, `users/${uid}/content`)
  return onSnapshot(q, snapshot)
}

export const receivedBoardsListener = (email: string, snapshot: ISnapshot) => {
  const itemsRef = collectionGroup(db, 'permissions')
  const itemsQuery = query(itemsRef, where('recipient', '==', email))
  return onSnapshot(itemsQuery, snapshot)
}

export const boardListener = (
  uid: string,
  bid: string,
  onResult: ISnapshotDocument,
) => {
  const docRef = doc(db, `users/${uid}/content/${bid}`)
  return onSnapshot(docRef, onResult)
}

export const clientConfigListener = (onResult: ISnapshot) => {
  const docRef = collection(db, `client-configuration`)
  return onSnapshot(docRef, onResult)
}

export const boardUpdatesMessagesListener = (
  uid: string,
  bid: string,
  snapshot: ISnapshot,
) => {
  const q = collection(db, `/users/${uid}/content/${bid}/messages`)
  return onSnapshot(q, snapshot)
}

export const boardUnreadMessagesListener = (
  uid: string,
  bid: string,
  onResult: ISnapshotDocument,
) => {
  const docRef = doc(
    db,
    `/users/${uid}/settings/content/unread-messages/${bid}`,
  )
  return onSnapshot(docRef, onResult)
}

export const boardFoldersListener = (
  uid: string,
  bid: string,
  snapshot: ISnapshot,
) => {
  const q = collection(db, `users/${uid}/content/${bid}/folders`)
  return onSnapshot(q, snapshot)
}

export const getFolderDoc = (uid: string, bid: string, folderId: string) => {
  const q = doc(db, `users/${uid}/content/${bid}/folders/${folderId}`)
  return getDoc(q)
}

export const getPermissionsDocs = (
  uid: string,
  bid: string,
  snapshot: ISnapshot,
) => {
  const docRef = collection(db, `/users/${uid}/content/${bid}/permissions`)
  const q = query(docRef, limit(2))

  getDocs(q).then((res) => snapshot(res))
}

export const boardPermissionsListener = (
  uid: string,
  bid: string,
  snapshot: ISnapshot,
) => {
  const docRef = collection(db, `/users/${uid}/content/${bid}/permissions`)
  return onSnapshot(docRef, snapshot)
}

export const inviteLinkListener = (
  uid: string,
  bid: string,
  onResult: ISnapshotDocument,
) => {
  const docRef = doc(db, `/users/${uid}/content/${bid}/api/permissions`)
  return onSnapshot(docRef, onResult)
}

export const receivedBoardsPermissionsListener = (
  uid: string,
  snapshot: ISnapshot,
) => {
  const itemsRef = collectionGroup(db, 'permissions')
  const itemsQuery = query(itemsRef, where('sharer', '==', uid))
  return onSnapshot(itemsQuery, snapshot)
}
