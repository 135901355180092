import React from 'react'

import useBoardActions from '@hooks/useBoardActions'

import UndoRedo from './UndoRedo'
import AddContentButton from './AddContentButton'
import ShareShortcutButton from './ShareShortcutButton'

import styles from './FolderTools.module.css'

const FolderTools: React.FC = () => {
  const { canUndoAndRedo, canShare, canAddContent } = useBoardActions()

  return (
    <div className={styles.Root}>
      {canUndoAndRedo && <UndoRedo />}

      {canShare && <ShareShortcutButton />}

      {canAddContent && <AddContentButton />}
    </div>
  )
}

export default FolderTools
