import { configurationObjectFromEnv } from '../utils/configurationObjectFromEnv'
import { ENV_KEYS } from '../utils/envKeys'

export interface Paths {
  ContentFunctions: string
  GeneralFunctions: string
  PdfStorage: string
  VideoStorage: string
}

export default configurationObjectFromEnv<Paths>({
  ContentFunctions: ENV_KEYS.FIREBASE_CONTENT_FUNCTIONS_URL,
  GeneralFunctions: ENV_KEYS.FIREBASE_GENERAL_FUNCTIONS_URL,
  PdfStorage: ENV_KEYS.PDF_BUCKET,
  VideoStorage: ENV_KEYS.VIDEO_BUCKET,
})
