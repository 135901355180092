import React, { DragEventHandler, FC, PropsWithChildren } from 'react'

import clsx from 'clsx'
import styles from './BoardMenu.module.css'

type Props = PropsWithChildren<{
  dataPosition: number
  draggedTo: number | undefined
  onDrop: () => void
  onDragOver?: DragEventHandler<HTMLLIElement>
  onDragStart?: DragEventHandler<HTMLLIElement>
  onDragLeave?: () => void
}>

const BoardMenu: FC<Props> = ({
  dataPosition,
  draggedTo,
  onDrop,
  onDragOver,
  onDragStart,
  onDragLeave,
  children,
}) => (
  <li
    className={clsx(
      styles.Root,
      draggedTo === dataPosition && styles.DraggedTo,
    )}
    data-position={dataPosition}
    onDrop={onDrop}
    onDragOver={onDragOver}
    onDragStart={onDragStart}
    onDragLeave={onDragLeave}
    draggable
  >
    {children}
  </li>
)

export default React.memo(BoardMenu)
