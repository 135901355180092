import Seedrandom from 'seedrandom'

export function limitNumberWithinRange(num, min, max) {
  const MIN = min || 1
  const MAX = max || 20
  const parsed = parseInt(num, 10)
  return Math.min(Math.max(parsed, MIN), MAX)
}

export const seededRandomInteger = (text, min, max) => {
  const rng = Seedrandom(text)
  return Math.floor(rng() * (max - min + 1)) + min
}

export function valBetween(v, min, max) {
  return Math.min(Math.max(v, min), max)
}
