import React, { DragEvent, FC, PropsWithChildren, useState } from 'react'
import { isFirefox } from '@helpers/browser'

import useScrollArea from '../../../../hooks/useScrollArea'
import { ScrollAreaContainer } from '../../../../ui/models/ScrollArea'
import { ILocation } from '../models/nodes'

import styles from './FolderListItem.module.css'

let PageX = 0
let PageY = 0

if (isFirefox()) {
  // Fix missing clientX and clientY on Firefox
  document.addEventListener('dragover', (e) => {
    PageX = e.clientX || e.pageX
    PageY = e.clientY || e.pageY
  })
}

interface ReactProps {
  x: number
  y: number
  width: number
  height: number
}
type Props = PropsWithChildren<{
  index: number
  rect: ReactProps
  onDrag: ((startIndex: number, dropLocation: ILocation) => void) | null
  onDragEnd: ((startIndex: number) => void) | null
}>
const FolderListItem: FC<Props> = ({
  index,
  rect,
  onDrag,
  onDragEnd,
  children,
}) => {
  const [startLocation, setStartLocation] = useState<ILocation>(rect)
  const [startIndex, setStartIndex] = useState<number>(index)
  const { getContentScrollTop } = useScrollArea()

  const nodeStyle = {
    left: `${rect.x}px`,
    top: `${rect.y}px`,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
  }

  const getEventLocation = (e: DragEvent<HTMLDivElement>) => ({
    x: e.pageX || PageX,
    y: (e.pageY || PageY) + getContentScrollTop(ScrollAreaContainer.PAGE),
  })

  const handleOnDragStart = (e: DragEvent<HTMLDivElement>) => {
    setStartIndex(index)
    setStartLocation(getEventLocation(e))
  }

  const handleOnDrag = (e: DragEvent<HTMLDivElement>) => {
    const location = getEventLocation(e)
    const dropLocation = {
      x: location.x - (startLocation?.x || 0),
      y: location.y - (startLocation?.y || 0),
    }

    if (onDrag) onDrag(startIndex, dropLocation)
  }

  const handleOnDragEnd = () => {
    if (onDragEnd) {
      onDragEnd(startIndex)
    }
  }

  if (!onDrag) {
    return (
      <div className={styles.Root} style={nodeStyle}>
        {children}
      </div>
    )
  }

  return (
    <div
      style={nodeStyle}
      className={styles.Root}
      onDrag={handleOnDrag}
      onDragEnd={handleOnDragEnd}
      onDragStart={handleOnDragStart}
      onDragOver={(e) => e.preventDefault()}
      draggable
    >
      {children}
    </div>
  )
}

export default React.memo(FolderListItem)
