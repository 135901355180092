export const enum MESSAGE_TYPE {
  SIGNED_IN_SUCCESS = 'SIGNED_IN_SUCCESS',
  SIGNED_OUT = 'SIGNED_OUT',
  AUTH_STATE_CHANGE = 'AUTH_STATE_CHANGE',
  BOARDS_CHROME_EXTENSION_AUTH_STATE_SYNC = 'BOARDS_CHROME_EXTENSION_AUTH_STATE_SYNC',
}

export type ChromeExtensionApiConfig = {
  id: string
}
