import { FC, useState } from 'react'
import { Trans } from 'react-i18next'
import clsx from 'clsx'
import { ImageUnavailableIcon } from 'boards-web-ui'

import { TemplateModel } from '@features/templateGallery/models/Template'
import { IconWrapper } from '@ui/components/IconWrapper'

import styles from './Template.module.css'

interface Props {
  template: TemplateModel
  onClick: (id: string) => void
}
const Template: FC<Props> = ({ template, onClick }) => {
  const [imageError, setImageError] = useState(false)

  if (!template.thumbnail || imageError) {
    return (
      <div
        className={clsx(styles.Image, styles.Placeholder)}
        onClick={() => onClick(template.id)}
      >
        <IconWrapper size={41}>
          <ImageUnavailableIcon />
        </IconWrapper>

        <div className={styles.PlaceholderMessage}>
          <Trans i18nKey={'page_template_gallery_error'} />
        </div>
      </div>
    )
  }

  return (
    <img
      key={template.thumbnail}
      className={styles.Image}
      src={template.thumbnail}
      alt={template.data.title}
      onClick={() => onClick(template.id)}
      onError={() => setImageError(true)}
    />
  )
}

export default Template
