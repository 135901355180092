import { useDateDiff } from '../../hooks/useDateDiff'

import styles from './DateDiff.module.css'
import { useUpdatesTranslations } from '../../app/features/updates/hooks/useUpdatesTranslations'

type Props = {
  date: number
  className?: string
}
export const DateDiff: React.FC<Props> = ({ date, className, ...rest }) => {
  const dateDiff = useDateDiff(date)
  const { timeDiffTranslation } = useUpdatesTranslations()

  return (
    <span className={[styles.Date, className].join(' ')} {...rest}>
      {timeDiffTranslation(dateDiff.value, dateDiff.label)}
    </span>
  )
}
