import React, { FC } from 'react'
import { PremiumCrownIcon } from 'boards-web-ui'

import { IconWrapper } from '@ui/components/IconWrapper'
import clsx from 'clsx'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useBoardMenu from '../hooks/useBoardMenu'

import BoardActivateButton from './BoardActivateButton'
import MenuItem from './MenuItem'

import styles from './BoardMenuItem.module.css'

interface Props {
  inactive?: boolean
  isFreeUserMenu?: boolean
  boardId: string
  isBoardSelected: boolean
  selectedFolderId: string | undefined
  initialStateCheck?: boolean
  isPageBoard?: boolean
}

const BoardMenuItem: FC<Props> = ({
  inactive,
  boardId,
  isBoardSelected,
  selectedFolderId,
  initialStateCheck,
  isPageBoard = false,
  isFreeUserMenu,
}) => {
  const { isFreePlanUser } = useReverseTrialContext()
  const { menu } = useBoardMenu({
    boardId,
    isBoardSelected,
    selectedFolderId,
    isFreeUserMenu,
  })

  if (
    menu === undefined ||
    (!menu.isPageBoard && isPageBoard) ||
    (menu.isPageBoard && !isPageBoard)
  ) {
    return null
  }

  const shouldRenderWithOpacity =
    (!menu.isActive && !menu.isPageBoard) ||
    (menu.isPageBoard && isFreePlanUser)

  return (
    <MenuItem
      level={1}
      menu={menu}
      inactive={inactive}
      initialStateCheck={initialStateCheck}
      className={clsx(shouldRenderWithOpacity && styles.Opacity)}
      isRoot
    >
      {!menu.isActive && !menu.isPageBoard ? (
        <div className={clsx(isFreeUserMenu && styles.RightAlignedButton)}>
          <BoardActivateButton
            onActivate={menu.onActivate}
            icon={
              menu.limitReached ? (
                <IconWrapper>
                  <PremiumCrownIcon />
                </IconWrapper>
              ) : undefined
            }
          />
        </div>
      ) : null}
    </MenuItem>
  )
}

export default React.memo(BoardMenuItem)
