import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import DoneButton from '../../../../ui/components/DoneButton'

import { useEditForms } from '../hooks'

const EditFormsFooterControls: FC = () => {
  const { action } = useEditForms()

  return (
    <DoneButton disabled={!action.isValid} onClick={action?.onNext}>
      <Trans i18nKey={action?.actionName} />
    </DoneButton>
  )
}

export default React.memo(EditFormsFooterControls)
