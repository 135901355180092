import React, { FC, ReactNode } from 'react'
import styles from './Title.module.css'

export const Title: FC<{ title: ReactNode; subTitle?: ReactNode }> = ({
  title,
  subTitle,
}) => (
  <div className={styles.TitleContainer}>
    <h3 className={styles.Title}>{title}</h3>

    {subTitle ? <h4 className={styles.SubTitle}>{subTitle}</h4> : null}
  </div>
)
