/* eslint no-param-reassign: 0 */
/* eslint no-unused-expressions: 0 */
import { useEffect, useState } from 'react'
import styles from './TextAreaResizable.module.css'

const minRows = 1
const maxRows = 5

const TextAreaResizable = ({ size, ...props }) => {
  const [rows, setRows] = useState(1)

  const textareaLineHeight = size

  useEffect(() => {
    props.inputRef.current.rows = minRows // reset number of rows in textarea

    const currentRows =
      props.value?.length > 10
        ? ~~(props.inputRef.current.scrollHeight / textareaLineHeight)
        : minRows

    if (currentRows === rows) {
      props.inputRef.current.rows = currentRows
    }

    if (currentRows >= maxRows) {
      props.inputRef.current.rows = maxRows
      props.inputRef.current.scrollTop = props.inputRef.current.scrollHeight
    }

    setRows(currentRows < maxRows ? currentRows : maxRows)
    // eslint-disable-next-line
  }, [rows, props.inputRef, textareaLineHeight])

  const handleChange = (event) => {
    const previousRows = event.target.rows
    event.target.rows = minRows // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      event.target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows
      event.target.scrollTop = event.target.scrollHeight
    }

    props.onChange && props.onChange(event)
    setRows(currentRows < maxRows ? currentRows : maxRows)
  }

  return (
    <textarea
      ref={props.inputRef}
      rows={rows}
      value={props.value}
      placeholder={props.placeholder}
      className={`${props.className} ${styles.Root}`}
      onChange={handleChange}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={{ fontSize: `${size}px`, lineHeight: `${size + 4}px` }}
    />
  )
}

export default TextAreaResizable
