import { NODE_LINK_TYPES } from 'boards-web-ui'

import { ICountry } from '../../../models'
import urlHelper from '../../../../helpers/url'

export const BUTTONS_PREFIX: { [key in NODE_LINK_TYPES]: string } = {
  [NODE_LINK_TYPES.SMS]: 'sms:+',
  [NODE_LINK_TYPES.PHONE]: 'tel:+',
  [NODE_LINK_TYPES.TELEGRAM]: 'https://t.me/+',
  [NODE_LINK_TYPES.WHATS_APP]: 'https://wa.me/+',

  [NODE_LINK_TYPES.MAIL]: 'mailto:',
  [NODE_LINK_TYPES.FACEBOOK]: 'https://m.me/',

  [NODE_LINK_TYPES.ZOOM]: '',
  [NODE_LINK_TYPES.CASH]: '',
  [NODE_LINK_TYPES.VENMO]: '',
  [NODE_LINK_TYPES.PAYPAL]: '',
  [NODE_LINK_TYPES.REGULAR]: '',
  [NODE_LINK_TYPES.CALENDLY]: '',
}

export const buildText = (
  type: NODE_LINK_TYPES,
  text: string,
  country?: ICountry,
  message?: string,
) => {
  switch (type) {
    case NODE_LINK_TYPES.WHATS_APP:
      return message
        ? encodeURI(
            `${BUTTONS_PREFIX[type]}${country?.dialCode}${text}?text=${message}`,
          )
        : `${BUTTONS_PREFIX[type]}${country?.dialCode}${text}`
    case NODE_LINK_TYPES.TELEGRAM:
      return `${BUTTONS_PREFIX[type]}${country?.dialCode}${text}`
    case NODE_LINK_TYPES.SMS:
      return `${BUTTONS_PREFIX[type]}${country?.dialCode}${text}`
    case NODE_LINK_TYPES.PHONE:
      return `${BUTTONS_PREFIX[type]}${country?.dialCode}${text}`
    case NODE_LINK_TYPES.MAIL:
      return `${BUTTONS_PREFIX[type]}${text.replace(BUTTONS_PREFIX[type], '')}`

    case NODE_LINK_TYPES.FACEBOOK:
      return `${BUTTONS_PREFIX[type]}${text}`

    default:
      return text
  }
}

export const getTextFromUrl = (
  text: string,
  type: NODE_LINK_TYPES,
  dialingCode?: string,
) => {
  if (text && type === NODE_LINK_TYPES.FACEBOOK) {
    return text.replace(BUTTONS_PREFIX[type], '').replace(dialingCode || '', '')
  }
  if (text && type === NODE_LINK_TYPES.MAIL) {
    return text.replace(BUTTONS_PREFIX[type], '')
  }

  return text
}

export const isLink = (type?: NODE_LINK_TYPES) =>
  type &&
  [
    NODE_LINK_TYPES.REGULAR,
    NODE_LINK_TYPES.ZOOM,
    NODE_LINK_TYPES.CALENDLY,
    NODE_LINK_TYPES.PAYPAL,
    NODE_LINK_TYPES.VENMO,
    NODE_LINK_TYPES.CASH,
  ].includes(type)

export const isPhoneUrl = (type?: NODE_LINK_TYPES) =>
  type && [NODE_LINK_TYPES.WHATS_APP].includes(type)

export const isPhoneInput = (type?: NODE_LINK_TYPES) =>
  type &&
  [
    NODE_LINK_TYPES.SMS,
    NODE_LINK_TYPES.WHATS_APP,
    NODE_LINK_TYPES.TELEGRAM,
    NODE_LINK_TYPES.PHONE,
  ].includes(type)

export const getNumberFromText = (
  text: string,
  type: NODE_LINK_TYPES,
  dialingCode?: string,
): string => {
  if (text && type === NODE_LINK_TYPES.FACEBOOK) {
    return text.split('/').pop() || ''
  }

  if (text && dialingCode && isPhoneUrl(type)) {
    const { pathname } = urlHelper.parseUrl(text)
    const index = pathname.indexOf(dialingCode)

    if (index > 0) {
      return pathname.slice(index + dialingCode.length) || ''
    }

    return pathname.split('/').pop() || ''
  }

  if (text && dialingCode && isPhoneInput(type)) {
    const index = text.indexOf(dialingCode)

    if (index > 0) {
      return text.slice(index + dialingCode.length)
    }

    return text.split('/').pop() || ''
  }

  return text || ''
}
