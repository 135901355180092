import { DocumentSnapshot } from 'firebase/firestore'
import { commonActions } from '../../../actions'
import { AppDispatch } from '../../../app/store'
import { transformLinkInvite } from '../transformers/InviteLinkTransformer'

export const InviteLinkSnapshot =
  (uid: string, bid: string, dispatch: AppDispatch) =>
  (doc: DocumentSnapshot) => {
    const boardId = `${uid}-${bid}`
    const invite = transformLinkInvite(doc)

    if (invite) {
      dispatch(commonActions.setInviteLink(boardId, invite))
    }
  }
