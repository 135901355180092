import React from 'react'
import { SeenByIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import TextButton from '@features/phoneVerification/components/TextButton'
import useBoard from '../../../../hooks/useBoard'
import useToolbarEvents from '../../analytics/useToolbarEvents'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'

import styles from './ViewModeIndicator.module.css'

type Props = {
  className?: string
}

const ViewModeIndicator: React.FC<Props> = ({ className }) => {
  const { boardId } = useBoard()
  const { viewModeBadgeClick } = useToolbarEvents()
  const { viewMode, canEditViewModeTooltip } = useToolbarTranslations()

  return (
    <Tooltip
      as="div"
      className={className}
      data={
        <TooltipContent className={styles.Offset}>
          {canEditViewModeTooltip()}
        </TooltipContent>
      }
    >
      <TextButton
        className={styles.ViewOnlyButton}
        disabled
        onClick={() => viewModeBadgeClick(boardId)}
      >
        <IconWrapper className={styles.Icon} size={16}>
          <SeenByIcon />
        </IconWrapper>
        &nbsp;{viewMode()}
      </TextButton>
    </Tooltip>
  )
}

export default ViewModeIndicator
