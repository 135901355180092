import React, { useCallback, useEffect, useState } from 'react'
import {
  AnimatedDialog,
  ButtonNew,
  parseUrl,
  ReplaceIcon,
  urlHelper,
  useDialog,
} from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'

import ModalEditWithConfirmation from 'components/ModalEditWithConfirmation'
import InputModalTitle from 'elements/InputModalTitle'
import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import DoneButton from 'ui/components/DoneButton'

import { IconWrapper } from '@ui/components/IconWrapper'
import useAddPdf from '@features/nodeEditor/hooks/useAddPdf'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { ReactComponent as PdfIcon } from '../../../../icons/Pdf.svg'

import useUpdatePdfUrl from '../hooks/useUpdatePdfUrl'
import { getUploadingNodes } from '../../../../selectors'
import { NodeType } from '../../../models'
import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'

import styles from './EditLinkPdf.module.css'
import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

interface ViewUrlProps {
  text: string
  error: string
  disabled: boolean
}

const ViewUrl = ({ text, error, disabled }: ViewUrlProps) => (
  <div className={styles.Wrapper}>
    {disabled ? (
      <span className={styles.UrlReadOnly}>{text}</span>
    ) : (
      <a
        href={text}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.Url}
        onClick={(e) => e.stopPropagation()}
      >
        {text}
      </a>
    )}
    {error && <p className={styles.ErrorMessage}>{error}</p>}
  </div>
)

interface Props {
  initialText: string
  initialTitle: string
  onSave: ICreateOrUpdateNode
  isPageEditor?: boolean
  onContentReplaceEvent?: () => void
}

const EditLinkPdf = ({
  initialText: text,
  initialTitle,
  onSave,
  isPageEditor,
  onContentReplaceEvent,
}: Props) => {
  const { addPdf, updatedPdf } = useAddPdf(onSave, isPageEditor, {
    isEditMode: true,
    onContentReplaceEvent,
  })
  const [pdfProps, setPdfProps] = useState({ text, title: initialTitle })
  const { title, hasChanges, setTitle } = useNodeAPI(pdfProps.title, '')

  useEffect(() => {
    setPdfProps((prevState) => ({
      text: updatedPdf.text || prevState.text,
      title: updatedPdf.title || prevState.title,
    }))

    if (updatedPdf.title) {
      setTitle(updatedPdf.title)
    }
  }, [setTitle, updatedPdf])

  const { t } = useTranslation()
  const updateUrl = useUpdatePdfUrl()
  const [error, setError] = useState('')
  const discardChanges = useNodeDiscardChanges()
  const { close } = useDialog()

  const uploading = useSelectorFactory(getUploadingNodes).includes(
    pdfProps.text,
  )

  const getUpdatedText = useCallback(() => {
    const { origin } = parseUrl(pdfProps.text)
    const ownPdfUrl = process.env.REACT_APP_PDF_URL
    return origin === ownPdfUrl
      ? updateUrl(pdfProps.text, pdfProps.title)
      : pdfProps.text
  }, [pdfProps, updateUrl])

  const handleSave = () => {
    if (urlHelper.isValidUrl(pdfProps.text)) {
      onSave({
        title,
        text: pdfProps.text,
        type: NodeType.PDF,
      })
      close()
      return
    }

    setError(t('linkpdf_invalid_url_msg'))
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: close,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <div className={styles.FooterControl}>
              {onContentReplaceEvent ? (
                <ButtonNew
                  color={'secondary'}
                  size={'medium'}
                  onClick={addPdf}
                  startIcon={
                    <IconWrapper>
                      <ReplaceIcon />
                    </IconWrapper>
                  }
                >
                  <Trans i18nKey="replace_pdf" />
                </ButtonNew>
              ) : null}

              <DoneButton onClick={handleSave}>
                <Trans i18nKey="action_done" />
              </DoneButton>
            </div>
          }
        >
          <InputModalTitle
            value={title}
            placeholder={t('add_an_update_title_optional')}
            onChange={setTitle}
          />

          <div className={styles.Field}>
            <PdfIcon className={styles.PdfIcon} />

            <ViewUrl
              text={getUpdatedText()}
              disabled={uploading}
              error={error}
            />

            {uploading && (
              <img className={styles.Loading} src="/loader.gif" alt="loading" />
            )}
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditLinkPdf
