import React, { FC, ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { ButtonNew } from 'boards-web-ui'

import styles from './BoardActivateButton.module.css'

type Props = {
  icon?: ReactElement
  onActivate?: () => void
}
const BoardActivateButton: FC<Props> = ({ onActivate, icon }) => {
  const handleClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (onActivate) {
      onActivate()
    }
  }

  return (
    <ButtonNew
      className={styles.Button}
      size={'small'}
      color={'secondary'}
      onClick={handleClick}
    >
      {icon ? <>{icon}&nbsp;&nbsp;</> : null}
      <Trans i18nKey={'activate_dialog_button_activate'} />
    </ButtonNew>
  )
}

export default React.memo(BoardActivateButton)
