import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

export const useMembersEvents = () => {
  const { trigger } = useLogEvents()

  const appMembersScreenView = () => {
    trigger(EventCategories.APP_MEMBERS_SCREEN_VIEW)
  }

  const appCoEditorsScreenView = (params?: { banner: 'co_editor_upgrade' }) => {
    trigger(EventCategories.APP_CO_EDITOR_SCREEN_VIEW, params)
  }

  return {
    appMembersScreenView,
    appCoEditorsScreenView,
  }
}
