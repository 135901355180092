import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, InputField, Spacer } from 'boards-web-ui'
import useFormsEvents from '@features/analytics/useFormsEvents'
import { useEditForms } from '../hooks'

import EditFormAdditionalFields from './EditFormAdditionalFields'
import styles from './FormContact.module.css'

type Props = {
  onAfterAddAdditionalField?: () => void
}
const FormContent: FC<Props> = ({ onAfterAddAdditionalField }) => {
  const { t } = useTranslation()
  const { formTitle, onFormTitleChange, isNew } = useEditForms()
  const { editScreenView } = useFormsEvents()
  useEffect(() => {
    editScreenView(isNew)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Spacer height={7} />
      <Grid justifyContent="space-between">
        <div className={styles.TitleWrapper}>
          <InputField
            className={styles.Title}
            value={formTitle}
            placeholder={t('forms_form_default_title')}
            onChange={(e) => onFormTitleChange(e.target.value)}
            whiteVariant
          />
        </div>
      </Grid>

      <EditFormAdditionalFields
        onAfterAddAdditionalField={onAfterAddAdditionalField}
      />
    </>
  )
}

export default FormContent
