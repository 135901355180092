import { useMemo } from 'react'

import { AuthState } from '../app/models'
import { useFirebaseAuth } from '../_firebase/hooks/useFirebaseAuth'

export const useAuth = (): AuthState => {
  const { user, isLoggedIn, loaded, signIn, signUp, signOut, resetPassword } =
    useFirebaseAuth()

  return useMemo(() => {
    return {
      user,
      isLoggedIn,
      loaded,
      signIn,
      signUp,
      signOut,
      resetPassword,
    }
  }, [user, isLoggedIn, loaded, signIn, signUp, signOut, resetPassword])
}
