import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { QUERY_PARAMS } from '../../../constants/routes'

interface Params {
  action: () => void
  queryOperation: QUERY_PARAMS
  paramValueToCompare: string
}

type TUseOperationFromSearchParam = (params: Params) => void

/**
 * @deprecated Create a generic solution to avoid usage this hook inside
 *   components it should be set as global hook only once
 *   https://heybliss.atlassian.net/browse/BLISS-10271
 */
export const useRunActionFromSearchParam: TUseOperationFromSearchParam = ({
  action,
  queryOperation,
  paramValueToCompare,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get(queryOperation) === paramValueToCompare) {
      searchParams.delete(queryOperation)
      setSearchParams(searchParams)
      action()
    }
  }, [
    action,
    paramValueToCompare,
    queryOperation,
    searchParams,
    setSearchParams,
  ])
}
