import { ReactComponent as LogoIcon } from '../icons/Logo.svg'

import styles from './LayoutMobilePlaceholder.module.css'

const LayoutMobilePlaceholder = () => (
  <div className={styles.Root}>
    <LogoIcon />
  </div>
)

export default LayoutMobilePlaceholder
