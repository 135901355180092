import { useState } from 'react'

const progressiveScale = [
  0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4,
  5,
]

const INITIAL_ZOOM_INDEX = 7

const useZoom = () => {
  const [scale, setScale] = useState(INITIAL_ZOOM_INDEX)

  const canReset = scale !== INITIAL_ZOOM_INDEX
  const canDec = scale > 0
  const canInc = scale < progressiveScale.length - 1

  const reset = () => setScale(INITIAL_ZOOM_INDEX)
  const increase = () => setScale((prev) => prev + 1)
  const decrease = () => setScale((prev) => prev - 1)

  return {
    zoom: progressiveScale[scale],
    reset: canReset ? reset : null,
    increase: canInc ? increase : null,
    decrease: canDec ? decrease : null,
  }
}

export default useZoom
