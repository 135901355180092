import React from 'react'

export enum NodeSelectionVariants {
  blue = 'blue',
  gray = 'gray',
  default = 'default',
}

export interface NodeSelectionProps {
  isSelected: boolean
  variant: NodeSelectionVariants
  withMargin?: boolean
  withOverlay?: boolean
  withGrayGradient?: boolean
  withDarkOverlay?: boolean
  tooltipMessage?: string
  opacity?: string
  onClick?: () => void
  onSelect?: () => void
  children: React.ReactElement
}
