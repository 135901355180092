import React, { PropsWithChildren, SyntheticEvent } from 'react'

import styles from './PreviewModalHeader.module.css'

type Props = PropsWithChildren<{
  style?: React.CSSProperties
  onClick: () => void
}>

const PreviewModalHeader: React.FC<Props> = ({
  onClick,
  children,
  ...rest
}) => {
  const handleOnClick = (e: SyntheticEvent) => {
    e.stopPropagation()

    if (e.target === e.currentTarget && onClick) {
      onClick()
    }
  }

  return (
    <div className={styles.Root} onClick={handleOnClick}>
      <div className={styles.Inner} {...rest}>
        {children}
      </div>
    </div>
  )
}

export default PreviewModalHeader
