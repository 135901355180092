import React from 'react'
import { DialogProvider } from 'boards-web-ui'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import ReverseTrialEndDialog from '@features/reverseTrial/components/reverseTrialStart/ReverseTrialEndDialog'
import ReverseTrialStartV2 from '@features/reverseTrial/components/reverseTrialStart/ReverseTrialStartV2'
import ReverseTrialStart from '@features/reverseTrial/components/reverseTrialStart/ReverseTrialStartDialog'

const ReverseTrialBlocker = () => {
  const {
    shouldShowStartTrialScreen,
    shouldShowEndOfTrialScreen,
    trialDaysAvailable,
  } = useReverseTrialContext()

  return shouldShowStartTrialScreen || shouldShowEndOfTrialScreen ? (
    <DialogProvider>
      {shouldShowStartTrialScreen &&
        trialDaysAvailable &&
        (trialDaysAvailable === 14 ? (
          <ReverseTrialStartV2 />
        ) : (
          <ReverseTrialStart />
        ))}
      {shouldShowEndOfTrialScreen && <ReverseTrialEndDialog />}
    </DialogProvider>
  ) : null
}

export default ReverseTrialBlocker
