import { FC, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'

import { CurrentPlan } from 'app/models'

import styles from './MobilePlan.module.css'

type Props = PropsWithChildren<{
  plan: CurrentPlan
}>
const MobilePlan: FC<Props> = ({ plan }) => (
  <div className={styles.Root}>
    {plan.source === 'AppStore' ? (
      <Trans i18nKey={'subscribed_apple'} />
    ) : (
      <Trans i18nKey={'subscribed_google'} />
    )}
  </div>
)

export default MobilePlan
