import type { Campaign, CampaignBanners } from './models/campaigns'
import { callFirebaseFunction } from './utils'
import { generalFunctions as functions } from './functions'

export const getCampaign = (
  campaignId: string,
): Promise<Campaign | undefined> => {
  return callFirebaseFunction<Campaign>(functions, 'campaigns-getCampaign', {
    campaignId,
  })
}

export const getCampaignBanners = (): Promise<CampaignBanners | undefined> => {
  return callFirebaseFunction<CampaignBanners>(
    functions,
    'notifications-getBanners ',
  )
}

export const markBannerAsShown = (bannerIds: Array<string>): Promise<void> => {
  return callFirebaseFunction(functions, 'users-setBannersShown', {
    ids: bannerIds,
  })
}
