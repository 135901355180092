import { FormResponseSpreadsheetStatuses } from 'boards-web-ui'
import { omit } from 'lodash-es'
import { callFirebaseFunction } from '_firebase/utils'
import { generalFunctions as functions } from './functions'
import { LoadFoldersWithShortcutsResponse } from './models/shortcutDataResponse'
import {
  FormIntegrationStatusData,
  IUserEmailPreferences,
  UIState,
  UserProperties,
} from './models/generalFunctions'

export const setUIState = ({ property, value }: UIState) => {
  return callFirebaseFunction(functions, 'setUIState', {
    [property]: value,
  })
}

export const setUserProperties = (params: UserProperties & { type?: string }) =>
  callFirebaseFunction(functions, 'setUserProperties', omit(params, ['type']))

export const setUserEmailPreferences = ({
  preferences,
}: {
  preferences: IUserEmailPreferences
}) =>
  callFirebaseFunction(functions, 'users-setPreferences', {
    emails: { ...preferences },
  })

export const authUserRecordChanged = (phoneNumber: string) => {
  return callFirebaseFunction(functions, 'users-authUserRecordChanged', {
    propertyNames: { phoneNumber },
  })
}

export const startFreeTrial = ({ userId }: { userId: string | null }) => {
  return callFirebaseFunction(
    functions,
    `monetization-startWebTrial?userId=${userId}`,
    {
      userId,
    },
  )
}

export const startReverseTrial = () => {
  return callFirebaseFunction(functions, 'monetization-startReverseTrial')
}

export const setMarketingInfo = (data: object) => {
  return callFirebaseFunction(functions, `setMarketingInfo`, data)
}

export const sendMobileAppInvitationEmail = () => {
  return callFirebaseFunction(
    functions,
    `notifications-sendMobileAppInvitationEmail`,
  )
}

export const getFoldersWithShortcuts = (shortcutId: string) => {
  const [sharerShortcut, shortcut] = shortcutId.split('.')
  return callFirebaseFunction<LoadFoldersWithShortcutsResponse>(
    functions,
    `loadFoldersWithShortcuts`,
    {
      sharerShortcut,
      folderShortcuts: [
        {
          shortcut,
        },
      ],
    },
  )
}

export const getFormIntegrationStatus = (
  board: string,
  folder: string,
  form?: string,
) => {
  let requestParams: { board: string; folder: string; form?: string } = {
    board,
    folder,
  }

  if (typeof form === 'string') {
    requestParams = {
      ...requestParams,
      form,
    }
  }

  return callFirebaseFunction<FormIntegrationStatusData>(
    functions,
    'forms-getFormIntegrationStatus',
    requestParams,
  )
}

export const startingPayPalOnboarding = () =>
  callFirebaseFunction(functions, 'forms-startingPayPalOnboarding')

export const disablePayPalAuthorization = () =>
  callFirebaseFunction(functions, 'forms-disablePayPalAuthorization')

export const testSpreadsheetStatus = (spreadSheetURL: string) => {
  return callFirebaseFunction<{ status: FormResponseSpreadsheetStatuses }>(
    functions,
    'forms-testSpreadsheetStatus',
    {
      url: spreadSheetURL,
    },
  )
}

export const updateSpreadsheetStatus = () => {
  return callFirebaseFunction(functions, 'forms-updateSpreadsheetStatus')
}

export const helperValidateEmails = (
  emails: string[],
  checkExisting: boolean,
) => {
  return callFirebaseFunction<string[]>(functions, 'helpers-validateEmails', {
    emails,
    checkExisting,
  })
}
