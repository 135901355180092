import { useState } from 'react'
import { ILoadImage } from '@features/board/models/nodes'

const useLoadImage = (): ILoadImage => {
  const [hasError, setError] = useState(false)
  const [retry, setRetry] = useState(1)

  const handleImageError = () => {
    setError(true)
    if (retry < 3) {
      setTimeout(() => {
        setRetry(retry + 1)
        setError(false)
      }, retry * 3000)
    }
  }

  return {
    hasError,
    handleImageError,
  }
}

export default useLoadImage
