import { PropsWithChildren } from 'react'
import { NODE_LINK_TYPES, SocialLinkIcon } from 'boards-web-ui'

import styles from './FormHeader.module.css'

type Props = PropsWithChildren<{
  type: NODE_LINK_TYPES
  title: string
}>
const FormHeader = ({ type, title, children }: Props) => (
  <div className={styles.Root}>
    <div className={styles.Header}>
      <SocialLinkIcon type={type} small />
      <span className={styles.Title}>{title}</span>
    </div>
    {children}
  </div>
)

export default FormHeader
