import { useCallback } from 'react'

import { isMobile } from '../../../utils/deviceParser'
import { useLogEvents } from './useLogEvents'

const useCampaignAnalytics = () => {
  const { trigger } = useLogEvents()

  const appCustomHomeBannerView = useCallback(
    (campaignId: string) =>
      trigger('app_custom_home_banner_view', {
        campaign_manager_id: campaignId,
        operating_system: isMobile ? 'mobile-web' : 'desktop',
      }),
    [trigger],
  )

  const appCustomHomeBannerClick = useCallback(
    (campaignId: string) =>
      trigger('app_custom_home_banner_click', {
        campaign_manager_id: campaignId,
        operating_system: isMobile ? 'mobile-web' : 'desktop',
      }),
    [trigger],
  )

  const appCustomHomeBannerDismiss = useCallback(
    (campaignId: string) =>
      trigger('app_custom_home_banner_dismiss', {
        campaign_manager_id: campaignId,
        operating_system: isMobile ? 'mobile-web' : 'desktop',
      }),
    [trigger],
  )

  return {
    appCustomHomeBannerView,
    appCustomHomeBannerClick,
    appCustomHomeBannerDismiss,
  } as const
}

export default useCampaignAnalytics
