export const enum ACTION_TYPE {
  ADD_MEMBERS = 'ADD_MEMBERS',
  REMOVE_MEMBERS = 'REMOVE_MEMBERS',
}

const addMembers = (board: string, emails: string[], permission: string) => ({
  type: ACTION_TYPE.ADD_MEMBERS,
  board,
  emails,
  permission,
})

const removeMembers = (board: string, emails: string[]) => ({
  type: ACTION_TYPE.REMOVE_MEMBERS,
  board,
  emails,
})

const actions = {
  addMembers,
  removeMembers,
} as const

export default actions
