import { Trans, useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { ButtonNew, InputField, useDialog } from 'boards-web-ui'

import { useToast } from '@hooks/useToast'
import { useCallback, useRef } from 'react'
import { useUpdatesTranslations } from '../../updates/hooks/useUpdatesTranslations'
import { useProfileName } from '../hooks/useProfileName'
import { useProfileInformation } from '../hooks/useProfileInformation'
import { useProfileEvents } from '../../analytics/useProfileEvents'

import { UpdateProfile } from '../../../models'

import styles from './ProfileMenu.module.css'

const EditProfileName = () => {
  const { t } = useTranslation()

  const toast = useToast()

  const { updateProfileName } = useProfileName()
  const { profile } = useProfileInformation()

  const { close } = useDialog()

  const profileName = profile?.name

  const { appAddName, appEditName } = useProfileEvents()
  const { minNameLength, maxNameLength } = useUpdatesTranslations()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: profileName,
    },
  })

  const nameFieldValue = useWatch({
    control,
    name: 'name',
  })

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleSubmitClick = useCallback(() => {
    setValue('name', inputRef.current?.value)

    inputRef.current?.blur()
  }, [setValue, inputRef])

  const onSubmit = async ({ name }: UpdateProfile) => {
    if (profileName) {
      appEditName()
    } else {
      appAddName()
    }

    toast(t('label_name_updated'), true, 0, false)
    close()

    await updateProfileName(name)
  }

  return (
    <div className={styles.ProfileNameDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={styles.ProfileNameDialogTitle}>
          {profileName ? (
            <Trans i18nKey="dialog_edit_name_title" />
          ) : (
            <Trans i18nKey="dialog_add_name_title" />
          )}
        </p>
        <div className={styles.ProfileNameInput}>
          <InputField
            autoFocus
            maxLength={30}
            placeholder={t('page_edit_name_placeholder')}
            errorMessage={errors?.name?.message}
            {...register('name', {
              required: minNameLength(3),
              minLength: {
                value: 3,
                message: minNameLength(3),
              },
              maxLength: {
                value: 30,
                message: maxNameLength(30),
              },
            })}
            value={nameFieldValue}
            onChange={(e) => {
              setValue('name', e.target.value)
            }}
            onFocus={() => clearErrors('name')}
            ref={inputRef}
          />
        </div>

        <ButtonNew
          type={'submit'}
          fullWidth
          size={'large'}
          onClick={handleSubmitClick}
        >
          <Trans i18nKey="action_done" />
        </ButtonNew>
      </form>
    </div>
  )
}

export default EditProfileName
