import React from 'react'
import styles from './TopContentList.module.css'

const TopContentList: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <div className={styles.Root}>{children}</div>
}

export default TopContentList
