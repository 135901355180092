import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ForwardIcon, Icon } from 'boards-web-ui'

import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useBoards from '@hooks/useBoards'
import usePaywall from '@hooks/usePaywall'

import styles from './ReverseTrialBar.module.css'

const ReverseTrialBar = () => {
  const { t } = useTranslation()
  const { openPaywall } = usePaywall()
  const { boards } = useBoards()
  const {
    shouldShowFreeTrialIndicator,
    shouldShowFreePlanIndicator,
    getDaysLeftIndicatorData,
  } = useReverseTrialContext()

  const {
    profile: { plan },
  } = useProfileInformation()

  const [showDaysLeft, setShowDaysLeft] = useState(false)
  const [days, setDays] = useState(0)
  const sharedBoards = Object.keys(boards)
    .filter((key) => {
      return !boards[key].isOwn && !boards[key].options?.notCountedTowardsLimit
    })
    .map((key) => {
      return boards[key]
    })

  useEffect(() => {
    const checkIndicator = () => {
      const { daysLeft, shouldShowDays } = getDaysLeftIndicatorData()
      setShowDaysLeft(shouldShowDays)
      setDays(daysLeft)
    }
    checkIndicator()
    const intervalId = setInterval(checkIndicator, 10000)
    return () => clearInterval(intervalId)
  }, [getDaysLeftIndicatorData])

  const hasZeroActiveSharedBoard = sharedBoards.every(
    (item) => item.isActive === false,
  )
  const numberBoardsLimit = plan?.limits?.boards
  const shouldShowActivateBoardsText =
    sharedBoards.length > 0 && numberBoardsLimit > 0 && hasZeroActiveSharedBoard

  const handleUpgrade = () => {
    openPaywall({ source: PaywallSourceEnum.FREE_PLAN_INDICATION })
  }

  return (
    <div className={styles.Root}>
      {shouldShowFreeTrialIndicator && (
        <h5 className={styles.TextPrimary}>
          {t('trial_indication_title')}{' '}
          {showDaysLeft && (
            <span className={styles.TextSecondary}>
              <Trans
                i18nKey={'trial_indication_timer_web'}
                values={{
                  days,
                }}
              />
            </span>
          )}
        </h5>
      )}

      {shouldShowFreePlanIndicator && (
        <h5 className={styles.TextPrimary}>
          {t('free_plan_indication_title')}{' '}
          {shouldShowActivateBoardsText ? (
            <span className={styles.TextSecondary}>
              <Trans
                i18nKey={'shared_board_shared_with_me_limit_text'}
                values={{
                  number: numberBoardsLimit,
                }}
              />
            </span>
          ) : (
            <span className={styles.TextSecondary}>
              <Trans i18nKey={'free_plan_unlimited_board_description'} />
            </span>
          )}
        </h5>
      )}

      <button className={clsx(styles.ActionButton)} onClick={handleUpgrade}>
        {t('paywall_upgrade')}
        <Icon>
          <ForwardIcon />
        </Icon>
      </button>
    </div>
  )
}

export default ReverseTrialBar
