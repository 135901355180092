import { ReactComponent as SpinnerLargeIcon } from '../icons/Spinner-81px.svg'
import { ReactComponent as SpinnerSmallIcon } from '../icons/Spinner-24px.svg'
import { ReactComponent as SpinnerSmallWhiteIcon } from '../icons/Spinner-24px-White.svg'
import { ReactComponent as SpinnerLargeWhiteIcon } from '../icons/Spinner-81px-White.svg'

import styles from './Spinner.module.css'

export enum Align {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}
export enum Justify {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}
export enum Size {
  SMALL = 'small',
  LARGE = 'large',
}

export enum Color {
  WHITE = 'white',
  GRAY = 'gray',
}

const alignMapToCss = {
  [Align.START]: styles.AlignFlexStart,
  [Align.CENTER]: styles.AlignCenter,
  [Align.END]: styles.AlignFlexEnd,
} as { [key in Align]: string }

const justifyMapToCss = {
  [Justify.START]: styles.JustifyFlexStart,
  [Justify.CENTER]: styles.JustifyCenter,
  [Justify.END]: styles.JustifyFlexEnd,
} as { [key in Justify]: string }

type Props = {
  spinning: boolean
  children: JSX.Element | JSX.Element[] | null
  size?: Size
  align?: Align
  justify?: Justify
  color?: Color
}

const Spinner = ({
  align = Align.CENTER,
  justify = Justify.CENTER,
  size = Size.LARGE,
  spinning,
  color = Color.GRAY,
  children,
}: Props) => {
  const SpinnerClasses = [
    styles.Root,
    alignMapToCss[align],
    justifyMapToCss[justify],
  ]

  let spinnerEl: JSX.Element | null = null

  if (size === Size.LARGE) {
    spinnerEl =
      color === Color.GRAY ? <SpinnerLargeIcon /> : <SpinnerLargeWhiteIcon />
  } else if (size === Size.SMALL) {
    spinnerEl =
      color === Color.GRAY ? <SpinnerSmallIcon /> : <SpinnerSmallWhiteIcon />
  }

  return (
    <>
      <div className={spinning ? styles.HideContent : styles.Content}>
        {children}
      </div>
      {spinning && <div className={SpinnerClasses.join(' ')}>{spinnerEl}</div>}
    </>
  )
}

export default Spinner
