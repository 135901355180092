import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { emailHelper, EmailIcon, useDialog } from 'boards-web-ui'

import { useAppClientConfig } from '@hooks/useAppClientConfig'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useMembersEvents } from '@features/analytics/useMembersEvents'
import AutocompleteEmails from './AutocompleteEmails'
import Button from '../../../../elements/Button'
import Avatar from '../../../../elements/Avatar'

import useBoard from '../../../../hooks/useBoard'
import useShareAPI from '../hooks/useShareAPI'
import useShareOptions from '../hooks/useShareOptions'

import styles from './AddMembers.module.css'

interface Props {
  emails: string[]
  addEmail: (email: string) => void
  removeEmailByIndex: (index: number) => void
}
const AddMembers = ({ emails, addEmail, removeEmailByIndex }: Props) => {
  const { role } = useBoard()
  const { close } = useDialog()
  const { t } = useTranslation()
  const { share } = useShareAPI()
  const [error, setError] = useState<string | null>(null)
  const [permission, setPermission] = useState('share')
  const { shareOptionsByRole } = useShareOptions()
  const clientConfig = useAppClientConfig()

  const { appMembersScreenView } = useMembersEvents()

  useEffect(() => {
    appMembersScreenView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPermissionAdd = () => {
    const errors = emails.filter((email) => {
      const { format, topLevelDomains } = clientConfig.emailAddress

      const isEmailValid = format.test(email.toLocaleLowerCase())
      const isTLDValid = emailHelper.validateTLD(email, topLevelDomains)

      return !(isEmailValid && isTLDValid)
    })

    if (emails.length === 0) {
      setError(t('share_add_email_no_email_error'))
      return
    }

    if (errors.length > 0) {
      setError(t('share_modal_invalid_email_address'))
      return
    }

    share(emails, permission)
    close()
  }

  return (
    <div className={styles.EmailPicker}>
      <div className={styles.AvatarWrapper}>
        <Avatar size={38} bgColor="#F2F4FF">
          <IconWrapper color={'primary'}>
            <EmailIcon />
          </IconWrapper>
        </Avatar>
      </div>
      <div className={styles.AutocompleteWrapper}>
        <AutocompleteEmails
          emails={emails}
          permission={permission}
          placeholder={t('share_screen_add_members_via_email')}
          error={error}
          shareOptions={shareOptionsByRole(role)}
          onPermissionSelected={setPermission}
          onEmailSelected={addEmail}
          onEmailDeleted={removeEmailByIndex}
        />
      </div>
      <div className={styles.SendButtonWrapper}>
        <Button onClick={onPermissionAdd} fullWidth>
          <Trans i18nKey="action_send" />
        </Button>
      </div>
    </div>
  )
}

export default AddMembers
