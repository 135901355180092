import { useEffect, useRef, useState } from 'react'
import { timeDifference } from '../helpers/timeDifference'

type DateDiffState = {
  value: number
  label: string
}

export const useDateDiff = (date: number, interval = 1000): DateDiffState => {
  const [state, setState] = useState<DateDiffState>(timeDifference(date))
  const timer = useRef<unknown>()

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current as number)
    }

    timer.current = setInterval(() => {
      setState(timeDifference(date))
    }, interval)

    return () => clearInterval(timer.current as number)
  }, [interval, date, setState])

  return state
}
