import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

const useReverseTrialEvents = () => {
  const { trigger } = useLogEvents()

  const startTrialViewEvent = (trialDays: number) => {
    trigger(EventCategories.APP_START_TRIAL_VIEW, {
      reverse_trial_days: trialDays,
    })
  }

  const startTrialClickEvent = ({
    trialDaysAvailable,
    isReverseTrial,
    trialDaysLeft,
  }: {
    trialDaysAvailable: number
    trialDaysLeft: number
    isReverseTrial: boolean
  }) => {
    trigger(EventCategories.APP_START_TRIAL_CLICK, {
      reverse_trial: isReverseTrial,
      reverse_trial_days: trialDaysAvailable,
      reverse_trial_days_left: trialDaysLeft,
    })
  }

  const endTrialViewEvent = (trialDays: number) => {
    trigger(EventCategories.APP_TRIAL_END_VIEW, {
      reverse_trial_days: trialDays,
    })
  }

  const endTrialDismissClickEvent = (trialDays: number) => {
    trigger(EventCategories.APP_TRIAL_END_DISMISS_CLICK, {
      reverse_trial_days: trialDays,
    })
  }

  const endTrialUpgradeClickEvent = (trialDays: number) => {
    trigger(EventCategories.APP_TRIAL_END_UPGRADE_CLICK, {
      reverse_trial_days: trialDays,
    })
  }

  const trialCountdownViewEvent = () => {
    trigger(EventCategories.APP_TRIAL_REMINDER_VIEW)
  }

  const trialCountdownDismissEvent = () => {
    trigger(EventCategories.APP_TRIAL_REMINDER_DISMISS)
  }

  return {
    startTrialViewEvent,
    startTrialClickEvent,
    endTrialViewEvent,
    endTrialDismissClickEvent,
    endTrialUpgradeClickEvent,
    trialCountdownViewEvent,
    trialCountdownDismissEvent,
  }
}

export default useReverseTrialEvents
