import React, { FC } from 'react'
import styles from './BoardPanel.module.css'

interface Props {
  height: number
  children: JSX.Element[]
  isSharedFolderMode?: boolean
  canBeShared?: boolean
}

const BoardPanel: FC<Props> = ({
  height,
  children,
  isSharedFolderMode,
  canBeShared,
}) => {
  const style = { height: 'auto' }
  if (height) {
    style.height = `${height}px`
  }

  return (
    <div
      className={[
        styles.Root,
        isSharedFolderMode && canBeShared
          ? styles.SharedRoot
          : styles.EditorRoot,
      ].join(' ')}
      style={style}
    >
      {children}
    </div>
  )
}

export default React.memo(BoardPanel)
