import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import useRotesHelper from './useRotesHelper'
import * as ROUTES from '../../constants/routes'
import { QUERY_PARAM_VALUES, QUERY_PARAMS } from '../../constants/routes'

const useAutoSelectFirstBoardOrRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { nonBoardSelectedState, firstBoardUrl, navigateToFirstBoard } =
    useRotesHelper()

  const templateGalleryRoute = `/${ROUTES.TEMPLATE_GALLERY}`

  useEffect(() => {
    if (
      searchParams.get(QUERY_PARAMS.operation) ===
      QUERY_PARAM_VALUES.operation.createPage
    ) {
      searchParams.delete(QUERY_PARAMS.operation)
      setSearchParams(searchParams)
      navigate(templateGalleryRoute)
    }

    if (
      nonBoardSelectedState &&
      firstBoardUrl &&
      location.pathname !== templateGalleryRoute
    ) {
      navigateToFirstBoard()
    }

    // eslint-disable-next-line
  }, [nonBoardSelectedState, firstBoardUrl, searchParams])

  return !nonBoardSelectedState
}

export default useAutoSelectFirstBoardOrRedirect
