import { parseUrl } from 'boards-web-ui'

export enum DynamicImageSize {
  Original = 'original',
  Based32 = '32',
  Based64 = '64',
  Based128 = '128',
  Based256 = '256',
  Based512 = '512',
  Based1024 = '1024',
  AutoPreview = 'og',
}

const tryToConvertToImageUrlWithSize = (
  url: string,
  size: DynamicImageSize,
): string => {
  const { pathname, hostname } = parseUrl(url)
  const components = pathname.split('/')

  if (components.length < 2) {
    return url
  }

  let newUrl = `https://${hostname}/`

  if (size !== DynamicImageSize.Original) {
    newUrl += `${size}/`
  }

  return newUrl + components[1]
}

export default tryToConvertToImageUrlWithSize
