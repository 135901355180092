import { useTranslation } from 'react-i18next'
import { NodeType, TNodeForm } from 'boards-web-ui'

import { EditForms } from '@features/forms'
import { DEFAULT_ADDITIONAL_FIELDS } from '@features/forms/constants'
import { useAuth } from '@hooks/useAuth'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'

interface Props {
  onSave: ICreateOrUpdateNode
  onCancel: () => void
}
const AddForm = ({ onSave, onCancel }: Props) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const initialNode = {
    type: NodeType.FORM,
    form: {
      title: '',
      action: t('forms_submit_button_label_default'),
      response: t('forms_thanks_message_default'),
      email: user?.email || '',
      fields: DEFAULT_ADDITIONAL_FIELDS,
    },
  } as TNodeForm

  return (
    <EditForms
      isNew
      initialNode={initialNode}
      onSave={onSave}
      onCancel={onCancel}
    />
  )
}

export default AddForm
