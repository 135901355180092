import {
  Campaign,
  CampaignBanner,
  CampaignBanners,
} from '../_firebase/models/campaigns'

// TODO State and Actions type
type State = {
  [key: string]: unknown
  campaigns?: {
    [key: string]: Campaign
  }
  campaignsBanners?: {
    [key: string]: CampaignBanner
  }
}

const getCampaignSuccess = (
  state: State = {},
  {
    campaign,
  }: {
    campaign: Campaign
  },
) => {
  return {
    ...state,
    campaigns: {
      [campaign.id]: campaign,
    },
  }
}

const getCampaignBannersSuccess = (
  state: State = {},
  {
    banners,
  }: {
    banners: CampaignBanners
  },
) => {
  const campaignsBanners = banners
    .filter((banner) => banner.type === 'homescreenBanner')
    .reduce((nextBanners, banner) => {
      return {
        ...nextBanners,
        [banner.id]: banner,
      }
    }, state?.campaignsBanners || {})

  return {
    ...state,
    campaignsBanners,
  }
}

const hideCampaignBanner = (
  state: State,
  { bannerIds }: { bannerIds: Array<string> },
) => {
  const nextCampaignsBanners = {
    ...state.campaignsBanners,
  }
  bannerIds.forEach((id) => delete nextCampaignsBanners[id])

  return {
    ...state,
    campaignsBanners: {
      ...nextCampaignsBanners,
    },
  }
}

export default {
  getCampaignSuccess,
  getCampaignBannersSuccess,
  hideCampaignBanner,
}
