import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../elements/Button'
import { useAuth } from '../../../../hooks/useAuth'
import styles from './MobilePopup.module.css'

export interface MobilePopupProps {
  setOpen: (value: boolean) => void
}

const MobilePopup: FC<MobilePopupProps> = ({ setOpen }: MobilePopupProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  return (
    <div className={styles.Root}>
      <h4 className={styles.Title}>
        {t('desktop_cta_mobile_email_popup_title')}
      </h4>
      <p className={styles.Email}>{user?.email}</p>
      <ol className={styles.List}>
        {t('desktop_cta_mobile_email_popup_body')
          .split('\n')
          .map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{item}</li>
          ))}
      </ol>
      <Button
        color="primary"
        size="md"
        onClick={() => setOpen(false)}
        className={styles.Button}
      >
        {t('action_got_it')}
      </Button>
    </div>
  )
}

export default MobilePopup
