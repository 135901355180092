import React, { FC } from 'react'
import BoardsSelection from '@features/pickers/components/BoardsSelection'

import FolderSelection from '@features/pickers/components/FolderSelection'
import { ContentPickersEnum, useContentSelection } from '@features/pickers'

interface Props {
  picker: ContentPickersEnum
  pickerBoardId?: string
  pickerFolderId?: string
  isPage: boolean
  selectBoard: () => void
  selectFolder: (newFolderId: string) => void
}

export const DestinationPickerBody: FC<Props> = ({
  picker,
  pickerFolderId,
  pickerBoardId,
  isPage,
  selectFolder,
  selectBoard,
}) => {
  const { nodes } = useContentSelection()

  const showFolderSelection = pickerBoardId && pickerFolderId && !isPage

  return (
    <>
      {!pickerBoardId && (
        <BoardsSelection selectBoard={selectBoard} isReadMode />
      )}

      {showFolderSelection && (
        <FolderSelection
          nodes={nodes}
          boardId={pickerBoardId}
          folderId={pickerFolderId}
          selectFolder={selectFolder}
          isReadMode
          picker={picker}
        />
      )}
    </>
  )
}
