import {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  useRef,
  useState,
} from 'react'
import { emailHelper } from 'boards-web-ui'

import { useAppClientConfig } from '@hooks/useAppClientConfig'
import { ShareOption } from '@features/share/models/ShareOption'
import { parseEmails, randomSeededColor } from '../../../../helpers'
import Avatar from '../../../../elements/Avatar'
import Chip from '../../../../elements/Chip'
import Select from '../../../../elements/Select'

import styles from './AutocompleteEmails.module.css'

interface Props {
  emails: string[]
  permission: string
  placeholder: string
  shareOptions: ShareOption[]
  error: string | null
  onPermissionSelected: (email: string) => void
  onEmailSelected: (email: string) => void
  onEmailDeleted: (index: number) => void
}
const AutocompleteEmails = ({
  emails,
  permission,
  placeholder,
  shareOptions,
  error,
  onPermissionSelected,
  onEmailSelected,
  onEmailDeleted,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState('')
  const clientConfig = useAppClientConfig()

  const isEmail = (email: string) => {
    const { topLevelDomains, format } = clientConfig.emailAddress

    const isEmailValid = format.test(email.toLocaleLowerCase())
    const isTLDValid = emailHelper.validateTLD(email, topLevelDomains)

    return isEmailValid && isTLDValid
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    if (['Tab', 'Space', 'Enter'].includes(e.code)) {
      selectEmail(newValue)
    }
    if (e.key === 'Backspace') {
      if (newValue === '') {
        onEmailDeleted(emails.length - 1)
      }
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setValue(newValue)
  }

  const handleOnPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const newValue = e.clipboardData.getData('Text')
    const chunks = parseEmails(newValue)
    chunks.forEach((email: string) => onEmailSelected(email))
  }

  const handleOnClick = () => inputRef?.current?.focus()

  const selectEmail = (email: string) => {
    if (email.trim()) {
      onEmailSelected(email.trim())
      setValue('')
    }
  }

  const getEmailAvatar = (email: string) => (
    <Avatar
      size={24}
      fontSize={14}
      color={'white'}
      bgColor={randomSeededColor(email)}
    >
      {email.charAt(0)}
    </Avatar>
  )

  const classes = error ? [styles.Root, styles.Error] : [styles.Root]
  return (
    <div className={classes.join(' ')}>
      <div className={styles.Wrapper} onClick={handleOnClick}>
        <div className={styles.InputWrapper}>
          {emails.map((email, i) => {
            const avatar = getEmailAvatar(email)
            const isEmailValid = isEmail(email)
            return (
              <Chip
                key={email}
                color={!isEmailValid ? 'error' : 'default'}
                variant={!isEmailValid ? 'outlined' : 'filled'}
                avatar={avatar}
                label={email}
                onDelete={() => onEmailDeleted(i)}
              />
            )
          })}
          <input
            ref={inputRef}
            className={styles.Input}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            onPaste={handleOnPaste}
            placeholder={emails.length ? '' : placeholder}
            type="text"
            value={value}
            onBlur={() => selectEmail(value)}
          />
        </div>

        <div style={{ whiteSpace: 'nowrap' }}>
          <Select
            options={shareOptions}
            value={permission}
            onSelect={onPermissionSelected}
            sx={{ fontSize: '14px' }}
          />
        </div>
      </div>
      <div className={styles.ErrorWrapper}>
        {error && <span className={styles.ErrorMessage}>{error}</span>}
      </div>
    </div>
  )
}

export default AutocompleteEmails
