import { useLogEvents } from '@features/analytics/useLogEvents'

enum TargetNames {
  BOARD = 'board',
  PAGE = 'page',
}

const useBoardEvents = () => {
  const { screenView, boardView } = useLogEvents()

  const viewPageScreen = (pageId: string, pageName: string) =>
    screenView(TargetNames.PAGE, {
      page_id: pageId,
      page_name: pageName,
    })

  return {
    boardView,
    viewPageScreen,
  } as const
}

export default useBoardEvents
