import { RefObject, useCallback, useEffect } from 'react'

import useRouterParams from '@hooks/useRouterParams'
import useScroll from '@hooks/useScroll'
import useBoards from '@hooks/useBoards'
import { useSelectorFactory } from '@hooks/useSelectorFactory'

import { appLoading } from '../../../../selectors'

const useSideBarBody = (scrollableRef: RefObject<HTMLDivElement>) => {
  const loading = useSelectorFactory(appLoading)
  const { order } = useBoards()
  const { boardId: selectedBoardId } = useRouterParams()
  const { scrollToElement } = useScroll()

  const scrollToBottom = useCallback(() => {
    scrollToElement(scrollableRef)
  }, [scrollableRef, scrollToElement])

  useEffect(() => {
    if (order) {
      const indexSelectedBoard: number | undefined = order.findIndex(
        (item) => item === selectedBoardId,
      )
      const index = indexSelectedBoard !== -1 ? indexSelectedBoard - 1 : 0
      scrollToElement({ current: scrollableRef.current }, {}, index, 46)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableRef.current])

  return {
    loading,
    scrollToBottom,
  } as const
}

export default useSideBarBody
