import useIsOnline from '../../../../hooks/useIsOnline'
import useBoard from '../../../../hooks/useBoard'
import useBulkSelection from './useBulkSelection'
import { BoardMode } from '../models/nodes'

const useBoardMode = (): BoardMode => {
  const isOnline = useIsOnline()
  const { isReadOnly, allowCopy } = useBoard()
  const { isInSelectionMode, hasAction } = useBulkSelection()

  const getMode = () => {
    if (!allowCopy) {
      return BoardMode.offline
    }

    if (isInSelectionMode) {
      if (hasAction) {
        return BoardMode.action
      }

      return BoardMode.select
    }

    if (isReadOnly) {
      return BoardMode.read
    }

    return BoardMode.write
  }

  return isOnline ? getMode() : BoardMode.offline
}

export default useBoardMode
