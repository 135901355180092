import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { ButtonNew, MembersIcon } from 'boards-web-ui'

import { useToast } from '@hooks/useToast'
import useUserFeatures, { Features } from '@hooks/useUserFeatures'

import useBoardsDialogs from '@features/boardsMenu/hooks/useBoardsDialogs'
import useFolderEvents from '@features/analytics/useFolderEvents'
import { ViewSource } from '@features/analytics/models/sourceEvents'

import { boardActions, folderActions } from '../../../../actions'

import styles from './SharedBoardModalFooter.module.css'

type Props = {
  joinKey: string
  boardId: string | undefined
  folderId: string | undefined
  joinFolderId: string
  icon?: string
  title?: string
  notCountedTowardsLimit: boolean
}

const SharedBoardModalFooter: FC<Props> = ({
  joinKey,
  boardId,
  folderId,
  icon,
  title,
  notCountedTowardsLimit,
  joinFolderId,
}) => {
  // TODO Refactor this component!!!
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const { limit, limitReached } = useUserFeatures(Features.boards)
  const { openBoardsLimitReached } = useBoardsDialogs()

  const { joinBoardEvent } = useFolderEvents()

  const joinBoard = async () => {
    if (limit !== 'unlimited' && limitReached && !notCountedTowardsLimit) {
      openBoardsLimitReached(ViewSource.sharedFolder)
      return
    }

    setLoading(true)

    await dispatch(boardActions.joinSharedFolder(joinKey))

    joinBoardEvent('web_folder', boardId, joinFolderId)

    const timer = setTimeout(() => {
      setLoading(false)
      toast(t('toast_youve_joined_this_board'))
      dispatch(
        folderActions.updateSharedFolderData({
          shortcutId: '',
          boardId: '',
          folderId: '',
        }),
      )
      dispatch(folderActions.setSharedFolder())
      navigate(`${boardId}/${folderId}`)
      clearTimeout(timer)
    }, 800)
  }
  return (
    <div className={styles.JoinBoard}>
      <p className={styles.JoinBoardTitle}>
        <span>{icon}</span>
        &nbsp;
        <span>{title}</span>
      </p>

      <p className={styles.JoinBoardSubtitle}>
        <Trans i18nKey="shared_folder_join_board_explanation_2" />
      </p>

      <ButtonNew
        isLoading={loading}
        size={'large'}
        startIcon={<MembersIcon color="#fff" />}
        onClick={joinBoard}
      >
        <Trans i18nKey="landingpage_joinboard" />
      </ButtonNew>
    </div>
  )
}

export default React.memo(SharedBoardModalFooter)
