/* eslint-disable no-nested-ternary, prefer-template */
const toFixedIfNecessary = (value: number, dp: number) => {
  return +value.toFixed(dp)
}

// prettier-ignore
export const formatNumber = (numParam: number): string => {
  const num = Math.abs(Number(numParam))
  const billions = num/1.0e+9
  const millions = num/1.0e+6
  const thousands = num/1.0e+3
  return num >= 1.0e+9 && billions >= 100  ? Math.round(billions)             + "B"
       : num >= 1.0e+9 && billions >= 10   ? toFixedIfNecessary(billions, 1)  + "B"
       : num >= 1.0e+9                     ? toFixedIfNecessary(billions, 2)  + "B"
       : num >= 1.0e+6 && millions >= 100  ? Math.round(millions)             + "M"
       : num >= 1.0e+6 && millions >= 10   ? toFixedIfNecessary(millions, 1)  + "M"
       : num >= 1.0e+6                     ? toFixedIfNecessary(millions, 2)  + "M"
       : num >= 1.0e+3 && thousands >= 100 ? Math.round(thousands)            + "K"
       : num >= 1.0e+3 && thousands >= 10  ? toFixedIfNecessary(thousands, 1) + "K"
       : num >= 1.0e+3                     ? toFixedIfNecessary(thousands, 2) + "K"
       : num.toFixed()
}
