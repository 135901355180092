import { useEffect, useState } from 'react'
import { FirebaseTimestamp } from '@features/reverseTrial/models/reverseTrialModels'

const MILLIS_IN_SECOND = 1000
const SECONDS_IN_HOUR = 3600
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24

export const calculateSecondsLeftUntilDueDate = (dueDate: Date): number => {
  const now = new Date().getTime()
  const timeLeft = dueDate.getTime() - now
  return Math.max(timeLeft / MILLIS_IN_SECOND, 0) // Return time in seconds, ensuring it's not negative
}

export const calculateHoursLeftUntilDueDate = (dueDate: Date) => {
  return calculateSecondsLeftUntilDueDate(dueDate) / SECONDS_IN_HOUR
}

export const calculateDaysLeftUntilDueDate = (dueDate: Date) => {
  const hoursLeft = calculateHoursLeftUntilDueDate(dueDate)
  return Math.ceil(hoursLeft / HOURS_IN_DAY)
}

export const convertFirestoreTimestampToDate = (
  timestamp: FirebaseTimestamp,
) => {
  const { seconds, nanoseconds } = timestamp
  const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000)
  return new Date(milliseconds)
}

const useCountdownTimer = ({ dueDate }: { dueDate: Date }) => {
  const [secondsLeft, setSecondsLeft] = useState(
    calculateSecondsLeftUntilDueDate(dueDate),
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId)
          return 0
        }
        return prev - 1
      })
    }, MILLIS_IN_SECOND)

    return () => clearInterval(intervalId)
  }, [])

  const hours = Math.floor(secondsLeft / SECONDS_IN_HOUR)
  const minutes = Math.floor((secondsLeft % SECONDS_IN_HOUR) / MINUTES_IN_HOUR)
  const seconds = Math.floor(secondsLeft % MINUTES_IN_HOUR)

  return { hours, minutes, seconds, secondsLeft }
}

export default useCountdownTimer
