import { DocumentSnapshot } from 'firebase/firestore'
import { contentActions } from '../../../actions'
import { transformUserContent } from '../transformers/UserContentTransformer'
import { AppDispatch } from '../../../app/store'

export const UserContentSnapshot =
  (dispatch: AppDispatch) => (doc: DocumentSnapshot) => {
    const user = transformUserContent(doc)
    dispatch(contentActions.setUserContentSettings(user))
  }
