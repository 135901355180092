import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import { useMemo } from 'react'

export enum ProfileMenuSourceEnum {
  Menu = 'menu',
  Account = 'account',
}

export const useProfileMenuEvents = (source: ProfileMenuSourceEnum) => {
  const { trigger } = useLogEvents()

  return useMemo(() => {
    const appFeedBackClickEvent = () => {
      trigger(EventCategories.APP_FEEDBACK_CLICK, {
        source,
      })
    }

    const appLogoutClickEvent = () => {
      trigger(EventCategories.APP_LOGOUT_CLICK, {
        source,
      })
    }

    const appInfoClickEvent = () => {
      trigger(EventCategories.APP_INFO_CLICK, {
        source,
      })
    }

    const appEmailPreferencesClick = () => {
      trigger(EventCategories.APP_EMAIL_PREFERENCES_CLICK, {
        source,
      })
    }

    return {
      appFeedBackClickEvent,
      appLogoutClickEvent,
      appInfoClickEvent,
      appEmailPreferencesClick,
    } as const
  }, [trigger, source])
}
