import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useAuth } from '@hooks/useAuth'

import { createFirebaseListener } from '_firebase/listeners/FirestoreListener'

const useStartProfileListener = () => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const { startProfileListener } = useMemo(
    () => createFirebaseListener(dispatch),
    [dispatch],
  )

  useEffect(() => {
    if (user) {
      startProfileListener(user.uid)
    }
  }, [user, startProfileListener])
}

export default useStartProfileListener
