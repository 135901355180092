import { QuerySnapshot } from 'firebase/firestore'
import { permissionActions } from '../../../actions'
import { AppDispatch } from '../../../app/store'
import { transformPermission } from '../transformers/PermissionTransformer'

export const ReceivedBoardsPermissionsSnapshot =
  (dispatch: AppDispatch) => (snapshot: QuerySnapshot) => {
    snapshot.docChanges().forEach(({ doc, type }) => {
      const data = transformPermission(doc)
      const content = doc.ref.parent.parent
      const ownerDoc = content?.parent.parent
      const boardId = `${ownerDoc?.id}-${content?.id}`

      if (type === 'added' || type === 'modified') {
        dispatch(
          permissionActions.setPermission(boardId, { [data.recipient]: data }),
        )
      }

      if (type === 'removed') {
        dispatch(permissionActions.deletePermission(boardId, data.recipient))
      }
    })
  }
