import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CheckoutActionsIdsEnum,
  CheckoutActions,
} from '@features/checkout/models'

import useActionStepper from '@helpers/useActionStepper'

type Props = {
  initialScreen: CheckoutActionsIdsEnum
  isAdditionalFieldsValid: boolean
  isProductsValid: boolean
  onSave?: () => void
}
const useCheckoutActions = ({
  initialScreen,
  isAdditionalFieldsValid,
  isProductsValid,
  onSave,
}: Props) => {
  const { t } = useTranslation()
  const actions: CheckoutActions = [
    {
      id: CheckoutActionsIdsEnum.PRODUCTS,
      isValid: true,
      title: t('label_products'),
      actionName: t('action_next'),
      onNext: () => nextStep(),
    },
    {
      id: CheckoutActionsIdsEnum.FIELDS,
      title: t('checkout_additional_fields_label'),
      isValid: true,
      actionName: t('action_next'),
      onNext: () => nextStep(),
      onCancel: () => prevStep(),
    },
    {
      id: CheckoutActionsIdsEnum.SETTINGS,
      isValid: true,
      title: t('label_settings'),
      actionName: t('action_done'),
      onNext: () => onSave && onSave(),
      onCancel: () => prevStep(),
    },
  ]

  const { currentAction, prevStep, nextStep } = useActionStepper(
    actions,
    initialScreen,
  )

  const isValid = useMemo(() => {
    if (currentAction.id === CheckoutActionsIdsEnum.FIELDS) {
      return isAdditionalFieldsValid
    }

    if (currentAction.id === CheckoutActionsIdsEnum.PRODUCTS) {
      return isProductsValid
    }

    return true
  }, [currentAction, isAdditionalFieldsValid, isProductsValid])

  return {
    action: {
      ...currentAction,
      isValid,
    },
  } as const
}

export default useCheckoutActions
