import React, { FC, ReactElement } from 'react'
import { useDialog } from 'boards-web-ui'
import Share from './Share'
import useBoard from '../../../hooks/useBoard'
import useSharePublicLink from './hooks/useSharePublicLink'
import { ShareScreens } from './hooks/useShareNav'

interface Props {
  component: ReactElement
  membersScreen?: boolean
}

const ShareButton: FC<Props> = ({ component, membersScreen }: Props) => {
  const { open } = useDialog()
  const { canShare } = useBoard()
  const { publicKeyEnabled } = useSharePublicLink()

  const onClick = () => {
    open(<Share screen={membersScreen ? ShareScreens.MEMBERS : undefined} />, {
      fullScreen: true,
    })
  }

  if (!canShare && !publicKeyEnabled) {
    return null
  }

  return React.cloneElement(component, { onClick })
}

export default ShareButton
