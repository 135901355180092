import { PropsWithChildren, useContext } from 'react'
import { ReactComponent as QuestionMarkIcon } from '../icons/QuestionMark.svg'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import ContainerReferenceContext from '../providers/ContainerReferenceContext'
import { supportsContainerQueries } from '../helper/MediaQueryPolyfill'

type Props = PropsWithChildren<{
  inline?: boolean
  onHover?: () => void
}>

const QuestionMarksTooltip = ({ inline = false, children, onHover }: Props) => {
  const styles: { [key: string]: string } = inline
    ? { display: 'inline-block', verticalAlign: 'middle', height: '22px' }
    : { display: 'flex' }

  const { getPosition } = useContext(ContainerReferenceContext)

  return (
    <Tooltip
      style={styles}
      onHover={onHover}
      getRelativePosition={supportsContainerQueries ? getPosition : undefined}
      data={
        <TooltipContent>
          <div
            style={{
              width: '226px',
              textAlign: 'left',
              padding: '5px 0px',
              lineHeight: '20px',
            }}
          >
            {children}
          </div>
        </TooltipContent>
      }
    >
      <QuestionMarkIcon style={{ cursor: 'pointer' }} />
    </Tooltip>
  )
}

export default QuestionMarksTooltip
