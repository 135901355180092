import { ChangeEvent, FC, memo } from 'react'
import {
  InputSelect,
  InputSelectOption,
  PageNodeProfileVariants,
} from 'boards-web-ui'

import { PageFormChangeHandler } from '@features/PageProfile/models'
import enumKeys from '../../../../utils/enumKeys'

type ProfileVariationPickerProps = {
  label: string
  name: string
  value?: PageNodeProfileVariants
  onChange: PageFormChangeHandler
}
const ProfileVariationPicker: FC<ProfileVariationPickerProps> = ({
  value = PageNodeProfileVariants.v0,
  onChange,
  ...rest
}) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      e.target.value = PageNodeProfileVariants.v0
    }

    if (onChange) {
      onChange(e)
    }
  }
  return (
    <InputSelect
      value={value.toString() || PageNodeProfileVariants.v0}
      onChange={onChangeHandler}
      {...rest}
    >
      {enumKeys(PageNodeProfileVariants).map((variation) => {
        return (
          <InputSelectOption
            value={PageNodeProfileVariants[variation]}
            key={variation}
          >
            {variation.toUpperCase()}
          </InputSelectOption>
        )
      })}
    </InputSelect>
  )
}

export default memo(ProfileVariationPicker)
