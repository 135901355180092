import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonNew,
  DropDown,
  DropDownPosition,
  Menu,
  MenuItem,
} from 'boards-web-ui'

import useConfirmDialog from '@hooks/useConfirmDialog'
import useAccountSubscriptionEvents from '@features/analytics/useAccountSubscriptionEvents'

import styles from './EditPlanDropdown.module.css'

interface Props {
  onChangePlanClick: () => void
  onCancelPlanClick: () => Promise<void>
}
const EditPlanDropdown: FC<Props> = ({
  onChangePlanClick,
  onCancelPlanClick,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const confirmDialog = useConfirmDialog()
  const {
    cancelPlanClickEvent,
    confirmCancelClickEvent,
    dismissCancelClickEvent,
    editPlanClickEvent,
  } = useAccountSubscriptionEvents()

  const closeMenu = () => setIsOpen(false)

  const handleChangePlanClick = () => {
    closeMenu()
    onChangePlanClick()
  }

  const handleCancelPlanClick = async () => {
    closeMenu()

    cancelPlanClickEvent()

    confirmDialog({
      title: t('cancel_popup_title'),
      message: t('cancel_popup_body'),
      cancelText: t('cancel_popup_cancel'),
      confirmText: t('cancel_popup_keep'),
      stacked: true,
      onCancel: async () => {
        setIsLoading(true)
        confirmCancelClickEvent()

        closeMenu()
        await onCancelPlanClick()

        setIsLoading(false)
      },
      onConfirm: () => {
        dismissCancelClickEvent()
      },
    })
  }

  return (
    <DropDown
      position={DropDownPosition.right}
      isOpen={isOpen}
      onToggle={setIsOpen}
      className={styles.DropdownMenu}
      content={
        <Menu as="div">
          <MenuItem as="button" type="button" onClick={handleChangePlanClick}>
            <Trans i18nKey={'change_plan_button'} />
          </MenuItem>
          <MenuItem as="button" type="button" onClick={handleCancelPlanClick}>
            <span className={styles.CancelSubscription}>
              <Trans i18nKey={'cancel_plan_button'} />
            </span>
          </MenuItem>
        </Menu>
      }
    >
      <ButtonNew
        color={'secondary'}
        size={'medium'}
        isActive={isOpen}
        isLoading={isLoading}
        onClick={editPlanClickEvent}
      >
        {t('edit_plan_button')}
      </ButtonNew>
    </DropDown>
  )
}

export default EditPlanDropdown
