import { setMarketingInfo } from '../../../../_firebase/generalFunctions'
import { ProfilingMarketingStatesEnum } from '../models'

const useProfilingAPI = () => {
  const showMarketingForm = () => {
    setMarketingInfo({
      status: ProfilingMarketingStatesEnum.PRESENTED,
    })
  }

  const updateMarketingForm = (answers: { [id: string]: string }) => {
    setMarketingInfo(answers)
  }

  const saveMarketingForm = (answers: { [id: string]: string }) => {
    setMarketingInfo({
      status: ProfilingMarketingStatesEnum.COMPLETED,
      ...answers,
    })
  }

  return {
    showMarketingForm,
    updateMarketingForm,
    saveMarketingForm,
  }
}

export default useProfilingAPI
