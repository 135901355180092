import { DocumentSnapshot } from 'firebase/firestore'
import { BoardInviteLink } from '../../../app/models/boardInviteLink'

const DEFAULT_INVITE = {
  key: '',
  enabled: false,
}

export const transformLinkInvite = (
  doc: DocumentSnapshot,
): BoardInviteLink | null => {
  const invite = doc.data()

  if (invite) {
    return {
      ...DEFAULT_INVITE,
      ...invite,
    } as BoardInviteLink
  }

  return null
}
