import React from 'react'
import { useTranslation } from 'react-i18next'
import VideoInput from './VideoInput'

interface Props {
  hasError: boolean
  value: string
  onInputChange: (text: string) => void
}

const FormAddVideo = React.forwardRef<HTMLInputElement, Props>(
  ({ hasError, value, onInputChange }, ref) => {
    const { t } = useTranslation()

    return (
      <VideoInput
        ref={ref}
        value={value}
        placeholder={t('hints_paste_YouTube_or_Vimeo_link')}
        onChange={onInputChange}
        readOnly={false}
        hasError={hasError}
        hasUnderline
      />
    )
  },
)

export default FormAddVideo
