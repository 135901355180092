import React, { FC, PropsWithChildren, ReactElement } from 'react'

import styles from './ModalWithEmojiHeader.module.css'

type Props = PropsWithChildren<{
  close?: ReactElement
  emoji: ReactElement | string
  actions: ReactElement
}>
const ModalWithEmojiHeader: FC<Props> = ({
  close,
  emoji,
  actions,
  children,
}) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        {close ? <span className={styles.CloseButton}>{close}</span> : null}
        <span className={styles.HeaderIcon}>
          <span className={styles.Icon}>{emoji}</span>
        </span>
      </div>
      <div className={styles.Body}>{children}</div>
      {actions}
    </div>
  )
}

export default React.memo(ModalWithEmojiHeader)
