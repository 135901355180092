import ContentItem from './ContentItem'
import ContentItemCover, { CoverBackground } from './ContentItemCover'
import NoTitle from './NoTitle'
import { NodeType } from '../../../models'

interface Props {
  src: string
  text: string
  count: number
}

const ContentImage = ({ src, text, count }: Props) => {
  const textElement = text || <NoTitle type={NodeType.IMAGE} />

  return (
    <ContentItem text={textElement} count={count}>
      <ContentItemCover
        background={CoverBackground.GRAY}
        cover={src}
        alt={text}
      />
    </ContentItem>
  )
}

export default ContentImage
