import { useTranslation } from 'react-i18next'
import { PagesBoardKeyIcon } from 'boards-web-ui'

import { ContentSize } from '../../../../ui/components/AutoGrowingInput'
import { Folder } from '../../../models'
import ContentTitle from '../../toolbar/components/ContentTitle'
import PageLink from './PageLink'

import styles from './PageTitle.module.css'

interface Props {
  folder: Folder
  newTitle: string
  onDoneClick?: () => void
  autoPopulatePageTitle?: (title?: string) => void
  setNewTitle: (title: string) => void
}
const PageTitle = ({
  folder,
  newTitle,
  onDoneClick,
  autoPopulatePageTitle,
  setNewTitle,
}: Props) => {
  const { t } = useTranslation()
  const hideTitleTooltip = (newTitle || '').length === 0

  return (
    <div className={styles.Root}>
      <div className={styles.Title}>
        <PagesBoardKeyIcon className={styles.PagesBoardKeyIcon} />
        <ContentTitle
          title={newTitle}
          readOnly={false}
          size={ContentSize.Big}
          autofocus={false}
          hideTitleTooltip={hideTitleTooltip}
          onBlur={autoPopulatePageTitle}
          onEnter={autoPopulatePageTitle}
          onChange={setNewTitle}
          tooltip={t('desktop_hover_rename_page')}
          placeholder={t('page_web_name_placeholder')}
        />
      </div>

      <PageLink folder={folder} onDoneClick={onDoneClick} />
    </div>
  )
}

export default PageTitle
