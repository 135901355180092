import { PropsWithChildren } from 'react'

import styles from './Button.module.css'

type Props = PropsWithChildren<{
  className?: string
  onClick: () => void
  disabled?: boolean
}>

const Button: React.FC<Props> = ({
  className,
  onClick,
  children,
  disabled,
}: Props) => (
  <button
    className={`${styles.Button} ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)

export default Button
