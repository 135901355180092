import { useDispatch } from 'react-redux'

import { useAuth } from '@hooks/useAuth'

import { userActions } from '../../../../actions'

import { useProfileInformation } from './useProfileInformation'

export const useProfileName = () => {
  const { profile, loaded } = useProfileInformation()
  const { user } = useAuth()

  const dispatch = useDispatch()

  const updateProfileName = (name: string | undefined) =>
    dispatch(userActions.setUserProperties(name))

  return {
    loaded,
    name: profile?.name || user?.email || '',
    updateProfileName,
  }
}
