import { useEffect } from 'react'
import { VideoType, urlHelper } from 'boards-web-ui'

import useAPI, { FUNCTIONS } from './useAPI'
import useLocalStorage from './useLocalStorage'
import { getInfo } from '../api/YoutubeAPI'
import useSessionStorage from './useSessionStorage'

interface IUseVideo {
  thumbnail: string | undefined
  title: string | undefined
  isLoading: boolean
  hasError: boolean
  fetchData: () => void
}

interface IResolution {
  height: number
  width: number
  url: string
}

export interface IYoutubeVideo {
  categoryId: string
  channelId: string
  channelTitle: string
  description: string
  title: string
  liveBroadcastContent: string
  publishedAt: string
  tags: string[]
  thumbnails: {
    default: IResolution
    high: IResolution
    maxres: IResolution
    medium: IResolution
    standard: IResolution
  }
}

export interface IVideo {
  title: string
  thumbnail: string
}

const useVideo = (url: string, sync = true): IUseVideo => {
  const videoDetails = urlHelper.getVideoDetails(url)
  const key = `video.cover-${videoDetails?.id}`
  const [hasError, setHasError] = useSessionStorage(key, false)
  const [video, setVideo] = useLocalStorage<IVideo | undefined>(key, undefined)
  const {
    data,
    hasError: hasApiError,
    isLoading,
    fetchData,
  } = useAPI<IYoutubeVideo | IVideo>(
    videoDetails?.type === VideoType.VIMEO
      ? FUNCTIONS.GET_VIDEO_VIMEO
      : FUNCTIONS.GET_VIDEO_YOUTUBE,
    videoDetails?.id || '',
    false,
  )

  useEffect(() => {
    setHasError(videoDetails?.id === undefined)
  }, [url, videoDetails?.id, setHasError])

  useEffect(() => {
    if (videoDetails?.id === undefined) {
      setHasError(true)
    }
    if (sync && !video && !hasError && videoDetails?.id) {
      fetchData()
    }
  }, [sync, url, videoDetails?.id, video, hasError, fetchData, setHasError])

  useEffect(() => {
    const handleVimeo = () => setVideo(data as IVideo)
    const handleYoutube = () => {
      setVideo(getInfo(data as IYoutubeVideo) as IVideo)
    }

    if (data === undefined || videoDetails?.id === undefined) return

    try {
      if (videoDetails?.type === VideoType.VIMEO) {
        handleVimeo()
        setHasError(false)
        return
      }

      handleYoutube()
    } catch (error) {
      setHasError(true)
    }
    // eslint-disable-next-line
  }, [data])

  return {
    thumbnail: video?.thumbnail,
    title: video?.title,
    isLoading,
    hasError: hasApiError || hasError,
    fetchData,
  }
}

export default useVideo
