import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from '@hooks/useAuth'
import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'

import { FirebaseError } from '@firebase/util'
import { EmailPasswordSignIn } from '_firebase/models/auth'
import { firebaseEventsAsyncActions } from '../../../../actions'
import useIsOnline from '../../../../hooks/useIsOnline'
import { SIGN_IN, EDITOR, WELCOME } from '../../../constants/routes'
import { isMobile } from '../../../../utils/deviceParser'

// eslint-disable-next-line
export const useSignIn = (setError: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const { signIn } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const isOnline = useIsOnline()
  const { routeHasRedirect, redirectToOverwriteRoute } =
    useOverwriteRouteRedirect()

  const redirect = searchParams.get('redirect')
  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')

  useEffect(() => {
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_screen_view', {
        screen: 'sign_in',
      }),
    )
  }, [dispatch])

  const onError = (errorField: string, message: string) => {
    setError(errorField, {
      type: 'server',
      message,
    })
  }

  const onSignIn = async (data: EmailPasswordSignIn) => {
    setSubmitting(true)
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_button_click', {
        action: 'sign_in',
      }),
    )

    try {
      await signIn(data)
      dispatch(firebaseEventsAsyncActions.logEvent('app_sign_in'))

      if (routeHasRedirect(SIGN_IN)) {
        redirectToOverwriteRoute()
        return
      }

      if (isMobile) {
        navigate(WELCOME)
      } else {
        if (redirect) {
          navigate(redirect)
          return
        }
        if (shortcutId) {
          navigate(
            `/?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}`,
          )
          return
        }
        navigate(EDITOR)
      }
    } catch (error) {
      if (!isOnline) {
        setError('password', t('optimistic_ui_failed'))
        return
      }

      if (error instanceof FirebaseError) {
        onError('password', t([`errors.${error.code}`, 'errors.default']))
        dispatch(
          firebaseEventsAsyncActions.logEvent('app_error', {
            type: 'sign_in_failed',
            code: error.code,
          }),
        )
      }

      setSubmitting(false)
    }
  }

  return {
    submitting,
    onError,
    onSignIn,
  }
}
