import { Dispatch, SetStateAction, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { VideoMetaData } from '@hooks/useUploadFileMetadata'
import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { videoActions } from '../../../../../actions'
import { Node, NodeType } from '../../../../models'
import { configurationObjectFromEnv } from '../../../../../utils/configurationObjectFromEnv'
import { ENV_KEYS } from '../../../../../utils/envKeys'

const { videoUrl } = configurationObjectFromEnv({
  videoUrl: ENV_KEYS.VIDEO_URL,
})

export const useUploadSuccessfully = ({
  setUploading,
  videoMetadata,
  onSave,
  setNode,
  isTryFree,
  title = '',
  currentNodeId,
}: {
  setUploading: Dispatch<SetStateAction<boolean>>
  videoMetadata?: VideoMetaData
  setNode: Dispatch<SetStateAction<Node | undefined>>
  onSave: ICreateOrUpdateNode
  isTryFree?: boolean
  title?: string
  currentNodeId?: string
}) => {
  const dispatch = useDispatch()

  return useMemo(
    () => (assetId?: string) => {
      if (!videoMetadata) {
        return
      }

      setUploading(false)

      const text = `${videoUrl}/${assetId}.m3u8`
      dispatch(videoActions.setVideoStreamingUrl(text))

      const nodeProps = {
        title,
        text,
        type: NodeType.VIDEO_STREAMING,
        thumbnail: '',
        isProcessing: true,
        width: videoMetadata.width,
        height: videoMetadata.height,
      }

      const id =
        onSave(nodeProps, {
          try_free: Boolean(isTryFree).toString(),
        }) || currentNodeId

      if (typeof id === 'string') {
        setNode({ ...nodeProps, id })
      }
    },
    [
      currentNodeId,
      dispatch,
      isTryFree,
      onSave,
      setNode,
      setUploading,
      title,
      videoMetadata,
    ],
  )
}
