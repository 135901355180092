import { useLogEvents } from '@features/analytics/useLogEvents'

const useBannerEvents = () => {
  const { trigger } = useLogEvents()

  const appPageRemoveBannerClick = (
    boardId: string,
    keyId: string,
    linkShortcut: string,
    isPremium: boolean,
  ) => {
    trigger('app_page_remove_banner_click', {
      board_id: boardId,
      key_id: keyId,
      link_shortcut: linkShortcut,
      has_premium: `${isPremium}`,
    })
  }

  const appPageBannerClick = (
    boardId: string,
    keyId: string,
    linkShortcut: string,
  ) => {
    trigger('app_page_banner_click', {
      board_id: boardId,
      key_id: keyId,
      link_shortcut: linkShortcut,
    })
  }

  return {
    appPageRemoveBannerClick,
    appPageBannerClick,
  } as const
}

export default useBannerEvents
