import { DocumentSnapshot } from 'firebase/firestore'

import { parseBoolean } from '@helpers/bool'
import {
  CLIENT_CONFIG_FEATURES,
  DefaultClientConfig,
  EmailAdressClientConfig,
} from 'app/models/clientConfig'
import { initialState } from 'reducers/initialState'

export const transformEmailAddressDoc = (
  doc: DocumentSnapshot,
): EmailAdressClientConfig => {
  const data = doc.data()

  return {
    format: data?.format
      ? new RegExp(data?.format)
      : initialState.app.clientConfig[CLIENT_CONFIG_FEATURES.EMAIL_ADDRESS]
          .format,
    frequentlyUsedDomains: data?.frequentlyUsedDomains || [],
    topLevelDomains: data?.topLevelDomains || [],
  }
}

export const transformClientConfigurationDoc = (
  doc: DocumentSnapshot,
): DefaultClientConfig => {
  const data = doc.data()

  return {
    reverseTrial: {
      isActive: parseBoolean(data?.reverseTrial?.isActive, false),
    },
  }
}
