import React from 'react'

import BoardsPagesSection from '@features/boardsMenu/components/BoardsPagesSection'
import ResizablePanel from './components/ResizablePanel'
import SideBarHeader from './components/SideBarHeader'
import SideBarBanners from './components/SideBarBanners'
import SideBarBody from './components/SideBarBody'
import SideBarFooter from './components/SideBarFooter'

import styles from './SideBar.module.css'

export interface SideBarProps {
  setOpen: (value: boolean) => void
}
const SideBar: React.FC<SideBarProps> = ({ setOpen }: SideBarProps) => {
  return (
    <ResizablePanel className={styles.Sidebar} as="aside">
      <SideBarHeader className={styles.Header} />
      <SideBarBanners setOpen={setOpen} />
      <BoardsPagesSection />
      <SideBarBody
        className={styles.Body}
        titleClassName={styles.HiddenButton}
      />
      <SideBarFooter className={styles.Footer} />
    </ResizablePanel>
  )
}

export default React.memo(SideBar)
