import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import {
  ErrorIndicator,
  ErrorIndicatorSize,
  NODE_LINK_TYPES,
} from 'boards-web-ui'

import { move } from '@helpers/array'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import useEditNode from '@features/nodeEditor/useEditNode'

import { IsMissedPageProperties } from 'utils/findMissedFieldsInForm'

import useRouterParams from '../../../../hooks/useRouterParams'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import useNode from '../../board/hooks/useNode'

import { BoardMode } from '../../board/models/nodes'
import { Folder, Node, NodeType } from '../../../models'

import EmptyContent, {
  EmptyBoardVariants,
} from '../../../../components/EmptyContent'
import CreatePageBanner from './CreatePageBanner'
import PageNode from '../../nodes/PageNode'
import ListItem from './ListItem'

import useBrandedPagesBanner from '../hooks/useBrandedPagesBanner'

import styles from './ListItems.module.css'

interface Props {
  mode: BoardMode
  folder: Folder
  updateNode: (id: string, node: INodeProps) => void
  deleteNode?: (node: Node) => void
  missedPageProperties?: IsMissedPageProperties
}

const ListItems: FC<Props> = ({
  mode,
  folder,
  updateNode,
  deleteNode,
  missedPageProperties,
}) => {
  const { t } = useTranslation()
  const { boardId, folderId } = useRouterParams()

  const editNode = useEditNode(boardId, folder.id, updateNode, true)
  const confirmDialog = useConfirmDialog()
  const { moveNode } = useNode({ boardId, folderId: folder.id, mode })
  const [items, setItems] = useState<Node[]>(folder.content)
  const { onDeleteBrandedBanner, unbrandedPages } = useBrandedPagesBanner(
    boardId,
    folderId,
    folder,
  )

  useEffect(() => {
    setItems(folder.content)
  }, [folder.content, setItems])

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((prevCards) => move(prevCards, dragIndex, hoverIndex))
  }, [])

  const moveItemAPI = useCallback(
    (nodeId: string, beforeIndex: number) => {
      const beforeNode = items[beforeIndex + 1]
      moveNode(nodeId, beforeNode?.id)
    },
    [items, moveNode],
  )

  const handleDeleteNode = (node: Node) => {
    confirmDialog({
      title: t('confirmation_message_delete_selected_key'),
      message: t('dialog_delete_this_key'),
      confirmText: t('action_delete'),
      cancelText: t('action_cancel'),
      onConfirm: () => {
        if (deleteNode) deleteNode(node)
      },
    })
  }

  const getButtonLinkTitle = (nodeLinkType: NODE_LINK_TYPES) => {
    const linkTitlesMap = {
      [NODE_LINK_TYPES.REGULAR]: 'page_section_header_link',
      [NODE_LINK_TYPES.ZOOM]: 'page_section_header_zoom',
      [NODE_LINK_TYPES.CALENDLY]: 'page_section_header_calendly',
      [NODE_LINK_TYPES.WHATS_APP]: 'page_section_header_whatsapp',
      [NODE_LINK_TYPES.FACEBOOK]: 'page_section_header_messenger',
      [NODE_LINK_TYPES.TELEGRAM]: 'page_section_header_telegram',
      [NODE_LINK_TYPES.PHONE]: 'page_section_header_phone',
      [NODE_LINK_TYPES.SMS]: 'page_section_header_sms',
      [NODE_LINK_TYPES.VENMO]: 'page_section_header_venmo',
      [NODE_LINK_TYPES.CASH]: 'page_section_header_cashapp',
      [NODE_LINK_TYPES.PAYPAL]: 'page_section_header_propay',
      [NODE_LINK_TYPES.MAIL]: 'page_section_header_email',
    }

    return t(linkTitlesMap[nodeLinkType])
  }

  const getItemTitle = (node: Node) => {
    const itemTitlesMap = {
      [NodeType.IMAGE]: 'page_section_header_image',
      [NodeType.PARAGRAPH]: 'page_section_header_paragraph',
      [NodeType.TEXT]: 'page_section_header_paragraph',
      [NodeType.VIDEO]: 'page_section_header_video',
      [NodeType.VIDEO_STREAMING]: 'page_section_header_video',
      [NodeType.HEADLINE]: 'page_section_header_headeline',
      [NodeType.PROFILE]: 'page_section_header_profile',
      [NodeType.PDF]: 'page_section_header_pdf',
      [NodeType.FORM]: 'page_section_header_form',
      [NodeType.CHECKOUT]: 'page_section_header_checkout',
    }

    if (node.type === NodeType.BUTTON) {
      return getButtonLinkTitle(node.link_type || NODE_LINK_TYPES.REGULAR)
    }

    if (Object.prototype.hasOwnProperty.call(itemTitlesMap, node.type)) {
      return t(itemTitlesMap[node.type as keyof typeof itemTitlesMap])
    }

    return ''
  }

  if (items.length === 0) {
    return <EmptyContent variant={EmptyBoardVariants.pageEditor} />
  }

  return (
    <div className={styles.Content}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.Root}>
          {items.map((item: Node, index: number) => {
            const withIndicator = !!missedPageProperties?.[item.id]
            return (
              <ListItem
                key={item.id}
                id={item.id}
                title={getItemTitle(item)}
                index={index}
                disabled={mode !== BoardMode.write}
                move={moveItem}
                end={moveItemAPI}
                withIndicator={withIndicator}
                onClick={() => editNode(item, missedPageProperties?.[item.id])}
                onDelete={() => handleDeleteNode(item)}
              >
                <PageNode node={item} />
                {withIndicator ? (
                  <ErrorIndicator
                    rounded
                    size={ErrorIndicatorSize.SMALL}
                    className={styles.Indicator}
                    onClick={(e) => {
                      e.stopPropagation()
                      editNode(
                        item,
                        missedPageProperties?.[item.id],
                        'settings',
                      )
                    }}
                  >
                    {t('info_missing_add_missing_details')}
                  </ErrorIndicator>
                ) : null}
              </ListItem>
            )
          })}

          {folder.content.length > 0 && !unbrandedPages ? (
            <CreatePageBanner
              unbrandedPages={unbrandedPages}
              onDelete={onDeleteBrandedBanner}
            />
          ) : null}
        </div>
      </DndProvider>
    </div>
  )
}

export default ListItems
