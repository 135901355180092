import { SyntheticEvent } from 'react'
import { ReactComponent as RadioSelectedIcon } from '../icons/RadioSelected.svg'
import { ReactComponent as RadioUnselectedIcon } from '../icons/RadioUnselected.svg'
import { ReactComponent as RadioUnselectedHoverIcon } from '../icons/RadioUnselectedHover.svg'

import styles from './NodeSelectionRadio.module.css'

interface RadioProps {
  className?: string
  classNameUnselected?: string
  classNameUnselectedHover?: string
  selected: boolean
  onClick: (e: SyntheticEvent) => void
}

const NodeSelectionRadio = ({
  className,
  classNameUnselected,
  classNameUnselectedHover,
  selected,
  onClick,
}: RadioProps) => (
  <div className={`${styles.Radio} ${className}`} onClick={onClick}>
    {selected ? (
      <RadioSelectedIcon className={styles.RadioSelectedIcon} />
    ) : (
      <>
        <RadioUnselectedIcon
          className={`${styles.RadioUnselectedIcon} ${classNameUnselected}`}
        />
        <RadioUnselectedHoverIcon
          className={`${styles.RadioUnselectedHoverIcon} ${classNameUnselectedHover}`}
        />
      </>
    )}
  </div>
)

export default NodeSelectionRadio
