import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

enum TargetNames {
  howToUseClick = 'how_to_use',
  EDIT_NAME = 'edit_name',
  ADD_NAME = 'add_name',
}

export const useProfileEvents = () => {
  const { trigger, buttonClick } = useLogEvents()

  const onHowToUseClick = () => buttonClick(TargetNames.howToUseClick)
  const appAddName = () =>
    trigger(EventCategories.APP_EDIT_NAME, { type: 'add' })
  const appEditName = () =>
    trigger(EventCategories.APP_EDIT_NAME, { type: 'edit' })
  const addNameClick = () => buttonClick(TargetNames.ADD_NAME)
  const editNameClick = () => buttonClick(TargetNames.EDIT_NAME)

  return {
    onHowToUseClick,
    addNameClick,
    appAddName,
    appEditName,
    editNameClick,
  } as const
}
