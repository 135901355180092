/* eslint no-console: ["error", { allow: ["error"] }] */
import { VideoType } from 'boards-web-ui'
import settings from '../../../settings'

export const arrayMove = <T>(arr: T[], oldIndex: number, newIndex: number) => {
  const copy = [...arr]
  copy.splice(newIndex, 0, copy.splice(oldIndex, 1)[0])
  return copy
}

export const getVideoPlaceholder = (type: string | undefined) => {
  if (type === VideoType.VIMEO) {
    return settings.nodeVimeoPlaceholder
  }
  return settings.nodeYoutubePlaceholder
}
