import { httpsCallable, Functions } from 'firebase/functions'
import { APP_VERSION } from '@helpers/constants'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { Node } from '../app/models'

export const callFirebaseFunction = async <T>(
  functions: Functions,
  firebaseFunctionName: string,
  parameters?: object,
): Promise<T | undefined> => {
  const callableReturnMessage = httpsCallable(functions, firebaseFunctionName)

  return callableReturnMessage({ ...parameters, client: APP_VERSION }).then(
    (response) => response.data as T | undefined,
  )
}

const clearProperty = <T extends INodeProps | Node>(property: T): T => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { integrationState, ...cleanProperty } = property

  return cleanProperty as T
}

export const clearProperties = <T extends INodeProps | Node>(
  properties: T,
): T => {
  let cleanProperties: T = clearProperty<T>(properties)

  if (
    cleanProperties &&
    typeof (cleanProperties as INodeProps).content !== 'undefined'
  ) {
    cleanProperties = {
      ...cleanProperties,
      content: (cleanProperties as INodeProps)?.content?.map((property) =>
        clearProperty(property),
      ),
    }
  }

  return cleanProperties
}
