import React, { useEffect, useState } from 'react'

import { valBetween } from '@helpers/numbers'
import PreviewPageBanner from '@features/editorPages/components/PreviewPageBanner'
import useRouterParams from '@hooks/useRouterParams'

import clsx from 'clsx'
import { CSS_TRANSITION_TIME } from 'constants/CSSTransitionTime'
import { Folder } from '../../../models'
import useBrandedPagesBanner from '../hooks/useBrandedPagesBanner'

import styles from './ScreenFrame.module.css'

type Props = {
  folder: Folder
  height: number
  minHeight?: number
  maxHeight?: number
  screenHeight: number
  children?: React.ReactNode
}
const frameBorder = 10

const ScreenFrame: React.FC<Props> = ({
  folder,
  minHeight,
  maxHeight,
  height,
  screenHeight,
  children,
}) => {
  const { boardId, folderId } = useRouterParams()
  const { onDeleteBrandedBanner, unbrandedPages } = useBrandedPagesBanner(
    boardId,
    folderId,
    folder,
    true,
  )
  const frameHeight = valBetween(height - frameBorder * 2, minHeight, maxHeight)
  const scale = frameHeight / screenHeight

  const [containerClassName, setContainerClassName] = useState<string>(
    clsx(styles.Container, styles.WithoutTransition),
  )
  useEffect(() => {
    // includes time from current css time(0.3s) + overlay transition time(0.3) + CSS_TRANSITION_TIME
    const transitionTime = 300 + 300 + CSS_TRANSITION_TIME
    const timeOutIt = setTimeout(() => {
      setContainerClassName(clsx(styles.Container))
    }, transitionTime)

    return () => clearTimeout(timeOutIt)
  }, [])

  return (
    <div className={styles.Frame}>
      <div className={styles.Root} style={{ height: frameHeight }}>
        <div
          className={containerClassName}
          style={{
            height: screenHeight,
            transform: `scale(${scale})`,
            width: '380px',
          }}
        >
          <div className={styles.Screen}>
            <div className={styles.Scroll}>
              {children}
              {folder.content.length > 0 && !unbrandedPages ? (
                <>
                  <div className={styles.BannerShadow} />
                  <PreviewPageBanner
                    unbrandedPages={unbrandedPages}
                    onDelete={onDeleteBrandedBanner}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScreenFrame
