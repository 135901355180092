import { InsightsResultState } from '../models/insightsResult'
import { InsightsState } from '../models/insights'
import { transformDataToChart } from './dataToChartStateTransformer'
import { transformDataToTopContent } from './topContentTransformer'
import { transformDataToIndicator } from './indicatorTransformer'
import { InsightsFilter } from '../models/filter'
import { Node } from '../../../models'

export const transformAndFilterInsightsData = (
  data: InsightsResultState,
  filter: InsightsFilter,
  getNode: (nodeId: string) => Node,
): InsightsState => ({
  boardCreateTime: new Date(data.boardCreateTime),
  hasData: getHasData(data),
  activeMembers: transformDataToIndicator(data.memberActivitySummary, filter)
    ?.users.all,
  dailyActiveMembers: transformDataToChart(
    data.memberActivitySummary,
    filter,
    true,
  ),
  contentInteractions: transformDataToIndicator(data.usageSummary, filter)
    ?.total.all,
  dailyContentInteractions: transformDataToChart(
    data.usageSummary,
    filter,
    false,
  ),
  topContentItems: transformDataToTopContent(
    data.topUsageNodes,
    filter,
    getNode,
  ),
})

const getHasData = (data: InsightsResultState) => {
  return data?.memberActivitySummary?.daily?.length > 0
}
