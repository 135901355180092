import { useDialog } from 'boards-web-ui'

import EditVideo from '../components/EditVideo'

import { IUseSaveNode } from '../models/IUseEditNode'
import { Node } from '../../../models'

const useEditVideo: IUseSaveNode = (save, isPageEditor) => {
  const { open } = useDialog()

  const editVideo = (node: Node) => {
    open(
      <EditVideo
        initialText={node.text || ''}
        initialTitle={node.title || ''}
        onSave={(n) => save(node.id, n)}
        isPageEditor={isPageEditor}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return editVideo
}

export default useEditVideo
