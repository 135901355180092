import { CurrencyCode } from '@paddle/paddle-js'

const PADDLE_SUPPORTED_CURRENCIES = [
  'USD',
  'EUR',
  'GBP',
  'JPY',
  'AUD',
  'CAD',
  'CHF',
  'HKD',
  'SGD',
  'SEK',
  'ARS',
  'BRL',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'HUF',
  'ILS',
  'INR',
  'KRW',
  'MXN',
  'NOK',
  'NZD',
  'PLN',
  'RUB',
  'THB',
  'TRY',
  'TWD',
  'UAH',
  'ZAR',
] as const

const isCurrencySupportedByPaddle = (
  currency?: string,
): currency is CurrencyCode => {
  return PADDLE_SUPPORTED_CURRENCIES.includes(currency as CurrencyCode)
}

export default isCurrencySupportedByPaddle
