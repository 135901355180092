import { Node } from '../../../models'

import styles from './NodeParagraph.module.css'

interface Props {
  node: Node
}
const NodeParagraph = ({ node }: Props) => {
  return <div className={styles.Root}>{node.text}</div>
}

export default NodeParagraph
