import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { randomEmojiExcluding } from '@helpers/randomEmojis'
import { useAuth } from '@hooks/useAuth'
import useIsApiAvailableOrNotify from '@hooks/useIsApiAvailableOrNotify'
import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'

import { boardActions } from '../../../../actions'

import {
  boardId as getBoardId,
  boardIndicator,
  newKey,
  rootFolderIndicator,
} from '../../../../helpers/NodeIdGenerator'

const useCreateBoard = () => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { openPaywall } = usePaywall()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { limitReached } = useUserFeatures(Features.boards)

  const createFakeBoard = (
    currentUserId: string,
    newBoardId: string,
    key: string | undefined,
    icon: string,
  ) => {
    dispatch(
      boardActions.createBoardFake(currentUserId, newBoardId, key, {
        icon,
        title: undefined,
      }),
    )
  }

  const createBoard = (onCreate?: () => void) => {
    if (!isApiAvailableOrNotify()) return

    if (limitReached) {
      openPaywall({ source: PaywallSourceEnum.CREATE_BOARD_HOMESCREEN })
      return
    }

    const icon = randomEmojiExcluding([])
    const currentUserId = user?.uid
    const key = newKey()
    const newBoardId = getBoardId(key)

    createFakeBoard(currentUserId as string, newBoardId, key, icon)
    // Todo refactoring when board API will be used inside Hook
    navigate(
      `/${currentUserId}-${newBoardId}/${newBoardId.replace(
        boardIndicator,
        rootFolderIndicator,
      )}`,
    )

    if (onCreate) onCreate()
  }

  return createBoard
}

export default useCreateBoard
