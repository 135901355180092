import { PropsWithChildren } from 'react'

import styles from './Card.module.css'

type Props = PropsWithChildren<{
  className?: string
}>

const Card: React.FC<Props> = ({ className, children }) => {
  return <div className={[styles.Root, className].join(' ')}>{children}</div>
}

export default Card
