import { ChromeExtensionApiConfig } from './types'

import { ENV_KEYS } from '../../../utils/envKeys'
import { configurationObjectFromEnv } from '../../../utils/configurationObjectFromEnv'

const config = configurationObjectFromEnv<ChromeExtensionApiConfig>({
  id: ENV_KEYS.CHROME_EXTENSION_ID,
})

export const ID_SEPARATOR = '-'

export default config
