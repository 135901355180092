import { useTranslation } from 'react-i18next'
import { NODE_LINK_TYPES, SocialLinkIcon } from 'boards-web-ui'

import { Node } from '../../../models'

import styles from './NodeButton.module.css'

interface Props {
  node: Node
}

const NodeButton = ({ node }: Props) => {
  const { t } = useTranslation()
  let displayTitle = node.title
  if (!displayTitle?.trim()) {
    if (node.link_type === NODE_LINK_TYPES.REGULAR) {
      displayTitle = t('button_link_label_auto_populate')
    } else {
      displayTitle = t('page_my_button')
    }
  }

  return (
    <div className={styles.Root}>
      <div className={styles.Flex}>
        {node.link_type && node.link_type !== NODE_LINK_TYPES.REGULAR && (
          <span className={styles.Icon}>
            <SocialLinkIcon type={node.link_type} color="transparent" small />
          </span>
        )}
        <span className={styles.Title}>{displayTitle}</span>
      </div>
    </div>
  )
}

export default NodeButton
