import React, { PropsWithChildren, ReactElement } from 'react'

import styles from './Button.module.css'

const sizes = {
  sm: styles.Small,
  md: styles.Medium,
  lg: styles.Large,
} as { [key: string]: string }

type Props = PropsWithChildren<{
  size?: string
  color?: string
  variant?: string
  loading?: boolean
  disabled?: boolean
  fullWidth?: boolean
  startIcon?: ReactElement
  className?: string
  onClick: () => void
}>

const Button: React.FC<Props> = ({
  children,
  size = 'md',
  startIcon,
  color = 'primary',
  variant = 'contained',
  loading = false,
  fullWidth = false,
  onClick,
  className,
  ...props
}: Props) => {
  const classes = [styles.Root, className]

  classes.push(sizes[size])
  classes.push(styles[`${color}-${variant}`])

  if (props.disabled) {
    classes.push(styles.Disabled)
  }
  if (loading) {
    classes.push(styles.Disabled)
    classes.push(styles.Loading)
  }
  if (fullWidth) {
    classes.push(styles.FullWidth)
  }

  return (
    <button className={classes.join(' ')} {...props} onClick={onClick}>
      {startIcon && <span className={styles.StartIcon}>{startIcon}</span>}
      {children}
      {loading && (
        <div className={styles.Spinner}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </button>
  )
}

export default Button
