import { DocumentData } from 'firebase/firestore'
import { isContentNodeId } from '@helpers/NodeIdGenerator'
import {
  isAudioNode,
  isImageNode,
  isLinkNode,
  isPdfNode,
  isVideoNode,
  isVideoStreamingNode,
} from '@helpers/nodeType'

import { Node, NodeType } from '../../../app/models'

export const transformNodeFolder = (
  id: string,
  node: DocumentData,
  properties: DocumentData,
): Node => {
  if (!isContentNodeId(id)) {
    return {
      id,
      type: node?.type === NodeType.PAGE ? NodeType.PAGE : NodeType.FOLDER,
      icon: node.icon,
      title: node.title,
      text: node.text,
      thumbnail: node.thumbnail,
      shortcut: node.shortcut,
    } as Node
  }

  if (isImageNode(node)) {
    return {
      id,
      title: node.title,
      text: node.text,
      type: NodeType.IMAGE,
      image: node.text,
      width: properties?.w,
      height: properties?.h,
    } as Node
  }

  if (isAudioNode(node)) {
    return {
      id,
      title: node.title,
      text: node.text,
      type: NodeType.AUDIO,
      duration: parseInt(properties?.d, 10),
      size: properties?.s,
      shortcut: node.shortcut,
    } as Node
  }

  if (isVideoNode(node)) {
    return {
      id,
      title: node.title,
      text: node.text,
      type: NodeType.VIDEO,
      thumbnail: properties?.i,
    } as Node
  }

  if (isVideoStreamingNode(node)) {
    return {
      id,
      type: NodeType.VIDEO_STREAMING,
      text: node.text,
      title: node.title,
      shortcut: node.shortcut,
      thumbnail: properties?.i,
      isProcessing:
        properties?.x === 'processing' ||
        (properties?.x === undefined && properties?.w === undefined),
      width: properties?.w,
      height: properties?.h,
    } as Node
  }

  if (isPdfNode(node)) {
    return {
      id,
      type: NodeType.PDF,
      text: node.text,
      title: node.title,
      thumbnail: properties?.i,
    } as Node
  }

  if (isLinkNode(node)) {
    return {
      id,
      type: NodeType.LINK,
      text: node.text,
      title: node.title,
    } as Node
  }

  return {
    id,
    type: NodeType.TEXT,
    text: node.text,
    title: node.title,
  } as Node
}
