import { FunctionsError } from '@firebase/functions'

type FirebaseFunctionsError = FunctionsError & {
  details: {
    error_code?: string
  }
}
const isFunctionsError = (error: unknown): error is FirebaseFunctionsError => {
  return (error as FunctionsError).details !== undefined
}

export default isFunctionsError
