import { useQuery } from 'react-query'

import { getTheme } from '../_firebase'

const useWebPageTheme = (themeId?: string) =>
  useQuery(
    ['theme', themeId],
    () => (themeId ? getTheme(themeId) : undefined),
    {
      staleTime: aDay,
      cacheTime: aDay,
      retry: 2,
      onError: () => {
        /* eslint no-console: ["error", { allow: ["error"] }] */
        console.error('Error fetching theme', themeId)
      },
    },
  )

const aDay = 24 * 60 * 60 * 1000

export default useWebPageTheme
