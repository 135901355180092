import { useTranslation } from 'react-i18next'

export const useUpdatesTranslations = () => {
  const { t } = useTranslation()

  const postUpdate = (): string => t('side_drawer_post_update')
  const closeButtonTooltip = (): string => t('side_drawer_close_updates')
  const postTitlePlaceholder = (): string => t('add_an_update_title_optional')
  const previousUpdatesTitle = (): string => t('side_drawer_previous_updates')
  const postButtonValue = (): string => t('action_post')
  const readMore = (): string => t('action_read_more')
  const addNamePlaceholder = (): string => t('dialog_add_name_full_name')
  const timeDiffTranslation = (time: number, label: string): string =>
    t(`updates_screen_${label}_ago`, { [label]: time })
  const postedBy = (name?: string): string => {
    if (!name) {
      return t('update_screen_posted_by_no_name')
    }
    return t('update_screen_posted_by', { Board_editor_name: name })
  }
  const postBodyPlaceholder = (
    BoardTitle: string,
    BoardEmoji: string,
  ): string =>
    t('add_an_update_placeholder', { Board_emoji: BoardEmoji }).replace(
      '{{Board_title}}',
      `<strong>${BoardTitle}</strong>`,
    )

  const postConfirmationTitle = (name?: string): string => {
    if (name) {
      return t('dialog_post_confirmation_title')
    }

    return t('dialog_add_name_title')
  }

  const postConfirmationBody = (
    members: number,
    boardEmoji: string,
    boardName: string,
    name?: string,
  ): string => {
    return name
      ? t('dialog_confirmation_message_before_posting_with_name')
          .replace('{{members}}', `<strong>${members}</strong>`)
          .replace('{{Board_emoji}}', `<strong>${boardEmoji}</strong>`)
          .replace('{{Board_name}}', `<strong>${boardName}</strong>`)
      : t('dialog_add_name_subtitle_pc_version')
          .replace('{{members}}', `<strong>${members}</strong>`)
          .replace('{{Board_emoji}}', `<strong>${boardEmoji}</strong>`)
          .replace('{{Board_name}}', `<strong>${boardName}</strong>`)
  }

  const addNameIsRequired = (): string => t('dialog_add_name_error_is_required')
  const minNameLength = (min: number): string =>
    t('dialog_add_name_error_min_characters', { min })
  const maxNameLength = (max: number): string =>
    t('dialog_add_name_error_max_characters', { max })

  const deleteConfirmationTitle = (): string => t('dialog_delete_update_title')
  const deleteConfirmationMessage = (): string =>
    t('dialog_delete_update_subtitle')

  const cancelValue = () => t('action_cancel')
  const deleteValue = () => t('action_delete')
  const learnMore = () => t('learn_more')

  const toastSent = () => t('toast_update_sent')
  const toastDeleted = () => t('toast_update_deleted')

  const bannerPremiumLink = () =>
    t('banner_premium_link', { origin: 'premium_banner_announcements' })
  const deleteUpdateAction = () => t('action_delete_update')

  return {
    postUpdate,
    closeButtonTooltip,
    postTitlePlaceholder,
    postBodyPlaceholder,
    previousUpdatesTitle,
    postButtonValue,
    addNamePlaceholder,
    timeDiffTranslation,
    postedBy,
    readMore,
    postConfirmationTitle,
    postConfirmationBody,
    addNameIsRequired,
    minNameLength,
    maxNameLength,
    deleteConfirmationTitle,
    deleteConfirmationMessage,
    cancelValue,
    deleteValue,
    learnMore,
    toastSent,
    toastDeleted,
    bannerPremiumLink,
    deleteUpdateAction,
  } as const
}
