import { FC, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { ButtonNew, getCurrencySymbol, PremiumCrownIcon } from 'boards-web-ui'

import useGeoLocation from '@hooks/useGeoLocation'
import SubscriptionRow from '@features/account/components/SubscriptionRow'
import PriceInfo from '@features/account/components/PriceInfo'
import { IconWrapper } from '@ui/components/IconWrapper'

type Props = PropsWithChildren<{
  onChangePlanClick: () => void
  customPlanTitle?: string
}>
const FreePlan: FC<Props> = ({ onChangePlanClick, customPlanTitle }) => {
  const { geoData } = useGeoLocation()

  const currency = getCurrencySymbol(geoData?.currency || 'USD')

  return (
    <SubscriptionRow
      planInfo={
        <PriceInfo
          planTitle={customPlanTitle || <Trans i18nKey={'free_plan_title'} />}
          planPrice={`${currency}0`}
        />
      }
      button={
        <ButtonNew
          color={'primary'}
          size={'medium'}
          startIcon={
            <IconWrapper>
              <PremiumCrownIcon />
            </IconWrapper>
          }
          onClick={onChangePlanClick}
        >
          <Trans i18nKey={'paywall_upgrade'} />
        </ButtonNew>
      }
    />
  )
}

export default FreePlan
