import { Trans, useTranslation } from 'react-i18next'

import React, { FC } from 'react'
import useBoards from '@hooks/useBoards'
import EmptyContent from '@features/pickers/components/EmptyContent'
import useBulkSelection from '@features/board/hooks/useBulkSelection'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import SelectBoardItem from './SelectBoardItem'
import { ReactComponent as EmptyBoardStateIcon } from '../../../../icons/EmptyBoardState.svg'

import styles from './BoardsSelection.module.css'
import useFirebaseListeners from '../../../../_firebase/hooks/useFirebaseListeners'

interface Props {
  selectBoard: (boardId: string) => void
  isReadMode?: boolean
}

const BoardsSelection: FC<Props> = ({ selectBoard, isReadMode }) => {
  const { t } = useTranslation()
  const { order, boards } = useBoards()
  const { isPageOnlySelected } = useBulkSelection()
  const { isFreePlanUser } = useReverseTrialContext()

  const hasActiveBoards = order.some((boardId) =>
    Boolean(boards[boardId]?.isActive),
  )

  const { startBoardPermissionsListener } = useFirebaseListeners()

  if (order.length === 0 || (isReadMode && !hasActiveBoards)) {
    return (
      <EmptyContent
        icon={<EmptyBoardStateIcon width="144" height="101" />}
        title={<Trans i18nKey={'desktop_no_boards_empty_state_title_2'} />}
      />
    )
  }

  return (
    <div className={styles.Root}>
      {order.map((boardId: string) => {
        const board = boards[boardId]

        const isSharedBoard = !board?.isOwn

        if (isReadMode && isSharedBoard) {
          const [uid, bid] = boardId.split('-')
          startBoardPermissionsListener(uid, bid)
        }

        const isDisabledPageBoard = Boolean(
          (isReadMode && !isPageOnlySelected && board?.isPageBoard) ||
            (isFreePlanUser && board?.isPageBoard),
        )

        // the content should be not allow to use if the flag "copyAllowed" is "false"
        // for the Page creation/ edit flow ("Content from my Boards"). But user still able to copy the content
        // to this board if he has edit permissions
        const isDisabledForSharedBoard = Boolean(
          isSharedBoard && !board?.options.copyAllowed && !isReadMode,
        )

        const isDisabled = isDisabledForSharedBoard || isDisabledPageBoard

        return board?.isActive || board?.isPageBoard ? (
          <SelectBoardItem
            key={boardId}
            boardId={boardId}
            emoji={board?.icon}
            title={board?.isPageBoard ? t('label_web_pages') : board?.title}
            isShared={!board?.isOwn}
            onClick={() => selectBoard(boardId)}
            disabled={isDisabled}
            isPageBoard={board?.isPageBoard}
            isReadMode={isReadMode}
          />
        ) : null
      })}
    </div>
  )
}

export default BoardsSelection
