import { RefObject, useLayoutEffect } from 'react'

export const useResizeElementHandler = (
  elementTargetRef: RefObject<HTMLElement>,
  handler: () => void,
) => {
  useLayoutEffect(() => {
    const observer = new ResizeObserver(handler)
    observer.observe(elementTargetRef.current as HTMLElement)
    return () => observer.disconnect()
    // eslint-disable-next-line
  }, [])
}
