import React, { useEffect } from 'react'
import useShareNav, { ShareScreens } from './hooks/useShareNav'
import ShareManagement from './components/ShareManagement'
import MembersManagement from './components/MembersManagement'
import useShareEvents from '../analytics/useShareEvents'

interface Props {
  screen?: ShareScreens
}
const Share = ({ screen }: Props) => {
  const { viewMembersScreenEvent, viewShareScreenEvent } = useShareEvents()
  const { isShareScreen, openShareScreen, openMembersScreen } =
    useShareNav(screen)

  useEffect(() => {
    if (isShareScreen) {
      viewShareScreenEvent()
      return
    }

    viewMembersScreenEvent()
  }, [isShareScreen, viewShareScreenEvent, viewMembersScreenEvent])

  if (isShareScreen) {
    return <ShareManagement openMembersScreen={openMembersScreen} />
  }

  return <MembersManagement goBack={openShareScreen} />
}

export default React.memo(Share)
