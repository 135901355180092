import styles from './ExplanationMessage.module.css'

interface Props {
  message: string
}
const ExplanationMessage = ({ message }: Props) => (
  <div className={styles.Root} dangerouslySetInnerHTML={{ __html: message }} />
)

export default ExplanationMessage
