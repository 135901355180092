import { ReactElement } from 'react'
import Card from './Card'
import QuestionMarksTooltip from './QuestionMarksTooltip'
import Spinner, { Size, Align, Justify } from '../../../components/Spinner'
import ScaleTextToFit from './ScaleTextToFix'

import styles from './TopWidget.module.css'
import Grid from '../../../../elements/Grid'

interface Props {
  loading: boolean
  label: string
  value: number | undefined
  icon: ReactElement
  tooltipLabel: string
  onHoverTooltip: () => void
}

const TopWidget = ({
  loading,
  label,
  value,
  icon,
  tooltipLabel,
  onHoverTooltip,
}: Props) => {
  return (
    <Card>
      <div className={styles.Root}>
        <div className={styles.Icon}>{icon}</div>
        <div className={styles.Value}>
          <Spinner
            size={Size.SMALL}
            align={Align.START}
            justify={Justify.START}
            spinning={loading}
          >
            <Grid
              alignItems="flex-end"
              justifyContent="flex-start"
              height="100%"
            >
              <ScaleTextToFit value={value} />
            </Grid>
          </Spinner>
        </div>
        <div className={styles.Label}>
          <span>{label}</span>
          <QuestionMarksTooltip onHover={onHoverTooltip} inline>
            {tooltipLabel}
          </QuestionMarksTooltip>
        </div>
      </div>
    </Card>
  )
}

export default TopWidget
