import { useCallback } from 'react'
import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import useBoard from '../../../hooks/useBoard'

const useShareEvents = () => {
  const { boardId } = useBoard()
  const { trigger } = useLogEvents()

  const addMembersEvent = (membersCount: number, permission: string) => {
    trigger(EventCategories.APP_BOARD_MEMBERS_ADD, {
      board_id: boardId,
      member_count: membersCount,
      permission_level: permission,
    })
  }

  const addMembersForwardEvent = (membersCount: number, permission: string) => {
    trigger(EventCategories.APP_BOARD_MEMBERS_FORWARD, {
      board_id: boardId,
      member_count: membersCount,
      permission_level: permission,
    })
  }

  const removeMembersEvent = (membersCount: number) => {
    trigger(EventCategories.APP_BOARD_MEMBERS_REMOVE, {
      board_id: boardId,
      member_count: membersCount,
    })
  }

  const exportMemberListEvent = (membersCount: number) => {
    trigger(EventCategories.APP_MEMBER_LIST_EXPORT, {
      board_id: boardId,
      member_count: membersCount,
    })
  }

  const changePermissionEvent = () => {
    trigger(EventCategories.APP_BOARD_MEMBER_CHANGE_PERMISSION, {
      board_id: boardId,
    })
  }

  const viewMembersScreenEvent = useCallback(
    () => trigger(EventCategories.APP_SCREEN_VIEW, { screen: 'members' }),
    [trigger],
  )

  const viewShareScreenEvent = useCallback(
    () => trigger(EventCategories.APP_SCREEN_VIEW, { screen: 'add_members' }),
    [trigger],
  )

  const createInviteLinkEvent = () =>
    trigger(EventCategories.APP_BOARD_INVITE_LINK_CREATE)

  const deactivateInviteLinkEvent = () =>
    trigger(EventCategories.APP_BOARD_INVITE_LINK_DEACTIVATE)

  const copyInviteLinkEvent = () =>
    trigger(EventCategories.APP_BOARD_INVITE_LINK_COPIED)

  return {
    addMembersEvent,
    removeMembersEvent,
    exportMemberListEvent,
    addMembersForwardEvent,
    changePermissionEvent,
    viewShareScreenEvent,
    viewMembersScreenEvent,
    createInviteLinkEvent,
    deactivateInviteLinkEvent,
    copyInviteLinkEvent,
  } as const
}

export default useShareEvents
