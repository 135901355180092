import useFolderEvents from '@features/analytics/useFolderEvents'
import useRouterParams from '@hooks/useRouterParams'
import { useClipboard } from '@hooks/useClipboard'

import { Node, NodeType } from '../../../models'

const useNodeClipboard = () => {
  const { copyText, copyImg } = useClipboard()
  const { folderShareContentEvent } = useFolderEvents()
  const { boardId } = useRouterParams()

  const copyToClipboard = async (node: Node) => {
    if (node.type === NodeType.IMAGE) {
      await copyImg(node.text)
    } else {
      await copyText(node.text)
    }

    folderShareContentEvent(1, boardId, node)
  }

  return {
    copyToClipboard,
  }
}

export default useNodeClipboard
