import { useSelector } from 'react-redux'
import { Board } from '../app/models'
import { selectBoards } from '../selectors'

interface IUseBoards {
  order: string[]
  orderByActiveField: string[]
  count: number
  activeBoardsCount: number
  inactiveBoardsCount: number
  boards: {
    [key: string]: Board
  }
  indexOfPagesBoard: number
}

const useBoards = (): IUseBoards => {
  const {
    order,
    boards,
    orderByActiveField,
    count,
    activeBoardsCount,
    inactiveBoardsCount,
    indexOfPagesBoard,
  } = useSelector(selectBoards)

  return {
    order,
    orderByActiveField,
    boards,
    count,
    activeBoardsCount,
    inactiveBoardsCount,
    indexOfPagesBoard,
  } as const
}

export default useBoards
