import { FC, memo } from 'react'
import { Trans } from 'react-i18next'

import {
  Icon,
  PagePreviewEmptyStateIcon,
  SpinnerIcon,
  theme,
} from 'boards-web-ui'

import useWebPageTheme from '@hooks/useWebPageTheme'
import { ScreenNames } from '@features/analytics/useNodeEvents'

import { Node } from '../../../models'
import PageNodes from '../../nodes/PageNodes'

import styles from './PreviewPage.module.css'

type Props = {
  asMobile?: boolean
  isLoading?: boolean
  themeId?: string
  nodes?: Node[]
  EmptyDisplay?: JSX.Element
}

const PreviewPageContent: FC<Props> = ({
  isLoading,
  EmptyDisplay,
  themeId,
  nodes,
  asMobile = false,
}) => {
  const { data: webPageTheme, isLoading: isThemeLoading } =
    useWebPageTheme(themeId)

  if (isLoading || isThemeLoading) {
    return (
      <div className={styles.FolderLoadingSpinner}>
        <Icon size="38px">
          <SpinnerIcon />
        </Icon>
      </div>
    )
  }

  if (!nodes?.length) {
    return EmptyDisplay || <DefaultEmptyDisplay />
  }

  const backgroundColor = theme.themeParsers.parseColor(
    webPageTheme?.page?.backgroundColor,
  )

  return (
    <theme.UiThemeProvider theme={{ webPageTheme }}>
      <div style={{ height: '100%', backgroundColor }}>
        <PageNodes
          asMobile={asMobile}
          nodes={nodes}
          screen={ScreenNames.PAGE_PREVIEW}
        />
      </div>
    </theme.UiThemeProvider>
  )
}

const DefaultEmptyDisplay: FC = () => (
  <div className={styles.NoNodesContainer}>
    <PagePreviewEmptyStateIcon />
    <Trans i18nKey="page_empty_state_title" />
  </div>
)

export default memo(PreviewPageContent)
