import { useTranslation } from 'react-i18next'

const useAddContentTranslations = () => {
  const { t } = useTranslation()

  const page = (): string => t('menu_web_page')
  const folder = (): string => t('content_key_folder')
  const text = (): string => t('content_key_text')
  const videoYoutubeVimeo = (): string => t('content_key_youtube_vimeo')
  const videoStreaming = (): string => t('label_video')
  const link = (): string => t('content_key_link')
  const image = (): string => t('content_key_photo')
  const pdf = (): string => t('content_key_pdf')
  const headline = (): string => t('page_text_headline')
  const paragraph = (): string => t('page_text_paragraph')
  const button = (): string => t('page_button')

  const bigFile = (): string => t('file_size_too_big')

  const closeTitle = (): string => t('discard_changes_title')
  const closeMessage = (): string => t('discard_changes_body')
  const cancel = (): string => t('action_cancel')
  const discard = (): string => t('action_discard')
  const badgeNew = (): string => t('badge_new')

  return {
    folder,
    text,
    videoYoutubeVimeo,
    videoStreaming,
    link,
    button,
    image,
    pdf,
    headline,
    paragraph,
    page,

    bigFile,

    closeTitle,
    closeMessage,
    cancel,
    discard,
    badgeNew,
  } as const
}

export default useAddContentTranslations
