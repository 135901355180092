import { PropsWithChildren, ReactElement } from 'react'

import styles from './Label.module.css'

type Props = PropsWithChildren<{ subtitle?: string | ReactElement }>
const Label = ({ subtitle, children }: Props) => {
  return (
    <>
      <div className={styles.Title}>{children}</div>
      {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
    </>
  )
}

export default Label
