import { useMemo } from 'react'

import { UserProfileFeatures } from '@models/UserProfile'
import { isCurrentVersionFresh } from '@helpers/compareVersions'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

const useProfileFeatureFlag = <T extends keyof UserProfileFeatures>(
  featureName: T,
) => {
  const {
    profile: { features },
  } = useProfileInformation()

  const featureVersionNumber: string = useMemo(() => {
    return features?.[featureName]?.web || ''
  }, [features, featureName])

  const isFeatureAvailable: boolean = useMemo(() => {
    return isCurrentVersionFresh(featureVersionNumber)
  }, [featureVersionNumber])

  return {
    featureName,
    isFeatureAvailable,
    featureVersionNumber,
  } as const
}

export default useProfileFeatureFlag
