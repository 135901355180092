export const SIGN_IN = '/signin'
export const SIGN_UP = '/signup'
export const HOME = '/'
export const RESET_PASSWORD = '/reset-password'
export const EDITOR = '/editor'
export const MOBILE = '/mobile'

export const SHARED_FOLDERS = {
  oldRoot: '/a',
  newRoot: '/b',
}
