import { useCallback, useState } from 'react'

type Props = {
  defaultTitle?: string
}
const useEditFormsTitle = ({ defaultTitle = '' }: Props) => {
  const [formTitle, setFormTitle] = useState<string>(defaultTitle)

  const onFormTitleChange = useCallback(
    (newTitle = '') => {
      setFormTitle(newTitle)
    },
    [setFormTitle],
  )

  return {
    formTitle,
    onFormTitleChange,
  }
}

export default useEditFormsTitle
