import { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import clsx from 'clsx'

import styles from './Screen.module.css'

type Props = PropsWithChildren<{
  className?: string
  title: string
  text: string
}>
const Screen = ({ className, title, text, children }: Props) => {
  return (
    <div className={clsx(styles.Root, className && className)}>
      <h1 className={styles.Title}>
        <Trans i18nKey={title} />
      </h1>
      <p className={styles.Text}>
        <Trans i18nKey={text} />
      </p>

      {children}
    </div>
  )
}

export default Screen
