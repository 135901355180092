import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { ButtonNew } from 'boards-web-ui'
import GuestInputWrapper from './GuestInputWrapper'
import GuestTitle from './GuestTitle'
import InputField from '../../../../elements/InputField'
import { useResetPassword } from '../hooks/useResetPassword'
import * as ROUTES from '../../../../constants/routes'
import { isEmail } from '../../../../utils/validators'
import styles from './ResetPasswordForm.module.css'
import GuestFooter from './GuestFooter'
import Layout from '../../layout/Layout'

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      agreeWithTerms: true,
    },
  })
  const { submitting, onSignUp } = useResetPassword(setError)

  const onSubmit = async (data) => {
    await onSignUp(data)
  }

  return (
    <Layout>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <GuestInputWrapper>
          <GuestTitle>
            <Trans i18nKey="authentication_screen_reset_password" />
          </GuestTitle>
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            placeholder={t('authentication_screen_email_placholder')}
            {...register('email', {
              required: t('singup_error_no_email_filled'),
              pattern: {
                value: isEmail,
                message: t('errors.invalid_email_address'),
              },
            })}
            errors={errors}
          />
        </GuestInputWrapper>

        <ButtonNew size={'xl'} isLoading={submitting} type={'submit'}>
          <Trans i18nKey="authentication_screen_send_reset_link" />
        </ButtonNew>

        <GuestFooter
          link={ROUTES.SIGN_IN}
          linkKey="action_sign_in"
          msgKey="authentication_screen_remembered_password"
        />
      </form>
    </Layout>
  )
}
export default ResetPasswordForm
