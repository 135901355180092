import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { ButtonIcon, Icon, LogoIcon, TrashIcon } from 'boards-web-ui'

import clsx from 'clsx'
import { IconWrapper } from '@ui/components/IconWrapper'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'

import styles from './CreatePageBanner.module.css'

type Props = {
  unbrandedPages?: boolean
  onDelete: () => void
}
const CreatePageBanner: FC<Props> = ({ unbrandedPages, onDelete }) => (
  <div
    className={clsx(
      styles.CreatePageBanner,
      unbrandedPages && styles.UnbrandedPages,
    )}
  >
    <div>
      <Icon className={styles.Logo} size="57px" color="#222">
        <LogoIcon />
      </Icon>

      <p className={styles.Message}>Easily create a page like this 🚀</p>
    </div>

    <Tooltip
      data={
        <TooltipContent>
          <Trans i18nKey="action_delete" />
        </TooltipContent>
      }
    >
      <ButtonIcon variant="purple" size={38} round onClick={onDelete}>
        <IconWrapper color="primary" className={styles.DeleteIcon}>
          <TrashIcon />
        </IconWrapper>
      </ButtonIcon>
    </Tooltip>
  </div>
)

export default React.memo(CreatePageBanner)
