import { Trans } from 'react-i18next'
import { IconWrapper } from '@ui/components/IconWrapper'
import {
  ButtonIcon,
  ZoomInIcon,
  ZoomOutIcon,
  ZoomResetIcon,
} from 'boards-web-ui'
import React from 'react'
import { TooltipPositions } from '@ui/models/tooltip'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'

import styles from './ZoomButtons.module.css'

interface Props {
  reset: (() => void) | null
  increase: (() => void) | null
  decrease: (() => void) | null
}

const ZoomButtons = ({ reset, increase, decrease }: Props) => {
  const handleReset = () => reset && reset()
  const handleDecrease = () => decrease && decrease()
  const handleIncrease = () => increase && increase()

  return (
    <div className={styles.Root}>
      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_zoom_out'} />
          </TooltipContent>
        }
      >
        <ButtonIcon
          className={decrease ? '' : styles.IconDisabled}
          onClick={handleDecrease}
          disabled={!decrease}
          round
          variant={'white-transparent'}
        >
          <IconWrapper>
            <ZoomOutIcon />
          </IconWrapper>
        </ButtonIcon>
      </Tooltip>

      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_reset_zoom'} />
          </TooltipContent>
        }
      >
        <ButtonIcon
          className={reset ? '' : styles.IconDisabled}
          onClick={handleReset}
          disabled={!reset}
          round
          variant={'white-transparent'}
        >
          <IconWrapper>
            <ZoomResetIcon />
          </IconWrapper>
        </ButtonIcon>
      </Tooltip>

      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_zoom_in'} />
          </TooltipContent>
        }
      >
        <ButtonIcon
          className={increase ? '' : styles.IconDisabled}
          onClick={handleIncrease}
          disabled={!increase}
          round
          variant={'white-transparent'}
        >
          <IconWrapper>
            <ZoomInIcon />
          </IconWrapper>
        </ButtonIcon>
      </Tooltip>
    </div>
  )
}

export default ZoomButtons
