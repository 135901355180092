import { useState, useEffect } from 'react'

export const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState)

  useEffect(() => {
    const onClick = (e) => {
      if (isActive && el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive)
      }
    }

    const timeoutId = setTimeout(() => {
      document.addEventListener('click', onClick, false)
    }, 0)
    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', onClick, false)
    }
  }, [isActive, el])

  return [isActive, setIsActive]
}
