import { UserContentSettings } from '@models/UserContentSettings'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { Folder } from 'app/models'

export const enum ACTION_TYPE {
  ADD_CONTENT = 'ADD_CONTENT',
  CREATE_CONTENT = 'CREATE_CONTENT',
  UPDATE_CONTENT_COLLECTION = 'UPDATE_CONTENT_COLLECTION',
  SET_USER_CONTENT_SETTINGS = 'SET_USER_CONTENT_SETTINGS',
  COPY_TEMPLATE = 'COPY_TEMPLATE',
  REVERT_COPY_TEMPLATE = 'REVERT_COPY_TEMPLATE',
}

const addContent = (
  board: string,
  folder: string,
  key: string,
  properties: INodeProps,
) => ({
  type: ACTION_TYPE.ADD_CONTENT,
  board,
  folder,
  key,
  properties,
})

const createContent = (contentId: string, userId: string) => ({
  type: ACTION_TYPE.CREATE_CONTENT,
  contentId,
  userId,
})

const updateContentCollection = (modified: string, removed: string) => ({
  type: ACTION_TYPE.UPDATE_CONTENT_COLLECTION,
  modified,
  removed,
})

const setUserContentSettings = (settings: UserContentSettings) => ({
  type: ACTION_TYPE.SET_USER_CONTENT_SETTINGS,
  settings,
})

const copyTemplate = (
  targetBoardId: string,
  targetFolderId: string,
  template: string,
  key: string,
  page: Folder,
) => ({
  type: ACTION_TYPE.COPY_TEMPLATE,
  targetBoardId,
  targetFolderId,
  template,
  key,
  page,
})

const revertCopyTemplate = (
  boardId: string,
  folderId: string,
  pageId: string,
) => ({
  type: ACTION_TYPE.REVERT_COPY_TEMPLATE,
  boardId,
  folderId,
  pageId,
})

const actions = {
  addContent,
  createContent,
  updateContentCollection,
  setUserContentSettings,
  copyTemplate,
  revertCopyTemplate,
} as const

export default actions
