import { AfterSubmitBehaviourEnum } from '@features/forms/constants'

export const CHECKOUT_POST_SUBMITTING_BEHAVIOUR: {
  [key: string]: AfterSubmitBehaviourEnum
} = {
  MESSAGE: AfterSubmitBehaviourEnum.MESSAGE,
  REDIRECT: AfterSubmitBehaviourEnum.REDIRECT,
}

export const DEFAULT_POST_SUBMISSION_MESSAGE = 'forms_thanks_message_default'
