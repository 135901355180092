export const shortDateFormat = (date: Date): string => {
  return `${date.toLocaleString('default', {
    month: 'short',
  })} ${date.getDate()}`
}

export const longDateFormat = (date: Date): string => {
  return `${date.getFullYear().toString().slice(-2)} ${date.toLocaleString(
    'default',
    {
      month: 'short',
    },
  )}`
}
