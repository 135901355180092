import React, { FC } from 'react'
import styles from '@features/boardsMenu/components/FreePlanUserMenu/FreePlanUserMenu.module.css'
import BoardsMenu from '@features/boardsMenu/components/BoardsMenu'
import { Board } from '../../../../models'

interface IProps {
  title?: React.ReactNode
  boards: { [p: string]: Board }
  order: string[]

  draggedTo: number | undefined
  onDragStart: (event: unknown) => void
  onDragOver: (event: unknown) => void
  onDrop: () => void
  onDragLeave: () => void
}

export const BoardsSection: FC<IProps> = ({
  title,
  boards,
  order,
  ...rest
}) => (
  <>
    {title ? <div className={styles.BoardTitle}>{title}</div> : null}

    <BoardsMenu boards={boards} order={order} isFreeUserMenu {...rest} />

    <BoardsMenu
      boards={boards}
      order={order}
      isFreeUserMenu
      inactive
      {...rest}
    />
  </>
)
