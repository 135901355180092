export const parseBoolean = (
  value: string | undefined | boolean,
  defaultValue: boolean,
) => {
  if (typeof value !== 'boolean') {
    return defaultValue
  }

  return value
}
