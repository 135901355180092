import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DialogProvider } from 'boards-web-ui'
import SharedBoardModal from '@features/editor/components/SharedBoardModal'
import ReverseTrialBar from '@features/editor/components/reverseTrial/ReverseTrialBar'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useFetchAvailableSubscriptions from '@hooks/useFetchAvailableSubscriptions'

import { BannerProvider } from '@ui/components/Banner/BannerProvider'
import useBulk from '@hooks/bulk/useBulk'
import { useSharedFolder } from '@hooks/useSharedFolder'
import useBoard from '@hooks/useBoard'
import useBoards from '@hooks/useBoards'

import UpdatesProvider from '../../updates/UpdatesProviders'
import useRotesHelper from '../../../routes/hooks/useRotesHelper'
import useAutoSelectFirstBoardOrRedirect from '../../../routes/hooks/useAutoSelectFirstBoardOrRedirect'
import useLoadCurrentBoardFolders from '../hooks/useLoadCurrentBoardFolders'
import useDialogFromSearchParams from '../hooks/useDialogFromSearchParams'
import ScrollAreasProvider from '../../../../ui/components/Scroll/ScrollAreasProvider'

import SideBar from '../../sidebar/SideBar'
import AppUpdatesSection from '../../updates/AppUpdatesSection'

import EditorNav from '../components/EditorNav'

import BoardToolBar from '../../toolbar/BoardToolBar'
import ToastMessageContainer from '../../../../containers/ToastMessageContainer'

import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import EmptyContent, {
  EmptyBoardVariants,
} from '../../../../components/EmptyContent'
import BulkActionsToolbar from '../../bulkActionsToolbar/BulkActionsToolbar'

import { ReactComponent as Spinner } from '../../../../icons/Spinner.svg'
import { isMobile } from '../../../../utils/deviceParser'

import Modal from '../../../../components/Modal'
import MobilePopup from '../../sidebar/components/MobilePopup'
import MobilePopupHeader from '../../sidebar/components/MobilePopupHeader'

import { folderActions } from '../../../../actions'

import styles from './EditorRoot.module.css'

const RenderElement: React.FC = () => {
  useLoadCurrentBoardFolders()
  useDialogFromSearchParams()

  const { boards } = useBoards()

  const { resetBulkActions, isSelectionModeEnabled } = useBulk()

  const { board } = useBoard()

  if (!board || Object.keys(boards).length === 0) {
    return (
      <>
        <EmptyContent variant={EmptyBoardVariants.noBoards} />
        <Outlet />
      </>
    )
  }

  return (
    <>
      {isSelectionModeEnabled && (
        <BulkActionsToolbar
          className={styles.BulkContainer}
          isPageBoard={board.isPageBoard}
        />
      )}

      <BoardToolBar className={isSelectionModeEnabled ? styles.Hidden : ''} />

      <EditorNav onLinkClicked={resetBulkActions} />
      <div className={styles.Wrap}>
        <div className={styles.Content}>
          <Outlet />
        </div>
        <AppUpdatesSection />
      </div>
    </>
  )
}

type Props = PropsWithChildren<Record<string, unknown>>
const EditorRoot: React.FC<Props> = () => {
  const currentBoardExists = useAutoSelectFirstBoardOrRedirect()
  useFetchAvailableSubscriptions()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { pending } = useRotesHelper()
  const { loaded } = useProfileInformation()
  const [open, setOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')

  const { shouldShowFreeTrialIndicator, shouldShowFreePlanIndicator } =
    useReverseTrialContext()

  const { sharedFolderState, isSharedFolderExistsInFolders, showSharedFolder } =
    useSharedFolder()

  useEffect(() => {
    if (shortcutId && boardId && folderId) {
      dispatch(
        folderActions.updateSharedFolderData({ shortcutId, boardId, folderId }),
      )
      searchParams.delete('shortcutId')
      searchParams.delete('boardId')
      searchParams.delete('folderId')
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNavigate = async () => {
    navigate(`/${sharedFolderState.boardId}/${sharedFolderState.folderId}`)
  }

  useEffect(() => {
    if (isSharedFolderExistsInFolders) {
      handleNavigate().then(() =>
        dispatch(
          folderActions.updateSharedFolderData({
            shortcutId: '',
            boardId: '',
            folderId: '',
          }),
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSharedFolderExistsInFolders])

  const canRenderSharedFolder = showSharedFolder && !(pending || !loaded)

  useEffect(() => {
    if (isMobile) {
      navigate('/welcome')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Fix blank screen when user click back button
   *
   * TODO: avoid reset state in FirebaseListenersProvider
   */
  if (!currentBoardExists && !loaded) return null

  return (
    <ScrollAreasProvider>
      <UpdatesProvider>
        <BannerProvider>
          <DialogProvider>
            <main className={styles.Body}>
              <Modal
                variant="smallPopup"
                Header={<MobilePopupHeader setOpen={setOpen} />}
                onClickOutside={undefined}
                open={open}
                className={styles.MobileModal}
              >
                <MobilePopup setOpen={setOpen} />
              </Modal>

              <SideBar setOpen={setOpen} />

              <section className={styles.Main}>
                {(shouldShowFreeTrialIndicator ||
                  shouldShowFreePlanIndicator) && <ReverseTrialBar />}
                {pending || !loaded ? (
                  <Spinner className={styles.Spinner} />
                ) : (
                  <RenderElement />
                )}

                {canRenderSharedFolder && (
                  <SharedBoardModal
                    shortcutId={sharedFolderState.shortcutId}
                    boardId={sharedFolderState.boardId}
                    folderId={sharedFolderState.folderId}
                  />
                )}
                <ToastMessageContainer />
              </section>
            </main>

            {/* <Monetization /> Monetization Banner was commented by BLISS-4342 */}
          </DialogProvider>
        </BannerProvider>
      </UpdatesProvider>
    </ScrollAreasProvider>
  )
}

export default EditorRoot
