import { FC, PropsWithChildren, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ButtonNew, PaddleCard } from 'boards-web-ui'

import EditPlanDropdown from '@features/account/components/EditPlanDropdown'
import usePaywallProducts from '@features/payments/hooks/usePaywallProducts'
import SubscriptionRow from '@features/account/components/SubscriptionRow'
import PriceInfo from '@features/account/components/PriceInfo'
import { useToast } from '@hooks/useToast'

import { CurrentPlan } from 'app/models'

import styles from './PaddlePlan.module.css'

type Props = PropsWithChildren<{
  plan: CurrentPlan
  onChangePlanClick: () => void
  onCancelPlanClick: () => Promise<void>
  onRenewPlanClick: () => Promise<void>
  onChangePaymentClick: () => Promise<void>
}>
const PaddlePlan: FC<Props> = ({
  plan,
  onCancelPlanClick,
  onChangePlanClick,
  onRenewPlanClick,
  onChangePaymentClick,
}) => {
  const toast = useToast()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isChangePaymentLoading, setIsChangePaymentLoading] = useState(false)
  const { getProductByPeriod } = usePaywallProducts([plan.productId])
  const product = getProductByPeriod(plan.productId)

  const handleRenewPlanClick = async () => {
    try {
      setIsLoading(true)
      await onRenewPlanClick()
    } catch (error) {
      toast(t('optimistic_ui_failed'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangePaymentClick = async () => {
    try {
      setIsChangePaymentLoading(true)
      await onChangePaymentClick()
    } catch (error) {
      toast(t('optimistic_ui_failed'))
    } finally {
      setIsChangePaymentLoading(false)
    }
  }

  const getBillingInterval = useMemo(() => {
    if (product?.price.billingCycle?.interval === 'year') {
      return <Trans i18nKey={'business_regular_yearly_price'} />
    }

    if (plan.numberOfPeriods && plan.numberOfPeriods > 1) {
      return (
        <>
          {' / '}
          <Trans
            i18nKey={'paywall_three_months_plan'}
            values={{
              numberOfPeriods: plan.numberOfPeriods,
            }}
          />
        </>
      )
    }

    return <Trans i18nKey={'business_price'} />
  }, [plan.numberOfPeriods, product?.price.billingCycle?.interval])

  return (
    <>
      <SubscriptionRow
        planInfo={
          <PriceInfo
            planTitle={<Trans i18nKey={'business_plan_title'} />}
            planPrice={
              product && (
                <>
                  {product?.formattedTotals.total}
                  <span className={styles.BillingInterval}>
                    {getBillingInterval}
                  </span>
                </>
              )
            }
            scheduledCancel={product && plan.scheduledCancel}
          />
        }
        button={
          plan.scheduledCancel ? (
            <ButtonNew
              color={'secondary'}
              size={'medium'}
              isLoading={isLoading}
              onClick={handleRenewPlanClick}
            >
              <Trans i18nKey={'cancel_popup_renew'} />
            </ButtonNew>
          ) : (
            <EditPlanDropdown
              onCancelPlanClick={onCancelPlanClick}
              onChangePlanClick={onChangePlanClick}
            />
          )
        }
      />

      <div className={styles.CardWrapper}>
        {plan.paymentMethod && (
          <PaddleCard
            method={plan.paymentMethod.type}
            card={plan.paymentMethod?.card?.type}
            last4Digits={plan.paymentMethod?.card?.last4}
          />
        )}
        <ButtonNew
          color={'secondary'}
          size={'medium'}
          onClick={handleChangePaymentClick}
          isLoading={isChangePaymentLoading}
        >
          <Trans i18nKey={'change_button'} />
        </ButtonNew>
      </div>
    </>
  )
}

export default PaddlePlan
