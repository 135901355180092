import { Role, RoleAnalytics } from '../app/models'

export const mapPermissionToAnalyticsBoardRole = ({
  role,
  isOwnBoard,
}: {
  role: Role
  isOwnBoard: boolean
}) => {
  if (isOwnBoard) {
    return RoleAnalytics.OWNER
  }
  return role === Role.WRITE ? RoleAnalytics.EDITOR : RoleAnalytics.MEMBER
}
