import { useEffect, useState } from 'react'

// this hook will trigger handler only after first dependency changes
const useOnlyFirstChange = (
  handler: () => void,
  dependency: Array<unknown>,
): void => {
  const [changesCount, setChangesCount] = useState<number>(0)

  useEffect(() => {
    if (changesCount > 1) {
      return
    }

    setChangesCount((count) => count + 1)
    handler()
    // no need to add more dependency to avoid extra trigger
    // eslint-disable-next-line
  }, dependency)
}

export default useOnlyFirstChange
