import { FC, memo } from 'react'
import { Trans } from 'react-i18next'

import { ButtonNew, GoogleSheetsIcon, Icon, Spacer } from 'boards-web-ui'

import Label from '../../../../ui/components/Label'
import GoogleSheetConnected from './GoogleSheetConnected'

import styles from './GoogleSheetConnect.module.css'

interface Props {
  integrationStatusStatePending: boolean
  isGoogleAccountConnectedPending: boolean
  isGoogleFormConnected: boolean
  spreadsheetURL?: string
  onUnlinkSpreadSheet: () => void
  onConnectAccountToGoogleSheets: () => void
}

const GoogleSheetConnect: FC<Props> = ({
  integrationStatusStatePending,
  isGoogleFormConnected,
  isGoogleAccountConnectedPending,
  spreadsheetURL = '',
  onUnlinkSpreadSheet,
  onConnectAccountToGoogleSheets,
}) => {
  return (
    <div>
      <div className={styles.Row}>
        <Label
          subtitle={<Trans i18nKey={'forms_collect_responses_subtitle'} />}
        />
      </div>

      <Spacer height={5} />
      {!isGoogleFormConnected ||
      isGoogleAccountConnectedPending ||
      spreadsheetURL === '' ? (
        <ButtonNew
          size="xl"
          fullWidth
          color="green"
          onClick={onConnectAccountToGoogleSheets}
          startIcon={
            <Icon className={styles.GoogleSheetIcon} size="20px">
              <GoogleSheetsIcon />
            </Icon>
          }
          isLoading={isGoogleAccountConnectedPending}
          className={styles.ConnectButton}
        >
          <Trans i18nKey={'forms_collect_responses_button'} />
        </ButtonNew>
      ) : (
        <GoogleSheetConnected
          integrationStatusStatePending={integrationStatusStatePending}
          link={spreadsheetURL}
          onUnLink={onUnlinkSpreadSheet}
        />
      )}
    </div>
  )
}

export default memo(GoogleSheetConnect)
