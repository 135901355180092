import { UpdatesMessage, UiUpdatesMessage, Board } from '../app/models'

export const mergeMessageWithBoard = (
  message: UpdatesMessage,
  { title, icon }: Board,
  unread: boolean,
): UiUpdatesMessage => ({
  title,
  unread,
  icon,
  ...message,
})
