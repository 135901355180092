import { FC, ReactNode, useEffect, useState } from 'react'
import { PickerModal } from '@ui/components/PickerModal'
import { DestinationPickerHeader } from '@features/pickers/destinationPicker/DestinationPickerHeader'
import { DestinationPickerFooter } from '@features/pickers/destinationPicker/DestinationPickerFooter'
import { DestinationPickerBody } from '@features/pickers/destinationPicker/DestinationPickerBody'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import { useDispatch } from 'react-redux'
import {
  DestinationPickerSourceEventEnum,
  useDestinationPickerEvents,
} from '@features/analytics/useDestinationPickerEvents'
import useRouterParams from '@hooks/useRouterParams'
import useBulkActionsPermissions from '@hooks/bulk/useBulkActionsPermissions'
import useBulkSelection from '@features/board/hooks/useBulkSelection'
import useBoard from '@hooks/useBoard'
import useFolder from '@hooks/useFolder'
import { boardActions } from '../../../../actions'

interface Props {
  picker: ContentPickersEnum
  contentType: string
  onClose: (picker: ContentPickersEnum) => void
  headerCopy: ReactNode
  performActionCopy: ReactNode
  performActionSuccessMessage: string
  eventsOverrides?: {
    source: DestinationPickerSourceEventEnum
  }
}

export const DestinationPickerModal: FC<Props> = ({
  picker,
  contentType,
  onClose,
  headerCopy,
  performActionCopy,
  performActionSuccessMessage,
  eventsOverrides,
}) => {
  const { pickerBoardId, pickerFolderId, isPage, selectBoard, selectFolder } =
    useContentNavigation(picker)

  const dispatch = useDispatch()
  const { destinationPickerViewEvent } = useDestinationPickerEvents()
  const { boardId: currentBoardId, folderId: currentFolderId } =
    useRouterParams()

  const { isMoveDisabled } = useBulkActionsPermissions({
    boardId: pickerBoardId,
    folderId: pickerFolderId,
  })
  const { folder: existingFolder } = useFolder(pickerBoardId, pickerFolderId)

  const isPathNotExists = !existingFolder && pickerFolderId
  const isPickerPathNotRelatedToCurrentBoard = currentBoardId !== pickerBoardId
  const isMovePicker = picker === ContentPickersEnum.MovePicker

  const { isPageOnlySelected } = useBulkSelection()
  const { isOwnBoard, isPageBoard } = useBoard({ boardId: pickerBoardId })

  const shouldUpdateMovePath =
    isMovePicker &&
    (isPickerPathNotRelatedToCurrentBoard ||
      isPathNotExists ||
      !pickerFolderId ||
      isMoveDisabled)

  const shouldUpdateCopyPath =
    Boolean(!isMovePicker && isPathNotExists) ||
    (!isPageOnlySelected && isPageBoard)

  // This flag is needed to avoid content flicking when the picker state has to be updated
  const [isWaitingForNavigationUpdate, setIsWaitingForNavigationUpdate] =
    useState(shouldUpdateMovePath || shouldUpdateCopyPath)

  useEffect(() => {
    destinationPickerViewEvent({
      type: picker,
      source:
        eventsOverrides?.source ||
        (isOwnBoard
          ? DestinationPickerSourceEventEnum.OwnBoard
          : DestinationPickerSourceEventEnum.ReceivedBoard),
      contentType,
    })

    // Because we are not able to move content cross-boards if picker opened
    // for other boards or path that is not exists already than
    // we navigate to the current folder content
    if (shouldUpdateMovePath) {
      dispatch(
        boardActions.setFolderPathAction(
          ContentPickersEnum.MovePicker,
          currentBoardId,
          currentFolderId,
        ),
      )
      setIsWaitingForNavigationUpdate(false)
      return
    }

    // If picker opened in path that is not exists already (folder can be deleted) than navigate to root (boards list)
    if (shouldUpdateCopyPath) {
      dispatch(
        boardActions.setFolderPathAction(
          ContentPickersEnum.CopyPicker,
          undefined,
          undefined,
        ),
      )
    }

    setIsWaitingForNavigationUpdate(false)
    // eslint-disable-next-line
  }, [])

  const scrollKey = `${pickerBoardId?.toString()}-${pickerFolderId?.toString()}`

  return (
    <PickerModal
      onClose={() => {
        onClose(picker)
      }}
      picker={picker}
      scrollKey={scrollKey}
      header={
        <DestinationPickerHeader picker={picker} headerCopy={headerCopy} />
      }
      footer={
        <DestinationPickerFooter
          picker={picker}
          onClose={onClose}
          contentType={contentType}
          performActionCopy={performActionCopy}
          performActionSuccessMessage={performActionSuccessMessage}
          eventsOverrides={eventsOverrides}
        />
      }
    >
      {isWaitingForNavigationUpdate ? null : (
        <DestinationPickerBody
          picker={picker}
          pickerBoardId={pickerBoardId}
          pickerFolderId={pickerFolderId}
          isPage={isPage}
          selectBoard={selectBoard}
          selectFolder={selectFolder}
        />
      )}
    </PickerModal>
  )
}
