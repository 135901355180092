import { ContentPickersEnum } from '@features/pickers'
import { Folder } from '../app/models'

export const enum ACTION_TYPE {
  ADD_FOLDER = 'ADD_FOLDER',
  ADD_FOLDER_FAKE = 'ADD_FOLDER_FAKE',
  DELETE_FOLDER = 'DELETE_FOLDER',
  SET_FOLDER = 'SET_FOLDER',
  SET_FOLDER_SHORTCUT = 'SET_FOLDER_SHORTCUT',
  DELETE_FOLDER_SHORTCUT = 'DELETE_FOLDER_SHORTCUT',
  UPDATE_SHARED_FOLDER_DATA = 'UPDATE_SHARED_FOLDER_DATA',
  SET_SHARED_FOLDER = 'SET_SHARED_FOLDER',
  ADD_AND_SELECT_FOLDER = 'ADD_AND_SELECT_FOLDER',
}

const addFolder = (
  board: string,
  folder: string,
  key: string,
  properties: { [key: string]: unknown },
) => ({
  type: ACTION_TYPE.ADD_FOLDER,
  board,
  folder,
  key,
  properties,
})

const addFolderFake = (
  board: string,
  folder: string,
  key: string,
  properties: { [key: string]: unknown },
  isPage: boolean,
  picker?: ContentPickersEnum,
) => ({
  type: ACTION_TYPE.ADD_FOLDER_FAKE,
  board,
  folder,
  key,
  properties,
  isPage,
  picker,
})

const deleteFolder = (board: string, fid: string) => ({
  type: ACTION_TYPE.DELETE_FOLDER,
  board,
  fid,
})

const setFolders = (boardId: string, folders: { [id: string]: Folder }) => ({
  type: ACTION_TYPE.SET_FOLDER,
  boardId,
  folders,
})

const addAndSelectFolder = (boardId: string, parentId: string) => ({
  type: ACTION_TYPE.ADD_AND_SELECT_FOLDER,
  boardId,
  parentId,
})

const setFolderShortcut = (shortcut: string) => ({
  type: ACTION_TYPE.SET_FOLDER_SHORTCUT,
  shortcut,
})

const updateSharedFolderData = ({
  shortcutId,
  boardId,
  folderId,
}: {
  shortcutId: string
  boardId: string
  folderId: string
}) => ({
  type: ACTION_TYPE.UPDATE_SHARED_FOLDER_DATA,
  shortcutId,
  boardId,
  folderId,
})

const deleteFolderShortcut = (shortcut?: string | Array<string>) => ({
  type: ACTION_TYPE.DELETE_FOLDER_SHORTCUT,
  shortcut,
})

const setSharedFolder = (folder?: Folder) => ({
  type: ACTION_TYPE.SET_SHARED_FOLDER,
  folder,
})

const actions = {
  addFolder,
  /** @deprecated Need to update "ADD_FOLDER" reducer logic instead */
  addFolderFake,
  deleteFolder,
  setFolders,
  addAndSelectFolder,
  setFolderShortcut,
  updateSharedFolderData,
  deleteFolderShortcut,
  setSharedFolder,
} as const
export default actions
