export const enum ACTION_TYPE {
  SET_VIDEO_ERROR = 'SET_VIDEO_ERROR',
  SET_VIDEO_URL = 'SET_VIDEO_URL',
  SET_VIDEO_ID = 'SET_VIDEO_ID',
}

const videoError = (error: boolean) => ({
  type: ACTION_TYPE.SET_VIDEO_ERROR,
  error,
})

const setVideoStreamingUrl = (url: string) => ({
  type: ACTION_TYPE.SET_VIDEO_URL,
  url,
})

const setVideoStreamingId = (id: string) => ({
  type: ACTION_TYPE.SET_VIDEO_ID,
  id,
})

const actions = {
  videoError,
  setVideoStreamingUrl,
  setVideoStreamingId,
} as const

export default actions
