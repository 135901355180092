import {
  AnimatedDialog,
  ButtonNew,
  ReplaceIcon,
  useDialog,
} from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'

import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'
import DoneButton from '@ui/components/DoneButton'

import { IconWrapper } from '@ui/components/IconWrapper'
import React, { useCallback, useEffect, useState } from 'react'
import styles from '@features/nodeEditor/components/EditImage.module.css'

import useAddVideoStreaming from '@features/nodeEditor/hooks/useAddVideoStreaming'
import useUploadFirebase from '@hooks/useUploadFirebase'
import { useUploadSuccessfully } from '@features/nodeEditor/components/VideoStreaming/useUploadSuccessfully'
import { VideoStreamingUploadingProgress } from './VideoStreamingUploadingProgress'
import InputModalTitle from '../../../../../elements/InputModalTitle'
import ModalEditWithConfirmation from '../../../../../components/ModalEditWithConfirmation'
import { Node } from '../../../../models'
import { ICreateOrUpdateNode } from '../../models/IUseNodeCreatorAPI'
import useNodeAPI from '../../hooks/useNodeAPI'
import useNodeDiscardChanges from '../../hooks/useNodeDiscardChanges'
import VideoStreaming from '../VideoStreaming'

interface Props {
  node: Node
  boardId: string
  folderId: string
  isPageEditor?: boolean
  onSave: ICreateOrUpdateNode
  onContentReplaceEvent: () => void
}

const EditVideoStreaming = ({
  node: currentNode,
  boardId,
  folderId,

  isPageEditor,
  onSave,
  onContentReplaceEvent,
}: Props) => {
  const { t } = useTranslation()
  const { close } = useDialog()
  const [node, setNode] = useState<Node | undefined>()

  const discardChanges = useNodeDiscardChanges()
  const [isUploading, setIsUploading] = useState(false)
  const { error, progress, uploadFile, cancelUpload } = useUploadFirebase()
  const { title, hasChanges, setTitle } = useNodeAPI(
    currentNode?.title || '',
    '',
  )

  const { uploadWithFilePicker, file, videoMetadata } = useAddVideoStreaming({
    isEditVideoMode: true,
    onContentReplaceEvent,
    isPageEditor,
  })

  const uploadSuccessfully = useUploadSuccessfully({
    onSave,
    setNode,
    videoMetadata,
    setUploading: setIsUploading,
    title,
    currentNodeId: currentNode.id,
  })

  const startUpload = useCallback(() => {
    if (!file) {
      return null
    }

    return uploadFile(file, uploadSuccessfully)
  }, [file, uploadFile, uploadSuccessfully])

  useEffect(() => {
    if (!file || !videoMetadata) {
      return undefined
    }

    setIsUploading(true)
    startUpload()

    return cancelUpload
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, videoMetadata])

  const handleSave = () => {
    onSave({ ...currentNode, ...node, title })
    close()
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  if (!currentNode) return null

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: handleSave,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <div className={styles.FooterControl}>
              {isUploading ? null : (
                <ButtonNew
                  color={'secondary'}
                  size={'medium'}
                  onClick={uploadWithFilePicker}
                  startIcon={
                    <IconWrapper>
                      <ReplaceIcon />
                    </IconWrapper>
                  }
                >
                  <Trans i18nKey="replace_video" />
                </ButtonNew>
              )}

              <DoneButton onClick={handleSave} disabled={isUploading}>
                <Trans i18nKey="action_done" />
              </DoneButton>
            </div>
          }
        >
          {!isPageEditor && (
            <InputModalTitle
              value={title}
              placeholder={t('add_an_update_title_optional')}
              onChange={setTitle}
            />
          )}

          {isUploading ? (
            <VideoStreamingUploadingProgress
              progress={progress}
              error={error}
              startUpload={startUpload}
            />
          ) : (
            <VideoStreaming
              boardId={boardId}
              folderId={folderId}
              nodeId={node?.id || currentNode.id}
            />
          )}
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditVideoStreaming
