import { useDispatch } from 'react-redux'

import { generateNodeId } from '@helpers/NodeIdGenerator'

import { contentActions } from '../../../../actions'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

import { INodeProps } from '../models/IUseNodeCreatorAPI'
import { NodeType } from '../../../models'

const useNodeCreatorAPI = (boardId: string, folderId: string) => {
  const dispatch = useDispatch()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const create = (node: INodeProps) => {
    if (!isApiAvailableOrNotify()) return false

    const { nodeId, key } = generateNodeId()

    if (node.type !== NodeType.PROFILE) {
      const updatedNode = {
        ...node,
        title: node.title?.trim(),
        text: node.text?.trim(),
      }

      dispatch(contentActions.addContent(boardId, folderId, key, updatedNode))
    }

    if (node.type === NodeType.PROFILE) {
      dispatch(contentActions.addContent(boardId, folderId, key, node))
    }

    return nodeId
  }

  return create
}

export default useNodeCreatorAPI
