import { useCallback } from 'react'
import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

export const useProfileImageEvents = () => {
  const { trigger } = useLogEvents()

  const profileImageRepositionView = useCallback(
    (boardId: string, keyId: string, linkShortcut: string | null) =>
      trigger(EventCategories.APP_PAGE_IMAGE_REPOSITION_VIEW, {
        board_id: boardId,
        key_id: keyId,
        link_shortcut: linkShortcut || undefined,
      }),
    [trigger],
  )

  const profileImageRepositionDone = useCallback(
    (repositioned: boolean) =>
      trigger(EventCategories.APP_PAGE_IMAGE_REPOSITION_DONE, {
        repositioned,
      }),
    [trigger],
  )

  return {
    profileImageRepositionView,
    profileImageRepositionDone,
  } as const
}
