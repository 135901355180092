import {
  TemplateCategoryModel,
  TemplateModel,
} from '@features/templateGallery/models/Template'

import { TemplateCategory } from '_firebase/models/templates'
import { transformFolderFromShortcut } from '_firebase/shortcutTransformers'

const trasformTemplateCategory = async (templateCategory: TemplateCategory) => {
  const templates = (await Promise.all(
    templateCategory.templates.map(async (template) => ({
      id: template.id,
      thumbnail: template.thumbnail,
      data: await transformFolderFromShortcut('', template.data, ''),
    })),
  )) as TemplateModel[]

  return {
    id: templateCategory.id,
    nameFormatted: templateCategory.nameFormatted,
    thumbnail: templateCategory.thumbnail,
    templates,
  } as TemplateCategoryModel
}

export default trasformTemplateCategory
