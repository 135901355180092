import React, { useEffect, useState } from 'react'

import useBoard from '@hooks/useBoard'
import useBoardActions from '@hooks/useBoardActions'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import useFolderApi from '../hooks/useFolderApi'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import { AddNodeData } from '../models'

import ContentTitle, { ContentTitleSize } from './ContentTitle'
import FolderActions from './FolderActions'
import ContentIcon from './ContentIcon'

import styles from './FolderTitle.module.css'

const FolderTitle: React.FC = () => {
  /**
   * TODO: This is an ugly hack to avoid duplicate API calls, should be fixed
   * with new route like /${boardId}/new-folder
   */
  const [createdFolderKeys, setCreatedFolderKeys] = useState<string[]>([])

  const { create, update } = useFolderApi()
  const { canEditFolderTitle } = useBoardActions()
  const { parent, folder, isRoot, boardId, isReadOnly } = useBoard()
  const {
    renameFolderTooltip,
    defaultFolderTitle,
    addFolderPlaceholder,
    changeEmojiTooltip,
    deleteFolderAction,
    deleteFolderTitle,
    deleteFolderConfirmation,
    folderDeleted,
  } = useToolbarTranslations()
  const [newTitle, setNewTitle] = useState<string>(folder?.title)

  useEffect(() => {
    if (folder) {
      setNewTitle(folder.title || '')
    }

    // eslint-disable-next-line
  }, [folder?.title])

  if (isRoot) {
    return null
  }

  const handleTitleChange = (title: string): void => {
    setNewTitle(title)
  }

  const handleTitleSubmit = (title: string): void => {
    const submitTitle = title || defaultFolderTitle()
    setNewTitle(submitTitle)

    if (!parent || isReadOnly || folder.title === submitTitle) {
      return
    }

    const node: AddNodeData = {
      title: submitTitle,
      icon: folder.icon,
    }

    if (folder?.key && !createdFolderKeys.includes(folder.key)) {
      create(boardId, parent.id, folder.key || '', node, ViewSource.board)
      setCreatedFolderKeys((prev) => [...prev, folder?.key || ''])
      return
    }

    update(boardId, parent.id, folder.id, node)
  }

  const handleIconChange = (icon: string): void => {
    const node = {
      title: folder.title,
      icon,
    }

    update(boardId, parent.id, folder.id, node)
  }

  return (
    <div className={styles.Inline}>
      <ContentIcon
        readOnly={!canEditFolderTitle}
        className={styles.Icon}
        icon={folder?.icon}
        onChange={handleIconChange}
        tooltip={changeEmojiTooltip()}
      />

      <ContentTitle
        title={newTitle}
        readOnly={!canEditFolderTitle}
        size={ContentTitleSize.Default}
        autofocus={!newTitle}
        onBlur={handleTitleSubmit}
        onEnter={handleTitleSubmit}
        onChange={handleTitleChange}
        tooltip={renameFolderTooltip()}
        placeholder={addFolderPlaceholder()}
      />

      <FolderActions
        deleteAction={deleteFolderAction()}
        deleteTitle={deleteFolderTitle()}
        deleteConfirmation={deleteFolderConfirmation()}
        deletedToastMsg={folderDeleted()}
      />
    </div>
  )
}

export default React.memo(FolderTitle)
