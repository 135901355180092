import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { ReactComponent as LogoIcon } from '../../../../icons/logo.svg'
import Button from '../../../../elements/Button'
import { doSignOut } from '../../../../_firebase/auth'
import styles from './WelcomeHeader.module.css'

const WelcomeHeader = () => {
  return (
    <div className={styles.HeaderWrapper}>
      <Link to={`${process.env.REACT_APP_BOARDS_APP_URL}`}>
        <LogoIcon className={styles.Logo} />
      </Link>
      <Button className={styles.Button} onClick={doSignOut}>
        <Trans i18nKey={'open_boards_log_out'} />
      </Button>
    </div>
  )
}

export default WelcomeHeader
