export const enum CLIENT_CONFIG_FEATURES {
  EMAIL_ADDRESS = 'emailAddress',
  CLIENT_CONFIGURATION = 'clientConfiguration',
}

export type DefaultClientConfig = {
  reverseTrial: {
    isActive: boolean
  }
}

export type EmailAdressClientConfig = {
  format: RegExp
  frequentlyUsedDomains: string[]
  topLevelDomains: string[]
}

export interface ClientConfig {
  [CLIENT_CONFIG_FEATURES.EMAIL_ADDRESS]: EmailAdressClientConfig
  [CLIENT_CONFIG_FEATURES.CLIENT_CONFIGURATION]: DefaultClientConfig
}
