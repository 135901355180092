import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

const useSidebarBanners = () => {
  const {
    profile: { clients, uiState },
    loaded,
  } = useProfileInformation()
  const hasAndroidOrIOS =
    clients?.includes('android') || clients?.includes('ios')

  return {
    loaded,
    uiState,
    hasAndroidOrIOS,
  } as const
}

export default useSidebarBanners
