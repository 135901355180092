import ContentVideo from './ContentVideo'
import useVideo from '../../../../hooks/useVideo'

interface Props {
  cover: string
  text: string
  count: number
}

const ContentVideoWrapper = ({ cover, text, count }: Props) => {
  const { thumbnail } = useVideo(cover)

  return <ContentVideo cover={thumbnail} text={text} count={count} />
}

export default ContentVideoWrapper
