import React from 'react'

import useBoardsOrder from '@features/boardsMenu/hooks/useBoardsOrder'
import useRouterParams from '@hooks/useRouterParams'
import BoardMenuItem from '@features/boardsMenu/components/BoardMenuItem'

import styles from './BoardsPagesSection.module.css'

const BoardsPagesSection = () => {
  const { order } = useBoardsOrder()
  const { boardId: selectedBoardId, folderId, bid } = useRouterParams()
  return (
    <div className={styles.BoardsPagesMenuItem}>
      {order.map((boardId) => {
        const isBoardSelected = boardId === selectedBoardId
        const initialStateCheck = boardId?.split('-')?.[1] === bid
        const selectedFolderId = isBoardSelected ? folderId : undefined

        return (
          <BoardMenuItem
            key={boardId}
            boardId={boardId}
            inactive
            isBoardSelected={isBoardSelected}
            selectedFolderId={selectedFolderId}
            initialStateCheck={initialStateCheck}
            isPageBoard
          />
        )
      })}
    </div>
  )
}

export default BoardsPagesSection
