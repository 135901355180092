import { AddPageData } from '@features/toolbar/models'

export const enum ACTION_TYPE {
  ADD_PAGE = 'ADD_PAGE',
}

const addPage = (
  board: string,
  folder: string,
  key: string,
  properties: AddPageData,
) => ({
  type: ACTION_TYPE.ADD_PAGE,
  board,
  folder,
  key,
  properties,
})

const actions = {
  addPage,
} as const

export default actions
