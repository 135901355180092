import React, { forwardRef, memo, useEffect, useState } from 'react'
import {
  ButtonNew,
  nextTick,
  RichTextField,
  useDebounceCallback,
  useDialog,
} from 'boards-web-ui'

import { MessageProperties } from '../../../models'
import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'

import { useCreateMessage } from '../hooks/useCreateMessage'
import { useUpdatesTools } from '../hooks/useUpdatesTools'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'

import PostMessageConfirmation from './PostMessageConfirmation'
import styles from './UpdatesCreateMessage.module.css'

type Props = {
  disabled?: boolean
  onChange: (properties: MessageProperties) => void
}
export const UpdatesCreateMessage = forwardRef<HTMLDivElement, Props>(
  ({ onChange, disabled, ...props }, ref) => {
    const { open, close } = useDialog()
    const { profile } = useProfileInformation()
    const { limitReached } = useUserFeatures(Features.messages)
    const [triggerFocusState, setTriggerFocusState] = useState<boolean>(false)
    const {
      board,
      boardId,
      savedProperties,
      saveProperties,
      removeProperties,
    } = useCreateMessage()

    const { triggerPostUpdates, postUpdates, addNameDismissed, viewPostPanel } =
      useUpdatesEvents()

    const { postTitlePlaceholder, postBodyPlaceholder, postButtonValue } =
      useUpdatesTranslations()

    const { isOpen } = useUpdatesTools()

    const [properties, setProperties] =
      useState<MessageProperties>(savedProperties)

    const emitTriggerFocusState = () => {
      setTriggerFocusState(true)
      const timer = setTimeout(() => {
        setTriggerFocusState(false)
        clearTimeout(timer)
      }, 200)
    }

    useEffect(() => {
      if (isOpen) {
        viewPostPanel()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
      if (isOpen) {
        emitTriggerFocusState()
      }
    }, [boardId, isOpen])

    const onPropertiesSave = useDebounceCallback((_properties) => {
      saveProperties(_properties)
    }, 600)

    const onPropertiesChange = async (property: Partial<MessageProperties>) => {
      setProperties((prevState) => ({ ...prevState, ...property }))
      await nextTick()
      onChange(properties)
      onPropertiesSave(properties)
    }

    const onPostMessage = (): void => {
      triggerPostUpdates()
      open(
        <PostMessageConfirmation
          properties={properties}
          onConfirm={() => {
            postUpdates()
            close()
            removeProperties()
            setProperties({ title: '', text: '' })
          }}
        />,
        {
          closeOnOverlayClick: !!profile?.name,
          onCloseClick: () => {
            if (!profile?.name) {
              addNameDismissed()
            }
            emitTriggerFocusState()
          },
        },
      )
    }

    const isDisabled = (): boolean => {
      const { text, title } = properties
      return (
        !!disabled ||
        (text.trim().length === 0 && title.trim().length === 0) ||
        limitReached
      )
    }

    return (
      <div className={styles.Root} {...props} ref={ref}>
        <RichTextField
          highlightLinks
          disabled={disabled}
          className={styles.Title}
          value={properties.title}
          onChange={(event) =>
            onPropertiesChange({ title: event.target.value })
          }
          placeholder={
            <span className={styles.PlaceholderTitle}>
              {postTitlePlaceholder()}
            </span>
          }
        />

        <RichTextField
          autoFocus={triggerFocusState}
          highlightLinks
          disabled={disabled}
          className={styles.Body}
          value={properties.text}
          onChange={(event) => onPropertiesChange({ text: event.target.value })}
          placeholder={
            <span
              className={styles.PlaceholderBody}
              dangerouslySetInnerHTML={{
                __html: postBodyPlaceholder(
                  board?.title || '',
                  board?.icon || '',
                ),
              }}
            />
          }
        />

        <div className={styles.Controls}>
          <ButtonNew disabled={isDisabled()} onClick={onPostMessage}>
            {postButtonValue()}
          </ButtonNew>
        </div>
      </div>
    )
  },
)

export default memo(UpdatesCreateMessage)
