import React, { PropsWithChildren, createElement, ReactElement } from 'react'

type Props = PropsWithChildren<{
  if?: boolean
  with: (typeof createElement.arguments)[0]
  props: (typeof createElement.arguments)[1]
}>

const Wrap: React.FC<Props> = ({
  if: condition,
  with: wrapper,
  props,
  children,
}) => {
  if (condition) {
    return createElement(wrapper, props, [children])
  }

  return children as ReactElement
}

export default Wrap
