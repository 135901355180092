import React from 'react'
import AddShortcutIdButton from './AddShortcutIdButton'
import useBoard from '../../../../hooks/useBoard'

const ShareShortcutButton = () => {
  const { isReadOnly, folder } = useBoard()

  const isPage = folder?.isPage

  return <AddShortcutIdButton alignTooltipLeft={isReadOnly || isPage} />
}

/** @deprecated Use need to refactor this component */
export default ShareShortcutButton
