import { Trans } from 'react-i18next'

import styles from './VideoError.module.css'

const VideoError = () => (
  <p className={styles.Root}>
    <Trans i18nKey="linkpdf_invalid_url_msg" />
  </p>
)

export default VideoError
