import { useDialog } from 'boards-web-ui'
import { ContentPickersEnum, EntityDialogEnum } from '@features/pickers'
import { AddNewEntity } from './AddNewEntity'
import styles from './AddNewEntity.module.css'

export const useAddNewEntityDialog = () => {
  const { open } = useDialog()

  const openAddNewEntityDialog = ({
    entity,
    picker,
  }: {
    entity: EntityDialogEnum
    picker: ContentPickersEnum
  }) => {
    open(<AddNewEntity entity={entity} picker={picker} />, {
      closeOnOverlayClick: false,
      className: styles.Dialog,
    })
  }

  return openAddNewEntityDialog
}
