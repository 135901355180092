import React, { useContext, useLayoutEffect, useRef } from 'react'
import { ScrollAreaContainer } from '../../models/ScrollArea'
import ScrollAreasContext from './ScrollAreasContext'

import styles from './ScrollArea.module.css'

interface Props {
  area: ScrollAreaContainer
  className?: string
  children: React.ReactElement
}
const ScrollArea = ({ className, area, children }: Props) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>
  const endRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const { addArea, removeArea } = useContext(ScrollAreasContext)

  useLayoutEffect(() => {
    addArea(area, ref, endRef)

    return () => removeArea(area)
  }, [area, ref, endRef, addArea, removeArea])

  return (
    <div ref={ref} className={[styles.Root, className].join(' ')}>
      {children}
      <div ref={endRef} />
    </div>
  )
}

export default ScrollArea
