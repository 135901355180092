import { Trans } from 'react-i18next'
import styles from './TopContentEmptyState.module.css'

interface Props {
  showNoContentMsg: boolean
}

const TopContentEmptyState = ({ showNoContentMsg }: Props) => (
  <div className={styles.Root}>
    <h4 className={styles.Title}>
      <Trans i18nKey="insights_tab_no_content_title" />
    </h4>
    <p className={styles.Message}>
      {showNoContentMsg && <Trans i18nKey="insights_tab_no_content_subtitle" />}
      {!showNoContentMsg && (
        <Trans i18nKey="insights_tab_no_interaction_in_period" />
      )}
    </p>
  </div>
)

export default TopContentEmptyState
