import { useTranslation } from 'react-i18next'

const useStyledSubtitle = (
  mainText: string,
  placeholderText: string,
): [string, string, string] => {
  const { t } = useTranslation()

  const fullText = t(mainText)
  const placeholder = t(placeholderText)
  const startIndex = fullText.indexOf(placeholder)
  const endIndex = startIndex + placeholder.length

  const before = fullText.substring(0, startIndex)
  const bold = fullText.substring(startIndex, endIndex)
  const after = fullText.substring(endIndex)

  return [before, bold, after]
}

export default useStyledSubtitle
