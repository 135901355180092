import Spinner, { Justify, Size } from '../../../components/Spinner'
import { Node } from '../../../models'
import { ReactComponent as PlayBtnIcon } from '../icons/PlayBtn.svg'

import styles from './NodeVideo.module.css'

interface Props {
  node: Node
}
const NodeVideo = ({ node }: Props) => {
  const isLoading = !node?.thumbnail || node?.isProcessing || false

  return (
    <div className={`${styles.Root} ${isLoading ? styles.LoadingWidth : ''}`}>
      <Spinner size={Size.SMALL} justify={Justify.START} spinning={isLoading}>
        <div className={styles.VideoWrapper}>
          <img
            className={styles.Image}
            src={node?.thumbnail}
            alt={node.title}
            draggable="false"
            loading="lazy"
          />
          <PlayBtnIcon className={styles.PlayBtnIcon} />
        </div>
      </Spinner>
    </div>
  )
}

export default NodeVideo
