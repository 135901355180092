import {
  REVERSE_TRIAL_LOCAL_STORAGE_KEY,
  ReverseTrialLocalStorageData,
  USER_PREFERENCE_FIELDS,
} from '@features/reverseTrial/models/reverseTrialModels'
import useLocalStorage from '@hooks/useLocalStorage'
import { useAuth } from '@hooks/useAuth'
import { useCallback } from 'react'

const getUpdateLocalStorageDataByUserId = ({
  localStorage,
  userId,
  hasUserClosedCountdownTimer,
  shouldShowReverseTrialToastMessage,
}: {
  localStorage: ReverseTrialLocalStorageData[]
  userId: string
  hasUserClosedCountdownTimer?: boolean
  shouldShowReverseTrialToastMessage?: boolean
}): ReverseTrialLocalStorageData[] => {
  const localStorageClone = [...localStorage]

  const existingIndex = localStorageClone.findIndex(
    (pref) => pref.userId === userId,
  )

  const update = {
    userId,
    ...(hasUserClosedCountdownTimer !== undefined && {
      hasUserClosedCountdownTimer,
    }),
    ...(shouldShowReverseTrialToastMessage !== undefined && {
      shouldShowReverseTrialToastMessage,
    }),
  }
  if (existingIndex !== -1) {
    localStorageClone[existingIndex] = update
  } else {
    localStorageClone.push(update)
  }

  return localStorageClone
}

const useReverseTrialLocalStorageHelper = () => {
  const { user } = useAuth()
  const userId = user?.uid

  const [reverseTrialLocalStoragePreferences, setReverseTrialUserPreferences] =
    useLocalStorage<ReverseTrialLocalStorageData[]>(
      REVERSE_TRIAL_LOCAL_STORAGE_KEY,
      [],
    )

  const currentUserPreference: ReverseTrialLocalStorageData | undefined =
    reverseTrialLocalStoragePreferences.find((item) => item.userId === userId)

  const setPreference = useCallback(
    (key: USER_PREFERENCE_FIELDS, value: boolean) => {
      if (userId) {
        const updatedPreferences: ReverseTrialLocalStorageData[] =
          getUpdateLocalStorageDataByUserId({
            localStorage: reverseTrialLocalStoragePreferences,
            userId,
            [key]: value,
          })
        setReverseTrialUserPreferences(updatedPreferences)
      }
    },
    [
      userId,
      reverseTrialLocalStoragePreferences,
      setReverseTrialUserPreferences,
    ],
  )

  return {
    currentUserPreference,
    setPreference,
  }
}

export default useReverseTrialLocalStorageHelper
