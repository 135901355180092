import React, { useEffect, useState } from 'react'
import { SpinnerIcon, CheckedIcon, Icon } from 'boards-web-ui'

import { useAuth } from '../../../../../hooks/useAuth'

const PageSingOut: React.FC = () => {
  const [pending, setPending] = useState(true)
  const { signOut } = useAuth()

  useEffect(() => {
    const logout = async () => {
      setPending(true)
      await signOut()
      localStorage.clear()
      setPending(false)
    }

    logout()
    // eslint-disable-next-line
  }, [])

  return pending ? (
    <p>
      Pending...{' '}
      <Icon size="16px" color="yellow">
        <SpinnerIcon />
      </Icon>
    </p>
  ) : (
    <p>
      Success{' '}
      <Icon size="16px" color="green">
        <CheckedIcon />
      </Icon>
    </p>
  )
}

export default PageSingOut
