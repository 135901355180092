import React, { FC } from 'react'
import {
  eEnvironment,
  Node as WebUiNode,
  NodeVideoStreaming,
} from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import usePageNodeTransformer from '@features/nodes/hooks/usePageNodeTransformer'
import { getPdfThumbnailWithPlaceholder } from '@helpers/getPdfThumbnailWithPlaceholder'
import { PAGE_PLACEHOLDER } from 'constants/pagePlaceholder'
import NodeText from './NodeText'
import NodeLink from './NodeLink'
import NodeImage from './NodeImage'
import NodeVideo from './NodeVideo'
import NodeAudio from './NodeAudio'
import NodePdf from './NodePdf'
import { Node, NodeType } from '../../../../models'

interface Props {
  node: Node
  isSelected: boolean
}
const NodeItem: FC<Props> = ({ node, isSelected }) => {
  const { t } = useTranslation()
  const transformPageNode = usePageNodeTransformer()
  const { type, title, text } = node

  if (type === NodeType.PAGE || type === NodeType.FOLDER) {
    return (
      <WebUiNode
        node={transformPageNode(node).node}
        titleBreakLineThreshold={100}
        environment={eEnvironment.Editor}
        nodeBackground={isSelected ? 'transparent' : undefined}
        pageFullbackThumbnail={PAGE_PLACEHOLDER}
      />
    )
  }
  return (
    <>
      {type === NodeType.PDF && (
        <NodePdf
          title={title}
          thumbnail={getPdfThumbnailWithPlaceholder(node)}
        />
      )}

      {type === NodeType.AUDIO && <NodeAudio node={node} />}

      {type === NodeType.TEXT && <NodeText title={title} text={text} />}

      {type === NodeType.LINK && <NodeLink title={title} text={text} />}

      {type === NodeType.IMAGE && <NodeImage node={node} />}

      {type === NodeType.VIDEO && <NodeVideo node={node} />}

      {type === NodeType.VIDEO_STREAMING && (
        <NodeVideoStreaming
          title={node.title}
          thumbnail={node?.thumbnail}
          isProcessing={node.isProcessing}
          processingLabel={t('label_processing_video')}
        />
      )}
    </>
  )
}

export default React.memo(NodeItem)
