import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AnimatedDialog, useDialog } from 'boards-web-ui'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import { VideoMetaData } from '@hooks/useUploadFileMetadata'
import { useSelectorFactory } from '@hooks/useSelectorFactory'

import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'
import DoneButton from '@ui/components/DoneButton'

import useUploadFirebase from '@hooks/useUploadFirebase'
import useRouterParams from '@hooks/useRouterParams'
import { useUploadSuccessfully } from '@features/nodeEditor/components/VideoStreaming/useUploadSuccessfully'
import { VideoStreamingUploadingProgress } from './VideoStreamingUploadingProgress'
import { getCurrentFolderId } from '../../../../../selectors'
import { Node, NodeType } from '../../../../models'
import { videoActions } from '../../../../../actions'
import ModalEditWithConfirmation from '../../../../../components/ModalEditWithConfirmation'
import VideoStreaming from '../VideoStreaming'
import InputModalTitle from '../../../../../elements/InputModalTitle'

import useNodeAPI from '../../hooks/useNodeAPI'
import useNodeDiscardChanges from '../../hooks/useNodeDiscardChanges'
import useAddVideoStreamingDialogs from '../../hooks/useAddVideoStreamingDialogs'

import {
  ICreateOrUpdateNode,
  INodeProps,
} from '../../models/IUseNodeCreatorAPI'

import styles from './AddVideoStreaming.module.css'

interface Props {
  file: File
  videoMetadata: VideoMetaData
  isTryFree: boolean
  isPageEditor?: boolean
  onSave: ICreateOrUpdateNode
  onUpdate: (id: string, node: INodeProps) => void
}
const AddVideoStreaming = ({
  file,
  videoMetadata,
  isPageEditor,
  isTryFree,
  onSave,
  onUpdate,
}: Props) => {
  const { close } = useDialog()
  const [node, setNode] = useState<Node>()
  const [uploading, setUploading] = useState(true)

  const currentFolderId = useSelectorFactory(getCurrentFolderId)
  const dispatch = useDispatch()
  const discardChanges = useNodeDiscardChanges()
  const { t } = useTranslation()
  const { boardId } = useRouterParams()
  const { error, progress, cancelUpload, uploadFile } = useUploadFirebase()
  const { title, setTitle } = useNodeAPI('', '')
  const { cancelUploadDialog } = useAddVideoStreamingDialogs()

  useEffect(() => {
    if (node?.id) {
      dispatch(videoActions.setVideoStreamingId(node.id))
    }
  }, [dispatch, node])

  const handleSave = () => {
    if (!node) return

    onUpdate(node?.id, {
      title,
      text: node?.text,
      type: NodeType.VIDEO_STREAMING,
    })
    dispatch(videoActions.setVideoStreamingId(''))
    dispatch(videoActions.setVideoStreamingUrl(''))
    close()
  }

  const uploadSuccessfully = useUploadSuccessfully({
    setNode,
    onSave,
    videoMetadata,
    setUploading,
    isTryFree,
  })

  const startUpload = useCallback(
    () => uploadFile(file, uploadSuccessfully),
    [file, uploadFile, uploadSuccessfully],
  )

  useEffect(() => {
    startUpload()

    return cancelUpload
  }, [startUpload, cancelUpload])

  const handleClose = () => {
    if (uploading) {
      cancelUploadDialog(() => {
        cancelUpload()
        close()
      })
      return
    }

    discardChanges()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: handleClose,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton onClick={handleSave} disabled={uploading}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          <div className={clsx(styles.Root, !isPageEditor && styles.WithTitle)}>
            {!isPageEditor && (
              <InputModalTitle
                value={title}
                placeholder={t('add_an_update_title_optional')}
                onChange={setTitle}
              />
            )}
            {uploading ? (
              <VideoStreamingUploadingProgress
                progress={progress}
                error={error}
                startUpload={startUpload}
              />
            ) : (
              node && (
                <VideoStreaming
                  boardId={boardId}
                  folderId={currentFolderId}
                  nodeId={node.id}
                  showDescriptionLabel
                />
              )
            )}
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default AddVideoStreaming
