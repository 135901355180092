import React, { FC, ReactNode, useCallback } from 'react'
import { AddSmallIcon, ButtonNew } from 'boards-web-ui'
import { Trans } from 'react-i18next'

import { useAddNewEntityDialog } from '@features/pickers/destinationPicker/AddNewEntity'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import useBoardsDialogs from '@features/boardsMenu/hooks/useBoardsDialogs'
import useBulkActionsPermissions from '@hooks/bulk/useBulkActionsPermissions'
import {
  ContentPickersEnum,
  EntityDialogEnum,
  useContentNavigation,
  useDestinationPickerActions,
} from '@features/pickers'

import useBoard from '@hooks/useBoard'
import { DestinationPickerSourceEventEnum } from '@features/analytics/useDestinationPickerEvents'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import styles from './DestinationPickerFooter.module.css'

interface IProps {
  picker: ContentPickersEnum
  onClose: (picker: ContentPickersEnum) => void
  contentType: string
  performActionCopy: ReactNode
  performActionSuccessMessage: string
  eventsOverrides?: {
    source: DestinationPickerSourceEventEnum
  }
}

export const DestinationPickerFooter: FC<IProps> = ({
  picker,
  onClose,
  contentType,
  performActionCopy,
  performActionSuccessMessage,
  eventsOverrides,
}) => {
  const { pickerBoardId, pickerFolderId } = useContentNavigation(picker)
  const { isFreePlanUser } = useReverseTrialContext()
  const openAddNewEntityDialog = useAddNewEntityDialog()
  const { limitReached } = useUserFeatures(Features.boards)
  const { openBoardsLimitReached } = useBoardsDialogs()
  const { runCopy, runMove } = useDestinationPickerActions({
    contentType,
    eventsOverrides,
  })

  const { isMoveDisabled, isCopyDisabled } = useBulkActionsPermissions({
    boardId: pickerBoardId,
    folderId: pickerFolderId,
  })

  const performCopyActionDisabled =
    picker === ContentPickersEnum.CopyPicker && isCopyDisabled
  const performMoveActionDisabled =
    picker === ContentPickersEnum.MovePicker && isMoveDisabled

  const isPerformActionDisabled =
    !pickerBoardId || performCopyActionDisabled || performMoveActionDisabled

  const { isPageBoard } = useBoard({ boardId: pickerBoardId })

  const handleSecondaryButtonClick = useCallback(() => {
    if (!pickerBoardId) {
      if (limitReached || isFreePlanUser) {
        openBoardsLimitReached(ViewSource.home)
        return
      }

      openAddNewEntityDialog({ entity: EntityDialogEnum.Board, picker })

      return
    }

    openAddNewEntityDialog({ entity: EntityDialogEnum.Folder, picker })
  }, [
    pickerBoardId,
    limitReached,
    openAddNewEntityDialog,
    openBoardsLimitReached,
    picker,
    isFreePlanUser,
  ])

  const handleBulkActionClick = useCallback(() => {
    const runAction =
      picker === ContentPickersEnum.CopyPicker ? runCopy : runMove

    runAction({ toastSuccessMessage: performActionSuccessMessage })
    onClose(picker)
  }, [picker, runCopy, runMove, performActionSuccessMessage, onClose])

  return (
    <div className={styles.Root}>
      <div className={styles.SecondaryButton}>
        {pickerBoardId && (performCopyActionDisabled || isPageBoard) ? null : (
          <ButtonNew
            color={'secondary'}
            startIcon={
              <IconWrapper size={16}>
                <AddSmallIcon />
              </IconWrapper>
            }
            onClick={handleSecondaryButtonClick}
            disabled={isFreePlanUser}
          >
            <Trans i18nKey={pickerBoardId ? 'create_folder' : 'create_board'} />
          </ButtonNew>
        )}
      </div>

      <ButtonNew
        disabled={isPerformActionDisabled}
        onClick={handleBulkActionClick}
      >
        {performActionCopy}
      </ButtonNew>
    </div>
  )
}
