import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { InputCheckbox, InputLabel, emailHelper } from 'boards-web-ui'

import useGeoLocation from '@hooks/useGeoLocation'
import { filterFirstError } from '@helpers/formErrors'
import { useAppClientConfig } from '@hooks/useAppClientConfig'
import GuestInputWrapper from './GuestInputWrapper'
import GuestTitle from './GuestTitle'
import Link from '../../../../elements/Link'
import Button from '../../../../elements/Button'
import InputField from '../../../../elements/InputField'
import * as ROUTES from '../../../../constants/routes'
import styles from './SignUpForm.module.css'
import { useSignUp } from '../hooks/useSignUp'
import GuestFooter from './GuestFooter'
import Layout from '../../layout/Layout'
import { helperValidateEmails } from '../../../../_firebase/generalFunctions'

const TERMS_URL = 'https://www.boards.com/terms'
const PRIVACY_URL = 'https://www.boards.com/privacy'

const SignUpForm = () => {
  const { t } = useTranslation()
  const clientConfig = useAppClientConfig()

  const { isGeoFromEuropeList, isGeoFromIsrael } = useGeoLocation()

  const [agreeEmailConsent, setAgreeEmailConsent] = useState(true)

  const showAgreeEmailConsent = isGeoFromEuropeList || isGeoFromIsrael

  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {},
  })

  const { submitting, onSignUp } = useSignUp(setError)
  const [searchParams, setSearchParams] = useSearchParams()
  const referralCode = searchParams.get('ref')
  const email = searchParams.get('email')
  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')
  const linkShortcut = searchParams.get('link-shortcut')

  useEffect(() => {
    if (email) {
      setValue('email', email)
      searchParams.delete('email')
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    try {
      if (
        !emailHelper.validateDomain(
          data.email,
          clientConfig.emailAddress.frequentlyUsedDomains,
        )
      ) {
        const validEmails = await helperValidateEmails([data.email], false)
        if (!validEmails.includes(data.email)) {
          setError('email', {
            message: 'errors.invalid_email_address',
          })
          return
        }
      }

      await onSignUp(data, { agreeEmailConsent })
    } catch (error) {
      setError('email', {
        message: t('errors.invalid_email_address'),
      })
    }
  }

  const firstError = filterFirstError(errors, ['name', 'email', 'password'])

  return (
    <Layout>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <GuestInputWrapper>
          <GuestTitle>
            <Trans i18nKey="action_sign_up" />
          </GuestTitle>
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            placeholder={t('signup_credential_name')}
            {...register('name', {
              required: t('singup_error_please_enter_name'),
              minLength: {
                value: 3,
                message: t('singup_error_min_characters', {
                  field: 'Name',
                  min: 3,
                }),
              },
              maxLength: 30,
            })}
            errors={firstError}
            maxLength="30"
          />
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            placeholder={t('authentication_screen_email_placholder')}
            {...register('email', {
              required: t('singup_error_no_email_filled'),
              pattern: {
                value: clientConfig.emailAddress.format,
                message: t('errors.invalid_email_address'),
              },
              validate: {
                tld: (value) =>
                  emailHelper.validateTLD(
                    value,
                    clientConfig.emailAddress.topLevelDomains,
                  ) || t('errors.invalid_email_address'),
              },
            })}
            errors={firstError}
          />
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            type="password"
            placeholder={t('authentication_screen_password')}
            {...register('password', {
              required: t('singup_error_no_password_filled'),
              minLength: {
                value: 6,
                message: t('singup_error_min_characters', {
                  field: 'Password',
                  min: 6,
                }),
              },
            })}
            errors={firstError}
          />
        </GuestInputWrapper>

        {showAgreeEmailConsent ? (
          <div className={styles.EmailConsent}>
            <InputLabel>
              <InputCheckbox
                id={'agreeEmailConsent'}
                checked={agreeEmailConsent}
                onChange={() => setAgreeEmailConsent((prevState) => !prevState)}
              />

              <Trans i18nKey="signup_email_consent_checkbox" />
            </InputLabel>
          </div>
        ) : null}

        <Button loading={submitting} size={'lg'}>
          <Trans i18nKey="action_sign_up" />
        </Button>

        <GuestFooter
          link={`${ROUTES.SIGN_IN}${
            referralCode ? `?ref=${referralCode}` : ''
          }${
            shortcutId
              ? `?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}&link-shortcut=${linkShortcut}`
              : ''
          }`}
          linkKey="action_sign_in"
          msgKey="authentication_screen_already_have_an_account"
        />

        <div className={styles.Disclaimer}>
          <Trans
            i18nKey={
              showAgreeEmailConsent
                ? 'action_read_our_privacy_policy_full_new'
                : 'signup_disclaimer'
            }
            components={{
              termsLink: <Link to={TERMS_URL} target="_blank" />,
              privacyLink: <Link to={PRIVACY_URL} target="_blank" />,
            }}
          />
        </div>
      </form>
    </Layout>
  )
}
export default SignUpForm
