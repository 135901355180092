import { Node } from 'app/models'
import settings from '../app/settings'

export const getPdfThumbnailWithPlaceholder = (
  node: Node | undefined,
): string | undefined => {
  return typeof node === 'undefined'
    ? undefined
    : node.thumbnail || settings.nodePdfErrorPlaceholder
}
