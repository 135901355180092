import { newKey } from '@helpers/NodeIdGenerator'
import { CheckoutFormProduct } from 'boards-web-ui'

export const DEFAULT_NEW_PRODUCT: CheckoutFormProduct = {
  id: newKey(),
  type: 'product',
  label: '',
  description: '',
  price: 10,
  maxQuantity: 5,
}

export const DEFAULT_QUANTITY = '1'
export const DEFAULT_MIN_QUANTITY = 1
export const DEFAULT_MAX_QUANTITY = 20
export const CHECKOUT_FIELDS_ALLOW_TO_CHANGE = [
  'fields',
  'currency',
  'shippingFee',
  'response',
  'minOrder',
  'email',
  'paymentProviders',
]
export const CHECKOUT_FIELDS_ALLOW_TO_CHANGE_WITHOUT_GOOGLE = [
  'fields',
  'currency',
  'shippingFee',
  'response',
  'minOrder',
  'email',
  'paymentProviders',
]
