/* eslint no-console: ["error", { allow: ["error"] }] */
import { useCallback, useEffect, useState } from 'react'
import { PricePreviewResponse } from '@paddle/paddle-js'

import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import usePaddleAPI from '@features/payments/hooks/usePaddleAPI'
import transformSubscription from '@features/payments/utils/subscriptionTransformer'
import transformformPricePreview from '../utils/productTransformer'

const usePaywallProducts = (productIds: string[]) => {
  const { getProductPrices } = usePaddleAPI()
  const { plans } = useSubscriptionPlans()
  const [sourceProductIds, setSourceProductIds] = useState<string[]>([])
  const [sourceProducts, setProducts] = useState<PricePreviewResponse>()
  const subscriptions = transformSubscription(plans)
  const products = transformformPricePreview(subscriptions, sourceProducts)

  const firstPriceDiscountId = plans
    .map((p) => p.subscriptions.map((s) => s.productDiscountId))
    .flat()
    .find((s) => s !== undefined)

  const fetchProducts = useCallback(async () => {
    try {
      const prices = await getProductPrices(productIds, firstPriceDiscountId)
      if (prices) {
        setProducts(prices)
      }
    } catch (error) {
      console.error(error)
    }
  }, [productIds, firstPriceDiscountId, getProductPrices])

  useEffect(() => {
    if (
      productIds.length > 0 &&
      !productIds.every((id) => sourceProductIds.includes(id))
    ) {
      fetchProducts()
      setSourceProductIds(productIds)
    }
  }, [productIds, sourceProductIds, fetchProducts])

  const getProductByPeriod = (productId: string) => {
    if (products) {
      return sourceProducts?.data.details.lineItems.find(
        (p) => p.price.id === productId,
      )
    }

    return undefined
  }

  return {
    products,
    subscriptions,
    getProductByPeriod,
  } as const
}

export default usePaywallProducts
