/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect } from 'react'
import { configurationObjectFromEnv } from 'utils/configurationObjectFromEnv'
import { ENV_KEYS } from 'utils/envKeys'

const { customerIoSiteId } = configurationObjectFromEnv<{
  customerIoSiteId: string
}>({
  customerIoSiteId: ENV_KEYS.CUSTOMER_IO_SITE_ID,
})

const CustomerIO: FC = () => {
  useEffect(() => {
    const _cio = (window as any)._cio || []
    ;(window as any)._cio = _cio

    let c: number

    const a: (f: string) => (...args: any[]) => void = function (f) {
      return function (...args: any[]) {
        _cio.push([f].concat(args))
      }
    }

    const b: string[] = [
      'load',
      'identify',
      'sidentify',
      'track',
      'page',
      // 'on',
      // 'off',
    ]

    for (c = 0; c < b.length; c += 1) {
      _cio[b[c]] = a(b[c])
    }

    const t = document.createElement('script')
    const s = document.getElementsByTagName('script')[0]
    t.async = true
    t.id = 'cio-tracker'
    t.setAttribute('data-site-id', customerIoSiteId)
    t.src = 'https://assets.customer.io/assets/track.js'

    if (s.parentNode) {
      s.parentNode.insertBefore(t, s)
    }
  }, [])

  return null
}

export default CustomerIO
