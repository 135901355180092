import { parseUrl } from 'boards-web-ui'

const useUpdatePdfUrl = () => (text: string, title: string) => {
  const { pathname } = parseUrl(text)

  const url = process.env.REACT_APP_PDF_URL
  const fileId = pathname
    .split('/')[1]
    .trim()
    .replace(/\.pdf$/, '')

  try {
    const newTitle = encodeURI(
      title.trim().toLowerCase().split(' ').join('-').replace(/\//g, '_'),
    )
    return `${url}/${fileId}/${newTitle || 'file'}.pdf`
  } catch (error) {
    return `${url}/${fileId}/file.pdf`
  }
}

export default useUpdatePdfUrl
