import React, { FC, memo, useEffect } from 'react'

import { BackIcon, ButtonIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'

import { useUpdatesMessages } from '../hooks/useUpdatesMessages'
import { useUpdatesMessage } from '../hooks/useUpdatesMessage'

import UpdatesMessage from './UpdatesMessage'
import UpdatesMessageMenu from './UpdatesMessageMenu'
import styles from './UpdatesSelected.module.css'

type Props = {
  onClose: () => void
  onDelete: () => void
}

const UpdatesSelected: FC<Props> = ({ onClose, onDelete }) => {
  const { updateView } = useUpdatesEvents()
  const { selectedMessage, viewOnly } = useUpdatesMessages()
  const { canRemove, remove, unselect } = useUpdatesMessage(
    selectedMessage ? selectedMessage.id : null,
  )

  useEffect(() => {
    if (selectedMessage) {
      updateView(
        selectedMessage.id,
        selectedMessage.node,
        selectedMessage.unread,
      )
    }
  }, [updateView, selectedMessage])

  if (!selectedMessage) {
    return null
  }

  return (
    <div className={styles.Root}>
      <div className={styles.Controls}>
        <Tooltip data={<TooltipContent>Back</TooltipContent>}>
          <ButtonIcon
            size={38}
            round
            variant="gray"
            onClick={() => {
              unselect()
              onClose()
            }}
          >
            <IconWrapper>
              <BackIcon />
            </IconWrapper>
          </ButtonIcon>
        </Tooltip>

        {!viewOnly && canRemove && (
          <UpdatesMessageMenu
            onDelete={() => {
              remove()
              onDelete()
            }}
          />
        )}
      </div>

      <UpdatesMessage message={selectedMessage} />
    </div>
  )
}

export default memo(UpdatesSelected)
