import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { TPageNodeForm } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { FORMS_ACTION_IDS } from '@features/forms/models'

import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import { EditFormsProvider } from '../providers'
import EditFormsDialog from './EditFormsDialog'

interface Props {
  isNew?: boolean
  initialScreen?: FORMS_ACTION_IDS
  initialNode?: TPageNodeForm
  onCancel: () => void
  onSave: ICreateOrUpdateNode
}
const EditForms: FC<Props> = ({
  isNew,
  initialScreen = FORMS_ACTION_IDS.FIELDS,
  initialNode,
  onSave,
  onCancel,
}) => {
  const {
    profile: { plan },
  } = useProfileInformation()
  const { openPaywall } = usePaywall()
  const { boardId, isOwnBoard } = useBoard()
  const { pageId } = useParams()

  const onPaywall = () => {
    openPaywall({ source: PaywallSourceEnum.FORM_POST_SUBMISSION })
  }

  return (
    <EditFormsProvider
      initialScreen={initialScreen}
      boardId={boardId}
      isOwn={isOwnBoard}
      isNew={isNew}
      pageId={pageId || ''}
      initialForm={initialNode}
      advancedForms={plan?.features?.advancedForms}
      onSave={onSave}
      onCancel={onCancel}
      onPaywall={onPaywall}
    >
      <EditFormsDialog />
    </EditFormsProvider>
  )
}

export default EditForms
