import { FC, PropsWithChildren, ReactElement } from 'react'
import { BubbleBackground, ConfettiIcon } from 'boards-web-ui'

import styles from './ConfetisBackground.module.css'

type Props = PropsWithChildren<{
  hasConfetis?: boolean
  isMobile?: boolean
  header?: ReactElement
}>
const ConfetisBackground: FC<Props> = ({
  header,
  isMobile,
  hasConfetis,
  children,
}) => {
  if (isMobile) {
    return (
      <div className={styles.Mobile}>
        {header}
        <div className={styles.MobileContent}>
          {hasConfetis && (
            <div className={styles.ConfettiContainer}>
              <ConfettiIcon
                width={'auto'}
                height={'auto'}
                preserveAspectRatio={'none'}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    )
  }

  if (hasConfetis) {
    return (
      <div className={styles.ConfetisBackground}>
        {header}
        {children}
      </div>
    )
  }

  return (
    <BubbleBackground>
      <div className={styles.BubbleContainer}>
        {header}
        {children}
      </div>
    </BubbleBackground>
  )
}

export default ConfetisBackground
