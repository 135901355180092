import { useTranslation } from 'react-i18next'
import useNodeClipboard from '../../nodeEditor/hooks/useNodeClipboard'
import { Folder, Node } from '../../../models'
import PreviewModal from './PreviewModal'

import styles from './PreviewImage.module.css'

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const PreviewImage = ({
  node,
  prev,
  next,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
}: Props) => {
  const { t } = useTranslation()
  const { copyToClipboard } = useNodeClipboard()

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyToClipboard(node)}
      prev={prev}
      next={next}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <img className={styles.Image} src={node.text} alt={node.title} />
      </div>
    </PreviewModal>
  )
}

export default PreviewImage
