export async function getVimeoVideoInfo(videoId: string) {
  const url = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`
  const response = await fetch(url)
  const info = await response.json()

  return info
    ? {
        title: info.title,
        thumbnail: info.thumbnail_url,
      }
    : null
}
