import { FC } from 'react'
import { Trans } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ArrowBackIcon,
  ButtonIcon,
  CloseIcon,
  Grid,
  Icon,
  LogoBigIcon,
  Tooltip,
  TooltipContent,
  TooltipPositions,
  useDialog,
} from 'boards-web-ui'
import { Avatar } from '@ui/components/Avatar'
import { randomSeededColor } from '@helpers/index'

import { EDITOR } from 'constants/routes'
import { PROFILING } from 'app/constants/routes'
import StickyBottomBar from 'elements/StickyBottomBar'
import { User } from '@firebase/auth'

import styles from './PageHeader.module.css'

interface Props {
  user?: User | null
  name?: string
  isMobile?: boolean
  hideBackButton?: boolean
  onLogoutClick?: () => void
}
interface LocationState {
  previousRoute?: string
}
const PageHeader: FC<Props> = ({
  user,
  name,
  isMobile,
  hideBackButton,
  onLogoutClick,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const color = randomSeededColor(name)
  const { open, close } = useDialog()

  const state = location.state as LocationState
  const canGoBack = location.key !== 'default'

  const handleClickBack = () => {
    if (canGoBack && state?.previousRoute !== PROFILING) {
      navigate(-1)
    }

    navigate(EDITOR)
  }

  const handleAvatarClick = () => {
    open(
      <StickyBottomBar withMargins withHigherZIndex>
        <div className={styles.CloseButtonWrapper}>
          <button onClick={close} aria-label="Close">
            <Icon size="19px" color="#3A4DDF">
              <CloseIcon />
            </Icon>
          </button>
        </div>
        <p className={styles.Email}>{user?.email}</p>
        {onLogoutClick && (
          <button className={styles.LogoutButtonMobile} onClick={onLogoutClick}>
            <ArrowBackIcon className={styles.BackIcon} />
            Log out
          </button>
        )}
      </StickyBottomBar>,
    )
  }

  return (
    <div className={isMobile ? styles.MobileHeader : styles.DesktopHeader}>
      <Grid justifyContent="space-between" width="100%">
        <Grid>
          {!isMobile && !hideBackButton && (
            <Tooltip
              data={
                <TooltipContent>
                  <Trans i18nKey={'action_back'} />
                </TooltipContent>
              }
              position={TooltipPositions.BOTTOM_LEFT}
            >
              <ButtonIcon
                className={styles.BackBtn}
                size={38}
                round
                variant="gray"
                onClick={handleClickBack}
              >
                <ArrowBackIcon />
              </ButtonIcon>
            </Tooltip>
          )}
          <LogoBigIcon width={92} height={25} />
        </Grid>
        {name && (
          <button
            type="button"
            onClick={isMobile ? handleAvatarClick : undefined}
          >
            <Avatar
              className={styles.Avatar}
              size={isMobile ? 38 : 34}
              color={color}
            >
              {name.trim().charAt(0)}
            </Avatar>
          </button>
        )}
      </Grid>
    </div>
  )
}

export default PageHeader
