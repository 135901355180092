import { RefObject, useCallback, useState } from 'react'

type ReturnType = {
  rememberAnchor: () => void
  scrollToAnchor: () => void
  checkBottomPosition: () => void
}

export const useScrollAnchor = (
  scrollableElementRef: RefObject<HTMLDivElement>,
  bottomCheckElementRef?: RefObject<HTMLDivElement>,
  bottomMargin = 170,
): ReturnType => {
  const [scrollAnchorPosition, setScrollAnchorPosition] = useState<number>(0)

  const rememberAnchor = useCallback(() => {
    if (!scrollableElementRef || !scrollableElementRef.current) {
      return
    }

    setScrollAnchorPosition(scrollableElementRef.current.scrollTop)
    // eslint-disable-next-line
    scrollableElementRef.current.scrollTop = 0
  }, [scrollableElementRef, setScrollAnchorPosition])

  const scrollToAnchor = useCallback(() => {
    // setTimeout is used to avoid trigger scroll event on element at render tick;
    const timer = setTimeout(() => {
      if (!scrollableElementRef || !scrollableElementRef.current) {
        return
      }

      // eslint-disable-next-line
      scrollableElementRef.current.scrollTop = scrollAnchorPosition
      clearTimeout(timer)
    }, 50)
  }, [scrollableElementRef, scrollAnchorPosition])

  const checkBottomPosition = useCallback(() => {
    if (
      !bottomCheckElementRef ||
      !bottomCheckElementRef.current ||
      !scrollableElementRef ||
      !scrollableElementRef.current
    ) {
      return
    }

    const elemRect = bottomCheckElementRef.current.getBoundingClientRect()
    const bottomPushLine =
      (window.innerHeight || document.documentElement.clientHeight) -
      bottomMargin

    const scrollableRect = scrollableElementRef.current.getBoundingClientRect()

    if (!(elemRect.bottom <= bottomPushLine)) {
      // eslint-disable-next-line
      scrollableElementRef.current.scrollTop =
        elemRect.height - (scrollableRect.height - bottomMargin)
    }
  }, [scrollableElementRef, bottomCheckElementRef, bottomMargin])

  return {
    rememberAnchor,
    scrollToAnchor,
    checkBottomPosition,
  }
}
