import { useDialog } from 'boards-web-ui'

import useNodeEvents from '@features/analytics/useNodeEvents'
import EditLinkPdf from '../components/EditLinkPdf'
import { Node } from '../../../models'
import { IUseSaveNode } from '../models/IUseEditNode'

const useEditPdf: IUseSaveNode = (save, isPageEditor) => {
  const { open } = useDialog()
  const { appContentReplaceEvent } = useNodeEvents()

  const editPdf = (node: Node) => {
    open(
      <EditLinkPdf
        initialText={node.text || ''}
        initialTitle={node.title}
        isPageEditor={isPageEditor}
        onSave={(n) => save(node.id, n)}
        onContentReplaceEvent={() => {
          appContentReplaceEvent({ node, isPageEditor })
        }}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return editPdf
}

export default useEditPdf
