import React, { PropsWithChildren } from 'react'

import styles from './Avatar.module.css'

const DEFAULT_COLOR = '#FF9604'
const DEFAULT_SIZE = 32
type Props = PropsWithChildren<{
  color?: string
  size?: number
  className?: string
}>
export const Avatar: React.FC<Props> = ({
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE,
  className,
  children,
}) => {
  const style = {
    backgroundColor: color,
    height: `${size}px`,
    width: `${size}px`,
    minWidth: `${size}px`,
    lineHeight: `${size}px`,
  }

  return (
    <div className={[styles.Root, className].join(' ')} style={style}>
      {children}
    </div>
  )
}
