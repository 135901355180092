import React, { useEffect, memo, FC } from 'react'

import useFeatureFlags from '../../../hooks/useFeatureFlags'
import useRouterParams from '../../../hooks/useRouterParams'
import useFirebaseListeners from '../../../_firebase/hooks/useFirebaseListeners'

import { useUpdatesTools } from './hooks/useUpdatesTools'
import Updates from './components/Updates'
import styles from './AppUpdatesSection.module.css'

const AppUpdatesSection: FC = () => {
  const { isOpen: updatesIsOpen, isUnAvailable } = useUpdatesTools()
  const { insightsFeatureFlag } = useFeatureFlags()

  const { boardId } = useRouterParams()
  const {
    startBoardUpdatesMessagesListener,
    stopBoardUpdatesMessagesListener,
  } = useFirebaseListeners()

  useEffect(() => {
    if (!isUnAvailable && updatesIsOpen) {
      const [uid, bid] = boardId.split('-')

      if (uid && bid) {
        startBoardUpdatesMessagesListener(uid, bid)
      }
    }
    return () => {
      const [uid, bid] = boardId.split('-')
      stopBoardUpdatesMessagesListener(uid, bid)
    }
    // eslint-disable-next-line
  }, [isUnAvailable, updatesIsOpen, boardId])

  if (isUnAvailable) {
    return null
  }

  return (
    <section
      className={
        updatesIsOpen
          ? [
              styles.Updates,
              styles.UpdatesIsOpened,
              insightsFeatureFlag ? styles.WithNav : '',
            ].join(' ')
          : [styles.Updates, insightsFeatureFlag ? styles.WithNav : ''].join(
              ' ',
            )
      }
    >
      <Updates />
    </section>
  )
}

export default memo(AppUpdatesSection)
