import { useState } from 'react'

const useSelectedEmails = () => {
  const [selectedEmails, setSelectedEmails] = useState<string[]>([])

  const toggleEmail = (email: string) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails((prevEmails) => prevEmails.filter((e) => e !== email))
      return
    }

    setSelectedEmails((prevEmails) => [...prevEmails, email])
  }

  const resetEmails = () => setSelectedEmails([])

  return {
    selectedEmails,
    toggleEmail,
    resetEmails,
  } as const
}

export default useSelectedEmails
