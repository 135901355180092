import { useCallback, useContext } from 'react'
import { ScrollAreaContainer } from '@ui/models/ScrollArea'
import ScrollAreasContext from '@ui/components/Scroll/ScrollAreasContext'

import useScroll from './useScroll'

const useScrollArea = () => {
  const { getArea } = useContext(ScrollAreasContext)
  const { scrollToTop, scrollToElement, getScrollTop } = useScroll()

  const scrollToBottom = useCallback(
    (area: ScrollAreaContainer) => {
      const scrollArea = getArea(area)
      if (scrollArea) scrollToElement(scrollArea.endRef)
    },
    [getArea, scrollToElement],
  )

  const handleScrollToTop = useCallback(
    (area: ScrollAreaContainer) => {
      const scrollArea = getArea(area)
      if (scrollArea) scrollToTop(scrollArea.ref)
    },
    [getArea, scrollToTop],
  )

  const getContentScrollTop = useCallback(
    (area: ScrollAreaContainer) => {
      const scrollArea = getArea(area)
      if (scrollArea) {
        return getScrollTop(scrollArea.ref)
      }

      return 0
    },
    [getArea, getScrollTop],
  )

  return {
    scrollToTop: handleScrollToTop,
    scrollToBottom,
    getContentScrollTop,
  }
}

export default useScrollArea
