import { useDispatch } from 'react-redux'
import { nextTick, NodeType } from 'boards-web-ui'

import { putImage } from '_firebase'

import { randomContentId } from '@helpers/index'
import { generateNodeId } from '@helpers/NodeIdGenerator'

import { configurationObjectFromEnv } from 'utils/configurationObjectFromEnv'
import { ENV_KEYS } from 'utils/envKeys'
import { uploadActions } from 'actions'

import useUpload from '../../toolbar/hooks/useUpload'
import { IUseAddNode } from '../models/IUseAddNode'

const preventExitWhileLoading = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = false
}

const useAddImages: IUseAddNode = (save) => {
  const dispatch = useDispatch()
  const { imageBucket } = configurationObjectFromEnv({
    imageBucket: ENV_KEYS.IMAGE_BUCKET,
  })

  const createImages = async (files: HTMLInputElement[]) => {
    await Promise.all(
      Array.from(files).map(async (file) => {
        const imageName = `${randomContentId()}.${file.name.split('.').pop()}`
        const nodeText = `${imageBucket}/${imageName}`

        const { nodeId, key } = generateNodeId()

        dispatch(uploadActions.addUpload(nodeId))

        const node = {
          key,
          id: nodeId,
          title: '',
          text: nodeText,
          image: imageName,
          type: NodeType.IMAGE,
        }

        // TODO get back to using await when the uploading progress bar will be ready
        // https://heybliss.atlassian.net/browse/BLISS-7549
        save({ ...node, isProcessing: true })
        await putImage(file, imageName)
        await nextTick()
        dispatch(uploadActions.removeUpload(nodeId))
      }),
    )
  }

  const uploadImages = async (files: HTMLInputElement[]) => {
    window.addEventListener('beforeunload', preventExitWhileLoading)

    await createImages(files)

    window.removeEventListener('beforeunload', preventExitWhileLoading)
  }

  const addImages = useUpload(uploadImages, {
    accept: 'image/png, image/gif, image/jpeg',
    multiple: true,
  })

  return addImages
}

export default useAddImages
