import { Trans } from 'react-i18next'

import { configurationObjectFromEnv } from 'utils/configurationObjectFromEnv'
import { ENV_KEYS } from 'utils/envKeys'
import Button from '../../../elements/Button'
import WelcomeHeader from './components/WelcomeHeader'
import GuestTitle from '../auth/components/GuestTitle'

import styles from './WelcomePage.module.css'

const WelcomePage = () => {
  const { boardsAppUrl, shareLink } = configurationObjectFromEnv<{
    boardsAppUrl: string
    shareLink: string
  }>({
    boardsAppUrl: ENV_KEYS.APP_BOARDS_APP_URL,
    shareLink: ENV_KEYS.APP_BOARDS_LINK,
  })

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Boards for desktop',
          url: shareLink,
        })
        .catch(() => {
          return null
        })
    }
  }

  const handleGoToApp = () => {
    window.location.href = boardsAppUrl
  }

  return (
    <>
      <WelcomeHeader />
      <div className={styles.Layout}>
        <div className={styles.WelcomeContent}>
          <GuestTitle className={styles.Title}>
            <Trans i18nKey={'open_boards_title'} /> 🎉
          </GuestTitle>
          <p className={styles.SubTitle}>
            <Trans i18nKey={'open_boards_subtitle'} />
          </p>
          <Button className={styles.Button} onClick={handleGoToApp} size={'lg'}>
            <Trans i18nKey={'open_boards_app_button'} />
          </Button>
        </div>

        <div className={styles.WelcomeFooter}>
          <p className={styles.FooterText}>
            <Trans i18nKey={'open_boards_footer_text'} />
          </p>
          <Button
            className={styles.FooterButton}
            onClick={() => {
              handleShare()
            }}
          >
            <Trans i18nKey={'open_boards_footer_button'} />
          </Button>
        </div>
      </div>
    </>
  )
}

export default WelcomePage
