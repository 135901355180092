import { useDialog } from 'boards-web-ui'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'

import AddForm from '../components/AddForm'
import { IUseAddNode } from '../models/IUseAddNode'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { NodeType } from '../../../models'

const useAddForm: IUseAddNode = (save) => {
  const { open, close } = useDialog()
  const { hideFormsHint } = useProfileInformation()
  const { isLimitedNode, onLimitedKeyClick } = usePagePremiumLimitationMode()

  if (isLimitedNode(NodeType.FORM)) {
    return () => onLimitedKeyClick(PaywallSourceEnum.FORM_CREATE)
  }

  return () => {
    hideFormsHint()

    open(<AddForm onSave={save} onCancel={close} />, {
      fullScreen: true,
      blankDialog: true,
    })
  }
}

export default useAddForm
