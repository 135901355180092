import { Trans } from 'react-i18next'
import Link from '../../../../elements/Link'
import styles from './GuestFooter.module.css'

const GuestFooter = ({ msgKey, linkKey, link }) => (
  <div className={styles.Root}>
    <span className={styles.Message}>
      <Trans i18nKey={msgKey} />
    </span>

    <Link to={link} linkClassName={styles.Link}>
      <Trans i18nKey={linkKey} />
    </Link>
  </div>
)

export default GuestFooter
