import React, { PropsWithChildren, SyntheticEvent } from 'react'
import Wrap from '../../../../ui/components/Wrap'

import styles from './PreviewModalBody.module.css'

type Props = PropsWithChildren<{
  withScroll: boolean
  onClick: () => void
}>

const PreviewModalBody: React.FC<Props> = ({
  withScroll = false,
  onClick,
  children,
}) => {
  const handleOnClick = (e: SyntheticEvent) => {
    e.stopPropagation()

    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={styles.Root} onClick={handleOnClick}>
      <Wrap if={withScroll} with="div" props={{ className: styles.Scroll }}>
        <div className={styles.Content}>
          <div className={styles.FirstItem}></div>
          {children}
          <div className={styles.LastItem}>&nbsp;</div>
        </div>
      </Wrap>
    </div>
  )
}

export default PreviewModalBody
