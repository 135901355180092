import clsx from 'clsx'
import { Trans } from 'react-i18next'
import { ButtonNew, PremiumCrownIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import usePaywall from '@hooks/usePaywall'
import { FC } from 'react'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import styles from './MembersPremiumBanner.module.css'

export const MembersPremiumBanner: FC = () => {
  const { openPaywall } = usePaywall()

  return (
    <div className={clsx(styles.Root)}>
      <span className={styles.UpgradeText}>
        <Trans i18nKey={'co_editor_limit_owner'} />
      </span>

      <ButtonNew
        color={'primary'}
        size={'medium'}
        startIcon={
          <IconWrapper size={16} color={'white'}>
            <PremiumCrownIcon />
          </IconWrapper>
        }
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()

          openPaywall({ source: PaywallSourceEnum.UPGRADE_CO_EDITORS })
        }}
      >
        <Trans i18nKey={'action_upgrade'} />
      </ButtonNew>
    </div>
  )
}
