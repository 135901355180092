import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './MenuItem.module.css'

type Props = PropsWithChildren<{
  active: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}>

const MenuItem: FC<Props> = ({ active, children, onClick }) => {
  return (
    <button
      className={clsx(styles.Root, active && styles.Active)}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default MenuItem
