import { t } from 'i18next'

export const useProfileTranslations = () => {
  const accountHoverTooltip = () => t('desktop_hover_your_account')
  const logOut = () => t('action_logout')
  const feedBack = () => t('action_send_feedback')
  const howToUse = () => t('desktop_help_and_resources')
  const personalInfo = () => t('personal_info_title')
  const subscription = () => t('subscription_title')
  const messagePreferences = () => t('message_preferences_title')

  return {
    accountHoverTooltip,
    logOut,
    feedBack,
    howToUse,
    personalInfo,
    subscription,
    messagePreferences,
  } as const
}
