import React from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'

import PublicPaywall from '@features/payments/PublicPaywall'

import PageSignIn from './PageSignIn/PageSignIn'
import PageSignUp from './PageSignUp/PageSignUp'
import PageSingOut from './PageSingOut/PageSingOut'
import PageResetPassword from './PageResetPassword/PageResetPassword'
import PhoneVerification from '../../phoneVerification/PhoneVerification'

import * as ROUTES from '../../../constants/routes'

const AuthRouting: React.FC = () => {
  const [searchParams] = useSearchParams()

  const getAllSearchParamsAsString = () => {
    let result = ''
    searchParams.forEach((value, key) => {
      result += `&${key}=${value}`
    })
    return result.length > 0 ? result.substring(1) : ''
  }

  return (
    <Routes>
      <Route path={ROUTES.PLANS} element={<PublicPaywall />} />
      <Route path={ROUTES.SIGN_IN} element={<PageSignIn />} />
      <Route path={ROUTES.PHONE_VERIFICATION} element={<PhoneVerification />} />
      <Route path={ROUTES.SIGN_UP} element={<PageSignUp />} />
      <Route path={ROUTES.SIGN_OUT} element={<PageSingOut />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<PageResetPassword />} />
      <Route
        path={ROUTES.NOT_FOUND}
        element={
          <Navigate to={`${ROUTES.SIGN_IN}?${getAllSearchParamsAsString()}`} />
        }
      />
    </Routes>
  )
}

export default AuthRouting
