import { QueryDocumentSnapshot } from 'firebase/firestore'
import { getRootFolderId } from '@helpers/NodeIdGenerator'
import { BoardItem } from '../../../app/models'

export const transformReceivedBoard = (doc: QueryDocumentSnapshot) => {
  const data = doc.data()
  const boardRef = doc.ref.parent.parent
  const id = boardRef ? boardRef.id : ''
  const uid = data.owner
  const boardId = `${uid}-${id}`
  const rootFolderId = getRootFolderId(boardId)

  return {
    id,
    uid,
    boardId,
    rootFolderId,
    isOwn: false,
  } as BoardItem
}
