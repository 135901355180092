import React, { FC, ReactNode } from 'react'
import { ButtonNew } from 'boards-web-ui'
import styles from './AccountPersonalInfo.module.css'

export const InfoRow: FC<{
  rowName: ReactNode
  rowValue: ReactNode
  buttonText?: ReactNode
  onRowButtonClick?: () => void
}> = ({ rowName, rowValue, onRowButtonClick, buttonText }) => {
  return (
    <div className={styles.InfoRow}>
      <div className={styles.InfoRowTitleContainer}>
        <span className={styles.InfoRowName}>{rowName}</span>

        <span className={styles.InfoRowNameValue}>{rowValue}</span>
      </div>

      {buttonText ? (
        <ButtonNew
          color={'secondary'}
          size={'medium'}
          onClick={onRowButtonClick}
        >
          {buttonText}
        </ButtonNew>
      ) : null}
    </div>
  )
}
