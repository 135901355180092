import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useBoards from '@hooks/useBoards'

import useDragAndDropList from '../../../../ui/hooks/useDragAndDropList'

import actionSetBoardsOrder from '../actions/setBoardsOrder'

const useBoardsOrder = () => {
  const dispatch = useDispatch()
  const { boards, order, activeBoardsCount, inactiveBoardsCount } = useBoards()
  const [orderedList, setOrderedList] = useState<string[]>(order)
  const setBoardsOrder = (newOrder: string[]) => {
    dispatch(actionSetBoardsOrder(newOrder))
  }
  const reOrder = (newOrder: string[]) => {
    const boardsIds = Object.keys(boards)
    const newOrderFiltered = newOrder.filter((x) => boardsIds.includes(x))

    setBoardsOrder(newOrderFiltered)
    setOrderedList(newOrderFiltered)
  }
  const {
    draggedTo,
    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
    setDefaultList,
  } = useDragAndDropList(reOrder)

  useEffect(() => {
    setDefaultList(order as never[])
    setOrderedList(order)
  }, [order, setDefaultList])

  return {
    boards,
    order: orderedList,
    draggedTo,
    inactiveBoardsCount,
    activeBoardsCount,
    setBoardsOrder, // to remove
    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
  } as const
}

export default useBoardsOrder
