// TODO replace with hook from board-web-ui package when it will be merged
import { useCallback, useRef } from 'react'

// we do not have an ArgumentsOf<T> type and unknown[] for ...args will throw an error for handler functions
/* eslint-disable @typescript-eslint/no-explicit-any */
const useDebounceCallback = <T extends (...args: any[]) => ReturnType<T>>(
  handler: T,
  delay = 300,
) => {
  const timer = useRef<unknown>(null)

  return useCallback(
    (...args: unknown[]) => {
      if (timer.current) {
        clearTimeout(timer.current as number)
        timer.current = null
      }

      timer.current = setTimeout(() => {
        handler(...args)
        clearTimeout(timer.current as number)
        timer.current = null
      }, delay) as unknown as number
    },
    [handler, delay],
  )
}

export default useDebounceCallback
