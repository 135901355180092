import React, { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ButtonIcon, CloseIcon, NextIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import Link from '../../../../elements/Link'

import useSidebarEvents from '../../analytics/useSidebarEvents'
import styles from './SideBarMonetizationBanner.module.css'

interface Props {
  onClose: () => void
  isCountrySingleTier: boolean
}
const MonetizationBanner: FC<Props> = ({ onClose, isCountrySingleTier }) => {
  const { t } = useTranslation()
  const { clickBannerEvent, showBannerEvent } = useSidebarEvents()

  useEffect(() => {
    showBannerEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.Root}>
      <ButtonIcon
        size={10}
        className={styles.CloseIcon}
        round
        onClick={onClose}
      >
        <IconWrapper size={16}>
          <CloseIcon />
        </IconWrapper>
      </ButtonIcon>

      <div className={styles.Title}>
        <Trans i18nKey="monetization_banner_pre_launch_title_emoji" />
      </div>

      <Link
        to={t(
          isCountrySingleTier
            ? 'monetization_banner_pre_launch_link_single_tier'
            : 'monetization_banner_pre_launch_link',
        )}
        target="_blank"
        onClick={clickBannerEvent}
      >
        <span className={styles.LearnMore}>
          <Trans i18nKey="learn_more" /> &nbsp;
          <IconWrapper className={styles.Icon}>
            <NextIcon />
          </IconWrapper>
        </span>
      </Link>
    </div>
  )
}

export default MonetizationBanner
