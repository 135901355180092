/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  PhoneAuthProvider,
  PhoneInfoOptions,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword as signIn,
  signOut,
  updatePhoneNumber,
  UserCredential,
} from 'firebase/auth'

import { callFirebaseFunction } from '_firebase/utils'
import {
  EmailPasswordSignIn,
  ResetPasswordData,
  SignUpData,
} from './models/auth'

import app from './app'
import { functions } from './functions'

const auth = getAuth(app)

// fix Firebase: Losing Auth State on new Tab
// TODO: need to find a solution how to solve the issue in other way
// setPersistence(auth, browserLocalPersistence)

export const sendCodeToPhoneNumber = (
  phoneOptions: string | PhoneInfoOptions,
  recaptchaContainerId: string,
) => {
  if (!(window as any).recaptchaVerifier) {
    ;(window as any).recaptchaVerifier = new RecaptchaVerifier(
      auth,
      recaptchaContainerId,
      {
        size: 'invisible',
      },
    )
  }
  ;(window as any).recaptchaVerifier.render()

  const phoneProvider = new PhoneAuthProvider(auth)
  return phoneProvider.verifyPhoneNumber(
    phoneOptions,
    (window as any).recaptchaVerifier,
  )
}

export const updateUserPhoneNumber = (
  verificationId: string,
  verificationCode: string,
) => {
  if (!auth.currentUser) {
    throw new Error('User is not authenticated!')
  }

  const phoneCredential = PhoneAuthProvider.credential(
    verificationId,
    verificationCode,
  )
  return updatePhoneNumber(auth.currentUser, phoneCredential)
}

export const getSignInMethodsForEmail = (email: string) => {
  return fetchSignInMethodsForEmail(auth, email)
}

export const signInWithEmailAndPassword = (
  data: EmailPasswordSignIn,
): Promise<void | UserCredential> => {
  const persistence = data.remember
    ? browserLocalPersistence
    : browserSessionPersistence

  if (data.email === 'token@boards.com') {
    return signInWithCustomToken(auth, data.password)
  }

  return setPersistence(auth, persistence).then(() =>
    signIn(auth, data.email, data.password),
  )
}

export const signUpWithEmailAndPassword = (
  data: SignUpData,
): Promise<void | UserCredential> => {
  return createUserWithEmailAndPassword(auth, data.email, data.password)
}

export const resetPasswordWithEmail = (
  data: ResetPasswordData,
): Promise<void> => {
  return sendPasswordResetEmail(auth, data.email.toLowerCase())
}

export const doSignOut = (): Promise<void> => signOut(auth)

export const getCustomToken = async (): Promise<string | undefined> => {
  return callFirebaseFunction<string>(
    functions,
    'users-createCustomSignInToken',
  )
}

export default auth
