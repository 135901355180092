import { PropsWithChildren } from 'react'

import styles from './Label.module.css'

type Props = PropsWithChildren
const Label = ({ children }: Props) => (
  <span className={styles.Root}>{children}</span>
)

export default Label
