import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useOutSideClickHandler } from '../hooks/useOutSideClickHandler'

import styles from './DropDown.module.css'

export enum DropDownPosition {
  left = 'LeftPosition',
  center = 'CenterPosition',
  right = 'RightPosition',
}
type Props = PropsWithChildren<{
  isOpen?: boolean
  className?: string
  content: ReactNode | string
  onToggle?: (isOpen: boolean) => void
  position?: DropDownPosition
  disabled?: boolean
}>
export const DropDown: React.FC<Props> = ({
  content,
  className,
  isOpen = false,
  position = DropDownPosition.left,
  onToggle = () => null,
  children,
  disabled,
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState<boolean>(isOpen)

  const changeState = (value: boolean): void => {
    setState(value)
    onToggle(value)
  }

  const toggle = (): void => changeState(!state)

  useOutSideClickHandler(rootRef, () => changeState(false))

  useEffect(() => {
    setState(isOpen)
  }, [isOpen])

  return (
    <div className={styles.Root} ref={rootRef}>
      <div
        className={!disabled ? styles.Target : ''}
        onClick={!disabled ? toggle : undefined}
      >
        {children}
      </div>

      {state ? (
        <div
          className={[styles.Content, styles[position], className].join(' ')}
        >
          {content}
        </div>
      ) : null}
    </div>
  )
}
