import React, { FC } from 'react'
import useCountdownTimer from '@hooks/useCountdownTimer'
import styles from './ReverseTrialCountdownTimer.module.css'

type Props = {
  dueDate: Date
}
const ReverseTrialCountdownTimer: FC<Props> = ({ dueDate }) => {
  const { seconds, minutes, hours } = useCountdownTimer({
    dueDate,
  })

  return (
    <div className={styles.Timer}>
      <span>{String(hours).padStart(2, '0')}</span>
      <span className={styles.Separator} />
      <span>{String(minutes).padStart(2, '0')}</span>
      <span className={styles.Separator} />
      <span>{String(seconds).padStart(2, '0')}</span>
    </div>
  )
}

export default ReverseTrialCountdownTimer
