export enum RoleAnalytics {
  OWNER = 'owner',
  EDITOR = 'editor',
  MEMBER = 'member',
}

export enum Role {
  OWN = 'own',
  WRITE = 'write',
  SHARE = 'share',
  READ = 'read',
}

export type Permission = {
  sharer?: string
  recipient: string
  owner: string
  permission: Role
}
