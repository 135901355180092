import { ENV_KEYS } from '../../utils/envKeys'
import { configurationObjectFromEnv } from '../../utils/configurationObjectFromEnv'

type Props = {
  plansLink: string
  helpUrl: string
  supportUrl: string
}

export default configurationObjectFromEnv<Props>({
  plansLink: ENV_KEYS.PLANS_URL,
  helpUrl: ENV_KEYS.HELP_URL,
  supportUrl: ENV_KEYS.SUPPORT_URL,
})
