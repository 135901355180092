import React, { FC } from 'react'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import { Trans } from 'react-i18next'
import styles from './SelectContentDialogHeader.module.css'

export const SelectContentDialogHeader: FC = () => {
  const { title } = useContentNavigation(ContentPickersEnum.SourcePicker)

  return (
    <div className={styles.Header}>
      <span className={styles.Mode}>
        <Trans i18nKey={'insert_to_page_select_from'} />
      </span>

      <span className={styles.Title}>{title}</span>
    </div>
  )
}
