import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useTemplatePickerEvents from '@features/analytics/useTemplatePickerEvents'
import useRouterParams from '@hooks/useRouterParams'
import useShortcut from '@hooks/useShortcut'
import { Folder } from 'app/models'

const useTipBanner = (folder: Folder) => {
  const location = useLocation()
  const templateCategory = location.state?.templateCategory
  const templateName = location.state?.templateName
  const { pagePersonalizeTemplateDismissEvent } = useTemplatePickerEvents()
  const { getShortcut } = useShortcut()
  const { boardId, folderId } = useRouterParams()

  const isCopiedFromTemplate = location.state?.isCopiedFromTemplate || false
  const [isTipBannerVisible, setIsTipBannerVisible] =
    useState<boolean>(isCopiedFromTemplate)

  const handleTemplateBannerDismiss = () => {
    pagePersonalizeTemplateDismissEvent({
      boardId,
      nodeId: folderId,
      pageId: folderId,
      linkShortcut: getShortcut(folder) || '',
      templateCategory,
      templateName,
    })
    setIsTipBannerVisible(false)
  }

  return {
    isTipBannerVisible,
    handleTemplateBannerDismiss,
  } as const
}

export default useTipBanner
