import { Grid, RetryIcon, SpinnerIcon } from 'boards-web-ui'

import { Trans } from 'react-i18next'
import ProgressBar from '@features/nodeEditor/components/ProgressBar'
import { FC } from 'react'
import styles from './AddVideoStreaming.module.css'

interface Props {
  error: boolean
  progress: number
  startUpload: () => void
}

export const VideoStreamingUploadingProgress: FC<Props> = ({
  error,
  progress,
  startUpload,
}) => (
  <div className={styles.Center}>
    <div className={styles.ProgressBarContainer}>
      <Grid justifyContent="space-between">
        <span className={styles.UploadingLabel}>
          {error ? (
            <Trans i18nKey={'upload_failed_please_try_again'} />
          ) : (
            <Trans i18nKey={'label_uploading'} />
          )}
        </span>

        {error ? (
          <RetryIcon onClick={startUpload} className={styles.RetryIcon} />
        ) : (
          <SpinnerIcon className={styles.SpinnerIcon} />
        )}
      </Grid>
      <ProgressBar progress={progress} isRed={error} />
    </div>
  </div>
)
