/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ForwardedRef, InputHTMLAttributes } from 'react'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'

import styles from './SimpleField.module.css'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError | Merge<FieldError, FieldErrorsImpl> | undefined
  errorClassName?: string
  name: string
}

/** @deprecated Use "InputField" instead from "boards-web-ui" */
export const SimpleField: React.FC<Props> = React.forwardRef(
  (
    { error, errorClassName, name, ...props },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const errorClassNames = (
      errorClassName ? [styles.Error, errorClassName] : [styles.Error]
    ).join(' ')

    return (
      <label className={styles.Label}>
        <input
          className={[styles.Input, error ? styles.Invalid : ''].join(' ')}
          name={name}
          {...props}
          ref={ref}
        />

        {error && (
          <span className={errorClassNames}>{error.message?.toString()}</span>
        )}
      </label>
    )
  },
)
