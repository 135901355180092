import { commonActions } from '../../actions'
import { AppDispatch } from '../../app/store'

let loading = true
let OwnBoardsToLoad: string[] | undefined
let ReceivedBoardsToLoad: string[] | undefined
let loadedBoards: string[] = []

export const createLoadingManagement = (dispatch: AppDispatch) => {
  const getRemainingBoardsToLoad = () => {
    if (OwnBoardsToLoad !== undefined && ReceivedBoardsToLoad !== undefined) {
      const allBoardsToLoad = [...OwnBoardsToLoad, ...ReceivedBoardsToLoad]
      return allBoardsToLoad.filter((x) => !loadedBoards.includes(x)).length
    }
    return 1
  }

  const finishLoading = () => {
    if (loading) {
      loading = false
      dispatch(commonActions.setLoading(false))
    }
  }

  const checkAndFinishLoading = () => {
    const remainingBoardsToLoad = getRemainingBoardsToLoad()
    if (
      OwnBoardsToLoad &&
      ReceivedBoardsToLoad &&
      remainingBoardsToLoad === 0
    ) {
      finishLoading()
    }
  }

  const addBoardsToLoad = (own: boolean, boardIds: string[]) => {
    if (own && !OwnBoardsToLoad) {
      OwnBoardsToLoad = boardIds
    }
    if (!own && !ReceivedBoardsToLoad) {
      ReceivedBoardsToLoad = boardIds
    }

    checkAndFinishLoading()
  }

  const addLoadedBoard = (boardId: string) => {
    if (loading) {
      loadedBoards.push(boardId)

      checkAndFinishLoading()
    }
  }

  const resetLoadingManagement = () => {
    loading = true
    OwnBoardsToLoad = undefined
    ReceivedBoardsToLoad = undefined
    loadedBoards = []

    // Fail safe loading of 5 seconds
    setTimeout(() => {
      finishLoading()
    }, 5000)
  }

  return {
    addBoardsToLoad,
    addLoadedBoard,
    resetLoadingManagement,
  }
}
