import { useTranslation } from 'react-i18next'
import useInsightsEvents from '@features/analytics/useInsightsEvents'

import { InsightsFilter } from '../models/filter'
import useSessionStorage from '../../../../hooks/useSessionStorage'

const useInsightsFilter = () => {
  const { t } = useTranslation()
  const { insightsFilterChanged } = useInsightsEvents()
  const [filter, setFilter] = useSessionStorage(
    'insights-filter',
    InsightsFilter.LAST_WEEK,
  )

  const updateFilter = (f: string) => {
    insightsFilterChanged(f as InsightsFilter)
    setFilter(f as InsightsFilter)
  }

  const options = {
    [InsightsFilter.TODAY]: t('insights_tab_filter_today'),
    [InsightsFilter.YESTERDAY]: t('insights_filter_yesterday'),
    [InsightsFilter.THIS_WEEK]: t('insights_tab_filter_this_week'),
    [InsightsFilter.LAST_WEEK]: t('insights_tab_filter_last_week'),
    [InsightsFilter.THIS_MONTH]: t('insights_tab_filter_this_month'),
    [InsightsFilter.LAST_MONTH]: t('insights_tab_filter_last_month'),
    [InsightsFilter.ALL_TIME]: t('insights_filter_all_time'),
  }

  return {
    filter,
    options,
    updateFilter,
  }
}

export default useInsightsFilter
