import React, { FC, useState } from 'react'
import {
  ButtonIcon,
  CloseIcon,
  Icon,
  IphoneIcon,
  NextIcon,
  SpinnerIcon,
} from 'boards-web-ui'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useSidebarEvents from '@features/analytics/useSidebarEvents'
import useStyledSubtitle from '@features/sidebar/hooks/useStyledSubtitle'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { commonActions } from '../../../../actions'
import styles from './ChromeAndMobileBanner.module.css'

export interface MobileBannerProps {
  setOpen: (value: boolean) => void
}

const MobileBanner: FC<MobileBannerProps> = ({ setOpen }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const { hideInstallMobileAppHintShown } = useProfileInformation()

  const { desktopCtasMobileClick, desktopCtasDismissClick } = useSidebarEvents()

  const [before, bold, after] = useStyledSubtitle(
    'desktop_cta_mobile_subtitle',
    'desktop_cta_mobile_subtitle_placeholder',
  )

  const handleMobileClick = async () => {
    desktopCtasMobileClick()
    setLoading(true)
    await dispatch(commonActions.sendMobileAppInvitationEmail())
    setLoading(false)
    setOpen(true)
  }

  const handleCloseIconClick = () => {
    hideInstallMobileAppHintShown()
    desktopCtasDismissClick('mobile')
  }

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <Icon size="29px">
          <IphoneIcon />
        </Icon>
        <ButtonIcon
          size={10}
          className={styles.Close}
          onClick={handleCloseIconClick}
        >
          <IconWrapper size={16}>
            <CloseIcon />
          </IconWrapper>
        </ButtonIcon>
      </div>
      <p className={styles.Message}>
        {before}
        <span>{bold}</span>
        {after}
      </p>
      <button className={styles.Forward} onClick={handleMobileClick}>
        {t('desktop_cta_mobile_button')}{' '}
        <IconWrapper>
          {loading ? (
            <SpinnerIcon style={{ marginLeft: '6px' }} />
          ) : (
            <NextIcon />
          )}
        </IconWrapper>
      </button>
    </div>
  )
}

export default MobileBanner
