import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TNodeCheckoutForm, urlHelper } from 'boards-web-ui'
import { CheckoutActionsIdsEnum } from '@features/checkout/models'
import type { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { useGoogleSheetConnector } from '@features/googleSpreadsheet'
import usePaymentProviders from '@features/checkout/hooks/usePaymentProviders'
import useFormIntegrationStatusState from '@features/forms/hooks/useFormIntegrationStatusState'
import { GOOGLE_SHEETS_INTEGRATION_SCREEN } from '@features/analytics/useFormsEvents'

import {
  useAdditionalFields,
  useCheckoutActions,
  useCheckoutApi,
  useCheckoutCurrency,
  useCheckoutProducts,
  useCheckoutSettingsPayment,
  useSettingsPostSubmission,
  useSettingsResponses,
} from '../hooks'
import { CHECKOUT_POST_SUBMITTING_BEHAVIOUR } from '../constants'
import CheckoutContext from './CheckoutContext'

type CheckoutProviderProps = {
  initialScreen?: CheckoutActionsIdsEnum
  isOwn: boolean
  isNew: boolean
  boardId: string
  pageId: string
  initialNode?: TNodeCheckoutForm
  advancedForms: boolean
  onPaywall: () => void
  onSave: ICreateOrUpdateNode
  onCancel: () => void
}
const CheckoutProvider: FC<PropsWithChildren<CheckoutProviderProps>> = ({
  initialScreen = CheckoutActionsIdsEnum.PRODUCTS,
  isOwn,
  isNew,
  boardId,
  pageId,
  initialNode,
  advancedForms,
  onPaywall,
  onSave,
  onCancel,
  children,
}) => {
  const { t } = useTranslation()
  const {
    formIntegrationStatusState,
    formIntegrationStatusPending,
    onGetFormIntegrationStatus,
  } = useFormIntegrationStatusState(boardId, pageId, initialNode?.id)

  useEffect(() => {
    if (isNew) {
      onGetFormIntegrationStatus()
    }
    // eslint-disable-next-line
  }, [])

  const {
    products,
    isProductsValid,
    onProductsReOrder,
    onProductAdd,
    onProductDelete,
    onProductChange,
  } = useCheckoutProducts(initialNode?.form?.products)

  const {
    additionalFields,
    isAdditionalFieldsValid,
    onAdditionalFieldsReOrder,
    onAdditionalFieldsAdd,
    onAdditionalFieldsDelete,
    onAdditionalFieldsChange,
  } = useAdditionalFields(
    initialNode?.form?.additionalFields,
    initialNode?.form?.fields,
  )

  const {
    postSubmissionBehavior,
    postSubmissionMessage,
    postSubmissionUrl,
    postSubmissionError,
    onPostSubmissionBehaviourChange,
    onPostSubmissionMessageChange,
    onPostSubmissionUrlChange,
  } = useSettingsPostSubmission({
    defaultPostSubmissionBehavior: urlHelper.isValidUrl(
      initialNode?.form?.response || '',
    )
      ? CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
      : CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE,
    defaultPostSubmissionMessage: urlHelper.isValidUrl(
      initialNode?.form?.response,
    )
      ? ''
      : initialNode?.form?.response || t('forms_thanks_message_default'),
    postSubmissionUrl: urlHelper.isValidUrl(initialNode?.form?.response || '')
      ? initialNode?.form?.response || ''
      : '',
  })

  const {
    spreadsheetURL,
    isGoogleFormConnected,
    onUnlinkSpreadSheet,
    onFormConnectToGoogleSpreadSheet,
    isGoogleAccountConnectedPending,
  } = useGoogleSheetConnector({
    isOwn,
    formIntegrationStatusState,
    initialSpreadsheetURL: initialNode?.form?.spreadsheetURL,
    screen: GOOGLE_SHEETS_INTEGRATION_SCREEN.CHECKOUT,
    onGetFormIntegrationStatus,
  })

  const { responsesEmail, responsesEmailError, onResponsesEmailChange } =
    useSettingsResponses(
      initialNode?.form?.email ||
        formIntegrationStatusState?.decryptedProperties?.email ||
        '',
    )

  const { shippingFees, minOrder, onMinOrderChange, onShippingFeesChange } =
    useCheckoutSettingsPayment({
      defaultShippingFees: initialNode?.form?.shippingFee?.toString() || '0',
      defaultMinOrder: initialNode?.form?.minOrder?.toString() || '0',
    })

  const {
    isPayPalAccountConnected,
    paymentProviders,
    onPaypalConnect,
    onPaypalDisconnect,
    isPaypalFormConnected,
    isPayPalDisconnectedPending,
    isPayPalConnectionPending,
    merchantPaymentsReceivable,
    merchantPrimaryEmailConfirmed,
  } = usePaymentProviders({
    isOwn,
    formIntegrationStatusState,
    paymentProviders:
      initialNode?.form?.paymentProviders ||
      formIntegrationStatusState?.paymentProviders ||
      [],
    onGetFormIntegrationStatus,
  })

  const { currency, currencies, onCurrencyChange } = useCheckoutCurrency(
    initialNode?.form?.currency
      ? { defaultCurrency: initialNode?.form?.currency }
      : {},
  )

  const { isSubmitted, onCheckoutSave, onCheckoutCancel } = useCheckoutApi({
    isOwn,
    action: '',
    initialForm: initialNode,

    formIntegrationStatusState,

    encryptedEmail: initialNode?.form.encryptedEmail || '',
    initialResponsesEmail:
      formIntegrationStatusState?.decryptedProperties?.email || '',
    responsesEmail,
    responsesEmailError,

    encryptedSpreadsheetURL: initialNode?.form?.encryptedSpreadsheetURL || '',
    initialSpreadsheetURL:
      formIntegrationStatusState?.decryptedProperties?.spreadsheetURL || '',
    spreadsheetURL,

    products,
    additionalFields,

    currency,
    shippingFees,
    minOrder,

    postSubmissionUrl,
    postSubmissionBehavior,
    postSubmissionMessage,
    postSubmissionError,

    paymentProviders,

    isPayPalAccountConnected,

    onSave,
    onCancel,
  })

  const { action } = useCheckoutActions({
    initialScreen,
    isProductsValid,
    isAdditionalFieldsValid,
    onSave: () => onCheckoutSave(),
  })

  const state = useMemo(
    () => ({
      isOwn,
      isNew,
      action,
      advancedForms,

      products,
      onProductsReOrder,
      onProductAdd,
      onProductDelete,
      onProductChange,

      additionalFields,
      onAdditionalFieldsReOrder,
      onAdditionalFieldsAdd,
      onAdditionalFieldsDelete,
      onAdditionalFieldsChange,

      currency,
      currencies,
      onCurrencyChange,

      shippingFees,
      onShippingFeesChange,

      minOrder,
      onMinOrderChange,

      formIntegrationStatusState,
      isPayPalAccountConnected,
      isGoogleFormConnected,
      isPaypalFormConnected,
      paymentProviders,
      isPayPalConnectionPending,
      isPayPalDisconnectedPending,
      onPaypalConnect,
      onPaypalDisconnect,

      postSubmissionBehavior,
      postSubmissionMessage,
      postSubmissionUrl,
      postSubmissionError,
      onPostSubmissionUrlChange,
      onPostSubmissionMessageChange,
      onPostSubmissionBehaviourChange,

      responsesEmail,
      responsesEmailError,
      onResponsesEmailChange,

      spreadsheetURL,
      onUnlinkSpreadSheet,
      isGoogleAccountConnectedPending,

      onFormConnectToGoogleSpreadSheet,

      isSubmitted,
      onPaywall,
      onCheckoutCancel,
      formIntegrationStatusPending,
      merchantPaymentsReceivable,
      merchantPrimaryEmailConfirmed,
    }),
    [
      isOwn,
      isNew,
      action,
      advancedForms,

      products,
      onProductsReOrder,
      onProductAdd,
      onProductDelete,
      onProductChange,

      additionalFields,
      onAdditionalFieldsReOrder,
      onAdditionalFieldsAdd,
      onAdditionalFieldsDelete,
      onAdditionalFieldsChange,

      currency,
      currencies,
      onCurrencyChange,

      shippingFees,
      onShippingFeesChange,

      minOrder,
      onMinOrderChange,

      formIntegrationStatusState,
      isPayPalAccountConnected,
      isPaypalFormConnected,
      paymentProviders,
      isPayPalConnectionPending,
      isPayPalDisconnectedPending,
      onPaypalConnect,
      onPaypalDisconnect,

      postSubmissionBehavior,
      postSubmissionMessage,
      postSubmissionUrl,
      postSubmissionError,
      onPostSubmissionUrlChange,
      onPostSubmissionMessageChange,
      onPostSubmissionBehaviourChange,

      responsesEmail,
      responsesEmailError,
      onResponsesEmailChange,

      isGoogleFormConnected,
      spreadsheetURL,
      onUnlinkSpreadSheet,
      isGoogleAccountConnectedPending,
      onFormConnectToGoogleSpreadSheet,

      isSubmitted,
      onPaywall,
      onCheckoutCancel,
      formIntegrationStatusPending,
      merchantPaymentsReceivable,
      merchantPrimaryEmailConfirmed,
    ],
  )

  return (
    <CheckoutContext.Provider value={state}>
      {children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutProvider
