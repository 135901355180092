import { Folder, Node, NodeType } from 'app/models'

import { addNodes, nodesWithNewKeys } from '../FolderManagement'
import { AppState } from '../../app/models/appState'
import { initialState } from '../initialState'
import { clearProperties } from '../../_firebase/utils'

interface ICopyNodesReducer {
  targetBoardId: string
  targetFolderId: string
  template: string
  key: string
  page: Folder
}
export function copyTemplateReducer(
  state: AppState,
  { targetBoardId, targetFolderId, key, page }: ICopyNodesReducer,
  undoStackWithContentAdded: (state: AppState, boardId: string) => unknown,
) {
  const nodesToAdd = [
    {
      id: page.id,
      icon: page.icon,
      shortcut: page.shortcut,
      thumbnail: '',
      title: page.title,
      type: NodeType.PAGE,
    } as Node,
  ].map((node) => clearProperties(node))

  const { nodes, folders } = nodesWithNewKeys(
    {
      ...state.folders[targetBoardId],
      [page.id]: page,
    },
    nodesToAdd,
    key,
    true,
  )

  const foldersWithNewNodes = addNodes(
    {
      ...state.folders,
      [targetBoardId]: folders,
    },
    targetBoardId,
    targetFolderId,
    nodes,
  )

  return {
    ...state,
    folders: foldersWithNewNodes,
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, targetBoardId),
      bulk: initialState.app.bulk,
    },
  }
}
