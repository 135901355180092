export type ConfObjectKeyNames<C> = { [key in keyof C]: string }

export const configurationObjectFromEnv = <C>(keys: ConfObjectKeyNames<C>): C =>
  Object.entries(keys).reduce((config, [key, keyName]) => {
    if (typeof process.env[keyName as string] === 'undefined') {
      throw new Error(
        `${keyName} - is not defined for Firebase configuration, please check you .env file`,
      )
    }

    return {
      ...config,
      [key]: process.env[keyName as string],
    } as C
  }, {} as C)
