import {
  ButtonNew,
  CardActions,
  CardBody,
  CardBodyContent,
  CardHeader,
  InfoWithBackgroundIcon,
} from 'boards-web-ui'
import { Trans } from 'react-i18next'
import { FC } from 'react'
import styles from './CoEditorDialog.module.css'

interface Props {
  onClose: () => void
}

export const CoEditorDialog: FC<Props> = ({ onClose }) => (
  <div className={styles.Root}>
    <CardHeader
      className={styles.IconContainer}
      icon={<InfoWithBackgroundIcon width={80} height={75} />}
    />

    <CardBody className={styles.Container}>
      <CardBodyContent className={styles.Content}>
        <div className={styles.Message}>
          <Trans i18nKey={'page_only_board_owners_can_edit_section'} />
        </div>
      </CardBodyContent>

      <CardActions className={styles.Actions}>
        <ButtonNew fullWidth size={'large'} onClick={onClose}>
          <Trans i18nKey={'action_got_it'} />
        </ButtonNew>
      </CardActions>
    </CardBody>
  </div>
)
