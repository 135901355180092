const cropImageWithJimp = (
  imageBuffer: string,
  imageType: string,
  x: number,
  y: number,
  width: number,
  height: number,
): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const worker = new Worker(
      new URL('../workers/imageWorker.ts', import.meta.url),
    )

    worker.onmessage = (event) => {
      if (event.data.error) {
        reject(event.data.error)
      } else {
        resolve(Buffer.from(event.data))
      }
    }

    worker.onerror = (error) => {
      reject(error.message)
    }

    worker.postMessage({ imageBuffer, imageType, x, y, width, height })
  })
}

export default cropImageWithJimp
