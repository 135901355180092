import ContentItem from './ContentItem'
import ContentItemCover, { CoverBackground } from './ContentItemCover'
import { ReactComponent as GrayPageIcon } from '../icons/PageGray.svg'

interface Props {
  cover: string | undefined
  text: string
  count: number
}

const ContentPage = ({ cover, text, count }: Props) => {
  const textElement = text

  return (
    <ContentItem text={textElement} count={count}>
      <ContentItemCover
        background={CoverBackground.GRAY}
        cover={cover}
        alt={text}
        icon={<GrayPageIcon />}
      />
    </ContentItem>
  )
}

export default ContentPage
