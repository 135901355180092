import { useContext } from 'react'
import { UpdatesContext } from '../UpdatesProviders'
import useBoard from '../../../../hooks/useBoard'

interface IUseUpdatesTools {
  isOpen: boolean
  disabled: boolean
  isUnAvailable: boolean
  close: () => void
  toggle: () => void
}

export const useUpdatesTools = (): IUseUpdatesTools => {
  const state = useContext(UpdatesContext)

  const { board, isReadOnly, isShared, isOwnBoard } = useBoard()

  const isUnAvailable =
    !state.isAvailable || isReadOnly || !board || (isOwnBoard && !isShared)

  return {
    isOpen: state.isOpen,
    disabled: state.disabled,
    isUnAvailable,
    close: state.close,
    toggle: state.toggle,
  }
}
