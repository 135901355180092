import { useState } from 'react'

const useNodeAPI = (initialTitle: string, initialText: string) => {
  const [text, setText] = useState(initialText)
  const [title, setTitle] = useState(initialTitle)

  const hasChanges = initialText !== text || initialTitle !== title
  const isEmpty = text === '' && title === ''

  return { text, title, hasChanges, isEmpty, setText, setTitle }
}

export default useNodeAPI
