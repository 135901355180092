import React, { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import useBoards from '@hooks/useBoards'

import useBoardEvents from '@features/analytics/useBoardEvents'

import Content from '../components/Content'

const Editor: FC = () => {
  const { count, activeBoardsCount, inactiveBoardsCount } = useBoards()
  const { appHomeScreenView } = useBoardEvents()

  useEffect(() => {
    appHomeScreenView(count, activeBoardsCount, inactiveBoardsCount)

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Content />
      <Outlet />
    </>
  )
}

export default React.memo(Editor)
