import { NODE_LINK_TYPES } from 'boards-web-ui'

export const NODE_LINK_TYPES_NAME: { [key in NODE_LINK_TYPES]: string } =
  Object.freeze({
    [NODE_LINK_TYPES.ZOOM]: 'zoom',
    [NODE_LINK_TYPES.CALENDLY]: 'calendly',
    [NODE_LINK_TYPES.CASH]: 'cash',
    [NODE_LINK_TYPES.FACEBOOK]: 'messenger',
    [NODE_LINK_TYPES.MAIL]: 'email',
    [NODE_LINK_TYPES.PAYPAL]: 'paypal',
    [NODE_LINK_TYPES.PHONE]: 'phone',
    [NODE_LINK_TYPES.REGULAR]: 'link',
    [NODE_LINK_TYPES.SMS]: 'sms',
    [NODE_LINK_TYPES.TELEGRAM]: 'telegram',
    [NODE_LINK_TYPES.VENMO]: 'venmo',
    [NODE_LINK_TYPES.WHATS_APP]: 'whatsapp',
  })
