import { useContext } from 'react'
import { EditFormsContext } from '../providers'

type Props = {
  onAfterAddAdditionalField?: () => void
}
const useEditForms = ({ onAfterAddAdditionalField }: Props = {}) => {
  const { onAddFormsField, ...context } = useContext(EditFormsContext)

  return {
    ...context,
    onAddFormsField: async () => {
      await onAddFormsField()

      if (onAfterAddAdditionalField) {
        onAfterAddAdditionalField()
      }
    },
  }
}

export default useEditForms
