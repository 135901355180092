export const enum ACTION_TYPE {
  SET_IS_GOOGLE_SHEETS_URL_VALID = 'SET_IS_GOOGLE_SHEETS_URL_VALID',
}

const setIsGoogleSheetsUrlValid = (isGoogleSheetsUrlValid: boolean) => ({
  type: ACTION_TYPE.SET_IS_GOOGLE_SHEETS_URL_VALID,
  isGoogleSheetsUrlValid,
})

const actions = {
  setIsGoogleSheetsUrlValid,
} as const

export default actions
