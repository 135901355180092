import React, { useMemo } from 'react'
import { EmptyPagesBoardIcon } from 'boards-web-ui'

import { useBoardsMenuTranslations } from '@features/boardsMenu/hooks/useBoardsMenuTranslations'

import { ReactComponent as EmptyBoardStateIcon } from '../icons/EmptyBoardState.svg'
import { ReactComponent as EmptyFolderStateIcon } from '../icons/EmptyFolderState.svg'
import { ReactComponent as EmptyPageEditorIcon } from '../icons/EmptyPageEditor.svg'

import styles from './EmptyContent.module.css'

export enum EmptyBoardVariants {
  noBoards = 'noBoards',
  board = 'board',
  folder = 'folder',
  page = 'page',
  pageEditor = 'pageEditor',
  pageBoard = 'pageBoard',
}
type Props = {
  variant?: EmptyBoardVariants
  isSharedFolderMode?: boolean
}

const EmptyContent: React.FC<Props> = ({
  variant = EmptyBoardVariants.board,
  isSharedFolderMode,
}) => {
  const {
    emptyBoardTitle,
    emptyBoardSubTitle,
    emptyBoardTitle2,
    emptyBoardSubTitle2,
    emptyFolderTitle,
    emptyFolderSubTitle,
    emptyPageTitle,
    emptyPageSubTitle,
    emptyPagesBoardTitle,
    emptyPagesBoardSubTitle,
  } = useBoardsMenuTranslations()

  const { icon, title, subTitle } = useMemo(() => {
    switch (variant) {
      case EmptyBoardVariants.board:
        return {
          icon: <EmptyFolderStateIcon />,
          title: emptyBoardTitle(),
          subTitle: emptyBoardSubTitle(),
        }

      case EmptyBoardVariants.folder:
        return {
          icon: <EmptyFolderStateIcon />,
          title: emptyFolderTitle(),
          subTitle: isSharedFolderMode ? undefined : emptyFolderSubTitle(),
        }

      case EmptyBoardVariants.pageEditor:
        return {
          icon: <EmptyPageEditorIcon />,
          title: emptyPageTitle(),
          subTitle: emptyPageSubTitle(),
        }

      case EmptyBoardVariants.noBoards:
        return {
          icon: <EmptyBoardStateIcon />,
          title: emptyBoardTitle2(),
          subTitle: emptyBoardSubTitle2(),
        }

      case EmptyBoardVariants.pageBoard:
        return {
          icon: <EmptyPagesBoardIcon width={135} height={110} />,
          title: emptyPagesBoardTitle(),
          subTitle: emptyPagesBoardSubTitle(),
        }

      default:
        return {
          icon: null,
          title: '',
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSharedFolderMode, variant])

  return (
    <div className={[styles.Root, styles.Center].join(' ')}>
      <div className={styles.Centred}>
        {icon}
        <h3>{title}</h3>
        {subTitle ? <p>{subTitle}</p> : null}
      </div>
    </div>
  )
}

export default EmptyContent
