/* eslint no-console: ["error", { allow: ["warn"] }] */
import { isSafari } from './browser'

async function requestClipboardWritePermission() {
  if (!navigator?.permissions?.query) return false

  const { state } = await navigator.permissions.query({
    name: 'clipboard-write',
  })

  return state === 'granted'
}

function canCopyTextToClipboard() {
  if (!navigator.clipboard) console.warn('Clipboard API not available')
  if (!window.isSecureContext) console.warn('Context is not secure')
  if (!document.hasFocus()) console.warn('Document is not focused')

  return navigator.clipboard && window.isSecureContext && document.hasFocus()
}

function canCopyImagesToClipboard() {
  const hasFetch = typeof fetch !== 'undefined'
  const hasClipboardItem = typeof ClipboardItem !== 'undefined'
  const hasNavigatorClipboardWriteFunction = !!navigator?.clipboard?.write
  return hasFetch && hasClipboardItem && hasNavigatorClipboardWriteFunction
}

async function getBlobFromImageSource(imageSource) {
  const response = await fetch(`${imageSource}`)
  return response.blob()
}

function isJpegBlob(blob) {
  return blob && blob.type.includes('jpeg')
}

function isPngBlob(blob) {
  return blob && blob.type.includes('png')
}

async function createImageElement(imageSource) {
  return new Promise((resolve, reject) => {
    const imageElement = document.createElement('img')
    imageElement.crossOrigin = 'anonymous'
    imageElement.src = imageSource

    imageElement.onload = (event) => {
      // eslint-disable-next-line
      const target = event.target
      resolve(target)
    }

    imageElement.onabort = reject
    imageElement.onerror = reject
  })
}

async function getBlobFromImageElement(imageElement) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    if (context) {
      const { width, height } = imageElement
      canvas.width = width
      canvas.height = height
      context.drawImage(imageElement, 0, 0, width, height)

      canvas.toBlob(
        // eslint-disable-next-line
        function (blob) {
          if (blob) resolve(blob)
          else reject(new Error('Cannot get blob from image element'))
        },
        'image/png',
        1,
      )
    }
  })
}

async function convertBlobToPng(imageBlob) {
  const imageSource = URL.createObjectURL(imageBlob)
  const imageElement = await createImageElement(imageSource)
  return getBlobFromImageElement(imageElement)
}

async function copyBlobToClipboard(blob) {
  const items = { [blob.type]: blob }
  try {
    // eslint-disable-next-line
    const clipboardItem = new ClipboardItem(items)
    await navigator.clipboard.write([clipboardItem])
  } catch (e) {
    throw new Error(e.message)
  }
}

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  // make the textarea out of viewport
  textArea.style.position = 'fixed'
  textArea.style.left = '0'
  textArea.style.top = '0'
  textArea.style.zIndex = '-9999999'
  textArea.style.opacity = '0'
  document.body.appendChild(textArea)

  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false

  textArea.focus()
  textArea.select()

  document.execCommand('copy')
  document.body.removeChild(textArea)

  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }

  return text
}

export async function copyTextToClipBoard(text) {
  // navigator clipboard api needs a secure context (https)
  const state = canCopyTextToClipboard()

  if (state) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(text)
  }

  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  }

  return fallbackCopyTextToClipboard(text)
}

export async function copyImageToClipboard(imageSource) {
  const state = isSafari()
    ? await requestClipboardWritePermission()
    : canCopyImagesToClipboard()

  if (!state) {
    // eslint-disable-next-line
    console.info(
      // eslint-disable-next-line
      `Sorry, but it looks like your browser or its settings does not support the function of copying a picture to the clipboard directly, so we try to copied the link`,
    )
    return copyTextToClipBoard(imageSource)
  }

  let blob

  try {
    blob = await getBlobFromImageSource(imageSource)
  } catch (e) {
    // nothing need to do in this case
  }

  if (isJpegBlob(blob)) {
    const pngBlob = await convertBlobToPng(blob)
    await copyBlobToClipboard(pngBlob)
    return blob
  }

  if (isPngBlob(blob)) {
    await copyBlobToClipboard(blob)
    return blob
  }

  if (!blob) {
    // eslint-disable-next-line
    console.info(
      // eslint-disable-next-line
      `Sorry, but it looks like when copying a picture, there were problems with the browser settings or its capabilities, so we are trying to copy the link`,
    )
  }

  return copyTextToClipBoard(imageSource)
}

export function insertTextFromClipBoardAtCaret() {
  const cleanText = window.event.clipboardData.getData('text/plain')
  try {
    document.execCommand('insertText', false, cleanText)
  } catch (e) {
    let sel
    let range
    if (window.getSelection) {
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0)
        range.deleteContents()
        range.insertNode(document.createTextNode(cleanText))
      }
    } else if (document.selection && document.selection.createRange) {
      document.selection.createRange().text = cleanText
    }
  }
}
