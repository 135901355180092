/* eslint no-console: ["error", { allow: ["warn"] }] */

import { configurationObjectFromEnv } from '../../../../utils/configurationObjectFromEnv'
import { ENV_KEYS } from '../../../../utils/envKeys'

interface Paths {
  pdfUrl: string
}

const { pdfUrl } = configurationObjectFromEnv<Paths>({
  pdfUrl: ENV_KEYS.PDF_URL,
})

export const getPdfBucketUrl = (url: string) => {
  try {
    const { pathname, hostname, origin } = new URL(url)
    const pathnameArr = pathname.split('/')

    if (origin !== pdfUrl) return url

    if (pathnameArr.length > 3 || pathnameArr.length < 2) {
      throw new Error(`Wrong url`)
    }

    let fileName = pathnameArr[1]
    if (pathnameArr.length === 3) {
      fileName += '.pdf'
    }

    return `https://file.${hostname}/${fileName}`
  } catch (error) {
    console.warn(`Error parsing pdf url: ${url} err: ${error}`)
    return null
  }
}
