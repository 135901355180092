import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { AddNodeData } from '@features/toolbar/models'
import useBoardEvents from '@features/analytics/useBoardEvents'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

import { ViewSource } from '@features/analytics/models/sourceEvents'
import { ContentPickersEnum } from '@features/pickers'
import { boardActions, bulkActions, nodeActions } from '../actions'

import { useToast } from './useToast'
import useIsApiAvailableOrNotify from './useIsApiAvailableOrNotify'

const useBoardApi = () => {
  const dispatch = useDispatch()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const toast = useToast()
  const { createBoardEvent } = useBoardEvents()

  const create = useCallback(
    (
      owner: string,
      boardId: string,
      key: string,
      node: AddNodeData,
      source: ViewSource,
      picker?: ContentPickersEnum,
    ) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(
        boardActions.createBoardAction(owner, boardId, key, node, picker),
      )
      createBoardEvent({ boardId, title: node.title, source })
    },
    [dispatch, isApiAvailableOrNotify, createBoardEvent],
  )

  const update = useCallback(
    (
      boardId: string,
      folderId: string,
      contentId: string,
      node: AddNodeData,
    ) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(
        nodeActions.setProperties(
          boardId,
          folderId,
          contentId,
          node as unknown as INodeProps,
        ),
      )
    },
    [dispatch, isApiAvailableOrNotify],
  )

  const deleteBoard = useCallback(
    (boardId: string, successMessage?: string) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(boardActions.deleteBoard(boardId))

      if (successMessage) {
        toast(successMessage, true, 300, true)
      }
    },
    [dispatch, isApiAvailableOrNotify, toast],
  )

  const selectKeys = useCallback(
    (boardId: string, folderId: string) =>
      dispatch(bulkActions.setBulkAction({ boardId, folderId })),
    [dispatch],
  )

  const leaveBoard = useCallback(
    (boardId: string, email: string | null | undefined) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(boardActions.leaveBoard(boardId, [email]))
    },
    [dispatch, isApiAvailableOrNotify],
  )

  return {
    create,
    update,
    deleteBoard,
    selectKeys,
    leaveBoard,
  } as const
}

export default useBoardApi
