import React, { useEffect } from 'react'
import useFirebaseListeners from '_firebase/hooks/useFirebaseListeners'
import SignUpForm from '../../components/SignUpForm'

const PageSignUp: React.FC = () => {
  const { startClientConfigListener } = useFirebaseListeners()

  useEffect(() => {
    startClientConfigListener()
  }, [startClientConfigListener])

  return <SignUpForm />
}

export default PageSignUp
