import { StatState } from '../models/insights'
import { InsightsFilter } from '../models/filter'
import { StatisticRange } from '../models/insightsResult'
import { Node } from '../../../models'

const filterData = (data: StatisticRange, range: InsightsFilter) => {
  if (range === InsightsFilter.TODAY) return data.currentDay
  if (range === InsightsFilter.YESTERDAY) return data.previousDay
  if (range === InsightsFilter.THIS_WEEK) return data.currentWeek
  if (range === InsightsFilter.LAST_WEEK) return data.previousWeek
  if (range === InsightsFilter.THIS_MONTH) return data.currentMonth
  if (range === InsightsFilter.LAST_MONTH) return data.previousMonth

  return data.allTime
}

export const transformDataToTopContent = (
  data: StatisticRange,
  range: InsightsFilter,
  getNode: { (nodeId: string): Node },
): StatState[] => {
  const topContent: StatState[] = []
  filterData(data, range).forEach(({ id, all }) => {
    const node = getNode(id)
    if (node) {
      topContent.push({
        id,
        count: all,
        node,
      } as StatState)
    }
  })
  return topContent
}
