import DataCollectingMsgBanner from './DataCollectingMsgBanner'

import styles from './Banners.module.css'

interface Props {
  dataAvailableFromDate: Date
  isCollectingMsgBannerEnabled: boolean
}

const Banners = ({
  dataAvailableFromDate,
  isCollectingMsgBannerEnabled,
}: Props) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Item}>
        {isCollectingMsgBannerEnabled && (
          <DataCollectingMsgBanner
            dataAvailableFromDate={dataAvailableFromDate}
          />
        )}
      </div>
    </div>
  )
}

export default Banners
