/* eslint no-console: ["error", { allow: ["error"] }] */

export const appcuesReloadPage = () => {
  try {
    window.Appcues.page()
  } catch {
    console.error('Error on reload Appcues page')
  }
}

export const appcuesSetUserIdentity = (userId, identity) => {
  try {
    window.Appcues.identify(userId, identity)
  } catch {
    console.error('Error on set Appcues identity')
  }
}
