import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

import { AddPageData, UpdatePageData } from '../models'
import { nodeActions, pageActions } from '../../../../actions'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

const usePageAPI = () => {
  const dispatch = useDispatch()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const create = useCallback(
    (boardId: string, folderId: string, key: string, node: AddPageData) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(pageActions.addPage(boardId, folderId, key, node))
    },
    [dispatch, isApiAvailableOrNotify],
  )

  const update = useCallback(
    (
      boardId: string,
      folderId: string,
      contentId: string,
      node: UpdatePageData,
    ) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(
        nodeActions.setProperties(
          boardId,
          folderId,
          contentId,
          node as unknown as INodeProps,
        ),
      )
    },
    [dispatch, isApiAvailableOrNotify],
  )

  return {
    create,
    update,
  } as const
}

export default usePageAPI
