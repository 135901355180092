import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const usePaywallPlansBulletsProps = () => {
  const { t } = useTranslation()

  const freePlanBullets = useMemo(() => {
    return {
      title: '',
      items: [
        t('paywall_feature_owned_boards_desktop'),
        t('paywall_feature_sponsored'),
        t('paywall_feature_free_shares'),
      ],
    }
  }, [t])

  const businessPlanBullets = useMemo(() => {
    return {
      title: '',
      items: [
        t('paywall_feature_owned_board'),
        t('paywall_feature_shared_board'),
        t('paywall_feature_unlimited_shares'),

        t('paywall_feature_templates_v2'),
        t('paywall_feature_checkout_v2'),

        t('paywall_feature_web_forms'),
        t('paywall_feature_content_analytics'),
      ],
    }
  }, [t])

  return { freePlanBullets, businessPlanBullets }
}
