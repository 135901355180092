import { useDialog } from 'boards-web-ui'

import EditAudio from '../components/EditAudio'
import { Node } from '../../../models'
import { IUseSaveNode } from '../models/IUseEditNode'

const useEditAudio: IUseSaveNode = (save) => {
  const { open } = useDialog()

  const editAudio = (node: Node) => {
    open(<EditAudio node={node} onSave={(n) => save(node.id, n)} />, {
      fullScreen: true,
      blankDialog: true,
    })
  }

  return editAudio
}

export default useEditAudio
