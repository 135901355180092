import { Trans } from 'react-i18next'
import { NodeType } from '../../../models'

import styles from './NoTitle.module.css'

interface Props {
  type: NodeType
}

const NoTitle = ({ type }: Props) => (
  <span className={styles.Root}>
    {type === NodeType.IMAGE && <Trans i18nKey="insights_tab_no_image_title" />}
    {type === NodeType.PDF && <Trans i18nKey="insights_tab_no_pdf_title" />}
    {type === NodeType.VIDEO && <Trans i18nKey="insights_tab_no_video_title" />}
    {type === NodeType.VIDEO_STREAMING && (
      <Trans i18nKey="insights_tab_no_video_title" />
    )}
  </span>
)

export default NoTitle
