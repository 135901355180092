interface IColors {
  gray100: string
  gray600: string
  gray700: string

  purple100: string
  purple200: string
  purple700: string
  purple1000: string
}

export const colors: IColors = {
  gray100: '#F8F8F8',
  gray600: '#888888',
  gray700: '#666666',

  purple100: '#F2F4FF',
  purple200: '#E8EBFF',
  purple700: '#3A4DDF',
  purple1000: '#000B46',
}
