import { FC, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import {
  ButtonNew,
  Grid,
  Overlay,
  PremiumCrownIcon,
  PremiumIndicationIcon,
  Spinner,
} from 'boards-web-ui'

import Carousel from '@features/templateGallery/components/Carousel'
import MenuItem from '@features/templateGallery/components/MenuItem'
import Template from '@features/templateGallery/components/Template'
import {
  TemplateCategoryModel,
  TemplateModel,
} from '@features/templateGallery/models/Template'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { IconWrapper } from '@ui/components/IconWrapper'
import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'

import { CSS_TRANSITION_TIME } from 'constants/CSSTransitionTime'
import Box from 'elements/Box'

import styles from './TemplateGallery.module.css'

interface Props {
  isCopyingNodes: boolean
  isLoading: boolean
  templateCategories: TemplateCategoryModel[]
  onCloseTemplateGallery: () => void
  onTemplateClick: (
    selectedTemplate: TemplateModel,
    templates: TemplateModel[],
    templateCategory: TemplateCategoryModel,
  ) => void
  onTemplateCategoryClick: (category: string) => void
  onStartFromScratchClick: () => void
}
const TemplateGallery: FC<Props> = ({
  isCopyingNodes,
  isLoading,
  templateCategories,
  onCloseTemplateGallery,
  onTemplateClick,
  onTemplateCategoryClick,
  onStartFromScratchClick,
}) => {
  const {
    profile: { plan },
  } = useProfileInformation()
  const [activeMenuItem, setActiveMenuItem] = useState(
    templateCategories?.[0]?.id,
  )
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [setShow])

  const currentTemplateCategory = templateCategories?.find(
    (c) => c.id === activeMenuItem,
  )
  const templates = currentTemplateCategory?.templates || []

  useEffect(() => {
    const firstId = templateCategories?.[0]?.id
    setActiveMenuItem(firstId)
  }, [templateCategories])

  const handleCategoryClick = (templateCategory: TemplateCategoryModel) => {
    setActiveMenuItem(templateCategory.id)
    onTemplateCategoryClick(templateCategory.nameFormatted)
  }

  const handleCloseTemplateGallery = () => {
    setShow(false)

    setTimeout(() => {
      onCloseTemplateGallery()
    }, CSS_TRANSITION_TIME)
  }

  return (
    <CSSTransition
      in={show}
      classNames="create-page"
      timeout={CSS_TRANSITION_TIME}
    >
      <Overlay index={-9} position="fixed" color={'rgba(0, 0, 0, 0.2)'}>
        {(isLoading || isCopyingNodes) && (
          <div className={styles.SpinnerBackground} />
        )}
        <div className={styles.Root}>
          <div className={styles.Header}>
            <CloseButtonWithTooltip onClose={handleCloseTemplateGallery} gray />
          </div>
          <Grid>
            <h1 className={styles.Title}>
              <Trans i18nKey={'page_template_gallery_header'} />{' '}
            </h1>
            {!plan.features.themes && (
              <IconWrapper size={22}>
                <PremiumIndicationIcon />
              </IconWrapper>
            )}
          </Grid>
          <h4 className={styles.SubTitle}>
            <Trans i18nKey={'page_template_gallery_subtitle'} />
          </h4>
          <div className={styles.Menu}>
            {templateCategories?.map((templateCategory) => (
              <MenuItem
                key={templateCategory.id}
                active={activeMenuItem === templateCategory.id}
                onClick={() => handleCategoryClick(templateCategory)}
              >
                {templateCategory.nameFormatted}
              </MenuItem>
            ))}
          </div>
          <div className={styles.CarouselWrapper}>
            <Spinner
              className={styles.Spinner}
              size="large"
              spinning={isLoading || isCopyingNodes}
            >
              <Carousel itemWidth={168}>
                {currentTemplateCategory &&
                  templates.map((template) => (
                    <Template
                      key={template.id}
                      template={template}
                      onClick={() =>
                        onTemplateClick(
                          template,
                          templates,
                          currentTemplateCategory,
                        )
                      }
                    />
                  ))}
              </Carousel>
            </Spinner>
          </div>

          <Box mt={40} sx={{ display: 'flex', justifyContent: 'center' }}>
            <span className={styles.BottomDescriptionText}>
              <Trans
                i18nKey={
                  'page_template_gallery_start_from_scratch_header_desktop'
                }
              />
            </span>
          </Box>
          <Box mt={20} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonNew
              color={'secondary'}
              size={'large'}
              startIcon={
                !plan.features.themes && (
                  <IconWrapper>
                    <PremiumCrownIcon />
                  </IconWrapper>
                )
              }
              onClick={onStartFromScratchClick}
            >
              <Trans i18nKey={'page_template_gallery_start_from_scratch'} />
            </ButtonNew>
          </Box>
        </div>
      </Overlay>
    </CSSTransition>
  )
}

export default TemplateGallery
