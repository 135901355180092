import React, { FC, HTMLAttributes } from 'react'
import { MenuItem } from 'boards-web-ui'
import clsx from 'clsx'
import { IconWrapper } from '@ui/components/IconWrapper'
import styles from './AccountMenu.module.css'

export interface IAccountMenuItemProps {
  Icon: FC<HTMLAttributes<Element>>
  title: string
  isActive?: boolean
  onClick?: () => void
}

export const AccountMenuItem: FC<IAccountMenuItemProps> = ({
  Icon,
  title,
  isActive,
  onClick,
}) => (
  <MenuItem
    as="button"
    className={clsx(styles.Item, isActive && styles.Active)}
    onClick={onClick}
  >
    <IconWrapper className={styles.Icon}>
      <Icon />
    </IconWrapper>

    {title}
  </MenuItem>
)
