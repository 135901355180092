import { FC, useEffect } from 'react'
import BoardsSelection from '@features/pickers/components/BoardsSelection'
import FolderSelection from '@features/pickers/components/FolderSelection'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import PageSelection from '@features/pickers/components/PageSelection'
import useFirebaseListeners from '../../../../../_firebase/hooks/useFirebaseListeners'
import { Node as NodeModel } from '../../../../models'

interface Props {
  nodes: NodeModel[]
  toggleNode: (node: NodeModel) => void
  showClearMsgIfHasSelectedNodes: (callback: () => void) => void
}

export const SelectContentDialogBody: FC<Props> = ({
  nodes,
  toggleNode,
  showClearMsgIfHasSelectedNodes,
}) => {
  const {
    pickerBoardId: boardId,
    pickerFolderId: folderId,
    isPage,
    selectBoard,
    selectFolder,
  } = useContentNavigation(ContentPickersEnum.SourcePicker)

  const { startBoardFoldersListener } = useFirebaseListeners()

  useEffect(() => {
    if (boardId) {
      const [uid, bid] = boardId.split('-')
      startBoardFoldersListener(uid, bid)
    }
  }, [boardId, startBoardFoldersListener])

  return (
    <>
      {!boardId && <BoardsSelection selectBoard={selectBoard} />}

      {boardId && folderId && !isPage && (
        <FolderSelection
          nodes={nodes}
          boardId={boardId}
          folderId={folderId}
          selectFolder={(fid) =>
            showClearMsgIfHasSelectedNodes(() => selectFolder(fid))
          }
          toggleNode={toggleNode}
          picker={ContentPickersEnum.SourcePicker}
        />
      )}

      {boardId && folderId && isPage && (
        <PageSelection
          nodes={nodes}
          boardId={boardId}
          folderId={folderId}
          toggleNode={toggleNode}
        />
      )}
    </>
  )
}
