import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { urlHelper } from 'boards-web-ui'

import {
  CHECKOUT_POST_SUBMITTING_BEHAVIOUR,
  DEFAULT_POST_SUBMISSION_MESSAGE,
} from '@features/checkout/constants'
import useFormsEvents from '@features/analytics/useFormsEvents'

import { AfterSubmitBehaviourEnum } from '../constants'

type Props = {
  defaultAfterSubmitBehaviour?: AfterSubmitBehaviourEnum
  defaultFormRedirectUrl?: string
  defaultFormCustomMessage?: string
  defaultAction?: string
}
const useSettingsPostSubmission = ({
  defaultAction,
  defaultAfterSubmitBehaviour = AfterSubmitBehaviourEnum.MESSAGE,
  defaultFormRedirectUrl = '',
  defaultFormCustomMessage = '',
}: Props) => {
  const { t } = useTranslation()

  const {
    appFormPostSubmissionRedirectSelect,
    appFormPostSubmissionRedirectEdit,
    appFormPostSubmissionMessageSelect,
    appFormPostSubmissionMessageEdit,
  } = useFormsEvents()
  const [formAction, setFormAction] = useState<string>(defaultAction || '')
  const [formCustomMessage, setFormCustomMessage] = useState<string>(
    defaultFormCustomMessage,
  )
  const [formRedirectUrl, setFormRedirectUrl] = useState<string>(
    defaultFormRedirectUrl,
  )
  const [afterSubmitBehaviour, setAfterSubmitBehaviour] =
    useState<AfterSubmitBehaviourEnum>(defaultAfterSubmitBehaviour)

  const postSubmissionError: string = useMemo(() => {
    if (afterSubmitBehaviour === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT) {
      if (
        !formRedirectUrl.trim().length ||
        !urlHelper.isValidUrl(formRedirectUrl)
      ) {
        return t('error_invalid_url')
      }
    }

    return ''
  }, [afterSubmitBehaviour, formRedirectUrl, t])

  const onFormActionChange = useCallback(
    (newFormAction: string) => {
      setFormAction(newFormAction)
    },
    [setFormAction],
  )

  const onFormRedirectUrlChange = useCallback(
    (newFormRedirectUrl: string) => {
      appFormPostSubmissionRedirectEdit(true)
      setFormRedirectUrl(newFormRedirectUrl)
    },
    [setFormRedirectUrl, appFormPostSubmissionRedirectEdit],
  )

  const onFormCustomMessageChange = useCallback(
    (newFormCustomMessage: string) => {
      appFormPostSubmissionMessageEdit(true)
      setFormCustomMessage(
        newFormCustomMessage || t(DEFAULT_POST_SUBMISSION_MESSAGE),
      )
    },
    [setFormCustomMessage, appFormPostSubmissionMessageEdit, t],
  )

  const onAfterSubmitBehaviourChange = useCallback(
    (newAfterSubmitBehaviour: AfterSubmitBehaviourEnum) => {
      if (newAfterSubmitBehaviour === AfterSubmitBehaviourEnum.REDIRECT) {
        appFormPostSubmissionRedirectSelect(true)
      }

      if (newAfterSubmitBehaviour === AfterSubmitBehaviourEnum.MESSAGE) {
        appFormPostSubmissionMessageSelect(true)
      }

      setAfterSubmitBehaviour(newAfterSubmitBehaviour)
    },
    [
      setAfterSubmitBehaviour,
      appFormPostSubmissionRedirectSelect,
      appFormPostSubmissionMessageSelect,
    ],
  )

  return {
    formAction,
    formRedirectUrl,
    postSubmissionError,
    formCustomMessage,
    afterSubmitBehaviour,
    onFormActionChange,
    onFormRedirectUrlChange,
    onFormCustomMessageChange,
    onAfterSubmitBehaviourChange,
  } as const
}

export default useSettingsPostSubmission
