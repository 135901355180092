import { Trans, useTranslation } from 'react-i18next'
import { ContentPickersEnum, useDestinationPicker } from 'app/features/pickers'
import useBulk from '@hooks/bulk/useBulk'
import useBulkActions from '@hooks/bulk/useBulkActions'
import useBulkActionsPermissions from '@hooks/bulk/useBulkActionsPermissions'
import React, { useCallback } from 'react'
import useBoard from '@hooks/useBoard'
import { useCopyReceivedContent } from '@hooks/useCopyReceivedContent'
import {
  CloseIcon,
  CopyIcon,
  FolderIcon,
  PremiumCrownIcon,
  TrashIcon,
} from 'boards-web-ui'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import usePaywall from '@hooks/usePaywall'
import { IconWrapper } from '@ui/components/IconWrapper'

import Button from './components/Button'
import Toolbar from './components/Toolbar'

import styles from './BulkActionsToolbar.module.css'

interface Props {
  className: string
  isPageBoard: boolean
}
const BulkActionsToolbar = ({ className, isPageBoard }: Props) => {
  const { bulk, resetBulkActions } = useBulk()
  const nodesLength = bulk.nodes.length
  const { t } = useTranslation()
  const { deleteAction } = useBulkActions()
  const { openPaywall } = usePaywall()

  const { openDestinationPicker } = useDestinationPicker()
  const { isActive } = useBoard()

  const { isReadOnly, showActions, canCopyContentFromBoard } =
    useBulkActionsPermissions()

  const { canCopy, onCopyClick } = useCopyReceivedContent()

  const { isFreePlanUser } = useReverseTrialContext()

  const isLimitedInActive = isFreePlanUser && (isPageBoard || !isActive)
  const handleCopyClick = useCallback(() => {
    if (isLimitedInActive) {
      openPaywall({ source: PaywallSourceEnum.COPY_SELECTION })
      return
    }

    onCopyClick({
      source: PaywallSourceEnum.COPY_SELECTION,
      pickerProps: {
        headerCopy: t('destination_picker_copy_to'),
        performActionCopy: t('destination_picker_paste_here'),
        performActionSuccessMessage: t('toast_content_copied'),
        contentType: 'selection',
      },
    })
  }, [onCopyClick, t, isLimitedInActive, openPaywall])

  return (
    <Toolbar className={className} highlight>
      <>
        <div className={styles.CloseOption}>
          <Button className={styles.ButtonRound} onClick={resetBulkActions}>
            <IconWrapper>
              <CloseIcon />
            </IconWrapper>
          </Button>

          {nodesLength > 0 ? (
            <Trans
              i18nKey="placeholder_bulk_action_selected"
              values={{ count: nodesLength }}
            />
          ) : (
            <Trans i18nKey="action_select_keys" />
          )}
        </div>

        <div className={styles.Actions}>
          {showActions && (
            <>
              <Button
                onClick={() => {
                  openDestinationPicker({
                    picker: ContentPickersEnum.MovePicker,
                    contentType: 'selection',
                    headerCopy: (
                      <Trans i18nKey={'destination_picker_move_to'} />
                    ),
                    performActionCopy: (
                      <Trans i18nKey={'destination_picker_move_here'} />
                    ),
                    performActionSuccessMessage: t('toast_content_moved'),
                  })
                }}
                disabled={
                  isReadOnly ||
                  isPageBoard ||
                  !isActive ||
                  !canCopyContentFromBoard
                }
              >
                <IconWrapper>
                  <FolderIcon />
                </IconWrapper>

                <span className={styles.BtnText}>
                  <Trans i18nKey="action_move" />
                </span>
              </Button>

              <Button
                onClick={handleCopyClick}
                disabled={!canCopyContentFromBoard}
              >
                <IconWrapper>
                  {canCopy && !isLimitedInActive ? (
                    <CopyIcon />
                  ) : (
                    <PremiumCrownIcon />
                  )}
                </IconWrapper>

                <span className={styles.BtnText}>
                  <Trans i18nKey="action_copy" />
                </span>
              </Button>

              <Button onClick={deleteAction} disabled={isReadOnly}>
                <IconWrapper>
                  <TrashIcon />
                </IconWrapper>
                <span className={styles.BtnText}>
                  <Trans i18nKey="action_delete" />
                </span>
              </Button>
            </>
          )}
        </div>
      </>
    </Toolbar>
  )
}

export default BulkActionsToolbar
