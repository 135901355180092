import { useEffect, useState } from 'react'
import { getDatabase, ref, onValue } from 'firebase/database'

const db = getDatabase()

const useConnectionState = () => {
  const connectedRef = ref(db, '.info/connected')
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    return onValue(connectedRef, (snap) => {
      setIsConnected(snap.val() === true)
    })
  }, [connectedRef])

  return {
    isConnected,
  }
}

export default useConnectionState
