import React from 'react'
import VideoError from './VideoError'
import styles from './VideoInput.module.css'

interface Props {
  value: string
  hasUnderline?: boolean
  placeholder: string
  readOnly: boolean
  hasError: boolean
  onChange: (text: string) => void
}
const VideoInput = React.forwardRef<HTMLInputElement, Props>(
  ({ hasUnderline, value, hasError, placeholder, readOnly, onChange }, ref) => {
    const inputClasses = [styles.Input]
    if (hasUnderline) {
      inputClasses.push(styles.Underline)
    }

    return (
      <div className={styles.Root}>
        <input
          ref={ref}
          className={inputClasses.join(' ')}
          type="text"
          value={value}
          spellCheck="false"
          placeholder={placeholder}
          readOnly={readOnly}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
        {hasError && <VideoError />}
      </div>
    )
  },
)

export default VideoInput
