import { Subscription, SubscriptionPlan } from 'app/models'

const transformSubscription = (plans: SubscriptionPlan[]): Subscription[] => {
  return plans
    .map((p) => p.subscriptions)
    .flat()
    .reduce((acc, s) => {
      if (s.offer) {
        acc.push({
          productId: s.offer.priceId,
          productDiscountId: s.productDiscountId,
          period: s.period,
          title: s.title,
          offer: s.offer,
        })
      }

      acc.push({ ...s, offer: undefined })

      return acc
    }, [] as Subscription[])
}

export default transformSubscription
