import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { toastActions } from '../actions'

export const useToast = () => {
  const dispatch = useDispatch()

  return useCallback(
    (
      message: string,
      global = true,
      timeOut = 0,
      showIcon = false,
      customIcon: string | boolean = false,
      onClose?: () => void,
    ) => {
      if (timeOut) {
        const timer = setTimeout(() => {
          dispatch(
            toastActions.openToastMessage(
              message,
              global,
              showIcon,
              customIcon,
              onClose,
            ),
          )
          clearTimeout(timer)
        }, timeOut)
      } else {
        dispatch(
          toastActions.openToastMessage(
            message,
            global,
            showIcon,
            customIcon,
            onClose,
          ),
        )
      }

      if (onClose) {
        return
      }

      const timer = setTimeout(() => {
        dispatch(toastActions.closeToastMessage())
        clearTimeout(timer)
      }, 2500 + timeOut)
    },
    [dispatch],
  )
}
