import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { AnimatedDialog, urlHelper, useDialog } from 'boards-web-ui'

import ModalEditWithConfirmation from 'components/ModalEditWithConfirmation'
import DoneButton from 'ui/components/DoneButton'
import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'

import FormEditVideo from './FormEditVideo'
import useFetchVideoTitle from '../hooks/useFetchVideoTitle'
import { NodeType } from '../../../models'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'
import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

interface Props {
  initialTitle: string
  initialText: string
  onSave: ICreateOrUpdateNode
  isPageEditor?: boolean
}
const EditVideo = ({
  initialTitle,
  initialText,
  onSave,
  isPageEditor,
}: Props) => {
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const { title, text, hasChanges, setTitle, setText } = useNodeAPI(
    initialTitle,
    initialText,
  )

  const [hasError, setHasError] = useState(false)
  const ref = useRef<HTMLInputElement>()
  const { fetchTitle } = useFetchVideoTitle()

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
      ref.current.selectionStart = ref.current.value.length
      ref.current.selectionEnd = ref.current.value.length
    }
  }, [])

  const handleSave = () => {
    const video = urlHelper.getVideoDetails(text)
    if (video) {
      onSave({
        title,
        text: video.text,
        type: NodeType.VIDEO,
      })
      close()
    }
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  const handleTextChange = (newText: string) => {
    if (!setText || !setTitle) return

    setText(newText)

    if (!urlHelper.isSupportedVideoLink(newText)) {
      setHasError(true)
      return
    }
    setHasError(false)

    if (!title) {
      fetchTitle(newText)
        .then((titleByUrl) => {
          setTitle(titleByUrl)
        })
        .catch(() => {
          setHasError(true)
        })
    }
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: close,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton disabled={!text} onClick={handleSave}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          <FormEditVideo
            ref={ref as MutableRefObject<HTMLInputElement>}
            title={title}
            text={text}
            hasError={hasError}
            onTextChange={handleTextChange}
            onTitleChange={!isPageEditor ? setTitle : undefined}
          />
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditVideo
