import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PaddleEventData } from '@paddle/paddle-js'

import isFunctionsError from '@helpers/isFunctionsError'
import useGeoLocation from '@hooks/useGeoLocation'
import { useAuth } from '@hooks/useAuth'
import { useToast } from '@hooks/useToast'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { usePaddleSdk } from '@features/payments/hooks/usePaddleSdk'
import isCurrencySupportedByPaddle from '@features/payments/utils/isCurrencySupportedByPaddle'

import {
  UpdateSubscriptionProps,
  UpdateSubscriptionResult,
  updateSubscription,
} from '_firebase'

const usePaddleAPI = (eventCallback?: (event: PaddleEventData) => void) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: paddleSdk } = usePaddleSdk(user?.email || '', eventCallback)
  const { geoData } = useGeoLocation()
  const { profile } = useProfileInformation()

  const startCheckout = useCallback(
    (priceId: string, discountId?: string) => {
      const checkoutParams = {
        discountId,
        settings: {
          locale: geoData?.countryCode,
          // successUrl: window.location.origin,
          showAddDiscounts: false,
        },
        customer: {
          email: user?.email || '',
          address: {
            countryCode: geoData?.countryCode,
            city: geoData?.city,
          },
        },
        customData: {
          accountToken: profile.store.accountToken || '',
        },
        items: [
          {
            priceId,
            quantity: 1,
          },
        ],
        showAddDiscounts: false,
      }

      paddleSdk?.Checkout.open(checkoutParams)
    },
    [
      user?.email,
      profile.store.accountToken,
      geoData?.countryCode,
      geoData?.city,
      paddleSdk?.Checkout,
    ],
  )

  const getProductPrices = useCallback(
    async (productIds: string[], discountId?: string) => {
      const items = productIds.map((priceId) => ({
        quantity: 1,
        priceId,
      }))

      const result = await paddleSdk?.PricePreview({
        discountId,
        items,
        currencyCode: isCurrencySupportedByPaddle(geoData?.currency)
          ? geoData?.currency
          : 'USD',
        address: geoData?.countryCode
          ? {
              countryCode: geoData?.countryCode,
            }
          : undefined,
      })

      return result
    },
    [geoData?.countryCode, geoData?.currency, paddleSdk],
  )

  const updateSubscriptionWithToast = async (
    data: UpdateSubscriptionProps,
  ): Promise<UpdateSubscriptionResult | undefined> => {
    try {
      return await updateSubscription(data)
    } catch (error) {
      if (
        isFunctionsError(error) &&
        error.details?.error_code === 'payment-declined'
      ) {
        toast(t('payment_declined'))
        return undefined
      }

      toast(t('optimistic_ui_failed'))
      return undefined
    }
  }

  const updateCheckout = (priceId: string) =>
    updateSubscriptionWithToast({
      action: 'change-plan',
      productId: priceId,
    })

  const cancelSubscription = () =>
    updateSubscriptionWithToast({
      action: 'cancel',
    })

  const reinstateSubscription = () =>
    updateSubscriptionWithToast({
      action: 'reinstate',
    })

  const updatePayment = async () => {
    const data = await updateSubscriptionWithToast({
      action: 'update-payment-method',
    })

    if (data?.transactionId) {
      const checkoutParams = {
        settings: {
          locale: geoData?.countryCode,
          successUrl: window.location.origin,
        },
        customer: {
          email: user?.email || '',
          address: {
            countryCode: geoData?.countryCode,
            city: geoData?.city,
          },
        },
        customData: {
          accountToken: profile.store.accountToken || '',
        },
        transactionId: data.transactionId,
        showAddDiscounts: false,
      }

      paddleSdk?.Checkout.open(checkoutParams)
    }
  }

  return {
    getProductPrices,
    startCheckout,
    updateCheckout,
    cancelSubscription,
    reinstateSubscription,
    updatePayment,
  } as const
}

export default usePaddleAPI
