export type CheckoutAction = {
  id: string
  actionName: string
  title: string
  isValid: boolean
  onNext?: () => void
  onCancel?: () => void
}
export type CheckoutActions = Array<CheckoutAction>

export const enum CheckoutActionsIdsEnum {
  PRODUCTS = 'products',
  FIELDS = 'fields',
  SETTINGS = 'settings',
}
