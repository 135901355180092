import React, { memo } from 'react'

import UpdatesSectionHeader from './UpdatesSectionHeader'
import UpdatesScrollArea from './UpdatesScrollArea'

import styles from './Updates.module.css'

export const Updates = () => {
  return (
    <div className={styles.Root}>
      <UpdatesSectionHeader />
      <UpdatesScrollArea />
    </div>
  )
}

export default memo(Updates)
