import React from 'react'

import { Profile } from '../../profile/Profile'

import { ReactComponent as Logo } from '../../../../icons/LogoBig.svg'
import styles from './SideBarHeader.module.css'

type Props = {
  className?: string
}
const SideBarHeader: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <Logo className={styles.Logo} />
      <Profile />
    </div>
  )
}

export default React.memo(SideBarHeader)
