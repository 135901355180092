import { useCallback, useState } from 'react'
import {
  boardId as getBoardId,
  generateFolderId,
  newKey,
} from '@helpers/NodeIdGenerator'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import { useAuth } from '@hooks/useAuth'
import useBoardApi from '@hooks/useBoardApi'
import { randomEmojiExcluding } from '@helpers/randomEmojis'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import { useDispatch } from 'react-redux'
import useFolderApi from '@features/toolbar/hooks/useFolderApi'
import { folderActions } from '../../../../../actions'

export const useAddNewEntityActions = ({
  picker,
}: {
  picker: ContentPickersEnum
}) => {
  const { pickerBoardId: boardId, pickerFolderId: folderId } =
    useContentNavigation(picker)
  const dispatch = useDispatch()

  const { user } = useAuth()
  const { create: createBoard } = useBoardApi()
  const { create: createFolder } = useFolderApi()

  const [newIcon, setNewIcon] = useState<string>(randomEmojiExcluding([]))

  const handleCreateBoard = useCallback(
    (name: string) => {
      const currentUserId = user?.uid
      const key = newKey()
      const newBoardId = getBoardId(key)

      if (currentUserId) {
        createBoard(
          currentUserId,
          newBoardId,
          key,
          {
            title: name,
            icon: newIcon,
          },
          ViewSource.destinationPicker,
          picker,
        )
      }
    },
    [createBoard, newIcon, picker, user?.uid],
  )

  const handleCreateFolder = useCallback(
    (name: string) => {
      const { key } = generateFolderId()

      if (boardId && folderId) {
        dispatch(
          folderActions.addFolderFake(
            boardId,
            folderId,
            key,
            {
              title: name,
              icon: newIcon,
            },
            false,
            picker,
          ),
        )

        createFolder(
          boardId,
          folderId,
          key,
          {
            title: name,
            icon: newIcon,
          },
          ViewSource.destinationPicker,
        )
      }
    },
    [boardId, folderId, dispatch, newIcon, picker, createFolder],
  )

  return {
    handleCreateBoard,
    handleCreateFolder,
    setNewIcon,
    newIcon,
  } as const
}
