import styles from './ProgressBar.module.css'

interface Props {
  progress: number
  isRed?: boolean
}
const ProgressBar = ({ progress, isRed }: Props) => {
  const progressInRange = Math.min(Math.max(progress, 0), 100)

  const classes = [styles.Root]
  classes.push(isRed ? styles.Red : styles.Blue)

  return (
    <div className={classes.join(' ')}>
      <div className={styles.Background}>
        <div className={styles.Filler} style={{ width: `${progressInRange}%` }}>
          &nbsp;
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
