import { useCallback, useMemo, useState } from 'react'

const useActionStepper = <T extends { id: string }>(
  actions: Array<T>,
  initialScreen?: string,
) => {
  const [actionStep, setActionStep] = useState<number>(
    actions?.findIndex((action) => action?.id === initialScreen) || 0,
  )
  const currentAction = useMemo(
    () => actions[actionStep],
    [actionStep, actions],
  )

  const nextStep = useCallback(() => {
    setActionStep((_actionStep) =>
      _actionStep < actions.length - 1 ? _actionStep + 1 : actions.length,
    )
  }, [setActionStep, actions.length])

  const prevStep = useCallback(() => {
    setActionStep((_actionStep) => (_actionStep > 0 ? _actionStep - 1 : 0))
  }, [setActionStep])

  return { currentAction, nextStep, prevStep, setActionStep } as const
}

export default useActionStepper
