import { PropsWithChildren, ReactElement } from 'react'

import styles from './ContentItemCover.module.css'

export const enum CoverBackground {
  WHITE = 'Gray',
  GRAY = 'White',
}

type CoverProps = PropsWithChildren<{
  cover?: string
  alt?: string
  icon?: string | ReactElement
  background: CoverBackground
}>

const ContentItemCover = ({ background, cover, icon, alt }: CoverProps) => {
  const RootClass = styles[`Cover${background}`]

  return (
    <div className={RootClass}>
      {cover && (
        <img className={styles.Cover} src={cover} alt={alt} loading="lazy" />
      )}
      {icon && <div className={styles.Icon}>{icon}</div>}
    </div>
  )
}

export default ContentItemCover
