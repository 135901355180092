import { useEffect } from 'react'
import ProfilingContent from '@features/profiling/components/ProfilingContent'
import Layout from '../layout/Layout'
import Container from './components/Container'
import useProfilingAPI from './hooks/useProfilingAPI'
import useProfilingEvents from '../analytics/useProfilingEvents'

const Profiling = () => {
  const { showMarketingForm } = useProfilingAPI()
  const { showProfiling } = useProfilingEvents()

  useEffect(() => {
    showProfiling()
    showMarketingForm()
  }, [showProfiling, showMarketingForm])

  return (
    <Layout>
      <Container>
        <ProfilingContent />
      </Container>
    </Layout>
  )
}

export default Profiling
