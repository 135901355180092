import { useDialog } from 'boards-web-ui'

import useNodeEvents from '@features/analytics/useNodeEvents'
import EditImage from '../components/EditImage'
import { IUseSaveNode } from '../models/IUseEditNode'

const useEditImage: IUseSaveNode = (save, isPageEditor) => {
  const { open: openDialog, close: closeDialog } = useDialog()
  const { appContentReplaceEvent } = useNodeEvents()

  return (node) => {
    openDialog(
      <EditImage
        nodeId={node.id}
        initialText={node?.text || ''}
        initialTitle={node?.title || ''}
        onSave={(p) => {
          save(node.id, p)
          if (isPageEditor) {
            closeDialog()
          }
        }}
        onContentReplaceEvent={() => {
          appContentReplaceEvent({ node, isPageEditor })
        }}
        isPageEditor={isPageEditor}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }
}

export default useEditImage
