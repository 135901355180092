import PropTypes from 'prop-types'
import styles from './Typography.module.css'

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subheading1: 'h6',
  subheading2: 'h6',
  body1: 'p',
  body2: 'p',
  title: 'p',
  subtitle: 'p',
}

const variantsClass = {
  h1: styles.H1,
  h2: styles.H2,
  h3: styles.H3,
  h4: styles.H4,
  h5: styles.H5,
  h6: styles.H6,
  subheading1: styles.Subheading1,
  subheading2: styles.Subheading2,
  body1: styles.Body1,
  body2: styles.Body2,
  title: styles.Title,
  subtitle: styles.Subtitle,
}

const Typography = ({ variant, color, align, children, ...props }) => {
  const Component = variantsMapping[variant]
  const classeName = variantsClass[variant]
  const style = {}

  if (color) {
    style.color = color
  }
  if (align) {
    style.textAlign = align
  }

  return (
    <Component className={classeName} style={style} {...props}>
      {children}
    </Component>
  )
}

Typography.defaultProps = {
  variant: 'body1',
}

Typography.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variantsMapping)),
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  color: PropTypes.string,
}

export default Typography
