/* eslint no-console: ["error", { allow: ["error"] }] */
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useDialog } from 'boards-web-ui'

import {
  getFolderId,
  getPagesBoardId,
  getRootFolderId,
  newKey,
} from '@helpers/NodeIdGenerator'
import useRouterParams from '@hooks/useRouterParams'
import { useAuth } from '@hooks/useAuth'
import { useToast } from '@hooks/useToast'
import usePaywall from '@hooks/usePaywall'
import useTemplatePickerEvents, {
  TemplatePickerSourceEventEnum,
} from '@features/analytics/useTemplatePickerEvents'
import TemplateGallery from '@features/templateGallery/components/TemplateGallery'
import TemplatePreview from '@features/templateGallery/components/TemplatePreview'
import useTemplates from '@features/templateGallery/hooks/useTemplates'
import {
  TemplateCategoryModel,
  TemplateModel,
} from '@features/templateGallery/models/Template'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePageEvents from '@features/analytics/usePageEvents'

import useRotesHelper from 'app/routes/hooks/useRotesHelper'
import { Folder } from 'app/models'
import { contentActions } from 'actions'
import { copyTemplateApiCall } from '_firebase/nodeActions'

interface Props {
  isPagesBoardRoute?: boolean
}
const TemplatePicker: FC<Props> = ({ isPagesBoardRoute }) => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const { open, close } = useDialog()
  const toast = useToast()
  const { t } = useTranslation()
  const { openPaywall } = usePaywall()
  const { navigateToFirstBoard } = useRotesHelper()
  const navigate = useNavigate()
  const {
    profile: { plan },
  } = useProfileInformation()
  const { boardId: urlBoardId, folderId: urlFolderId } = useRouterParams()
  const [isCopyingNodes, setIsCopyingNodes] = useState(false)
  const redirectToPageBuilder = () =>
    navigate(`/${boardId}/${folderId}/new-page`)
  const { data, isLoading } = useTemplates({ onError: redirectToPageBuilder })
  const {
    pageTemplateCategoryViewEvent,
    pageTemplateCategoryClickEvent,
    pageTemplatePreviewEvent,
    pageTemplateStartFromScratchEvent,
    pageChooseThisTemplateClickEvent,
  } = useTemplatePickerEvents()
  const { createPageEvent } = usePageEvents()
  const location = useLocation()
  const routeSource = location.state?.source

  const pagesBoardId = `${user?.uid}-${getPagesBoardId(user?.uid || '')}`
  const boardId = isPagesBoardRoute ? pagesBoardId : urlBoardId
  const folderId = isPagesBoardRoute ? getRootFolderId(boardId) : urlFolderId
  const isPagesBoard = pagesBoardId === boardId

  useEffect(() => {
    const sourceFromClickAddPage = isPagesBoard
      ? TemplatePickerSourceEventEnum.BOARD
      : TemplatePickerSourceEventEnum.FOLDER

    pageTemplateCategoryViewEvent(
      routeSource === 'addPage'
        ? sourceFromClickAddPage
        : TemplatePickerSourceEventEnum.DIRECT_LINK,
    )
  }, [isPagesBoard, routeSource, pageTemplateCategoryViewEvent])

  const copyTemplateToBoard = async (
    selectedPage: Folder,
    templateCategory: TemplateCategoryModel,
  ) => {
    if (!plan.features.themes) {
      openPaywall({
        source: PaywallSourceEnum.PAGE_ADD_TEMPLATE,
        templateCategory: templateCategory.nameFormatted,
        templateName: selectedPage.title,
      })
      return
    }

    const key = newKey()
    const newPageId = getFolderId(selectedPage.id, key)
    pageChooseThisTemplateClickEvent({
      boardId,
      nodeId: newPageId,
      pageId: selectedPage.id,
      templateCategory: templateCategory.nameFormatted,
      templateName: selectedPage.title,
    })

    setIsCopyingNodes(true)
    close()

    try {
      dispatch(
        contentActions.copyTemplate(
          boardId,
          folderId,
          selectedPage.id,
          key,
          selectedPage,
        ),
      )

      await copyTemplateApiCall({
        targetBoardId: boardId,
        targetFolderId: folderId,
        template: selectedPage.id,
        key,
      })

      createPageEvent(selectedPage, boardId, isPagesBoard, {
        source: 'Template',
        template_category_name: templateCategory.nameFormatted,
        template_name: selectedPage.title,
      })

      navigate(`/${boardId}/${folderId}/edit-page/${newPageId}?create=true`, {
        state: {
          isCopiedFromTemplate: true,
          templateCategory: templateCategory.nameFormatted,
          templateName: selectedPage.title,
        },
      })
    } catch (error) {
      dispatch(contentActions.revertCopyTemplate(boardId, folderId, newPageId))
      console.error('Failed to copy template to board:', error)
      toast(t('optimistic_ui_failed'))
    } finally {
      setIsCopyingNodes(false)
    }
  }

  const closeTemplateGallery = () => {
    if (!isPagesBoardRoute && !boardId) {
      navigateToFirstBoard()
    }

    navigate(`/${boardId}/${folderId}`)
  }

  const startFromScratch = () => {
    if (!plan.features.themes) {
      openPaywall({ source: PaywallSourceEnum.START_FROM_SCRATCH })
      return
    }

    pageTemplateStartFromScratchEvent()
    redirectToPageBuilder()
  }

  const previewTemplate = (
    selectedTemplate: TemplateModel,
    templates: TemplateModel[],
    templateCategory: TemplateCategoryModel,
  ) => {
    pageTemplatePreviewEvent(
      templateCategory.nameFormatted,
      selectedTemplate.data.title,
    )

    open(
      <TemplatePreview
        nid={selectedTemplate.id}
        hasCrown={!plan.features.themes}
        templates={templates}
        onChooseThisTemplateClick={(selectedPage) =>
          copyTemplateToBoard(selectedPage, templateCategory)
        }
      />,
      {
        fullScreen: true,
        closeOnEscapeButton: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        withFullScreenDialogContainer: false,
      },
    )
  }

  return (
    <TemplateGallery
      isLoading={isLoading}
      isCopyingNodes={isCopyingNodes}
      templateCategories={data?.templateCategories || []}
      onCloseTemplateGallery={closeTemplateGallery}
      onTemplateClick={previewTemplate}
      onTemplateCategoryClick={pageTemplateCategoryClickEvent}
      onStartFromScratchClick={startFromScratch}
    />
  )
}

export default TemplatePicker
