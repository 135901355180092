import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, CountryChangeEvent, InputPhone, Spacer } from 'boards-web-ui'

import useGeoLocation from '@hooks/useGeoLocation'
import usePhoneVerificationEvents from '@features/analytics/usePhoneVerificationEvents'

import Screen from './Screen'

import styles from './PhoneNumberInput.module.css'

const RECAPTCHA_CONTAINER = 'phone-number-input'
interface Props {
  errors: { [fieldName: string]: string }
  submitting: boolean
  disabled: boolean
  onFocus: () => void
  onSendNumber: (
    phoneNumber: string,
    recaptchaContainer: string,
  ) => Promise<void>
}
const PhoneNumberInput = ({
  errors,
  disabled,
  submitting,
  onFocus,
  onSendNumber,
}: Props) => {
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState<CountryChangeEvent>()
  const { geoData } = useGeoLocation()

  const {
    verificationScreenViewEvent,
    verificationCountryClickEvent,
    verificationSendClickEvent,
  } = usePhoneVerificationEvents()

  useEffect(() => {
    verificationScreenViewEvent()
  }, [verificationScreenViewEvent])

  useEffect(() => {
    setCountryCode(geoData)
  }, [geoData])

  const handleSendCode = async () => {
    const number = `+${countryCode?.dialCode}${phoneNumber}`
    await onSendNumber(number, RECAPTCHA_CONTAINER)
    verificationSendClickEvent(countryCode?.countryCode)
  }

  const handleChangeCountry = (country: CountryChangeEvent) => {
    setCountryCode(country)
    verificationCountryClickEvent()
  }

  return (
    <Screen title={t('enter_phone_title')} text={t('enter_phone_body')}>
      <div id={RECAPTCHA_CONTAINER} />

      <Spacer height={40} />

      <InputPhone
        value={phoneNumber}
        countryCode={countryCode?.countryCode}
        placeholder={t('enter_phone_input')}
        errorMessage={disabled ? '' : errors.input}
        onChangeCountry={handleChangeCountry}
        onChange={(e) => setPhoneNumber(e.target.value)}
        disabled={submitting || disabled}
        onFocus={onFocus}
        autoFocus
      />

      <Spacer height={40} />

      <Button
        className={styles.Cta}
        onClick={handleSendCode}
        disabled={disabled}
        spinning={submitting}
        primary
        xl
      >
        <Trans i18nKey="enter_phone_cta" />
      </Button>
    </Screen>
  )
}

export default PhoneNumberInput
