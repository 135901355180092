import { FC } from 'react'
import { TPageNodeProfile } from 'boards-web-ui'

import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

import {
  PageProfileDialog,
  PageProfileProvider,
  ImageUploadProvider,
} from '@features/PageProfile'

type EditPageProfileProps = {
  initialState?: TPageNodeProfile
  imageUploadProvider: ImageUploadProvider
  onSave: ICreateOrUpdateNode
  onCancel: () => void
}
const EditPageProfile: FC<EditPageProfileProps> = ({
  initialState,
  imageUploadProvider,
  onSave,
  onCancel,
}) => {
  return (
    <PageProfileProvider
      initialState={initialState}
      imageUploadProvider={imageUploadProvider}
      onSave={onSave}
      onCancel={onCancel}
    >
      <PageProfileDialog />
    </PageProfileProvider>
  )
}

export default EditPageProfile
