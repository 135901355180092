import {
  CategoryScale,
  Chart as ChartJS,
  Chart,
  ChartTypeRegistry,
  FontSpec,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Scriptable,
  ScriptableTooltipContext,
  Title,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import createOptions from '../charts/options'
import annotationLinePlugin from '../charts/annotationLinePlugin'
import { colors } from '../config'
import { LineChartState } from '../models/insights'
import { shortDateFormat } from '../utils'

Chart.defaults.font.size = 14
Chart.defaults.font.family = 'Roboto'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

interface Props {
  data: LineChartState[]
  tooltipLabel: string
  onMouseOut: () => void
}

const LineChart = ({ data, tooltipLabel, onMouseOut }: Props) => {
  const dates = data.map((d) => d.date)
  const labels = data.map((d) => shortDateFormat(d.date))
  const values = data.map((d) => d.value)

  const eventPlugin = {
    id: 'eventPlugin',
    beforeEvent(_: unknown, { event }: { event: { type: string } }): void {
      if (event.type === 'mouseout') {
        onMouseOut()
      }
    },
  }

  Chart.register(eventPlugin, annotationLinePlugin)

  const options = createOptions(tooltipLabel, dates, values)
  const daysWithValue = values.filter((v) => v !== undefined)

  return (
    <Line
      height={320}
      options={options}
      data={{
        labels,
        datasets: [
          {
            data: values,
            borderColor: colors.purple700,
            borderWidth: 3,
            tension: 0.2,
            pointRadius: daysWithValue.length === 1 ? 3 : 0,
            order: 2,
          },
        ],
      }}
    />
  )
}

Chart.defaults.plugins.tooltip.padding = 7
Chart.defaults.plugins.tooltip.cornerRadius = 13
Chart.defaults.plugins.tooltip.titleFont = { weight: 'normal' } as Scriptable<
  FontSpec,
  ScriptableTooltipContext<keyof ChartTypeRegistry>
>
Chart.defaults.plugins.tooltip.footerFont = {
  weight: 'normal',
  size: 16,
} as Scriptable<FontSpec, ScriptableTooltipContext<keyof ChartTypeRegistry>>
Chart.defaults.plugins.tooltip.titleMarginBottom = 3

export default LineChart
