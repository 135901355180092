import { useEffect } from 'react'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useRouterParams from '../../../../hooks/useRouterParams'
import useFirebaseListeners from '../../../../_firebase/hooks/useFirebaseListeners'
import { appLoading } from '../../../../selectors'

const useLoadCurrentBoardFolders = () => {
  const loading = useSelectorFactory(appLoading)
  const { uid, bid } = useRouterParams()
  const { startBoardFoldersListener, startBoardPermissionsListener } =
    useFirebaseListeners()

  useEffect(() => {
    if (uid && bid && !loading) {
      startBoardFoldersListener(uid, bid)

      // TODO: fix ASAP
      if (
        ![
          'y6FgvMuICbUNGN7dPMw7cG7EoDY$',
          'NI8rhbvG0sJI41IHjAHGWg1sSFV$',
          'wbs5eJFpSzRzi0OtWCm1gVFwDht$',
          'KJuCDTwV31PfrmVj817GY18H71N$',
          '6brJ7EZrwNtlfW6rhPlL5wi0vcf$',
        ].includes(bid)
      ) {
        startBoardPermissionsListener(uid, bid)
      }
    }
  }, [
    uid,
    bid,
    loading,
    startBoardFoldersListener,
    startBoardPermissionsListener,
  ])
}

export default useLoadCurrentBoardFolders
