import LayoutDesktopPlaceholder from './components/LayoutDesktopPlaceholder'
import LayoutMobilePlaceholder from './components/LayoutMobilePlaceholder'

import styles from './Layout.module.css'

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className={styles.Container}>
    <header className={styles.Header}>
      <LayoutMobilePlaceholder />
    </header>
    <aside className={styles.DesktopPlaceholder}>
      <LayoutDesktopPlaceholder />
    </aside>
    <article className={styles.Content}>{children}</article>
  </div>
)

export default Layout
