import NodeMargin from './NodeMargin'
import urlHelper from '../../../../../helpers/url'
import config from '../../config'

import styles from './NodeLink.module.css'

interface LinkProps {
  text: string
  title: string
}

interface UrlArg {
  url: string
}

interface TitleArgs {
  url: string
  text: string
  title: string
  textHasOneLine: boolean
}

interface WithoutTitleArgs {
  url: string
  text: string
}

const Favicon = ({ url }: UrlArg) => {
  const faviconUrl = urlHelper.getFaviconUrl(url)
  return (
    <img
      className={styles.Favicon}
      src={faviconUrl}
      alt="Favicon"
      loading="lazy"
    />
  )
}

const WithTitle = ({ title, url, text, textHasOneLine }: TitleArgs) => {
  const classes = [styles.Wrapper, styles.WithTitle]
  if (textHasOneLine) classes.push(styles.TitleWithTwoLines)
  if (url) classes.push(styles.TitleWithText)

  return (
    <div className={classes.join(' ')}>
      <div className={styles.FirstColumn}>
        <Favicon url={url} />
      </div>
      <div className={styles.Content}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Text}>{text}</div>
      </div>
    </div>
  )
}

const WithoutTitle = ({ url, text }: WithoutTitleArgs) => (
  <div className={styles.Wrapper}>
    <div className={styles.FirstColumn}>
      <Favicon url={url} />
    </div>
    <div className={styles.Title}>{text}</div>
  </div>
)

/** @deprecated Prepare migration instead for "Node" component in "boards-web-ui" */
const NodeLink = ({ title, text }: LinkProps) => {
  const { url } = urlHelper.parseUrl(text)
  const textHasOneLine =
    (title && title.length) > config.titleBreakLineThreshold

  let node = (
    <WithTitle
      title={title}
      url={url}
      text={text}
      textHasOneLine={textHasOneLine}
    />
  )
  if (!title) {
    node = <WithoutTitle url={url} text={text} />
  }

  return (
    <div className={styles.Root}>
      <NodeMargin>{node}</NodeMargin>
    </div>
  )
}

export default NodeLink
