import { FieldTypes } from 'boards-web-ui'
import useBoard from '@hooks/useBoard'
import { useAuth } from '@hooks/useAuth'
import { Timestamp } from 'firebase/firestore'
import { EventCategories } from '@features/analytics/models/logEvents'

import { useLogEvents } from './useLogEvents'

const getEventFieldNameByFieldType = (fieldType: string) => {
  switch (fieldType) {
    case FieldTypes.STRING: {
      return 'name'
    }
    case FieldTypes.TEXT: {
      return 'text'
    }
    case FieldTypes.CHOICE: {
      return 'multiple_choice'
    }
    default: {
      return fieldType
    }
  }
}

type Params = {
  [key: string]: string | number | Timestamp | undefined | Date | boolean
}

const useCheckoutAnalytics = () => {
  const { user } = useAuth()
  const { boardId } = useBoard()

  const { trigger } = useLogEvents()

  const sendEvent = (eventName: string, params: Params = {}) => {
    const superParams = {
      boardId,
      sender_id: user?.uid || '',
    }

    trigger(eventName, {
      ...superParams,
      ...params,
    })
  }

  const appCheckoutEditorView = (editMode?: boolean) => {
    sendEvent('app_checkout_editor_view', {
      state: editMode ? 'edit' : 'new',
    })
  }

  const appCheckoutAdditionalFieldsView = () => {
    sendEvent(EventCategories.APP_CHECKOUT_ADDITIONAL_FIELDS_VIEW)
  }

  const appCheckoutSettingsView = () => {
    sendEvent(EventCategories.APP_CHECKOUT_SETTINGS_VIEW)
  }

  const appCheckoutConnectPaypalClick = (isPaypalConnected?: boolean) => {
    sendEvent(EventCategories.APP_CONNECT_PAYPAL_CLICK, {
      paypal_connected: isPaypalConnected,
    })
  }

  const appCheckoutDisconnectPaypalEvent = () =>
    sendEvent(EventCategories.APP_CHECKOUT_DISCONNECT)

  const appPaypalIntegrationSuccess = () => {
    sendEvent(EventCategories.APP_PAYPAL_INTEGRATION_SUCCESS)
  }

  const appCheckoutQuestionAddEvent = () =>
    trigger(EventCategories.APP_FORM_QUESTION_ADD_CLICK)

  const appCheckoutQuestionAddedEvent = (questionType: string) =>
    trigger(EventCategories.APP_FORM_QUESTION_ADDED, {
      type: getEventFieldNameByFieldType(questionType),
    })

  const appCheckoutQuestionDeletedEvent = (questionType: string) =>
    trigger(EventCategories.APP_FORM_QUESTION_DELETED, {
      type: getEventFieldNameByFieldType(questionType),
    })

  const appCheckoutMultiChoiceToggleEvent = (isMultiple: boolean) =>
    trigger(EventCategories.APP_FORM_QUESTION_MULTIPLE_CHOICE_TOGGLE_SWITCH, {
      type: isMultiple ? 'multi_select' : 'single_select',
    })

  const appCheckoutMultiChoiceAddEvent = (optionId: number | undefined) =>
    trigger(EventCategories.APP_FORM_QUESTION_MULTIPLE_CHOICE_OPTION_ADDED, {
      option_id: optionId,
    })

  const appCheckoutMultiChoiceDeleteEvent = (optionId: number | undefined) =>
    trigger(EventCategories.APP_FORM_QUESTION_MULTIPLE_CHOICE_OPTION_DELETED, {
      option_id: optionId,
    })

  const appCheckoutCreate = ({
    isPaymentProviderConnected,
    googleSheetsConnected,
    googleEmailConnected,
    productsCount,
    fieldsCount,
    currency,
  }: {
    isPaymentProviderConnected: boolean
    googleSheetsConnected: boolean
    googleEmailConnected: boolean
    productsCount: number
    fieldsCount: number
    currency: string
  }) => {
    sendEvent(EventCategories.APP_CHECKOUT_CREATE, {
      payment_provider: (!!isPaymentProviderConnected).toString(),
      google_sheets: (!!googleSheetsConnected).toString(),
      responses_email: (!!googleEmailConnected).toString(),
      products_count: productsCount,
      fields_count: fieldsCount,
      currency,
    })
  }

  const appCheckoutEdit = ({
    isPaymentProviderConnected,
    googleSheetsConnected,
    googleEmailConnected,
    productsCount,
    fieldsCount,
    currency,
  }: {
    isPaymentProviderConnected?: boolean
    googleSheetsConnected?: boolean
    googleEmailConnected?: boolean
    productsCount: number
    fieldsCount: number
    currency: string
  }) => {
    sendEvent(EventCategories.APP_CHECKOUT_EDIT, {
      payment_provider: (!!isPaymentProviderConnected).toString(),
      google_sheets: (!!googleSheetsConnected).toString(),
      responses_email: (!!googleEmailConnected).toString(),
      products_count: productsCount,
      fields_count: fieldsCount,
      currency,
    })
  }

  return {
    sendEvent,
    appCheckoutEditorView,
    appCheckoutAdditionalFieldsView,
    appCheckoutSettingsView,
    appCheckoutConnectPaypalClick,
    appCheckoutDisconnectPaypalEvent,
    appPaypalIntegrationSuccess,
    appCheckoutCreate,
    appCheckoutEdit,
    appCheckoutQuestionAddEvent,
    appCheckoutQuestionAddedEvent,
    appCheckoutQuestionDeletedEvent,
    appCheckoutMultiChoiceToggleEvent,
    appCheckoutMultiChoiceAddEvent,
    appCheckoutMultiChoiceDeleteEvent,
  } as const
}

export default useCheckoutAnalytics
