import { useCallback } from 'react'

import config from './config'
import { authorize, unAuthorize } from './api'

export const useChromeExtensionMessageProvider = () => {
  const onAuthorized = useCallback(
    (accessTokenKey: string, url?: string): void => {
      if (!accessTokenKey) {
        throw new Error(
          `Token is Invalid - ${accessTokenKey}, should be not empty string`,
        )
      }
      authorize(config.id, accessTokenKey, url)
    },
    [],
  )

  const onUnAuthorized = useCallback((): void => {
    unAuthorize(config.id)
  }, [])

  return {
    onAuthorized,
    onUnAuthorized,
  } as const
}
