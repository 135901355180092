import { FC, ReactElement } from 'react'

import styles from './SubscriptionRow.module.css'

interface Props {
  planInfo?: ReactElement | string
  button?: ReactElement
}
const SubscriptionRow: FC<Props> = ({ planInfo, button }) => {
  return (
    <>
      <div className={styles.Root}>
        {planInfo}
        {button}
      </div>

      <div className={styles.RowBorder} />
    </>
  )
}

export default SubscriptionRow
