import React, { CSSProperties, FC, useRef } from 'react'
import { useDetectOutsideClick } from '@hooks/useDetectOutsideClick'
import { ShareOption } from '@features/share/models/ShareOption'
import clsx from 'clsx'
import { ArrowDownIcon, RightIcon } from 'boards-web-ui'
import styles from './Select.module.css'

interface Props {
  value: string
  options: ShareOption[]
  disabled?: boolean
  onSelect?: (value: string) => void
  sx?: CSSProperties
}

const Select: FC<Props> = ({
  options,
  value,
  disabled = false,
  onSelect,
  sx,
}) => {
  const elRef = useRef(null)
  const [open, setOpen] = useDetectOutsideClick(elRef, false)

  const handleOnSelect = (
    e: React.MouseEvent<HTMLDivElement>,
    updatedValue: string,
  ) => {
    e.stopPropagation()
    if (!disabled) {
      if (onSelect) {
        onSelect(updatedValue)
      }
      setOpen(false)
    }
  }

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true)
    }
  }

  const label = options.find((o) => o.value === value)?.label

  return (
    <div ref={elRef} className={clsx(styles.Root, disabled && styles.Disabled)}>
      <button className={styles.Value} onClick={handleOpen} style={sx}>
        {label}

        <ArrowDownIcon className={styles.ArrowDownIcon} />
      </button>

      {open && (
        <div className={styles.Options}>
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.Option}
              onClick={(e) => {
                if (option.onClick) {
                  option.onClick()
                  return
                }

                handleOnSelect(e, option.value)
              }}
            >
              <span className={styles.OptionText}>
                {option.label}
                {option.suffixIcon}
              </span>

              {value === option.value && (
                <RightIcon className={styles.RightIcon} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Select
