import { useTranslation } from 'react-i18next'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { IconWrapper } from '@ui/components/IconWrapper'
import { PremiumIndicationIcon } from 'boards-web-ui'
import React from 'react'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { ShareOption } from '../models/ShareOption'
import { Role } from '../../../models'

const { OWN, WRITE, SHARE, READ } = Role

const useGetOptions = (): ShareOption[] => {
  const { t } = useTranslation()
  const { isFreePlanUser } = useReverseTrialContext()
  const { openPaywall } = usePaywall()

  return [
    {
      label: t('member_list_filter'),
      value: 'all',
      roles: [OWN, WRITE, SHARE, READ],
    },
    {
      label: t('co_editing_edit'),
      suffixIcon: isFreePlanUser ? (
        <IconWrapper>
          <PremiumIndicationIcon />
        </IconWrapper>
      ) : null,
      onClick: isFreePlanUser
        ? () => {
            openPaywall({ source: PaywallSourceEnum.ADD_CO_EDITORS })
          }
        : undefined,
      value: 'write',
      roles: [OWN],
      disabledMemberItem: isFreePlanUser,
    },
    {
      label: t('co_editing_view&share'),
      value: 'share',
      roles: [OWN, WRITE, SHARE],
    },
    {
      label: t('co_editing_view_only'),
      value: 'read',
      roles: [OWN, WRITE, SHARE],
    },
  ]
}

const useShareOptions = () => {
  const allOptionFilter = ({ value }: ShareOption) => value !== 'all'
  const options = useGetOptions()

  const shareOptionsByRole = (role: Role = READ) =>
    options.filter(allOptionFilter).filter(({ roles }) => roles.includes(role))

  const shareOptionsWithAll = options.map(({ label, value, roles }) => ({
    label,
    value,
    roles,
  }))

  const shareOptions = options.filter(allOptionFilter)

  return { shareOptions, shareOptionsWithAll, shareOptionsByRole } as const
}

export default useShareOptions
