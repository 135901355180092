import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@hooks/useAuth'
import { firebaseEventsAsyncActions } from '../../../../actions'
import * as ROUTES from '../../../../constants/routes'
import useIsOnline from '../../../../hooks/useIsOnline'

export const useResetPassword = (setError) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)
  const isOnline = useIsOnline()
  const { resetPassword } = useAuth()

  useEffect(() => {
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_screen_view', {
        screen: 'reset_password',
      }),
    )
  }, [dispatch])

  const onError = (errorField, message) => {
    setError(errorField, {
      type: 'server',
      message,
    })
  }

  const onSignUp = async (data) => {
    setSubmitting(true)
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_button_click', {
        action: 'reset_password',
      }),
    )

    try {
      await resetPassword(data)
      dispatch(firebaseEventsAsyncActions.logEvent('app_reset_password'))
      navigate(ROUTES.SIGN_IN)
    } catch ({ code }) {
      if (!isOnline) {
        setError('password', t('optimistic_ui_failed'))
        return
      }

      onError('email', t([`errors.${code}`, 'errors.default']))
      dispatch(
        firebaseEventsAsyncActions.logEvent('app_error', {
          type: 'reset_password_failed',
          code,
        }),
      )
    } finally {
      setSubmitting(false)
    }
  }

  return {
    submitting,
    onError,
    onSignUp,
  }
}
