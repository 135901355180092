import React, { FC, HTMLAttributes, MutableRefObject } from 'react'
import {
  CheckoutFormProduct,
  NewProduct,
  NewProductChange,
} from 'boards-web-ui'

import {
  DEFAULT_MAX_QUANTITY,
  DEFAULT_MIN_QUANTITY,
  DEFAULT_QUANTITY,
} from '@features/checkout/constants'

import clsx from 'clsx'
import styles from './CheckoutProduct.module.css'

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  titleRef?: (
    el: HTMLDivElement,
  ) => void | MutableRefObject<HTMLDivElement | null>
  descriptionRef?: (
    el: HTMLDivElement,
  ) => void | MutableRefObject<HTMLDivElement | null>
  product: CheckoutFormProduct
  minPrice?: string
  currency: string
  priceLabel: string
  quantityLabel: string
  defaultTitle?: string
  titlePlaceholder?: string
  descriptionPlaceholder?: string
  locale?: string
  autoFocus?: boolean
  onChange?: (product: CheckoutFormProduct) => void
  onDelete?: (product: CheckoutFormProduct) => void
}
const CheckoutProduct: FC<Props> = ({
  product,
  currency,
  priceLabel,
  quantityLabel,
  onDelete,
  onChange,
  locale,
  minPrice = '1',
  defaultTitle = 'New product',
  titlePlaceholder = 'Product Name',
  descriptionPlaceholder = 'Description',
  autoFocus,
  className,
  titleRef,
  descriptionRef,
  ...rest
}) => {
  const onChangeHandler = (changedProduct: Partial<NewProductChange>) => {
    if (!onChange) {
      return
    }

    const mapChangeProduct: CheckoutFormProduct = {
      ...product,
      ...changedProduct,
    }

    if (Object.prototype.hasOwnProperty.call(changedProduct, 'title')) {
      mapChangeProduct.label = changedProduct.title || product.label
    }

    if (Object.prototype.hasOwnProperty.call(changedProduct, 'price')) {
      mapChangeProduct.price =
        typeof changedProduct.price === 'undefined' ? '' : changedProduct.price
    }

    if (Object.prototype.hasOwnProperty.call(changedProduct, 'quantity')) {
      mapChangeProduct.maxQuantity =
        Number(changedProduct.quantity) || product.maxQuantity
    }

    onChange(mapChangeProduct)
  }

  return (
    <NewProduct
      titleRef={titleRef}
      descriptionRef={descriptionRef}
      autoFocus={autoFocus}
      locale={locale}
      title={product.label || undefined}
      defaultTitle={defaultTitle}
      description={product.description}
      price={product.price?.toString()}
      minPrice={minPrice}
      quantity={product.maxQuantity?.toString() || DEFAULT_QUANTITY}
      minQuantity={DEFAULT_MIN_QUANTITY}
      maxQuantity={DEFAULT_MAX_QUANTITY}
      currency={currency}
      priceLabel={priceLabel}
      quantityLabel={quantityLabel}
      titlePlaceholder={titlePlaceholder}
      descriptionPlaceholder={descriptionPlaceholder}
      className={clsx(styles.ProductRoot, className)}
      style={{ cursor: 'default' }}
      onChange={onChangeHandler}
      onDelete={onDelete ? () => onDelete(product) : undefined}
      {...rest}
    />
  )
}

export default React.memo(CheckoutProduct)
