import React, { FC } from 'react'

import { useEditForms } from '../hooks'
import { FORMS_ACTION_IDS } from '../models'

import FormSettings from './FormSettings'
import FormContent from './FormContent'

type Props = {
  onAfterAddAdditionalField?: () => void
}
const EditFormBody: FC<Props> = ({ onAfterAddAdditionalField }) => {
  const { action } = useEditForms()

  return (
    <>
      {action?.id === FORMS_ACTION_IDS.FIELDS ? (
        <FormContent onAfterAddAdditionalField={onAfterAddAdditionalField} />
      ) : null}
      {action?.id === FORMS_ACTION_IDS.SETTINGS ? <FormSettings /> : null}
    </>
  )
}

export default React.memo(EditFormBody)
