import {
  ProfilingQuestionEventNames,
  ProfilingQuestionKeysEnum,
} from '@features/profiling/models'
import useProfilingEvents from '@features/analytics/useProfilingEvents'
import {
  findIsRankQuestion,
  getTeamSizeAndTimeInBusinessByRank,
} from '@features/profiling/util/profilingHelper'

const sendEventsBasedOnRank = ({
  rankQuestion,
  rankAnswer,
  sendEvent,
}: {
  rankQuestion: string
  rankAnswer: string
  sendEvent: (eventName: string, eventValue: string) => void
}) => {
  const { teamSize, timeInBusiness } = getTeamSizeAndTimeInBusinessByRank({
    rankQuestion,
    rankAnswer,
  })
  sendEvent(
    ProfilingQuestionEventNames[
      ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE
    ],
    teamSize,
  )
  sendEvent(
    ProfilingQuestionEventNames[ProfilingQuestionKeysEnum.TIME_IN_BUSINESS],
    timeInBusiness,
  )
}

const useHandleAnswerSubmit = () => {
  const { answerQuestion } = useProfilingEvents()
  return (question: string, answer: string) => {
    if (findIsRankQuestion(question)) {
      sendEventsBasedOnRank({
        rankQuestion: question,
        rankAnswer: answer,
        sendEvent: answerQuestion,
      })
    } else {
      answerQuestion(
        ProfilingQuestionEventNames[question as ProfilingQuestionKeysEnum],
        answer,
      )
    }
  }
}

export default useHandleAnswerSubmit
