import React from 'react'
import { useTranslation } from 'react-i18next'
import VideoInput from './VideoInput'
import VideoPreview from './VideoPreview'
import InputModalTitle from '../../../../elements/InputModalTitle'

interface Props {
  title: string
  text: string
  hasError: boolean
  onTitleChange: ((title: string) => void) | undefined
  onTextChange: (title: string) => void
}
const FormEditVideo = React.forwardRef<HTMLInputElement, Props>(
  ({ title, text, hasError, onTitleChange, onTextChange }, ref) => {
    const { t } = useTranslation()

    return (
      <>
        {onTitleChange && (
          <InputModalTitle
            value={title}
            placeholder={t('add_an_update_title_optional')}
            onChange={onTitleChange}
          />
        )}
        <VideoInput
          ref={ref}
          value={text}
          placeholder={t('hints_paste_YouTube_or_Vimeo_link')}
          onChange={onTextChange}
          readOnly={onTextChange === undefined}
          hasError={hasError}
        />
        <VideoPreview title={title} url={text} />
      </>
    )
  },
)

export default FormEditVideo
