import { FC, ReactElement } from 'react'
import styles from './Checkbox.module.css'

interface CheckboxProps {
  checked: boolean
  onChange: () => void
  children: ReactElement
  disabled: boolean
}
const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  children,
  disabled = false,
}) => (
  <label className={styles.Root}>
    <span className={styles.Switch}>
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span className={`${styles.Slider} ${styles.Round}`}></span>
    </span>
    {children}
  </label>
)

export default Checkbox
