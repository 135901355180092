import { useParams } from 'react-router-dom'

import { NodeType, TNodeCheckoutForm, useDialog } from 'boards-web-ui'

import { Checkout } from '@features/checkout'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'

import usePaywall from '@hooks/usePaywall'
import useBoard from '@hooks/useBoard'
import { useAuth } from '@hooks/useAuth'

import { IUseAddNode } from '../models/IUseAddNode'

const useAddCheckout: IUseAddNode = (onCreate) => {
  const { open, close } = useDialog()
  const { openPaywall } = usePaywall()

  const { isLimitedNode, onLimitedKeyClick } = usePagePremiumLimitationMode()
  const { pageId } = useParams()
  const {
    hideCheckoutHint,
    profile: {
      plan: {
        features: { advancedForms },
      },
    },
  } = useProfileInformation()

  const { boardId, isOwnBoard } = useBoard()
  const { user } = useAuth()

  const checkoutNode = {
    type: NodeType.CHECKOUT,
    form: {
      action: null,
      type: NodeType.CHECKOUT,
      email: user?.email,
    },
  }

  if (isLimitedNode(NodeType.CHECKOUT)) {
    return () => onLimitedKeyClick(PaywallSourceEnum.CHECKOUT_CREATE)
  }

  const onPaywall = () => {
    openPaywall({ source: PaywallSourceEnum.FORM_POST_SUBMISSION })
  }

  const onSave = (data: INodeProps) => {
    onCreate(data)
    hideCheckoutHint()
    close()
  }

  return () => {
    open(
      <Checkout
        isOwn={isOwnBoard}
        pageId={pageId || ''}
        boardId={boardId}
        advancedForms={advancedForms}
        initialNode={checkoutNode as TNodeCheckoutForm}
        onPaywall={onPaywall}
        onClose={close}
        onSave={onSave}
        isNew
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }
}

export default useAddCheckout
