import { FC } from 'react'
import { ButtonIcon, CloseIcon } from 'boards-web-ui'
import { ReactComponent as MailIcon } from '../../../../icons/mail-icon.svg'
import styles from './MobilePopupHeader.module.css'

export interface MobilePopupHeaderProps {
  setOpen: (value: boolean) => void
}

const MobilePopupHeader: FC<MobilePopupHeaderProps> = ({
  setOpen,
}: MobilePopupHeaderProps) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Close}>
        <ButtonIcon size={20} onClick={() => setOpen(false)}>
          <CloseIcon />
        </ButtonIcon>
      </div>
      <div className={styles.Circle}>
        <MailIcon />
      </div>
    </div>
  )
}

export default MobilePopupHeader
