import { Trans } from 'react-i18next'
import styles from './PagePreviewHead.module.css'

const PagePreviewHead = () => {
  return (
    <div className={styles.Root}>
      <Trans i18nKey="page_builder_tab_preview" />
    </div>
  )
}

export default PagePreviewHead
