import { FC } from 'react'
import { Icon, SpinnerIcon } from 'boards-web-ui'

import SubscriptionRow from '@features/account/components/SubscriptionRow'

const LoadingPlan: FC = () => {
  return (
    <SubscriptionRow
      planInfo={
        <Icon size="53px" color="#bcbcbc">
          <SpinnerIcon />
        </Icon>
      }
    />
  )
}

export default LoadingPlan
