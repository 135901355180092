import { useState, useEffect, MutableRefObject } from 'react'

const useOnScroll = ({ current }: MutableRefObject<HTMLElement | null>) => {
  const [hasScroll, setHasScroll] = useState(false)
  const [isOnTop, setIsOnTop] = useState(true)
  const [isOnEnd, setIsOnEnd] = useState(false)

  useEffect(() => {
    if (!current) {
      return undefined
    }

    const onScroll = () => {
      const {
        scrollHeight = 0,
        scrollTop = 0,
        offsetHeight = 0,
        clientHeight = 0,
      } = current

      setHasScroll(scrollHeight > clientHeight)
      setIsOnTop(scrollTop - 30 < 0)
      setIsOnEnd(scrollTop + offsetHeight + 20 > scrollHeight)
    }

    onScroll()

    const node = current
    node?.addEventListener('scroll', onScroll)
    return () => {
      node?.removeEventListener('scroll', onScroll)
    }
  }, [current])

  return {
    hasScroll,
    isOnTop,
    isOnEnd,
  }
}

export default useOnScroll
