import { ElementType, FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GoogleSheetsIcon,
  Icon,
  If,
  OutsideLinkIcon,
  Menu,
  MenuItem,
  DropDownMenu,
  DropDownPosition,
  UnLinkIcon,
  SpinnerIcon,
} from 'boards-web-ui'

import styles from './GoogleSheetConnected.module.css'

type Props = {
  integrationStatusStatePending: boolean
  link?: string
  onUnLink?: () => void
}
const GoogleSheetConnected: FC<Props> = ({
  integrationStatusStatePending,
  link,
  onUnLink,
}) => {
  const { t } = useTranslation()

  const hasValidLink: boolean =
    typeof link === 'string' && !!link.length && link !== 'default'

  const LinkTag: ElementType = hasValidLink ? 'a' : 'span'

  return (
    <div className={styles.ConnectedGoogleSheet}>
      <div className={styles.ConnectedGoogleSheetStatus}>
        <div className={styles.ConnectedGoogleSheetStatusState}>
          <div className={styles.IconWrapper}>
            <Icon size="23px">
              <GoogleSheetsIcon />
            </Icon>
          </div>

          <div>
            <p className={styles.ButtonTitle}>{t('forms_google_sheets')}</p>
            <p className={styles.ButtonSubTitle}>{t('label_connected')}</p>
          </div>
        </div>
        <If state={false}>
          {/* // TODO add link check when UnLink Api will be ready */}
          <div className={styles.Menu}>
            <DropDownMenu position={DropDownPosition.right}>
              <Menu>
                <MenuItem
                  as="button"
                  type="button"
                  className={styles.UnLinkButton}
                  onClick={onUnLink}
                >
                  <Icon className={styles.UnLinkIcon}>
                    <UnLinkIcon />
                  </Icon>
                  Unlink sheet
                </MenuItem>
              </Menu>
            </DropDownMenu>
          </div>
        </If>
      </div>

      <div className={styles.spreadSheetLinkSection}>
        <span className={styles.Label}>{t('Linked sheet')}</span>

        <LinkTag href={link} className={styles.spreadSheetLink} target="_blank">
          <If state={integrationStatusStatePending}>
            <Icon color="#bcbcbc" size="25px">
              <SpinnerIcon />
            </Icon>
          </If>

          <If state={!integrationStatusStatePending}>
            <If state={hasValidLink}>
              {link}
              <Icon color="#222222" className={styles.LinkIcon}>
                <OutsideLinkIcon />
              </Icon>
            </If>
            <If state={!hasValidLink}>
              {t('A Google Sheets link will appear here after saving.')}
            </If>
          </If>
        </LinkTag>
      </div>
    </div>
  )
}

export default memo(GoogleSheetConnected)
