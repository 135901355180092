import React from 'react'

const styles = {
  padding: 'var(--node-padding)',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}

const NodeMargin: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <div style={styles}>{children}</div>

export default NodeMargin
