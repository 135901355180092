import { useEffect, useMemo, useState } from 'react'
import useLogUsageEvents from '@features/analytics/useLogUsageEvents'
import useRouterParams from '@hooks/useRouterParams'
import useFolder from '@hooks/useFolder'

import { Folder, Node, NodeType } from '../../../models'

import usePageNodeTransformer, {
  NodeWithSource,
} from '../../nodes/hooks/usePageNodeTransformer'

interface Props {
  node?: NodeWithSource
  prev?: () => void
  next?: () => void
}

const ALLOWED_TYPES_IN_PREVIEW = [
  NodeType.LINK,
  NodeType.IMAGE,
  NodeType.PDF,
  NodeType.TEXT,
  NodeType.PARAGRAPH,
  NodeType.HEADLINE,
  NodeType.VIDEO,
  NodeType.VIDEO_STREAMING,
  NodeType.BUTTON,
  NodeType.PAGE,
  NodeType.AUDIO,
]

const usePreviewNode = (
  specificNid: string,
  folder: Folder,
  sharedBoardId?: string,
  sharedFolderId?: string,
): Props => {
  const [nid, setNid] = useState(specificNid)
  let { boardId } = useRouterParams()
  const { folder: srcFolder } = useFolder(boardId, folder?.id)
  if (sharedBoardId && sharedFolderId) {
    boardId = sharedBoardId
  }

  const { viewNode } = useLogUsageEvents()
  const transformPageNode = usePageNodeTransformer()

  const nodes =
    (srcFolder || folder)?.content?.filter(({ type }) =>
      ALLOWED_TYPES_IN_PREVIEW.includes(type),
    ) || []
  const nodeSrc = nodes.find((n) => n.id === nid)

  const node = useMemo(
    () => (nodeSrc ? transformPageNode(nodeSrc as Node) : undefined),
    [nodeSrc, transformPageNode],
  )

  const nodeIndex = nodes.findIndex((n) => n.id === nid)
  const hasPrev = nodeIndex > 0
  const hasNext = nodes.length - 1 > nodeIndex

  useEffect(() => {
    setNid(specificNid)
  }, [specificNid])

  const prev = () => {
    if (hasPrev) {
      setNid(nodes[nodeIndex - 1].id)

      if (node?.source.type !== NodeType.FOLDER) {
        viewNode(nodes[nodeIndex - 1].id)
      }
    }
  }

  const next = () => {
    if (hasNext) {
      setNid(nodes[nodeIndex + 1].id)

      if (node?.source.type !== NodeType.FOLDER) {
        viewNode(nodes[nodeIndex + 1].id)
      }
    }
  }

  return {
    node,
    prev: hasPrev ? prev : undefined,
    next: hasNext ? next : undefined,
  } as const
}

export default usePreviewNode
