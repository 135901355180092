import { createContext, createRef } from 'react'

export type ContainerReference = {
  contentRef: React.RefObject<HTMLDivElement>
  getPosition: () => { top: number; left: number }
}

const defaultState = {
  contentRef: createRef<HTMLDivElement>(),
  getPosition: () => ({ top: 0, left: 0 }),
}
export default createContext<ContainerReference>(defaultState)
