import { QueryDocumentSnapshot } from 'firebase/firestore'
import { Permission, Role } from '../../../app/models'

export const transformPermission = (doc: QueryDocumentSnapshot) => {
  const data = doc.data()

  return {
    owner: data.owner,
    sharer: data?.sharer,
    recipient: data.recipient,
    permission: parsePermission(data.permission),
  } as Permission
}

const parsePermission = (permission: string) => {
  const roles = [
    Role.READ as string,
    Role.SHARE as string,
    Role.WRITE as string,
  ]

  if (roles.includes(permission)) {
    return permission as Role
  }

  return Role.READ
}
