import { useSelector } from 'react-redux'
import {
  BlackCheckedIcon,
  HideIcon,
  Icon,
  Toast,
  ViewOnlyIcon,
} from 'boards-web-ui'

import React from 'react'
import { AppState } from '../app/models/appState'

const ToastMessageContainer = () => {
  const { msg, global, showIcon, customIcon, onClose } = useSelector(
    (state: AppState) => state.app.toast,
  )

  if (!msg) {
    return null
  }

  const getStartIcon = () => {
    if (showIcon && !customIcon) {
      return <BlackCheckedIcon />
    }

    if (customIcon === 'HideIcon') {
      return (
        <Icon size="20px">
          <HideIcon />
        </Icon>
      )
    }

    if (customIcon === 'ViewOnly') {
      return (
        <Icon size="20px">
          <ViewOnlyIcon />
        </Icon>
      )
    }

    return customIcon
  }

  return (
    <Toast
      isLocal={!global}
      startIcon={getStartIcon()}
      message={msg}
      onClose={onClose}
    />
  )
}

export default ToastMessageContainer
