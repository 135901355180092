import { useEffect, useRef } from 'react'
import { NODE_LINK_TYPES, useDialog } from 'boards-web-ui'
import { useSelector } from 'react-redux'

import { Node } from '../../../models'

import EditButton from '../components/EditButton'
import { IUseSaveNode } from '../models/IUseEditNode'
import { AppState } from '../../../models/appState'

const useEditButton: IUseSaveNode = (save) => {
  const { open } = useDialog()

  const { buttonType: type, buttonGeoData } = useSelector(
    (state: AppState) => state.app.pageEditor,
  )

  const typeRef = useRef<NODE_LINK_TYPES | null>(null)
  const geoDataRef = useRef<
    { countryCode: string; dialCode: string } | undefined | null
  >(null)

  useEffect(() => {
    typeRef.current = type
  }, [type])

  useEffect(() => {
    geoDataRef.current = buttonGeoData
  }, [buttonGeoData])

  const addTextNode = (node: Node) => {
    open(<EditButton node={node} onSave={(n) => save(node.id, n)} />, {
      fullScreen: true,
      blankDialog: true,
    })
  }

  return addTextNode
}

export default useEditButton
