import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  NodeType,
  PageNodeProfileVariants,
  TPageNodeProfile,
  useDialog,
} from 'boards-web-ui'

import useNodeDiscardChanges from '@features/nodeEditor/hooks/useNodeDiscardChanges'

import { isEqualObjects } from '../../../../utils/isEqual'

import CropImageDialog from '../components/CropImageDialog'

import {
  ImageUploadProvider,
  PageFormChangeHandler,
  PageProfileFormErrorsState,
  TPageProfileNodeProfile,
} from '../models'

const defaultState: TPageNodeProfile = {
  type: NodeType.PROFILE,
  profile: {
    image: '',
    name: '',
    title: '',
    company: '',
    variation: PageNodeProfileVariants.v1,
  },
}
type UsePageProfileFormProps = {
  initialState?: TPageNodeProfile
  imageUploadProvider: ImageUploadProvider
  onSubmitHandler: (pageProfile: TPageNodeProfile) => void
  onCancelHandler: () => void
}
const usePageProfileForm = ({
  initialState = defaultState,
  imageUploadProvider,
  onSubmitHandler,
  onCancelHandler,
}: UsePageProfileFormProps) => {
  const discardChanges = useNodeDiscardChanges()
  const { open } = useDialog()
  const [formState, setFormState] = useState<TPageNodeProfile>(initialState)
  const [pending, setPending] = useState<boolean>(false)
  const [errors, setErrors] = useState<PageProfileFormErrorsState>({})
  const isValid = useMemo(() => !Object.keys(errors).length, [errors])
  const [imagePreview, setImagePreview] = useState<string | null>(
    initialState.profile.image,
  )
  const [cropping, setCropping] = useState(false)

  const onValidate = useCallback(
    (
      name: keyof TPageProfileNodeProfile,
      value: string | File,
      required?: boolean,
    ) => {
      if (required && !value) {
        setErrors((prevState) => ({
          ...prevState,
          [name]: `Field ${name} is required`,
        }))
      }

      if (required && value) {
        setErrors((prevState) => {
          const newErrorState = { ...prevState }
          delete newErrorState[name as keyof TPageProfileNodeProfile]
          return newErrorState
        })
      }
    },
    [setErrors],
  )

  useEffect(() => {
    Object.entries(formState?.profile).forEach(([key, value]) => {
      onValidate(
        key as keyof TPageProfileNodeProfile,
        value,
        key === 'image' || key === 'name',
      )
    })
  }, [formState, onValidate])

  useEffect(() => {
    setFormState(initialState)
  }, [initialState])

  const updateFormField = (updatedValues: Partial<TPageNodeProfile>) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      profile: {
        ...prevFormState.profile,
        ...updatedValues,
      },
    }))
  }

  const updateFormImage = async (key: string, imageSrc: string) => {
    setPending(true)

    setImagePreview(imageSrc)
    updateFormField({ [key]: imageSrc })

    setPending(false)
  }

  const onChange: PageFormChangeHandler = (e) => {
    const { name, value } = e.target

    if (typeof value === 'string') {
      updateFormField({ [name]: value })
    }
  }

  const cropImage = async (
    imageBufferPromise: Promise<Buffer>,
    imageType: string,
  ) => {
    setPending(true)

    try {
      const croppedBuffer = await imageBufferPromise

      const imageFile = new File([croppedBuffer], 'image.jpg', {
        type: imageType,
      })
      const image = await imageUploadProvider(imageFile)

      updateFormImage('image', image)
    } catch (error) {
      setPending(false)
    }
  }

  const onSubmit = async () => {
    // TODO: upload only on submit

    // if (cropping) {
    //   profileImageRepositionDone(wasRepositioned)
    //   await cropImage()
    //   return
    // }

    if (pending) {
      return
    }

    if (!isValid) {
      return
    }

    onSubmitHandler(formState)
  }

  const onCancel = () => {
    const hasUnsavedChanges = !isEqualObjects(initialState, formState)

    if (hasUnsavedChanges) {
      return discardChanges(onCancelHandler)
    }

    return onCancelHandler()
  }

  const uploadImage = (image: File) => {
    setCropping(true)

    const imageURL = URL.createObjectURL(image)

    const loadImageDimensions = new Promise<{ width: number }>((resolve) => {
      const img = new Image()
      img.onload = () => {
        resolve({ width: img.width })

        URL.revokeObjectURL(imageURL)
      }

      img.src = imageURL
    })

    const readImageAsBase64 = new Promise<string>((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string

        resolve(base64String)
      }
      reader.readAsDataURL(image)
    })

    Promise.all([loadImageDimensions, readImageAsBase64]).then(
      ([{ width }, base64String]) => {
        open(
          <CropImageDialog
            imageSrc={base64String}
            imageWidth={width}
            imageType={image.type}
            variation={
              formState.profile.variation || PageNodeProfileVariants.v0
            }
            cropImage={cropImage}
          />,
          {
            fullScreen: true,
            blankDialog: true,
          },
        )
      },
    )
  }

  return {
    cropping,
    imagePreview,
    isValid,
    formState,
    pending,
    errors,
    onChange,
    onCancel,
    onSubmit,
    uploadImage,
  } as const
}

export default usePageProfileForm
