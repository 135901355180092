import { EventCategories } from '@features/analytics/models/logEvents'
import { useLogEvents } from '@features/analytics/useLogEvents'

export const useUpgradeDialogEvents = () => {
  const { trigger } = useLogEvents()

  const appPageContentBlockedViewEvent = () => {
    trigger(EventCategories.APP_PAGE_CONTENT_BLOCKED_VIEW)
  }

  const appPageContentBlockedClickedEvent = () => {
    trigger(EventCategories.APP_PAGE_CONTENT_BLOCKED_CLICKED)
  }

  return {
    appPageContentBlockedViewEvent,
    appPageContentBlockedClickedEvent,
  }
}
