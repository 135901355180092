import { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useAuth } from '@hooks/useAuth'
import parsePhoneNumber from 'libphonenumber-js'

import AccountPageTitle from '@features/account/components/AccountPageTitle'
import { InfoRow } from '@features/account/AccountPersonalInfo/InfoRow'
import { useProfileEvents } from '@features/analytics/useProfileEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useProfileNameDialog from '@features/profile/hooks/useProfileNameDialog'

import styles from './AccountPersonalInfo.module.css'

export const AccountPersonalInfo: FC = () => {
  const { profile } = useProfileInformation()
  const { user } = useAuth()
  const phoneNumber = user?.phoneNumber
    ? parsePhoneNumber(user.phoneNumber)?.formatInternational()
    : undefined

  const name = profile?.name

  const { addNameClick, editNameClick } = useProfileEvents()
  const openProfileNameDialog = useProfileNameDialog()

  const handleClick = useCallback(() => {
    if (name) {
      editNameClick()
    } else {
      addNameClick()
    }

    openProfileNameDialog()
  }, [addNameClick, editNameClick, name, openProfileNameDialog])

  return (
    <>
      <AccountPageTitle>
        <Trans i18nKey="personal_info_title" />
      </AccountPageTitle>

      <div className={styles.InfoContainer}>
        <InfoRow
          rowName={<Trans i18nKey="name_title" />}
          rowValue={name}
          buttonText={
            <span className={styles.ButtonText}>
              <Trans
                i18nKey={name ? 'edit_name_button' : 'dialog_add_name_title'}
              />
            </span>
          }
          onRowButtonClick={handleClick}
        />

        <InfoRow
          rowName={<Trans i18nKey="email_title" />}
          rowValue={user?.email}
        />

        {phoneNumber ? (
          <InfoRow
            rowName={<Trans i18nKey="phone_title" />}
            rowValue={phoneNumber}
          />
        ) : null}
      </div>
    </>
  )
}
