import useRouterParams from '../../../hooks/useRouterParams'
import { EventCategories } from './models/logEvents'
import { InsightsFilter } from '../insights/models/filter'
import { useLogEvents } from './useLogEvents'

enum TargetNames {
  FEATURE = 'insights',
  CONTENT = 'content',
  PREMIUM_BANNER = 'premium_banner',
}

export enum RelateTooltip {
  ACTIVE_MEMBERS = 'active_members',
  CONTENT_INTERACTIONS = 'content_interactions',
  DAILY_ACTIVE_MEMBERS = 'daily_active_members',
  DAILY_CONTENT = 'daily_active_content',
  TOP_CONTENT_TITLE = 'top_content_title',
  TOP_CONTENT_INTERACTIONS = 'top_content_interactions',
}

const filterToEvent = {
  [InsightsFilter.TODAY]: 'today',
  [InsightsFilter.YESTERDAY]: 'yesterday',
  [InsightsFilter.THIS_WEEK]: 'this_week',
  [InsightsFilter.LAST_WEEK]: 'last_week',
  [InsightsFilter.THIS_MONTH]: 'this_month',
  [InsightsFilter.LAST_MONTH]: 'last_month',
  [InsightsFilter.ALL_TIME]: 'all_time',
}

const useInsightsEvents = () => {
  const { boardId } = useRouterParams()
  const { trigger, buttonClick, screenView } = useLogEvents()

  const viewInsightsScreen = () =>
    screenView(TargetNames.FEATURE, { board_id: boardId })

  const insightsFilterChanged = (filter: InsightsFilter) =>
    trigger(EventCategories.APP_INSIGHTS_FILTER_CHANGED, {
      value: filterToEvent[filter],
      board_id: boardId,
    })

  const insightsMessageDisplayed = () =>
    trigger(EventCategories.APP_INSIGHTS_DATE_MESSAGE_DISPLAYED, {
      board_id: boardId,
    })

  const bannerClick = () =>
    buttonClick(TargetNames.PREMIUM_BANNER, {
      board_id: boardId,
      screen: TargetNames.FEATURE,
    })

  const chartHovered = () =>
    trigger(EventCategories.APP_TIMELINE_HOVER_SHOW, { board_id: boardId })

  const tooltipHovered = (relateTo: RelateTooltip) =>
    trigger(EventCategories.APP_HOVER_TOOLTIP_HOVERED, {
      screen: TargetNames.FEATURE,
      relate_to: relateTo,
      board_id: boardId,
    })

  return {
    viewInsightsScreen,
    insightsFilterChanged,
    insightsMessageDisplayed,
    bannerClick,
    chartHovered,
    tooltipHovered,
  } as const
}

export default useInsightsEvents
