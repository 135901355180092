import { useTranslation } from 'react-i18next'
import useBulkSelection from '@features/board/hooks/useBulkSelection'
import useBulk from '@hooks/bulk/useBulk'
import { useSharedFolder } from '@hooks/useSharedFolder'
import { AnalyticsNodeType } from '@features/analytics/useNodeEvents'
import { DestinationPickerSourceEventEnum } from '@features/analytics/useDestinationPickerEvents'
import { useCopyReceivedContent } from '@hooks/useCopyReceivedContent'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { NodeType } from '../../../models'

export const usePreviewModalSaveActions = (params: {
  nodeId?: string
  nodeType?: NodeType
}) => {
  const { startSelection, toggleNodeSelection } = useBulkSelection()
  const { resetBulkActions } = useBulk()
  const { t } = useTranslation()

  const { canCopy, onCopyClick } = useCopyReceivedContent({
    isSharedFolderMode: true,
    destinationPickerParams: {
      onClose: resetBulkActions,
    },
  })

  const {
    sharedFolderState: { boardId, folderId, shortcutId },
  } = useSharedFolder()

  const onSaveActionClick = () => {
    if (!params.nodeId || !params.nodeType) {
      return
    }

    onCopyClick({
      source: PaywallSourceEnum.COPY_CONTENT_SHARED_FOLDER,
      pickerProps: {
        contentType: AnalyticsNodeType[params.nodeType],
        performActionCopy: t('action_save_here'),
        headerCopy: t('label_save_to'),
        performActionSuccessMessage: t('toast_folder_saved'),
        eventsOverrides: {
          source: DestinationPickerSourceEventEnum.WebFolder,
        },
      },
      onBeforeOpenPicker: () => {
        if (!params.nodeId || !params.nodeType) {
          return
        }

        startSelection({ boardId, folderId, shortcutId })

        toggleNodeSelection(params.nodeId, params.nodeType)
      },
      isPreviewMode: true,
    })
  }

  return { onSaveActionClick, canCopy } as const
}
