import { ReactComponent as PlayBtnIcon } from '../../icons/PlayBtn.svg'
import Spinner from '../../../../components/Spinner'
import { Node } from '../../../../models'
import withVideo from '../../../../hoc/withVideo'

import styles from './NodeVideo.module.css'

interface Props {
  node: Node
  thumbnail: string | undefined
  isLoading: boolean
  onImageLoad: () => void
}

/** @deprecated Prepare migration instead for "Node" component in "boards-web-ui" */
const NodeVideo = ({ node, thumbnail, isLoading, onImageLoad }: Props) => {
  const ButtonClass = node.title ? styles.PlayBtnTop : styles.PlayBtnCenter

  return (
    <div className={styles.Root}>
      <Spinner spinning={isLoading}>
        <>
          <img
            className={styles.Image}
            src={thumbnail}
            alt={node.title}
            draggable="false"
            loading="lazy"
            onLoad={onImageLoad}
          />
          {!isLoading && node?.title && (
            <div className={styles.Title}>
              <span>{node.title}</span>
            </div>
          )}
          {!isLoading && (
            <div className={ButtonClass}>
              <PlayBtnIcon />
            </div>
          )}
        </>
      </Spinner>
    </div>
  )
}
export default withVideo(NodeVideo)
