import React, { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import useFormsEvents from '@features/analytics/useFormsEvents'
import {
  ChoiceActionType,
  ChoiceField,
  FieldTypes,
  FormField,
  RegularField,
} from 'boards-web-ui'
import styles from './EditFormAdditionalField.module.css'

type Props = {
  field: FormField
  onDelete: () => void
  onChange: (field: Partial<FormField>) => void
  highlightLinks?: boolean
  placeholder: string
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>
const EditFormAdditionalField: FC<Props> = ({
  field,
  highlightLinks = false,
  placeholder,
  onDelete,
  onChange,
  className,
  ...rest
}) => {
  const { t } = useTranslation()
  const {
    multiChoiceToggleEvent,
    multiChoiceAddEvent,
    multiChoiceDeleteEvent,
  } = useFormsEvents()
  const sharedProps = {
    checked: field.required,
    custom: field.type === 'text',
    highlightLinks,
    onCheckToggle: (required: boolean) => onChange({ required }),
    onDelete,
    className: clsx(styles.FormAdditionalField, className),
    style: { cursor: 'auto' },
    ...rest,
  }

  return field.type === FieldTypes.CHOICE ? (
    <ChoiceField
      uniqueId={field.id}
      question={field.label || ''}
      choices={field.choices}
      isMultiSelect={field.multiple}
      texts={{
        addOption: t('action_add_option'),
        singleSelect: t('label_single_select'),
        multiSelect: t('label_multi_select'),
        questionPlaceholder: placeholder,
        optionPlaceholder: t('label_option_x'),
      }}
      onQuestionChange={(value) => onChange({ label: value })}
      onChangeMultiSelect={(multiple) => {
        onChange({ multiple })
        multiChoiceToggleEvent(multiple)
      }}
      onChoicesChange={(choiceAction) => {
        onChange({ choices: choiceAction.data })
        if (choiceAction.type === ChoiceActionType.ADD) {
          multiChoiceAddEvent(choiceAction.placeInTheList)
        } else if (choiceAction.type === ChoiceActionType.REMOVE) {
          multiChoiceDeleteEvent(choiceAction.placeInTheList)
        }
      }}
      {...sharedProps}
    />
  ) : (
    <RegularField
      value={field.label || ''}
      placeholder={placeholder}
      onChange={(value) => onChange({ label: value })}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      }}
      {...sharedProps}
    />
  )
}

export default React.memo(EditFormAdditionalField)
