import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDialog } from 'boards-web-ui'

import useConfirmDialog from '@hooks/useConfirmDialog'
import { Node } from '../../../models'
import { SelectAction } from './useSourcePicker'

export const useContentSelection = (action?: SelectAction) => {
  const { close } = useDialog()
  const { t } = useTranslation()
  const [nodes, setNodes] = useState<Node[]>([])
  const confirmDialog = useConfirmDialog()

  const toggleNode = (node: Node) => {
    setNodes((prevNodes) =>
      prevNodes.map((n) => n.id).includes(node.id)
        ? prevNodes.filter((n) => n.id !== node.id)
        : [...prevNodes, node],
    )
  }

  const clearSelection = () => setNodes([])

  const insertSelection = (boardId: string, folderId: string) => {
    close()
    if (action) {
      action({ boardId, folderId, nodes })
    }
  }

  const showClearMsgIfHasSelectedNodes = (callback: () => void) => {
    if (nodes.length === 0) {
      callback()
      return
    }

    confirmDialog({
      title: t('clear_selected_dialog_title'),
      message: t('clear_selected_dialog_subtitle'),
      cancelText: t('action_cancel'),
      confirmText: t('action_clear'),
      onConfirm: () => {
        callback()
        clearSelection()
      },
    })
  }

  return {
    nodes,
    toggleNode,
    clearSelection: nodes.length > 0 ? clearSelection : undefined,
    insertSelection: nodes.length > 0 ? insertSelection : undefined,
    showClearMsgIfHasSelectedNodes,
  }
}
