import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  HTMLAttributes,
} from 'react'
import { useMergeRefs } from 'use-callback-ref'

import styles from './VerticalScrollShadow.module.css'

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    className?: string
    onTopCallBack?: (isOnTop: boolean) => void
  }
>

const VerticalScrollShadow = forwardRef<HTMLDivElement, Props>(
  ({ className, onTopCallBack, children, ...rest }, ref) => {
    const [hasScroll, setHasScroll] = useState(false)
    const [isOnTop, setIsOnTop] = useState(true)
    const [isOnEnd, setIsOnEnd] = useState(false)

    const innerRef = useRef<HTMLDivElement>(null)
    const localRef = useMergeRefs([ref, useRef<HTMLDivElement>(null)])

    const calculateProperties = useCallback(() => {
      if (!localRef.current) return

      const {
        scrollHeight = 0,
        scrollTop = 0,
        offsetHeight = 0,
        clientHeight = 0,
      } = localRef.current

      setHasScroll(scrollHeight > clientHeight)
      setIsOnTop(scrollTop - 30 < 0)
      setIsOnEnd(scrollTop + offsetHeight + 20 > scrollHeight)
    }, [localRef])

    useEffect(() => {
      if (onTopCallBack) {
        onTopCallBack(isOnTop)
      }
    }, [isOnTop, onTopCallBack])

    useLayoutEffect(() => {
      const observer = new ResizeObserver(calculateProperties)

      if (innerRef.current) {
        observer.observe(innerRef.current)
      }

      return () => observer.disconnect()
    }, [innerRef, calculateProperties])

    useLayoutEffect(() => {
      if (!localRef.current) {
        return undefined
      }

      calculateProperties()

      const element = localRef.current
      element?.addEventListener('scroll', calculateProperties)
      return () => {
        element?.removeEventListener('scroll', calculateProperties)
      }
    }, [localRef, calculateProperties])

    const classes = [styles.Root]
    if (hasScroll && !isOnTop) classes.push(styles.Start)
    if (hasScroll && !isOnEnd) classes.push(styles.End)

    return (
      <div className={classes.join(' ')} {...rest}>
        <div ref={localRef} className={styles.Scroll}>
          <div ref={innerRef} className={`${className || ''}`}>
            {children}
          </div>
        </div>
      </div>
    )
  },
)

export default VerticalScrollShadow
