import { pdfjs, Document, Page } from 'react-pdf'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.min.js'

const { PasswordResponses } = pdfjs

export { PasswordResponses, Document, Page }
