import { QueryDocumentSnapshot } from 'firebase/firestore'
import { getRootFolderId } from '@helpers/NodeIdGenerator'
import { BoardItem } from '../../../app/models'

export const transformOwnBoard = (doc: QueryDocumentSnapshot) => {
  const boardRef = doc.ref.parent.parent
  const { id } = doc
  const uid = boardRef ? boardRef.id : ''
  const boardId = `${uid}-${id}`
  const rootFolderId = getRootFolderId(boardId)

  return {
    id,
    uid,
    boardId,
    rootFolderId,
    isOwn: true,
  } as BoardItem
}
