import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import { ContentPickersEnum } from '@features/pickers'

export enum DestinationPickerSourceEventEnum {
  WebFolder = 'web_folder',
  OwnBoard = 'own_board',
  ReceivedBoard = 'received_board',
}

interface IPickerViewEventParams {
  type: ContentPickersEnum
  source: DestinationPickerSourceEventEnum
  contentType: string
}

export const useDestinationPickerEvents = () => {
  const { trigger } = useLogEvents()

  const destinationPickerViewEvent = ({
    type,
    source,
    contentType,
  }: IPickerViewEventParams) => {
    trigger(EventCategories.APP_DESTINATION_PICKER_VIEW, {
      type,
      source,
      content_type: contentType,
    })
  }

  return { destinationPickerViewEvent } as const
}
