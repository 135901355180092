import { memo, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import usePhoneVerificationEvents from '@features/analytics/usePhoneVerificationEvents'

import TextButton from './TextButton'

interface Props {
  iteration: number
  disabled?: boolean
  onSendCodeClick: () => void
  onCountdownEnds: () => void
}
const SendCodeButton = memo(
  ({ iteration, disabled, onSendCodeClick, onCountdownEnds }: Props) => {
    const [timeLeft, setTimeLeft] = useState(0)
    const { verificationCodeResendClickEvent } = usePhoneVerificationEvents()

    useEffect(() => {
      if (iteration > 0) {
        setTimeLeft(20)
      }
    }, [iteration])

    useEffect(() => {
      if (timeLeft === 0) return

      const interval = setInterval(() => {
        setTimeLeft((prevtimeLeft) => {
          if (prevtimeLeft === 1) {
            // setTimeout is used as a workaround for this issue: https://reactjs.org/link/setstate-in-render
            setTimeout(onCountdownEnds)
          }
          return prevtimeLeft - 1
        })
      }, 1000)

      // eslint-disable-next-line
      return () => clearInterval(interval)
    }, [timeLeft, onCountdownEnds])

    function formatNumberWithLeadingZero(number: number) {
      if (number < 10) {
        return `0${number}`
      }
      return String(number)
    }

    const handleEnterCodeClick = () => {
      onSendCodeClick()
      verificationCodeResendClickEvent(iteration)
    }

    if (disabled) return null

    if (timeLeft > 0) {
      return (
        <TextButton disabled>
          <Trans
            i18nKey="enter_code_resend"
            values={{
              timer: `00:${formatNumberWithLeadingZero(timeLeft)}`,
            }}
          />
        </TextButton>
      )
    }

    return (
      <TextButton onClick={handleEnterCodeClick} primary>
        <Trans i18nKey="enter_code_cta" />
      </TextButton>
    )
  },
)

export default SendCodeButton
