import { FC } from 'react'

import CloseButton from '@ui/components/CloseButton'

import styles from './InfoTipBanner.module.css'

interface Props {
  title: string
  description: string
  onDismiss: () => void
}
const InfoTipBanner: FC<Props> = ({ title, description, onDismiss }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.LightbulbTipIcon}>💡</div>
      <div className={styles.Content}>
        <span className={styles.Title}>{title}</span>
        <span className={styles.Description}>{description}</span>
      </div>

      <CloseButton className={styles.CloseBtn} onClose={onDismiss} />
    </div>
  )
}

export default InfoTipBanner
