import { FC, memo } from 'react'
import { If, ImagePicker } from 'boards-web-ui'

import styles from './NodeProfile.module.css'

type NodeProfileProps = {
  data?: {
    image: string
    name: string
    title?: string
    company?: string
  }
}
const NodeProfile: FC<NodeProfileProps> = ({ data }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Avatar}>
        <ImagePicker size="65px" imageSrc={data?.image} disabled />
      </div>
      <div className={styles.Properties}>
        <h3 className={styles.Name}>{data?.name}</h3>
        <If state={!!data?.title}>
          <p className={styles.Title}>{data?.title}</p>
        </If>
        <If state={!!data?.company}>
          <p className={styles.Company}>{data?.company}</p>
        </If>
      </div>
    </div>
  )
}

export default memo(NodeProfile)
