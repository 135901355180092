const previousBoard = (
  order: Array<string>,
  boardId: string,
): string | null => {
  try {
    const position = order.indexOf(boardId)
    if (position && position > 0) {
      return order[position - 1]
    }
    return order[1]
  } catch (error) {
    return null
  }
}

export default previousBoard
