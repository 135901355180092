import React from 'react'

import clsx from 'clsx'
import BoardTitle from './components/BoardTitle'
import BoardTools from './components/BoardTools'

import styles from './BoardToolBar.module.css'

type Props = {
  className?: string
}

const BoardToolBar: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(styles.Root, className)}>
      <BoardTitle />
      <BoardTools />
    </div>
  )
}

export default BoardToolBar
