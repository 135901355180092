import { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  BackIcon,
  ButtonIcon,
  DialogProvider,
  parseUrl,
  Tooltip,
  TooltipContent,
  TooltipPositions,
} from 'boards-web-ui'
import SideBarFooter from '@features/sidebar/components/SideBarFooter'
import { IconWrapper } from '@ui/components/IconWrapper'
import styles from './AccountPage.module.css'
import { AccountMenu } from './components/AccountMenu'
import { TAccountSubRoutes, USER_PERSONAL_INFO } from '../../constants/routes'
import ToastMessageContainer from '../../../containers/ToastMessageContainer'

export const AccountPage: FC = () => {
  const location = useLocation()
  const { pathname } = parseUrl(location.pathname)
  const activePath = pathname.split('/').pop() as TAccountSubRoutes

  const navigate = useNavigate()

  const handleBackButtonClick = useCallback(() => {
    navigate(location.state || '/')
  }, [location.state, navigate])

  return (
    <DialogProvider>
      <main className={styles.Root}>
        <section className={styles.Header}>
          <div className={styles.TitleContainer}>
            <Tooltip
              data={
                <TooltipContent>
                  <Trans i18nKey={'action_back'} />
                </TooltipContent>
              }
              position={TooltipPositions.BOTTOM_LEFT}
            >
              <ButtonIcon
                size={38}
                round
                variant="gray"
                onClick={handleBackButtonClick}
              >
                <IconWrapper color="gray">
                  <BackIcon />
                </IconWrapper>
              </ButtonIcon>
            </Tooltip>

            <span className={styles.Title}>
              <Trans i18nKey="account_screen_title" />
            </span>
          </div>
        </section>

        <section className={styles.Main}>
          <div className={styles.SideBar}>
            <AccountMenu activeItem={activePath || USER_PERSONAL_INFO} />

            <SideBarFooter className={styles.SideBarFooter} />
          </div>

          <div className={styles.Container}>
            <Outlet />
          </div>
        </section>
      </main>

      <ToastMessageContainer />
    </DialogProvider>
  )
}
