import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TNodeForm, NodeType, useDialog, FormFields } from 'boards-web-ui'

import {
  AfterSubmitBehaviourEnum,
  FORM_FIELDS_ALLOW_TO_CHANGE,
} from '@features/forms/constants'
import type {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { responseRequestAdapter } from '@features/checkout/utils/postCheckoutRequestAdapter'
import useNodeDiscardChanges from '@features/nodeEditor/hooks/useNodeDiscardChanges'
import { CHECKOUT_POST_SUBMITTING_BEHAVIOUR } from '@features/checkout/constants'

import { isEqualObjects } from '../../../../utils/isEqual'
import { FormIntegrationStatusData } from '../../../../_firebase'

type Props = {
  initialForm?: TNodeForm
  formIntegrationStatusState: FormIntegrationStatusData | null
  title: string
  action?: string
  postSubmissionBehavior: AfterSubmitBehaviourEnum
  postSubmissionMessage: string
  postSubmissionUrl: string
  postSubmissionError: string
  formsFields: FormFields
  encryptedEmail: string
  encryptedSpreadsheetURL: string
  email?: string
  responsesEmailError: string
  spreadsheetURL?: string
  onSave: ICreateOrUpdateNode
  onCancel: () => void
  initialEmail?: string
  initialSpreadsheetURL?: string
  isNew: boolean
}
const useFormApi = ({
  initialForm,
  formIntegrationStatusState,
  title,
  action,
  postSubmissionBehavior,
  postSubmissionMessage,
  postSubmissionUrl,
  postSubmissionError,
  formsFields,
  encryptedEmail,
  encryptedSpreadsheetURL,
  email,
  responsesEmailError,
  spreadsheetURL,
  onSave,
  onCancel,
  initialEmail,
  initialSpreadsheetURL,
  isNew,
}: Props) => {
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const { t } = useTranslation()

  const [isSubmitted, setIsSubmitted] = useState<boolean>(!isNew)

  const formToSubmit: TNodeForm = useMemo(() => {
    const formNodeProps: TNodeForm = {
      type: NodeType.FORM,
      title,
      form: {
        title,
        action: action === 'Submit' ? '' : action || '',
        response: responseRequestAdapter(
          postSubmissionBehavior,
          postSubmissionMessage,
          postSubmissionUrl,
        ),
        fields: formsFields,
      },
    }

    if (initialEmail === email) {
      formNodeProps.form.encryptedEmail = encryptedEmail
    }

    if (initialSpreadsheetURL === spreadsheetURL) {
      formNodeProps.form.encryptedSpreadsheetURL = encryptedSpreadsheetURL
    }

    if (typeof email !== 'undefined' || email !== '') {
      formNodeProps.form.email = email
    }

    if (typeof spreadsheetURL !== 'undefined' || spreadsheetURL !== '') {
      formNodeProps.form.spreadsheetURL = spreadsheetURL
    }

    return formNodeProps
  }, [
    initialEmail,
    initialSpreadsheetURL,
    title,
    action,
    postSubmissionBehavior,
    postSubmissionMessage,
    postSubmissionUrl,
    formsFields,
    encryptedSpreadsheetURL,
    spreadsheetURL,
    email,
    encryptedEmail,
  ])

  const isEqual = useMemo(() => {
    if (!initialForm) {
      return false
    }

    return isEqualObjects(
      formToSubmit.form as unknown as Record<string, unknown>,
      {
        ...initialForm.form,
        email: initialEmail,
        spreadsheetURL: initialSpreadsheetURL,
      } as unknown as Record<string, unknown>,
      FORM_FIELDS_ALLOW_TO_CHANGE,
    )
  }, [initialForm, formToSubmit, initialEmail, initialSpreadsheetURL])

  const transformFormBeforeSave = useCallback(
    (form: INodeProps) => {
      const transformedForm = { ...form }
      if (
        transformedForm.form?.action === t('forms_submit_button_label_default')
      ) {
        transformedForm.form.action = ''
      }
      return transformedForm
    },
    [t],
  )

  const onSubmit = useCallback(() => {
    try {
      onSave(
        transformFormBeforeSave({
          ...formToSubmit,
          integrationState: formIntegrationStatusState || undefined,
          form: {
            ...formToSubmit.form,
            spreadsheetURL: formToSubmit.form.spreadsheetURL,
          },
        } as INodeProps),
      )
      close()
    } catch (e) {
      close()
    }
  }, [
    close,
    onSave,
    formToSubmit,
    transformFormBeforeSave,
    formIntegrationStatusState,
  ])

  const onFormCancel = useCallback(() => {
    if (!isEqual) {
      return discardChanges(close)
    }

    return onCancel && onCancel()
  }, [discardChanges, close, isEqual, onCancel])

  const onFormSave = useCallback(() => {
    setIsSubmitted(true)

    if (email && responsesEmailError) {
      return undefined
    }

    if (
      postSubmissionBehavior === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT &&
      postSubmissionError
    ) {
      return undefined
    }

    if (!email) {
      return null
    }

    return onSubmit()
  }, [
    email,
    responsesEmailError,
    postSubmissionBehavior,
    postSubmissionError,
    onSubmit,
    setIsSubmitted,
  ])

  return {
    isSubmitted,
    onFormSave,
    onFormCancel,
  } as const
}

export default useFormApi
