import { useDispatch } from 'react-redux'
import useFolderEvents from '@features/analytics/useFolderEvents'
import usePageEvents from '@features/analytics/usePageEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useBoard from '@hooks/useBoard'

import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'
import useFolder from '../../../../hooks/useFolder'

import { nodeActions } from '../../../../actions'
import { Node } from '../../../models'
import { INodeProps } from '../models/IUseNodeCreatorAPI'

const useNodeEditorAPI = (boardId: string, folderId: string) => {
  const dispatch = useDispatch()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { folderEditContentEvent } = useFolderEvents()
  const { pageEditContentEvent } = usePageEvents()
  const { isGoogleAccountConnected } = useProfileInformation()
  const { totalBoardMembersCount, totalBoardEditorsCount } = useBoard()

  const { folder } = useFolder(boardId, folderId)
  const isPage = folder ? folder.isPage : false

  const editNode = (id: string, node: INodeProps) => {
    if (!isApiAvailableOrNotify() || !node) return
    const updatedNode = {
      ...node,
      title: node.title?.trim(),
      text: node.text?.trim(),
    }

    dispatch(nodeActions.setProperties(boardId, folderId, id, updatedNode))

    if (isPage) {
      pageEditContentEvent(
        { ...updatedNode, id } as Node,
        boardId,
        folderId,
        isGoogleAccountConnected,
      )
    } else {
      folderEditContentEvent({ ...updatedNode, id } as Node, boardId, {
        board_members_count: totalBoardMembersCount.toString(),
        board_editors_count: totalBoardEditorsCount.toString(),
      })
    }
  }

  return editNode
}

export default useNodeEditorAPI
