import React, { useEffect } from 'react'
import { useUpgradeDialogEvents } from '@features/reverseTrial/hooks/useUpgradeDialogEvents'
import {
  ButtonNew,
  CardActions,
  CardBody,
  CardBodyContent,
  CardHeader,
  KingIcon,
} from 'boards-web-ui'
import { Trans } from 'react-i18next'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePaywall from '@hooks/usePaywall'
import styles from './UpgradeToViewDialog.module.css'

export const UpgradeToViewDialog = () => {
  const { appPageContentBlockedViewEvent, appPageContentBlockedClickedEvent } =
    useUpgradeDialogEvents()
  const { openPaywall } = usePaywall()

  useEffect(() => {
    appPageContentBlockedViewEvent()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.Root}>
      <CardHeader className={styles.IconContainer} icon={<>🚩</>} />

      <CardBody className={styles.Container}>
        <CardBodyContent className={styles.Content}>
          <div className={styles.Title}>
            <Trans i18nKey={'view_content_popup_title'} />
          </div>

          <div className={styles.Message}>
            <Trans i18nKey={'view_content_popup_body'} />
          </div>
        </CardBodyContent>

        <CardActions className={styles.Actions}>
          <ButtonNew
            fullWidth
            size={'large'}
            startIcon={<KingIcon />}
            onClick={() => {
              openPaywall({ source: PaywallSourceEnum.CONTENT_BLOCKED_POPUP })

              appPageContentBlockedClickedEvent()
            }}
          >
            <Trans i18nKey={'action_upgrade_now'} />
          </ButtonNew>
        </CardActions>
      </CardBody>
    </div>
  )
}
