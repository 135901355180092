import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useBoard } from '@hooks/useBoard'

import { firebaseEventsAsyncActions } from '../../../actions'
import { UseLogUsageEVentsState } from './models/UseLogUsageEventsState'

const useLogUsageEvents = (): UseLogUsageEVentsState => {
  const dispatch = useDispatch()
  const { boardId } = useBoard()

  const logEvent = useCallback(
    (event: string, node: string) =>
      dispatch(
        firebaseEventsAsyncActions.logUsageEvent(event, {
          board: boardId,
          nodes: [node],
        }),
      ),
    [dispatch, boardId],
  )

  const viewNode = (node: string) => logEvent('view', node)

  return {
    viewNode,
  }
}

export default useLogUsageEvents
