import { useLogEvents } from './useLogEvents'
import useRouterParams from '../../../hooks/useRouterParams'

enum TargetNames {
  INSIGHTS = 'insights',
  INSIGHTS_LOCKED = 'analytics_tab_locked',
  CONTENT = 'content',
}

const useAppEvents = () => {
  const { boardId } = useRouterParams()
  const { buttonClick } = useLogEvents()

  const insightsTabClick = () =>
    buttonClick(TargetNames.INSIGHTS, { board_id: boardId })

  const insightsTabLockedClick = () =>
    buttonClick(TargetNames.INSIGHTS_LOCKED, { board_id: boardId })

  const contentTabClick = () =>
    buttonClick(TargetNames.CONTENT, { board_id: boardId })

  return {
    insightsTabClick,
    insightsTabLockedClick,
    contentTabClick,
  } as const
}

export default useAppEvents
