/* eslint-disable no-shadow */

export function withContentAdded(stack, contentId, content) {
  const { undo: undoFromStack } = stack

  if (stack.contentId !== contentId) {
    return {
      contentId,
      undo: [content],
      redo: [],
    }
  }
  if (undoFromStack.length > 0) {
    const mostRecent = undo[undo.length - 1]
    if (mostRecent === content) {
      return stack // no changes
    }
  }
  if (undoFromStack.length > 15) {
    undoFromStack.shift()
  }
  return {
    contentId,
    undo: [...undoFromStack, content],
    redo: [],
  }
}

export function undo(stack, contentId, content) {
  if (stack.contentId !== contentId) return { stack }

  if (stack.undo.length === 0) return { stack }

  const undoFromStack = [...stack.undo]
  const popped = undoFromStack.pop()

  return {
    content: popped,
    undoStack: {
      contentId,
      undo: undoFromStack,
      redo: [...stack.redo, content],
    },
  }
}

export function redo(stack, contentId, content) {
  if (stack.contentId !== contentId) return { stack }

  if (stack.redo.length === 0) return { stack }

  const redoFromStack = [...stack.redo]
  const popped = redoFromStack.pop()

  return {
    content: popped,
    undoStack: {
      contentId,
      undo: [...stack.undo, content],
      redo: redoFromStack,
    },
  }
}
