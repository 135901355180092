import { useState } from 'react'

type Props = {
  defaultShippingFees?: string
  defaultMinOrder?: string
}
const DEFAULT_PROPS: Required<Props> = {
  defaultShippingFees: '0',
  defaultMinOrder: '0',
}
const useCheckoutSettingsPayment = (props?: Props) => {
  const { defaultMinOrder, defaultShippingFees }: Required<Props> = {
    ...DEFAULT_PROPS,
    ...props,
  }

  const [minOrder, setMinOrder] = useState<string>(defaultMinOrder)
  const [shippingFees, setShippingFees] = useState<string>(defaultShippingFees)
  const onShippingFeesChange = (newShippingFees: string) =>
    setShippingFees(newShippingFees)
  const onMinOrderChange = (newMinOrder: string) => setMinOrder(newMinOrder)

  return {
    minOrder,
    shippingFees,
    onShippingFeesChange,
    onMinOrderChange,
  } as const
}

export default useCheckoutSettingsPayment
