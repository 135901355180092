export const TeamSizeAnswers = {
  o0: '0',
  o1_10: '1-10',
  o11_50: '11-50',
  o51_100: '51-100',
  o101_200: '101-200',
  o201_300: '201-300',
  o301_500: '301-500',
  o501: '501+',
}

export const TimeAnswers = {
  o0_3: '0m-3m',
  o4_12: '4m-12m',
  o12_36: '1y-3y',
  o48: '4y+',
}

// ------------------------------------------------------
// ------------------- Herbalife ------------------------
// ------------------------------------------------------
export const RankAnswersHerbalife = {
  distributor: 'Distributor',
  seniorConsultant: 'Senior Consultant',
  qualifiedProducer: 'Qualified Producer',
  supervisor: 'Supervisor',
  worldTeam: 'World Team',
  globalExpansionTeam: 'Global Expansion Team',
  millionaire: 'Millionaire',
  president: "President's Team",
  president15: '15k President’s Team',
  president20: '20k President’s Team',
  president30: '30k President’s Team',
  president40: '40k President’s Team',
  president50: '50k President’s Team',
  president60: '60k President’s Team',
  president70: '70k President’s Team',
  president80: '80k President’s Team',
  president90: '90k President’s Team',
  executivePresident: 'Executive President’s Team',
  seniorExecutivePresident: 'Senior Executive President’s Team',
  internationalExecutivePresident: 'International Executive President’s Team',
  chiefExecutivePresident: 'Chief Executive President’s Team',
  chairman: "Chairman's Club",
  founder: 'Founder’s Circle',
}

export const TeamSizeAnswersByRankHerbalife = {
  [RankAnswersHerbalife.distributor]: TeamSizeAnswers.o1_10,
  [RankAnswersHerbalife.seniorConsultant]: TeamSizeAnswers.o1_10,
  [RankAnswersHerbalife.qualifiedProducer]: TeamSizeAnswers.o1_10,
  [RankAnswersHerbalife.supervisor]: TeamSizeAnswers.o1_10,
  [RankAnswersHerbalife.worldTeam]: TeamSizeAnswers.o1_10,
  [RankAnswersHerbalife.globalExpansionTeam]: TeamSizeAnswers.o11_50,
  [RankAnswersHerbalife.millionaire]: TeamSizeAnswers.o11_50,
  [RankAnswersHerbalife.president]: TeamSizeAnswers.o11_50,
  [RankAnswersHerbalife.president15]: TeamSizeAnswers.o11_50,
  [RankAnswersHerbalife.president20]: TeamSizeAnswers.o51_100,
  [RankAnswersHerbalife.president30]: TeamSizeAnswers.o51_100,
  [RankAnswersHerbalife.president40]: TeamSizeAnswers.o51_100,
  [RankAnswersHerbalife.president50]: TeamSizeAnswers.o101_200,
  [RankAnswersHerbalife.president60]: TeamSizeAnswers.o101_200,
  [RankAnswersHerbalife.president70]: TeamSizeAnswers.o101_200,
  [RankAnswersHerbalife.president80]: TeamSizeAnswers.o101_200,
  [RankAnswersHerbalife.president90]: TeamSizeAnswers.o101_200,
  [RankAnswersHerbalife.executivePresident]: TeamSizeAnswers.o201_300,
  [RankAnswersHerbalife.seniorExecutivePresident]: TeamSizeAnswers.o201_300,
  [RankAnswersHerbalife.internationalExecutivePresident]:
    TeamSizeAnswers.o201_300,
  [RankAnswersHerbalife.chiefExecutivePresident]: TeamSizeAnswers.o201_300,
  [RankAnswersHerbalife.chairman]: TeamSizeAnswers.o301_500,
  [RankAnswersHerbalife.founder]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankHerbalife = {
  [RankAnswersHerbalife.distributor]: TimeAnswers.o0_3,
  [RankAnswersHerbalife.seniorConsultant]: TimeAnswers.o0_3,
  [RankAnswersHerbalife.qualifiedProducer]: TimeAnswers.o0_3,
  [RankAnswersHerbalife.supervisor]: TimeAnswers.o0_3,
  [RankAnswersHerbalife.worldTeam]: TimeAnswers.o0_3,
  [RankAnswersHerbalife.globalExpansionTeam]: TimeAnswers.o4_12,
  [RankAnswersHerbalife.millionaire]: TimeAnswers.o4_12,
  [RankAnswersHerbalife.president]: TimeAnswers.o4_12,
  [RankAnswersHerbalife.president15]: TimeAnswers.o4_12,
  [RankAnswersHerbalife.president20]: TimeAnswers.o4_12,
  [RankAnswersHerbalife.president30]: TimeAnswers.o4_12,
  [RankAnswersHerbalife.president40]: TimeAnswers.o4_12,
  [RankAnswersHerbalife.president50]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.president60]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.president70]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.president80]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.president90]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.executivePresident]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.seniorExecutivePresident]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.internationalExecutivePresident]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.chiefExecutivePresident]: TimeAnswers.o12_36,
  [RankAnswersHerbalife.chairman]: TimeAnswers.o48,
  [RankAnswersHerbalife.founder]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Farmasi --------------------------
// ------------------------------------------------------
export const RankAnswersFarmasi = {
  beautyInfluencer: 'Beauty Influencer',
  manager: 'Manager',
  bronzeManager: 'Bronze Manager',
  goldenManager: 'Golden Manager',
  platinumManager: 'Platinum Manager ',
  emeraldManager: 'Emerald Manager',
  diamondManager: 'Diamond Manager',
  vicePresidentManager: 'Vice President Manager',
  presidentManager: 'President Manager',
  bossManager: 'Boss Manager',
  executiveBossManager: 'Executive Boss Manager',
}

export const TeamSizeAnswersByRankFarmasi = {
  [RankAnswersFarmasi.beautyInfluencer]: TeamSizeAnswers.o1_10,
  [RankAnswersFarmasi.manager]: TeamSizeAnswers.o1_10,
  [RankAnswersFarmasi.bronzeManager]: TeamSizeAnswers.o11_50,
  [RankAnswersFarmasi.goldenManager]: TeamSizeAnswers.o11_50,
  [RankAnswersFarmasi.platinumManager]: TeamSizeAnswers.o51_100,
  [RankAnswersFarmasi.emeraldManager]: TeamSizeAnswers.o101_200,
  [RankAnswersFarmasi.diamondManager]: TeamSizeAnswers.o101_200,
  [RankAnswersFarmasi.vicePresidentManager]: TeamSizeAnswers.o201_300,
  [RankAnswersFarmasi.presidentManager]: TeamSizeAnswers.o201_300,
  [RankAnswersFarmasi.bossManager]: TeamSizeAnswers.o301_500,
  [RankAnswersFarmasi.executiveBossManager]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankFarmasi = {
  [RankAnswersFarmasi.beautyInfluencer]: TimeAnswers.o0_3,
  [RankAnswersFarmasi.manager]: TimeAnswers.o0_3,
  [RankAnswersFarmasi.bronzeManager]: TimeAnswers.o4_12,
  [RankAnswersFarmasi.goldenManager]: TimeAnswers.o4_12,
  [RankAnswersFarmasi.platinumManager]: TimeAnswers.o4_12,
  [RankAnswersFarmasi.emeraldManager]: TimeAnswers.o12_36,
  [RankAnswersFarmasi.diamondManager]: TimeAnswers.o12_36,
  [RankAnswersFarmasi.vicePresidentManager]: TimeAnswers.o12_36,
  [RankAnswersFarmasi.presidentManager]: TimeAnswers.o12_36,
  [RankAnswersFarmasi.bossManager]: TimeAnswers.o48,
  [RankAnswersFarmasi.executiveBossManager]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Younique -------------------------
// ------------------------------------------------------
export const RankAnswersYounique = {
  brandAmbassador: 'Brand Ambassador',
  brandSpecialist: 'Brand Specialist',
  seniorBrandSpecialist: 'Senior Brand Specialist',
  brandManager: 'Brand Manager',
  seniorBrandManager: 'Senior Brand Manager',
  brandDirector: 'Brand Director',
  seniorBrandDirector: 'Senior Brand Director',
  brandVicePresident: 'Brand Vice President',
  brandExecutive: 'Brand Executive',
  presidentialBrandExecutive: 'Presidential Brand Executive',
  globalBrandExecutive: 'Global Brand Executive',
  chiefBrandExecutive: 'Chief Brand Executive',
}

export const TeamSizeAnswersByRankYounique = {
  [RankAnswersYounique.brandAmbassador]: TeamSizeAnswers.o1_10,
  [RankAnswersYounique.brandSpecialist]: TeamSizeAnswers.o1_10,
  [RankAnswersYounique.seniorBrandSpecialist]: TeamSizeAnswers.o1_10,
  [RankAnswersYounique.brandManager]: TeamSizeAnswers.o11_50,
  [RankAnswersYounique.seniorBrandManager]: TeamSizeAnswers.o11_50,
  [RankAnswersYounique.brandDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersYounique.seniorBrandDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersYounique.brandVicePresident]: TeamSizeAnswers.o101_200,
  [RankAnswersYounique.brandExecutive]: TeamSizeAnswers.o201_300,
  [RankAnswersYounique.presidentialBrandExecutive]: TeamSizeAnswers.o201_300,
  [RankAnswersYounique.globalBrandExecutive]: TeamSizeAnswers.o301_500,
  [RankAnswersYounique.chiefBrandExecutive]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankYounique = {
  [RankAnswersYounique.brandAmbassador]: TimeAnswers.o0_3,
  [RankAnswersYounique.brandSpecialist]: TimeAnswers.o0_3,
  [RankAnswersYounique.seniorBrandSpecialist]: TimeAnswers.o0_3,
  [RankAnswersYounique.brandManager]: TimeAnswers.o4_12,
  [RankAnswersYounique.seniorBrandManager]: TimeAnswers.o4_12,
  [RankAnswersYounique.brandDirector]: TimeAnswers.o4_12,
  [RankAnswersYounique.seniorBrandDirector]: TimeAnswers.o4_12,
  [RankAnswersYounique.brandVicePresident]: TimeAnswers.o12_36,
  [RankAnswersYounique.brandExecutive]: TimeAnswers.o12_36,
  [RankAnswersYounique.presidentialBrandExecutive]: TimeAnswers.o12_36,
  [RankAnswersYounique.globalBrandExecutive]: TimeAnswers.o48,
  [RankAnswersYounique.chiefBrandExecutive]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Arbonne -------------------------
// ------------------------------------------------------
export const RankAnswersArbonne = {
  independentConsultant: 'Independent Consultant',
  districtManager: 'District Manager',
  areaManager: 'Area Manager',
  regionalVicePresident: 'Regional Vice President',
  nationalVicePresident: 'National Vice President',
}

export const TeamSizeAnswersByRankArbonne = {
  [RankAnswersArbonne.independentConsultant]: TeamSizeAnswers.o1_10,
  [RankAnswersArbonne.districtManager]: TeamSizeAnswers.o11_50,
  [RankAnswersArbonne.areaManager]: TeamSizeAnswers.o51_100,
  [RankAnswersArbonne.regionalVicePresident]: TeamSizeAnswers.o201_300,
  [RankAnswersArbonne.nationalVicePresident]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankArbonne = {
  [RankAnswersArbonne.independentConsultant]: TimeAnswers.o0_3,
  [RankAnswersArbonne.districtManager]: TimeAnswers.o4_12,
  [RankAnswersArbonne.areaManager]: TimeAnswers.o4_12,
  [RankAnswersArbonne.regionalVicePresident]: TimeAnswers.o12_36,
  [RankAnswersArbonne.nationalVicePresident]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- NuSkin -------------------------
// ------------------------------------------------------
export const RankAnswersNuSkin = {
  member: 'Member',
  brandAffiliate: 'Brand Affiliate',
  qualifyingBrandRepresentative: 'Qualifying Brand Representative',
  brandRepresentative: 'Brand Representative',
  brandPartner: 'Brand Partner',
  seniorBrandPartner: 'Senior Brand Partner',
  executiveBrandPartner: 'Executive Brand Partner',
  brandDirector: 'Brand Director',
  seniorBrandDirector: 'Senior Brand Director',
  executiveBrandDirector: 'Executive Brand Director',
  presidentialDirector: 'Presidential Director',
}

export const TeamSizeAnswersByRankNuSkin = {
  [RankAnswersNuSkin.member]: TeamSizeAnswers.o0,
  [RankAnswersNuSkin.brandAffiliate]: TeamSizeAnswers.o1_10,
  [RankAnswersNuSkin.qualifyingBrandRepresentative]: TeamSizeAnswers.o1_10,
  [RankAnswersNuSkin.brandRepresentative]: TeamSizeAnswers.o11_50,
  [RankAnswersNuSkin.brandPartner]: TeamSizeAnswers.o11_50,
  [RankAnswersNuSkin.seniorBrandPartner]: TeamSizeAnswers.o51_100,
  [RankAnswersNuSkin.executiveBrandPartner]: TeamSizeAnswers.o101_200,
  [RankAnswersNuSkin.brandDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersNuSkin.seniorBrandDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersNuSkin.executiveBrandDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersNuSkin.presidentialDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankNuSkin = {
  [RankAnswersNuSkin.member]: TimeAnswers.o0_3,
  [RankAnswersNuSkin.brandAffiliate]: TimeAnswers.o0_3,
  [RankAnswersNuSkin.qualifyingBrandRepresentative]: TimeAnswers.o0_3,
  [RankAnswersNuSkin.brandRepresentative]: TimeAnswers.o4_12,
  [RankAnswersNuSkin.brandPartner]: TimeAnswers.o4_12,
  [RankAnswersNuSkin.seniorBrandPartner]: TimeAnswers.o4_12,
  [RankAnswersNuSkin.executiveBrandPartner]: TimeAnswers.o12_36,
  [RankAnswersNuSkin.brandDirector]: TimeAnswers.o12_36,
  [RankAnswersNuSkin.seniorBrandDirector]: TimeAnswers.o12_36,
  [RankAnswersNuSkin.executiveBrandDirector]: TimeAnswers.o48,
  [RankAnswersNuSkin.presidentialDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- It Works! ------------------------
// ------------------------------------------------------
export const RankAnswersItWorks = {
  partner: 'Partner',
  executive: 'Executive',
  ruby: 'Ruby',
  emerald: 'Emerald',
  diamond: 'Diamond',
  doubleDiamond: 'Double Diamond',
  tripleDiamond: 'Triple Diamond',
  presidentialDiamond: 'Presidential Diamond',
  ambassadorDiamond: 'Ambassador Diamond',
}

export const TeamSizeAnswersByRankItWorks = {
  [RankAnswersItWorks.partner]: TeamSizeAnswers.o0,
  [RankAnswersItWorks.executive]: TeamSizeAnswers.o1_10,
  [RankAnswersItWorks.ruby]: TeamSizeAnswers.o11_50,
  [RankAnswersItWorks.emerald]: TeamSizeAnswers.o51_100,
  [RankAnswersItWorks.diamond]: TeamSizeAnswers.o101_200,
  [RankAnswersItWorks.doubleDiamond]: TeamSizeAnswers.o101_200,
  [RankAnswersItWorks.tripleDiamond]: TeamSizeAnswers.o201_300,
  [RankAnswersItWorks.presidentialDiamond]: TeamSizeAnswers.o301_500,
  [RankAnswersItWorks.ambassadorDiamond]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankItWorks = {
  [RankAnswersItWorks.partner]: TimeAnswers.o0_3,
  [RankAnswersItWorks.executive]: TimeAnswers.o0_3,
  [RankAnswersItWorks.ruby]: TimeAnswers.o4_12,
  [RankAnswersItWorks.emerald]: TimeAnswers.o4_12,
  [RankAnswersItWorks.diamond]: TimeAnswers.o12_36,
  [RankAnswersItWorks.doubleDiamond]: TimeAnswers.o12_36,
  [RankAnswersItWorks.tripleDiamond]: TimeAnswers.o12_36,
  [RankAnswersItWorks.presidentialDiamond]: TimeAnswers.o48,
  [RankAnswersItWorks.ambassadorDiamond]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Modere ---------------------------
// ------------------------------------------------------
export const RankAnswersModere = {
  consultant: 'Consultant',
  seniorConsultant: 'Senior Consultant',
  teamLeader: 'Team Leader',
  seniorTeamLeader: 'Senior Team Leader',
  director1: 'Director 1',
  director2: 'Director 2',
  director3: 'Director 3',
  elite1: 'Elite 1',
  elite2: 'Elite 2',
  elite3: 'Elite 3',
}

export const TeamSizeAnswersByRankModere = {
  [RankAnswersModere.consultant]: TeamSizeAnswers.o0,
  [RankAnswersModere.seniorConsultant]: TeamSizeAnswers.o1_10,
  [RankAnswersModere.teamLeader]: TeamSizeAnswers.o11_50,
  [RankAnswersModere.seniorTeamLeader]: TeamSizeAnswers.o11_50,
  [RankAnswersModere.director1]: TeamSizeAnswers.o51_100,
  [RankAnswersModere.director2]: TeamSizeAnswers.o101_200,
  [RankAnswersModere.director3]: TeamSizeAnswers.o201_300,
  [RankAnswersModere.elite1]: TeamSizeAnswers.o301_500,
  [RankAnswersModere.elite2]: TeamSizeAnswers.o301_500,
  [RankAnswersModere.elite3]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankModere = {
  [RankAnswersModere.consultant]: TimeAnswers.o0_3,
  [RankAnswersModere.seniorConsultant]: TimeAnswers.o0_3,
  [RankAnswersModere.teamLeader]: TimeAnswers.o4_12,
  [RankAnswersModere.seniorTeamLeader]: TimeAnswers.o4_12,
  [RankAnswersModere.director1]: TimeAnswers.o4_12,
  [RankAnswersModere.director2]: TimeAnswers.o12_36,
  [RankAnswersModere.director3]: TimeAnswers.o12_36,
  [RankAnswersModere.elite1]: TimeAnswers.o48,
  [RankAnswersModere.elite2]: TimeAnswers.o48,
  [RankAnswersModere.elite3]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- JuicePlus+ ---------------------------
// ------------------------------------------------------
export const RankAnswersJuicePlus = {
  partner: 'Partner',
  partnerPlus: 'Partner Plus',
  qualifyingSalesCoordinator: 'Qualifying Sales Coordinator',
  salesCoordinator: 'Sales Coordinator',
  qualifyingSeniorSalesCoordinator: 'Qualifying Senior Sales Coordinator',
  seniorSalesCoordinator: 'Senior Sales Coordinator',
  qualifyingNationalMarketingDirector: 'Qualifying National Marketing Director',
  nationalMarketingDirector: 'National Marketing Director',
  internationalMarketingDirector: 'International Marketing Director',
  executiveMarketingDirector: 'Executive Marketing Director',
  presidentialMarketingDirector: 'Presidential Marketing Director',
  presidentialMarketingDirectorPlus: 'Presidential Marketing Director Plus',
}

export const TeamSizeAnswersByRankJuicePlus = {
  [RankAnswersJuicePlus.partner]: TeamSizeAnswers.o0,
  [RankAnswersJuicePlus.partnerPlus]: TeamSizeAnswers.o1_10,
  [RankAnswersJuicePlus.qualifyingSalesCoordinator]: TeamSizeAnswers.o1_10,
  [RankAnswersJuicePlus.salesCoordinator]: TeamSizeAnswers.o11_50,
  [RankAnswersJuicePlus.qualifyingSeniorSalesCoordinator]:
    TeamSizeAnswers.o11_50,
  [RankAnswersJuicePlus.seniorSalesCoordinator]: TeamSizeAnswers.o11_50,
  [RankAnswersJuicePlus.qualifyingNationalMarketingDirector]:
    TeamSizeAnswers.o51_100,
  [RankAnswersJuicePlus.nationalMarketingDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersJuicePlus.internationalMarketingDirector]:
    TeamSizeAnswers.o101_200,
  [RankAnswersJuicePlus.executiveMarketingDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersJuicePlus.presidentialMarketingDirector]:
    TeamSizeAnswers.o301_500,
  [RankAnswersJuicePlus.presidentialMarketingDirectorPlus]:
    TeamSizeAnswers.o501,
}

export const TimeAnswersByRankJuicePlus = {
  [RankAnswersJuicePlus.partner]: TimeAnswers.o0_3,
  [RankAnswersJuicePlus.partnerPlus]: TimeAnswers.o0_3,
  [RankAnswersJuicePlus.qualifyingSalesCoordinator]: TimeAnswers.o0_3,
  [RankAnswersJuicePlus.salesCoordinator]: TimeAnswers.o4_12,
  [RankAnswersJuicePlus.qualifyingSeniorSalesCoordinator]: TimeAnswers.o4_12,
  [RankAnswersJuicePlus.seniorSalesCoordinator]: TimeAnswers.o4_12,
  [RankAnswersJuicePlus.qualifyingNationalMarketingDirector]: TimeAnswers.o4_12,
  [RankAnswersJuicePlus.nationalMarketingDirector]: TimeAnswers.o4_12,
  [RankAnswersJuicePlus.internationalMarketingDirector]: TimeAnswers.o12_36,
  [RankAnswersJuicePlus.executiveMarketingDirector]: TimeAnswers.o12_36,
  [RankAnswersJuicePlus.presidentialMarketingDirector]: TimeAnswers.o48,
  [RankAnswersJuicePlus.presidentialMarketingDirectorPlus]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Pampered Chef --------------------
// ------------------------------------------------------
export const RankAnswersPamperedChef = {
  consultant: 'Consultant',
  seniorConsultant: 'Senior Consultant',
  teamLeader: 'Team leader',
  director: 'Director',
  advancedDirector: 'Advanced Director',
  seniorDirector: 'Senior Director',
  executiveDirector: 'Executive Director',
  seniorExecutiveDirector: 'Senior Executive Director',
  nationalExecutiveDirector: 'National Executive Director',
}

export const TeamSizeAnswersByRankPamperedChef = {
  [RankAnswersPamperedChef.consultant]: TeamSizeAnswers.o1_10,
  [RankAnswersPamperedChef.seniorConsultant]: TeamSizeAnswers.o1_10,
  [RankAnswersPamperedChef.teamLeader]: TeamSizeAnswers.o1_10,
  [RankAnswersPamperedChef.director]: TeamSizeAnswers.o11_50,
  [RankAnswersPamperedChef.advancedDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersPamperedChef.seniorDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersPamperedChef.executiveDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersPamperedChef.seniorExecutiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersPamperedChef.nationalExecutiveDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankPamperedChef = {
  [RankAnswersPamperedChef.consultant]: TimeAnswers.o0_3,
  [RankAnswersPamperedChef.seniorConsultant]: TimeAnswers.o0_3,
  [RankAnswersPamperedChef.teamLeader]: TimeAnswers.o0_3,
  [RankAnswersPamperedChef.director]: TimeAnswers.o4_12,
  [RankAnswersPamperedChef.advancedDirector]: TimeAnswers.o4_12,
  [RankAnswersPamperedChef.seniorDirector]: TimeAnswers.o12_36,
  [RankAnswersPamperedChef.executiveDirector]: TimeAnswers.o12_36,
  [RankAnswersPamperedChef.seniorExecutiveDirector]: TimeAnswers.o48,
  [RankAnswersPamperedChef.nationalExecutiveDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Xyngular -----------------------
// ------------------------------------------------------
export const RankAnswersXyngular = {
  partner: 'Partner',
  manager: 'Manager',
  silverManager: 'Silver Manager',
  goldManager: 'Gold Manager',
  platinumManager: 'Platinum Manager',
  director: 'Director',
  silverDirector: 'Silver Director',
  goldDirector: 'Gold Director',
  platinumDirector: 'Platinum Director',
  executive: 'Executive',
  silverExecutive: 'Silver Executive',
  goldExecutive: 'Gold Executive',
  platinumExecutive: 'Platinum Executive',
  ambassador: 'Ambassador',
  silverAmbassador: 'Silver Ambassador',
  goldAmbassador: 'Gold Ambassador',
  platinumAmbassador: 'Platinum Ambassador',
}

export const TeamSizeAnswersByRankXyngular = {
  [RankAnswersXyngular.partner]: TeamSizeAnswers.o0,
  [RankAnswersXyngular.manager]: TeamSizeAnswers.o1_10,
  [RankAnswersXyngular.silverManager]: TeamSizeAnswers.o11_50,
  [RankAnswersXyngular.goldManager]: TeamSizeAnswers.o11_50,
  [RankAnswersXyngular.platinumManager]: TeamSizeAnswers.o51_100,
  [RankAnswersXyngular.director]: TeamSizeAnswers.o51_100,
  [RankAnswersXyngular.silverDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersXyngular.goldDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersXyngular.platinumDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersXyngular.executive]: TeamSizeAnswers.o201_300,
  [RankAnswersXyngular.silverExecutive]: TeamSizeAnswers.o201_300,
  [RankAnswersXyngular.goldExecutive]: TeamSizeAnswers.o301_500,
  [RankAnswersXyngular.platinumExecutive]: TeamSizeAnswers.o301_500,
  [RankAnswersXyngular.ambassador]: TeamSizeAnswers.o501,
  [RankAnswersXyngular.silverAmbassador]: TeamSizeAnswers.o501,
  [RankAnswersXyngular.goldAmbassador]: TeamSizeAnswers.o501,
  [RankAnswersXyngular.platinumAmbassador]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankXyngular = {
  [RankAnswersXyngular.partner]: TimeAnswers.o0_3,
  [RankAnswersXyngular.manager]: TimeAnswers.o0_3,
  [RankAnswersXyngular.silverManager]: TimeAnswers.o4_12,
  [RankAnswersXyngular.goldManager]: TimeAnswers.o4_12,
  [RankAnswersXyngular.platinumManager]: TimeAnswers.o4_12,
  [RankAnswersXyngular.director]: TimeAnswers.o4_12,
  [RankAnswersXyngular.silverDirector]: TimeAnswers.o12_36,
  [RankAnswersXyngular.goldDirector]: TimeAnswers.o12_36,
  [RankAnswersXyngular.platinumDirector]: TimeAnswers.o12_36,
  [RankAnswersXyngular.executive]: TimeAnswers.o12_36,
  [RankAnswersXyngular.silverExecutive]: TimeAnswers.o12_36,
  [RankAnswersXyngular.goldExecutive]: TimeAnswers.o48,
  [RankAnswersXyngular.platinumExecutive]: TimeAnswers.o48,
  [RankAnswersXyngular.ambassador]: TimeAnswers.o48,
  [RankAnswersXyngular.silverAmbassador]: TimeAnswers.o48,
  [RankAnswersXyngular.goldAmbassador]: TimeAnswers.o48,
  [RankAnswersXyngular.platinumAmbassador]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Oriflame -----------------------
// ------------------------------------------------------
export const RankAnswersOriflame = {
  brandPartner: 'Brand Partner',
  brandManager: 'Brand Manager',
  seniorManager: 'Senior Manager',
  director: 'Director',
  seniorDirector: 'Senior Director',
  goldDirector: 'Gold Director',
  seniorGoldDirector: 'Senior Gold Director',
  sapphireDirector: 'Sapphire Director',
  diamondDirector: 'Diamond Director',
  seniorDiamondDirector: 'Senior Diamond Director',
  doubleDiamondDirector: 'Double Diamond Director',
  executiveDirector: 'Executive Director',
  goldExecutiveDirector: 'Gold Executive Director',
  sapphireExecutiveDirector: 'Sapphire Executive Director',
  diamondExecutiveDirector: 'Diamond Executive Director',
  presidentialDirector: 'Presidential Director',
  seniorPresidentialDirector: 'Senior Presidential Director',
  goldPresidentialDirector: 'Gold Presidential Director',
  sapphirePresidentialDirector: 'Sapphire Presidential Director',
  diamondPresidentialDirector: 'Diamond Presidential Director',
}

export const TeamSizeAnswersByRankOriflame = {
  [RankAnswersOriflame.brandPartner]: TeamSizeAnswers.o1_10,
  [RankAnswersOriflame.brandManager]: TeamSizeAnswers.o1_10,
  [RankAnswersOriflame.seniorManager]: TeamSizeAnswers.o1_10,
  [RankAnswersOriflame.director]: TeamSizeAnswers.o11_50,
  [RankAnswersOriflame.seniorDirector]: TeamSizeAnswers.o11_50,
  [RankAnswersOriflame.goldDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersOriflame.seniorGoldDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersOriflame.sapphireDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersOriflame.diamondDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersOriflame.seniorDiamondDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersOriflame.doubleDiamondDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersOriflame.executiveDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersOriflame.goldExecutiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersOriflame.sapphireExecutiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersOriflame.diamondExecutiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersOriflame.presidentialDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersOriflame.seniorPresidentialDirector]: TeamSizeAnswers.o501,
  [RankAnswersOriflame.goldPresidentialDirector]: TeamSizeAnswers.o501,
  [RankAnswersOriflame.sapphirePresidentialDirector]: TeamSizeAnswers.o501,
  [RankAnswersOriflame.diamondPresidentialDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankOriflame = {
  [RankAnswersOriflame.brandPartner]: TimeAnswers.o0_3,
  [RankAnswersOriflame.brandManager]: TimeAnswers.o0_3,
  [RankAnswersOriflame.seniorManager]: TimeAnswers.o0_3,
  [RankAnswersOriflame.director]: TimeAnswers.o4_12,
  [RankAnswersOriflame.seniorDirector]: TimeAnswers.o4_12,
  [RankAnswersOriflame.goldDirector]: TimeAnswers.o4_12,
  [RankAnswersOriflame.seniorGoldDirector]: TimeAnswers.o4_12,
  [RankAnswersOriflame.sapphireDirector]: TimeAnswers.o12_36,
  [RankAnswersOriflame.diamondDirector]: TimeAnswers.o12_36,
  [RankAnswersOriflame.seniorDiamondDirector]: TimeAnswers.o12_36,
  [RankAnswersOriflame.doubleDiamondDirector]: TimeAnswers.o12_36,
  [RankAnswersOriflame.executiveDirector]: TimeAnswers.o12_36,
  [RankAnswersOriflame.goldExecutiveDirector]: TimeAnswers.o48,
  [RankAnswersOriflame.sapphireExecutiveDirector]: TimeAnswers.o48,
  [RankAnswersOriflame.diamondExecutiveDirector]: TimeAnswers.o48,
  [RankAnswersOriflame.presidentialDirector]: TimeAnswers.o48,
  [RankAnswersOriflame.seniorPresidentialDirector]: TimeAnswers.o48,
  [RankAnswersOriflame.goldPresidentialDirector]: TimeAnswers.o48,
  [RankAnswersOriflame.sapphirePresidentialDirector]: TimeAnswers.o48,
  [RankAnswersOriflame.diamondPresidentialDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Pruvit -------------------------
// ------------------------------------------------------
export const RankAnswersPruvit = {
  R1Pruver: 'R1 Pruver',
  R2Pruver: 'R2 Pruver',
  R3Pruver: 'R3 Pruver',
  R4Pruver: 'R4 Pruver',
  R5Pruver: 'R5 Pruver',
  R6Champion: 'R6 Champion',
  R7100KChampion: 'R7 100K Champion',
  R8250KChampion: 'R8 250K Champion',
  R9750KChampion: 'R9 750 K Champion',
  R101MChampion: 'R10 1M Champion',
  R11Legend: 'R11 Legend',
}

export const TeamSizeAnswersByRankPruvit = {
  [RankAnswersPruvit.R1Pruver]: TeamSizeAnswers.o1_10,
  [RankAnswersPruvit.R2Pruver]: TeamSizeAnswers.o1_10,
  [RankAnswersPruvit.R3Pruver]: TeamSizeAnswers.o11_50,
  [RankAnswersPruvit.R4Pruver]: TeamSizeAnswers.o11_50,
  [RankAnswersPruvit.R5Pruver]: TeamSizeAnswers.o51_100,
  [RankAnswersPruvit.R6Champion]: TeamSizeAnswers.o101_200,
  [RankAnswersPruvit.R7100KChampion]: TeamSizeAnswers.o101_200,
  [RankAnswersPruvit.R8250KChampion]: TeamSizeAnswers.o201_300,
  [RankAnswersPruvit.R9750KChampion]: TeamSizeAnswers.o301_500,
  [RankAnswersPruvit.R101MChampion]: TeamSizeAnswers.o301_500,
  [RankAnswersPruvit.R11Legend]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankPruvit = {
  [RankAnswersPruvit.R1Pruver]: TimeAnswers.o0_3,
  [RankAnswersPruvit.R2Pruver]: TimeAnswers.o0_3,
  [RankAnswersPruvit.R3Pruver]: TimeAnswers.o4_12,
  [RankAnswersPruvit.R4Pruver]: TimeAnswers.o4_12,
  [RankAnswersPruvit.R5Pruver]: TimeAnswers.o4_12,
  [RankAnswersPruvit.R6Champion]: TimeAnswers.o12_36,
  [RankAnswersPruvit.R7100KChampion]: TimeAnswers.o12_36,
  [RankAnswersPruvit.R8250KChampion]: TimeAnswers.o12_36,
  [RankAnswersPruvit.R9750KChampion]: TimeAnswers.o48,
  [RankAnswersPruvit.R101MChampion]: TimeAnswers.o48,
  [RankAnswersPruvit.R11Legend]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Mary Kay -----------------------
// ------------------------------------------------------
export const RankAnswersMaryKay = {
  consultant: 'Consultant',
  seniorConsultant: 'Senior Consultant',
  starTeamBuilder: 'Star Team Builder',
  teamLeader: 'Team Leader',
  eliteTeamLeader: 'Elite Team Leader',
  salesDirector: 'Sales Director',
  seniorSalesDirector: 'Senior Sales Director',
  futureExecutiveSenior: 'Future Executive Senior',
  executiveSeniorDirector: 'Executive Senior Director',
  eliteExecutiveSenior: 'Elite Executive Senior',
  nationalSalesDirector: 'National Sales Director',
  seniorNationalSalesDirector: 'Senior National Sales Director',
  executiveNationalSalesDirector: 'Executive National Sales Director',
  eliteExecutiveNationalSalesDirector:
    'Elite Executive National Sales Director',
}

export const TeamSizeAnswersByRankMaryKay = {
  [RankAnswersMaryKay.consultant]: TeamSizeAnswers.o1_10,
  [RankAnswersMaryKay.seniorConsultant]: TeamSizeAnswers.o1_10,
  [RankAnswersMaryKay.starTeamBuilder]: TeamSizeAnswers.o1_10,
  [RankAnswersMaryKay.teamLeader]: TeamSizeAnswers.o11_50,
  [RankAnswersMaryKay.eliteTeamLeader]: TeamSizeAnswers.o11_50,
  [RankAnswersMaryKay.salesDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersMaryKay.seniorSalesDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersMaryKay.futureExecutiveSenior]: TeamSizeAnswers.o101_200,
  [RankAnswersMaryKay.executiveSeniorDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersMaryKay.eliteExecutiveSenior]: TeamSizeAnswers.o201_300,
  [RankAnswersMaryKay.nationalSalesDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersMaryKay.seniorNationalSalesDirector]: TeamSizeAnswers.o501,
  [RankAnswersMaryKay.executiveNationalSalesDirector]: TeamSizeAnswers.o501,
  [RankAnswersMaryKay.eliteExecutiveNationalSalesDirector]:
    TeamSizeAnswers.o501,
}

export const TimeActiveAnswersByRankMaryKay = {
  [RankAnswersMaryKay.consultant]: TimeAnswers.o0_3,
  [RankAnswersMaryKay.seniorConsultant]: TimeAnswers.o0_3,
  [RankAnswersMaryKay.starTeamBuilder]: TimeAnswers.o0_3,
  [RankAnswersMaryKay.teamLeader]: TimeAnswers.o4_12,
  [RankAnswersMaryKay.eliteTeamLeader]: TimeAnswers.o4_12,
  [RankAnswersMaryKay.salesDirector]: TimeAnswers.o4_12,
  [RankAnswersMaryKay.seniorSalesDirector]: TimeAnswers.o4_12,
  [RankAnswersMaryKay.futureExecutiveSenior]: TimeAnswers.o12_36,
  [RankAnswersMaryKay.executiveSeniorDirector]: TimeAnswers.o12_36,
  [RankAnswersMaryKay.eliteExecutiveSenior]: TimeAnswers.o12_36,
  [RankAnswersMaryKay.nationalSalesDirector]: TimeAnswers.o48,
  [RankAnswersMaryKay.seniorNationalSalesDirector]: TimeAnswers.o48,
  [RankAnswersMaryKay.executiveNationalSalesDirector]: TimeAnswers.o48,
  [RankAnswersMaryKay.eliteExecutiveNationalSalesDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- SeneGence ----------------------
// ------------------------------------------------------
export const RankAnswersSeneGence = {
  distributor: 'Distributor',
  maiden: 'Maiden',
  royal: 'Royal',
  lady: 'Lady',
  countess: 'Countess',
  dutchess: 'Dutchess',
  princess: 'Princess',
  crownPrincess: 'Crown Princess',
  queen: 'Queen',
  empress: 'Empress',
  monarch: 'Monarch',
  majesty: 'Majesty',
  highness: 'Highness',
}

export const TeamSizeAnswersByRankSeneGence = {
  [RankAnswersSeneGence.distributor]: TeamSizeAnswers.o1_10,
  [RankAnswersSeneGence.maiden]: TeamSizeAnswers.o1_10,
  [RankAnswersSeneGence.royal]: TeamSizeAnswers.o1_10,
  [RankAnswersSeneGence.lady]: TeamSizeAnswers.o11_50,
  [RankAnswersSeneGence.countess]: TeamSizeAnswers.o11_50,
  [RankAnswersSeneGence.dutchess]: TeamSizeAnswers.o51_100,
  [RankAnswersSeneGence.princess]: TeamSizeAnswers.o51_100,
  [RankAnswersSeneGence.crownPrincess]: TeamSizeAnswers.o101_200,
  [RankAnswersSeneGence.queen]: TeamSizeAnswers.o101_200,
  [RankAnswersSeneGence.empress]: TeamSizeAnswers.o201_300,
  [RankAnswersSeneGence.monarch]: TeamSizeAnswers.o201_300,
  [RankAnswersSeneGence.majesty]: TeamSizeAnswers.o301_500,
  [RankAnswersSeneGence.highness]: TeamSizeAnswers.o301_500,
}

export const TimeAnswersByRankSeneGence = {
  [RankAnswersSeneGence.distributor]: TimeAnswers.o0_3,
  [RankAnswersSeneGence.maiden]: TimeAnswers.o0_3,
  [RankAnswersSeneGence.royal]: TimeAnswers.o0_3,
  [RankAnswersSeneGence.lady]: TimeAnswers.o4_12,
  [RankAnswersSeneGence.countess]: TimeAnswers.o4_12,
  [RankAnswersSeneGence.dutchess]: TimeAnswers.o4_12,
  [RankAnswersSeneGence.princess]: TimeAnswers.o4_12,
  [RankAnswersSeneGence.crownPrincess]: TimeAnswers.o12_36,
  [RankAnswersSeneGence.queen]: TimeAnswers.o12_36,
  [RankAnswersSeneGence.empress]: TimeAnswers.o12_36,
  [RankAnswersSeneGence.monarch]: TimeAnswers.o12_36,
  [RankAnswersSeneGence.majesty]: TimeAnswers.o48,
  [RankAnswersSeneGence.highness]: TimeAnswers.o48,
}

//-----------------------------------------------------
// ------------------- Monat --------------------------
// ------------------------------------------------------
export const RankAnswersMonat = {
  marketPartner: 'Market Partner',
  associateMarketBuilder: 'Associate Market Builder',
  marketBuilder: 'Market Builder',
  managingMarketBuilder: 'Managing Market Builder',
  associateMarketMentor: 'Associate Market Mentor',
  marketMentor: 'Market Mentor',
  managingMarketMentor: 'Managing Market Mentor',
  associateExecutiveDirector: 'Associate Executive Director',
  executiveDirector: 'Executive Director',
  seniorExecutiveDirector: 'Senior Executive Director',
}

export const TeamSizeAnswersByRankMonat = {
  [RankAnswersMonat.marketPartner]: TeamSizeAnswers.o1_10,
  [RankAnswersMonat.associateMarketBuilder]: TeamSizeAnswers.o1_10,
  [RankAnswersMonat.marketBuilder]: TeamSizeAnswers.o11_50,
  [RankAnswersMonat.managingMarketBuilder]: TeamSizeAnswers.o11_50,
  [RankAnswersMonat.associateMarketMentor]: TeamSizeAnswers.o51_100,
  [RankAnswersMonat.marketMentor]: TeamSizeAnswers.o101_200,
  [RankAnswersMonat.managingMarketMentor]: TeamSizeAnswers.o101_200,
  [RankAnswersMonat.associateExecutiveDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersMonat.executiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersMonat.seniorExecutiveDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankMonat = {
  [RankAnswersMonat.marketPartner]: TimeAnswers.o0_3,
  [RankAnswersMonat.associateMarketBuilder]: TimeAnswers.o0_3,
  [RankAnswersMonat.marketBuilder]: TimeAnswers.o4_12,
  [RankAnswersMonat.managingMarketBuilder]: TimeAnswers.o4_12,
  [RankAnswersMonat.associateMarketMentor]: TimeAnswers.o4_12,
  [RankAnswersMonat.marketMentor]: TimeAnswers.o12_36,
  [RankAnswersMonat.managingMarketMentor]: TimeAnswers.o12_36,
  [RankAnswersMonat.associateExecutiveDirector]: TimeAnswers.o12_36,
  [RankAnswersMonat.executiveDirector]: TimeAnswers.o48,
  [RankAnswersMonat.seniorExecutiveDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- DoTerra ------------------------
// ------------------------------------------------------
export const RankAnswersDoTerra = {
  wellnessAdvocate: 'Wellness Advocate',
  manager: 'Manager',
  director: 'Director',
  executive: 'Executive',
  elite: 'Elite',
  premier: 'Premier',
  silver: 'Silver',
  gold: 'Gold',
  platinum: 'Platinum',
  diamond: 'Diamond',
  blueDiamond: 'Blue Diamond',
  presidentialDiamond: 'Presidential Diamond',
}

export const TeamSizeAnswersByRankDoTerra = {
  [RankAnswersDoTerra.wellnessAdvocate]: TeamSizeAnswers.o1_10,
  [RankAnswersDoTerra.manager]: TeamSizeAnswers.o1_10,
  [RankAnswersDoTerra.director]: TeamSizeAnswers.o11_50,
  [RankAnswersDoTerra.executive]: TeamSizeAnswers.o11_50,
  [RankAnswersDoTerra.elite]: TeamSizeAnswers.o51_100,
  [RankAnswersDoTerra.premier]: TeamSizeAnswers.o51_100,
  [RankAnswersDoTerra.silver]: TeamSizeAnswers.o101_200,
  [RankAnswersDoTerra.gold]: TeamSizeAnswers.o101_200,
  [RankAnswersDoTerra.platinum]: TeamSizeAnswers.o201_300,
  [RankAnswersDoTerra.diamond]: TeamSizeAnswers.o301_500,
  [RankAnswersDoTerra.blueDiamond]: TeamSizeAnswers.o301_500,
  [RankAnswersDoTerra.presidentialDiamond]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankDoTerra = {
  [RankAnswersDoTerra.wellnessAdvocate]: TimeAnswers.o0_3,
  [RankAnswersDoTerra.manager]: TimeAnswers.o0_3,
  [RankAnswersDoTerra.director]: TimeAnswers.o4_12,
  [RankAnswersDoTerra.executive]: TimeAnswers.o4_12,
  [RankAnswersDoTerra.elite]: TimeAnswers.o4_12,
  [RankAnswersDoTerra.premier]: TimeAnswers.o4_12,
  [RankAnswersDoTerra.silver]: TimeAnswers.o12_36,
  [RankAnswersDoTerra.gold]: TimeAnswers.o12_36,
  [RankAnswersDoTerra.platinum]: TimeAnswers.o12_36,
  [RankAnswersDoTerra.diamond]: TimeAnswers.o48,
  [RankAnswersDoTerra.blueDiamond]: TimeAnswers.o48,
  [RankAnswersDoTerra.presidentialDiamond]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Limelife ------------------------
// ------------------------------------------------------
export const RankAnswersLimelife = {
  beautyGuide: 'Beauty Guide',
  starBeautyGuide: 'Star Beauty Guide',
  seniorBeautyGuide: 'Senior Beauty Guide',
  director: 'Director',
  leadDirector: 'Lead Director',
  starDirector: 'Star Director',
  seniorDirector: 'Senior Director',
  executiveDirector: 'Executive Director',
  leadExecutiveDirector: 'Lead Executive Director',
  starExecutiveDirector: 'Star Executive Director',
  seniorExecutiveDirector: 'Senior Executive Director',
}

export const TeamSizeAnswersByRankLimelife = {
  [RankAnswersLimelife.beautyGuide]: TeamSizeAnswers.o1_10,
  [RankAnswersLimelife.starBeautyGuide]: TeamSizeAnswers.o1_10,
  [RankAnswersLimelife.seniorBeautyGuide]: TeamSizeAnswers.o1_10,
  [RankAnswersLimelife.director]: TeamSizeAnswers.o11_50,
  [RankAnswersLimelife.leadDirector]: TeamSizeAnswers.o11_50,
  [RankAnswersLimelife.starDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersLimelife.seniorDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersLimelife.executiveDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersLimelife.leadExecutiveDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersLimelife.starExecutiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersLimelife.seniorExecutiveDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankLimelife = {
  [RankAnswersLimelife.beautyGuide]: TimeAnswers.o0_3,
  [RankAnswersLimelife.starBeautyGuide]: TimeAnswers.o0_3,
  [RankAnswersLimelife.seniorBeautyGuide]: TimeAnswers.o0_3,
  [RankAnswersLimelife.director]: TimeAnswers.o4_12,
  [RankAnswersLimelife.leadDirector]: TimeAnswers.o4_12,
  [RankAnswersLimelife.starDirector]: TimeAnswers.o4_12,
  [RankAnswersLimelife.seniorDirector]: TimeAnswers.o12_36,
  [RankAnswersLimelife.executiveDirector]: TimeAnswers.o12_36,
  [RankAnswersLimelife.leadExecutiveDirector]: TimeAnswers.o12_36,
  [RankAnswersLimelife.starExecutiveDirector]: TimeAnswers.o48,
  [RankAnswersLimelife.seniorExecutiveDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Melaleuca ----------------------
// ------------------------------------------------------
export const RankAnswersMelaleuca = {
  marketingExecutive: 'Marketing Executive',
  marketingDirector: 'Marketing Director',
  seniorMarketingDirector: 'Senior Marketing Director',
  executiveDirector: 'Executive Director',
  nationalDirector: 'National Director',
  corporateDirector: 'Corporate Director',
  presidentialDirector: 'Presidential Director',
}

export const TeamSizeAnswersByRankMelaleuca = {
  [RankAnswersMelaleuca.marketingExecutive]: TeamSizeAnswers.o1_10,
  [RankAnswersMelaleuca.marketingDirector]: TeamSizeAnswers.o11_50,
  [RankAnswersMelaleuca.seniorMarketingDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersMelaleuca.executiveDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersMelaleuca.nationalDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersMelaleuca.corporateDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersMelaleuca.presidentialDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankMelaleuca = {
  [RankAnswersMelaleuca.marketingExecutive]: TimeAnswers.o0_3,
  [RankAnswersMelaleuca.marketingDirector]: TimeAnswers.o4_12,
  [RankAnswersMelaleuca.seniorMarketingDirector]: TimeAnswers.o4_12,
  [RankAnswersMelaleuca.executiveDirector]: TimeAnswers.o12_36,
  [RankAnswersMelaleuca.nationalDirector]: TimeAnswers.o12_36,
  [RankAnswersMelaleuca.corporateDirector]: TimeAnswers.o48,
  [RankAnswersMelaleuca.presidentialDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Scentsy ------------------------
// ------------------------------------------------------
export const RankAnswersScentsy = {
  essentialConsultant: 'Essential Consultant',
  certifiedConsultant: 'Certified Consultant',
  leadConsultant: 'Lead Consultant',
  starConsultant: 'Star Consultant',
  superstarConsultant: 'Superstar Consultant',
  director: 'Director',
  starDirector: 'Star Director',
  superstarDirector: 'Superstar Director',
}

export const TeamSizeAnswersByRankScentsy = {
  [RankAnswersScentsy.essentialConsultant]: TeamSizeAnswers.o1_10,
  [RankAnswersScentsy.certifiedConsultant]: TeamSizeAnswers.o1_10,
  [RankAnswersScentsy.leadConsultant]: TeamSizeAnswers.o11_50,
  [RankAnswersScentsy.starConsultant]: TeamSizeAnswers.o51_100,
  [RankAnswersScentsy.superstarConsultant]: TeamSizeAnswers.o101_200,
  [RankAnswersScentsy.director]: TeamSizeAnswers.o201_300,
  [RankAnswersScentsy.starDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersScentsy.superstarDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankScentsy = {
  [RankAnswersScentsy.essentialConsultant]: TimeAnswers.o0_3,
  [RankAnswersScentsy.certifiedConsultant]: TimeAnswers.o0_3,
  [RankAnswersScentsy.leadConsultant]: TimeAnswers.o4_12,
  [RankAnswersScentsy.starConsultant]: TimeAnswers.o4_12,
  [RankAnswersScentsy.superstarConsultant]: TimeAnswers.o12_36,
  [RankAnswersScentsy.director]: TimeAnswers.o12_36,
  [RankAnswersScentsy.starDirector]: TimeAnswers.o48,
  [RankAnswersScentsy.superstarDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Pure Romance -------------------
// ------------------------------------------------------
export const RankAnswersPureRomance = {
  partner: 'Partner',
  advancedPartner: 'Advanced Partner',
  seniorPartner: 'Senior Partner',
  associateDirector: 'Associate Director',
  director: 'Director',
  advancedDirector: 'Advanced Director',
  seniorDirector: 'Senior Director',
  nationalDirector: 'National Director',
  seniorNationalDirector: 'Senior National Director',
  executiveDirector: 'Executive Director',
  seniorExecutiveDirector: 'Senior Executive Director',
}

export const TeamSizeAnswersByRankPureRomance = {
  [RankAnswersPureRomance.partner]: TeamSizeAnswers.o1_10,
  [RankAnswersPureRomance.advancedPartner]: TeamSizeAnswers.o1_10,
  [RankAnswersPureRomance.seniorPartner]: TeamSizeAnswers.o11_50,
  [RankAnswersPureRomance.associateDirector]: TeamSizeAnswers.o11_50,
  [RankAnswersPureRomance.director]: TeamSizeAnswers.o51_100,
  [RankAnswersPureRomance.advancedDirector]: TeamSizeAnswers.o51_100,
  [RankAnswersPureRomance.seniorDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersPureRomance.nationalDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersPureRomance.seniorNationalDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersPureRomance.executiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersPureRomance.seniorExecutiveDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankPureRomance = {
  [RankAnswersPureRomance.partner]: TimeAnswers.o0_3,
  [RankAnswersPureRomance.advancedPartner]: TimeAnswers.o0_3,
  [RankAnswersPureRomance.seniorPartner]: TimeAnswers.o4_12,
  [RankAnswersPureRomance.associateDirector]: TimeAnswers.o4_12,
  [RankAnswersPureRomance.director]: TimeAnswers.o4_12,
  [RankAnswersPureRomance.advancedDirector]: TimeAnswers.o4_12,
  [RankAnswersPureRomance.seniorDirector]: TimeAnswers.o12_36,
  [RankAnswersPureRomance.nationalDirector]: TimeAnswers.o12_36,
  [RankAnswersPureRomance.seniorNationalDirector]: TimeAnswers.o12_36,
  [RankAnswersPureRomance.executiveDirector]: TimeAnswers.o48,
  [RankAnswersPureRomance.seniorExecutiveDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Tranont ------------------------
// ------------------------------------------------------
export const RankAnswersTranont = {
  quartz: 'Quartz',
  onyx: 'Onyx',
  jade: 'Jade',
  opal: 'Opal',
  pearl: 'Pearl',
  ruby: 'Ruby',
  sapphire: 'Sapphire',
  emerald: 'Emerald',
  diamond: 'Diamond',
  blackDiamond: 'Black Diamond',
  redDiamond: 'Red Diamond',
  blueDiamond: 'Blue Diamond',
  executiveBlueDiamond: 'Executive Blue Diamond',
  presidentialBlueDiamond: 'Presidential Blue Diamond',
}

export const TeamSizeAnswersByRankTranont = {
  [RankAnswersTranont.quartz]: TeamSizeAnswers.o1_10,
  [RankAnswersTranont.onyx]: TeamSizeAnswers.o1_10,
  [RankAnswersTranont.jade]: TeamSizeAnswers.o11_50,
  [RankAnswersTranont.opal]: TeamSizeAnswers.o11_50,
  [RankAnswersTranont.pearl]: TeamSizeAnswers.o51_100,
  [RankAnswersTranont.ruby]: TeamSizeAnswers.o51_100,
  [RankAnswersTranont.sapphire]: TeamSizeAnswers.o101_200,
  [RankAnswersTranont.emerald]: TeamSizeAnswers.o101_200,
  [RankAnswersTranont.diamond]: TeamSizeAnswers.o201_300,
  [RankAnswersTranont.blackDiamond]: TeamSizeAnswers.o201_300,
  [RankAnswersTranont.redDiamond]: TeamSizeAnswers.o301_500,
  [RankAnswersTranont.blueDiamond]: TeamSizeAnswers.o301_500,
  [RankAnswersTranont.executiveBlueDiamond]: TeamSizeAnswers.o301_500,
  [RankAnswersTranont.presidentialBlueDiamond]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankTranont = {
  [RankAnswersTranont.quartz]: TimeAnswers.o0_3,
  [RankAnswersTranont.onyx]: TimeAnswers.o0_3,
  [RankAnswersTranont.jade]: TimeAnswers.o4_12,
  [RankAnswersTranont.opal]: TimeAnswers.o4_12,
  [RankAnswersTranont.pearl]: TimeAnswers.o4_12,
  [RankAnswersTranont.ruby]: TimeAnswers.o4_12,
  [RankAnswersTranont.sapphire]: TimeAnswers.o12_36,
  [RankAnswersTranont.emerald]: TimeAnswers.o12_36,
  [RankAnswersTranont.diamond]: TimeAnswers.o12_36,
  [RankAnswersTranont.blackDiamond]: TimeAnswers.o12_36,
  [RankAnswersTranont.redDiamond]: TimeAnswers.o48,
  [RankAnswersTranont.blueDiamond]: TimeAnswers.o48,
  [RankAnswersTranont.executiveBlueDiamond]: TimeAnswers.o48,
  [RankAnswersTranont.presidentialBlueDiamond]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Tupeerware ---------------------
// ------------------------------------------------------
export const RankAnswersTupeerware = {
  consultant: 'Consultant',
  manager: 'Manager',
  starManager: 'Star Manager',
  executiveManager: 'Executive Manager',
  director: 'Director',
  starDirector: 'Star Director',
  twoStarDirector: '2 Star Director',
  threeStarDirector: '3 Star Director',
  fiveStarDirector: '5 Star Director',
  executiveDirector: 'Executive Director',
  starExecutiveDirector: 'Star Executive Director',
  presidentialDirector: 'Presidential Director',
}

export const TeamSizeAnswersByRankTupeerware = {
  [RankAnswersTupeerware.consultant]: TeamSizeAnswers.o0,
  [RankAnswersTupeerware.manager]: TeamSizeAnswers.o1_10,
  [RankAnswersTupeerware.starManager]: TeamSizeAnswers.o11_50,
  [RankAnswersTupeerware.executiveManager]: TeamSizeAnswers.o51_100,
  [RankAnswersTupeerware.director]: TeamSizeAnswers.o51_100,
  [RankAnswersTupeerware.starDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersTupeerware.twoStarDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersTupeerware.threeStarDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersTupeerware.fiveStarDirector]: TeamSizeAnswers.o201_300,
  [RankAnswersTupeerware.executiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersTupeerware.starExecutiveDirector]: TeamSizeAnswers.o301_500,
  [RankAnswersTupeerware.presidentialDirector]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankTupeerware = {
  [RankAnswersTupeerware.consultant]: TimeAnswers.o0_3,
  [RankAnswersTupeerware.manager]: TimeAnswers.o0_3,
  [RankAnswersTupeerware.starManager]: TimeAnswers.o4_12,
  [RankAnswersTupeerware.executiveManager]: TimeAnswers.o4_12,
  [RankAnswersTupeerware.director]: TimeAnswers.o4_12,
  [RankAnswersTupeerware.starDirector]: TimeAnswers.o12_36,
  [RankAnswersTupeerware.twoStarDirector]: TimeAnswers.o12_36,
  [RankAnswersTupeerware.threeStarDirector]: TimeAnswers.o12_36,
  [RankAnswersTupeerware.fiveStarDirector]: TimeAnswers.o12_36,
  [RankAnswersTupeerware.executiveDirector]: TimeAnswers.o48,
  [RankAnswersTupeerware.starExecutiveDirector]: TimeAnswers.o48,
  [RankAnswersTupeerware.presidentialDirector]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Color Street -------------------
// ------------------------------------------------------
export const RankAnswersColorStreet = {
  stylist: 'Stylist',
  qualifiedStylist: 'Qualified Stylist',
  bronzeStylist: 'Bronze Stylist',
  silverStylist: 'Silver Stylist',
  goldStylist: 'Gold Stylist',
  platinumStylist: 'Platinum Stylist',
  bronzeLeader: 'Bronze Leader',
  silverLeader: 'Silver Leader',
  goldLeader: 'Gold Leader',
  platinumLeader: 'Platinum Leader',
  silverExecutive: 'Silver Executive',
  goldExecutive: 'Gold Executive',
  platinumExecutive: 'Platinum Executive',
  topazNational: 'Topaz National',
  sapphireNational: 'Sapphire National',
  rubyNational: 'Ruby National',
  emeraldNational: 'Emerald National',
  diamondNational: 'Diamond National',
}

export const TeamSizeAnswersByRankColorStreet = {
  [RankAnswersColorStreet.stylist]: TeamSizeAnswers.o1_10,
  [RankAnswersColorStreet.qualifiedStylist]: TeamSizeAnswers.o1_10,
  [RankAnswersColorStreet.bronzeStylist]: TeamSizeAnswers.o1_10,
  [RankAnswersColorStreet.silverStylist]: TeamSizeAnswers.o1_10,
  [RankAnswersColorStreet.goldStylist]: TeamSizeAnswers.o11_50,
  [RankAnswersColorStreet.platinumStylist]: TeamSizeAnswers.o11_50,
  [RankAnswersColorStreet.bronzeLeader]: TeamSizeAnswers.o51_100,
  [RankAnswersColorStreet.silverLeader]: TeamSizeAnswers.o101_200,
  [RankAnswersColorStreet.goldLeader]: TeamSizeAnswers.o101_200,
  [RankAnswersColorStreet.platinumLeader]: TeamSizeAnswers.o101_200,
  [RankAnswersColorStreet.silverExecutive]: TeamSizeAnswers.o201_300,
  [RankAnswersColorStreet.goldExecutive]: TeamSizeAnswers.o201_300,
  [RankAnswersColorStreet.platinumExecutive]: TeamSizeAnswers.o201_300,
  [RankAnswersColorStreet.topazNational]: TeamSizeAnswers.o301_500,
  [RankAnswersColorStreet.sapphireNational]: TeamSizeAnswers.o301_500,
  [RankAnswersColorStreet.rubyNational]: TeamSizeAnswers.o301_500,
  [RankAnswersColorStreet.emeraldNational]: TeamSizeAnswers.o301_500,
  [RankAnswersColorStreet.diamondNational]: TeamSizeAnswers.o501,
}

export const TimeAnswersByRankColorStreet = {
  [RankAnswersColorStreet.stylist]: TimeAnswers.o0_3,
  [RankAnswersColorStreet.qualifiedStylist]: TimeAnswers.o0_3,
  [RankAnswersColorStreet.bronzeStylist]: TimeAnswers.o0_3,
  [RankAnswersColorStreet.silverStylist]: TimeAnswers.o0_3,
  [RankAnswersColorStreet.goldStylist]: TimeAnswers.o4_12,
  [RankAnswersColorStreet.platinumStylist]: TimeAnswers.o4_12,
  [RankAnswersColorStreet.bronzeLeader]: TimeAnswers.o4_12,
  [RankAnswersColorStreet.silverLeader]: TimeAnswers.o12_36,
  [RankAnswersColorStreet.goldLeader]: TimeAnswers.o12_36,
  [RankAnswersColorStreet.platinumLeader]: TimeAnswers.o12_36,
  [RankAnswersColorStreet.silverExecutive]: TimeAnswers.o12_36,
  [RankAnswersColorStreet.goldExecutive]: TimeAnswers.o12_36,
  [RankAnswersColorStreet.platinumExecutive]: TimeAnswers.o12_36,
  [RankAnswersColorStreet.topazNational]: TimeAnswers.o48,
  [RankAnswersColorStreet.sapphireNational]: TimeAnswers.o48,
  [RankAnswersColorStreet.rubyNational]: TimeAnswers.o48,
  [RankAnswersColorStreet.emeraldNational]: TimeAnswers.o48,
  [RankAnswersColorStreet.diamondNational]: TimeAnswers.o48,
}

// ------------------------------------------------------
// ------------------- Thirty-One Gifts -----------------
// ------------------------------------------------------
export const RankAnswersThirtyOneGifts = {
  consultant: 'Consultant',
  director: 'Director',
  seniorDirector: 'Senior Director',
  executiveDirector: 'Executive Director',
}

// Mapping of team size answers by rank
export const TeamSizeAnswersByRankThirtyOneGifts = {
  [RankAnswersThirtyOneGifts.consultant]: TeamSizeAnswers.o1_10,
  [RankAnswersThirtyOneGifts.director]: TeamSizeAnswers.o51_100,
  [RankAnswersThirtyOneGifts.seniorDirector]: TeamSizeAnswers.o101_200,
  [RankAnswersThirtyOneGifts.executiveDirector]: TeamSizeAnswers.o501,
}

// Mapping of time answers by rank
export const TimeAnswersByRankThirtyOneGifts = {
  [RankAnswersThirtyOneGifts.consultant]: TimeAnswers.o0_3,
  [RankAnswersThirtyOneGifts.director]: TimeAnswers.o4_12,
  [RankAnswersThirtyOneGifts.seniorDirector]: TimeAnswers.o12_36,
  [RankAnswersThirtyOneGifts.executiveDirector]: TimeAnswers.o48,
}
