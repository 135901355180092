import { useCallback } from 'react'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { UiUpdatesMessage } from '../../../models'
import { updatesMessageById, updatesMessageCount } from '../../../../selectors'

import { useUpdatesApi } from './useUpdatesApi'
import { useAuth } from '../../../../hooks/useAuth'

type UpdatesMessageState = {
  message: UiUpdatesMessage
  count: number
  canRemove: boolean
  select: () => void
  unselect: () => void
  remove: () => void
  read: () => void
}

export const useUpdatesMessage = (
  messageId: string | null,
): UpdatesMessageState => {
  const { user } = useAuth()

  const message = useSelectorFactory(updatesMessageById, messageId)
  const count = useSelectorFactory(updatesMessageCount, message)
  const { selectMessage, removeMessage, markAsRead } = useUpdatesApi()

  const canRemove =
    user && message && message.sender && user.uid === message.sender.id

  const select = useCallback(() => {
    selectMessage(message)
  }, [selectMessage, message])

  const unselect = useCallback(() => {
    selectMessage(null)
  }, [selectMessage])

  const remove = useCallback(
    () => removeMessage(message),
    [removeMessage, message],
  )
  const read = useCallback(() => {
    markAsRead(message)
  }, [markAsRead, message])

  return {
    message,
    count,
    canRemove,

    remove,
    select,
    unselect,
    read,
  } as const
}
