import { FC, PropsWithChildren } from 'react'

import styles from './AccountPageTitle.module.css'

const AccountPageTitle: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.Root}>
    <h3 className={styles.Title}>{children}</h3>
  </div>
)

export default AccountPageTitle
