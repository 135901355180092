import React, {
  ElementType,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { createPortal } from 'react-dom'
import { useResizeElementHandler } from '../hooks/useResizeElementHandler'
import useBodyResizeHandler from '../hooks/useBodyResizeHandler'
import { TooltipPositions } from '../models/tooltip'

import styles from './Tooltip.module.css'

type Position = {
  top: string
  left: string
}
type Props = PropsWithChildren<{
  data: JSX.Element | string
  disabled?: boolean
  offset?: number
  position?: TooltipPositions
  style?: {
    [key: string]: string
  }
  isOpen?: boolean
  className?: string
  onHover?: () => void
  followCursorY?: boolean
  followCursorX?: boolean
  getRelativePosition?: () => {
    top: number
    left: number
  }
  as?: ElementType
  container?: HTMLElement
}>

const Tooltip: FC<PropsWithChildren<Props>> = ({
  children,
  data,
  disabled = false,
  position = TooltipPositions.BOTTOM,
  onHover,
  className,
  isOpen,
  followCursorY = false,
  followCursorX = false,
  as: Tag = 'span',
  getRelativePosition,
  container,
  ...rest
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<HTMLElement>(null)

  const [isActive, setIsActive] = useState<boolean>(false)
  const [calculatedStyles, setCalculatedStyles] = useState<Position>({
    top: '0px',
    left: '0px',
  })

  const calculateTargetPosition = useCallback(
    (target: HTMLElement): Position => {
      const rect = target.getBoundingClientRect()
      let { top, left } = rect
      const { height, width } = rect

      if (getRelativePosition) {
        const relativePosition = getRelativePosition()

        top -= relativePosition.top
        left -= relativePosition.left
      }

      if (position === TooltipPositions.TOP) {
        return { top: `${top}px`, left: `${left + width / 2}px` }
      }

      if (position === TooltipPositions.BOTTOM) {
        return {
          top: `${top + height}px`,
          left: `${left + width / 2}px`,
        }
      }

      if (position === TooltipPositions.LEFT) {
        return { top: `${top + height / 2}px`, left: `${left}px` }
      }

      if (position === TooltipPositions.RIGHT) {
        return {
          top: `${top + height / 2}px`,
          left: `${left + width}px`,
        }
      }

      if (position === TooltipPositions.BOTTOM_LEFT) {
        return {
          top: `${top + height}px`,
          left: `${left - 6}px`,
        }
      }

      if (position === TooltipPositions.BOTTOM_RIGHT) {
        return {
          top: `${top + height}px`,
          left: `${left - width}px`,
        }
      }

      return {
        top: `${top + height}px`,
        left: `${left + width / 2}px`,
      }
    },
    [position, getRelativePosition],
  )

  const calculateEventPosition = (
    calculatedPosition: Position,
    e: MouseEvent,
  ): Position => {
    if (followCursorY) {
      return {
        ...calculatedPosition,
        top: `${e.pageY}px`,
      }
    }

    if (followCursorX) {
      return {
        ...calculatedPosition,
        left: `${e.pageX}px`,
      }
    }
    return calculatedPosition
  }

  const updatePosition = useCallback(
    (updatedPosition: Position) => {
      if (
        updatedPosition.top === calculatedStyles.top &&
        updatedPosition.left === calculatedStyles.left
      ) {
        return
      }

      setCalculatedStyles(updatedPosition)
    },
    [calculatedStyles, setCalculatedStyles],
  )

  const mouseOver = (e: MouseEvent) => {
    if (!targetRef.current) {
      return
    }
    const calculatedPosition = calculateTargetPosition(targetRef.current)
    const finalPosition = calculateEventPosition(calculatedPosition, e)

    if (disabled || !e.target) {
      return
    }

    if (onHover) {
      onHover()
    }

    updatePosition(finalPosition)
    setIsActive(true)
  }
  const mouseLeave: MouseEventHandler<HTMLSpanElement> = () => {
    if (disabled) {
      return
    }
    setIsActive(false)
  }

  useEffect(() => {
    if (typeof isOpen === 'undefined' || !targetRef.current) {
      return
    }

    const calculatedPosition = calculateTargetPosition(
      targetRef.current as HTMLElement,
    )
    updatePosition(calculatedPosition)
    setIsActive(isOpen)
  }, [isOpen, calculateTargetPosition, updatePosition])

  const resizeHandler = () => {
    if (!targetRef.current || (typeof isOpen === 'boolean' && !isOpen)) {
      return
    }

    const calculatedPosition = calculateTargetPosition(targetRef.current)
    updatePosition(calculatedPosition)
  }

  useResizeElementHandler(targetRef, resizeHandler)
  useBodyResizeHandler(resizeHandler)

  return (
    <>
      <Tag
        ref={targetRef}
        className={className}
        onMouseMove={mouseOver}
        onPointerLeave={mouseLeave}
        {...rest}
      >
        {children}
      </Tag>
      {isActive &&
        createPortal(
          <div
            className={[styles.TooltipPosition, styles[position]].join(' ')}
            style={calculatedStyles}
            ref={tooltipRef}
          >
            {data}
          </div>,
          container || targetRef.current?.parentElement || document.body,
        )}
    </>
  )
}

type TooltipContentProps = PropsWithChildren<{
  className?: string
  icon?: React.ReactNode
  disabled?: boolean
}>

/** @deprecated Use "TooltipContent" instead from "boards-web-ui" */
export const TooltipContent: FC<PropsWithChildren<TooltipContentProps>> = ({
  className,
  children,
  disabled = false,
  ...rest
}) => {
  if (disabled) return null
  return (
    <span className={[styles.Default, className].join(' ')} {...rest}>
      {children}
    </span>
  )
}

// TODO add in boards-web-ui "createPortal" for container
/** @deprecated Use "Tooltip" instead from "boards-web-ui" */
export default React.memo(Tooltip)
