import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { firebaseEventsAsyncActions } from '../actions'
import useIsOnline from './useIsOnline'
import { useToast } from './useToast'

const useIsApiAvailableOrNotify = () => {
  const isOnline = useIsOnline()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const toast = useToast()
  const { actionInProgress } = useSelector((state) => state.app)

  return () => {
    if (!isOnline) {
      dispatch(firebaseEventsAsyncActions.logEvent('app_connection_error'))
      toast(t('toast_no_internet_connection'))
      return false
    }

    if (actionInProgress) {
      dispatch(firebaseEventsAsyncActions.logEvent('app_action_block'))
      toast(t('optimistic_ui_failed'))
      return false
    }

    return true
  }
}

export default useIsApiAvailableOrNotify
