import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { urlHelper } from 'boards-web-ui'

import { AfterSubmitBehaviourEnum } from '@features/forms/constants'
import useFormsEvents from '@features/analytics/useFormsEvents'

import {
  CHECKOUT_POST_SUBMITTING_BEHAVIOUR,
  DEFAULT_POST_SUBMISSION_MESSAGE,
} from '../constants'

type Props = {
  disabled?: boolean
  defaultPostSubmissionBehavior?: AfterSubmitBehaviourEnum
  defaultPostSubmissionMessage?: string
  postSubmissionUrl?: string
  onDisabled?: () => void
}

const DEFAULT_PROPS: Required<Props> = {
  disabled: false,
  defaultPostSubmissionBehavior: CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE,
  defaultPostSubmissionMessage: DEFAULT_POST_SUBMISSION_MESSAGE,
  postSubmissionUrl: '',
  onDisabled: () => {
    throw new Error('onDisabled is not defined')
  },
}
const useSettingsPostSubmission = (props?: Props) => {
  const mergedProps: Required<Props> = {
    ...DEFAULT_PROPS,
    ...props,
  }
  const {
    appFormPostSubmissionRedirectSelect,
    appFormPostSubmissionRedirectEdit,
    appFormPostSubmissionMessageSelect,
    appFormPostSubmissionMessageEdit,
  } = useFormsEvents()
  const { t } = useTranslation()

  const [postSubmissionMessage, setPostSubmissionMessage] = useState<string>(
    t(mergedProps.defaultPostSubmissionMessage),
  )
  const [postSubmissionUrl, setPostSubmissionUrl] = useState<string>(
    mergedProps.postSubmissionUrl,
  )
  const [postSubmissionBehavior, setPostSubmissionBehavior] =
    useState<AfterSubmitBehaviourEnum>(
      mergedProps.defaultPostSubmissionBehavior,
    )

  const postSubmissionError: string = useMemo(() => {
    if (
      postSubmissionBehavior === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
    ) {
      if (
        !postSubmissionUrl.trim().length ||
        !urlHelper.isValidUrl(postSubmissionUrl)
      ) {
        return t('error_invalid_url')
      }
    }

    return ''
  }, [postSubmissionBehavior, postSubmissionUrl, t])

  const onPostSubmissionBehaviourChange = (
    postSubmissionBehaviorType: AfterSubmitBehaviourEnum,
  ) => {
    if (
      postSubmissionBehaviorType === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
    ) {
      appFormPostSubmissionRedirectSelect(true)
    }

    if (
      postSubmissionBehaviorType === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE
    ) {
      appFormPostSubmissionMessageSelect(true)
    }

    return !mergedProps.disabled
      ? setPostSubmissionBehavior(postSubmissionBehaviorType)
      : mergedProps.onDisabled()
  }

  const onPostSubmissionMessageChange = (message: string) => {
    appFormPostSubmissionMessageEdit(true)
    return !mergedProps.disabled
      ? setPostSubmissionMessage(message || t(DEFAULT_POST_SUBMISSION_MESSAGE))
      : mergedProps.onDisabled()
  }

  const onPostSubmissionUrlChange = (url: string) => {
    appFormPostSubmissionRedirectEdit(true)
    return !mergedProps.disabled
      ? setPostSubmissionUrl(url)
      : mergedProps.onDisabled()
  }

  return {
    postSubmissionBehavior,
    onPostSubmissionBehaviourChange,

    postSubmissionMessage,
    onPostSubmissionMessageChange,

    postSubmissionUrl,
    onPostSubmissionUrlChange,

    postSubmissionError,
  } as const
}

export default useSettingsPostSubmission
