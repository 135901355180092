import { FC } from 'react'
import {
  ContentPickersEnum,
  SelectAction,
  useContentSelection,
} from '@features/pickers'
import { PickerModal } from '@ui/components/PickerModal'

import { SelectContentDialogBody } from './SelectContentDialogBody'
import { SelectContentDialogHeader } from './SelectContentDialogHeader'
import { SelectContentDialogFooter } from './SelectContentDialogFooter'

interface Props {
  onClose: () => void
  action: SelectAction
}

export const SourcePickerModal: FC<Props> = ({ onClose, action }) => {
  const {
    nodes,
    clearSelection,
    insertSelection,
    toggleNode,
    showClearMsgIfHasSelectedNodes,
  } = useContentSelection(action)

  return (
    <PickerModal
      onClose={onClose}
      picker={ContentPickersEnum.SourcePicker}
      header={<SelectContentDialogHeader />}
      footer={
        <SelectContentDialogFooter
          selectedNodes={nodes.length}
          clearSelection={clearSelection}
          insertSelection={insertSelection}
        />
      }
      onBeforeGoBack={showClearMsgIfHasSelectedNodes}
    >
      <SelectContentDialogBody
        nodes={nodes}
        toggleNode={toggleNode}
        showClearMsgIfHasSelectedNodes={showClearMsgIfHasSelectedNodes}
      />
    </PickerModal>
  )
}
