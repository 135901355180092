import React, { PropsWithChildren } from 'react'

import styles from './Banner.module.css'

type Props = PropsWithChildren<{
  className?: string
}>
const Banner: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <div className={[styles.Root, className].join(' ')} {...rest}>
      {children}
    </div>
  )
}

export default Banner
