import React, { FC } from 'react'
import { CheckoutIcon, Grid, Icon } from 'boards-web-ui'
import clsx from 'clsx'

import { IconWrapper } from '@ui/components/IconWrapper'

import { Node } from '../../../models'

import styles from './NodeForm.module.css'

type Props = {
  node: Node
}
const NodeCheckoutForm: FC<Props> = ({ node }) => {
  const products =
    node.form?.fields?.filter((field) => field.type === 'product') || []
  const title = `${products?.length} product${products?.length > 1 ? 's' : ''}`
  const text = products?.map((product) => product.label).join(', ')

  return (
    <div className={styles.Root}>
      <Icon size="46px" className={clsx(styles.Icon, styles.CheckoutIcon)}>
        <IconWrapper color={'white'} size={22}>
          <CheckoutIcon />
        </IconWrapper>
      </Icon>

      <Grid
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
      >
        <div className={styles.Title}>{title}</div>
        <div className={styles.Text}>{text}</div>
      </Grid>
    </div>
  )
}

export default React.memo(NodeCheckoutForm)
