import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

const useProfilingEvents = () => {
  const { trigger } = useLogEvents()

  const showProfiling = () => trigger(EventCategories.APP_PROFILING_DISPLAY)

  const answeredTeamLead = () =>
    trigger(EventCategories.APP_PROFILING_ANSWERED_TEAM_LEAD)

  const answerQuestion = (eventName: string, eventValue: string) =>
    trigger(`${eventName}`, { answer: eventValue })

  const complete = () => trigger(EventCategories.APP_PROFILING_COMPLETE)

  return {
    showProfiling,
    answerQuestion,
    complete,
    answeredTeamLead,
  }
}

export default useProfilingEvents
