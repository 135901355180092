export const insertBefore = (arr, index, newItems) => {
  if (index === -1 || index === undefined) {
    return [...arr, ...newItems]
  }

  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]
}

export const move = (arr, from, to) => {
  const clone = [...arr]
  Array.prototype.splice.call(
    clone,
    to,
    0,
    Array.prototype.splice.call(clone, from, 1)[0],
  )
  return clone
}
