import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { randomEmojiExcluding } from '@helpers/randomEmojis'

import { folderActions } from '../../../../actions'
import { generateFolderId } from '../../../../helpers/NodeIdGenerator'
import useBoard from '../../../../hooks/useBoard'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

const useAddFolderOrPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { boardId, folder } = useBoard()

  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const addFolder = (): void => {
    if (!isApiAvailableOrNotify()) return

    const icon = randomEmojiExcluding([])
    const { nodeId, key } = generateFolderId()

    const node = { icon, title: undefined }

    dispatch(folderActions.addFolderFake(boardId, folder?.id, key, node, false))

    navigate(`/${boardId}/${nodeId}`)
  }

  return addFolder
}

export default useAddFolderOrPage
