import { FC, useEffect, useState } from 'react'
import {
  ErrorIndicator,
  ErrorIndicatorSize,
  If,
  isPageNodeCheckoutForm,
  isPageNodeForm,
  TPageNode,
} from 'boards-web-ui'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import usePageEditorEvents from '@features/analytics/usePageEditorEvents'
import {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import useBoardMode from '@features/board/hooks/useBoardMode'
import { SelectAction } from '@features/pickers'

import ScrollArea from 'ui/components/Scroll/ScrollArea'
import { ScrollAreaContainer } from 'ui/models/ScrollArea'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useRouterParams from '@hooks/useRouterParams'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { ProfileFeatures } from '@models/UserProfile'

import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'
import useFormIntegrationStatusState from '@features/forms/hooks/useFormIntegrationStatusState'
import { Folder, Node } from '../../../models'
import { selectMissedPageFields } from '../../../../selectors'

import useTipBanner from '../hooks/useTipBanner'
import PageMenu from './PageMenu'
import PageTitle from './PageTitle'
import PagePreview from './PagePreview'
import PagePreviewHead from './PagePreviewHead'
import ListItems from './ListItems'
import ContentHead from './ContentHead'
import InfoTipBanner from './InfoTipBanner'

import styles from './PageEditor.module.css'

interface Props {
  title: string
  folder: Folder
  updateTitle: (title: string) => void
  createNode: ICreateOrUpdateNode
  updateNode: (id: string, node: INodeProps) => void
  deleteNode?: (node: Node) => void
  onClickDone: () => void
  selectAction: SelectAction
  isNew?: boolean
}

const PageEditor: FC<Props> = ({
  title,
  folder,
  updateTitle,
  createNode,
  updateNode,
  deleteNode,
  onClickDone,
  selectAction,
  isNew,
}) => {
  const { t } = useTranslation()
  const mode = useBoardMode()
  const { viewPageEditorScreen } = usePageEditorEvents()
  const { isTipBannerVisible, handleTemplateBannerDismiss } =
    useTipBanner(folder)
  const { boardId } = useRouterParams()
  const { onGetFormIntegrationStatus } = useFormIntegrationStatusState(
    boardId,
    folder.id || '',
  )

  useEffect(() => {
    viewPageEditorScreen(isNew)
  }, [viewPageEditorScreen, isNew])

  const [containerClassName, setContainerClassName] = useState<string>(
    clsx(styles.Container, styles.WithMarginShift),
  )

  useEffect(() => {
    setContainerClassName(clsx(styles.Container))
  }, [])

  useEffect(() => {
    folder?.content?.forEach((nodeItem) => {
      if (
        (isPageNodeCheckoutForm(nodeItem as TPageNode) ||
          isPageNodeForm(nodeItem as TPageNode)) &&
        (!nodeItem.integrationState ||
          !Object.values(nodeItem.integrationState).length)
      ) {
        onGetFormIntegrationStatus(nodeItem.id)
      }
    })
  }, [folder, onGetFormIntegrationStatus])

  const { getPremiumPreviewPageFolder } = usePagePremiumLimitationMode()
  const { showPremiumPreviewPageLimitationModeBanner, previewPageFolder } =
    getPremiumPreviewPageFolder(folder)

  const { isFeatureAvailable: isCheckoutFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.CHECKOUT)
  const missedPageProperties = useSelectorFactory(
    selectMissedPageFields,
    boardId,
    folder?.id,
    isCheckoutFeatureAvailable,
  )

  if (!folder) return null

  const shouldShowIndicators =
    !isTipBannerVisible &&
    !showPremiumPreviewPageLimitationModeBanner &&
    !!Object.keys(missedPageProperties).length

  return (
    <div className={containerClassName}>
      <div className={styles.Title}>
        <PageTitle
          folder={folder}
          newTitle={title}
          setNewTitle={updateTitle}
          onDoneClick={onClickDone}
        />
      </div>

      <div className={styles.ContentHead}>
        <ContentHead />
      </div>

      <div className={styles.PreviewHead}>
        <PagePreviewHead />
      </div>

      <div className={styles.Content}>
        <If state={shouldShowIndicators}>
          <ErrorIndicator size={ErrorIndicatorSize.MEDIUM}>
            <span>{t('info_missing_page_banner')}</span>&nbsp;
            <span className={styles.Bold}>
              {t('info_missing_page_banner_see_below_placeholder')}
            </span>
          </ErrorIndicator>
        </If>

        <div
          className={clsx(
            styles.EditorContent,
            showPremiumPreviewPageLimitationModeBanner &&
              styles.EditorContentWithBanner,
          )}
          style={{ height: '100%', display: 'flex' }}
        >
          <PageMenu
            folder={folder}
            createNode={createNode}
            updateNode={updateNode}
            selectAction={selectAction}
          />

          <ScrollArea
            className={styles.Content}
            area={ScrollAreaContainer.PAGE}
          >
            <>
              {isTipBannerVisible && (
                <div className={styles.TipBanner}>
                  <InfoTipBanner
                    title={t('page_editor_template_message_title')}
                    description={t('page_editor_template_message_body')}
                    onDismiss={handleTemplateBannerDismiss}
                  />
                </div>
              )}

              <ListItems
                folder={folder}
                mode={mode}
                updateNode={updateNode}
                deleteNode={deleteNode}
                missedPageProperties={missedPageProperties}
              />
            </>
          </ScrollArea>
        </div>
      </div>

      <div className={styles.Preview}>
        <PagePreview
          folder={{
            ...previewPageFolder,
            content: previewPageFolder.content,
          }}
        />
      </div>
    </div>
  )
}

export default PageEditor
