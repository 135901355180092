import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useBoardActions from '@hooks/useBoardActions'
import useIsApiAvailableOrNotify from '@hooks/useIsApiAvailableOrNotify'
import { useLogEvents } from '@features/analytics/useLogEvents'

import useBulk from '@hooks/bulk/useBulk'
import { useUpgradeToViewDialog } from '@features/reverseTrial/hooks/useUpgradeToViewDialog'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { useReverseTrialToastMessage } from '@features/reverseTrial/hooks/useReverseTrialToastMessage'
import { Folder, NodeType } from '../../../models'

import useNode from '../hooks/useNode'
import useDragAndDrop from '../hooks/useDragAndDrop'
import { BoardMode } from '../models/nodes'
import { NodeSelectionVariants } from '../models/selection'
import useBulkSelection from '../hooks/useBulkSelection'

import BoardPanel from './BoardPanel'
import NodeItem from './folderNodes/NodeItem'
import FolderListItem from './FolderListItem'
import NodeSelection from './NodeSelection'

import styles from './FolderManagement.module.css'

const nodeVariantsByMode = {
  [BoardMode.read]: NodeSelectionVariants.gray,
  [BoardMode.write]: NodeSelectionVariants.gray,
  [BoardMode.select]: NodeSelectionVariants.blue,
  [BoardMode.action]: NodeSelectionVariants.default,
  [BoardMode.offline]: NodeSelectionVariants.default,
} as const

type Props = {
  mode: BoardMode
  boardId: string
  folder: Folder
  isSharedFolderMode?: boolean
  canCopy?: boolean
  canBeShared?: boolean
  isActive?: boolean
  isPageBoard?: boolean
  canCopyContentOfSharedFolder?: boolean
  isOwnBoard: boolean
}

const FolderManagement: FC<Props> = ({
  mode,
  boardId,
  folder,
  isSharedFolderMode,
  canCopy,
  canBeShared,
  isActive,
  isPageBoard,
  canCopyContentOfSharedFolder,
  isOwnBoard,
}) => {
  const { isFreePlanUser } = useReverseTrialContext()
  const { openUpgradeToViewDialog } = useUpgradeToViewDialog()
  const { t } = useTranslation()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { onClick, onSelect, moveNode } = useNode({
    boardId,
    folderId: folder.id,
    mode,
    folder,
    isSharedFolderMode,
    canCopyContentOfSharedFolder,
  })
  const { canPreviewNode } = useBoardActions()
  const { pagesBoardViewEvent } = useLogEvents()
  const { bulk } = useBulk()

  const isDragEnabledByReverseTrialFeature = Boolean(
    isFreePlanUser ? isActive : true,
  )

  const isDragEnabled = isSharedFolderMode
    ? false
    : mode === BoardMode.write && isDragEnabledByReverseTrialFeature

  const { layout, setItems, onDrag, onDragEnd } = useDragAndDrop(
    isDragEnabled,
    moveNode,
    isApiAvailableOrNotify,
  )
  const { isSelected } = useBulkSelection()

  useEffect(() => {
    if (isPageBoard) {
      pagesBoardViewEvent({ activePage: !isFreePlanUser })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageBoard, isFreePlanUser])

  useEffect(() => {
    setItems(folder.content)
  }, [folder.content, setItems])

  useReverseTrialToastMessage({ isActive, folder, isSharedFolderMode })

  return (
    <div className={styles.Root}>
      <BoardPanel
        height={layout.height}
        isSharedFolderMode={isSharedFolderMode}
        canBeShared={canBeShared}
      >
        {layout.tiles.map(({ node, rect }, index) => {
          const key = `${node.id}-edit`
          const isFolderNode = node.type === NodeType.FOLDER
          const isPageNode = node.type === NodeType.PAGE
          const isLimitedPageNode = isFreePlanUser && isPageNode && !isActive
          const shouldShowPopup = isFreePlanUser && !isPageNode && !isActive

          const shouldShowUpgradeDialog =
            shouldShowPopup && !isFolderNode && !isPageBoard && !isOwnBoard

          const showTooltip =
            !canPreviewNode &&
            mode !== BoardMode.select &&
            !isFolderNode &&
            !isPageBoard &&
            !isSharedFolderMode &&
            !shouldShowPopup

          const unSelectable = Boolean(
            isFolderNode && bulk.nodes.length && isSharedFolderMode,
          )

          const isNotSameFolderIdForBulkAction = Boolean(
            bulk.folderId && bulk.folderId !== folder.id,
          )

          const handleClick = () => {
            const isUnClickable =
              showTooltip || (mode === BoardMode.select && unSelectable)

            if (isUnClickable && !isLimitedPageNode) {
              return
            }

            if (shouldShowUpgradeDialog) {
              openUpgradeToViewDialog()
              return
            }

            onClick({
              nodeId: node.id,
              type: node.type,
              unSelectable,
              isNotSameFolderIdForBulkAction,
            })
          }

          const handleSelect = () => onSelect(node.id, node.type)

          const shouldRenderWithOpacity =
            (unSelectable && mode === BoardMode.select && !isPageBoard) ||
            isLimitedPageNode ||
            shouldShowPopup

          const isUnSelectable =
            !isActive ||
            unSelectable ||
            isNotSameFolderIdForBulkAction ||
            (isSharedFolderMode &&
              (isFolderNode || !canCopyContentOfSharedFolder))

          return (
            <FolderListItem
              key={key}
              rect={rect}
              index={index}
              onDrag={onDrag}
              onDragEnd={onDragEnd}
            >
              <NodeSelection
                isSelected={isSelected(node.id)}
                tooltipMessage={
                  showTooltip && !isLimitedPageNode
                    ? t('inactive_board_content_toast')
                    : undefined
                }
                onClick={handleClick}
                onSelect={isUnSelectable ? undefined : handleSelect}
                variant={nodeVariantsByMode[mode]}
                opacity={shouldRenderWithOpacity ? '0.5' : undefined}
                withDarkOverlay={[NodeType.VIDEO, NodeType.IMAGE].includes(
                  node.type,
                )}
                withGrayGradient={
                  (!isActive &&
                    !isPageBoard &&
                    mode !== BoardMode.select &&
                    mode !== BoardMode.offline &&
                    node.type !== NodeType.FOLDER) ||
                  shouldShowPopup
                }
                withOverlay={
                  canCopy || canPreviewNode || node.type === NodeType.FOLDER
                }
              >
                <NodeItem node={node} isSelected={isSelected(node.id)} />
              </NodeSelection>
            </FolderListItem>
          )
        })}
      </BoardPanel>
    </div>
  )
}

export default FolderManagement
