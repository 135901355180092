import { useCallback } from 'react'
import { newKey } from '@helpers/NodeIdGenerator'
import useIsApiAvailableOrNotify from '@hooks/useIsApiAvailableOrNotify'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '@hooks/useToast'
import { useTranslation } from 'react-i18next'
import { ContentPickersEnum } from '@features/pickers'
import useFolderEvents from '@features/analytics/useFolderEvents'
import useBoard from '@hooks/useBoard'
import useBulk from '@hooks/bulk/useBulk'
import { useSharedFolder } from '@hooks/useSharedFolder'
import { DestinationPickerSourceEventEnum } from '@features/analytics/useDestinationPickerEvents'
import { nodeActions } from '../../../../actions'
import { AppState } from '../../../models/appState'
import { Role } from '../../../models'

interface IRunParams {
  toastSuccessMessage: string
}

interface IDestinationPickerActions {
  runCopy: (params: IRunParams) => void
  runMove: (params: IRunParams) => void
}

export const useDestinationPickerActions = ({
  contentType,
  eventsOverrides,
}: {
  contentType: string
  eventsOverrides?: {
    source: DestinationPickerSourceEventEnum
  }
}): IDestinationPickerActions => {
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const dispatch = useDispatch()
  const toast = useToast()
  const { t } = useTranslation()

  const {
    bulk: { nodes, contentId: sourceBoardId, folderId: sourceFolderId },
  } = useBulk()

  const { role, isActive } = useBoard({
    boardId: sourceBoardId,
    folderId: sourceFolderId,
  })

  const {
    sharedFolderState: { shortcutId },
  } = useSharedFolder()

  const { folderMoveContentEvent, folderCopyContentEvent } = useFolderEvents()

  const { pickersContentNavigation } = useSelector(
    (state: AppState) => state.app,
  )

  const runCopy: IDestinationPickerActions['runCopy'] = useCallback(
    ({ toastSuccessMessage }) => {
      if (!isApiAvailableOrNotify()) {
        return
      }

      const { boardId, folderId } =
        pickersContentNavigation[ContentPickersEnum.CopyPicker]

      if (!boardId || !folderId || !sourceFolderId || !sourceBoardId) {
        toast(t('optimistic_ui_failed'))
        return
      }

      dispatch(
        nodeActions.copyNodesAction({
          targetBoardId: boardId,
          targetFolderId: folderId,
          nodes: nodes.map(({ id }) => id),
          key: newKey(),
          fromParams: {
            fromBoardId: sourceBoardId,
            fromFolderId: sourceFolderId,
            fromShortcutId: shortcutId,
          },
        }),
      )

      folderCopyContentEvent({
        count: nodes.length,
        boardId,
        isActive,
        contentType,
        source:
          eventsOverrides?.source ||
          (role === Role.OWN
            ? DestinationPickerSourceEventEnum.OwnBoard
            : DestinationPickerSourceEventEnum.ReceivedBoard),
      })

      toast(toastSuccessMessage)
    },
    [
      isApiAvailableOrNotify,
      pickersContentNavigation,
      sourceFolderId,
      sourceBoardId,
      dispatch,
      nodes,
      shortcutId,
      folderCopyContentEvent,
      role,
      isActive,
      contentType,
      eventsOverrides?.source,
      toast,
      t,
    ],
  )

  const runMove: IDestinationPickerActions['runMove'] = useCallback(
    ({ toastSuccessMessage }) => {
      if (!isApiAvailableOrNotify()) {
        return
      }

      const { boardId, folderId } =
        pickersContentNavigation[ContentPickersEnum.MovePicker]

      if (!boardId || !folderId || !sourceFolderId) {
        toast(t('optimistic_ui_failed'))
        return
      }

      dispatch(
        nodeActions.moveNodesAction({
          targetBoardId: boardId,
          targetFolderId: folderId,
          nodes: nodes.map(({ id }) => id),
          sourceFolderId,
        }),
      )

      folderMoveContentEvent({
        contentType,
        count: nodes.length,
      })

      toast(toastSuccessMessage)
    },
    [
      isApiAvailableOrNotify,
      pickersContentNavigation,
      sourceFolderId,
      dispatch,
      nodes,
      folderMoveContentEvent,
      contentType,
      toast,
      t,
    ],
  )

  return {
    runCopy,
    runMove,
  }
}
