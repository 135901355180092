import { useDispatch } from 'react-redux'
import { undoRedoActions } from '../actions'

const useUndoStack = () => {
  const dispatch = useDispatch()

  const reset = () => {
    dispatch(undoRedoActions.resetUndoStack())
  }

  return { reset }
}

export default useUndoStack
