import { useLocation, useParams } from 'react-router-dom'
import { getRootFolderId } from '../helpers/NodeIdGenerator'

export enum TabType {
  CONTENT = 'content',
  INSIGHTS = 'insights',
}

interface IUseRouterParams {
  uid: string
  bid: string
  boardId: string
  folderId: string
  selectedTab: string
}

const useRouterParams = (): IUseRouterParams => {
  const { pathname } = useLocation()
  const { boardId, folderId: urlFolderId } = useParams()

  const folderId = urlFolderId || getRootFolderId(boardId || '')
  const [uid, bid] = boardId ? boardId.split('-') : ['', '']

  const selectedTab = pathname.includes('insights')
    ? TabType.INSIGHTS
    : TabType.CONTENT

  return {
    uid,
    bid,
    boardId: boardId || '',
    folderId,
    selectedTab,
  }
}

export default useRouterParams
