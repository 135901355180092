import { useEffect, useState } from 'react'

const useOnScrollHandler = (
  target?: HTMLElement | null,
  handler?: () => void,
  delay = 500,
) => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const handleScroll = () => {
      if (!isScrolled) {
        setIsScrolled(true)
        if (handler) {
          handler()
        }
      }

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setIsScrolled(false)
      }, delay)
    }

    target?.addEventListener('scroll', handleScroll)

    return () => {
      target?.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolled, delay, handler, target])
}

export default useOnScrollHandler
