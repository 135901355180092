import React, { FC } from 'react'
import { ForwardIcon, Icon } from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'

import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import { useLogEvents } from '@features/analytics/useLogEvents'

import links from '../../../constants/links'
import { deviceLanguage } from '../../../../utils/deviceParser'

import styles from './EmptyActiveBoardsState.module.css'

const EmptyActiveBoardsState: FC = () => {
  const { trigger } = useLogEvents()
  const { t } = useTranslation()
  const { limit } = useUserFeatures(Features.boards)

  const message = t('active_boards_empty_state').replace(
    '%@',
    limit?.toString(),
  )
  const link = `${links.plansLink}${
    deviceLanguage === 'en' ? '' : `-${deviceLanguage}`
  }`

  const onClick = () => {
    trigger('app_empty_boards_learn_click')
  }

  return (
    <p className={styles.Message}>
      {message}&nbsp;
      <a
        className={styles.Link}
        href={link}
        target="_blank"
        rel="noreferrer"
        aria-label="Trigger empty Boards event"
        onClick={onClick}
      >
        <Trans i18nKey="learn_more" />
        &nbsp;
        <Icon size="13px" color="inherit">
          <ForwardIcon />
        </Icon>
      </a>
    </p>
  )
}

export default React.memo(EmptyActiveBoardsState)
