import React, {
  ElementType,
  MouseEventHandler,
  PropsWithChildren,
  useRef,
  useState,
} from 'react'

import { useResizable } from '../hooks/useResizable'

import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { TooltipPositions } from '../../../../ui/models/tooltip'

import styles from './ResizablePanel.module.css'
import { useSideBarTranslations } from '../hooks/useSideBarTranslations'

type Props = PropsWithChildren<{ as?: ElementType; className?: string }>

const ResizablePanel: React.FC<Props> = ({
  as: Tag = 'div',
  className,
  children,
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const { dragToResize } = useSideBarTranslations()
  const [isActive, setIsActive] = useState(false)

  const onDrop = () => {
    setIsActive(false)
  }

  const { resizableWidth, resizeHandler } = useResizable(rootRef, onDrop)

  const onResize: MouseEventHandler = (e) => {
    resizeHandler(e)
    setIsActive(true)
  }

  return (
    <Tag
      ref={rootRef}
      className={[styles.Root, className].join(' ')}
      style={{ flexBasis: `${resizableWidth}px` }}
    >
      {children}

      <div
        className={styles.ResizableIcon}
        onMouseDown={onResize}
        onMouseUp={onDrop}
      >
        <Tooltip
          className={styles.TooltipTarget}
          isOpen={isActive ? false : undefined}
          disabled={isActive}
          followCursorY
          as="div"
          data={<TooltipContent>{dragToResize()}</TooltipContent>}
          position={TooltipPositions.RIGHT}
        >
          <div />
        </Tooltip>
      </div>
    </Tag>
  )
}

export default React.memo(ResizablePanel)
