import Spinner, { Justify, Size } from '../../../components/Spinner'
import { Node } from '../../../models'
import withImage from '../../../hoc/withImage'

import styles from './NodeImage.module.css'

interface Props {
  node: Node
  thumbnail: string
  isLoading: boolean
  isUploading: boolean
  onError: () => void
  onLoad: () => void
}
const NodeImage = ({
  node,
  thumbnail,
  isUploading,
  isLoading,
  onLoad,
  onError,
}: Props) => {
  return (
    <div className={`${styles.Root} ${isLoading ? styles.LoadingWidth : ''}`}>
      <Spinner
        size={Size.SMALL}
        justify={Justify.START}
        spinning={isUploading || isLoading}
      >
        <img
          className={styles.Image}
          src={thumbnail}
          loading="lazy"
          alt={node.title}
          onError={onError}
          onLoad={onLoad}
        />
      </Spinner>
    </div>
  )
}

export default withImage(NodeImage)
