import { useEffect } from 'react'
import { NODE_LINK_TYPES } from 'boards-web-ui'

import useButtonEvents from '@features/analytics/useButtonEvents'

import { ButtonGroupType } from './models'
import ButtonGroup from './components/ButtonGroup'
import ButtonItem from './components/ButtonItem'

import styles from './ButtonPicker.module.css'

interface Props {
  onSelectButton: (type: NODE_LINK_TYPES) => void
}
const ButtonPicker = ({ onSelectButton }: Props) => {
  const { buttonsMenuPicker } = useButtonEvents()

  useEffect(() => {
    buttonsMenuPicker()
  }, [buttonsMenuPicker])

  return (
    <div className={styles.Root}>
      <ButtonGroup type={ButtonGroupType.GENERAL}>
        <ButtonItem type={NODE_LINK_TYPES.REGULAR} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.ZOOM} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.CALENDLY} onClick={onSelectButton} />
      </ButtonGroup>
      <ButtonGroup type={ButtonGroupType.CONTACT}>
        <ButtonItem type={NODE_LINK_TYPES.WHATS_APP} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.FACEBOOK} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.TELEGRAM} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.PHONE} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.SMS} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.MAIL} onClick={onSelectButton} />
      </ButtonGroup>
      <ButtonGroup type={ButtonGroupType.PAYMENT}>
        <ButtonItem type={NODE_LINK_TYPES.PAYPAL} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.VENMO} onClick={onSelectButton} />
        <ButtonItem type={NODE_LINK_TYPES.CASH} onClick={onSelectButton} />
      </ButtonGroup>
    </div>
  )
}

export default ButtonPicker
