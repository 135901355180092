import React from 'react'
import { useSelectContext } from './SelectContext'

import styles from './Options.module.css'

const Option: React.FC<{
  children: string
  value: string
}> = ({ children, value }) => {
  const { selectedOption, changeSelectedOption } = useSelectContext()

  const classes = [styles.Root]
  if (selectedOption === value) {
    classes.push(styles.Selected)
  }
  return (
    <li
      className={classes.join(' ')}
      onClick={() => changeSelectedOption(value, children)}
    >
      {children}
    </li>
  )
}

export default Option
