import { FC, memo, useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'
import { Trans } from 'react-i18next'

import PreviewPageContent from '@features/nodePreview/components/PreviewPageContent'

import ScreenFrame from './ScreenFrame'
import { Folder } from '../../../models'

import styles from './PagePreview.module.css'

const EmptyState = () => (
  <div className={styles.EmptyState}>
    <Trans i18nKey="page_builder_preview_empty_state" />
  </div>
)

interface Props {
  folder: Folder
}

const PagePreview: FC<Props> = ({ folder }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { height = 0 } = useResizeObserver({
    ref,
  })

  return (
    <div ref={ref} className={styles.Scroll}>
      <div className={styles.Root}>
        <ScreenFrame
          folder={folder}
          height={height}
          maxHeight={800}
          minHeight={350}
          screenHeight={800}
        >
          <PreviewPageContent
            asMobile
            nodes={folder?.content}
            themeId={folder?.themeId}
            EmptyDisplay={<EmptyState />}
          />
        </ScreenFrame>
      </div>
    </div>
  )
}

export default memo(PagePreview)
