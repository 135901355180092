import { User } from '@firebase/auth'

import { MESSAGE_TYPE } from './types'
import { ID_SEPARATOR } from './config'

export const authorize = (
  extensionId: string,
  token: string,
  url?: string,
): void => {
  try {
    if (typeof chrome !== 'undefined' && chrome.runtime) {
      const ids = extensionId.split(ID_SEPARATOR)
      ids.forEach((_extensionId) => {
        chrome.runtime?.sendMessage(
          _extensionId,
          {
            type: MESSAGE_TYPE.SIGNED_IN_SUCCESS,
            value: { token, url },
          },
          () => chrome.runtime.lastError,
        )
      })
    }
  } catch (e) {
    // eslint-disable-next-line
    console.log(e)
  }
}

export const unAuthorize = (extensionId: string): void => {
  try {
    if (typeof chrome !== 'undefined' && chrome.runtime) {
      const ids = extensionId.split(ID_SEPARATOR)
      ids.forEach((_extensionId) => {
        chrome.runtime?.sendMessage(
          _extensionId,
          {
            type: MESSAGE_TYPE.SIGNED_OUT,
            value: null,
          },
          () => chrome.runtime.lastError,
        )
      })
    }
  } catch (e) {
    // eslint-disable-next-line
    console.log(e)
  }
}

export const syncAuthState = (
  extensionId: string,
  value: () => User | null,
): (() => void) => {
  const listener = () => {
    try {
      if (typeof chrome !== 'undefined' && chrome.runtime) {
        const ids = extensionId.split(ID_SEPARATOR)
        ids.forEach((_extensionId) => {
          chrome.runtime?.sendMessage(_extensionId, {
            type: MESSAGE_TYPE.AUTH_STATE_CHANGE,
            value: value(),
          })
        })
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  document.addEventListener(
    MESSAGE_TYPE.BOARDS_CHROME_EXTENSION_AUTH_STATE_SYNC,
    listener,
  )
  return () =>
    document.removeEventListener(
      MESSAGE_TYPE.BOARDS_CHROME_EXTENSION_AUTH_STATE_SYNC,
      listener,
    )
}
