import { createContext } from 'react'
import {
  AdditionalField,
  AdditionalFields,
  CheckoutFormProduct,
  CheckoutFormProducts,
} from 'boards-web-ui'

import { AfterSubmitBehaviourEnum } from '@features/forms/constants'

import { FormIntegrationStatusData } from '../../../../_firebase'
import { CheckoutAction } from '../models'

type CheckoutState = {
  isSubmitted: boolean
  isOwn: boolean
  isNew: boolean
  action: CheckoutAction
  formIntegrationStatusState: FormIntegrationStatusData | null
  advancedForms: boolean
  isPayPalAccountConnected: boolean

  paymentProviders: Array<string>
  onPaypalConnect: () => void
  onPaypalDisconnect: () => void

  isPaypalFormConnected: boolean

  currency: string
  currencies: string[]
  onCurrencyChange: (currency: string) => void

  shippingFees: string
  onShippingFeesChange: (value: string) => void

  minOrder: string
  onMinOrderChange: (value: string) => void

  products: CheckoutFormProducts
  onProductsReOrder: (products: CheckoutFormProducts) => void
  onProductAdd: (product?: CheckoutFormProduct) => CheckoutFormProduct
  onProductDelete: (product: CheckoutFormProduct) => void
  onProductChange: (product: CheckoutFormProduct) => void

  additionalFields: AdditionalFields
  onAdditionalFieldsReOrder: (additionalFields: AdditionalFields) => void
  onAdditionalFieldsAdd: (
    additionalField?: AdditionalField,
  ) => Promise<AdditionalField | null>
  onAdditionalFieldsDelete: (additionalField: AdditionalField) => void
  onAdditionalFieldsChange: (additionalField: AdditionalField) => void

  postSubmissionBehavior: AfterSubmitBehaviourEnum
  postSubmissionMessage: string | null
  postSubmissionUrl: string | null
  postSubmissionError: string
  onPostSubmissionMessageChange: (
    message: string,
    advancedForms: boolean,
  ) => void
  onPostSubmissionUrlChange: (url: string, advancedForms: boolean) => void
  onPostSubmissionBehaviourChange: (
    value: AfterSubmitBehaviourEnum,
    advancedForms: boolean,
  ) => void

  responsesEmail: string
  responsesEmailError: string
  onResponsesEmailChange: (email: string) => void

  spreadsheetURL: string | undefined
  onUnlinkSpreadSheet: () => void
  isGoogleFormConnected: boolean

  onFormConnectToGoogleSpreadSheet: () => void

  onPaywall: () => void
  onCheckoutCancel: () => void
  isPayPalConnectionPending: boolean
  isPayPalDisconnectedPending: boolean
  isGoogleAccountConnectedPending: boolean
  formIntegrationStatusPending: boolean

  merchantPaymentsReceivable?: boolean
  merchantPrimaryEmailConfirmed?: boolean
}
const CheckoutContext = createContext({} as CheckoutState)

export default CheckoutContext
