import {
  AdditionalFields,
  CheckoutFormProducts,
  FieldTypes,
  FormFields,
  NodeType,
  TNodeCheckoutForm,
} from 'boards-web-ui'

import { AfterSubmitBehaviourEnum } from '@features/forms/constants'

import { CHECKOUT_POST_SUBMITTING_BEHAVIOUR } from '../constants'

type Props = {
  action: string
  title?: string
  additionalFields: AdditionalFields
  products: CheckoutFormProducts
  currency: string
  shippingFees: string
  minOrder: string
  postSubmissionBehavior: AfterSubmitBehaviourEnum
  postSubmissionUrl: string
  postSubmissionMessage: string
  responsesEmail?: string
  spreadsheetURL?: string
  paymentProviders: Array<string>
  encryptedEmail: string
  encryptedSpreadsheetURL: string
  initialResponsesEmail?: string
  initialSpreadsheetURL?: string
}
const postCheckoutRequestAdapter = (data: Props) => {
  const result: TNodeCheckoutForm = {
    title: data.title,
    type: NodeType.CHECKOUT,
    form: {
      encryptedEmail: data.encryptedEmail,
      title: '',
      type: NodeType.CHECKOUT,
      action: data.action || '',
      spreadsheetURL: data.spreadsheetURL || '',
      currency: data.currency,
      paymentProviders: data.paymentProviders,
      shippingFee: Number(data.shippingFees || 0),
      minOrder: Number(data.minOrder || 0),
      response: responseRequestAdapter(
        data.postSubmissionBehavior,
        data.postSubmissionMessage,
        data.postSubmissionUrl,
      ),
      fields: [
        ...productsRequestAdapter(data.products),
        ...additionAlFieldAdapter(data.additionalFields),
      ],
    },
  }

  if (data.initialResponsesEmail === data.responsesEmail) {
    result.form.encryptedEmail = data.encryptedEmail
  }

  if (data.initialSpreadsheetURL === data.spreadsheetURL) {
    result.form.encryptedSpreadsheetURL = data.encryptedSpreadsheetURL
  }

  if (data.responsesEmail) {
    result.form.email = data.responsesEmail
  }

  if (data.spreadsheetURL) {
    result.form.spreadsheetURL = data.spreadsheetURL
  }

  return result
}

export const additionAlFieldAdapter = (
  additionalFields: AdditionalFields,
): FormFields => {
  return additionalFields.map((additionalField) => {
    const {
      id,
      type,
      value: label,
      required,
      multiple,
      choices,
    } = additionalField
    return type === FieldTypes.CHOICE
      ? {
          id,
          type,
          label: label || '',
          required: !!required,
          multiple,
          choices,
        }
      : {
          id,
          type: type === 'name' ? 'string' : type,
          label: label || '',
          required: !!required,
        }
  })
}

export const productsRequestAdapter = (
  products: CheckoutFormProducts,
): CheckoutFormProducts => {
  return products.map((product) => {
    const { id, label, description, price, maxQuantity } = product

    return {
      id,
      type: 'product',
      label: label || '',
      description,
      price: Number(price),
      maxQuantity: Number(maxQuantity),
    }
  })
}

export const responseRequestAdapter = (
  postSubmissionBehavior: AfterSubmitBehaviourEnum,
  postSubmissionMessage: string,
  postSubmissionUrl: string,
) => {
  let response = null

  if (postSubmissionBehavior === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE) {
    response = postSubmissionMessage
  }

  if (postSubmissionBehavior === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT) {
    response = postSubmissionUrl
  }

  return response
}

export default postCheckoutRequestAdapter
