import { User } from '@firebase/auth'
import { AppDispatch } from '../../app/store'
import { BoardItem } from '../../app/models'

import { createSubscription } from './FirestoreServiceSubscriptions'
import {
  boardFoldersListener,
  boardListener,
  boardPermissionsListener,
  boardUnreadMessagesListener,
  boardUpdatesMessagesListener,
  clientConfigListener,
  getFolderDoc,
  getPermissionsDocs,
  inviteLinkListener,
  ownBoardsListener,
  profileListener,
  receivedBoardsListener,
  receivedBoardsPermissionsListener,
  userContentSettingsListener,
} from './FirestoreService'
import { createLoadingManagement } from './LoadingManagement'
import { UserContentSnapshot } from './snapshots/UserSnapshot'
import { BoardSnapshot } from './snapshots/BoardSnapshot'
import { BoardsSnapshot } from './snapshots/BoardsSnapshot'
import { ClientConfigSnapshot } from './snapshots/ClientConfigSnapshot'
import { ProfileSnapshot } from './snapshots/ProfileSnapshot'
import { PermissionSnapshot } from './snapshots/PermissionSnapshot'
import { UpdatesMessageSnapshot } from './snapshots/UpdatesMessageSnapshot'
import { FolderDoc, FoldersSnapshot } from './snapshots/FoldersSnapshot'
import { UpdatesUnreadMessageSnapshot } from './snapshots/UpdatesUnreadMessageSnapshot'
import { ReceivedBoardsPermissionsSnapshot } from './snapshots/ReceivedBoardsPermissionsSnapshot'
import { InviteLinkSnapshot } from './snapshots/InviteLinkSnapshot'

let user: User
export const createFirebaseListener = (dispatch: AppDispatch) => {
  const { addListener, stopListener, stopAllListeners } = createSubscription()
  const { addBoardsToLoad, addLoadedBoard, resetLoadingManagement } =
    createLoadingManagement(dispatch)

  const startProfileListener = (uid: string) => {
    profileListener(uid, ProfileSnapshot(dispatch))
  }

  function start(userParam: User) {
    user = userParam

    resetLoadingManagement()
    startProfileListener(user.uid)
    startClientConfigListener()
    userContentSettingsListener(user.uid, UserContentSnapshot(dispatch))

    ownBoardsListener(
      user.uid,
      BoardsSnapshot(
        true,
        addBoardsToLoad,
        handleAddBoard,
        stopListener,
        dispatch,
      ),
    )

    receivedBoardsListener(
      user.email || '',
      BoardsSnapshot(
        false,
        addBoardsToLoad,
        handleAddBoard,
        stopListener,
        dispatch,
      ),
    )
    setTimeout(() => {
      startReceivedBoardsPermissionsListener()
    }, 5000)
  }

  function handleAddBoard(board: BoardItem) {
    const { uid, id, boardId, rootFolderId, isOwn } = board

    startBoardListener(board)
    getFolderDoc(uid, id, rootFolderId).then((doc) => {
      FolderDoc(doc, boardId, dispatch)
      addLoadedBoard(boardId)
    })

    startInviteLinkListener(uid, id)
    if (isOwn) {
      getPermissionsDocs(uid, id, PermissionSnapshot(uid, id, false, dispatch))
    }
  }

  function startBoardListener(board: BoardItem) {
    const key = `${board.boardId}-board`
    const fnc = () =>
      boardListener(board.uid, board.id, BoardSnapshot(board, dispatch))

    addListener(key, fnc)
  }

  function startClientConfigListener() {
    const key = 'client-config'
    const fnc = () => clientConfigListener(ClientConfigSnapshot(dispatch))

    addListener(key, fnc)
  }

  async function startBoardFoldersListener(uid: string, bid: string) {
    const key = `${uid}-${bid}-folders`
    const fnc = () =>
      boardFoldersListener(uid, bid, FoldersSnapshot(uid, bid, dispatch))

    addListener(key, fnc)
  }

  function startBoardPermissionsListener(uid: string, bid: string) {
    const key = `${uid}-${bid}-permissions`
    const fnc = () =>
      boardPermissionsListener(
        uid,
        bid,
        PermissionSnapshot(uid, bid, true, dispatch),
      )

    addListener(key, fnc)
  }

  function startInviteLinkListener(uid: string, bid: string) {
    const key = `${uid}-${bid}-api-permissions`
    const fnc = () =>
      inviteLinkListener(uid, bid, InviteLinkSnapshot(uid, bid, dispatch))

    addListener(key, fnc)
  }

  function startReceivedBoardsPermissionsListener() {
    const key = `permissions-received`
    const fnc = () =>
      receivedBoardsPermissionsListener(
        user.uid,
        ReceivedBoardsPermissionsSnapshot(dispatch),
      )

    addListener(key, fnc)
  }

  function startUpdatesMessages(uid: string, bid: string) {
    const key = `${uid}-${bid}-update-messages`
    const fnc = () =>
      boardUpdatesMessagesListener(
        uid,
        bid,
        UpdatesMessageSnapshot(uid, bid, dispatch),
      )

    addListener(key, fnc)
  }

  function startUpdatesUnreadMessages(uid: string, bid: string) {
    const key = `${uid}-${bid}-update-messages-unread`
    const fnc = () =>
      boardUnreadMessagesListener(
        uid,
        bid,
        UpdatesUnreadMessageSnapshot(uid, bid, dispatch),
      )

    addListener(key, fnc)
  }

  function startBoardUpdatesMessagesListener(buid: string, bid: string) {
    startUpdatesMessages(buid, bid)
    startUpdatesUnreadMessages(user.uid, bid)
  }

  function stopBoardUpdatesMessagesListener(buid: string, bid: string) {
    stopListener(`${buid}-${bid}-update-messages`)
    stopListener(`${buid}-${bid}-update-messages-unread`)
  }

  const stop = () => stopAllListeners()

  return {
    start,
    stop,
    startClientConfigListener,
    startProfileListener,
    startBoardFoldersListener,
    startBoardPermissionsListener,
    startBoardUpdatesMessagesListener,
    stopBoardUpdatesMessagesListener,
  } as const
}
