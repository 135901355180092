import PropTypes from 'prop-types'

const Box = ({ ml, mr, mt, mb, pt, sx, children }) => {
  const customStyle = {}

  if (mt) {
    customStyle.marginTop = `${mt}px`
  }
  if (mb) {
    customStyle.marginBottom = `${mb}px`
  }
  if (ml) {
    customStyle.marginLeft = `${ml}px`
  }
  if (mr) {
    customStyle.marginRight = `${mr}px`
  }
  if (pt) {
    customStyle.paddingTop = `${pt}px`
  }
  if (sx) {
    Object.assign(customStyle, sx)
  }

  return <div style={customStyle}>{children}</div>
}

Box.propTypes = {
  mt: PropTypes.number,
  mb: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  pt: PropTypes.number,
  // eslint-disable-next-line
  sx: PropTypes.object,
}

export default Box
