import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

const enum TARGET_FEATURES {
  MONETIZATION = 'monetization',
}

const enum TARGET_SCREENS {
  HOME = 'home',
}

const useSidebarEvents = () => {
  const { trigger } = useLogEvents()

  const showBannerEvent = () =>
    trigger(EventCategories.APP_BANNER_MONETIZATION_SHOW, {
      feature: TARGET_FEATURES.MONETIZATION,
      screen: TARGET_SCREENS.HOME,
    })

  const dismissBannerEvent = () =>
    trigger(EventCategories.APP_BANNER_MONETIZATION_DISMISS, {
      feature: TARGET_FEATURES.MONETIZATION,
      screen: TARGET_SCREENS.HOME,
    })

  const clickBannerEvent = () => {
    trigger(EventCategories.APP_BANNER_MONETIZATION_CLICK, {
      feature: TARGET_FEATURES.MONETIZATION,
      screen: TARGET_SCREENS.HOME,
    })
  }

  const desktopCtasMobileClick = () => {
    trigger(EventCategories.APP_DESKTOP_CTAS_MOBILE_CLICK)
  }

  const desktopCtasChromeClick = () => {
    trigger(EventCategories.APP_DESKTOP_CTAS_CHROME_CLICK)
  }

  const desktopCtasDismissClick = (type: string) => {
    trigger(EventCategories.APP_DESKTOP_CTAS_DISMISS, {
      type,
    })
  }

  return {
    showBannerEvent,
    dismissBannerEvent,
    clickBannerEvent,
    desktopCtasMobileClick,
    desktopCtasChromeClick,
    desktopCtasDismissClick,
  }
}

export default useSidebarEvents
