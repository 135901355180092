export type FormsAction = {
  id: string
  actionName: string
  title?: string
  isValid: boolean
  onNext?: () => void
  onCancel?: () => void
}
export type FormsActions = Array<FormsAction>

export const enum FORMS_ACTION_IDS {
  FIELDS = 'fields',
  SETTINGS = 'settings',
}
