import { useEffect } from 'react'

import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { UserProfilePlan } from '@models/UserProfile'

import useUserFeatures, { Features } from './useUserFeatures'

const useMessageLimits = (
  messagesOverBannerView: () => void,
  messagesFreeBannerView: () => void,
  messagesOverBannerClick: () => void,
  messagesFreeBannerClick: () => void,
  plan: UserProfilePlan | undefined,
  updatesIsOpen: boolean,
): {
  handleUpgrade: () => void
  limitReached: boolean
  freeCountLeft: string | number
} => {
  const { freeCountLeft, limitReached } = useUserFeatures(Features.messages)
  const { openPaywall } = usePaywall()

  useEffect(() => {
    const viewBanner = limitReached
      ? messagesOverBannerView
      : messagesFreeBannerView
    if (updatesIsOpen) {
      viewBanner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitReached, updatesIsOpen])

  const handleUpgrade = () => {
    const bannerClick = limitReached
      ? messagesOverBannerClick
      : messagesFreeBannerClick
    bannerClick()

    if (plan?.monetized) {
      openPaywall({ source: PaywallSourceEnum.MESSAGES })
    }
  }

  return {
    handleUpgrade,
    freeCountLeft,
    limitReached,
  }
}

export default useMessageLimits
