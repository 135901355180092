import { Trans } from 'react-i18next'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import { ButtonNew } from 'boards-web-ui'
import LinkButton from '../../../../../elements/Link'

import styles from './SelectContentDialogFooter.module.css'

interface Props {
  selectedNodes: number
  clearSelection?: () => void
  insertSelection?: (boardId: string, folderId: string) => void
}

export const SelectContentDialogFooter = ({
  selectedNodes,
  clearSelection,
  insertSelection,
}: Props) => {
  const { pickerBoardId: boardId, pickerFolderId: folderId } =
    useContentNavigation(ContentPickersEnum.SourcePicker)

  const handleOnClick =
    insertSelection && boardId && folderId
      ? () => insertSelection(boardId, folderId)
      : undefined

  return (
    <div className={styles.Root}>
      <span className={styles.SelectedLabel}>
        {selectedNodes} selected &nbsp;
        {clearSelection && (
          <LinkButton onClick={clearSelection}>
            <Trans i18nKey="action_clear" />
          </LinkButton>
        )}
      </span>

      <ButtonNew
        color={'primary'}
        size={'medium'}
        disabled={!handleOnClick}
        onClick={handleOnClick}
      >
        <Trans i18nKey={'action_insert'} />
      </ButtonNew>
    </div>
  )
}
