import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

export const useEmailPreferencesEvents = () => {
  const { trigger } = useLogEvents()

  const appEmailPreferencesView = () => {
    trigger(EventCategories.APP_EMAIL_PREFERENCES_VIEW)
  }

  const appEmailOffersClick = (isChecked: boolean) => {
    trigger(EventCategories.APP_EMAIL_OFFERS_CLICK, {
      type: isChecked ? 'select' : 'unselect',
    })
  }
  const appEmailRecommendationsClick = (isChecked: boolean) => {
    trigger(EventCategories.APP_EMAIL_RECOMMENDATIONS_CLICK, {
      type: isChecked ? 'select' : 'unselect',
    })
  }
  const appEmailProductClick = (isChecked: boolean) => {
    trigger(EventCategories.APP_EMAIL_PRODUCT_CLICK, {
      type: isChecked ? 'select' : 'unselect',
    })
  }

  return {
    appEmailPreferencesView,
    appEmailOffersClick,
    appEmailRecommendationsClick,
    appEmailProductClick,
  } as const
}
