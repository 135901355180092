import React, { FC, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { Title } from '@features/account/common'
import {
  IUserEmailPreferences,
  useUserEmailPreferences,
} from '@features/account/EmailPreferences/useUserEmailPreferences'
import { useEmailPreferencesEvents } from '@features/analytics/useEmailPreferencesEvents'
import { useAuth } from '@hooks/useAuth'
import styles from './EmailPreferences.module.css'
import { InfoRow, TEmailPreferencesId } from './InfoRow'
import { updateUserProperties } from '../../../../api/AnalyticsAPI'

export const EmailPreferences: FC = () => {
  const {
    appEmailPreferencesView,
    appEmailOffersClick,
    appEmailRecommendationsClick,
    appEmailProductClick,
  } = useEmailPreferencesEvents()

  const { updateUserEmailPreferences, emailPreferences } =
    useUserEmailPreferences()
  const { user } = useAuth()

  const [preferences, setPreferences] = useState<IUserEmailPreferences>({
    offers: Boolean(emailPreferences?.offers),
    tips: Boolean(emailPreferences?.tips),
    product: Boolean(emailPreferences?.product),
  })

  const handleOnChange = (id: TEmailPreferencesId) => (isChecked: boolean) => {
    setPreferences((prevState) => {
      const newPreferences = { ...prevState, [id]: isChecked }

      updateUserEmailPreferences(newPreferences)

      if (user) {
        updateUserProperties(user, {
          topic_1: newPreferences.product,
          topic_2: newPreferences.offers,
          topic_3: newPreferences.tips,
        })
      }

      return newPreferences
    })

    if (id === 'offers') {
      appEmailOffersClick(isChecked)
    }

    if (id === 'tips') {
      appEmailRecommendationsClick(isChecked)
    }

    if (id === 'product') {
      appEmailProductClick(isChecked)
    }
  }

  useEffect(() => {
    appEmailPreferencesView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Title
        title={<Trans i18nKey="message_preferences_title" />}
        subTitle={<Trans i18nKey="email_preferences_header" />}
      />

      <div className={styles.InfoContainer}>
        <InfoRow
          title={<Trans i18nKey="email_topic_offers_title" />}
          subtitle={<Trans i18nKey="email_topic_offers_body" />}
          checked={preferences.offers}
          onSwitchChange={handleOnChange('offers')}
        />

        <InfoRow
          title={<Trans i18nKey="email_topic_recommendation_title" />}
          subtitle={<Trans i18nKey="email_topic_recommendation_body" />}
          checked={preferences.tips}
          onSwitchChange={handleOnChange('tips')}
        />

        <InfoRow
          title={<Trans i18nKey="email_topic_product_title" />}
          subtitle={<Trans i18nKey="email_topic_product_body" />}
          checked={preferences.product}
          onSwitchChange={handleOnChange('product')}
        />
      </div>
    </>
  )
}
