import { Trans } from 'react-i18next'
import UndoRedo from '../../toolbar/components/UndoRedo'

import styles from './ContentHead.module.css'

const ContentHead = () => {
  return (
    <div className={styles.Root}>
      <h2 className={styles.Title}>
        <Trans i18nKey="page_builder_tab_content" />
      </h2>

      <div className={styles.UndoRedo}>
        <UndoRedo />
      </div>
    </div>
  )
}

export default ContentHead
