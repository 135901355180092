import React, { useCallback } from 'react'
import { useDialog } from 'boards-web-ui'

import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import BoardsLimitReached from '../components/BoardsLimitReached'

const useBoardsDialogs = () => {
  const { limit } = useUserFeatures(Features.boards)
  const { open, close } = useDialog()

  const openBoardsLimitReached = useCallback(
    (source: ViewSource) => {
      open(
        <BoardsLimitReached
          limit={limit?.toString()}
          onCancel={close}
          source={source}
        />,
        {
          className: 'not-default',
          fullScreen: true,
        },
      )
    },
    [open, close, limit],
  )

  return {
    openBoardsLimitReached,
  } as const
}

export default useBoardsDialogs
