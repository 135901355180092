import { useEffect } from 'react'
import { User } from '@firebase/auth'
import { setUserProperties } from '../_firebase'
import { selectProfileInformation } from '../selectors'
import useGeoLocation from './useGeoLocation'
import { useSelectorFactory } from './useSelectorFactory'

export const useUpdateUserInfo = (user: User | null) => {
  const { country, language, timezoneOffset, loaded } = useSelectorFactory(
    selectProfileInformation,
  )

  const geolocationData = useGeoLocation()

  const browserLanguage = window.navigator.language

  const setUserCountryTimezoneAndLocationData = () => {
    let browserCountryValue
    let browserLanguageValue
    let browserTimezoneOffsetValue

    if (country == null) {
      browserCountryValue = geolocationData?.geoData?.countryCode
    }
    if (language == null) {
      browserLanguageValue = browserLanguage.split('-')
    }
    if (timezoneOffset == null) {
      browserTimezoneOffsetValue = new Date().getTimezoneOffset() / -60
    }

    if (
      browserCountryValue != null ||
      browserLanguageValue != null ||
      browserTimezoneOffsetValue != null
    ) {
      setUserProperties({
        name: undefined,
        country: browserCountryValue,
        language: browserLanguageValue && browserLanguageValue[0],
        timezoneOffset: browserTimezoneOffsetValue,
      })
    }
  }

  useEffect(() => {
    if (user && loaded) {
      setUserCountryTimezoneAndLocationData()
    }
    // eslint-disable-next-line
  }, [loaded])
}
