import { FC, PropsWithChildren } from 'react'
import { ButtonNew } from 'boards-web-ui'

type Props = {
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}
const DoneButton: FC<PropsWithChildren<Props>> = ({
  onClick,
  loading,
  disabled,
  children,
}) => (
  <ButtonNew
    isLoading={loading}
    color={'primary'}
    disabled={disabled}
    onClick={onClick}
    size={'medium'}
  >
    {children}
  </ButtonNew>
)

export default DoneButton
