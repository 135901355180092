import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from 'react'

import styles from './AutoGrowingInput.module.css'

export enum ContentSize {
  Default = 'Default',
  Big = 'Big',
}

type Props = {
  size?: ContentSize
  type?: string
  value?: string
  placeholder?: string
  maxlength?: number
  autofocus?: boolean
  readOnly?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onEnter?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  className?: string
  disabled?: boolean
}
const AutoGrowingInput: React.FC<Props> = React.memo(
  ({
    size = ContentSize.Default,
    type = 'text',
    value = '',
    placeholder,
    maxlength,
    autofocus,
    readOnly,
    onEnter,
    onBlur,
    onFocus,
    onChange,
    className,
    disabled,
  }) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (autofocus && inputRef.current && !disabled) {
        inputRef.current.focus()
      }
    }, [autofocus, disabled])

    const handleEnterPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (disabled) {
        return
      }

      if (e.key === 'Enter' && onEnter) {
        if (inputRef.current) {
          inputRef.current.blur()
        }
        const changeEvent = {
          ...e,
          value: inputRef?.current?.value || '',
        }
        onEnter(changeEvent as unknown as ChangeEvent<HTMLInputElement>)
      }
    }

    return (
      <label className={[styles.Label, styles[size]].join(' ')}>
        <span className={styles.Hidden}>&nbsp;{value || placeholder}</span>
        <input
          className={[styles.Input, className].join(' ')}
          type={type}
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          maxLength={maxlength}
          onBlur={onBlur}
          onFocus={disabled ? undefined : onFocus}
          onChange={disabled ? undefined : onChange}
          onKeyPress={disabled ? undefined : handleEnterPress}
          disabled={disabled}
        />
      </label>
    )
  },
)

export default AutoGrowingInput
