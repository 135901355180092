import { createHash } from 'crypto-browserify'

/* prettier-ignore */
const chars = [
  '0',  '1',  '2',  '3',  '4',  '5',  '6',  '7',  '8',  '9',
  // eslint-disable-next-line
  'A',  'B',  'C',  'D',  'E',  'F',  'G',  'H',  'I',  'J',  'K',  'L',  'M',  'N',  'O',  'P',  'Q',  'R',  'S',  'T',  'U',  'V',  'W',  'X',  'Y',  'Z',
  // eslint-disable-next-line
  'a',  'b',  'c',  'd',  'e',  'f',  'g',  'h',  'i',  'j',  'k',  'l',  'm',  'n',  'o',  'p',  'q',  'r',  's',  't',  'u',  'v',  'w',  'x',  'y',  'z'
]

const hashLength = 28
const regEx = /^[0-9A-Za-z]+$/

export function random(length) {
  let string = ''

  for (let i = 0; i < length; i += 1) {
    string += chars[Math.floor(Math.random() * chars.length)]
  }

  return string
}

export function test(string) {
  return regEx.test(string)
}

export function hash28(string) {
  const newString = createHash('sha256').update(string).digest('binary')

  let base62 = ''
  for (let i = 0; i < hashLength; i += 1) {
    base62 += chars[newString.charCodeAt([i]) % 62]
  }

  return base62
}
