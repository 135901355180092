import { Navigate, useLocation } from 'react-router-dom'
import { REDIRECT_PREFIX } from '../../constants/routes'

const RedirectWithPrefix: React.FC = () => {
  const location = useLocation()
  const { pathname } = location
  const redirectPrefix = `${REDIRECT_PREFIX}/`

  if (pathname.startsWith(redirectPrefix)) {
    // Remove the $ prefix and redirect to the new path
    const newPath = pathname.replace(redirectPrefix, '/')
    return <Navigate to={newPath} />
  }
  return null
}

export default RedirectWithPrefix
