const useUpload = (
  onUpload,
  { accept, multiple } = { accept: '', multiple: false },
) => {
  const onChange = async ({ target }) => {
    const { files } = target

    target.removeEventListener('change', onChange)
    target.remove()

    onUpload(multiple ? files : files[0])
  }

  const uploadFile = () => {
    const inputEL = document.createElement('input')

    inputEL.type = 'file'
    inputEL.accept = accept
    inputEL.multiple = multiple

    inputEL.addEventListener('change', onChange)
    inputEL.click()
  }

  return uploadFile
}

export default useUpload
