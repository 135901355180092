import React, { FC, useEffect } from 'react'
import useCampaignAnalytics from '@features/analytics/useCampaignAnalytics'

import { CampaignBanner as TCampaignBanner } from '../../../../_firebase/models/campaigns'
import CloseButton from '../../../../ui/components/CloseButton'

import styles from './CampaignBanner.module.css'

type Props = {
  banner: TCampaignBanner
  onPaywall: () => void
  onClose: (banner?: TCampaignBanner) => void
}

const CampaignBanner: FC<Props> = ({ banner, onPaywall, onClose }) => {
  const {
    appCustomHomeBannerView,
    appCustomHomeBannerClick,
    appCustomHomeBannerDismiss,
  } = useCampaignAnalytics()

  useEffect(() => {
    if (!banner) {
      return
    }

    appCustomHomeBannerView(banner.campaignId)
  }, [banner, appCustomHomeBannerView])

  return (
    <div key={banner.id} className={styles.Root}>
      <div className={styles.Header}>
        <span className={styles.Icon}>{banner.emoji}</span>
        <CloseButton
          size={12}
          onClose={() => {
            onClose(banner)
            appCustomHomeBannerDismiss(banner?.campaignId)
          }}
        />
      </div>

      <h3 className={styles.Title}>{banner?.title}</h3>

      {banner?.destination.type === 'link' ? (
        <a
          className={styles.Forward}
          href={banner?.destination?.text}
          rel="noreferrer"
          target="_blank"
          onClick={() => appCustomHomeBannerClick(banner?.campaignId)}
        >
          {banner?.buttonText}
        </a>
      ) : (
        ''
      )}
      {banner.destination.type === 'paywall' ? (
        <button
          className={styles.Forward}
          type="button"
          onClick={() => {
            appCustomHomeBannerClick(banner?.campaignId)
            onPaywall()
          }}
        >
          {banner?.buttonText}
        </button>
      ) : (
        ''
      )}
    </div>
  )
}

export default React.memo(CampaignBanner)
