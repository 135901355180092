import { useCallback } from 'react'
import { NODE_LINK_TYPES } from 'boards-web-ui'

import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import { NODE_LINK_TYPES_NAME } from '../../models/buttons'

const useButtonEvents = () => {
  const { trigger } = useLogEvents()

  const buttonsMenuPicker = useCallback(
    () => trigger(EventCategories.APP_BUTTON_MENU_VIEW),
    [trigger],
  )
  const buttonEditorView = useCallback(
    (buttonType: NODE_LINK_TYPES) => {
      const params = {
        button_type: NODE_LINK_TYPES_NAME[buttonType] || buttonType,
      }

      trigger(EventCategories.APP_BUTTON_EDITOR_VIEW, params)
    },
    [trigger],
  )
  const messengerSearchClick = useCallback(() => {
    trigger(EventCategories.APP_BUTTON_MESSENGER_SEARCH_CLICK)
  }, [trigger])

  return {
    buttonEditorView,
    buttonsMenuPicker,
    messengerSearchClick,
  } as const
}

export default useButtonEvents
