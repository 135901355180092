/* eslint no-console: ["error", { allow: ["warn"] }] */
import { isSameDay, subDays } from 'date-fns'
import { InsightsFilter } from '../models/filter'
import { ActivitySummary, DailyStat } from '../models/insightsResult'

const filterByDay = (days: DailyStat[], day: Date) => {
  return days.find(({ date }) => isSameDay(new Date(date), day))
}

const filterData = (
  data: ActivitySummary,
  filter: InsightsFilter,
): DailyStat | undefined => {
  const today = new Date()
  const yesterday = subDays(today, 1)

  switch (filter) {
    case InsightsFilter.TODAY:
      return filterByDay(data.daily, today)
    case InsightsFilter.YESTERDAY:
      return filterByDay(data.daily, yesterday)
    case InsightsFilter.THIS_WEEK:
      return data.currentWeek[0]
    case InsightsFilter.LAST_WEEK:
      return data.previousWeek[0]
    case InsightsFilter.THIS_MONTH:
      return data.currentMonth[0]
    case InsightsFilter.LAST_MONTH:
      return data.previousMonth[0]
    case InsightsFilter.ALL_TIME:
      return data.allTime
    default:
      return undefined
  }
}

export const transformDataToIndicator = (
  data: ActivitySummary,
  filter: InsightsFilter,
) => {
  try {
    const result = filterData(data, filter)
    if (result) {
      return result
    }

    return {
      total: { all: 0 },
      users: { all: 0 },
    } as DailyStat
  } catch {
    console.warn(`Error parsing indicator with filter ${filter}`)
    return undefined
  }
}
