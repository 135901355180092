import React, { FC, Fragment } from 'react'
import { useSideBarBanners } from '@features/sidebar/components/useSideBarBanners'

type Props = {
  setOpen: (value: boolean) => void
}

const SideBarBanners: FC<Props> = ({ setOpen }) => {
  const { bannersToShow } = useSideBarBanners({ setOpen })

  return (
    <div>
      {bannersToShow.map(({ id, element }) => (
        <Fragment key={id}>{element}</Fragment>
      ))}
    </div>
  )
}

export default React.memo(SideBarBanners)
