import {
  getIsPayingUser,
  getReverseAnalyticsGlobalParams,
} from '@features/reverseTrial/heplers/getReverseAnalyticsGlobalParams'
import { AppState } from '../app/models/appState'
import { getFirstActivation } from '../selectors'

const logEvent =
  (event: string, parameters?: Record<string, unknown>) =>
  async (
    _: never,
    getState: () => AppState,
    extraArgument: {
      logEvent: (
        event: string,
        params: { [key: string]: unknown },
      ) => Promise<void>
    },
  ) => {
    const state = getState()

    const firstActivation = getFirstActivation(state)
    const reverseAnalyticsGlobalParams = getReverseAnalyticsGlobalParams({
      activation: firstActivation,
      trialDaysAvailable: undefined,
    })

    const isPayingUser =
      firstActivation &&
      getIsPayingUser({
        planName: firstActivation.plan,
        isReverseTrial: firstActivation.isReverseTrial,
      })

    extraArgument.logEvent(event, {
      ...reverseAnalyticsGlobalParams,
      ...parameters,
      subscription_active: `${Boolean(isPayingUser)}`,
      subscription_plan: isPayingUser ? firstActivation?.plan : null,
    })
  }

const logUsageEvent =
  (event: string, parameters?: Record<string, unknown>) =>
  async (
    _: never,
    __: never,
    {
      logUsage,
    }: {
      logUsage: (props: {
        event: string
        [key: string]: unknown
      }) => Promise<void>
    },
  ) => {
    logUsage({
      event,
      ...parameters,
    })
  }

export const asyncActions = {
  logEvent,
  logUsageEvent,
} as const
