export type Shortcuts = {
  user: string
  node: string
  isDotted?: boolean
}
export type UnValidProps = {
  user: null
  node: null
  isDotted: boolean
}
const getShortcuts = (shortcut: string | null): Shortcuts | UnValidProps => {
  if (!shortcut) {
    return {
      user: '',
      node: '',
      isDotted: false,
    }
  }

  if (shortcut.includes('.')) {
    const [user, node] = shortcut.split('.')

    return {
      user,
      node,
      isDotted: true,
    } as Shortcuts
  }

  if (shortcut.length !== 14 && shortcut.length !== 11) {
    return {
      user: null,
      node: null,
      isDotted: false,
    } as UnValidProps
  }

  let userShortcutLength = 5
  if (shortcut.length === 14) {
    userShortcutLength = 6
  }

  const user = shortcut.substring(0, userShortcutLength)
  const node = shortcut.substring(userShortcutLength)

  return {
    user,
    node,
    isDotted: false,
  } as Shortcuts
}

export default getShortcuts
