import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import { subscriptionAsyncActions } from 'actions'

const useFetchAvailableSubscriptions = (discountId?: string | null) => {
  const dispatch = useDispatch()
  const { status, discountId: prevDiscountId } = useSubscriptionPlans()

  const fetchAvailableSubscriptions = useCallback(
    () =>
      dispatch(
        subscriptionAsyncActions.fetchAvailableSubscriptions(
          discountId || undefined,
        ),
      ),
    [discountId, dispatch],
  )

  useEffect(() => {
    if (!status || discountId !== prevDiscountId) {
      fetchAvailableSubscriptions()
    }
  }, [status, discountId, prevDiscountId, fetchAvailableSubscriptions])

  return fetchAvailableSubscriptions
}

export default useFetchAvailableSubscriptions
