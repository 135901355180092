import { initializeFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import app from './app'
import paths from './paths'

export const storage = getStorage(app)
export const storagePdf = getStorage(app, paths.PdfStorage)
export const storageVideo = getStorage(app, paths.VideoStorage)

const firebaseStorage = initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true,
})

export default firebaseStorage
