import React, { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  AdditionalField as TAdditionalField,
  ChoiceActionType,
  ChoiceField,
  FieldTypes,
  RegularField,
} from 'boards-web-ui'
import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'

import styles from './AdditionalField.module.css'

type AdditionalFieldProps = {
  field: TAdditionalField
  onDelete: () => void
  onChange: (field: Partial<TAdditionalField>) => void
  highlightLinks?: boolean
  placeholder: string
  autoFocus?: boolean
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>

const AdditionalField: FC<AdditionalFieldProps> = ({
  field,
  highlightLinks = false,
  placeholder,
  onDelete,
  onChange,
  className,
  autoFocus,
  ...rest
}) => {
  const { t } = useTranslation()
  const {
    appCheckoutMultiChoiceToggleEvent,
    appCheckoutMultiChoiceAddEvent,
    appCheckoutMultiChoiceDeleteEvent,
  } = useCheckoutAnalytics()
  const sharedProps = {
    checked: field.required,
    custom: field.type === 'address' || field.type === 'text',
    autoFocus,
    highlightLinks,
    onCheckToggle: (required: boolean) => onChange({ required }),
    onDelete,
    className: clsx(styles.CheckoutAdditionalField, className),
    style: { cursor: 'default' },
    ...rest,
  }

  return field.type === FieldTypes.CHOICE ? (
    <ChoiceField
      uniqueId={field.id}
      question={field.value}
      choices={field.choices}
      isMultiSelect={field.multiple}
      autoFocusQuestion={autoFocus}
      texts={{
        addOption: t('action_add_option'),
        singleSelect: t('label_single_select'),
        multiSelect: t('label_multi_select'),
        questionPlaceholder: placeholder,
        optionPlaceholder: t('label_option_x'),
      }}
      onQuestionChange={(value) => onChange({ value })}
      onChangeMultiSelect={(multiple) => {
        onChange({ multiple })
        appCheckoutMultiChoiceToggleEvent(multiple)
      }}
      onChoicesChange={(choiceAction) => {
        onChange({ choices: choiceAction.data })
        if (choiceAction.type === ChoiceActionType.ADD) {
          appCheckoutMultiChoiceAddEvent(choiceAction.placeInTheList)
        } else if (choiceAction.type === ChoiceActionType.REMOVE) {
          appCheckoutMultiChoiceDeleteEvent(choiceAction.placeInTheList)
        }
      }}
      {...sharedProps}
    />
  ) : (
    <RegularField
      value={field.value}
      checked={field.required}
      custom={field.type === 'address' || field.type === 'text'}
      autoFocus={autoFocus}
      highlightLinks={highlightLinks}
      placeholder={placeholder}
      onChange={(value) => onChange({ value })}
      onCheckToggle={(required) => onChange({ required })}
      onDelete={onDelete}
      className={[styles.AdditionalField, className].join(' ')}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      }}
      {...rest}
      style={{ cursor: 'default' }}
    />
  )
}

export default React.memo(AdditionalField)
