import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import usePageEvents from '@features/analytics/usePageEvents'

import { newKey } from '@helpers/NodeIdGenerator'
import { useToast } from '@hooks/useToast'
import { SelectActionProps } from '@features/pickers/hooks/useSourcePicker'
import { nodeActions } from '../../../../actions'
import { ScrollAreaContainer } from '../../../../ui/models/ScrollArea'
import useScrollArea from '../../../../hooks/useScrollArea'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

const useCopyNodes = (boardId: string, folderId: string) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { scrollToBottom } = useScrollArea()
  const { pageInsertContentFromBoardsEvent } = usePageEvents()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const copyNodes = async (props: SelectActionProps) => {
    if (!isApiAvailableOrNotify()) return

    const key = newKey()

    dispatch(
      nodeActions.copyNodesAction({
        targetBoardId: boardId,
        targetFolderId: folderId,
        nodes: props.nodes.map((n) => n.id),
        key,
        fromParams: {
          fromBoardId: props.boardId,
          fromFolderId: props.folderId,
        },
      }),
    )

    scrollToBottom(ScrollAreaContainer.PAGE)
    toast(t('content_inserted_toast_msg'))
    pageInsertContentFromBoardsEvent(props.nodes.length, boardId, folderId)
  }

  return copyNodes
}

export default useCopyNodes
