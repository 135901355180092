import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { InputVerification, Spacer, Spinner } from 'boards-web-ui'
import parsePhoneNumber from 'libphonenumber-js'

import usePhoneVerificationEvents from '@features/analytics/usePhoneVerificationEvents'

import Screen from './Screen'

import styles from './VerificationCodeInput.module.css'

interface Props {
  errors: { [fieldName: string]: string }
  submitting: boolean
  phoneNumber: string
  verificationCode: string
  disabled: boolean
  setVerificationCode: (code: string) => void
  onFocus: () => void
  onCodeSubmit: (code: string) => Promise<void>
}
const VerificationCodeInput = ({
  errors,
  submitting,
  disabled,
  phoneNumber,
  verificationCode,
  setVerificationCode,
  onFocus,
  onCodeSubmit,
}: Props) => {
  const { t } = useTranslation()
  const { verificationCodeScreenViewEvent, verificationCodeSubmitedEvent } =
    usePhoneVerificationEvents()

  const phoneNumnberFormated =
    parsePhoneNumber(phoneNumber)?.formatInternational()

  useEffect(() => {
    verificationCodeScreenViewEvent()
  }, [verificationCodeScreenViewEvent])

  const handleChange = async (value: string) => {
    setVerificationCode(value)

    if (value.length === 6) {
      await onCodeSubmit(value)
      verificationCodeSubmitedEvent()
    }
  }

  return (
    <Screen
      title={t('enter_code_title')}
      text={t('enter_code_body', { phone_number: phoneNumnberFormated })}
      className={submitting ? styles.Loading : ''}
    >
      <Spacer height={50} />
      <div className={styles.InputVerificationWrapper}>
        <Spinner spinning={submitting} size="36px">
          <InputVerification
            length={6}
            value={verificationCode}
            errorMessage={disabled ? '' : errors.input}
            onChange={handleChange}
            disabled={submitting || disabled}
            onFocus={onFocus}
            autoFocus
          />
        </Spinner>
      </div>
    </Screen>
  )
}

export default VerificationCodeInput
