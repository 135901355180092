import { AppDispatch } from '../app/store'
import { Campaign, CampaignBanners } from '../_firebase/models/campaigns'

export const enum ACTION_TYPE {
  GET_CAMPAIGN = 'GET_CAMPAIGN',
  GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_ERROR = 'GET_CAMPAIGN_ERROR',

  GET_CAMPAIGN_BANNERS = 'GET_CAMPAIGN_BANNERS',
  GET_CAMPAIGN_BANNERS_SUCCESS = 'GET_CAMPAIGN_BANNERS_SUCCESS',
  GET_CAMPAIGN_BANNERS_ERROR = 'GET_CAMPAIGN_BANNERS_ERROR',

  HIDE_CAMPAIGN_BANNER = 'HIDE_CAMPAIGN_BANNER',
  HIDE_CAMPAIGN_BANNER_SUCCESS = 'HIDE_CAMPAIGN_BANNER_SUCCESS',
  HIDE_CAMPAIGN_BANNER_ERROR = 'HIDE_CAMPAIGN_BANNER_ERROR',
}

const getCampaignSuccess = (campaign: Campaign) => ({
  type: ACTION_TYPE.GET_CAMPAIGN_SUCCESS,
  campaign,
})

const getCampaignError = (error: string) => ({
  type: ACTION_TYPE.GET_CAMPAIGN_ERROR,
  error,
})

const getCampaignBannersSuccess = (banners: CampaignBanners) => ({
  type: ACTION_TYPE.GET_CAMPAIGN_BANNERS_SUCCESS,
  banners,
})

const getCampaignBannersError = (error: string) => ({
  type: ACTION_TYPE.GET_CAMPAIGN_BANNERS_ERROR,
  error,
})

const hideCampaignBannerError = (error: string) => ({
  type: ACTION_TYPE.HIDE_CAMPAIGN_BANNER_ERROR,
  error,
})

const hideCampaignBannerSuccess = (bannerIds: Array<string>) => ({
  type: ACTION_TYPE.HIDE_CAMPAIGN_BANNER_SUCCESS,
  bannerIds,
})

const getCampaign =
  (campaignId: string) =>
  async (
    dispatch: AppDispatch,
    getState: unknown,
    firebase: {
      firebaseAPI: { getCampaign: (campaignId: string) => Promise<Campaign> }
    },
  ) => {
    const { firebaseAPI } = firebase
    try {
      dispatch({ type: ACTION_TYPE.GET_CAMPAIGN, campaignId })
      const campaign = await firebaseAPI.getCampaign(campaignId)
      dispatch(getCampaignSuccess(campaign))
    } catch (e) {
      dispatch(getCampaignError(e as string))
    }
  }

const getCampaignBanners =
  () =>
  async (
    dispatch: AppDispatch,
    getState: unknown,
    firebase: {
      firebaseAPI: { getCampaignBanners: () => Promise<CampaignBanners> }
    },
  ) => {
    const { firebaseAPI } = firebase
    try {
      dispatch({ type: ACTION_TYPE.GET_CAMPAIGN_BANNERS })
      const banners = await firebaseAPI.getCampaignBanners()
      dispatch(getCampaignBannersSuccess(banners))
    } catch (e) {
      dispatch(getCampaignBannersError(e as string))
    }
  }

const markCampaignBannerAsShown =
  (bannerIds: Array<string>) =>
  async (
    dispatch: AppDispatch,
    getState: unknown,
    firebase: {
      firebaseAPI: {
        markBannerAsShown: (bannerIds: Array<string>) => Promise<void>
      }
    },
  ) => {
    if (!bannerIds || !bannerIds.length) {
      return
    }
    const { firebaseAPI } = firebase
    try {
      dispatch({ type: ACTION_TYPE.HIDE_CAMPAIGN_BANNER, bannerIds })
      await firebaseAPI.markBannerAsShown(bannerIds)
      dispatch(hideCampaignBannerSuccess(bannerIds))
    } catch (e) {
      dispatch(hideCampaignBannerError(e as string))
    }
  }

const actions = {
  getCampaignSuccess,
  getCampaignError,

  getCampaignBannersSuccess,
  getCampaignBannersError,

  hideCampaignBannerSuccess,
  hideCampaignBannerError,
} as const

export const asyncActions = {
  getCampaign,

  getCampaignBanners,
  markCampaignBannerAsShown,
} as const

export default actions
