import { useCallback, useEffect, useState } from 'react'
import { Node } from '../app/models'
import useBoard from './useBoard'

interface INodes {
  [id: string]: Node
}

interface IUseBoardNodes {
  getNode: (nid: string) => Node
}

const useBoardNodes = (): IUseBoardNodes => {
  const { folders } = useBoard()
  const [nodes, setNodes] = useState<INodes>({})

  useEffect(() => {
    const boardNodes: INodes = {}
    Object.keys(folders || {}).forEach((fid) => {
      const folder = folders[fid]
      folder.content.forEach((node) => {
        boardNodes[node.id] = node
      })
    })

    setNodes(boardNodes)
  }, [folders])

  const getNode = useCallback((nodeId: string) => nodes[nodeId], [nodes])

  return {
    getNode,
  }
}

export default useBoardNodes
