import React, { ReactNode, useState, useRef, useMemo, useCallback } from 'react'
import { useDetectOutsideClick } from '../../../../../hooks/useDetectOutsideClick'
import { SelectContext } from './SelectContext'

import styles from './Select.module.css'

const Select: React.FC<
  React.PropsWithChildren<{
    children: ReactNode | ReactNode[]
    defaultValue?: string
    placeholder?: string
    onSelect: (option: string) => void
  }>
> = ({ children, defaultValue, placeholder, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || '')
  const [selectedLabel, setSelectedLabel] = useState('')
  const showDropdownHandler = () => setShowDropdown(!showDropdown)
  const selectPlaceholder = placeholder || 'Choose an option'
  const selectContainerRef = useRef(null)
  const [showDropdown, setShowDropdown] = useDetectOutsideClick(
    selectContainerRef,
    false,
  )

  const updateSelectedOption = useCallback(
    (option: string, label: string) => {
      setSelectedLabel(label)
      setSelectedOption(option)
      setShowDropdown(false)
      onSelect(option)
    },
    [setSelectedLabel, setSelectedOption, setShowDropdown, onSelect],
  )

  const value = useMemo(() => {
    return { selectedOption, changeSelectedOption: updateSelectedOption }
  }, [selectedOption, updateSelectedOption])

  return (
    <SelectContext.Provider value={value}>
      <div className={styles.SelectContainer} ref={selectContainerRef}>
        <div
          className={
            showDropdown
              ? `${styles.SelectedText} ${styles.Active}`
              : styles.SelectedText
          }
          onClick={showDropdownHandler}
        >
          {selectedLabel.length > 0 ? selectedLabel : selectPlaceholder}
        </div>
        <ul
          className={
            showDropdown
              ? `${styles.SelectOptions} ${styles.ShowDropdownOptions}`
              : `${styles.SelectOptions} ${styles.HideDropdownOptions}`
          }
        >
          {children}
        </ul>
      </div>
    </SelectContext.Provider>
  )
}

export default Select
