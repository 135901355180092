export const enum ACTION_TYPE {
  SET_PERSIST_USER = 'SET_PERSIST_USER',
}

const setPersistUser = (isPersist: boolean) => ({
  type: ACTION_TYPE.SET_PERSIST_USER,
  isPersist,
})

const actions = {
  setPersistUser,
} as const

export default actions
