import { REMOTE_CONFIG_KEYS, VERIFICATION_OPTIONS } from '../app/models'
import useRemoteConfig from './useRemoteConfig'

const {
  INSIGHTS_ENABLED,
  INSIGHTS_DATA_AVAILABLE_DATE,
  UPDATES_ENABLED,
  WEB_USER_PROFILING_ENABLED,
  SHARED_FOLDER_ENABLED,
  PAGE_BUILDER_ENABLED,
  MONETIZATION_BANNER_ENABLED,
  GEO_MONETIZATION_BANNER_ENABLED,
  UPLOAD_VIDEO_ENABLED,
  PHONE_VERIFICATION_OPTIONS,
  VIEW_ONLY_LABEL_ENABLED,
} = REMOTE_CONFIG_KEYS

const useFeatureFlags = () => {
  const userProfilingFeatureFlag = useRemoteConfig(WEB_USER_PROFILING_ENABLED)
  const pageBuilderFeatureFlag = useRemoteConfig(PAGE_BUILDER_ENABLED)
  const sharedFolderFeatureFlag = useRemoteConfig(SHARED_FOLDER_ENABLED)

  const insightsFeatureFlag = useRemoteConfig(INSIGHTS_ENABLED)
  const insightsAvailableDateFeatureFlag = useRemoteConfig(
    INSIGHTS_DATA_AVAILABLE_DATE,
    1652313600000,
  )

  const updatesFeatureFlag = useRemoteConfig(UPDATES_ENABLED)
  const monetizationBannerEnabled = useRemoteConfig(MONETIZATION_BANNER_ENABLED)
  const geoMonetizationBannerFeatureFlag = useRemoteConfig(
    GEO_MONETIZATION_BANNER_ENABLED,
  )
  const uploadVideoEnabledFeatureFlag = useRemoteConfig(UPLOAD_VIDEO_ENABLED)
  const phoneVerificationEnabledFeatureFlag = useRemoteConfig(
    PHONE_VERIFICATION_OPTIONS,
  )

  const viewOnlyLabelFeatureEnabledFeatureFlag = useRemoteConfig(
    VIEW_ONLY_LABEL_ENABLED,
  )

  return {
    sharedFolderFeatureFlag,
    userProfilingFeatureFlag,

    updatesFeatureFlag,
    pageBuilderFeatureFlag,

    insightsFeatureFlag,
    insightsAvailableDateFeatureFlag,

    monetizationBannerEnabled,
    geoMonetizationBannerFeatureFlag,

    viewOnlyLabelFeatureEnabledFeatureFlag,

    uploadVideoEnabledFeatureFlag,
    phoneVerificationFeatureFlag: {
      disabled:
        phoneVerificationEnabledFeatureFlag === VERIFICATION_OPTIONS.DISABLED,
      skipAllowed:
        phoneVerificationEnabledFeatureFlag ===
        VERIFICATION_OPTIONS.SKIP_ALLOWED,
      skipNotAllowed:
        phoneVerificationEnabledFeatureFlag ===
        VERIFICATION_OPTIONS.SKIP_NOT_ALLOWED,
    },
  }
}

export default useFeatureFlags
