import { useTranslation } from 'react-i18next'
import { useDialog } from 'boards-web-ui'

import Modal from '../../../../components/Modal'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import useShareAPI from '../hooks/useShareAPI'
import { ReactComponent as ExportListIcon } from '../icons/ExportList.svg'
import MembersHeader from './MembersHeader'
import Members from './Members'
import useSelectedEmails from '../hooks/useSelectedEmails'

interface Props {
  goBack: () => void
}
const MembersManagement = ({ goBack }: Props) => {
  const { close } = useDialog()
  const { t } = useTranslation()
  const confirmDialog = useConfirmDialog()
  const { selectedEmails, toggleEmail, resetEmails } = useSelectedEmails()
  const { role, permissions, exportPermissions, changePermission, unShare } =
    useShareAPI()

  const handleDeleteMembers = () => {
    unShare(selectedEmails)

    resetEmails()
  }

  const handleDelete = () =>
    confirmDialog({
      title: t('app_boards_members_remove_members_title'),
      message: t('app_boards_members_remove_members_msg', {
        count: selectedEmails.length,
      }),
      confirmText: t('action_remove'),
      cancelText: t('action_cancel'),
      onConfirm: handleDeleteMembers,
    })

  const options =
    permissions.length > 0
      ? [
          {
            text: t('action_export_list'),
            onClick: exportPermissions,
            icon: <ExportListIcon />,
          },
        ]
      : []

  return (
    <Modal
      variant="small"
      Header={
        selectedEmails.length ? (
          <MembersHeader
            members={selectedEmails.length}
            onClose={resetEmails}
            onDelete={handleDelete}
          />
        ) : undefined
      }
      options={options}
      onClickOutside={close}
      onBack={goBack}
      open
    >
      <Members
        role={role}
        permissions={permissions}
        selectedEmails={selectedEmails}
        goBack={goBack}
        toggleUser={toggleEmail}
        onChangePermission={changePermission}
      />
    </Modal>
  )
}

export default MembersManagement
