import React, { PropsWithChildren } from 'react'

import Tooltip from '../../../../ui/components/Tooltip'
import { TooltipPositions } from '../../../../ui/models/tooltip'
import { AnimatedArrows } from '../../../../components/AnimatedArrows'

import styles from './CreateBoardTooltip.module.css'

type Props = PropsWithChildren<{
  isOpen: boolean
}>
const CreateBoardTooltip: React.FC<Props> = ({ children, isOpen }) => {
  return (
    <Tooltip
      as="div"
      disabled
      followCursorY
      isOpen={isOpen}
      style={{ padding: '10px 15px' }}
      position={TooltipPositions.RIGHT}
      data={<AnimatedArrows className={styles.Margin} />}
    >
      {children}
    </Tooltip>
  )
}

export default React.memo(CreateBoardTooltip)
