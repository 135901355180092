/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum TargetScreens {
  PAGE = 'page',
  FOLDER = 'folder',
}

export enum ViewSource {
  analytics = 'analytics',
  postAnnouncement = 'post_announcement',
  webTrialBanner = 'web_trial_banner',
  upgradeButton = 'upgrade_button',
  viewPlansButton = 'view_plans_button',
  videoHosted = 'video_hosted',
  messages = 'messages',
  formGoogleSheets = 'form_google_sheets',

  boardsLimitReached = 'boards_limit_reached',
  activateBoardList = 'activate_board_list',
  activateBoardScreen = 'activate_board_screen',
  list = 'activate_board_list',
  home = 'home',
  destinationPicker = 'destination_picker',
  sharedFolder = 'shared_folder',
  board = 'board',
}
