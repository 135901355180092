import React, { PropsWithChildren } from 'react'

import {
  AnimatedArrows,
  AnimatedArrowDirection,
} from '../../../../components/AnimatedArrows'

import styles from './AddContentTooltip.module.css'

type Props = PropsWithChildren<{
  isOpen: boolean
}>
const AddContentTooltip: React.FC<Props> = ({ isOpen, children }) => {
  return (
    <div className={styles.Root}>
      {children}
      {isOpen && (
        <AnimatedArrows
          className={styles.Arrow}
          direction={AnimatedArrowDirection.top}
        />
      )}
    </div>
  )
}

export default AddContentTooltip
