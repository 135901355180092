import { Trans } from 'react-i18next'
import ProgressBar from '../../app/features/nodeEditor/components/ProgressBar'

import styles from './VideoUsage.module.css'

interface Props {
  limit: number | string
  used: number
}
const VideoUsage = ({ used, limit }: Props) => {
  const progress = Number.isInteger(limit)
    ? Math.min(Math.max(Math.round((used / Number(limit)) * 100), 0), 100)
    : 0

  const limitReached = progress >= 90

  const classes = [styles.Root]
  classes.push(limitReached ? styles.Red : styles.Blue)

  return (
    <div className={classes.join(' ')}>
      <div className={styles.UsageLabel}>
        <Trans i18nKey={'video_upload_video_usage'} />
      </div>
      <ProgressBar progress={progress} isRed={limitReached} />
      <div className={styles.UsageLimits}>
        <span>
          {used} / {limit}
        </span>{' '}
        <Trans i18nKey={'video_upload_videos'} />
      </div>
    </div>
  )
}

export default VideoUsage
