import React, { SyntheticEvent, useCallback, useState, memo, FC } from 'react'

import { useToast } from '@hooks/useToast'
import { useAuth } from '@hooks/useAuth'

import { DateDiff } from '../../../../ui/components/DateDiff'
import Grid from '../../../../elements/Grid'
import { UiUpdatesMessage } from '../../../models'
import { useUpdatesMessage } from '../hooks/useUpdatesMessage'
import { useOnMount } from '../../../../ui/hooks/useOnMount'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'

import UpdatesMessageHeader from './UpdatesMessageHeader'
import UpdatesMessageBody from './UpdatesMessageBody'
import UpdatesMessageSeenBy from './UpdatesMessageSeenBy'
import styles from './UpdatesMessage.module.css'

type Props = {
  message: UiUpdatesMessage
  className?: string
  presentationMode?: boolean
  onClick?: (event: SyntheticEvent) => void
}

const UpdatesMessage: FC<Props> = ({
  message,
  className,
  presentationMode = false,
  onClick,
}) => {
  const { user } = useAuth()
  const { messageView } = useUpdatesEvents()
  const { read, count } = useUpdatesMessage(message?.id)
  const { toastSent } = useUpdatesTranslations()
  const [postedJustNow, setPostedJustNow] = useState(
    message?.unread && presentationMode,
  )
  const toast = useToast()

  const onMount = useCallback(() => {
    if (!presentationMode) {
      messageView(message?.node, message?.id, count || 0)
    }

    if (message?.unread && !presentationMode) {
      read()
    }

    if (postedJustNow && user?.uid === message.sender.id) {
      toast(toastSent(), true, 300)
      const timer = setTimeout(() => {
        setPostedJustNow(false)
        clearTimeout(timer)
      }, 1000)
    }
  }, [
    toast,
    toastSent,
    postedJustNow,
    message,
    presentationMode,
    read,
    count,
    user,
    messageView,
  ])

  useOnMount(onMount)

  return (
    <div
      className={[
        styles.Root,
        presentationMode ? styles.Hovered : '',
        message?.unread && presentationMode ? styles.Unread : '',
        postedJustNow ? styles.PostedJustNow : '',
        className,
      ].join(' ')}
      onClick={onClick}
    >
      <UpdatesMessageHeader
        presentationMode={presentationMode}
        boardTitle={message?.title}
        author={message?.sender?.properties?.name}
        icon={message?.icon}
      />

      <UpdatesMessageBody
        title={message?.properties?.title}
        text={message?.properties?.text}
        presentationMode={presentationMode}
      />

      <Grid justifyContent="space-between">
        <DateDiff date={message ? message.time.seconds * 1000 : 0} />
        <UpdatesMessageSeenBy count={message ? message.viewCount : 0} />
      </Grid>
    </div>
  )
}

export default memo(UpdatesMessage)
