import NoTitle from './NoTitle'
import ContentItem from './ContentItem'
import ContentItemCover, { CoverBackground } from './ContentItemCover'
import { ReactComponent as VideoStreamingIcon } from '../icons/VideoStreaming.svg'
import { NodeType } from '../../../models'

interface Props {
  thumbnail?: string
  text: string
  count: number
}

const ContentVideoStreaming = ({ thumbnail, text, count }: Props) => {
  const textElement = text || <NoTitle type={NodeType.VIDEO_STREAMING} />

  return (
    <ContentItem text={textElement} count={count}>
      <ContentItemCover
        background={CoverBackground.WHITE}
        cover={thumbnail}
        alt={text}
        icon={<VideoStreamingIcon />}
      />
    </ContentItem>
  )
}

export default ContentVideoStreaming
