import {
  boardsOrderActions,
  firebaseEventsAsyncActions,
} from '../../../../actions'

export const setBoardsOrder =
  (order) =>
  async (dispatch, _, { firebaseAPI }) => {
    dispatch(boardsOrderActions.setBoardsOrder(order))
    dispatch(firebaseEventsAsyncActions.logEvent('app_boards_reorder'))

    await firebaseAPI.setBoardsOrder(order)
  }

export default setBoardsOrder
