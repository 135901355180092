import { useTranslation } from 'react-i18next'

import useConfirmDialog from '@hooks/useConfirmDialog'
import usePageAPI from '@features/toolbar/hooks/usePageAPI'
import useBannerEvents from '@features/editorPages/hooks/useBannerEvents'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import usePaywall from '@hooks/usePaywall'

import { Folder } from '../../../models'

const useBrandedPagesBanner = (
  boardId: string,
  folderId: string,
  folder: Folder,
  preview?: boolean,
) => {
  const { update } = usePageAPI()
  const { profile } = useProfileInformation()
  const confirmDialog = useConfirmDialog()
  const { appPageRemoveBannerClick, appPageBannerClick } = useBannerEvents()
  const { t } = useTranslation()

  const handleUnBrandedDeleteBanner = () => {
    confirmDialog({
      title: t('confirmation_message_delete_selected_key'),
      message: t('dialog_delete_this_key'),
      confirmText: t('action_delete'),
      cancelText: t('action_cancel'),
      onConfirm: () => {
        appPageRemoveBannerClick(
          boardId,
          folder.id,
          folder.shortcut,
          !!profile.plan?.features?.unbrandedPages,
        )
        update(boardId, folderId, folder?.id, {
          title: folder?.title || t('page_my_page'),
          unbranded: true,
          themeId: folder?.themeId
        })
      },
    })
  }
  const { openPaywall } = usePaywall()

  const handleBrandedDeleteBanner = () => {
    if (preview) {
      appPageBannerClick(boardId, folder.id, folder.shortcut)
    } else {
      appPageRemoveBannerClick(
        boardId,
        folder.id,
        folder.shortcut,
        !!profile.plan?.features?.unbrandedPages,
      )
    }

    openPaywall({ source: PaywallSourceEnum.REMOVE_PAGE_CAMPAIGN })
  }

  const onDeleteBrandedBanner = () => {
    return profile.plan?.features?.unbrandedPages
      ? handleUnBrandedDeleteBanner()
      : handleBrandedDeleteBanner()
  }

  return {
    unbrandedPages: !!profile.plan?.features?.unbrandedPages,
    unbrandedFolder: folder.unbranded,
    onDeleteBrandedBanner,
  } as const
}

export default useBrandedPagesBanner
