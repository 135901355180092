import { useDialog } from 'boards-web-ui'
import { SourcePickerModal } from '@features/pickers/sourcePicker/SourcePickerModal'
import { Node } from '../../../models'

export interface SelectActionProps {
  boardId: string
  folderId: string
  nodes: Node[]
}
export type SelectAction = (props: SelectActionProps) => void

export const useSourcePicker = (action: SelectAction) => {
  const { open, close } = useDialog()

  const openSourcePicker = () => {
    open(<SourcePickerModal onClose={close} action={action} />, {
      fullScreen: true,
      closeOnOverlayClick: true,
      overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
      withFullScreenDialogContainer: false,
    })
  }

  return {
    selectContent: openSourcePicker,
  }
}
