import { Permission } from '../app/models'

export const enum ACTION_TYPE {
  UPDATE_PERMISSION = 'UPDATE_PERMISSION',
  SET_PERMISSION = 'SET_PERMISSION',
  DELETE_PERMISSION = 'DELETE_PERMISSION',
}

const setPermission = (
  board: string,
  permissions: { [key: string]: Permission },
  final?: boolean,
) => ({
  type: ACTION_TYPE.SET_PERMISSION,
  board,
  permissions,
  final,
})
const deletePermission = (board: string, recipient: string) => ({
  type: ACTION_TYPE.DELETE_PERMISSION,
  board,
  recipient,
})

const updatePermission = (
  board: string,
  email: string,
  permission: string,
) => ({
  type: ACTION_TYPE.UPDATE_PERMISSION,
  board,
  email,
  permission,
})

const actions = {
  setPermission,
  deletePermission,
  updatePermission,
} as const

export default actions
