import React, { ChangeEventHandler, FocusEventHandler, useState } from 'react'

import AutoGrowingInput, {
  ContentSize,
} from '../../../../ui/components/AutoGrowingInput'
import BoardTitleTooltip from '../../../../ui/components/DisableStateTooltip'

import styles from './ContentTitle.module.css'

type Props = {
  size?: ContentSize
  title?: string
  tooltip: string
  placeholder?: string
  maxlength?: number
  autofocus?: boolean
  hideTitleTooltip?: boolean
  readOnly?: boolean
  onFocus?: (title: string) => void
  onBlur?: (title: string) => void
  onEnter?: (title: string) => void
  onChange?: (title: string) => void
  disabled?: boolean
}
const ContentTitle: React.FC<Props> = ({
  size,
  placeholder,
  maxlength = 30,
  autofocus = false,
  hideTitleTooltip = false,
  readOnly,
  title = '',
  tooltip,
  onFocus,
  onBlur,
  onEnter,
  onChange,
  disabled,
}) => {
  const [focused, setFocused] = useState<boolean>(autofocus)

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    if (onFocus) {
      onFocus(e.target.value)
    }
    setFocused(true)
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (onBlur) {
      onBlur(e.target.value)
    }
    setFocused(false)
  }

  const handleEnter: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onEnter) {
      onEnter(e.target.value)
    }
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <>
      <BoardTitleTooltip
        data={tooltip}
        isOpen={focused ? false : undefined}
        disabled={disabled || focused || !!readOnly || hideTitleTooltip}
        className={styles.Root}
      >
        <AutoGrowingInput
          value={title}
          size={size}
          placeholder={placeholder}
          maxlength={maxlength}
          readOnly={readOnly}
          autofocus={autofocus}
          disabled={disabled}
          onEnter={handleEnter}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </BoardTitleTooltip>

      {focused && !readOnly && (
        <span
          className={[
            styles.CharactersCount,
            size === ContentSize.Big ? styles.CharactersCountBig : '',
          ].join(' ')}
        >
          {title.length}/{maxlength} characters
        </span>
      )}
    </>
  )
}

export { ContentSize as ContentTitleSize } from '../../../../ui/components/AutoGrowingInput'
export default React.memo(ContentTitle)
