import { DocumentSnapshot } from 'firebase/firestore'
import { AppDispatch } from '../../../app/store'
import { BoardItem } from '../../../app/models'
import { boardActions } from '../../../actions'
import { transformBoard } from '../transformers/BoardTransformer'

export const BoardSnapshot =
  (item: BoardItem, dispatch: AppDispatch) => (doc: DocumentSnapshot) => {
    if (doc.exists()) {
      const board = transformBoard(item, doc)

      dispatch(boardActions.setBoard(item.boardId, board))
    }
  }
