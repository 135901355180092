import { useEffect, useState } from 'react'
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config'
import { REMOTE_CONFIG_DEFAULT_VALUE } from '../app/models'

const remoteConfig = getRemoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 600000

const useRemoteConfig = (key, defaultValue) => {
  const defaultProperty =
    typeof defaultValue === 'undefined'
      ? REMOTE_CONFIG_DEFAULT_VALUE[key]
      : defaultValue

  // useState because need to avoid displaying
  // the default value if the component that uses
  // this hook was already rendered at the time
  // the response was received from the API.
  // This can happen because the value is passed by reference
  const [value, setValue] = useState(defaultProperty)

  remoteConfig.defaultConfig = { [key]: defaultProperty }

  useEffect(() => {
    ;(async () => {
      await fetchAndActivate(remoteConfig)
      const config = getValue(remoteConfig, key)

      if (typeof REMOTE_CONFIG_DEFAULT_VALUE[key] === 'boolean') {
        setValue(config.asBoolean())
      } else if (typeof REMOTE_CONFIG_DEFAULT_VALUE[key] === 'string') {
        // eslint-disable-next-line no-underscore-dangle
        setValue(config.asString())
      } else if (typeof REMOTE_CONFIG_DEFAULT_VALUE[key] === 'object') {
        try {
          setValue(JSON.parse(config.asString()))
        } catch (error) {
          setValue(defaultProperty)
        }
      } else {
        setValue(config.asNumber())
      }
    })()
  }, [key, defaultProperty])

  return value
}

export default useRemoteConfig
