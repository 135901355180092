import React, {
  FC,
  ReactComponentElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DraggableList, FieldTypes, FormField } from 'boards-web-ui'

import useFormsEvents from '@features/analytics/useFormsEvents'
import AddButton from '../../../components/AddButton'

import { ADDITIONAL_FIELDS_TYPE } from '../constants'
import { useEditForms } from '../hooks'

import EditFormAdditionalField from './EditFormAdditionalField'
import styles from './EditFormAdditionalFields.module.css'

type Props = {
  onAfterAddAdditionalField?: () => void
}
const EditFormAdditionalFields: FC<Props> = ({ onAfterAddAdditionalField }) => {
  const { t } = useTranslation()
  const { questionAddEvent } = useFormsEvents()
  const {
    formsFields,
    onUpdateFormsField,
    onDeleteFormsField,
    onAddFormsField,
    onFormFieldsReOrder,
  } = useEditForms({
    onAfterAddAdditionalField,
  })
  const [autoFocusIndex, setAutoFocusIndex] = useState<number>(0)

  const additionalFieldsReorderHandler = (items?: ReactNode[]) => {
    const additionalFieldsInNewOrder: Array<FormField> | undefined = items?.map(
      (additionalFieldComponent) => {
        const AdditionalFieldProps = (
          additionalFieldComponent as ReactComponentElement<
            typeof EditFormAdditionalField
          >
        ).props
        return {
          ...AdditionalFieldProps.field,
        }
      },
    )

    onFormFieldsReOrder(additionalFieldsInNewOrder || [])
  }

  const onAdditionFieldBlurHandler = useCallback(
    (field: FormField) => {
      if (!field.label?.trim()?.length) {
        onUpdateFormsField({
          ...field,
          label: t(ADDITIONAL_FIELDS_TYPE[field.type]?.defaultValue) || '',
        })
      }
    },
    [onUpdateFormsField, t],
  )

  const renderAdditionalFields = useMemo(() => {
    return formsFields.map((field: FormField, index) => (
      <EditFormAdditionalField
        key={field.id}
        data-key={field.id}
        autoFocus={autoFocusIndex === index && !field?.label}
        placeholder={
          field.type === FieldTypes.CHOICE
            ? t('label_type_your_question')
            : t('forms_add_field_label')
        }
        field={field}
        onBlur={() => onAdditionFieldBlurHandler(field)}
        onDelete={() => onDeleteFormsField(field)}
        onChange={(updatedField) =>
          onUpdateFormsField({
            ...field,
            ...updatedField,
          })
        }
      />
    ))
  }, [
    t,
    autoFocusIndex,
    formsFields,
    onUpdateFormsField,
    onDeleteFormsField,
    onAdditionFieldBlurHandler,
  ])

  return (
    <>
      <label className={styles.Label}>
        {(formsFields || []).length > 0 && <Trans i18nKey={'label_required'} />}
      </label>
      <DraggableList onReOrderEnd={additionalFieldsReorderHandler}>
        {renderAdditionalFields}
      </DraggableList>
      <AddButton
        onClick={() => {
          questionAddEvent()
          onAddFormsField()
          setAutoFocusIndex(formsFields.length)
        }}
      >
        <Trans i18nKey={'forms_add_field'} />
      </AddButton>
    </>
  )
}

export default React.memo(EditFormAdditionalFields)
