import { useTranslation } from 'react-i18next'

const useEditTranslations = () => {
  const { t } = useTranslation()
  const insightsTabTitle = () => t('insights_tab_title')
  const insightsTabLink = () =>
    t('banner_premium_link', { origin: 'analytics_tab_locked' })
  const contentTabTitle = () => t('content_tab_title')

  return {
    insightsTabTitle,
    insightsTabLink,
    contentTabTitle,
  } as const
}

export default useEditTranslations
