import settings from '../config'

const { columnWidth, columnSpacing, rowHeight, rowSpacing } = settings

class Tiler {
  constructor() {
    this.rowIndex = [0, 0]
  }

  clone() {
    const copy = new Tiler()
    // eslint-disable-next-line
    copy.rowIndex[0] = this.rowIndex[0]
    // eslint-disable-next-line
    copy.rowIndex[1] = this.rowIndex[1]
    return copy
  }

  addTile(rowCount, columnCount) {
    const column = this.rowIndex[0] <= this.rowIndex[1] ? 0 : 1
    const row = this.rowIndex[column]

    const x = column * (columnWidth + columnSpacing)
    const y = row * (rowHeight + rowSpacing)

    this.rowIndex[column] += rowCount

    let width = 0
    if (columnCount > 0) {
      width = (columnWidth + columnSpacing) * columnCount - columnSpacing
    }

    let height = 0
    if (rowCount > 1) {
      height = (rowHeight + rowSpacing) * rowCount - rowSpacing
    }

    return { x, y, width, height, column, rowCount }
  }

  get size() {
    const rowCount = Math.max(this.rowIndex[0], this.rowIndex[1])

    const width = 4 * (columnWidth + columnSpacing) - columnSpacing
    const height = rowCount * (rowHeight + rowSpacing) - rowSpacing

    return { width, height }
  }
}

export default Tiler
