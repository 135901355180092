import { useState } from 'react'

import { useAuth } from '@hooks/useAuth'

import { DropDown } from '../../../ui/components/DropDown'
import { ProfileMenu } from './components/ProfileMenu'
import { ProfileAvatar } from './components/ProfileAvatar'
import { ProfileTooltip } from './components/ProfileTooltip'

import styles from './Profile.module.css'

export const Profile = () => {
  const { isLoggedIn } = useAuth()
  const [dropDownIsOpen, setDropDownIsOpen] = useState<boolean>(false)

  if (!isLoggedIn) {
    return null
  }

  return (
    <DropDown
      onToggle={setDropDownIsOpen}
      className={styles.Menu}
      content={<ProfileMenu />}
    >
      <ProfileTooltip
        isOpen={dropDownIsOpen ? false : undefined}
        disabled={dropDownIsOpen}
      >
        <ProfileAvatar />
      </ProfileTooltip>
    </DropDown>
  )
}
