import NodeMargin from './NodeMargin'
import config from '../../config'

import styles from './NodeText.module.css'

interface Props {
  text: string
  title: string
}

const Title: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className={styles.Title}>{children}</div>
)
const Text: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className={styles.Text}>{children}</div>
)

/** @deprecated Prepare migration instead for "Node" component in "boards-web-ui" */
const NodeText = ({ title, text }: Props) => {
  const textHasOneLine =
    (title && title.length) > config.titleBreakLineThreshold

  let titleClass = styles.TitleWithTwoLinesText
  if (textHasOneLine) {
    titleClass = styles.TitleWithOneLineText
  }
  if (!title || !text) {
    titleClass = styles.TitleOrText
  }

  return (
    <div className={styles.Root}>
      <NodeMargin>
        <div className={titleClass}>
          {title && <Title>{title}</Title>}
          {text && <Text>{text}</Text>}
        </div>
      </NodeMargin>
    </div>
  )
}

export default NodeText
