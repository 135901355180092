import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from '../icons/Close.svg'
import styles from './Chip.module.css'

const Chip = ({ label, color, variant, avatar, onDelete }) => {
  const classes = [styles.Root]
  classes.push(styles[`${color}-${variant}`])

  return (
    <div className={classes.join(' ')}>
      {avatar && avatar}
      <span className={styles.Label}>{label}</span>
      {onDelete && (
        <CloseIcon className={styles.CloseIcon} onClick={onDelete} />
      )}
    </div>
  )
}

Chip.propTypes = {
  variant: PropTypes.oneOf(['filled', 'outlined']),
  color: PropTypes.oneOf(['default', 'error']),
  label: PropTypes.string,
  avatar: PropTypes.element,
  onDelete: PropTypes.func,
}

export default Chip
