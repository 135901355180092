import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import BannerComponent from './Banner'

export type Banner = {
  id?: string
  component: ReactNode
}

export type BannerApi = {
  banners: Array<Banner>
  showBanner: (banner: Banner) => string
  hideBanner: (id: string) => void
}

export const BannerContent = createContext<BannerApi>({
  banners: [],
  // eslint-disable-next-line
  showBanner: (banner: Banner): string => {
    throw new Error(
      `You do not need to use this context by ref, please use useBanner hook`,
    )
  },
  // eslint-disable-next-line
  hideBanner: (id: string) => {
    throw new Error(
      `You do not need to use this context by ref, please use useBanner hook`,
    )
  },
})
export const BannerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [banners, setBanners] = useState<Array<Banner>>([])

  const showBanner = useCallback(
    (banner: Banner): string => {
      const id = banner.id || new Date().getTime().toString()

      const check = banners.find((b) => b.id === id)

      if (typeof check !== 'undefined') {
        return check.id as string
      }

      setBanners((_banners) => [
        ..._banners,
        {
          ...banner,
          id,
        },
      ])

      return id
    },
    [banners, setBanners],
  )

  const hideBanner = useCallback(
    (id: string) => {
      setBanners((_banners) => _banners.filter((_banner) => _banner.id !== id))
    },
    [setBanners],
  )

  const bannerApi: BannerApi = useMemo(
    () => ({
      banners,
      showBanner,
      hideBanner,
    }),
    // eslint-disable-next-line
    [showBanner, hideBanner],
  )

  return (
    <BannerContent.Provider value={bannerApi}>
      {banners.map(({ id, component }) => (
        <BannerComponent key={id}>{component}</BannerComponent>
      ))}
      {children}
    </BannerContent.Provider>
  )
}
