import React, { FC, memo } from 'react'

import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'

import styles from './UpdatesMessageHeader.module.css'

type Props = {
  presentationMode?: boolean
  boardTitle: string
  icon: string
  author?: string
  className?: string
}

export const UpdatesMessageHeader: FC<Props> = ({
  presentationMode,
  icon,
  boardTitle,
  author,
  className,
}) => {
  const { postedBy } = useUpdatesTranslations()

  return (
    <div
      className={[
        styles.Root,
        className,
        presentationMode ? '' : styles.Margin,
      ].join(' ')}
    >
      {icon && <span className={styles.Icon}>{icon}</span>}
      {(boardTitle || author) && (
        <div className={styles.Wrap}>
          <span className={styles.BoardTitle}>{boardTitle}</span>
          {author && <span className={styles.Author}>{postedBy(author)}</span>}
        </div>
      )}
    </div>
  )
}

export default memo(UpdatesMessageHeader)
