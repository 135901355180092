import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import FileSaver from 'file-saver'

import { useToast } from '@hooks/useToast'

import { membersActions, permissionActions } from '../../../../actions'
import useBoard from '../../../../hooks/useBoard'
import useShareEvents from '../../analytics/useShareEvents'

const useShareAPI = () => {
  const toast = useToast()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { boardId, isOwnBoard, permissions, role } = useBoard()
  const {
    addMembersEvent,
    addMembersForwardEvent,
    removeMembersEvent,
    exportMemberListEvent,
    changePermissionEvent,
  } = useShareEvents()

  const share = (emails: string[], permission: string) => {
    dispatch(membersActions.addMembers(boardId, emails, permission))

    toast(t('app_boards_members_success_msg', { count: emails.length }))

    if (isOwnBoard) {
      addMembersEvent(emails.length, permission)
      return
    }

    addMembersForwardEvent(emails.length, permission)
  }

  const unShare = (emails: string[]) => {
    dispatch(membersActions.removeMembers(boardId, emails))

    removeMembersEvent(emails.length)
  }

  const changePermission = (email: string, permission: string) => {
    dispatch(permissionActions.updatePermission(boardId, email, permission))

    changePermissionEvent()
  }

  const exportPermissions = () => {
    const fileName = `${boardId}-permissions.txt`
    const recipients = permissions.map((p) => p.recipient)
    const blob = new Blob([recipients.join('\n')], {
      type: 'text/plain;charset=utf-8',
    })

    FileSaver.saveAs(blob, fileName)

    exportMemberListEvent(permissions.length)
  }

  return {
    role,
    permissions,
    share,
    unShare,
    changePermission,
    exportPermissions,
  } as const
}

export default useShareAPI
