import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'

export const useFormsCheckoutPaidPlansEvents = () => {
  const { trigger } = useLogEvents()

  const appPageUnsubscribedBannerView = (
    screen: PaywallSourceEnum.PAGE_PREVIEW | PaywallSourceEnum.PAGE_EDITOR,
  ) => {
    trigger(EventCategories.APP_PAGE_UNSUBSCRIBED_BANNER_VIEW, { screen })
  }

  const appPageUnsubscribedBannerClick = (
    screen: PaywallSourceEnum.PAGE_PREVIEW | PaywallSourceEnum.PAGE_EDITOR,
  ) => {
    trigger(EventCategories.APP_PAGE_UNSUBSCRIBED_BANNER_UPGRADE_CLICK, {
      screen,
    })
  }

  const appPageCoEditorsNoAccessPopupView = (
    source:
      | PaywallSourceEnum.FORM_CREATE
      | PaywallSourceEnum.FORM_EDIT
      | PaywallSourceEnum.CHECKOUT_CREATE
      | PaywallSourceEnum.CHECKOUT_EDIT,
  ) => {
    trigger(EventCategories.APP_PAGE_CO_EDITORS_NO_ACCESS_POPUP_VIEW, {
      source,
    })
  }

  return {
    appPageUnsubscribedBannerView,
    appPageUnsubscribedBannerClick,
    appPageCoEditorsNoAccessPopupView,
  }
}
