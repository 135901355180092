import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@hooks/useAuth'
import { useSelectorFactory } from '@hooks/useSelectorFactory'

import useBulk from '@hooks/bulk/useBulk'
import { undoRedoActions } from '../../../../actions'
import { selectActionInProgress, selectUndoStack } from '../../../../selectors'

import useBoard from '../../../../hooks/useBoard'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

const useUndoRedo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { isSelectionModeEnabled } = useBulk()

  const { user } = useAuth()
  const { boardId, isReadOnly, board, isPage } = useBoard()

  const undoStack = useSelectorFactory(selectUndoStack)
  const actionInProgress = useSelectorFactory(selectActionInProgress)

  // todo refactor next method
  const checkHasUndo = (): boolean => {
    if (isReadOnly) return false
    if (actionInProgress) return false
    if (undoStack.contentId !== boardId) return false

    if (undoStack.undo.length === 0) return false
    if (!board?.action?.undoHead) return false
    if (board?.action?.undoHead?.actor !== user?.uid) return false

    return true
  }

  // todo refactor next method
  const checkHasRedo = (): boolean => {
    if (isReadOnly) return false
    if (actionInProgress) return false
    if (undoStack.contentId !== boardId) return false

    if (undoStack.redo.length === 0) return false
    if (!board?.action?.redoHead) return false
    if (board?.action?.redoHead?.actor !== user?.uid) return false

    return true
  }

  const undo = (): void => {
    const undoHead = board?.action?.undoHead
    if (!isApiAvailableOrNotify() || !undoHead) return

    dispatch(undoRedoActions.undo(boardId, undoHead.action))

    if (undoHead.function === 'setProperties') {
      return
    }

    if (!undoHead.folder) {
      navigate(`/${boardId}`)
      return
    }

    if (!isPage(undoHead.folder)) {
      navigate(`/${boardId}/${undoHead.folder}`)
    }
  }

  const redo = (): void => {
    const redoHead = board?.action?.redoHead
    if (!isApiAvailableOrNotify() || !redoHead) return

    dispatch(undoRedoActions.redo(boardId, redoHead.action))

    if (!redoHead.folder) {
      navigate(`/${boardId}`)
      return
    }

    if (!isPage(redoHead.folder)) {
      navigate(`/${boardId}/${redoHead.folder}`)
    }
  }

  return {
    isDisabled: isSelectionModeEnabled,
    undo: checkHasUndo() ? undo : undefined,
    redo: checkHasRedo() ? redo : undefined,
  } as const
}

export default useUndoRedo
