import React, { useMemo } from 'react'

import AuthContext from './FirebaseAuthContext'

import { AuthData } from '../../app/models'
import { useFirebaseAuthStateChangedListener } from '../hooks/useAuthStateChangeListener'
import useConnectionState from '../hooks/useConnectionState'

const FirebaseAuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { user, loaded } = useFirebaseAuthStateChangedListener()
  useConnectionState()

  const authState: AuthData = useMemo<AuthData>(
    () => ({ user, isLoggedIn: !!user, loaded }) as AuthData,
    [user, loaded],
  )

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  )
}

export default FirebaseAuthProvider
