import { QueryDocumentSnapshot, QuerySnapshot } from 'firebase/firestore'
import { UpdatesMessage } from '../../../app/models'
import { AppDispatch } from '../../../app/store'
import { updatesActions } from '../../../actions'
import { transformUpdateMessage } from '../transformers/UpdateMessageTransformer'

export const UpdatesMessageSnapshot =
  (uid: string, bid: string, dispatch: AppDispatch) =>
  (snapshot: QuerySnapshot) => {
    const boardId = `${uid}-${bid}`
    const messages: UpdatesMessage[] = []

    snapshot.forEach((doc: QueryDocumentSnapshot) => {
      messages.push(transformUpdateMessage(doc))
    })

    dispatch(updatesActions.receivedUpdatesMessages(boardId, messages))
  }
