import { configurationObjectFromEnv } from '../utils/configurationObjectFromEnv'
import { ENV_KEYS } from '../utils/envKeys'

export interface InitConf {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
  gtmId: string
}

export default configurationObjectFromEnv<InitConf>({
  apiKey: ENV_KEYS.API_KEY,
  authDomain: ENV_KEYS.AUTH_DOMAIN,
  databaseURL: ENV_KEYS.DATABASE_URL,
  projectId: ENV_KEYS.PROJECT_ID,
  storageBucket: ENV_KEYS.STORAGE_BUCKET,
  messagingSenderId: ENV_KEYS.MESSAGING_SENDER_ID,
  appId: ENV_KEYS.APP_ID,
  measurementId: ENV_KEYS.MEASUREMENT_ID,
  gtmId: ENV_KEYS.GTM_ID,
})
