import { ComponentType, useState } from 'react'
import { urlHelper } from 'boards-web-ui'

import useVideo from '../../hooks/useVideo'
import { Node } from '../models'
import { getVideoPlaceholder } from '../features/board/helpers'

interface Props {
  node: Node
}
interface InjectedProps {
  thumbnail: string
  isLoading: boolean
  onImageLoad: () => void
}
function withVideo(Component: ComponentType<Props & InjectedProps>) {
  return ({ node }: Props) => {
    const { thumbnail, isLoading, hasError } = useVideo(node.text)
    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true)
    const videoDetails = urlHelper.getVideoDetails(node.text)
    const placeholder = getVideoPlaceholder(videoDetails?.type || '')

    const videoThumbnail =
      isLoading || hasError || !thumbnail ? placeholder : thumbnail

    return (
      <Component
        node={node}
        thumbnail={videoThumbnail}
        isLoading={isLoading || isLoadingImage}
        onImageLoad={() => setIsLoadingImage(false)}
      />
    )
  }
}

export default withVideo
