import React, { ElementType, PropsWithChildren } from 'react'

import styles from './Menu.module.css'

type PropsMenu = PropsWithChildren<{
  className?: string
  as?: ElementType
  [key: string]: unknown
}>

type PropsMenuItem = PropsWithChildren<{
  className?: string
  as?: ElementType
  chip?: JSX.Element
  [key: string]: unknown
}>

const Menu: React.FC<PropsMenu> = ({ as: Tag = 'ul', children, className }) => (
  <Tag className={[styles.Root, className].join(' ')}>{children}</Tag>
)

/** @deprecated Use "MenuItem" instead from "boards-web-ui" */
export const MenuItem: React.FC<PropsMenuItem> = React.memo(
  ({ chip, as: Tag = 'li', children, className, ...rest }) => (
    <Tag className={[styles.Menu, className].join(' ')} {...rest}>
      <span className={styles.Item}>{children}</span>
      {chip && chip}
    </Tag>
  ),
)
/** @deprecated Use "Menu" instead from "boards-web-ui" */
export default React.memo(Menu)
