import { v4 as uuidv4 } from 'uuid'
import { seededRandomInteger } from './numbers'

/* prettier-ignore */
const colors = [
  '#5566d6', '#bdbbf9', '#c4dcfc', '#609ac9', '#8dcbd6',
  '#997ded', '#6586f2', '#81bdd1', '#376ead', '#492591',
  '#8f52af', '#e1b3fc', '#4d1baa', '#c47bfc', '#e0ccff',
  '#ab7cd3', '#5d2793', '#c0adea', '#d099e5', '#e08bb1',
  '#ccdcff', '#a7f9d3', '#afbff7', '#b0f287', '#81b5f4',
  '#a4f9c9', '#ffc9a5', '#f2c587', '#70c1ef', '#d1d117',
]

export function randomContentId() {
  /* prettier-ignore */
  const chars = [
    'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U',
    'V','W','X','Y','Z','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p',
    'q','r','s','t','u','v','w','x','y','z','0','1','2','3','4','5','6','7','8','9',
  ]

  let id = ''

  for (let i = 0; i < 28; i += 1) {
    id += chars[Math.floor(Math.random() * chars.length)]
  }
  return id
}

export function uuidImageName(imageFile) {
  return `${uuidv4()}.${imageFile.name.split('.').pop()}`
}

export function parseEmails(text) {
  const textArray = text.replace(/[\n\r;,]/g, ' ').split(' ')
  return textArray.filter((t) => t !== '')
}

export function randomSeededColor(text) {
  const index = seededRandomInteger(text, 0, colors.length - 1)
  return colors[index]
}
