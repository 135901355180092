import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react'
import {
  ScrollAreaContainer,
  ScrollAreaRef,
  ScrollAreasContextProps,
} from '../../models/ScrollArea'
import ScrollAreasContext from './ScrollAreasContext'

const ScrollAreasProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [areas, setAreas] = useState<ScrollAreaRef[]>([])

  const addArea = useCallback(
    (
      area: ScrollAreaContainer,
      ref: React.MutableRefObject<HTMLDivElement>,
      endRef: React.MutableRefObject<HTMLDivElement>,
    ) => {
      setAreas((prevAreas) => [...prevAreas, { area, ref, endRef }])
    },
    [setAreas],
  )

  const removeArea = useCallback(
    (area: ScrollAreaContainer) => {
      setAreas((prevAreas) => prevAreas.filter((a) => a.area !== area))
    },
    [setAreas],
  )

  const getArea = useCallback(
    (area: ScrollAreaContainer) => {
      return areas.find((a) => a.area === area)
    },
    [areas],
  )

  const value: ScrollAreasContextProps = useMemo(
    () => ({
      addArea,
      removeArea,
      getArea,
    }),
    [addArea, removeArea, getArea],
  )

  return (
    <ScrollAreasContext.Provider value={value}>
      {children}
    </ScrollAreasContext.Provider>
  )
}

export default ScrollAreasProvider
