import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { NODE_LINK_TYPES, parseUrl } from 'boards-web-ui'
import isEmail from '@stdlib/assert-is-email-address'

import urlHelper from '../../../../helpers/url'
import { BUTTONS_PREFIX } from '../util'

const minLength =
  (length: number) =>
  (t: string): boolean =>
    (t?.length || 0) >= length
const prefixIncludes =
  (prefix: string) =>
  (t: string): boolean =>
    !!t && t.startsWith(prefix)
const domainIncludes =
  (domain: string) =>
  (t: string): boolean =>
    !!t && urlHelper.domainIncludes(t, domain)
const domainStartsWith =
  (domain: string) =>
  (t: string): boolean =>
    !!t && urlHelper.domainStartsWith(t, domain)
const validPhoneNumber =
  (prefix: string) =>
  (phone: string): boolean => {
    if (!prefixIncludes(prefix)(phone)) {
      return false
    }

    const prefixWithoutPlus = prefix.replace('+', '')
    const number = phone.replace(prefixWithoutPlus, '')
    return isValidPhoneNumber(number)
  }
const validUrlPhoneNumber =
  (prefix: string) =>
  (phone: string): boolean => {
    if (!prefixIncludes(prefix)(phone)) {
      return false
    }
    const { pathname } = parseUrl(phone)
    return isValidPhoneNumber(pathname.replace('/', ''))
  }

const useButtonValidations = () => {
  const { t } = useTranslation()

  const validations = {
    [NODE_LINK_TYPES.SMS]: [
      {
        message: t('error_invalid_number'),
        validate: validPhoneNumber(BUTTONS_PREFIX[NODE_LINK_TYPES.SMS]),
      },
    ],
    [NODE_LINK_TYPES.PHONE]: [
      {
        message: t('error_invalid_number'),
        validate: validPhoneNumber(BUTTONS_PREFIX[NODE_LINK_TYPES.PHONE]),
      },
    ],
    [NODE_LINK_TYPES.TELEGRAM]: [
      {
        message: t('error_invalid_number'),
        validate: validUrlPhoneNumber(BUTTONS_PREFIX[NODE_LINK_TYPES.TELEGRAM]),
      },
    ],
    [NODE_LINK_TYPES.WHATS_APP]: [
      {
        message: t('error_invalid_number'),
        validate: validUrlPhoneNumber(
          BUTTONS_PREFIX[NODE_LINK_TYPES.WHATS_APP],
        ),
      },
    ],
    [NODE_LINK_TYPES.MAIL]: [
      {
        message: t('button_email_error_invalid_address'),
        validate: isEmail,
      },
    ],
    [NODE_LINK_TYPES.PAYPAL]: [
      {
        message: t('error_invalid_url'),
        validate: domainIncludes('paypal'),
      },
    ],
    [NODE_LINK_TYPES.VENMO]: [
      {
        message: t('error_invalid_url'),
        validate: domainStartsWith('venmo.com'),
      },
    ],
    [NODE_LINK_TYPES.CASH]: [
      {
        message: t('error_invalid_url'),
        validate: domainStartsWith('cash.app'),
      },
    ],
    [NODE_LINK_TYPES.FACEBOOK]: [
      {
        message: t('error_invalid_username'),
        validate: minLength(14),
      },
    ],
    [NODE_LINK_TYPES.CALENDLY]: [
      {
        message: t('error_invalid_url'),
        validate: domainIncludes('calendly'),
      },
    ],
    [NODE_LINK_TYPES.ZOOM]: [
      {
        message: t('error_invalid_url'),
        validate: domainIncludes('zoom'),
      },
    ],
    [NODE_LINK_TYPES.REGULAR]: [
      {
        message: t('error_invalid_url'),
        validate: urlHelper.isValidUrl,
      },
    ],
  }

  const validateButton = (text: string, type?: NODE_LINK_TYPES) => {
    if (!type) return { isValid: false }

    const buttonValidations = validations[type]

    // eslint-disable-next-line
    for (const { message, validate } of buttonValidations) {
      if (!validate(text)) {
        return { isValid: false, message }
      }
    }

    return { isValid: true }
  }

  return validateButton
}

export default useButtonValidations
