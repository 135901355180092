import { FC, memo } from 'react'
import { Trans } from 'react-i18next'
import { isNumber } from 'lodash-es'

import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { ButtonNew } from 'boards-web-ui'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { ReactComponent as KingIcon } from '../../../../icons/king.svg'
import useMessageLimits from '../../../../hooks/useMessageLimits'

import styles from './UpdatesMessageLimits.module.css'

const UpdatesMessageLimits: FC = () => {
  const { profile } = useProfileInformation()
  const {
    messagesFreeBannerView,
    messagesOverBannerView,
    messagesFreeBannerCLick,
    messagesOverBannerCLick,
  } = useUpdatesEvents()

  const { handleUpgrade, limitReached, freeCountLeft } = useMessageLimits(
    messagesOverBannerView,
    messagesFreeBannerView,
    messagesOverBannerCLick,
    messagesFreeBannerCLick,
    profile.plan,
    false,
  )

  return (
    <ButtonNew
      fullWidth
      color={limitReached ? 'primary' : 'secondary'}
      className={styles.UpgradeButton}
      onClick={handleUpgrade}
    >
      <p>
        <Trans
          i18nKey={
            isNumber(freeCountLeft) && freeCountLeft <= 1
              ? 'messages_banner_single'
              : 'messages_banner_plural'
          }
          values={{ count: freeCountLeft }}
        />
      </p>
      <div className={styles.IconContainer}>
        <KingIcon width={15} />
        &nbsp;
        <Trans i18nKey="paywall_upgrade" />
      </div>
    </ButtonNew>
  )
}

export default memo(UpdatesMessageLimits)
