import { Trans } from 'react-i18next'
import Box from '../../../../elements/Box'
import Typography from '../../../../elements/Typography'
import Button from '../../../../elements/Button'
import Member from './Member'

import styles from './MembersEmptyState.module.css'

interface Props {
  onClickInvite: () => void
}
const MembersEmptyState = ({ onClickInvite }: Props) => (
  <div className={styles.Root}>
    <h2 className={styles.Title}>
      <Trans i18nKey="share_screen_member_list" />
    </h2>
    <Box mt={80} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Member
        email={'Email@boards.com'}
        permission="read"
        disabled
        emptyState
      />
    </Box>
    <Box mt={30}>
      <Typography variant="subheading2" align="center" color="#C4C4C4">
        <Trans i18nKey="share_screen_there_are_no_members" />
      </Typography>
    </Box>
    <Box mt={30} mb={100} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button color="secondary" onClick={() => onClickInvite()}>
        <Trans i18nKey="action_add_members" />
      </Button>
    </Box>
  </div>
)

export default MembersEmptyState
