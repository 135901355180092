import {
  CLIENT_CONFIG_FEATURES,
  DefaultClientConfig,
  EmailAdressClientConfig,
} from 'app/models/clientConfig'

export const enum ACTION_TYPE {
  SET_CLIENT_CONFIG = 'SET_CLIENT_CONFIG',
}

const setClientConfig = (
  data: EmailAdressClientConfig | DefaultClientConfig,
  clientFeature: CLIENT_CONFIG_FEATURES,
) => ({
  type: ACTION_TYPE.SET_CLIENT_CONFIG,
  data,
  clientFeature,
})

const actions = {
  setClientConfig,
} as const

export default actions
