import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { Tooltip, TooltipContent } from 'boards-web-ui'

import CloseButton from './CloseButton'

type Props = {
  gray?: boolean
  className?: string
  onClose?: () => void
}
const CloseButtonWithTooltip: FC<Props> = ({
  gray,
  className,
  onClose,
  ...rest
}) => (
  <Tooltip
    data={
      <TooltipContent>
        <Trans i18nKey={'action_close'} />
      </TooltipContent>
    }
    className={className}
    {...rest}
  >
    <CloseButton onClose={onClose} gray={gray} />
  </Tooltip>
)

export default React.memo(CloseButtonWithTooltip)
