import { useTranslation } from 'react-i18next'

const useShortcutTranslations = () => {
  const { t } = useTranslation()

  const copyToast = () => t('share_folder_copied_to_clipboard')
  const copyTooltip = () => t('action_copy_link')

  return {
    copyToast,
    copyTooltip,
  } as const
}

export default useShortcutTranslations
