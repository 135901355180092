import { useCallback } from 'react'

import useUndoStack from '@hooks/useUndoStack'
import useBulk from '@hooks/bulk/useBulk'

const useSelectMenuItem = () => {
  const { reset: resetUndoStack } = useUndoStack()
  const { resetBulkActions } = useBulk()

  return useCallback(() => {
    resetUndoStack()
    resetBulkActions()
  }, [resetUndoStack, resetBulkActions])
}

export default useSelectMenuItem
