import { FieldTypes } from 'boards-web-ui'
import { useLogEvents } from './useLogEvents'
import { EventCategories } from './models/logEvents'

export const enum GOOGLE_SHEETS_INTEGRATION_SCREEN {
  CHECKOUT = 'checkout',
  FORM = 'form',
}

const getEventFieldNameByFieldType = (fieldType: string) => {
  switch (fieldType) {
    case FieldTypes.STRING: {
      return 'name'
    }
    case FieldTypes.TEXT: {
      return 'message'
    }
    case FieldTypes.CHOICE: {
      return 'multiple_choice'
    }
    default: {
      return fieldType
    }
  }
}

const useFormsEvents = () => {
  const { trigger } = useLogEvents()

  const editScreenView = (isNew?: boolean) =>
    trigger(EventCategories.APP_FORM_EDIT_SCREEN_VIEW, {
      state: isNew ? 'new' : 'edit',
    })

  const formSettingsScreenView = () => {
    trigger(EventCategories.APP_FORM_SETTINGS_SCREEN_VIEW)
  }

  const questionAddEvent = () =>
    trigger(EventCategories.APP_FORM_QUESTION_ADD_CLICK)

  const questionAddedEvent = (questionType: string) =>
    trigger(EventCategories.APP_FORM_QUESTION_ADDED, {
      type: getEventFieldNameByFieldType(questionType),
    })

  const questionDeletedEvent = (questionType: string) =>
    trigger(EventCategories.APP_FORM_QUESTION_DELETED, {
      type: getEventFieldNameByFieldType(questionType),
    })

  const multiChoiceToggleEvent = (isMultiple: boolean) =>
    trigger(EventCategories.APP_FORM_QUESTION_MULTIPLE_CHOICE_TOGGLE_SWITCH, {
      type: isMultiple ? 'multi_select' : 'single_select',
    })

  const multiChoiceAddEvent = (optionId: number | undefined) =>
    trigger(EventCategories.APP_FORM_QUESTION_MULTIPLE_CHOICE_OPTION_ADDED, {
      option_id: optionId,
    })

  const multiChoiceDeleteEvent = (optionId: number | undefined) =>
    trigger(EventCategories.APP_FORM_QUESTION_MULTIPLE_CHOICE_OPTION_DELETED, {
      option_id: optionId,
    })

  const responsesEmailChangedEvent = () =>
    trigger(EventCategories.APP_FORM_RESPONSES_EMAIL_CHANGED)

  const appFormGoogleSheetsIntegrationClick = (
    screen: GOOGLE_SHEETS_INTEGRATION_SCREEN,
    googleSheetsConnected: boolean,
  ) =>
    trigger(EventCategories.APP_GOOGLE_SHEETS_INTEGRATION_CLICK, {
      screen,
      google_sheets_connected: googleSheetsConnected.toString(),
    })

  const appGoogleSheetsIntegrationSuccess = () =>
    trigger(EventCategories.APP_GOOGLE_SHEETS_INTEGRATION_SUCCESS)

  const appFormPostSubmissionRedirectSelect = (hasPremium: boolean) => {
    trigger(EventCategories.APP_FORM_POST_SUBMISSION_REDIRECT_SELECT, {
      has_premium: `${hasPremium}`,
    })
  }

  const appFormPostSubmissionRedirectEdit = (hasPremium: boolean) => {
    trigger(EventCategories.APP_FORM_POST_SUBMISSION_REDIRECT_EDIT, {
      has_premium: `${hasPremium}`,
    })
  }

  const appFormPostSubmissionMessageSelect = (hasPremium: boolean) => {
    trigger(EventCategories.APP_FORM_POST_SUBMISSION_MESSAGE_SELECT, {
      has_premium: `${hasPremium}`,
    })
  }

  const appFormPostSubmissionMessageEdit = (hasPremium: boolean) => {
    trigger(EventCategories.APP_FORM_POST_SUBMISSION_MESSAGE_EDIT, {
      has_premium: `${hasPremium}`,
    })
  }

  return {
    editScreenView,
    questionAddEvent,
    questionAddedEvent,
    questionDeletedEvent,
    multiChoiceToggleEvent,
    multiChoiceAddEvent,
    multiChoiceDeleteEvent,
    responsesEmailChangedEvent,
    appFormGoogleSheetsIntegrationClick,
    appGoogleSheetsIntegrationSuccess,
    appFormPostSubmissionRedirectSelect,
    appFormPostSubmissionRedirectEdit,
    appFormPostSubmissionMessageSelect,
    appFormPostSubmissionMessageEdit,
    formSettingsScreenView,
  }
}

export default useFormsEvents
