import { useEffect } from 'react'
import { User } from '@firebase/auth'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { getSignInMethodsForEmail } from '../_firebase/auth'
import { SIGN_IN, SIGN_UP } from '../app/constants/routes'

function useEmailAuthenticationCheck(user: User | null, loaded: boolean) {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const email = searchParams.get('email')
  const shortcutId = searchParams.get('shortcutId')

  const handleEmailCheck = async () => {
    if (email && loaded) {
      const signInMethods = await getSignInMethodsForEmail(email)
      if (!user) {
        if (signInMethods.length > 0) {
          navigate(`${SIGN_IN}?redirect=${pathname}?${searchParams}`)
        } else {
          navigate(`${SIGN_UP}?${searchParams}`)
        }
        return
      }
      searchParams.delete('email')
      searchParams.delete('link-shortcut')
      searchParams.delete('source')
      setSearchParams(searchParams)
    }
    if (shortcutId && loaded) {
      if (!user) {
        navigate(`${SIGN_UP}?${searchParams}`)
      }
    }
  }

  useEffect(() => {
    handleEmailCheck()
    // eslint-disable-next-line
  }, [loaded])
}

export default useEmailAuthenticationCheck
