import { EventCategories } from '@features/analytics/models/logEvents'
import { useLogEvents } from '@features/analytics/useLogEvents'
import { useCallback } from 'react'

export enum TemplatePickerSourceEventEnum {
  HOME_SCREEN = 'homescreen',
  FOLDER = 'folder',
  BOARD = 'board',
  DIRECT_LINK = 'direct_link',
}

interface chooseThisTemplateClickProps {
  boardId: string
  nodeId: string
  pageId: string
  templateCategory: string
  templateName: string
}
interface personalizeTemplateDismissProps extends chooseThisTemplateClickProps {
  linkShortcut: string
}
const useTemplatePickerEvents = () => {
  const { trigger } = useLogEvents()

  const pageTemplateCategoryViewEvent = useCallback(
    (source: TemplatePickerSourceEventEnum) => {
      trigger(EventCategories.APP_PAGE_TEMPLATE_CATEGORY_VIEW, {
        source,
      })
    },
    [trigger],
  )

  const pageTemplateCategoryClickEvent = (categoryName: string) => {
    trigger(EventCategories.APP_PAGE_TEMPLATE_CATEGORY_CLICK, {
      template_category_name: categoryName,
    })
  }

  const pageTemplatePreviewEvent = (
    categoryName: string,
    templateName: string,
  ) => {
    trigger(EventCategories.APP_PAGE_TEMPLATE_PREVIEW_SCREEN_VIEW, {
      template_category_name: categoryName,
      template_name: templateName,
    })
  }

  const pageTemplateStartFromScratchEvent = () => {
    trigger(EventCategories.APP_PAGE_TEMPLATE_CATEGORY_START_FROM_SCRATCH)
  }

  const pageChooseThisTemplateClickEvent = ({
    boardId,
    nodeId,
    pageId,
    templateCategory,
    templateName,
  }: chooseThisTemplateClickProps) => {
    trigger(EventCategories.APP_PAGE_CHOOSE_THIS_TEMPLATE_CLICK, {
      board_id: boardId,
      key_id: nodeId,
      page_id: pageId,
      template_category_name: templateCategory,
      template_name: templateName,
    })
  }

  const pagePersonalizeTemplateDismissEvent = ({
    boardId,
    nodeId,
    pageId,
    linkShortcut,
    templateCategory,
    templateName,
  }: personalizeTemplateDismissProps) => {
    trigger(EventCategories.APP_PAGE_PERSONALIZE_TEMPLATE_DISMISS, {
      board_id: boardId,
      key_id: nodeId,
      page_id: pageId,
      link_shortcut: linkShortcut,
      template_category_name: templateCategory,
      template_name: templateName,
    })
  }

  return {
    pageTemplateCategoryViewEvent,
    pageTemplateCategoryClickEvent,
    pageTemplatePreviewEvent,
    pageTemplateStartFromScratchEvent,
    pageChooseThisTemplateClickEvent,
    pagePersonalizeTemplateDismissEvent,
  }
}

export default useTemplatePickerEvents
