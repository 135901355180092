import { Trans } from 'react-i18next'
import { AlarmClockIcon } from 'boards-web-ui'
import { isMobile } from '../../../../utils/deviceParser'

const longTextLength = 80

export const usePaywallTitle = ({
  currentPlanTitle,
  shouldShowFreePlanIndicator,
  shouldShowFreeTrialIndicator,
}: {
  currentPlanTitle?: string
  shouldShowFreePlanIndicator?: boolean
  shouldShowFreeTrialIndicator?: boolean
}) => {
  const getTitle = () => {
    if (currentPlanTitle) {
      return currentPlanTitle
    }

    if (shouldShowFreeTrialIndicator) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
          }}
        >
          <AlarmClockIcon fontSize={isMobile ? '22px' : '34px'} />
          <Trans i18nKey={'paywall_before_trial_ended_title_v2'} />
        </div>
      )
    }

    if (shouldShowFreePlanIndicator) {
      return <Trans i18nKey={'paywall_trial_ended_title_v2'} />
    }

    return <Trans i18nKey={'paywall_title_two_tiers'} />
  }

  return {
    isLongText: currentPlanTitle
      ? currentPlanTitle.length > longTextLength
      : false,
    title: getTitle(),
  }
}
