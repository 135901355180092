export const isEqualValues = (val1: unknown, val2: unknown): boolean => {
  if (Array.isArray(val1)) {
    if (!Array.isArray(val2)) {
      return false
    }

    if (val1.length !== val2.length) {
      return false
    }
  }

  if (Array.isArray(val2)) {
    if (!Array.isArray(val1)) {
      return false
    }

    if (val1.length !== val2.length) {
      return false
    }
  }

  if (typeof val1 === 'object' && typeof val2 === 'object') {
    return isEqualObjects(
      val1 as Record<string, unknown>,
      val2 as Record<string, unknown>,
    )
  }

  return val1 === val2
}

export const isEqualObjects = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
  compareKeys?: string[],
): boolean => {
  return (compareKeys || Object.keys(obj1)).reduce((state, key) => {
    if (
      !isEqualValues(
        obj1[key as keyof Record<string, unknown>] || '',
        obj2[key as keyof Record<string, unknown>] || '',
      )
    ) {
      return false
    }

    return state
  }, true)
}
