import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useSharedFolder } from '@hooks/useSharedFolder'
import { AppState } from '../../app/models/appState'
import { bulkActions } from '../../actions'

const useBulk = () => {
  const { showSharedFolder } = useSharedFolder()
  const bulk = useSelector((state: AppState) => state.app.bulk)
  const dispatch = useDispatch()

  const resetBulkActions = useCallback(() => {
    dispatch(bulkActions.resetBulkAction())
  }, [dispatch])

  const isSelectionModeEnabled = Boolean(
    bulk.contentId !== undefined && !showSharedFolder,
  )

  return {
    bulk,
    resetBulkActions,
    isSelectionModeEnabled,
  } as const
}

export default useBulk
