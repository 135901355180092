import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import styles from './Link.module.css'

interface Props {
  to?: string
  target?: string
  onClick?: () => void
  linkClassName?: string
}
const Link: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  to,
  target,
  linkClassName,
  ...rest
}) => {
  if (!to) {
    return (
      <button className={styles.Root} {...rest}>
        {children}
      </button>
    )
  }

  if (target) {
    return (
      <a
        className={clsx(styles.Root, linkClassName)}
        href={to}
        target={target}
        {...rest}
      >
        {children}
      </a>
    )
  }
  return (
    <RouterLink className={clsx(styles.Root, linkClassName)} to={to}>
      {children}
    </RouterLink>
  )
}

export default Link
