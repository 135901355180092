import { Node } from '../../../models'

import styles from './NodeHeadline.module.css'

interface Props {
  node: Node
}
const NodeHeadline = ({ node }: Props) => {
  return <div className={styles.Root}>{node.title}</div>
}

export default NodeHeadline
