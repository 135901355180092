import PropTypes from 'prop-types'
import styles from './Toolbar.module.css'

const Toolbar = ({ highlight, className, children }) => {
  const classes = [styles.Root]

  if (className) {
    classes.push(className)
  }

  if (highlight) {
    classes.push(styles.Highlight)
  }
  return <div className={classes.join(' ')}>{children}</div>
}

Toolbar.propTypes = {
  children: PropTypes.node,
  highlight: PropTypes.bool,
  className: PropTypes.string,
}

export default Toolbar
