import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './InputField.module.css'

const InputField = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { name, errors, type, placeholder, ...rest } = props
  const hasError = errors && errors[name]

  return (
    <div className={styles.Root}>
      <input
        ref={ref}
        className={styles.Input}
        type={type}
        name={name}
        placeholder={placeholder ?? ''}
        {...rest}
      />
      {hasError && (
        <div className={styles.Error}>{t(errors[name].message)}</div>
      )}
    </div>
  )
})

InputField.defaultProps = {
  type: 'text',
}

InputField.propTypes = {
  type: PropTypes.oneOf(['text', 'password']),
  placeholder: PropTypes.string,
}

export default InputField
