/* eslint no-console: ["error", { allow: ["warn"] }] */
import {
  eachDayOfInterval,
  isSameDay,
  subDays,
  startOfISOWeek,
  endOfISOWeek,
  startOfMonth,
  subMonths,
  endOfMonth,
} from 'date-fns'
import { LineChartState } from '../models/insights'
import { ActivitySummary } from '../models/insightsResult'
import { InsightsFilter } from '../models/filter'

const today = new Date()
const yesterday = subDays(today, 1)

const filterDateRange = {
  [InsightsFilter.TODAY]: { start: today, end: today },
  [InsightsFilter.YESTERDAY]: { start: yesterday, end: yesterday },
  [InsightsFilter.THIS_WEEK]: { start: startOfISOWeek(today), end: today },
  [InsightsFilter.LAST_WEEK]: {
    start: startOfISOWeek(subDays(today, 7)),
    end: endOfISOWeek(subDays(today, 7)),
  },
  [InsightsFilter.THIS_MONTH]: { start: startOfMonth(today), end: today },
  [InsightsFilter.LAST_MONTH]: {
    start: startOfMonth(subMonths(today, 1)),
    end: endOfMonth(subMonths(today, 1)),
  },
  [InsightsFilter.ALL_TIME]: { start: subDays(today, 30), end: today },
} as const

export const transformDataToChart = (
  data: ActivitySummary,
  filter: InsightsFilter,
  unique: boolean,
): LineChartState[] => {
  const sortedData = sortAndFormatDate(data, filter, unique)
  const firstDataDate = getFirstDataDate(sortedData)
  const firstChartDate = getFirstChartDate(firstDataDate, filter)
  const daysByRange = eachDayOfInterval({
    start: firstChartDate,
    end: filterDateRange[filter].end,
  })

  const daysWithDefaultData = getDaysWithDefaultData(daysByRange, firstDataDate)
  return fillDays(sortedData, daysWithDefaultData)
}

const getFirstDataDate = (data: LineChartState[]) => {
  const firstRecord = data[0]
  if (firstRecord?.date) {
    return new Date(firstRecord.date)
  }

  return undefined
}

const getFirstChartDate = (
  firstDataDate: Date | undefined,
  filter: InsightsFilter,
) => {
  const firstFilterDate = filterDateRange[filter].start
  if (
    filter === InsightsFilter.ALL_TIME &&
    firstDataDate &&
    firstDataDate < firstFilterDate
  ) {
    return firstDataDate
  }
  return firstFilterDate
}

const sortAndFormatDate = (
  data: ActivitySummary,
  filter: InsightsFilter,
  unique: boolean,
) => {
  try {
    return data.daily
      .sort((a, b) => a.date - b.date)
      .map((d) => ({
        date: new Date(d.date),
        value: unique ? d.users.all : d.total.all,
      }))
  } catch {
    console.warn(`Error parsing statistic with filter ${filter}`)
    return []
  }
}

const getDaysWithDefaultData = (days: Date[], firstDate: Date | undefined) => {
  return days.map((date) => {
    const value = firstDate && firstDate < date ? 0 : undefined
    return { value, date } as LineChartState
  })
}

const fillDays = (
  data: LineChartState[],
  defaultData: LineChartState[],
): LineChartState[] => {
  return defaultData.map((day) => {
    const record = data.find((d) => isSameDay(day.date, d.date))
    if (record) {
      return { ...day, value: record?.value }
    }

    return day
  })
}
