import { TemplateCategory } from '_firebase/models/templates'

export const enum ACTION_TYPE {
  SET_TEMPLATES = 'SET_TEMPLATES',
}

const setTemplates = (data: TemplateCategory[]) => ({
  type: ACTION_TYPE.SET_TEMPLATES,
  data,
})

const actions = {
  setTemplates,
} as const

export default actions
