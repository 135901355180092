import { FC, ReactElement } from 'react'
import { Grid } from 'boards-web-ui'
import DoneButton from '@ui/components/DoneButton'

import styles from './PageProfileDialogFooter.module.css'

type PageProfileDialogFooterProps = {
  name: string
  leftElement?: ReactElement
  onSubmit?: () => void
}
const PageProfileDialogFooter: FC<PageProfileDialogFooterProps> = ({
  name,
  leftElement,
  onSubmit,
}) => {
  return (
    <Grid className={styles.Root} justifyContent="space-between" width="100%">
      <Grid columnGap={5}>{leftElement}</Grid>
      <DoneButton disabled={!onSubmit} onClick={onSubmit}>
        {name}
      </DoneButton>
    </Grid>
  )
}
export default PageProfileDialogFooter
