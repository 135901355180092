import { useEffect, useState } from 'react'

const useDragAndDropList = (setItems) => {
  const [items, setDefaultList] = useState([])
  const [list, setList] = useState([])
  const [draggedFrom, setDraggedFrom] = useState()
  const [draggedTo, setDraggedTo] = useState()

  useEffect(() => {
    setList(items)
  }, [items])

  // onDragStart fires when an element starts being dragged
  const onDragStart = (event) => {
    const newDraggedFrom = Number(event.currentTarget.dataset.position)
    setDraggedFrom(newDraggedFrom)

    event.dataTransfer.setData('text/html', '')
  }

  // onDragOver fires when an element being dragged enters a droppable area.
  const onDragOver = (event) => {
    event.preventDefault()

    const newDraggedTo = Number(event.currentTarget.dataset.position)

    if (draggedTo !== newDraggedTo) {
      const itemDragged = items[draggedFrom]

      if (itemDragged !== undefined) {
        const remainingItems = items.filter((_, index) => index !== draggedFrom)
        remainingItems.splice(newDraggedTo, 0, itemDragged)

        setList(remainingItems)
        setDraggedTo(newDraggedTo)
      }
    }
  }

  const onDrop = () => {
    setItems(list)
    setList(list)
    setDraggedFrom(null)
    setDraggedTo(null)
  }

  const onDragLeave = () => {
    setDraggedTo(null)
  }

  return {
    list,
    draggedTo,
    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
    setDefaultList,
  }
}

export default useDragAndDropList
