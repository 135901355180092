import React, { FC, HTMLAttributes, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem } from 'boards-web-ui'

import { IconWrapper } from '@ui/components/IconWrapper'
import { DEFAULT_SELECTED_ADDITIONAL_FIELDS } from '../constants'
import type { AdditionalFieldTypeWithIcon } from '../models'

import styles from './SelectAdditionalFieldType.module.css'

type SelectAdditionalFieldTypeProps = {
  fieldTypes?: Array<AdditionalFieldTypeWithIcon>
  onSelect: (field: AdditionalFieldTypeWithIcon) => void
} & Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'>
const SelectAdditionalFieldType: FC<SelectAdditionalFieldTypeProps> = ({
  fieldTypes = DEFAULT_SELECTED_ADDITIONAL_FIELDS,
  title = 'Select field type',
  onSelect,
  ...rest
}) => {
  const { t } = useTranslation()

  const renderFieldTypes = useMemo(
    () =>
      fieldTypes.map((field, index) => {
        return (
          <MenuItem
            key={field.type + index.toString()}
            className={styles.ListItem}
            onClick={() => onSelect(field)}
          >
            <IconWrapper className={styles.ListItemIcon}>
              {field.icon}
            </IconWrapper>
            {t(field?.title || '')}
          </MenuItem>
        )
      }),
    [fieldTypes, onSelect, t],
  )

  return (
    <div className={styles.Root} {...rest}>
      <h3 className={styles.Title}>{title}</h3>
      <Menu className={styles.List}>{renderFieldTypes}</Menu>
    </div>
  )
}

export default React.memo(SelectAdditionalFieldType)
