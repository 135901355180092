import { urlHelper } from 'boards-web-ui'

interface Props {
  url: string
  title: string
}
const VideoPreview = ({ url, title }: Props) => {
  const video = urlHelper.getVideoDetails(url)

  if (!video) return null

  return (
    <iframe
      title={title}
      src={video.url}
      width="590"
      height="320"
      frameBorder="0"
    />
  )
}

export default VideoPreview
