import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { FormField, TPageNodeForm, urlHelper } from 'boards-web-ui'

import { FORMS_ACTION_IDS } from '@features/forms/models'
import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { useSettingsResponses } from '@features/checkout/hooks'
import { useGoogleSheetConnector } from '@features/googleSpreadsheet'
import useFormIntegrationStatusState from '@features/forms/hooks/useFormIntegrationStatusState'
import { GOOGLE_SHEETS_INTEGRATION_SCREEN } from '@features/analytics/useFormsEvents'

import {
  useAdditionalFields,
  useEditFormsTitle,
  useFormApi,
  useFormsActions,
  useSettingsPostSubmission,
} from '../hooks'

import { AfterSubmitBehaviourEnum } from '../constants'
import EditFormsContext from './EditFormsContext'

type Props = {
  initialScreen: FORMS_ACTION_IDS
  isNew?: boolean
  boardId: string
  isOwn: boolean
  pageId: string
  initialForm?: TPageNodeForm
  advancedForms: boolean
  onPaywall: () => void
  onSave: ICreateOrUpdateNode
  onCancel: () => void
}
const EditFormsProvider: FC<PropsWithChildren<Props>> = ({
  initialScreen,
  children,
  boardId,
  isOwn,
  isNew = false,
  pageId,
  initialForm,
  advancedForms,
  onPaywall,
  onSave,
  onCancel,
}) => {
  const {
    formIntegrationStatusState,
    formIntegrationStatusPending,
    onGetFormIntegrationStatus,
  } = useFormIntegrationStatusState(boardId, pageId, initialForm?.id)

  useEffect(() => {
    if (isNew) {
      onGetFormIntegrationStatus()
    }
    // eslint-disable-next-line
  }, [])

  const { formTitle, onFormTitleChange } = useEditFormsTitle({
    defaultTitle: initialForm?.title,
  })
  const {
    formsFields,
    isFormFieldsValid,
    onAddFormsField,
    onDeleteFormsField,
    onUpdateFormsField,
    onFormFieldsReOrder,
  } = useAdditionalFields({
    defaultFields: initialForm?.form?.fields as FormField[],
  })

  const isRedirectAfterSubmit = urlHelper.isValidUrl(
    initialForm?.form?.response || '',
  )

  const {
    isGoogleFormConnected,
    spreadsheetURL,
    onUnlinkSpreadSheet,
    onFormConnectToGoogleSpreadSheet,
    isGoogleAccountConnectedPending,
  } = useGoogleSheetConnector({
    isOwn,
    formIntegrationStatusState,
    initialSpreadsheetURL: initialForm?.form?.spreadsheetURL,
    screen: GOOGLE_SHEETS_INTEGRATION_SCREEN.FORM,
    onGetFormIntegrationStatus,
  })

  const { responsesEmail, responsesEmailError, onResponsesEmailChange } =
    useSettingsResponses(
      initialForm?.form.email ||
        formIntegrationStatusState?.decryptedProperties?.email ||
        '',
    )

  const {
    formAction,
    formRedirectUrl,
    postSubmissionError,
    formCustomMessage,
    afterSubmitBehaviour,
    onFormActionChange,
    onFormRedirectUrlChange,
    onFormCustomMessageChange,
    onAfterSubmitBehaviourChange,
  } = useSettingsPostSubmission({
    defaultAction: initialForm?.form?.action || '',
    defaultAfterSubmitBehaviour: isRedirectAfterSubmit
      ? AfterSubmitBehaviourEnum.REDIRECT
      : AfterSubmitBehaviourEnum.MESSAGE,
    defaultFormRedirectUrl: isRedirectAfterSubmit
      ? initialForm?.form?.response || ''
      : undefined,
    defaultFormCustomMessage: !isRedirectAfterSubmit
      ? initialForm?.form?.response || ''
      : undefined,
  })

  const { isSubmitted, onFormSave, onFormCancel } = useFormApi({
    initialForm,
    formIntegrationStatusState,
    title: formTitle,
    action: formAction,
    postSubmissionBehavior: afterSubmitBehaviour,
    postSubmissionMessage: formCustomMessage,
    postSubmissionUrl: formRedirectUrl,
    postSubmissionError,
    formsFields,
    encryptedEmail: initialForm?.form?.encryptedEmail || '',
    encryptedSpreadsheetURL: initialForm?.form?.encryptedSpreadsheetURL || '',
    email: responsesEmail,
    responsesEmailError,
    spreadsheetURL,
    onSave,
    onCancel,
    initialEmail: formIntegrationStatusState?.decryptedProperties?.email || '',
    initialSpreadsheetURL:
      formIntegrationStatusState?.decryptedProperties?.spreadsheetURL || '',
    isNew,
  })

  const { action } = useFormsActions({
    initialScreen,
    isFormFieldsValid,
    onSave: () => onFormSave(),
  })

  const state = useMemo(
    () =>
      ({
        isOwn,
        isNew,
        isSubmitted,
        advancedForms,
        onPaywall,
        onFormCancel,
        isGoogleAccountConnectedPending,

        action,
        formAction,
        onFormActionChange,

        formTitle,
        onFormTitleChange,

        formsFields,
        onAddFormsField,
        onDeleteFormsField,
        onUpdateFormsField,
        onFormFieldsReOrder,

        responsesEmail,
        responsesEmailError,
        onResponsesEmailChange,

        formRedirectUrl,
        onFormRedirectUrlChange,
        postSubmissionError,

        formCustomMessage,
        onFormCustomMessageChange,

        afterSubmitBehaviour,
        onAfterSubmitBehaviourChange,

        isGoogleFormConnected,
        spreadsheetURL,
        onUnlinkSpreadSheet,
        onFormConnectToGoogleSpreadSheet,
        formIntegrationStatusPending,
      }) as const,
    [
      isOwn,
      isNew,
      isSubmitted,
      advancedForms,
      onPaywall,
      onFormCancel,
      isGoogleAccountConnectedPending,

      action,
      formAction,
      onFormActionChange,

      formTitle,
      onFormTitleChange,

      formsFields,
      onAddFormsField,
      onDeleteFormsField,
      onUpdateFormsField,
      onFormFieldsReOrder,

      responsesEmail,
      responsesEmailError,
      onResponsesEmailChange,

      formRedirectUrl,
      onFormRedirectUrlChange,
      postSubmissionError,

      formCustomMessage,
      onFormCustomMessageChange,

      afterSubmitBehaviour,
      onAfterSubmitBehaviourChange,

      isGoogleFormConnected,
      spreadsheetURL,
      onUnlinkSpreadSheet,
      onFormConnectToGoogleSpreadSheet,
      formIntegrationStatusPending,
    ],
  )

  return (
    <EditFormsContext.Provider value={state}>
      {children}
    </EditFormsContext.Provider>
  )
}

export default React.memo(EditFormsProvider)
