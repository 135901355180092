import { Fragment } from 'react'
import { Trans } from 'react-i18next'
import useInsightsEvents, {
  RelateTooltip,
} from '@features/analytics/useInsightsEvents'

import TopContentEmptyState from './TopContentEmptyState'
import QuestionMarkTooltip from './QuestionMarksTooltip'
import TopContentList from './TopContentList'
import ContentImage from './ContentImage'
import ContentVideo from './ContentVideoWrapper'
import ContentAudio from './ContenAudio'
import ContentText from './ContentText'
import ContentLink from './ContentLink'
import ContentPdf from './ContentPdf'
import ContentVideoStreaming from './ContentVideoStreaming'
import ContentPage from './ContentPage'
import VerticalScrollShadow from './VerticalScrollShadow'
import Card from './Card'
import Spinner from '../../../components/Spinner'
import { StatState } from '../models/insights'
import { NodeType } from '../../../models'
import { ReactComponent as TopContentIcon } from '../icons/TopContent.svg'

import styles from './TopContent.module.css'

interface Props {
  loading: boolean
  isBoardEmpty: boolean
  items: StatState[] | undefined
}

const TopContent = ({ loading, items, isBoardEmpty }: Props) => {
  const { tooltipHovered } = useInsightsEvents()

  const moreThant50ContentsInteracted = (items?.length || 0) === 50
  const showEmtpyState = items !== undefined && items.length === 0

  const contentClasses = [styles.Scrollable]
  if (loading) {
    contentClasses.push(styles.LoadingContent)
  }

  return (
    <Card className={styles.CardHeight}>
      <div className={styles.Root}>
        <div className={styles.IconWrapper}>
          <TopContentIcon />
        </div>
        <div className={styles.TitleWrapper}>
          <h6>
            <Trans i18nKey="insights_tab_top_content" />
          </h6>
          <QuestionMarkTooltip
            onHover={() => tooltipHovered(RelateTooltip.TOP_CONTENT_TITLE)}
          >
            <Trans i18nKey="insights_hover_tooltip_top_content" />
          </QuestionMarkTooltip>
        </div>
        <VerticalScrollShadow className={contentClasses.join(' ')}>
          <Spinner spinning={loading}>
            <>
              <TopContentList>
                {items &&
                  items.map(({ id, count, node }) => (
                    <Fragment key={id}>
                      {node.type === NodeType.PAGE && (
                        <ContentPage
                          cover={node.thumbnail}
                          text={node.title}
                          count={count}
                        />
                      )}
                      {node.type === NodeType.PDF && (
                        <ContentPdf
                          thumbnail={node.thumbnail}
                          text={node.title}
                          count={count}
                        />
                      )}
                      {node.type === NodeType.TEXT && (
                        <ContentText
                          title={node.title}
                          text={node.text}
                          count={count}
                        />
                      )}
                      {node.type === NodeType.LINK && (
                        <ContentLink
                          src={node.text}
                          text={node.title}
                          count={count}
                        />
                      )}
                      {node.type === NodeType.VIDEO && (
                        <ContentVideo
                          cover={node.text}
                          text={node.title}
                          count={count}
                        />
                      )}
                      {node.type === NodeType.VIDEO_STREAMING && (
                        <ContentVideoStreaming
                          thumbnail={node.thumbnail}
                          text={node.title}
                          count={count}
                        />
                      )}
                      {node.type === NodeType.IMAGE && (
                        <ContentImage
                          src={node.text}
                          text={node.title}
                          count={count}
                        />
                      )}
                      {node.type === NodeType.AUDIO && (
                        <ContentAudio
                          title={node.title}
                          text={node.text}
                          count={count}
                        />
                      )}
                    </Fragment>
                  ))}
                {moreThant50ContentsInteracted && (
                  <p className={styles.MoreThan50Message}>
                    <Trans i18nKey="insights_limit_content_loading" />
                  </p>
                )}
              </TopContentList>
              {showEmtpyState && (
                <TopContentEmptyState showNoContentMsg={isBoardEmpty} />
              )}
            </>
          </Spinner>
        </VerticalScrollShadow>
      </div>
    </Card>
  )
}

export default TopContent
