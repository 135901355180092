const roundToFactorOf5 = (number: number) => {
  return Math.round(number / 5) * 5
}

export const calculateDiscount = (
  annualPrice: number,
  monthlyPrice: number,
) => {
  const totalMonthlyCost = monthlyPrice * 12
  const discount = ((totalMonthlyCost - annualPrice) / totalMonthlyCost) * 100
  return roundToFactorOf5(discount)
}

export function formatNumberWithMaxTwoDecimals(number: number) {
  return parseFloat(number.toFixed(2))
}
