import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DialogProvider, PaywallDesktop, PaywallMobile } from 'boards-web-ui'

import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import usePaywallProducts from '@features/payments/hooks/usePaywallProducts'
import { ProductData } from '@features/payments/utils/productTransformer'
import useStartProfileListener from '@hooks/useStartProfileListener'
import ConfetisBackground from '@features/payments/components/ConfetisBackground'
import usePaddleAPI from '@features/payments/hooks/usePaddleAPI'
import PageHeader from '@features/payments/components/PageHeader'
import useFetchAvailableSubscriptions from '@hooks/useFetchAvailableSubscriptions'
import SubscribeButtonText from '@features/payments/components/SubscribeButtonText'
import usePaywallEvents from '@features/analytics/usePaywallEvents'
import useHandleEventCallback from '@features/payments/hooks/useHandleEventCallback'
import { useProfileName } from '@features/profile/hooks/useProfileName'

import { useAuth } from '@hooks/useAuth'
import { usePaywallPlansBulletsProps } from '@features/payments/hooks/usePaywallPlansProps'
import { usePaywallTitle } from '@features/payments/hooks/usePaywallTitle'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { usePaywallPlanTermSelection } from '@features/payments/hooks/usePaywallPlanTermSelection'
import { isMobile } from '../../../utils/deviceParser'
import { EDITOR } from '../../constants/routes'

const PrivatePaywall = () => {
  const { freePlanBullets, businessPlanBullets } = usePaywallPlansBulletsProps()

  const [searchParams] = useSearchParams()
  const queryParampriceId = searchParams.get('price-id')
  const campaignId = searchParams.get('campaign')
  const isFromSource = Boolean(searchParams.get('from-source'))

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { name } = useProfileName()
  const { user, signOut } = useAuth()

  const { current, paddlePriceIds, status, plans } = useSubscriptionPlans()
  const handleEventCallback = useHandleEventCallback(campaignId, isFromSource)
  const { startCheckout, updateCheckout } = usePaddleAPI(handleEventCallback)
  const { products, subscriptions } = usePaywallProducts(paddlePriceIds)
  const { paywallView, paywallTabClickEvent, paywallSubscribeClickEvent } =
    usePaywallEvents()

  useStartProfileListener()
  useFetchAvailableSubscriptions(campaignId || undefined)

  const {
    termOptions,
    selectedProductId,
    billedText,
    isMonthlyTermSelected,
    currentPlan,
    discountForPlan,
    handleTermChange,
  } = usePaywallPlanTermSelection({
    items: products?.items,
  })

  const isSubscriptionCanceled = !!current?.scheduledCancel

  const isSubscribedInAnotherStore = ['AppStore', 'GooglePlay'].includes(
    current?.source,
  )
  const isQueryPriceIdInPlans =
    queryParampriceId && paddlePriceIds.includes(queryParampriceId as string)

  const isSubscribedToAvailablePlanFrom3rdSource = Boolean(
    plans.find(({ id }) => id === current?.plan),
  )

  const alreadySubscribedInAnotherStoreText = useMemo(() => {
    if (
      isSubscribedToAvailablePlanFrom3rdSource &&
      current?.productId === selectedProductId
    ) {
      return undefined
    }

    if (current?.isTrial) return t('desktop_plan_on_trial')
    if (current?.source === 'AppStore')
      return t('paywall_already_subscribed_ios')
    if (current?.source === 'GooglePlay')
      return t('paywall_already_subscribed_android')
    return undefined
  }, [
    isSubscribedToAvailablePlanFrom3rdSource,
    current?.productId,
    current?.isTrial,
    current?.source,
    selectedProductId,
    t,
  ])

  const querySubscription = subscriptions.find(
    (s) => s.productId === queryParampriceId,
  )

  const isToOpenCheckoutDialog = useMemo(() => {
    return (
      status === 'loaded' &&
      isQueryPriceIdInPlans &&
      !isSubscribedInAnotherStore &&
      !isSubscriptionCanceled &&
      !current?.plan
    )
  }, [
    status,
    isQueryPriceIdInPlans,
    isSubscribedInAnotherStore,
    isSubscriptionCanceled,
    current,
  ])

  useEffect(() => {
    paywallView(campaignId || undefined)
  }, [campaignId, paywallView])

  useEffect(() => {
    if (isToOpenCheckoutDialog && querySubscription?.productId) {
      startCheckout(
        querySubscription?.productId,
        querySubscription?.productDiscountId,
      )
    }
  }, [
    isToOpenCheckoutDialog,
    querySubscription?.productId,
    querySubscription?.productDiscountId,
    startCheckout,
  ])

  const handleOpenCheckoutDialog = async (plan: ProductData) => {
    const priceId = plan.subscription?.offer?.priceId || plan.priceId
    paywallSubscribeClickEvent(
      plan.productName,
      priceId,
      campaignId || undefined,
    )

    if (current && !current.isReverseTrial) {
      await updateCheckout(priceId)
      navigate(EDITOR)
      return
    }

    startCheckout(priceId, currentPlan?.subscription?.productDiscountId)
  }

  const isCurrentSubscribedPlan =
    current &&
    isSubscribedToAvailablePlanFrom3rdSource &&
    currentPlan?.priceId === current?.productId

  const Paywall = isMobile ? PaywallMobile : PaywallDesktop

  const { shouldShowFreeTrialIndicator, shouldShowFreePlanIndicator } =
    useReverseTrialContext()

  const { title: paywallTitle, isLongText } = usePaywallTitle({
    currentPlanTitle: currentPlan?.subscription?.title,
    shouldShowFreePlanIndicator,
    shouldShowFreeTrialIndicator,
  })

  return (
    <DialogProvider>
      <ConfetisBackground
        isMobile={isMobile}
        hasConfetis={!!campaignId}
        header={
          <PageHeader
            name={name}
            isMobile={isMobile}
            user={user}
            onLogoutClick={signOut}
            hideBackButton={isFromSource}
          />
        }
      >
        {currentPlan && (
          <Paywall
            priceTermValue={selectedProductId}
            onTermChange={handleTermChange}
            planPriceTermSwitcherOptions={termOptions}
            onChangeSubscriptionPlan={paywallTabClickEvent}
            title={paywallTitle}
            isLongTextTitle={isLongText}
            billedText={billedText}
            alreadySubscribedInAnotherStoreText={
              alreadySubscribedInAnotherStoreText || ''
            }
            defaultPlan={'business'}
            plans={{
              free: {
                chipLabel: t('free_plan_title'),
                planPriceTitle: t('free_plan_title'),
                planPriceValue: `${products?.currencySymbol}0`,
                bullets: freePlanBullets,
                confirmButtonText: current
                  ? t('action_get_started')
                  : t('paywall_current_plan'),
                hideSubscribeButton: !!current,
              },
              business: {
                chipLabel: null,
                planPriceTitle: currentPlan.productName,
                planPriceValue: `${products?.currencySymbol}${currentPlan.price}`,
                planPriceSubValue: {
                  linedThrough: !isMonthlyTermSelected
                    ? `${products?.currencySymbol}${products?.items[0]?.price}`
                    : undefined,
                  commonText: t('business_price'),
                },
                planPriceDiscountBadge: !isMonthlyTermSelected
                  ? t('label_save_number', {
                      percentage: discountForPlan,
                    })
                  : undefined,
                bullets: businessPlanBullets,
                confirmButtonText: (
                  <SubscribeButtonText
                    subscribedPlanId={current?.productId}
                    plan={currentPlan}
                    showCurrentPlanText={isCurrentSubscribedPlan}
                  />
                ),
                onSubscribePlan:
                  isCurrentSubscribedPlan || isSubscriptionCanceled
                    ? undefined
                    : () => handleOpenCheckoutDialog(currentPlan),
              },
            }}
          />
        )}
      </ConfetisBackground>
    </DialogProvider>
  )
}

export default PrivatePaywall
