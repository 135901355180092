import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InputCurrency } from 'boards-web-ui'

import { useCheckout } from '../hooks'

const CheckoutCurrency: FC = () => {
  const { t } = useTranslation()
  const { currency, currencies, onCurrencyChange } = useCheckout()

  return (
    <InputCurrency
      label={t('label_currency')}
      value={currency}
      onChange={(e) => onCurrencyChange(e.target.value)}
      currencies={currencies as Array<string>}
    />
  )
}

export default React.memo(CheckoutCurrency)
