import React from 'react'

import useBoard from '../../../../hooks/useBoard'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import ContentTitle, { ContentTitleSize } from './ContentTitle'
import FolderActions from './FolderActions'

import styles from './PageTitle.module.css'

const PageTitle: React.FC = () => {
  const { folder } = useBoard()
  const {
    renamePageTooltip,
    defaultPageTitle,
    addPagePlaceholder,
    deletePageAction,
    deletePageTitle,
    deletePageConfirmation,
    pageDeleted,
  } = useToolbarTranslations()

  return (
    <div className={styles.Inline}>
      <ContentTitle
        title={folder.title || defaultPageTitle()}
        size={ContentTitleSize.Default}
        tooltip={renamePageTooltip()}
        placeholder={addPagePlaceholder()}
        readOnly
      />

      <FolderActions
        deleteAction={deletePageAction()}
        deleteTitle={deletePageTitle()}
        deleteConfirmation={deletePageConfirmation()}
        deletedToastMsg={pageDeleted()}
      />
    </div>
  )
}

export default React.memo(PageTitle)
