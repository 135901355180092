import React, { FC, HTMLAttributes, memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  ButtonNew,
  Card,
  CardActions,
  CardBody,
  CardBodyContent,
  CardBodyTitle,
  CardHeader,
  theme,
} from 'boards-web-ui'
import clsx from 'clsx'

import DisconnectIconImage from '../../../../assets/images/disconnect.png'

import styles from './PayPalDisconnectWarning.module.css'

type Props = HTMLAttributes<HTMLDivElement> & {
  onSubmit: () => void
  onCancel: () => void
}
const PayPalDisconnectWarning: FC<Props> = ({
  onCancel,
  onSubmit,
  className,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Card className={clsx(className, styles.Root)} {...rest}>
      <CardHeader
        bg={theme.theme.colors.alert100}
        icon={<img src={DisconnectIconImage} alt="danger icon" />}
      />
      <CardBody>
        <CardBodyTitle>
          {t('payment_provider_disconnect_dialog_title')}
        </CardBodyTitle>
        <CardBodyContent className={styles.Centred}>
          <Trans
            i18nKey={'payment_provider_disconnect_delete_dialog_subtitle'}
          />
        </CardBodyContent>
        <CardActions>
          <ButtonNew fullWidth color="warning" size="large" onClick={onSubmit}>
            {t('action_disconnect')}
          </ButtonNew>

          <ButtonNew
            fullWidth
            color="secondary"
            size="large"
            onClick={onCancel}
          >
            {t('action_cancel')}
          </ButtonNew>
        </CardActions>
      </CardBody>
    </Card>
  )
}

export default memo(PayPalDisconnectWarning)
