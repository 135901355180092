import { Trans } from 'react-i18next'
import { NODE_LINK_TYPES, SocialLinkIcon } from 'boards-web-ui'

import styles from './ButtonItem.module.css'

const Labels = {
  [NODE_LINK_TYPES.REGULAR]: 'button_link_label',
  [NODE_LINK_TYPES.ZOOM]: 'button_zoom_label',
  [NODE_LINK_TYPES.CALENDLY]: 'button_calendly_label',
  [NODE_LINK_TYPES.WHATS_APP]: 'button_whatsapp_label',
  [NODE_LINK_TYPES.FACEBOOK]: 'button_messenger_label',
  [NODE_LINK_TYPES.TELEGRAM]: 'button_telegram_label',
  [NODE_LINK_TYPES.PHONE]: 'button_phone_label',
  [NODE_LINK_TYPES.SMS]: 'button_sms_label',
  [NODE_LINK_TYPES.MAIL]: 'button_email_label',
  [NODE_LINK_TYPES.PAYPAL]: 'button_paypal_label',
  [NODE_LINK_TYPES.VENMO]: 'button_venmo_label',
  [NODE_LINK_TYPES.CASH]: 'button_cashapp_label',
}

interface Props {
  type: NODE_LINK_TYPES
  onClick: (type: NODE_LINK_TYPES) => void
}
const ButtonItem = ({ type, onClick }: Props) => {
  return (
    <div className={styles.Root} onClick={() => onClick(type)}>
      <p className={styles.Label}>
        <Trans i18nKey={Labels[type]} />
      </p>
      <SocialLinkIcon type={type} />
    </div>
  )
}

export default ButtonItem
