import { useState } from 'react'

export enum ShareScreens {
  SHARE = 'share',
  MEMBERS = 'members',
}

const useShareNav = (defaultScreen: ShareScreens = ShareScreens.SHARE) => {
  const [screen, setScreen] = useState<ShareScreens>(defaultScreen)

  const openShareScreen = () => setScreen(ShareScreens.SHARE)

  const openMembersScreen = () => setScreen(ShareScreens.MEMBERS)

  return {
    isShareScreen: screen === ShareScreens.SHARE,
    openShareScreen,
    openMembersScreen,
  } as const
}

export default useShareNav
