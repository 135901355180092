/* eslint no-console: ["error", { allow: ["error"] }] */
import { useQuery } from 'react-query'
import { Paddle, PaddleEventData } from '@paddle/paddle-js'

import { configurationObjectFromEnv } from 'utils/configurationObjectFromEnv'
import { ENV_KEYS } from 'utils/envKeys'
import { useEffect } from 'react'
import { createScript, getScriptBySrc, loadScript } from '../utils/dom'

export const paddleQueryKey = 'paddle'
const paddleScriptSource = 'https://cdn.paddle.com/paddle/v2/paddle.js'

const { paddleToken, projectId } = configurationObjectFromEnv<{
  paddleToken: string
  projectId: 'bliss-creator' | 'bliss-development'
}>({
  paddleToken: ENV_KEYS.PADDLE_TOKEN,
  projectId: ENV_KEYS.PROJECT_ID,
})

const environment = projectId === 'bliss-creator' ? 'production' : 'sandbox'

export const usePaddleSdk = (
  email: string,
  eventCallback?: (event: PaddleEventData) => void,
) => {
  useEffect(() => {
    if (!window.Paddle) {
      return
    }

    window.Paddle.Update({
      pwCustomer: {
        email,
      },
      eventCallback,
    })
  }, [email, eventCallback])

  return useQuery(
    paddleQueryKey,
    async () => {
      if (!window.Paddle) {
        const paddleScript = getScriptBySrc(paddleScriptSource)
        try {
          if (paddleScript) {
            await loadScript(paddleScript)
          } else {
            const script = createScript(paddleScriptSource)
            await loadScript(script)
          }

          const paddleSdk = window.Paddle as unknown as Paddle
          paddleSdk.Environment.set(environment)
          paddleSdk.Initialize({
            token: paddleToken,
            pwCustomer: {
              email,
            },
            eventCallback,
          })
        } catch (err) {
          console.error('Fail to load Paddle')
        }
      }

      return window.Paddle as Paddle
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  )
}
