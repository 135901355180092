import { KeyboardEvent } from 'react'
import { InputField, InputPhone, NODE_LINK_TYPES } from 'boards-web-ui'

import { isPhoneInput } from './util'
import useButtonTranslations from './hooks/useButtonTranslations'
import FormHeaderWithTitleAndMessage from './components/FormHeaderWithTitleAndMessage'

interface Props {
  title: string
  text: string
  message?: string
  type: NODE_LINK_TYPES
  error?: string
  countryCode?: string
  autoFocusText?: boolean
  autoFocusTitle?: boolean
  onTitleChange: (t: string) => void
  onMessageChange?: (m: string) => void
  onChangeNumber: (number: string) => void
  onChangeCountry: ({
    dialCode,
    countryCode,
  }: {
    countryCode?: string
    dialCode?: string
  }) => void
  onTextFocus: () => void
}
const EditPhoneButtonForm = ({
  title,
  text,
  type,
  error,
  message,
  countryCode,
  autoFocusText,
  autoFocusTitle,
  onTitleChange,
  onMessageChange,
  onChangeNumber,
  onChangeCountry,
  onTextFocus,
}: Props) => {
  const { getButtonTranslations } = useButtonTranslations()
  const buttonTranslations = getButtonTranslations(type)

  if (!isPhoneInput(type)) {
    return null
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) =>
    ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault()

  return (
    <FormHeaderWithTitleAndMessage
      type={type}
      title={title}
      buttonTitle={buttonTranslations.title}
      inputLabel={buttonTranslations.titleInputLabel}
      inputPlaceholder={buttonTranslations.titleInputPlaceholder}
      explanationText={buttonTranslations.explanationText}
      onTitleChange={onTitleChange}
      autoFocus={autoFocusTitle}
    >
      <InputPhone
        value={text}
        countryCode={countryCode?.toLowerCase()}
        label={buttonTranslations.textInputlabel}
        placeholder={buttonTranslations.textInputPlaceholder}
        errorMessage={error}
        onChangeCountry={onChangeCountry}
        onChange={(e) => onChangeNumber(e.target.value)}
        onFocus={onTextFocus}
        onKeyDown={handleKeyUp}
        autoFocus={autoFocusText}
        blueVariant
      />
      {onMessageChange && (
        <InputField
          value={message}
          label={buttonTranslations.messageLabel}
          placeholder={buttonTranslations.messagePlaceholder}
          onChange={(e) => onMessageChange && onMessageChange(e.target.value)}
          blueVariant
        />
      )}
    </FormHeaderWithTitleAndMessage>
  )
}

export default EditPhoneButtonForm
