import { useTranslation } from 'react-i18next'
import { NODE_LINK_TYPES } from 'boards-web-ui'

export interface ButtonTranslations {
  title: string
  titleInputLabel: string
  titleInputPlaceholder: string

  textInputlabel: string
  textInputPlaceholder: string

  explanationText: string
  autoPopulateText: string

  messageLabel?: string
  messagePlaceholder?: string
}
type ButtonsTranslations = {
  [key in NODE_LINK_TYPES]: ButtonTranslations
}
const useButtonTranslations = () => {
  const { t } = useTranslation()

  const translations: ButtonsTranslations = {
    [NODE_LINK_TYPES.CALENDLY]: {
      title: t('button_calendly_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_calendly_label_placeholder'),
      textInputlabel: t('button_calendly_button_name'),
      textInputPlaceholder: t('button_calendly_button_placeholder'),
      explanationText: t('button_calendly_explanation_text'),
      autoPopulateText: t('button_calendly_label_auto_populate'),
    },
    [NODE_LINK_TYPES.ZOOM]: {
      title: t('button_zoom_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_zoom_label_placeholder'),
      textInputlabel: t('button_zoom_button_name'),
      textInputPlaceholder: t('button_zoom_button_placeholder'),
      explanationText: t('button_zoom_explanation_text'),
      autoPopulateText: t('button_zoom_label_auto_populate'),
    },
    [NODE_LINK_TYPES.FACEBOOK]: {
      title: t('button_messenger_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_messenger_label_placeholder'),
      textInputlabel: t('username_label'),
      textInputPlaceholder: t('button_messenger_button_placeholder_username'),
      explanationText: t('button_messenger_explanation_text'),
      autoPopulateText: t('button_messenger_label_auto_populate'),
    },
    [NODE_LINK_TYPES.PAYPAL]: {
      title: t('button_paypal_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_paypal_label_placeholder'),
      textInputlabel: t('button_paypal_button_name'),
      textInputPlaceholder: t('button_paypal_button_placeholder'),
      explanationText: t('button_paypal_explanation_text'),
      autoPopulateText: t('button_paypal_label_auto_populate'),
    },
    [NODE_LINK_TYPES.VENMO]: {
      title: t('button_venmo_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_venmo_label_placeholder'),
      textInputlabel: t('button_venmo_button_name'),
      textInputPlaceholder: t('button_venmo_button_placeholder'),
      explanationText: t('button_venmo_explanation_text'),
      autoPopulateText: t('button_venmo_label_auto_populate'),
    },
    [NODE_LINK_TYPES.CASH]: {
      title: t('button_cashapp_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_cashapp_label_placeholder'),
      textInputlabel: t('button_cashapp_button_name'),
      textInputPlaceholder: t('button_cashapp_button_placeholder'),
      explanationText: t('button_cashapp_explanation_text'),
      autoPopulateText: t('button_cashapp_label_auto_populate'),
    },
    [NODE_LINK_TYPES.REGULAR]: {
      title: t('button_link_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_link_label_placeholder'),
      textInputlabel: t('button_link_label'),
      textInputPlaceholder: t('button_link_button_placeholder'),
      explanationText: t('button_link_explanation_text'),
      autoPopulateText: t('button_link_label_auto_populate'),
    },
    [NODE_LINK_TYPES.WHATS_APP]: {
      title: t('button_whatsapp_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_whatsapp_label_placeholder'),
      textInputlabel: t('buttons_phone_number_label'),
      textInputPlaceholder: t('buttons_phone_number_placeholder'),
      explanationText: t('button_whatsapp_explanation_text'),
      autoPopulateText: t('button_whatsapp_label_auto_populate'),

      messageLabel: t('buttons_pre_filled_message'),
      messagePlaceholder: t('buttons_pre_filled_message_placeholder'),
    },
    [NODE_LINK_TYPES.PHONE]: {
      title: t('buttons_phone_number_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_phone_label_placeholder'),
      textInputlabel: t('buttons_phone_number_label'),
      textInputPlaceholder: t('buttons_phone_number_placeholder'),
      explanationText: t('button_phone_explanation_text'),
      autoPopulateText: t('button_phone_label_auto_populate'),
    },
    [NODE_LINK_TYPES.SMS]: {
      title: t('button_sms_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_sms_label_placeholder'),
      textInputlabel: t('buttons_phone_number_label'),
      textInputPlaceholder: t('buttons_phone_number_placeholder'),
      explanationText: t('button_sms_explanation_text'),
      autoPopulateText: t('button_sms_label_auto_populate'),
    },
    [NODE_LINK_TYPES.MAIL]: {
      title: t('button_email_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_email_label_placeholder'),
      textInputlabel: t('button_email_button_name'),
      textInputPlaceholder: t('button_email_button_placeholder'),
      explanationText: t('button_email_explanation_text'),
      autoPopulateText: t('button_email_label_auto_populate'),
    },
    [NODE_LINK_TYPES.TELEGRAM]: {
      title: t('button_telegram_label'),
      titleInputLabel: t('placeholder_label'),
      titleInputPlaceholder: t('button_telegram_label_placeholder'),
      textInputlabel: t('buttons_phone_number_label'),
      textInputPlaceholder: t('buttons_phone_number_placeholder'),
      explanationText: t('button_telegram_explanation_text'),
      autoPopulateText: t('button_telegram_label_auto_populate'),
    },
  }

  const getButtonTranslations = (type: NODE_LINK_TYPES) => {
    return translations[type]
  }

  return { getButtonTranslations }
}

export default useButtonTranslations
