import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useToast } from '@hooks/useToast'
import {
  copyImageToClipboard,
  copyTextToClipBoard,
  insertTextFromClipBoardAtCaret,
} from '@helpers/clipboard'

export const useClipboard = () => {
  const toast = useToast()
  const { t } = useTranslation()

  const copyText = useCallback(
    async (...args) => {
      try {
        await copyTextToClipBoard(...args)
      } catch (error) {
        toast(t('optimistic_ui_failed'))
      }
    },
    [t, toast],
  )

  const copyImg = useCallback(
    async (...args) => {
      try {
        await copyImageToClipboard(...args)
      } catch (error) {
        toast(t('optimistic_ui_failed'))
      }
    },
    [t, toast],
  )

  const pasteTextFromClipBoardAtCaret = useCallback(
    async (...args) => insertTextFromClipBoardAtCaret(...args),
    [],
  )

  return { copyText, copyImg, pasteTextFromClipBoardAtCaret }
}
