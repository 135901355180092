const sizes = ['maxres', 'standard', 'high']

export function getInfo(data) {
  const thumbnails = data?.thumbnails

  if (thumbnails) {
    // eslint-disable-next-line
    for (let i in sizes) {
      const thumbnail = thumbnails[sizes[i]]
      if (thumbnail) {
        return {
          title: data.title,
          thumbnail: thumbnail.url,
        }
      }
    }
  }

  throw new Error('Size not found for youtube video!')
}
