import React, { FC, ReactNode } from 'react'

import styles from './EmptyContent.module.css'

interface Props {
  icon: ReactNode
  title: ReactNode
}

const EmptyContent: FC<Props> = ({ icon, title }) => (
  <div className={styles.Root}>
    <div className={styles.IconContainer}>{icon}</div>

    {title}
  </div>
)
export default EmptyContent
