import { createContext, useContext } from 'react'

const SelectContext = createContext<{
  selectedOption: string
  changeSelectedOption: (option: string, label: string) => void
}>({
  selectedOption: '',
  // eslint-disable-next-line
  changeSelectedOption: () => {},
})

const useSelectContext = () => {
  const context = useContext(SelectContext)
  if (!context) {
    throw new Error('Error in creating the context')
  }
  return context
}

export { useSelectContext, SelectContext }
