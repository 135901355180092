import React, { PropsWithChildren, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { CloseIcon } from 'boards-web-ui'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'

import { IconWrapper } from '@ui/components/IconWrapper'
import styles from './MessagesTooltip.module.css'

type Props = PropsWithChildren<{
  onClose: () => void
}>

export const MessagesTooltip: React.FC<Props> = ({ onClose }) => {
  const { messagesTooltipView } = useUpdatesEvents()
  useEffect(() => {
    messagesTooltipView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.ParentContainer}>
      <div className={styles.IconsContainer}>
        <span className={styles.DotsIcon}>💬</span>

        <IconWrapper size={16}>
          <CloseIcon className={styles.CloseIcon} onClick={onClose} />
        </IconWrapper>
      </div>
      <h4 className={styles.Title}>
        <Trans i18nKey="messages_tooltip_title" />
      </h4>
      <p className={styles.Text}>
        <Trans i18nKey="messages_tooltip_free_body" />
      </p>
    </div>
  )
}

export default MessagesTooltip
