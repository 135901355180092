import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import styles from './IconWrapper.module.css'

interface Props {
  size?: number
  color?: 'primary' | 'white' | 'gray'
  className?: string
}

export const IconWrapper: FC<PropsWithChildren<Props>> = ({
  size = 20,
  color = 'inherit',
  children,
  className,
}) => {
  return (
    <div
      className={clsx(
        styles.Root,
        color === 'primary' && styles.Primary,
        color === 'white' && styles.White,
        color === 'gray' && styles.Gray,
        className,
      )}
      style={{ width: size, height: size, fontSize: size }}
    >
      {children}
    </div>
  )
}
