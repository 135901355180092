import { NODE_LINK_TYPES } from 'boards-web-ui'
import { DocumentData } from 'firebase/firestore'
import { Node, NodeType } from 'app/models'

import urlHelper from './url'

export function isFolderNode(node: DocumentData) {
  return node.content !== undefined
}

export function isImageNode(node: DocumentData) {
  return node.type === 'img'
}

export function isAudioNode(node: DocumentData) {
  return node.type === 'aud'
}

export function isVideoNode(node: DocumentData) {
  return node.type === 'vid'
}

export function isVideoStreamingNode(node: DocumentData) {
  return node.type === 'vst'
}

export function isLinkNode(node: DocumentData) {
  return node.type === 'lnk' || urlHelper.isValidUrl(node.text)
}

export function isFormNode(node: DocumentData) {
  return node.type === 'frm'
}

export function isCheckoutForm(node: DocumentData) {
  return node.type === 'frm' && node?.form?.type === NodeType.CHECKOUT
}

export function isPdfNode(node: DocumentData) {
  return urlHelper.isPdf(node.text)
}

export function isParagraphNode(node: DocumentData) {
  return (
    Object.prototype.hasOwnProperty.call(node, 'text') &&
    node.text !== undefined
  )
}

export function isHeadlineNode(node: DocumentData) {
  return (
    Object.prototype.hasOwnProperty.call(node, 'title') &&
    node.title !== undefined &&
    !node.text
  )
}

export function convertFolderNodeToPageNode(node: Node) {
  switch (node.type) {
    case NodeType.LINK:
      return {
        ...node,
        type: NodeType.BUTTON,
        link_type: NODE_LINK_TYPES.REGULAR,
      }
    case NodeType.TEXT:
      return {
        ...node,
        type: isHeadlineNode(node) ? NodeType.HEADLINE : NodeType.PARAGRAPH,
      }
    default:
      return node
  }
}
