export const getTimeFromNanoSeconds = (
  seconds: number,
  nanoseconds: number,
): number => {
  if (typeof seconds !== 'number') {
    throw new Error(`wrong argument seconds: ${seconds}`)
  }

  if (typeof nanoseconds !== 'number') {
    throw new Error(`wrong argument nanoseconds ${nanoseconds}`)
  }

  return Number(`${seconds}${nanoseconds}`)
}
