import React, { PropsWithChildren } from 'react'

import styles from './Chip.module.css'

type Props = PropsWithChildren<{
  className?: string
  blue?: boolean
  marketingPurple?: boolean
}>

const Chip: React.FC<Props> = ({
  className,
  blue,
  marketingPurple,
  children,
}) => {
  const chipClasses = [styles.Root]
  if (className) chipClasses.push(className)
  if (blue) chipClasses.push(styles.Blue)
  if (marketingPurple) chipClasses.push(styles.MarketingPurple)

  return <div className={chipClasses.join(' ')}>{children}</div>
}

export default Chip
