import React, { FC, memo } from 'react'
import { Icon, SpinnerIcon, Tag } from 'boards-web-ui'

import useUserFeatures, { Features } from '@hooks/useUserFeatures'

import clsx from 'clsx'
import CreateBoardButtonTooltip from './components/CreateBoardButton'

import styles from './BoardsMenuTitle.module.css'

type Props = {
  className?: string
  loading?: boolean
  onCreate: () => void
  isFreePlanUser: boolean
}
const BoardsMenuTitle: FC<Props> = ({
  className,
  loading,
  onCreate,
  isFreePlanUser,
}) => {
  const { limit, usedCount } = useUserFeatures(Features.boards)
  const CreateBoardButton = isFreePlanUser ? null : (
    <CreateBoardButtonTooltip onCreate={onCreate} rounded />
  )

  return (
    <div
      className={clsx(styles.TitleWrap, loading && styles.Aligned, className)}
    >
      <div className={styles.Root}>
        <h3
          className={clsx(
            styles.Title,
            isFreePlanUser && styles.TitleFreePlanUser,
          )}
        >
          Boards
        </h3>
        &nbsp;
        {limit !== 'unlimited' && !isFreePlanUser ? (
          <>
            <span>
              {usedCount}/{limit}
            </span>
            &nbsp;
            <Tag className={styles.Tag} secondary>
              Free
            </Tag>
          </>
        ) : null}
      </div>

      {loading ? (
        <Icon size="21px" color="#bcbcbc" className={styles.Margin}>
          <SpinnerIcon />
        </Icon>
      ) : (
        CreateBoardButton
      )}
    </div>
  )
}

export default memo(BoardsMenuTitle)
