import { useEffect, useState } from 'react'
import { differenceInCalendarDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import useBoard from '../../../../hooks/useBoard'
import { useToast } from '../../../../hooks/useToast'
import useBoardNodes from '../../../../hooks/useBoardNodes'
import useAPI, { FUNCTIONS } from '../../../../hooks/useAPI'
import { transformAndFilterInsightsData } from '../transformers/insightsTransformer'
import { InsightsState } from '../models/insights'
import { InsightsResultState } from '../models/insightsResult'
import { InsightsFilter } from '../models/filter'

const initialState = {
  hasData: undefined,
  activeMembers: undefined,
  contentInteractions: undefined,
  dailyActiveMembers: undefined,
  dailyContentInteractions: undefined,
  topContentItems: undefined,
} as InsightsState

interface IInsights {
  insights: InsightsState
  isLoading: boolean
  isBoardEmpty: boolean
}

const useInsights = (filter: InsightsFilter): IInsights => {
  const toast = useToast()
  const { t } = useTranslation()
  const { boardId, isEmpty } = useBoard()
  const { getNode } = useBoardNodes()
  const [insights, setInsights] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const {
    hasError,
    isLoading: isApiLoading,
    data,
  } = useAPI<InsightsResultState>(FUNCTIONS.GET_INSIGHTS, boardId)
  const boardCreateTime = data?.boardCreateTime
  const wasCreatedToday = boardCreateTime
    ? differenceInCalendarDays(new Date(), boardCreateTime) === 0
    : false

  useEffect(() => {
    if (!isLoading && hasError) {
      toast(t('optimistic_ui_failed'), false)
    }
  }, [isLoading, hasError, t, toast])

  useEffect(() => {
    if (!isLoading && wasCreatedToday) {
      toast(t('toast_board_insights_not_available'), false)
    }
  }, [isLoading, wasCreatedToday, t, toast])

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [filter])

  useEffect(() => {
    const transformAndFilterData = async () => {
      if (data && !hasError && !wasCreatedToday) {
        setInsights(transformAndFilterInsightsData(data, filter, getNode))
      } else {
        setInsights(initialState)
      }
    }

    setInsights(initialState)
    transformAndFilterData()
  }, [filter, data, hasError, wasCreatedToday, getNode])

  return {
    insights,
    isLoading: isApiLoading || isLoading,
    isBoardEmpty: isEmpty,
  }
}

export default useInsights
