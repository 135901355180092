import { useCallback } from 'react'
import { t } from 'i18next'

export const useBoardsMenuTranslations = () => {
  const emptyBoardTitle = (): string => t('board_screen_currently_empty')
  const emptyBoardSubTitle = (): string => t('board_empty_state_add_content')
  const emptyBoardTitle2 = (): string =>
    t('desktop_no_boards_empty_state_title_2')
  const emptyBoardSubTitle2 = (): string =>
    t('desktop_no_boards_empty_state_subtitle_2')
  const emptyPagesBoardTitle = (): string =>
    t('pages_board_empty_state_title_desktop')
  const emptyPagesBoardSubTitle = (): string =>
    t('pages_board_empty_state_subtitle_desktop')
  const createBoard = (): string => t('create_board')
  const createBoardHover = (): string => t('desktop_hover_create_board')
  const sharedBoard = (): string => t('desktop_hover_shared_board')

  const emptyFolderTitle = () => t('board_screen_folder_empty')
  const emptyFolderSubTitle = () => t('board_empty_state_add_content')

  const emptyPageTitle = () => t('page_empty_state_title')
  const emptyPageSubTitle = () => t('board_screen_add_your_first_key')

  const parseTitle = useCallback((title: string, isRoot?: boolean): string => {
    if (title) {
      return title
    }

    if (isRoot) {
      return t('my_board')
    }

    return t('my_folder')
  }, [])

  return {
    emptyBoardTitle,
    emptyBoardSubTitle,
    emptyBoardTitle2,
    emptyBoardSubTitle2,
    emptyPagesBoardTitle,
    emptyPagesBoardSubTitle,
    createBoard,
    createBoardHover,
    sharedBoard,
    emptyFolderTitle,
    emptyFolderSubTitle,
    emptyPageTitle,
    emptyPageSubTitle,
    parseTitle,
  } as const
}
