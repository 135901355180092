export enum ButtonGroupType {
  GENERAL = 'general',
  CONTACT = 'contact',
  PAYMENT = 'payment',
}

export interface Country {
  callingCode: string
  code: string
  name: string
}
