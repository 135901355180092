import React, { PropsWithChildren } from 'react'

import styles from './ReadMoreText.module.css'

type Props = PropsWithChildren<{
  className?: string
}>

export const ReadMoreText: React.FC<Props> = ({ children, className }) => {
  return <span className={[styles.Text, className].join(' ')}>{children}</span>
}
