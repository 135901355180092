import { useTranslation } from 'react-i18next'
import { useDialog } from 'boards-web-ui'

import EditText from '../components/EditText'
import { IUseAddNode } from '../models/IUseAddNode'

const useAddText: IUseAddNode = (save) => {
  const { open } = useDialog()
  const { t } = useTranslation()

  const addTextNode = () => {
    open(
      <EditText
        initialText={''}
        initialTitle={''}
        titlePlaceholder={t('add_an_update_title_optional')}
        textPlaceholder={t('node_text_placeholder')}
        onSave={save}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return addTextNode
}

export default useAddText
