type Properties = Record<string, unknown>

// Utility function to omit deep properties
const omitDeep = (obj: Properties, paths: string[]): Properties => {
  // Deep clone the object to avoid mutating the original
  const newObj: Properties = JSON.parse(JSON.stringify(obj))

  paths.forEach((path) => {
    const keys = path.split('.')
    let current: Properties = newObj

    for (let i = 0; i < keys.length - 1; i += 1) {
      if (current[keys[i]] === undefined) {
        return
      }
      current = current[keys[i]] as Properties
    }

    delete current[keys[keys.length - 1]]
  })

  return newObj
}

export default omitDeep
