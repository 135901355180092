import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  AnimatedDialog,
  ButtonNew,
  ReplaceIcon,
  useDialog,
} from 'boards-web-ui'

import useUpload from '@features/toolbar/hooks/useUpload'
import useIsApiAvailableOrNotify from '@hooks/useIsApiAvailableOrNotify'
import { randomContentId } from '@helpers/index'

import DoneButton from 'ui/components/DoneButton'
import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import ModalEditWithConfirmation from 'components/ModalEditWithConfirmation'
import InputModalTitle from 'elements/InputModalTitle'

import { putImage } from '_firebase'
import { uploadActions } from 'actions'
import { IconWrapper } from '@ui/components/IconWrapper'
import React from 'react'
import { NodeType } from '../../../models'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'
import useNodeAPI from '../hooks/useNodeAPI'

import styles from './EditImage.module.css'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

interface Props {
  nodeId: string
  initialTitle: string
  initialText: string
  onSave: ICreateOrUpdateNode
  isPageEditor?: boolean
  onContentReplaceEvent: () => void
}

const preventExitWhileLoading = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = false
}

const EditImage = ({
  nodeId,
  initialText,
  initialTitle,
  onSave,
  isPageEditor,
  onContentReplaceEvent,
}: Props) => {
  const { t } = useTranslation()
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const { title, hasChanges, setTitle } = useNodeAPI(initialTitle, '')
  const dispatch = useDispatch()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const uploadImage = async (file: HTMLInputElement) => {
    if (!isApiAvailableOrNotify()) return

    close()

    window.addEventListener('beforeunload', preventExitWhileLoading)

    const imageName = `${randomContentId()}.${file.name.split('.').pop()}`
    const text = `${process.env.REACT_APP_IMAGE_BUCKET}/${imageName}`

    dispatch(uploadActions.addUpload(nodeId))
    if (!isPageEditor) {
      close()
    }
    await putImage(file, imageName)

    onSave({
      title,
      text,
      type: NodeType.IMAGE,
    })

    onContentReplaceEvent()

    setTimeout(() => {
      dispatch(uploadActions.removeUpload(nodeId as string))
    })

    window.removeEventListener('beforeunload', preventExitWhileLoading)
  }

  const fileUpload = useUpload(uploadImage, {
    accept: 'image/png, image/gif, image/jpeg',
    multiple: false,
  })

  const handleSave = () => {
    onSave({
      text: initialText,
      title,
      type: NodeType.IMAGE,
    })

    close()
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: close,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <div className={styles.FooterControl}>
              <ButtonNew
                color={'secondary'}
                size={'medium'}
                onClick={fileUpload}
                startIcon={
                  <IconWrapper>
                    <ReplaceIcon />
                  </IconWrapper>
                }
              >
                <Trans i18nKey="replace_image" />
              </ButtonNew>

              <DoneButton onClick={handleSave}>
                <Trans i18nKey="action_done" />
              </DoneButton>
            </div>
          }
        >
          <div className={styles.Root}>
            {!isPageEditor && (
              <InputModalTitle
                value={title}
                placeholder={t('add_an_update_title_optional')}
                onChange={setTitle}
              />
            )}

            <div className={styles.ImageWrapper}>
              <img
                className={styles.Image}
                alt={initialTitle}
                src={initialText}
              />
            </div>
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditImage
