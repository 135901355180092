import { configurationObjectFromEnv } from '../../../../utils/configurationObjectFromEnv'
import { ENV_KEYS } from '../../../../utils/envKeys'

type Props = {
  project: string
  shortcutDomain: string
}
const { project, shortcutDomain } = configurationObjectFromEnv<Props>({
  project: ENV_KEYS.PROJECT_ID,
  shortcutDomain: ENV_KEYS.SHARED_FOLDERS_DOMAIN,
})

const baseUrl = `https://us-central1-${project}.cloudfunctions.net`

export const buildPdfCoverUrl = (image: string) =>
  `${baseUrl}/thumbnails/200x200-fill/${image}`

export default {
  baseUrl,
  shortcutDomain,
}
