import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getNodeId } from '@helpers/NodeIdGenerator'
import useFolderEvents from '@features/analytics/useFolderEvents'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

import { ViewSource } from '@features/analytics/models/sourceEvents'
import { NodeType } from '../../../models'
import { AddNodeData } from '../models'

import { folderActions, nodeActions } from '../../../../actions'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

const useFolderApi = () => {
  const dispatch = useDispatch()
  const { createFolderEvent } = useFolderEvents()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const create = useCallback(
    (
      boardId: string,
      folder: string,
      key: string,
      node: AddNodeData,
      source: ViewSource.board | ViewSource.destinationPicker,
    ) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(folderActions.addFolder(boardId, folder, key, node))

      createFolderEvent(
        {
          id: getNodeId(0, key),
          title: node.title,
          text: '',
          icon: node.icon,
          type: NodeType.FOLDER,
        },
        boardId,
        source,
      )
    },
    [dispatch, createFolderEvent, isApiAvailableOrNotify],
  )

  const update = useCallback(
    (
      boardId: string,
      folderId: string,
      contentId: string,
      node: AddNodeData,
    ) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(
        nodeActions.setProperties(
          boardId,
          folderId,
          contentId,
          node as unknown as INodeProps,
        ),
      )
    },
    [dispatch, isApiAvailableOrNotify],
  )

  return {
    create,
    update,
  } as const
}

export default useFolderApi
