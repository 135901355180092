import { QuerySnapshot } from 'firebase/firestore'
import { permissionActions } from '../../../actions'
import { AppDispatch } from '../../../app/store'
import { Permission } from '../../../app/models'
import { transformPermission } from '../transformers/PermissionTransformer'

export const PermissionSnapshot =
  (uid: string, bid: string, final: boolean, dispatch: AppDispatch) =>
  (snapshot: QuerySnapshot) => {
    const boardId = `${uid}-${bid}`
    const items = {} as { [id: string]: Permission }

    snapshot.docChanges().forEach(({ doc, type }) => {
      const data = transformPermission(doc)

      if (type === 'added' || type === 'modified') {
        items[data.recipient] = data
      }

      if (type === 'removed') {
        dispatch(permissionActions.deletePermission(boardId, data.recipient))
      }
    })

    if (Object.keys(items).length > 0) {
      dispatch(permissionActions.setPermission(boardId, items, final))
    }
  }
