export function randomEmojiExcluding(excluding: string[]) {
  const emojis = [
    '😀',
    '💫',
    '⭐️',
    '🌟',
    '✨',
    '⚡️',
    '💬',
    '💭',
    '🗯',
    '🔱',
    '🌱',
    '🍀',
    '🎱',
    '🎨',
    '🎯',
    '🎋',
    '🥋',
    '🏆',
    '🏰',
    '📢',
    '💣',
    '🏁',
    '🚩',
    '🌀',
    '💡',
    '⚖️',
    '🧭',
    '🏖',
    '🍥',
    '🚀',
  ]

  const unused = emojis.filter((emoji) => !excluding.includes(emoji))

  if (unused.length > 0) {
    return unused[Math.floor(Math.random() * unused.length)]
  }

  return emojis[Math.floor(Math.random() * emojis.length)]
}
