import { Folder } from '../app/models'
import { getFolder, getParentFolder } from '../selectors'
import { useSelectorFactory } from './useSelectorFactory'

interface IUseFolder {
  folder: Folder
  parentFolder: Folder
}

const useFolder = (
  boardId: string | undefined | null,
  folderId: string | undefined | null,
): IUseFolder => {
  const folder = useSelectorFactory(getFolder, boardId, folderId)
  const parentFolder = useSelectorFactory(getParentFolder, boardId, folderId)

  return {
    parentFolder,
    folder,
  }
}

export default useFolder
