import NoTitle from './NoTitle'
import ContentItem from './ContentItem'
import ContentItemCover, { CoverBackground } from './ContentItemCover'
import { ReactComponent as VideoIcon } from '../icons/Video.svg'
import { NodeType } from '../../../models'

interface Props {
  cover: string | undefined
  text: string
  count: number
}

const ContentVideo = ({ cover, text, count }: Props) => {
  const textElement = text || <NoTitle type={NodeType.VIDEO} />

  return (
    <ContentItem text={textElement} count={count}>
      <ContentItemCover
        background={CoverBackground.WHITE}
        cover={cover}
        alt={text}
        icon={<VideoIcon />}
      />
    </ContentItem>
  )
}

export default ContentVideo
