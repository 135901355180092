import React, { useEffect, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import useInsightsEvents from '@features/analytics/useInsightsEvents'

import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { ReactComponent as ExclamationMarkIcon } from '../icons/ExclamationMark.svg'

import styles from './DataCollectingMsgBanner.module.css'

const formatParams = {
  val: { year: 'numeric', month: 'long', day: 'numeric' },
}

type Props = PropsWithChildren<{
  dataAvailableFromDate: Date
}>
const DataCollectingMsgBanner = ({ dataAvailableFromDate }: Props) => {
  const { insightsMessageDisplayed } = useInsightsEvents()

  useEffect(() => {
    insightsMessageDisplayed()
    // should only be trigger once when the component is first rendered
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.Root}>
      <Tooltip
        style={{ display: 'flex' }}
        data={
          <TooltipContent>
            <Trans
              i18nKey="insights_data_available_date"
              values={{
                val: dataAvailableFromDate,
                formatParams,
              }}
            />
          </TooltipContent>
        }
      >
        <ExclamationMarkIcon style={{ cursor: 'pointer' }} />
      </Tooltip>

      <div className={styles.Message}>
        <Trans
          i18nKey="insights_data_available_date"
          values={{
            val: dataAvailableFromDate,
            formatParams,
          }}
        />
      </div>
    </div>
  )
}

export default DataCollectingMsgBanner
