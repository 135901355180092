import React, { FC, ReactNode } from 'react'

import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import styles from './DestinationPickerHeader.module.css'

interface IProps {
  picker: ContentPickersEnum
  headerCopy: ReactNode
}

export const DestinationPickerHeader: FC<IProps> = ({ picker, headerCopy }) => {
  const { title } = useContentNavigation(picker)

  return (
    <div className={styles.Header}>
      <span className={styles.Mode}>{headerCopy}</span>

      <span className={styles.Path}>{title}</span>
    </div>
  )
}
