import { useCallback } from 'react'

import { EventCategories } from '@features/analytics/models/logEvents'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useLogEvents } from '@features/analytics/useLogEvents'

const usePaywallEvents = () => {
  const { trigger } = useLogEvents()

  const paywallView = useCallback(
    (campaignManagerId?: string) => {
      trigger(EventCategories.APP_PAYWALL_VIEW, {
        campaign_manager_id: campaignManagerId,
      })
    },
    [trigger],
  )

  const paywallSource = (
    source: PaywallSourceEnum | string,
    templateCategory?: string,
    templateName?: string,
  ) => {
    const params: {
      source: string
      template_category_name?: string
      template_name?: string
    } = {
      source,
    }

    if (templateCategory) {
      params.template_category_name = templateCategory
    }

    if (templateName) {
      params.template_name = templateName
    }

    trigger(EventCategories.APP_PAYWALL_SOURCE, params)
  }

  const paywallTabClickEvent = (tab: string) =>
    trigger(EventCategories.APP_PAYWALL_TAB_CLICK, { tab })

  const paywallSubscriptionStartEvent = ({
    plan,
    subscriptionCycle,
    trial,
    productId,
    campaignManagerId,
  }: {
    plan: string
    subscriptionCycle: string
    trial: boolean
    productId?: string
    campaignManagerId?: string
  }) =>
    trigger(EventCategories.APP_SUBSCRIPTION_START, {
      plan,
      product_id: productId,
      campaign_manager_id: campaignManagerId,
      subscription_cycle: subscriptionCycle,
      trial,
    })

  const paywallSubscribeClickEvent = (
    plan: string,
    productId?: string,
    campaignManagerId?: string,
  ) =>
    trigger(EventCategories.APP_PAYWALL_SUBSCRIBE_CLICK, {
      plan,
      product_id: productId,
      campaign_manager_id: campaignManagerId,
    })

  const paywallCycleClickEvent = (subscriptionCycle: string) =>
    trigger(EventCategories.APP_PAYWALL_CYCLE_CLICK, {
      subscription_cycle: subscriptionCycle,
    })

  return {
    paywallView,
    paywallSource,
    paywallTabClickEvent,
    paywallSubscriptionStartEvent,
    paywallSubscribeClickEvent,
    paywallCycleClickEvent,
  }
}

export default usePaywallEvents
