import React, { FC, HTMLAttributes } from 'react'
import { Trans } from 'react-i18next'

import DoneButton from '../../../../ui/components/DoneButton'

import { useCheckout } from '../hooks'

type Props = Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'>
const FooterControls: FC<Props> = ({ ...rest }) => {
  const { action } = useCheckout()

  return action && action.onNext ? (
    <DoneButton disabled={!action.isValid} onClick={action?.onNext} {...rest}>
      <Trans i18nKey={action.actionName} />
    </DoneButton>
  ) : null
}

export default React.memo(FooterControls)
