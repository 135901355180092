import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './TextButton.module.css'

type Props = PropsWithChildren<{
  disabled?: boolean
  primary?: boolean
  onClick?: () => void
  className?: string
}>

const TextButton: FC<Props> = ({
  primary,
  disabled,
  onClick,
  className,
  children,
}) => {
  return (
    <button
      className={clsx(styles.Root, primary && styles.Primary, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default TextButton
