import { useSessionStorage } from 'usehooks-ts'
import { useLocation, useNavigate } from 'react-router-dom'

interface RouteProps {
  routes: string[]
  destinationUrl: string
}

const useOverwriteRouteRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [value, setValue] = useSessionStorage<RouteProps | null>(
    'redirect-after-sign-up',
    null,
  )

  const overwriteRouteRedirect = (routes: string[], destinationUrl: string) => {
    setValue({
      routes,
      destinationUrl,
    })
  }

  const redirectToOverwriteRoute = () => {
    if (!value) return

    const { destinationUrl } = value
    setValue(null)

    navigate(destinationUrl, { state: { previousRoute: location.pathname } })
  }

  const routeHasRedirect = (route: string) => {
    if (!value) return false

    return value.routes.includes(route)
  }

  return {
    routeHasRedirect,
    overwriteRouteRedirect,
    redirectToOverwriteRoute,
  } as const
}

export default useOverwriteRouteRedirect
