import { useDialog } from 'boards-web-ui'

import AddVideo from '../components/AddVideo'
import { IUseAddNode } from '../models/IUseAddNode'

const useAddVideo: IUseAddNode = (save, isPageEditor) => {
  const { open } = useDialog()

  const addVideo = () => {
    open(
      <AddVideo
        initialText={''}
        initialTitle={''}
        onSave={save}
        isPageEditor={isPageEditor}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return addVideo
}

export default useAddVideo
