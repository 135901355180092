enum TIME_LABELS {
  s = 'seconds',
  m = 'minutes',
  h = 'hours',
  d = 'days',
  w = 'weeks',
  mo = 'months',
  y = 'years',
}

type TimeDiffValue = {
  label: TIME_LABELS
  value: number
}

export function timeDifference(previous: number): TimeDiffValue {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerWeek = msPerDay * 7
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = new Date().getTime() - previous

  if (elapsed < msPerMinute) {
    return {
      label: TIME_LABELS.s,
      value: Math.round(elapsed / 1000),
    }
  }

  if (elapsed < msPerHour) {
    return {
      label: TIME_LABELS.m,
      value: Math.round(elapsed / msPerMinute),
    }
  }

  if (elapsed < msPerDay) {
    return {
      label: TIME_LABELS.h,
      value: Math.round(elapsed / msPerHour),
    }
  }

  if (elapsed < msPerWeek) {
    return {
      label: TIME_LABELS.d,
      value: Math.round(elapsed / msPerDay),
    }
  }

  if (elapsed < msPerMonth) {
    return {
      label: TIME_LABELS.w,
      value: Math.round(elapsed / msPerWeek),
    }
  }

  if (elapsed < msPerYear) {
    return {
      label: TIME_LABELS.mo,
      value: Math.round(elapsed / msPerMonth),
    }
  }

  return {
    label: TIME_LABELS.y,
    value: Math.round(elapsed / msPerYear),
  }
}
