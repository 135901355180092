import { useDispatch } from 'react-redux'
import { UploadTask } from 'firebase/storage'
import { useCallback, useRef, useState } from 'react'

import { uploadActions } from '../actions'
import { uploadVideo } from '../_firebase'
import { randomContentId } from '../helpers'
import { useAuth } from './useAuth'

interface UploadTaskSnapshot {
  bytesTransferred: number
  totalBytes: number
}

const calcSnapshotProgressPercent = (snapshot: UploadTaskSnapshot) =>
  Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

const preventExitWhileLoading = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = false
}

const addPreventExitDialog = () =>
  window.addEventListener('beforeunload', preventExitWhileLoading)
const removePreventExitDialog = () =>
  window.removeEventListener('beforeunload', preventExitWhileLoading)

const useUploadFirebase = () => {
  const { user } = useAuth()
  const uploadTaskRef = useRef<UploadTask>()
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const [progress, setProgress] = useState(0)

  const uploadFile = useCallback(
    (file: File, uploadSuccessfully: (keyId?: string) => void) => {
      if (!user?.uid) return

      setError(false)
      addPreventExitDialog()

      const assetId = randomContentId()
      const fileName = `${assetId}.mp4`
      const fileUrl = encodeURI(`${process.env.REACT_APP_PDF_URL}/${file.name}`)

      dispatch(uploadActions.addUpload(fileUrl))

      uploadTaskRef.current = uploadVideo(file, fileName, user?.uid)

      uploadTaskRef.current.on(
        'state_changed',
        (snapshot: UploadTaskSnapshot) => {
          setProgress(calcSnapshotProgressPercent(snapshot))
        },
        () => {
          setError(true)
          removePreventExitDialog()

          dispatch(uploadActions.removeUpload(fileUrl))
        },
        () => {
          uploadSuccessfully(assetId)
          removePreventExitDialog()

          dispatch(uploadActions.removeUpload(fileUrl))
        },
      )

      // eslint-disable-next-line
      return { title: file.name.slice(0, -4), url: fileUrl }
    },
    [user?.uid, dispatch],
  )

  const cancelUpload = useCallback(() => {
    if (uploadTaskRef.current) {
      uploadTaskRef.current.cancel()
      removePreventExitDialog()
    }
  }, [])

  return { error, progress, cancelUpload, uploadFile }
}

export default useUploadFirebase
