import PropTypes from 'prop-types'
import styles from './Avatar.module.css'

const Avatar = ({
  size,
  fontSize,
  color,
  variant,
  bgColor,
  disabled,
  children,
  onClick,
  className,
}) => {
  const customStyle = {}
  const classes = [styles.Root, className]

  if (size) {
    customStyle.width = `${size}px`
    customStyle.height = `${size}px`
  }
  if (color) {
    customStyle.color = color
  }
  if (variant === 'text') {
    customStyle.border = `solid 1px ${bgColor}`
  } else {
    customStyle.background = bgColor
  }
  if (fontSize) {
    customStyle.fontSize = fontSize
  }

  if (disabled) {
    classes.push(styles.Disabled)
  }
  if (onClick) {
    classes.push(styles.CursorPointer)
  }

  return (
    <div className={classes.join(' ')} style={customStyle} onClick={onClick}>
      {children}
    </div>
  )
}

Avatar.defaultProps = {
  size: 25,
  color: 'darkgray',
  bgColor: 'gray',
  disabled: false,
}
Avatar.propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(['contained', 'text']),
  fontSize: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default Avatar
