import { useContext } from 'react'
import Charts from './Charts'
import Option from './Select/Options'
import Select from './Select/Select'
import Banners from './Banners'
import TopContent from './TopContent'
import { InsightsFilter } from '../models/filter'
import useInsights from '../hooks/useInsights'
import useInsightsFilter from '../hooks/useInsightsFilter'
import useInsightsBanners from '../hooks/useInsightsBanners'
import ContainerReferenceContext from '../providers/ContainerReferenceContext'

import styles from './InsightsContent.module.css'

const InsightsContent = () => {
  const { filter, options, updateFilter } = useInsightsFilter()
  const { insights, isLoading, isBoardEmpty } = useInsights(filter)
  const { dataAvailableFromDate, isCollectingMsgBannerEnabled } =
    useInsightsBanners(filter, insights.boardCreateTime)

  const { contentRef } = useContext(ContainerReferenceContext)

  return (
    <div className={styles.Root}>
      <div className={styles.Filter}>
        <div style={{ minWidth: '200px' }}>
          <Select
            placeholder={options[filter]}
            defaultValue={filter}
            onSelect={updateFilter}
          >
            {Object.keys(options).map((optionIndex) => {
              return (
                <Option key={optionIndex} value={optionIndex}>
                  {options[optionIndex as InsightsFilter]}
                </Option>
              )
            })}
          </Select>
        </div>
        <Banners
          dataAvailableFromDate={dataAvailableFromDate}
          isCollectingMsgBannerEnabled={isCollectingMsgBannerEnabled}
        />
      </div>
      <div ref={contentRef} className={styles.Responsive}>
        <div className={styles.Grid}>
          <div className={styles.LeftColumn}>
            <Charts
              loading={isLoading}
              activeMembers={insights.activeMembers}
              dailyActiveMembers={insights.dailyActiveMembers}
              contentInteractions={insights.contentInteractions}
              dailyContentInteractions={insights.dailyContentInteractions}
            />
          </div>
          <div className={styles.RightColumn}>
            <TopContent
              loading={isLoading}
              items={insights.topContentItems}
              isBoardEmpty={isBoardEmpty}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsightsContent
