import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import { InsightsFilter } from '../models/filter'

const useInsightsBanners = (filter: InsightsFilter, boardCreateTime: Date) => {
  const { t } = useTranslation()
  const [
    isBoardOlderThanDataCollectionStart,
    setIsBoardOlderThanDataCollectionStart,
  ] = useState(false)
  const { insightsAvailableDateFeatureFlag } = useFeatureFlags()

  useEffect(() => {
    const dataAvailableDate = new Date(insightsAvailableDateFeatureFlag)
    setIsBoardOlderThanDataCollectionStart(boardCreateTime < dataAvailableDate)
  }, [insightsAvailableDateFeatureFlag, boardCreateTime])

  return {
    dataAvailableFromDate: new Date(insightsAvailableDateFeatureFlag),
    bannerPremiumUrl: t('banner_premium_link', {
      origin: 'premium_banner_analytics',
    }),
    isCollectingMsgBannerEnabled:
      filter === InsightsFilter.ALL_TIME && isBoardOlderThanDataCollectionStart,
  }
}

export default useInsightsBanners
