import {
  getAnalytics,
  logEvent as logEventFirebase,
  setUserProperties,
} from 'firebase/analytics'
import mixpanel from 'mixpanel-browser'
import { setUser as setSentryUser } from '@sentry/react'
import { User } from 'firebase/auth'

import { ENV_KEYS } from '../utils/envKeys'
import { configurationObjectFromEnv } from '../utils/configurationObjectFromEnv'
import auth from '../_firebase/auth'
import { isMobile } from '../utils/deviceParser'
import customerIO, {
  CustomerIoIdentify,
  IUserProperties,
} from './CustomerIoAPI'

const { mixpanelToken } = configurationObjectFromEnv<{ mixpanelToken: string }>(
  {
    mixpanelToken: ENV_KEYS.MIXPANEL_TOKEN,
  },
)

const analytics = getAnalytics()
mixpanel.init(mixpanelToken)

interface IGetSuperParameters {
  email?: string | null
  user_uid?: string
  account_id?: string
  os_language: string
  signed_in: boolean
  operating_system: string
  platform: string
}

const getSuperParameters = (): IGetSuperParameters => {
  const baseParameters = {
    os_language: navigator?.language,
    operating_system: 'web',
    platform: isMobile ? 'mobile-web' : 'web',
  }

  if (!auth?.currentUser) {
    return {
      ...baseParameters,
      signed_in: false,
    }
  }

  const { email, uid } = auth.currentUser

  return {
    ...baseParameters,
    email,
    user_uid: uid,
    account_id: email || uid,
    signed_in: true,
  }
}

export const logEvent = (
  event: string,
  eventParameters: { [key: string]: string },
) => {
  const superParameters = getSuperParameters()
  const allEventParameters = {
    ...superParameters,
    ...eventParameters,
  }
  logEventFirebase(analytics, event, allEventParameters)
  mixpanel.track(event, allEventParameters)
  customerIO.track(event, allEventParameters)
}

export const identifyUser = ({
  user,
  maxBoardsMembers,
  sponsoredBoards,
  name,
  isGoogleAccountConnected,
  countryCode,
  plan,
}: {
  user: User
  maxBoardsMembers: number
  sponsoredBoards: number
  name: string
  isGoogleAccountConnected: boolean
  countryCode: string
  plan: string
}): Promise<void> => {
  const userProperties = {
    user_id: user.uid,
    email: user.email || '',
    first_name: name.split(' ')[0],
    account_id: user.email ? user.email : user.uid,
    max_boards_members: maxBoardsMembers,
    sponsored_boards: sponsoredBoards,
    os_language: navigator.language,
    operating_system: 'web',
    platform: isMobile ? 'mobile-web' : 'web',
    plan,
    google_sheets_integrated: isGoogleAccountConnected.toString(),
    country_code: countryCode,
  }

  return new Promise((resolve) => {
    setUserProperties(analytics, userProperties)
    setSentryUser({
      id: user.uid,
      email: user.email || undefined,
    })
    customerIO.identifyUser<CustomerIoIdentify>({
      ...userProperties,
      id: user.uid,
      user_id: user.uid,
    })
    mixpanel.identify(user.uid)
    mixpanel.people.set(
      {
        ...userProperties,
        $email: userProperties.email,
      },
      () => {
        resolve(undefined)
      },
    )
  })
}

export const updateUserProperties = (user: User, props: IUserProperties) => {
  const customerIOProps = {
    ...props,
    id: user.uid,
    user_id: user.uid,
  }

  return new Promise((resolve) => {
    customerIO.identifyUser<typeof customerIOProps>(customerIOProps)

    mixpanel.people.set(props, () => {
      resolve(undefined)
    })
  })
}
