import React, { FC, PropsWithChildren } from 'react'
import { AddSmallIcon, ButtonNew } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'

type CheckoutAddButtonProps = PropsWithChildren<{
  onClick: () => void
}>

const AddButton: FC<CheckoutAddButtonProps> = ({
  children,
  onClick,
  ...rest
}) => (
  <ButtonNew
    fullWidth
    color="secondary"
    size="large"
    startIcon={
      <IconWrapper size={16}>
        <AddSmallIcon />
      </IconWrapper>
    }
    onClick={onClick}
    {...rest}
  >
    {children}
  </ButtonNew>
)

export default React.memo(AddButton)
