import { memoize } from 'lodash-es'

export const getScriptBySrc = (src: string) =>
  document.querySelectorAll(`script[src="${src}"]`)[0] as HTMLScriptElement

export const loadScript = memoize((script: HTMLScriptElement) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    script.onload = resolve
    // eslint-disable-next-line
    script.onerror = reject
  })
})

export const createScript = (src: string) => {
  const script = document.createElement('script')
  script.src = src
  script.async = true

  return document.body.appendChild(script)
}
