import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { useCallback, useMemo } from 'react'
import useBoard from '@hooks/useBoard'
import {
  ContentPickersEnum,
  IOpenDestinationPickerParams,
  IUseDestinationPickerParams,
  useDestinationPicker,
} from '@features/pickers'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'

type TSource =
  | PaywallSourceEnum.COPY_SELECTION
  | PaywallSourceEnum.COPY_CONTENT
  | PaywallSourceEnum.COPY_SHARED_FOLDER
  | PaywallSourceEnum.COPY_CONTENT_SHARED_FOLDER

interface IUseFreePlanCopyReceivedContent {
  canCopy: boolean
  onCopyClick: (params: {
    source: TSource
    pickerProps: Pick<
      IOpenDestinationPickerParams,
      | 'contentType'
      | 'headerCopy'
      | 'performActionCopy'
      | 'performActionSuccessMessage'
      | 'eventsOverrides'
    >
    onBeforeOpenPicker?: () => void
    isPreviewMode?: boolean
  }) => void
}

type TUseFreePlanCopyReceivedContent = (params?: {
  isSharedFolderMode: boolean
  destinationPickerParams?: IUseDestinationPickerParams
}) => IUseFreePlanCopyReceivedContent

export const useCopyReceivedContent: TUseFreePlanCopyReceivedContent = (
  params,
) => {
  const { isReadOnly } = useBoard()
  const { openDestinationPicker } = useDestinationPicker(
    params?.destinationPickerParams,
  )
  const { openPaywall } = usePaywall()

  const { profile } = useProfileInformation()

  const openMonetizationDialog = useCallback(
    (source: TSource) => {
      openPaywall({ source })
    },
    [openPaywall],
  )

  const canCopy = useMemo(() => {
    const copyReceivedContentEnabled = Boolean(
      profile.plan?.features?.copyReceivedContent,
    )

    if (params?.isSharedFolderMode) {
      return copyReceivedContentEnabled
    }

    if (!isReadOnly) {
      return true
    }

    return copyReceivedContentEnabled
  }, [
    isReadOnly,
    params?.isSharedFolderMode,
    profile.plan?.features?.copyReceivedContent,
  ])

  const onCopyClick: IUseFreePlanCopyReceivedContent['onCopyClick'] =
    useCallback(
      ({ source, pickerProps, onBeforeOpenPicker }) => {
        if (!canCopy) {
          openMonetizationDialog(source)
          return
        }

        if (onBeforeOpenPicker) {
          onBeforeOpenPicker()
        }

        openDestinationPicker({
          picker: ContentPickersEnum.CopyPicker,
          ...pickerProps,
        })
      },
      [canCopy, openDestinationPicker, openMonetizationDialog],
    )

  return {
    onCopyClick,
    canCopy,
  }
}
