import { createContext } from 'react'
import { ScrollAreasContextProps } from '../../models/ScrollArea'

const initialValue: ScrollAreasContextProps = {
  addArea: () => {
    throw new Error(
      `Please make sure ScrollArea component is inside ScrollAreasProvider context`,
    )
  },
  removeArea: () => {
    throw new Error(
      `Please make sure ScrollArea component is inside ScrollAreasProvider context`,
    )
  },
  getArea: () => {
    throw new Error(
      `Please make sure ScrollArea component is inside ScrollAreasProvider context`,
    )
  },
}

const ScrollAreasContext = createContext<ScrollAreasContextProps>(initialValue)

export default ScrollAreasContext
