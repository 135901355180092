import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useAccountSubscriptionEvents, {
  PaywallSourceEnum,
} from '@features/analytics/useAccountSubscriptionEvents'
import useFetchAvailableSubscriptions from '@hooks/useFetchAvailableSubscriptions'
import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import usePaddleAPI from '@features/payments/hooks/usePaddleAPI'
import AccountPageTitle from '@features/account/components/AccountPageTitle'
import PaddlePlan from '@features/account/components/PaddlePlan'
import MobilePlan from '@features/account/components/MobilePlan'
import FreePlan from '@features/account/components/FreePlan'
import LoadingPlan from '@features/account/components/LoadingPlan'
import usePaywall from '@hooks/usePaywall'

import { subscriptionsActions } from 'actions'

import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import styles from './AccountSubscriptions.module.css'

export const AccountSubscriptions = () => {
  const dispatch = useDispatch()
  const { openPaywall } = usePaywall()
  const { current, status } = useSubscriptionPlans()
  const { isUserInTrialMode } = useReverseTrialContext()

  const { changePlanClickEvent, changeMethodClickEvent, renewPlanEvent } =
    useAccountSubscriptionEvents()
  const { updatePayment, cancelSubscription, reinstateSubscription } =
    usePaddleAPI()

  const fetchAvailableSubscriptions = useFetchAvailableSubscriptions()

  useEffect(() => {
    fetchAvailableSubscriptions()
  }, [fetchAvailableSubscriptions])

  const handleOpenPaywall = () =>
    openPaywall({ source: PaywallSourceEnum.FREE_PLAN_UPDATE })

  const handleChangePlan = () => {
    changePlanClickEvent()
    openPaywall({ source: PaywallSourceEnum.SUBSCRIPTIONS_CHANGE_PLAN })
  }

  const handleRenewPlan = async () => {
    renewPlanEvent()
    await reinstateSubscription()
    dispatch(subscriptionsActions.renewCurrentSubscription())
  }

  const handleCancelSubscription = async () => {
    const data = await cancelSubscription()
    if (data?.effectiveAt) {
      dispatch(subscriptionsActions.cancelCurrentSubscription(data.effectiveAt))
    }
  }

  const handleUpdatePayment = async () => {
    changeMethodClickEvent()
    await updatePayment()
  }

  const renderPlan = () => {
    if (status !== 'loaded') return <LoadingPlan />

    if (!current || isUserInTrialMode) {
      return (
        <FreePlan
          onChangePlanClick={handleOpenPaywall}
          customPlanTitle={isUserInTrialMode ? 'Business - trial' : undefined}
        />
      )
    }

    if (current && current.source !== 'Paddle') {
      return <MobilePlan plan={current} />
    }

    return (
      <div className={styles.InfoContainer}>
        <PaddlePlan
          plan={current}
          onCancelPlanClick={handleCancelSubscription}
          onChangePlanClick={handleChangePlan}
          onRenewPlanClick={handleRenewPlan}
          onChangePaymentClick={handleUpdatePayment}
        />
      </div>
    )
  }

  return (
    <>
      <AccountPageTitle>
        <Trans i18nKey="subscription_title" />
      </AccountPageTitle>

      <div className={styles.InfoContainer}>{renderPlan()}</div>
    </>
  )
}
