import { useTranslation } from 'react-i18next'
import useInsightsEvents, {
  RelateTooltip,
} from '@features/analytics/useInsightsEvents'

import Grid from '../../../../elements/Grid'
import TopWidget from './TopWidget'
import Chart from './Chart'
import { ReactComponent as ActiveMembersIcon } from '../icons/ActiveMembers.svg'
import { ReactComponent as ContentEngagementIcon } from '../icons/ContentEngagement.svg'
import { LineChartState } from '../models/insights'

import styles from './Charts.module.css'

interface Props {
  loading: boolean
  activeMembers: number | undefined
  contentInteractions: number | undefined
  dailyActiveMembers: LineChartState[] | undefined
  dailyContentInteractions: LineChartState[] | undefined
}

const Charts = ({
  loading,
  activeMembers,
  contentInteractions,
  dailyActiveMembers,
  dailyContentInteractions,
}: Props) => {
  const { t } = useTranslation()
  const { tooltipHovered } = useInsightsEvents()
  return (
    <Grid
      flexDirection="column"
      rowGap={20}
      justifyContent="space-between"
      alignItems="stretch"
      height="100%"
    >
      <div className={styles.TopContentWidgetsItem}>
        <Grid
          columnGap={20}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          width={'100%'}
        >
          <TopWidget
            icon={<ActiveMembersIcon />}
            label={t('insights_tab_widget_active_members')}
            tooltipLabel={t('insights_hover_tooltip_active_members')}
            value={activeMembers}
            loading={loading}
            onHoverTooltip={() => tooltipHovered(RelateTooltip.ACTIVE_MEMBERS)}
          />
          <TopWidget
            icon={<ContentEngagementIcon />}
            label={t('insights_tab_widget_content_interaction')}
            tooltipLabel={t('insights_hover_tooltip_content_interactions')}
            value={contentInteractions}
            loading={loading}
            onHoverTooltip={() =>
              tooltipHovered(RelateTooltip.CONTENT_INTERACTIONS)
            }
          />
        </Grid>
      </div>
      <div className={styles.ChartItem}>
        <Chart
          label={t('insights_hover_linegraph_active_members')}
          tooltipLabel={t('insights_hover_tooltip_daily_active_members')}
          chartTooltipLabel={t('insights_chart_tooltip_active_members')}
          data={dailyActiveMembers}
          loading={loading}
          onHoverTooltip={() =>
            tooltipHovered(RelateTooltip.DAILY_ACTIVE_MEMBERS)
          }
        />
      </div>
      <div className={styles.ChartItem}>
        <Chart
          label={t('insights_hover_linegraph_content_interaction')}
          tooltipLabel={t('insights_hover_tooltip_daily_content')}
          chartTooltipLabel={t('insights_chart_tooltip_content_interactions')}
          data={dailyContentInteractions}
          loading={loading}
          onHoverTooltip={() => tooltipHovered(RelateTooltip.DAILY_CONTENT)}
        />
      </div>
    </Grid>
  )
}

export default Charts
