import tryToConvertToImageUrlWithSize, {
  DynamicImageSize,
} from 'utils/tryToConvertToImageUrlWithSize'
import ContentItem from './ContentItem'
import ContentItemCover, { CoverBackground } from './ContentItemCover'
import { ReactComponent as GrayPdfIcon } from '../icons/PdfGray.svg'
import NoTitle from './NoTitle'
import { NodeType } from '../../../models'

interface Props {
  thumbnail: string | undefined
  text: string
  count: number
}

const ContentPdf = ({ thumbnail, text, count }: Props) => {
  const textElement = text || <NoTitle type={NodeType.PDF} />

  const cover = thumbnail
    ? tryToConvertToImageUrlWithSize(thumbnail, DynamicImageSize.Based64)
    : thumbnail

  return (
    <ContentItem text={textElement} count={count}>
      <ContentItemCover
        icon={<GrayPdfIcon />}
        background={CoverBackground.WHITE}
        cover={cover}
        alt={text}
      />
    </ContentItem>
  )
}

export default ContentPdf
