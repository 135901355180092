import { VideoType, urlHelper } from 'boards-web-ui'

import { FUNCTIONS, apiFunction } from '../../../../hooks/useAPI'
import { IVideo, IYoutubeVideo } from '../../../../hooks/useVideo'

const useFetchVideoTitle = () => {
  const getVideoFunction = (type: string | undefined) => {
    return type === VideoType.VIMEO
      ? FUNCTIONS.GET_VIDEO_VIMEO
      : FUNCTIONS.GET_VIDEO_YOUTUBE
  }

  const fetchTitle = async (url: string) => {
    const videoDetails = urlHelper.getVideoDetails(url)

    const fnc = getVideoFunction(videoDetails?.type)
    const functionToCall = apiFunction[fnc]

    if (videoDetails?.id) {
      const res = await functionToCall(videoDetails?.id)
      const data = res as IYoutubeVideo | IVideo | boolean

      if (typeof data !== 'boolean') {
        return data?.title
      }
    }

    return ''
  }

  return {
    fetchTitle,
  }
}

export default useFetchVideoTitle
