import React from 'react'

import { ReactComponent as ArrowLeft } from '../icons/empty_board_arrow_left.svg'
import { ReactComponent as ArrowRight } from '../icons/empty_board_arrow_right.svg'

import styles from './AnimatedArrows.module.css'

export enum AnimatedArrowDirection {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

type DirectionProps = {
  className?: string
}
const LeftDirection: React.FC<DirectionProps> = ({ className, ...rest }) => (
  <div
    className={`${styles.Root} ${styles.AnimationEffect} ${styles.DirectionLeft} ${className}`}
    {...rest}
  >
    <ArrowLeft />
    <ArrowLeft />
    <ArrowLeft />
    <ArrowLeft />
    <ArrowLeft />
  </div>
)
const RightDirection: React.FC<DirectionProps> = ({ className, ...rest }) => (
  <div
    className={`${styles.Root} ${styles.AnimationEffect} ${styles.DirectionRight} ${className}`}
    {...rest}
  >
    <ArrowRight />
    <ArrowRight />
    <ArrowRight />
    <ArrowRight />
    <ArrowRight />
  </div>
)
const TopDirection: React.FC<DirectionProps> = ({ className, ...rest }) => (
  <div
    className={`${styles.Root} ${styles.AnimationEffect} ${styles.DirectionTop} ${className}`}
    {...rest}
  >
    <ArrowRight />
    <ArrowRight />
    <ArrowRight />
    <ArrowRight />
    <ArrowRight />
  </div>
)
const BottomDirection: React.FC<DirectionProps> = ({ className, ...rest }) => (
  <div
    className={`${styles.Root} ${styles.AnimationEffect} ${styles.DirectionBottom} ${className}`}
    {...rest}
  >
    <ArrowLeft />
    <ArrowLeft />
    <ArrowLeft />
    <ArrowLeft />
    <ArrowLeft />
  </div>
)
type Props = {
  direction?: AnimatedArrowDirection
  className?: string
}
export const AnimatedArrows: React.FC<React.PropsWithChildren<Props>> = ({
  direction = AnimatedArrowDirection.left,
  ...rest
}) => (
  <>
    {direction === AnimatedArrowDirection.left && <LeftDirection {...rest} />}
    {direction === AnimatedArrowDirection.right && <RightDirection {...rest} />}
    {direction === AnimatedArrowDirection.top && <TopDirection {...rest} />}
    {direction === AnimatedArrowDirection.bottom && (
      <BottomDirection {...rest} />
    )}
  </>
)
