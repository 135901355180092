import { useCallback, useEffect, useState } from 'react'
import { getVimeoVideoInfo } from '../api/VimeoAPI'
import { getInsights, getYouTubeVideoInfo } from '../_firebase'

export enum FUNCTIONS {
  GET_INSIGHTS = 'insights',
  GET_VIDEO_YOUTUBE = 'youtube',
  GET_VIDEO_VIMEO = 'vimeo',
}

export const apiFunction = {
  [FUNCTIONS.GET_INSIGHTS]: getInsights,
  [FUNCTIONS.GET_VIDEO_YOUTUBE]: getYouTubeVideoInfo,
  [FUNCTIONS.GET_VIDEO_VIMEO]: getVimeoVideoInfo,
}

interface State<T> {
  data?: T
  hasError: boolean
  isLoading: boolean
  fetchData: () => void
}

const useAPI = <T>(fnc: FUNCTIONS, params: string, sync = true): State<T> => {
  const [data, setData] = useState<T>()
  const [isLoading, setIsLoading] = useState(false)
  const [subscribed, setSubscribed] = useState(true)
  const [hasError, setHasError] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setHasError(false)
      setIsLoading(true)
      const functionToCall = apiFunction[fnc]
      const res = await functionToCall(params)

      if (subscribed) {
        setData(res as T)
      }
    } catch (error) {
      if (subscribed) {
        setHasError(true)
      }
    } finally {
      if (subscribed) {
        setIsLoading(false)
      }
    }
  }, [params, fnc, subscribed])

  useEffect(() => {
    if (sync) {
      fetchData()
    }
  }, [sync, fetchData])

  useEffect(() => {
    setData(undefined)
    setHasError(false)
  }, [fnc])

  useEffect(() => {
    return () => setSubscribed(false)
  }, [])

  return { isLoading, hasError, data, fetchData }
}

export default useAPI
