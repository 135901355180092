export interface VideoMetaData {
  duration: number | string
  width: number
  height: number
}

const useUploadFileMetadata = () => {
  const getVideoMetadata = async (f: File): Promise<VideoMetaData> => {
    const fileCallbackToPromise = (fileObj: HTMLVideoElement) => {
      return Promise.race([
        new Promise((resolve) => {
          // eslint-disable-next-line
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve
          // eslint-disable-next-line
          else fileObj.onloadedmetadata = resolve
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000)
        }),
      ])
    }

    const objectUrl = URL.createObjectURL(f)
    const video = document.createElement('video')
    video.src = objectUrl
    await fileCallbackToPromise(video)

    return {
      duration: video.duration,
      width: video.videoWidth,
      height: video.videoHeight,
    }
  }

  const getFileSize = (file: File) => Math.round(file.size / 1024)

  return {
    getFileSize,
    getVideoMetadata,
  }
}
export default useUploadFileMetadata
