export enum ENV_KEYS {
  NODE_ENV = 'NODE_ENV',
  API_KEY = 'REACT_APP_API_KEY',
  AUTH_DOMAIN = 'REACT_APP_AUTH_DOMAIN',
  DATABASE_URL = 'REACT_APP_DATABASE_URL',
  PROJECT_ID = 'REACT_APP_PROJECT_ID',
  MESSAGING_SENDER_ID = 'REACT_APP_MESSAGING_SENDER_ID',
  APP_ID = 'REACT_APP_APP_ID',
  MEASUREMENT_ID = 'REACT_APP_MEASUREMENT_ID',
  STORAGE_BUCKET = 'REACT_APP_STORAGE_BUCKET',
  IMAGE_BUCKET = 'REACT_APP_IMAGE_BUCKET',
  VIDEO_BUCKET = 'REACT_APP_VIDEO_BUCKET',
  VIDEO_URL = 'REACT_APP_VIDEO_URL',
  PDF_BUCKET = 'REACT_APP_PDF_BUCKET',
  PDF_URL = 'REACT_APP_PDF_URL',
  BOARDS_URL = 'REACT_APP_BOARDS_URL',
  FIREBASE_CONTENT_FUNCTIONS_URL = 'REACT_APP_FIREBASE_CONTENT_FUNCTIONS_URL',
  FIREBASE_GENERAL_FUNCTIONS_URL = 'REACT_APP_FIREBASE_GENERAL_FUNCTIONS_URL',
  GTM_ID = 'REACT_APP_GTM_ID',
  GOOGLE_AUTH_URL = 'REACT_APP_GOOGLE_AUTH_URL',

  HELP_URL = 'REACT_APP_HELP_URL',
  SUPPORT_URL = 'REACT_APP_SUPPORT_URL',
  SHARED_FOLDERS_DOMAIN = 'REACT_APP_SHARED_FOLDERS_DOMAIN',
  MIXPANEL_TOKEN = 'REACT_APP_MIXPANEL_TOKEN',
  CUSTOMER_IO_SITE_ID = 'REACT_APP_CUSTOMER_IO_SITE_ID',
  CHROME_EXTENSION_ID = 'REACT_APP_CHROME_EXTENSION_ID',
  CHROME_EXTENSION_URL = 'REACT_APP_CHROME_EXTENSION_URL',
  PLANS_URL = 'REACT_APP_PLANS_URL',
  SENTRY_DSN = 'REACT_APP_SENTRY_DSN',
  PADDLE_TOKEN = 'REACT_APP_PADDLE_TOKEN',
  APP_BOARDS_LINK = 'REACT_APP_BOARDS_LINK',
  APP_BOARDS_APP_URL = 'REACT_APP_BOARDS_APP_URL',
}
