import { memo, MouseEvent, useRef } from 'react'
import { ShareOption } from '@features/share/models/ShareOption'
import clsx from 'clsx'
import Avatar from '../../../../elements/Avatar'
import { randomSeededColor } from '../../../../helpers'
import Select from '../../../../elements/Select'
import Grid from '../../../../elements/Grid'
import useShareOptions from '../hooks/useShareOptions'

import styles from './Member.module.css'
import { Role } from '../../../models'

interface MemberDisabledProps {
  email: string
  firstChar: string
  permission: string
  bgColor?: string
  color?: string
  shareOptions: ShareOption[]
  avatarClassName?: string
}
const MemberDisabled = ({
  email,
  firstChar,
  permission,
  shareOptions,
  bgColor = '#F2F4FF',
  color,
  avatarClassName,
}: MemberDisabledProps) => (
  <div className={clsx(styles.Item, styles.Disabled)}>
    <Grid justifyContent="flex-start" columnGap={10}>
      <Avatar
        size={32}
        bgColor={bgColor}
        color={color}
        disabled
        className={avatarClassName}
      >
        {firstChar}
      </Avatar>

      <div className={styles.Email}>{email}</div>
    </Grid>

    <Select options={shareOptions} value={permission} disabled />
  </div>
)

interface Props {
  role?: Role
  email: string
  permission: string
  selected?: boolean
  emptyState?: boolean
  disabled?: boolean
  className?: string
  onClick?: (email: string) => void
  onChangePermission?: (email: string, permission: string) => void
}
export const Member = memo(
  ({
    role,
    email,
    permission,
    selected = false,
    disabled = false,
    emptyState,
    className,
    onClick,
    onChangePermission,
  }: Props) => {
    const firstChar = email.charAt(0)
    const color = randomSeededColor(email)
    const rootElRef = useRef(null)

    const lefElRef = useRef<HTMLDivElement>(null)
    const { shareOptions, shareOptionsByRole } = useShareOptions()

    const handleOnSelect = (newPermission: string) => {
      if (onChangePermission) onChangePermission(email, newPermission)
    }

    const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
      const { target } = event
      /*
      Ensures the Select onClick event bubbles up
      and is not taken into account on member selection
      */
      if (
        rootElRef.current === target ||
        lefElRef?.current?.contains(target as Node)
      ) {
        if (onClick) onClick(email)
      }
    }

    if (emptyState) {
      return (
        <MemberDisabled
          email={email}
          firstChar={' '}
          permission={permission}
          shareOptions={shareOptions}
        />
      )
    }

    if (disabled) {
      return (
        <MemberDisabled
          email={email}
          bgColor={color}
          firstChar={firstChar}
          permission={permission}
          shareOptions={shareOptions}
          color={'#FFFFFF'}
          avatarClassName={styles.Opacity}
        />
      )
    }

    return (
      <div
        ref={rootElRef}
        className={clsx([styles.Item, className, selected && styles.Selected])}
        onClick={handleOnClick}
      >
        <div ref={lefElRef} className={styles.ItemLeft}>
          <div className={styles.Grid}>
            <Avatar size={32} bgColor={color}>
              {firstChar}
            </Avatar>

            <div className={styles.Email}>{email}</div>
          </div>
        </div>

        <Select
          options={shareOptionsByRole(role)}
          value={permission}
          onSelect={handleOnSelect}
        />
      </div>
    )
  },
)

export default Member
