import { useDialog } from 'boards-web-ui'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useNodeEvents from '@features/analytics/useNodeEvents'

import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import {
  IDiscardChanges,
  IUseNodeDiscardChanges,
} from '../models/IUseNodeDiscardChanges'
import { editLinkActions } from '../../../../actions'

const useNodeDiscardChanges: IUseNodeDiscardChanges = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { close: closeDialog } = useDialog()
  const { discardChangesEvent } = useNodeEvents()
  const confirmDialog = useConfirmDialog()

  const discardChanges: IDiscardChanges = (
    onConfirmDiscardChanges,
    settings,
  ) => {
    confirmDialog({
      title: settings?.title || t('discard_changes_title'),
      message: settings?.message || t('discard_changes_body'),
      cancelText: t('action_cancel'),
      confirmText: t('action_discard'),
      onConfirm: () => {
        discardChangesEvent()
        closeDialog()
        dispatch(editLinkActions.resetEditLinkState())
        if (typeof onConfirmDiscardChanges === 'function') {
          onConfirmDiscardChanges()
        }
      },
    })
  }

  return discardChanges
}

export default useNodeDiscardChanges
