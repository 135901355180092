import React, { useCallback, useRef } from 'react'
import clsx from 'clsx'
import useCreateBoard from '@features/boardsMenu/hooks/useCreateBoard'

import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { useRunActionFromSearchParam } from '@features/boardsMenu/hooks/useOperationFromSearchParam'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import useSideBarBody from '../hooks/useSideBarBody'

import BoardsMenu from '../../boardsMenu/Menu'
import BoardsMenuTitle from '../../boardsMenu/BoardsMenuTitle'

import styles from './SideBarBody.module.css'

import { QUERY_PARAM_VALUES, QUERY_PARAMS } from '../../../constants/routes'

type Props = {
  className?: string
  titleClassName?: string
}
const SideBarBody: React.FC<Props> = ({ className, titleClassName }) => {
  const createBoard = useCreateBoard()
  const scrollableRef = useRef<HTMLDivElement>(null)
  const { loading, scrollToBottom } = useSideBarBody(scrollableRef)
  const { openPaywall } = usePaywall()

  const scrollToNewBoard = useCallback(
    () => setTimeout(scrollToBottom, 100),
    [scrollToBottom],
  )
  const { isFreePlanUser } = useReverseTrialContext()

  useRunActionFromSearchParam({
    action: () => {
      if (isFreePlanUser) {
        openPaywall({ source: PaywallSourceEnum.DEEPLINK_OPEN })
        return
      }

      createBoard(scrollToNewBoard)
    },
    queryOperation: QUERY_PARAMS.operation,
    paramValueToCompare: QUERY_PARAM_VALUES.operation.createBoard,
  })

  return (
    <div className={clsx(styles.Root, className)}>
      <BoardsMenuTitle
        className={titleClassName}
        loading={loading}
        onCreate={scrollToNewBoard}
        isFreePlanUser={isFreePlanUser}
      />

      {!loading ? (
        <div className={styles.Scrollable} ref={scrollableRef}>
          <BoardsMenu isFreePlanUser={isFreePlanUser} />
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(SideBarBody)
