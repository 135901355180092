import { Board, Folder } from '../../app/models'

function sanitizeFolder(folders: { [folderId: string]: Folder }) {
  const tempFolders: Array<string> = []
  Object.keys(folders).forEach((folderId) => {
    if (Object.prototype.hasOwnProperty.call(folders[folderId], 'key')) {
      tempFolders.push(folderId)
      // eslint-disable-next-line
      delete folders[folderId]
    }
  })

  Object.keys(folders).forEach((folderId) => {
    folders[folderId].content.forEach((node, index) => {
      if (tempFolders.includes(node.id)) {
        folders[folderId].content.splice(index, 1)
      }
    })
  })
}

function sanitizeBoards({
  boards,
  folders,
}: {
  boards: { [boardId: string]: Board }
  folders: { [boardId: string]: Folder }
}) {
  Object.keys(boards).forEach((boardId) => {
    if (Object.prototype.hasOwnProperty.call(boards[boardId], 'key')) {
      // eslint-disable-next-line
      delete boards[boardId]
      return
    }

    // TODO - fix types issue
    sanitizeFolder(
      folders[boardId] as unknown as { [folderId: string]: Folder },
    )
  })
}

export default sanitizeBoards
