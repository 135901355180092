import { getFolders } from '../selectors'
import { useSelectorFactory } from './useSelectorFactory'
import useRouterParams from './useRouterParams'

const useFolders = (boardIdParam?: string) => {
  const { boardId } = useRouterParams()

  const folders = useSelectorFactory(getFolders, boardIdParam || boardId)

  return {
    folders,
  }
}

export default useFolders
