import { useEffect, useState } from 'react'

export const useOnMount = (handler: () => void) => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init) {
      handler()
    }

    setInit(true)
  }, [handler, init])
}
