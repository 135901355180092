export enum ContentPickersEnum {
  SourcePicker = 'source',
  CopyPicker = 'copy',
  MovePicker = 'move',
}

export enum EntityDialogEnum {
  Board = 'board',
  Folder = 'folder',
}
