import { useEffect, useRef } from 'react'
import { useDialog } from 'boards-web-ui'
import { useSelector } from 'react-redux'

import EditLink from '../components/EditLink'
import { IUseAddNode } from '../models/IUseAddNode'
import { AppState } from '../../../models/appState'

const useAddLink: IUseAddNode = (save) => {
  const { open } = useDialog()
  const step = useSelector((state: AppState) => state.app.nodeEditor.pageStep)
  const pageStepRef = useRef(step)

  useEffect(() => {
    pageStepRef.current = step
  }, [step])

  const addLink = () => {
    open(<EditLink initialText={''} initialTitle={''} onSave={save} />, {
      fullScreen: true,
      blankDialog: true,
    })
  }

  return addLink
}

export default useAddLink
