import { useTranslation } from 'react-i18next'
import { useDialog } from 'boards-web-ui'

import EditHeadlineOrParagraph from '../components/EditHeadlineOrParagraph'
import { Node } from '../../../models'
import { IUseSaveNode } from '../models/IUseEditNode'

const useEditParagraph: IUseSaveNode = (save) => {
  const { open } = useDialog()
  const { t } = useTranslation()

  const editParagraph = (node: Node) => {
    open(
      <EditHeadlineOrParagraph
        initialText={node.text.trim() || ''}
        placeholder={t('page_text_paragraph')}
        onSave={(n) => save(node.id, n)}
        paragraph
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return editParagraph
}

export default useEditParagraph
