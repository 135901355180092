import { useState } from 'react'

const useFocusState = () => {
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  return {
    isFocused,
    onFocus,
    onBlur,
  } as const
}

export default useFocusState
