import { Board } from '../../../models'

type FreePlanUserMenuBoards = { [p: string]: Board }

export const useFreePlanUserMenu = ({
  order,
  boards,
}: {
  order: string[]
  boards: FreePlanUserMenuBoards
}) => {
  const { sharedBoards, ownBoards, activeBoards, menuOrder } = order.reduce<{
    sharedBoards: FreePlanUserMenuBoards
    ownBoards: FreePlanUserMenuBoards
    activeBoards: FreePlanUserMenuBoards
    menuOrder: {
      sharedBoards: string[]
      ownBoards: string[]
      activeBoards: string[]
    }
  }>(
    (acc, currentValue) => {
      const getBoardTypeToAdd = () => {
        if (boards[currentValue]?.isActive) {
          return 'activeBoards'
        }

        return boards[currentValue]?.isOwn ? 'ownBoards' : 'sharedBoards'
      }

      const key = getBoardTypeToAdd()

      return {
        ...acc,
        [key]: {
          ...acc[key],
          [currentValue]: boards[currentValue],
        },
        menuOrder: {
          ...acc.menuOrder,
          [key]: [...acc.menuOrder[key], currentValue],
        },
      }
    },
    {
      sharedBoards: {},
      ownBoards: {},
      activeBoards: {},
      menuOrder: {
        sharedBoards: [],
        ownBoards: [],
        activeBoards: [],
      },
    },
  )

  const activeBoardsLength = Object.keys(activeBoards).length
  const activeBoardsOrder = menuOrder.activeBoards

  const sharedBoardsLength = Object.keys(sharedBoards).length
  const sharedBoardsOrder = menuOrder.sharedBoards

  const ownBoardsLength = Object.keys(ownBoards).length
  const ownBoardsOrder = menuOrder.ownBoards

  return {
    sharedBoards,
    sharedBoardsOrder,
    sharedBoardsLength,

    ownBoards,
    ownBoardsOrder,
    ownBoardsLength,

    activeBoards,
    activeBoardsOrder,
    activeBoardsLength,
  }
}
