import { useContext } from 'react'
import { CheckoutFormProduct } from 'boards-web-ui'
import { CheckoutContext } from '../providers'

type Props = {
  onAfterAddProduct?: (product?: CheckoutFormProduct) => void
  onAfterAddAdditionalField?: () => void
}
const useCheckout = ({
  onAfterAddProduct,
  onAfterAddAdditionalField,
}: Props = {}) => {
  const { onProductAdd, onAdditionalFieldsAdd, ...context } =
    useContext(CheckoutContext)
  return {
    ...context,
    onProductAdd: (product?: CheckoutFormProduct) => {
      const addedProduct = onProductAdd(product)
      if (onAfterAddProduct) {
        onAfterAddProduct(addedProduct)
      }
    },
    onAdditionalFieldsAdd: async () => {
      await onAdditionalFieldsAdd()

      if (onAfterAddAdditionalField) {
        onAfterAddAdditionalField()
      }
    },
  }
}

export default useCheckout
