import { useState, ComponentType } from 'react'

import { useSelectorFactory } from '@hooks/useSelectorFactory'

import { getUploadingNodes } from '../../selectors'
import useLoadImage from '../../hooks/useLoadImage'

import { Node } from '../models'
import settings from '../settings'

interface Props {
  node: Node
}
interface InjectedProps {
  thumbnail: string
  isLoading: boolean
  isUploading: boolean
  onError: () => void
  onLoad: () => void
}
function withImage(Component: ComponentType<Props & InjectedProps>) {
  return (hocProps: Props) => {
    const { hasError, handleImageError } = useLoadImage()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const uploadingNodes = useSelectorFactory(getUploadingNodes)

    const handleOnLoad = () => setIsLoading(false)

    const isUploading = uploadingNodes.includes(hocProps.node.id)

    const thumbnail =
      isUploading || hasError
        ? settings.nodeImagePlaceholder
        : hocProps.node.text

    return (
      <Component
        node={hocProps.node}
        thumbnail={thumbnail}
        isLoading={isLoading}
        // TODO remove this huck when upload progress bar design will be ready
        // https://heybliss.atlassian.net/browse/BLISS-7549
        isUploading={isUploading || hasError}
        onError={handleImageError}
        onLoad={handleOnLoad}
      />
    )
  }
}

export default withImage
