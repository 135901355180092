import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonLinkIcon,
  CheckoutIcon,
  ContentFromMyBoardsIcon,
  FormIcon,
  Grid,
  HeadlineIcon,
  If,
  ImageIcon,
  PdfIcon,
  PremiumIndicationIcon,
  ProfileIcon,
  TextCustomFieldIcon,
  VideoIcon,
  YoutubeVimeoIcon,
} from 'boards-web-ui'

import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { ProfileFeatures } from '@models/UserProfile'

import usePageEvents from '@features/analytics/usePageEvents'
import usePageEditorEvents from '@features/analytics/usePageEditorEvents'
import {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { SelectAction, useSourcePicker } from '@features/pickers'

import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'
import { IconWrapper } from '@ui/components/IconWrapper'
import {
  AnimatedArrowDirection,
  AnimatedArrows,
} from '../../../../components/AnimatedArrows'
import useAddNode from '../../nodeEditor/useAddNode'

import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { Folder, NodeType } from '../../../models'
import Chip from '../../../../ui/components/Chip'

import PageMenuItem from './PageMenuItem'
import styles from './PageMenu.module.css'

interface Props {
  folder: Folder
  createNode: ICreateOrUpdateNode
  updateNode: (id: string, node: INodeProps) => void
  selectAction: SelectAction
}

const PageMenu: FC<Props> = ({
  folder,
  createNode,
  updateNode,
  selectAction,
}) => {
  const { addPageContentMenuItemClick } = usePageEvents()
  const { t } = useTranslation()
  const { tryFree, limitReached, enabled } = useUserFeatures(
    Features.uploadVideo,
  )

  const { isFeatureAvailable: isCheckoutFeatureEnabled } =
    useProfileFeatureFlag(ProfileFeatures.CHECKOUT)

  const { isLimitedNode, getLimitationKeyIcon } = usePagePremiumLimitationMode()

  const { profile } = useProfileInformation()
  const { selectContent } = useSourcePicker(selectAction)
  const { viewInsertContentDialogEvent } = usePageEditorEvents()

  const {
    addButton,
    addProfile,
    addImages,
    addVideo,
    addVideoStreaming,
    addHeadline,
    addParagraph,
    addForm,
    addCheckout,
    addPdf,
  } = useAddNode(folder?.id, createNode, updateNode, true)
  const showTryFreeBadge = tryFree && !limitReached

  const onClickContentFromMyBoards = () => {
    selectContent()
    viewInsertContentDialogEvent()
  }

  const handleClickPageMenu =
    (
      callback: () => void,
      type: NodeType,
      isTryFree = false,
      isBlocked = false,
    ) =>
    () => {
      callback()
      addPageContentMenuItemClick(type, isTryFree, isBlocked)
    }

  return (
    <div className={styles.Menu}>
      {folder.content.length === 0 && (
        <AnimatedArrows
          className={styles.Arrow}
          direction={AnimatedArrowDirection.left}
        />
      )}

      <PageMenuItem
        icon={
          <IconWrapper>
            <ContentFromMyBoardsIcon />
          </IconWrapper>
        }
        title={t('page_menu_content_from_my_boards')}
        onClick={onClickContentFromMyBoards}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <HeadlineIcon />
          </IconWrapper>
        }
        title={t('page_text_headline')}
        onClick={handleClickPageMenu(addHeadline, NodeType.HEADLINE)}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <TextCustomFieldIcon />
          </IconWrapper>
        }
        title={t('page_text_paragraph')}
        onClick={handleClickPageMenu(addParagraph, NodeType.PARAGRAPH)}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <ImageIcon />
          </IconWrapper>
        }
        title={t('content_key_photo')}
        onClick={handleClickPageMenu(addImages, NodeType.IMAGE)}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <VideoIcon />
          </IconWrapper>
        }
        title={t('menu_upload_video')}
        chip={
          <Grid rowGap={5}>
            {(!enabled || tryFree) && (
              <IconWrapper>
                <PremiumIndicationIcon />
              </IconWrapper>
            )}
            {showTryFreeBadge && (
              <Chip blue>
                <Trans i18nKey={'badge_try_free'} />
              </Chip>
            )}
            {!showTryFreeBadge && !profile.uiState.videoHintShown && (
              <Chip blue>
                <Trans i18nKey={'badge_new'} />
              </Chip>
            )}
          </Grid>
        }
        onClick={handleClickPageMenu(
          addVideoStreaming,
          NodeType.VIDEO_STREAMING,
          showTryFreeBadge,
          !enabled || tryFree,
        )}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <PdfIcon />
          </IconWrapper>
        }
        title={t('content_key_pdf')}
        onClick={handleClickPageMenu(addPdf, NodeType.PDF)}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <YoutubeVimeoIcon />
          </IconWrapper>
        }
        title={t('content_key_youtube_vimeo')}
        onClick={handleClickPageMenu(addVideo, NodeType.VIDEO)}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <ButtonLinkIcon />
          </IconWrapper>
        }
        title={t('page_button')}
        chip={
          !profile.uiState.buttonsHintShown ? (
            <Chip blue>
              <Trans i18nKey={'badge_new'} />
            </Chip>
          ) : undefined
        }
        onClick={handleClickPageMenu(addButton, NodeType.BUTTON)}
      />

      <PageMenuItem
        icon={
          <IconWrapper>
            <ProfileIcon />
          </IconWrapper>
        }
        title={t('label_profile')}
        onClick={handleClickPageMenu(addProfile, NodeType.PROFILE)}
      />

      <PageMenuItem
        icon={
          <IconWrapper className={styles.IconWrapper}>
            <FormIcon />
          </IconWrapper>
        }
        title={t('label_form')}
        disabledStyleMenu={isLimitedNode(NodeType.FORM)}
        chip={
          <Grid rowGap={5}>
            {getLimitationKeyIcon(NodeType.FORM)}

            {!profile.uiState.formsHintShown ? (
              <Chip blue>
                <Trans i18nKey={'badge_new'} />
              </Chip>
            ) : undefined}
          </Grid>
        }
        onClick={handleClickPageMenu(
          addForm,
          NodeType.FORM,
          false,
          isLimitedNode(NodeType.FORM),
        )}
      />

      <If state={isCheckoutFeatureEnabled}>
        <PageMenuItem
          icon={
            <IconWrapper className={styles.IconWrapper}>
              <CheckoutIcon />
            </IconWrapper>
          }
          title={t('label_feature_checkout')}
          disabledStyleMenu={isLimitedNode(NodeType.CHECKOUT)}
          chip={
            <Grid rowGap={5}>
              {getLimitationKeyIcon(NodeType.CHECKOUT)}

              {!profile.uiState.checkoutHintShown ? (
                <Chip blue>
                  <Trans i18nKey={'badge_new'} />
                </Chip>
              ) : undefined}
            </Grid>
          }
          onClick={handleClickPageMenu(
            addCheckout,
            NodeType.CHECKOUT,
            false,
            isLimitedNode(NodeType.CHECKOUT),
          )}
        />
      </If>
    </div>
  )
}

export default PageMenu
