import { EffectCallback, useEffect, useState } from 'react'

const useSkipFirstChange = (
  effect: EffectCallback,
  dependencies: Array<unknown>,
) => {
  const [init, setInit] = useState<boolean>(false)

  useEffect(() => {
    if (!init) {
      setInit(true)
      return
    }

    // eslint-disable-next-line
    return effect()
    // no need to add more dependency to avoid extra trigger
    // eslint-disable-next-line
  }, dependencies)
}

export default useSkipFirstChange
