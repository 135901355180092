import { UserProfile } from '@models/UserProfile'

export const enum ACTION_TYPE {
  UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO',
  SET_PAYPAL_AUTHORIZATION = 'SET_PAYPAL_AUTHORIZATION',
  SYNC_PROFILE_INFO = 'SYNC_PROFILE_INFO',
}

const updateProfileInformation = (info: UserProfile) => ({
  type: ACTION_TYPE.UPDATE_PROFILE_INFO,
  info,
})

const syncProfileInformation = (info: UserProfile) => ({
  type: ACTION_TYPE.SYNC_PROFILE_INFO,
  info,
})

const setPayPalAuthorization = (status: string) => ({
  type: ACTION_TYPE.SET_PAYPAL_AUTHORIZATION,
  status,
})

const actions = {
  updateProfileInformation,
  syncProfileInformation,
  setPayPalAuthorization,
} as const

export default actions
