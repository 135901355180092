import React, { FC, ReactNode } from 'react'
import { InputToggleSwitch } from 'boards-web-ui'
import styles from './EmailPreferences.module.css'

export type TEmailPreferencesId = 'offers' | 'tips' | 'product'

export const InfoRow: FC<{
  title: ReactNode
  subtitle: ReactNode
  checked: boolean
  onSwitchChange: (checked: boolean) => void
}> = ({ title, subtitle, onSwitchChange, checked }) => (
  <div className={styles.InfoRow}>
    <div className={styles.InfoRowTitleContainer}>
      <span className={styles.Title}>{title}</span>

      <span className={styles.Subtitle}>{subtitle}</span>
    </div>

    <InputToggleSwitch
      checked={checked}
      onChange={(event) => {
        onSwitchChange(event.target.checked)
      }}
    />
  </div>
)
