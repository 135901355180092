import { FC, ReactNode } from 'react'

import styles from './TermWithDiscount.module.css'

export const TermWithDiscount: FC<{
  text: ReactNode
  discount: number
}> = ({ text, discount }) => (
  <div className={styles.Root}>
    <span>{text}</span>
    <span className={styles.Discount}>-{discount}%</span>
  </div>
)
