/**
 * TODO: migrate this component to shared UI lib, extend InputField with an
 * extra property like withEmojiPicker
 *
 * Ex: <InputField ... withEmojiPicker />
 */

import { InputField, SmileIcon } from 'boards-web-ui'
import { ChangeEvent, SyntheticEvent, useRef, useState } from 'react'
import EmojiPicker from '../../../../components/EmojiPicker'

import styles from './InputFieldWithEmojiPicker.module.css'

interface Props {
  label: string
  placeholder: string
  value: string
  autoFocus?: boolean
  onFocus?: () => void
  onChange: (t: string) => void
}
const InputFieldWithEmojiPicker = ({
  label,
  placeholder,
  value,
  autoFocus,
  onFocus,
  onChange,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [cursorPosition, setCursorPosition] = useState(0)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const onEmojiClick = (emoji: string) => {
    const title = value || ''

    const position = cursorPosition || inputRef?.current?.selectionStart || 0
    const newVal = title.slice(0, position) + emoji + title.slice(position)
    setCursorPosition(position + emoji.length)
    onChange(newVal)
  }

  const handleFocus = (e: SyntheticEvent) => {
    let range
    if (document.createRange) {
      // Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange() // Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(e.currentTarget) // Select the entire contents of the element with the range
      range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start
    }

    if (onFocus) onFocus()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const onClickOutsideEmojiPicker = () => {
    setCursorPosition(0)
    setShowEmojiPicker(false)
  }

  return (
    <div className={styles.Wrapper}>
      <InputField
        className={styles.Input}
        ref={inputRef}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        blueVariant
      />
      <div
        className={[
          `${styles.EmojiPickerWrapper} ${
            showEmojiPicker ? styles.ShowPicker : ''
          }`,
        ].join(' ')}
      >
        <SmileIcon
          className={styles.SmileIcon}
          onClick={() => setShowEmojiPicker(true)}
        />

        {showEmojiPicker && (
          <EmojiPicker
            standalone
            onClickEmoji={onEmojiClick}
            onClickOutside={onClickOutsideEmojiPicker}
            side="right"
          />
        )}
      </div>
    </div>
  )
}

export default InputFieldWithEmojiPicker
