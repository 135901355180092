import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { getSubscriptionPlans } from '../../../../selectors'
import { CurrentPlan, SubscriptionPlan } from '../../../models'

interface ISubscriptionPlans {
  plans: Array<SubscriptionPlan>
  current: CurrentPlan
  status: string | null
  discountId?: string
  paddlePriceIds: string[]
}

export const useSubscriptionPlans = (): ISubscriptionPlans => {
  const { data, status, discountId } = useSelectorFactory(getSubscriptionPlans)
  const { current } = data

  const paddlePriceIds = data.plans
    .map((p: SubscriptionPlan) =>
      p.subscriptions.map((s) => s?.offer?.priceId || s.productId),
    )
    .flat()

  return {
    plans: data.plans,
    status,
    discountId,
    current,
    paddlePriceIds,
  } as const
}

export default useSubscriptionPlans
