import { useDialog } from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import EditHeadlineOrParagraph from '../components/EditHeadlineOrParagraph'
import { IUseAddNode } from '../models/IUseAddNode'

const useAddHeadline: IUseAddNode = (save) => {
  const { open } = useDialog()
  const { t } = useTranslation()

  const addHeadline = () => {
    open(
      <EditHeadlineOrParagraph
        initialText={''}
        placeholder={t('page_text_headline')}
        onSave={save}
        headline
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return addHeadline
}

export default useAddHeadline
