import { useDialog } from 'boards-web-ui'
import { UpgradeToViewDialog } from '@features/reverseTrial/components/UpgradeToViewDialog/UpgradeToViewDialog'
import styles from '@features/reverseTrial/components/UpgradeToViewDialog/UpgradeToViewDialog.module.css'

export const useUpgradeToViewDialog = () => {
  const { open } = useDialog()

  const openUpgradeToViewDialog = () => {
    open(<UpgradeToViewDialog />, {
      closeOnOverlayClick: true,
      className: styles.DialogRoot,
    })
  }

  return { openUpgradeToViewDialog }
}
