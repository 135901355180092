import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ForwardIcon, Icon } from 'boards-web-ui'
import usePaywall from '@hooks/usePaywall'
import useReverseTrialEvents from '@features/reverseTrial/hooks/useReverseTrialEvents'
import ReverseTrialCountdownTimer from '@features/reverseTrial/components/countdownBanner/ReverseTrialCountdownTimer'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import CloseButton from '@ui/components/CloseButton'
import styles from './ReverseTrialCountdownBanner.module.css'

type Props = {
  dueDate: Date
  handleUserCloseCountdownBanner: () => void
}

const ReverseTrialCountdownBanner: FC<Props> = ({
  dueDate,
  handleUserCloseCountdownBanner,
}) => {
  const { t } = useTranslation()
  const { openPaywall } = usePaywall()
  const { trialCountdownViewEvent, trialCountdownDismissEvent } =
    useReverseTrialEvents()

  useEffect(() => {
    trialCountdownViewEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    trialCountdownDismissEvent()
    handleUserCloseCountdownBanner()
  }

  const handleUpgrade = () => {
    openPaywall({ source: PaywallSourceEnum.TRIAL_REMINDER_BANNER })
  }

  return (
    <div className={styles.Root}>
      <div className={styles.CloseButton}>
        <CloseButton size={12} onClose={handleClose} />
      </div>

      <h4 className={styles.Title}>{t('trial_banner_v2')}</h4>

      <ReverseTrialCountdownTimer dueDate={dueDate} />

      <button className={styles.ActionButton} onClick={handleUpgrade}>
        {t('action_upgrade_now')}
        <Icon>
          <ForwardIcon />
        </Icon>
      </button>
    </div>
  )
}

export default ReverseTrialCountdownBanner
