import styles from './LayoutDesktopPlaceholder.module.css'

const LayoutDesktopPlaceholder = () => (
  <div className={styles.BlueBackground}>
    <div className={styles.SquaresBackground}>
      <img
        className={styles.Phone}
        src="/features/layout/phone.png"
        alt="Boards"
      />
    </div>
  </div>
)

export default LayoutDesktopPlaceholder
