import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { If, ImagePicker, InputField, Spacer } from 'boards-web-ui'

import usePageProfile from '@features/PageProfile/hooks/usePageProfile'

import ProfileVariationPicker from './ProfileVariationPicker'

import styles from './PageProfileForm.module.css'

const PageProfileForm: FC = () => {
  const { t } = useTranslation()
  const {
    pageProfileFormState: {
      onChange,
      uploadImage,
      formState,
      pending,
      imagePreview,
    },
    isProfileVariationAvailable,
  } = usePageProfile()

  return (
    <>
      <ImagePicker
        accept="image/png, image/jpeg"
        pending={pending}
        label={t('profile_cover_image')}
        emptyLabel={t('profile_add_an_image')}
        imageSrc={imagePreview || undefined}
        onChange={uploadImage}
      />

      <Spacer height={15} />

      <InputField
        selectValueOnFocus
        name="name"
        label={t('profile_headline')}
        placeholder={t('profile_headline_placeholder')}
        value={formState.profile.name}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <InputField
        selectValueOnFocus
        name="title"
        label={t('profile_description')}
        placeholder={t('profile_description_placeholder')}
        value={formState.profile.title}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <InputField
        selectValueOnFocus
        name="company"
        label={t('profile_additional_information')}
        placeholder={t('profile_additional_information_placeholder')}
        value={formState.profile.company}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <If state={isProfileVariationAvailable}>
        <ProfileVariationPicker
          label="Variation"
          name="variation"
          value={formState.profile.variation}
          onChange={onChange}
        />
      </If>
    </>
  )
}

export default PageProfileForm
