import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useSelectorFactory } from '@hooks/useSelectorFactory'

import {
  FormIntegrationStatusData,
  getFormIntegrationStatus,
} from '../../../../_firebase'
import { nodeActions } from '../../../../actions'
import { selectIntegrationState } from '../../../../selectors'

const useFormIntegrationStatusState = (
  boardId: string,
  pageId: string,
  formId?: string,
) => {
  const dispatch = useDispatch()
  const integrationStatusState = useSelectorFactory(
    selectIntegrationState,
    boardId,
    pageId,
    formId,
  )

  const [formIntegrationStatusPending, setFormIntegrationStatusPending] =
    useState<boolean>(false)
  const [formIntegrationStatusState, setFormIntegrationStatusState] =
    useState<FormIntegrationStatusData | null>(integrationStatusState || null)

  useEffect(() => {
    setFormIntegrationStatusState(integrationStatusState || null)
  }, [integrationStatusState])

  const onGetFormIntegrationStatus = useCallback(
    async (nodeId?: string) => {
      try {
        setFormIntegrationStatusPending(true)
        const integrationStateResult = await getFormIntegrationStatus(
          boardId,
          pageId,
          nodeId || formId,
        )
        dispatch(
          nodeActions.setFormIntegrationState(
            boardId,
            pageId,
            nodeId || formId,
            integrationStateResult,
          ),
        )
        setFormIntegrationStatusState((state) => {
          return {
            ...state,
            ...integrationStateResult,
          } as FormIntegrationStatusData
        })
        setFormIntegrationStatusPending(false)
      } catch (e) {
        setFormIntegrationStatusPending(false)
      }
    },
    [dispatch, boardId, pageId, formId, setFormIntegrationStatusState],
  )

  return useMemo(
    () => ({
      formIntegrationStatusPending,
      formIntegrationStatusState,
      onGetFormIntegrationStatus,
    }),
    [
      formIntegrationStatusPending,
      formIntegrationStatusState,
      onGetFormIntegrationStatus,
    ],
  )
}

export default useFormIntegrationStatusState
