import { ButtonNew, PremiumCrownIcon } from 'boards-web-ui'
import { Trans } from 'react-i18next'
import React, { FC, ForwardedRef } from 'react'
import { useFreePlanUserMenu } from '@features/boardsMenu/hooks/useFreePlanUserMenu'
import { BoardsSection } from '@features/boardsMenu/components/FreePlanUserMenu/BoardsSection'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePaywall from '@hooks/usePaywall'
import { IconWrapper } from '@ui/components/IconWrapper'
import styles from './FreePlanUserMenu.module.css'
import { Board } from '../../../../models'

interface IProps {
  ref: ForwardedRef<HTMLDivElement>
  order: string[]
  boards: { [p: string]: Board }
  draggedTo: number | undefined
  onDragStart: (event: unknown) => void
  onDragOver: (event: unknown) => void
  onDrop: () => void
  onDragLeave: () => void
}

export const FreePlanUserMenu: FC<IProps> = ({
  ref,
  order,
  boards,
  ...rest
}) => {
  const {
    sharedBoardsLength,
    sharedBoards,
    sharedBoardsOrder,

    ownBoards,
    ownBoardsOrder,
    ownBoardsLength,

    activeBoardsOrder,
    activeBoardsLength,
    activeBoards,
  } = useFreePlanUserMenu({ order, boards })
  const { openPaywall } = usePaywall()

  const countWebPageBoards = Object.keys(ownBoards).filter(
    (key) => ownBoards[key].isPageBoard,
  ).length

  return (
    <div ref={ref}>
      <div className={styles.CreateBoardWrapper}>
        {activeBoardsLength > 0 ? (
          <BoardsSection
            boards={activeBoards}
            order={activeBoardsOrder}
            {...rest}
          />
        ) : null}

        <div className={styles.CreateBoardButton}>
          <ButtonNew
            color={'secondary'}
            startIcon={
              <IconWrapper>
                <PremiumCrownIcon />
              </IconWrapper>
            }
            fullWidth
            onClick={() => {
              openPaywall({ source: PaywallSourceEnum.CREATE_BOARD_HOMESCREEN })
            }}
          >
            <Trans i18nKey="create_board" />
          </ButtonNew>
        </div>
      </div>

      {sharedBoardsLength > 0 ? (
        <BoardsSection
          title={<Trans i18nKey={'share_folder_shared_with_me'} />}
          boards={sharedBoards}
          order={sharedBoardsOrder}
          {...rest}
        />
      ) : null}

      {ownBoardsLength - countWebPageBoards > 0 ? (
        <BoardsSection
          title={<Trans i18nKey={'owned_boards_title_homescreen'} />}
          boards={ownBoards}
          order={ownBoardsOrder}
          {...rest}
        />
      ) : null}
    </div>
  )
}
