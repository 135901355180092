import { DocumentSnapshot } from 'firebase/firestore'
import { profileInfoActions } from '../../../actions'
import { AppDispatch } from '../../../app/store'
import { transformProfile } from '../transformers/ProfileTransformer'

export const ProfileSnapshot =
  (dispatch: AppDispatch) => (doc: DocumentSnapshot) => {
    const data = transformProfile(doc)
    dispatch(profileInfoActions.syncProfileInformation(data))
  }
