import React, { FC, RefObject, useCallback, useEffect } from 'react'

import { useCheckout } from '../hooks'
import { CheckoutActionsIdsEnum } from '../models'

import CheckoutProducts from './CheckoutProducts'
import AdditionalFields from './AdditionalFields'
import CheckoutSettings from './CheckoutSettings'
import styles from './CheckoutBody.module.css'

type Props = {
  wrapperRef: RefObject<HTMLDivElement | null>
}
const CheckoutBody: FC<Props> = ({ wrapperRef }) => {
  const { action } = useCheckout()

  const scrollTo = useCallback(
    (position: number = wrapperRef?.current?.scrollHeight || 0) => {
      const timer = setTimeout(() => {
        if (wrapperRef.current) {
          // eslint-disable-next-line
          wrapperRef.current.scrollTop = position
        }
        clearTimeout(timer)
      }, 50)
    },
    [wrapperRef],
  )

  useEffect(() => {
    scrollTo(0)
  }, [action?.id, scrollTo])

  return (
    <div className={styles.CheckoutBody}>
      {action?.id === CheckoutActionsIdsEnum.PRODUCTS ? (
        <CheckoutProducts onAfterAddProduct={() => scrollTo()} />
      ) : null}
      {action?.id === CheckoutActionsIdsEnum.FIELDS ? (
        <AdditionalFields onAfterAddAdditionalField={() => scrollTo()} />
      ) : null}
      {action?.id === CheckoutActionsIdsEnum.SETTINGS ? (
        <CheckoutSettings />
      ) : null}
    </div>
  )
}

export default React.memo(CheckoutBody)
