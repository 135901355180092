import { useDialog } from 'boards-web-ui'

import EditProfileName from '../components/EditProfileName'

const useProfileNameDialog = () => {
  const { open } = useDialog()

  const profileNameDialog = () => {
    open(<EditProfileName />, {
      closeOnOverlayClick: true,
    })
  }

  return profileNameDialog
}

export default useProfileNameDialog
