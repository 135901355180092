import { useTranslation } from 'react-i18next'
import { Node, NodeType } from '../../../models'

const useAutoPopulatePageTitle = () => {
  const { t } = useTranslation()

  const autoPopulatePageTitle = (nodes: Node[], title?: string) => {
    const headlineNode = nodes.find((n) => n.type === NodeType.HEADLINE)

    const newTitle = title || headlineNode?.title

    if (newTitle) {
      return newTitle.substring(0, 30)
    }

    return t('page_my_page')
  }

  return autoPopulatePageTitle
}

export default useAutoPopulatePageTitle
