import { FC, useEffect } from 'react'
import useRouterParams from '@hooks/useRouterParams'
import useFolderEvents from '@features/analytics/useFolderEvents'
import useBoard from '@hooks/useBoard'
import useFolder from '@hooks/useFolder'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import PreviewImage from './components/PreviewImage'
import PreviewPdf from './components/PreviewPdf'
import PreviewText from './components/PreviewText'
import PreviewLink from './components/PreviewLink'
import PreviewVideo from './components/PreviewVideo'
import PreviewPage from './components/PreviewPage'
import PreviewAudio from './components/PreviewAudio'
import PreviewVideoStreaming from './components/PreviewVideoStreaming'
import { Folder, NodeType } from '../../models'
import usePreviewNode from './hooks/usePreviewNode'

interface Props {
  nid: string
  close: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const NodePreview: FC<Props> = ({
  nid,
  close,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
}) => {
  const { boardId, folderId } = useRouterParams()
  const { folder: srcFolder } = useFolder(
    sharedBoardId || boardId,
    sharedFolderId || folderId,
  )
  const { node, prev, next } = usePreviewNode(nid, folder || srcFolder)
  const { folderViewContentEvent } = useFolderEvents()

  const { isActiveBoard, isPageBoard } = useBoard()
  const { isFreePlanUser } = useReverseTrialContext()

  useEffect(() => {
    const isDisabledPreviewNavigation =
      isFreePlanUser &&
      !isActiveBoard &&
      !isPageBoard &&
      node?.source.type === NodeType.PAGE

    if (isDisabledPreviewNavigation) {
      return
    }

    const bind = (e: KeyboardEvent) => {
      if (next && e.key === 'ArrowRight') {
        next()
      }
      if (prev && e.key === 'ArrowLeft') {
        prev()
      }
    }

    document.addEventListener('keyup', bind)

    // eslint-disable-next-line
    return () => {
      document.removeEventListener('keyup', bind)
    }
  }, [
    isActiveBoard,
    isPageBoard,
    isFreePlanUser,
    next,
    node?.source.type,
    prev,
  ])

  useEffect(() => {
    if (node?.source.id) {
      if (node.source.type !== NodeType.PAGE) {
        folderViewContentEvent(node.source.id, node.source.type, boardId)
      }
    }
    // eslint-disable-next-line
  }, [node?.source.id, node?.source.type, node?.source.shortcut, boardId])

  if (!node) return null

  return (
    <>
      {node.source.type === NodeType.PDF && (
        <PreviewPdf
          node={node.source}
          next={next}
          prev={prev}
          close={close}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.AUDIO && (
        <PreviewAudio
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.TEXT && (
        <PreviewText
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.HEADLINE && (
        <PreviewText
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.PARAGRAPH && (
        <PreviewText
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.LINK && (
        <PreviewLink
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.BUTTON && (
        <PreviewLink
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.VIDEO && (
        <PreviewVideo
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.VIDEO_STREAMING && (
        <PreviewVideoStreaming
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.IMAGE && (
        <PreviewImage
          node={node.source}
          next={next}
          prev={prev}
          folder={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
      {node.source.type === NodeType.PAGE && (
        <PreviewPage
          node={node.source}
          next={next}
          prev={prev}
          folderShared={folder}
          isSharedFolderMode={isSharedFolderMode}
          canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
          sharedBoardId={sharedBoardId}
          sharedFolderId={sharedFolderId}
        />
      )}
    </>
  )
}

export default NodePreview
