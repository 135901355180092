import { useEffect, useRef, useState } from 'react'
import {
  caretApi,
  CheckoutFormProduct,
  CheckoutFormProducts,
} from 'boards-web-ui'

const useProductsInputRefs = (products: CheckoutFormProducts) => {
  const [activeRef, setActiveRef] = useState<{
    id: string
    type: 'title' | 'description'
  } | null>(null)

  const refs = useRef<
    Record<
      string,
      {
        title: HTMLDivElement | undefined
        description: HTMLDivElement | undefined
      }
    >
  >(
    products.reduce((result, product) => {
      return {
        ...result,
        [product.id]: {
          title: undefined,
          description: undefined,
        },
      }
    }, {}),
  )

  useEffect(() => {
    refs.current = products.reduce((result, product) => {
      return {
        ...result,
        [product.id]: refs.current[product.id]
          ? refs.current[product.id]
          : {
              title: undefined,
              description: undefined,
            },
      }
    }, {})
  }, [products])

  const addRef = (
    id: string,
    type: 'title' | 'description',
    element: HTMLDivElement,
  ) => {
    if (!element || !refs.current?.[id]) {
      return
    }

    refs.current[id][type] = element
  }

  const setFocusOnProductFieldRef = (product?: CheckoutFormProduct) => {
    const timer = setTimeout(() => {
      if (!product && !activeRef) {
        clearTimeout(timer)
        return
      }

      const id = product?.id || activeRef?.id || ''
      const type = (product ? 'title' : activeRef?.type) || 'title'

      caretApi.focusInTheEnd(refs.current[id]?.[type] || undefined)
      clearTimeout(timer)
    }, 400)
  }

  const findCurrentRefByActiveElement = () => {
    let currentElement: {
      id: string
      type: 'title' | 'description'
    } | null = null

    Object.entries(refs.current).forEach(([id, elem]) => {
      if (document.activeElement === elem?.title) {
        currentElement = {
          id,
          type: 'title',
        }
      }

      if (document.activeElement === elem?.description) {
        currentElement = {
          id,
          type: 'description',
        }
      }
    })

    setActiveRef(currentElement)
  }

  return {
    refs,
    addRef,
    setFocusOnProductFieldRef,
    findCurrentRefByActiveElement,
  } as const
}

export default useProductsInputRefs
