import { FC, memo } from 'react'
import { If } from 'boards-web-ui'

import { ProfileFeatures } from '@models/UserProfile'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'

import CheckoutResponsesEmail from './CheckoutResponsesEmail'
import CheckoutGoogleSheetsConnector from './CheckoutGoogleSheetsConnector'

import styles from './CheckoutResponses.module.css'

const CheckoutSettingsResponses: FC = () => {
  const { isFeatureAvailable: isGoogleSheetsFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.GOOGLE_SHEETS)
  return (
    <div className={styles.Root}>
      <CheckoutResponsesEmail />
      <If state={isGoogleSheetsFeatureAvailable}>
        <CheckoutGoogleSheetsConnector />
      </If>
    </div>
  )
}

export default memo(CheckoutSettingsResponses)
