export const filterFirstError = (errors, sortedFields) => {
  if (Object.keys(errors).length > 0) {
    for (let i = 0; i < sortedFields.length; i += 1) {
      if (Object.prototype.hasOwnProperty.call(errors, sortedFields[i])) {
        return { [sortedFields[i]]: errors[sortedFields[i]] }
      }
    }
  }

  return {}
}
