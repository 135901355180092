import { APP_VERSION } from '@helpers/constants'

const compareVersions = (version1: string, version2: string): number => {
  const v1 = version1.split('.').map(Number)
  const v2 = version2.split('.').map(Number)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const num1 = v1[i] || 0
    const num2 = v2[i] || 0
    if (num1 < num2) return -1
    if (num1 > num2) return 1
  }

  return 0
}

export const isCurrentVersionFresh = (
  currentVersion: string,
  appVersion = APP_VERSION,
): boolean => {
  if (!currentVersion) {
    return false
  }

  const [, appVersionNumber] = appVersion.split('-')
  return compareVersions(appVersionNumber, currentVersion) >= 0
}

export default compareVersions
