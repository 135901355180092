import { FC } from 'react'
import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'

import styles from './PageProfileDialogHeader.module.css'

type PageProfileDialogHeaderProps = {
  title: string
  onCancel: () => void
}
const PageProfileDialogHeader: FC<PageProfileDialogHeaderProps> = ({
  title,
  onCancel,
}) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Spacer}></div>
      <h4 className="">{title}</h4>
      <CloseButtonWithTooltip onClose={onCancel} gray />
    </div>
  )
}

export default PageProfileDialogHeader
