import { NODE_LINK_TYPES } from 'boards-web-ui'

export const enum ACTION_TYPE {
  SET_BUTTON_ERROR = 'SET_BUTTON_ERROR',
  SET_BUTTON_TYPE = 'SET_BUTTON_TYPE',
  SET_BUTTON_GEO_DATA = 'SET_BUTTON_GEO_DATA',
}

const setButtonError = (buttonError?: string | null) => ({
  type: ACTION_TYPE.SET_BUTTON_ERROR,
  buttonError,
})

const setButtonType = (buttonType?: NODE_LINK_TYPES) => ({
  type: ACTION_TYPE.SET_BUTTON_TYPE,
  buttonType,
})

const setButtonGeoData = (geoData?: {
  countryCode?: string
  dialCode?: string
}) => ({
  type: ACTION_TYPE.SET_BUTTON_GEO_DATA,
  geoData,
})

const actions = {
  setButtonError,
  setButtonType,
  setButtonGeoData,
} as const

export default actions
