import { FC, useEffect, useRef, useState, MouseEvent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'

import {
  ErrorIndicator,
  ErrorIndicatorSize,
  Grid,
  If,
  InputField,
  InputRadio,
  Spacer,
} from 'boards-web-ui'

import useFocusState from '@hooks/useFocusState'

import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { ProfileFeatures } from '@models/UserProfile'
import useFormsEvents from '@features/analytics/useFormsEvents'

import {
  CHECKOUT_POST_SUBMITTING_BEHAVIOUR,
  DEFAULT_POST_SUBMISSION_MESSAGE,
} from '@features/checkout/constants'

import Label from '../../../../ui/components/Label'

import { AfterSubmitBehaviourEnum } from '../constants'
import { useEditForms } from '../hooks'
import FormGoogleSheetsConnector from './FormGoogleSheetsConnector'

import styles from './FormSettings.module.css'

// TODO split this components
const FormSettings: FC = () => {
  const { t } = useTranslation()
  const { formSettingsScreenView } = useFormsEvents()
  const {
    isFocused: emailInFocus,
    onBlur: emailOnBlur,
    ...emailFocusHandlers
  } = useFocusState()
  const {
    isFocused: isRedirectFocused,
    onBlur: redirectOnBlur,
    ...redirectFocusHandlers
  } = useFocusState()
  const customMessageInputRef = useRef<HTMLInputElement>(null)
  const redirectToUrlInputRef = useRef<HTMLInputElement>(null)
  const { isFeatureAvailable: isGoogleSheetsFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.GOOGLE_SHEETS)

  const {
    isSubmitted,
    advancedForms,
    formAction,
    responsesEmail,
    responsesEmailError,
    formRedirectUrl,
    postSubmissionError,

    formCustomMessage,
    afterSubmitBehaviour,
    onPaywall,
    onFormActionChange,
    onResponsesEmailChange,
    onFormRedirectUrlChange,
    onFormCustomMessageChange,
    onAfterSubmitBehaviourChange,
  } = useEditForms()

  const [formCustomMessageState, setFormCustomMessageState] = useState<string>(
    formCustomMessage || t('forms_thanks_message_default'),
  )
  const [formResponseEmailState, setFormResponseEmailState] = useState<string>(
    responsesEmail || '',
  )
  const [formRedirectUrlState, setFormRedirectUrlState] = useState<string>(
    formRedirectUrl || '',
  )

  useEffect(() => {
    setFormResponseEmailState(responsesEmail || '')
  }, [responsesEmail])

  useEffect(() => {
    setFormCustomMessageState(
      formCustomMessage || t('forms_thanks_message_default'),
    )
  }, [formCustomMessage, t])

  useEffect(() => {
    setFormRedirectUrlState(formRedirectUrl || '')
  }, [formRedirectUrl])

  useEffect(() => {
    formSettingsScreenView()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCustomMessageRadioClick = () => {
    if (formCustomMessage) {
      // TODO: remove this after refactor dialogs and click outside to save changes
      // workaround to force hasChanges() = true
      onFormCustomMessageChange(formCustomMessageState)
    }

    onAfterSubmitBehaviourChange(AfterSubmitBehaviourEnum.MESSAGE)
    setTimeout(() => customMessageInputRef.current?.focus())
  }

  const handleRedirectRadioClick = () => {
    if (formRedirectUrl) {
      // TODO: remove this after refactor dialogs and click outside to save changes
      // workaround to force hasChanges() = true
      onFormRedirectUrlChange(formRedirectUrl)
    }

    onAfterSubmitBehaviourChange(AfterSubmitBehaviourEnum.REDIRECT)
    setTimeout(() => redirectToUrlInputRef.current?.focus())
  }

  const handleCustomMessageClick = (e: MouseEvent<HTMLInputElement>) => {
    if (!advancedForms) {
      e.preventDefault()
      onPaywall()
    }
  }

  const shouldShowWrongFieldsIndicator = (): boolean => {
    if (!isSubmitted) {
      return false
    }

    if (!!responsesEmail.trim().length && responsesEmailError) {
      return true
    }

    if (
      formRedirectUrl?.trim().length &&
      afterSubmitBehaviour === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT &&
      postSubmissionError
    ) {
      return true
    }

    return false
  }

  return (
    <>
      {shouldShowWrongFieldsIndicator() ? (
        <>
          <ErrorIndicator
            inverseColors
            size={ErrorIndicatorSize.MEDIUM}
            className={styles.Indicator}
          >
            {t('validation_errors_fix_errors_to_continue')}
          </ErrorIndicator>
          <Spacer height={38} />
        </>
      ) : (
        ''
      )}
      <Spacer height={20} />
      <InputField
        value={formAction || ''}
        label={t('forms_submit_button_label_title')}
        placeholder={t('forms_submit_button_label_placeholder')}
        onChange={(e) => onFormActionChange(e.target.value)}
        onBlur={(e) => {
          if (!e.target.value.trim()) {
            onFormActionChange(t('forms_submit_button_label_default'))
          }
        }}
        blueVariant
      />

      <Spacer height={35} />
      <div className={styles.Line}>&nbsp;</div>
      <Spacer height={35} />

      <Label>{t('forms_collect_responses_title')}</Label>
      <Spacer height={20} />

      <InputField
        redVariant={isSubmitted && !emailInFocus && !!responsesEmailError}
        label={t('forms_responses_will_be_sent_to')}
        value={formResponseEmailState}
        errorMessage={isSubmitted && !emailInFocus ? responsesEmailError : ''}
        placeholder={t('button_email_button_placeholder')}
        onChange={(e) => setFormResponseEmailState(e.target.value)}
        onBlur={(e) => {
          emailOnBlur()
          if (e.target.value !== responsesEmail) {
            onResponsesEmailChange(e.target.value)
          }
        }}
        {...emailFocusHandlers}
      />

      <Spacer height={20} />

      <If state={isGoogleSheetsFeatureAvailable}>
        <FormGoogleSheetsConnector />
      </If>

      <Spacer height={35} />
      <div className={styles.Line}>&nbsp;</div>
      <Spacer height={35} />

      <Label subtitle={<Trans i18nKey={'forms_post_submission_subtitle'} />}>
        <Grid justifyContent="flex-start" columnGap={5}>
          <Trans i18nKey={'forms_post_submission_title'} />
        </Grid>
      </Label>

      <Spacer height={25} />

      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        columnGap={10}
      >
        <InputRadio
          checked={afterSubmitBehaviour === AfterSubmitBehaviourEnum.MESSAGE}
          onClick={handleCustomMessageRadioClick}
        />
        <div
          className={clsx(
            styles.RadioInput,
            afterSubmitBehaviour === AfterSubmitBehaviourEnum.MESSAGE &&
              styles.RadioInputWrapper,
          )}
          onPointerUp={handleCustomMessageRadioClick}
        >
          <InputField
            ref={customMessageInputRef}
            value={formCustomMessageState || ''}
            label={t('forms_display_custom_message')}
            placeholder={t('forms_thanks_message_placeholder')}
            onChange={(e) => setFormCustomMessageState(e.target.value)}
            onBlur={(e) => {
              setFormCustomMessageState(
                e.target.value || t(DEFAULT_POST_SUBMISSION_MESSAGE),
              )
              onFormCustomMessageChange(
                e.target.value || t(DEFAULT_POST_SUBMISSION_MESSAGE),
              )
            }}
            disabled={
              afterSubmitBehaviour === AfterSubmitBehaviourEnum.REDIRECT
            }
            blueVariant
          />
        </div>
      </Grid>
      <Spacer height={20} />

      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        columnGap={10}
      >
        <InputRadio
          checked={afterSubmitBehaviour === AfterSubmitBehaviourEnum.REDIRECT}
          onClick={handleRedirectRadioClick}
        />
        <div
          className={clsx(
            styles.RadioInput,
            afterSubmitBehaviour === AfterSubmitBehaviourEnum.REDIRECT &&
              styles.RadioInputWrapper,
          )}
          onPointerUp={handleRedirectRadioClick}
        >
          <InputField
            redVariant={
              isSubmitted && !isRedirectFocused && !!postSubmissionError
            }
            ref={redirectToUrlInputRef}
            value={formRedirectUrlState || ''}
            label={t('forms_redirect_to_url')}
            placeholder={t('button_link_button_placeholder')}
            onChange={(e) => setFormRedirectUrlState(e.target.value)}
            onBlur={(e) => {
              redirectOnBlur()
              if (e.target.value !== formRedirectUrl) {
                onFormRedirectUrlChange(e.target.value)
              }
            }}
            onMouseDown={handleCustomMessageClick}
            disabled={afterSubmitBehaviour === AfterSubmitBehaviourEnum.MESSAGE}
            errorMessageOnBlur={
              isSubmitted && !isRedirectFocused ? postSubmissionError : ''
            }
            {...redirectFocusHandlers}
          />
        </div>
      </Grid>

      <Spacer height={20} />
    </>
  )
}

export default FormSettings
