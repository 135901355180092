import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './StickyBottomBar.module.css'

interface Props {
  withMargins?: boolean
  withHigherZIndex?: boolean
}
const StickyBottomBar: FC<PropsWithChildren<Props>> = ({
  withMargins,
  withHigherZIndex,
  children,
}) => {
  return (
    <div
      className={clsx(
        styles.Root,
        withMargins ? styles.WithMargins : '',
        withHigherZIndex ? styles.WithHigherZIndex : '',
      )}
    >
      {children}
    </div>
  )
}

export default StickyBottomBar
