const formatValue = (value: number | undefined) => {
  if (!value) {
    return value
  }
  return new Intl.NumberFormat('en-US').format(value)
}

const getScale = (value: number | undefined) => {
  let scale = 350
  const digits = value?.toString().length
  if (value && digits && digits > 8) {
    const extra = digits - 8
    scale += extra * (7 + extra * 2)
  }

  return scale
}

interface Props {
  value: number | undefined
}
const ScaleTextToFit = ({ value }: Props) => {
  const scaleWidth = getScale(value)
  const viewBox = `0 0 ${scaleWidth} 34`
  return (
    <svg viewBox={viewBox} style={{ width: '100%', maxWidth: '255px' }}>
      <text x="0" y="27">
        {formatValue(value)}
      </text>
    </svg>
  )
}

export default ScaleTextToFit
