import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from 'chart.js'
import useInsightsEvents from '@features/analytics/useInsightsEvents'

import Card from './Card'
import LineChart from './LineChart'
import QuestionMarksTooltip from './QuestionMarksTooltip'
import Spinner from '../../../components/Spinner'
import { LineChartState } from '../models/insights'

import styles from './Chart.module.css'

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title)

interface Props {
  loading: boolean
  label: string
  tooltipLabel: string
  chartTooltipLabel: string
  data: LineChartState[] | undefined
  onHoverTooltip?: () => void
}

const Chart = ({
  loading,
  label,
  tooltipLabel,
  chartTooltipLabel,
  data,
  onHoverTooltip,
}: Props) => {
  const { chartHovered } = useInsightsEvents()

  const handleOnMouseOut = () => chartHovered()

  return (
    <Card>
      <div className={styles.Root}>
        <div className={styles.Label}>
          {label}
          <QuestionMarksTooltip onHover={onHoverTooltip}>
            {tooltipLabel}
          </QuestionMarksTooltip>
        </div>
        <div className={styles.Content}>
          <Spinner spinning={loading}>
            <LineChart
              data={data || []}
              onMouseOut={handleOnMouseOut}
              tooltipLabel={chartTooltipLabel}
            />
          </Spinner>
        </div>
      </div>
    </Card>
  )
}

export default Chart
