import { randomContentId } from '@helpers/index'
import { putImage } from './index'

const useFirebaseImageUploader = () => {
  return async (file: File): Promise<string> => {
    try {
      const imageName = `${randomContentId()}.${file.name.split('.').pop()}`
      const imageURL = `${process.env.REACT_APP_IMAGE_BUCKET}/${imageName}`
      await putImage(file, imageName)
      return imageURL
    } catch (e) {
      return ''
    }
  }
}

export default useFirebaseImageUploader
