import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useActionStepper from '@helpers/useActionStepper'

import { FORMS_ACTION_IDS, FormsActions } from '../models'

type Props = {
  initialScreen: string
  isFormFieldsValid: boolean
  onSave: () => void
}
const useFormsActions = ({
  initialScreen = FORMS_ACTION_IDS.FIELDS,
  isFormFieldsValid,
  onSave,
}: Props) => {
  const { t } = useTranslation()

  const actions: FormsActions = [
    {
      id: FORMS_ACTION_IDS.FIELDS,
      isValid: true,
      title: t('Fields'),
      actionName: t('action_next'),
      onNext: () => nextStep(),
    },
    {
      id: FORMS_ACTION_IDS.SETTINGS,
      isValid: true,
      title: t('forms_form_settings'),
      actionName: t('action_done'),
      onNext: () => onSave(),
      onCancel: () => prevStep(),
    },
  ]

  const { currentAction, prevStep, nextStep } = useActionStepper(
    actions,
    initialScreen,
  )

  const isValid = useMemo(() => {
    if (currentAction.id === FORMS_ACTION_IDS.FIELDS) {
      return isFormFieldsValid
    }

    return true
  }, [currentAction, isFormFieldsValid])

  return { action: { ...currentAction, isValid } } as const
}

export default useFormsActions
