import { DocumentSnapshot } from 'firebase/firestore'
import { updatesActions } from '../../../actions'
import { AppDispatch } from '../../../app/store'
import { transformUpdateUnreadMessage } from '../transformers/UpdateUnreadMessageTransformer'

export const UpdatesUnreadMessageSnapshot =
  (uid: string, bid: string, dispatch: AppDispatch) =>
  (doc: DocumentSnapshot) => {
    const boardId = `${uid}-${bid}`
    dispatch(
      updatesActions.receivedReadUnreadMessages(
        boardId,
        transformUpdateUnreadMessage(doc),
      ),
    )
  }
