import { FC, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { ButtonGroupType } from '../models'

import styles from './ButtonGroup.module.css'

const ButtonGroupLabels = {
  [ButtonGroupType.GENERAL]: 'buttons_menu_general',
  [ButtonGroupType.CONTACT]: 'buttons_menu_contact',
  [ButtonGroupType.PAYMENT]: 'buttons_menu_payment',
}

interface Props {
  type: ButtonGroupType
}
const ButtonGroup: FC<PropsWithChildren<Props>> = ({ type, children }) => {
  return (
    <div className={styles.Root}>
      <h4 className={styles.Label}>
        <Trans i18nKey={ButtonGroupLabels[type]} />
      </h4>
      <div className={styles.Buttons}>{children}</div>
    </div>
  )
}

export default ButtonGroup
