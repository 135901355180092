/* eslint no-console: ["error", { allow: ["warn"] }] */
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'
import {
  rootFolderIndicator,
  rootFolderIndicatorSource,
} from '@helpers/NodeIdGenerator'
import { hash28 } from '@helpers/Base62'

import { Folder, NodeType } from '../../../app/models'
import { transformNodePage } from './NodePageTransformer'
import { transformNodeFolder } from './NodeFolderTransformer'

/** Deprecated: will be removed soon */
const getShortcutFallback = (data: DocumentData) => {
  const shortcutId = data.id.replace(
    rootFolderIndicator,
    rootFolderIndicatorSource,
  )

  if (data?.shortcuts && typeof data?.shortcuts[shortcutId] !== 'undefined') {
    return data?.shortcuts[shortcutId]
  }

  console.warn(`Unable to fetch shortcut for folder "${data.id}"`)

  return ''
}

export const transformFolder = async (
  boardId: string,
  doc: DocumentSnapshot,
) => {
  if (!doc.exists())
    return {
      id: '',
      icon: '',
      title: '',
      isPage: false,
      shortcut: '',
      content: [],
    } as Folder

  const data = doc.data()

  const shortcut = data.properties?.shortcut || getShortcutFallback(data)

  const isPage = data.properties?.type === NodeType.PAGE

  const content = await Promise.all(
    data.content.ids.map(async (id: string) => {
      const node = data.content.properties[id]
      const propertyId = hash28(node.text || '')

      const properties =
        data.urls?.properties && data.urls.properties[propertyId]

      return isPage
        ? transformNodePage(boardId, data.id, id, node, properties)
        : transformNodeFolder(id, node, properties)
    }),
  )

  return {
    id: data.id,
    icon: data.properties.icon,
    title: data.properties.title,
    unbranded: !!data.properties.unbranded,
    isPage,
    shortcut,
    content,
    themeId: data.properties.themeId,
  } as Folder
}
