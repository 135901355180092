export const enum ACTION_TYPE {
  SET_EDIT_LINK_PAGE_STEP = 'SET_EDIT_LINK_PAGE_STEP',
  SET_EDIT_LINK_LOADING = 'SET_EDIT_LINK_LOADING',
  SET_EDIT_LINK_TITLE = 'SET_EDIT_LINK_TITLE',
  RESET_EDIT_LINK_STATE = 'RESET_EDIT_LINK_STATE',
  SET_EDIT_LINK_ERROR = 'SET_EDIT_LINK_ERROR',
}

const editLinkError = (errorMessage: string | null) => ({
  type: ACTION_TYPE.SET_EDIT_LINK_ERROR,
  errorMessage,
})

const editLinkPageStep = (pageStep: number) => ({
  type: ACTION_TYPE.SET_EDIT_LINK_PAGE_STEP,
  pageStep,
})
const editLinkLoading = (loading: boolean) => ({
  type: ACTION_TYPE.SET_EDIT_LINK_LOADING,
  loading,
})

const editLinkTitle = (title: string) => ({
  type: ACTION_TYPE.SET_EDIT_LINK_TITLE,
  title,
})

const resetEditLinkState = () => ({
  type: ACTION_TYPE.RESET_EDIT_LINK_STATE,
})

const actions = {
  editLinkError,
  editLinkPageStep,
  editLinkLoading,
  editLinkTitle,
  resetEditLinkState,
} as const

export default actions
