import React from 'react'

import useBoard from '../../../../hooks/useBoard'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'

import Board from '../../board/Board'
import FolderToolBar from '../../toolbar/FolderToolBar'
import ScrollArea from '../../../../ui/components/Scroll/ScrollArea'

import { ScrollAreaContainer } from '../../../../ui/models/ScrollArea'

import styles from './Content.module.css'

const Content: React.FC = () => {
  const { boardId, isFolderEmpty, isRoot, isReadOnly, folder } = useBoard()
  const { insightsFeatureFlag } = useFeatureFlags()

  if (!folder) {
    return null
  }

  const contentClasses = [styles.Content]

  if (isReadOnly) {
    contentClasses.push(styles.HeightReadOnly)
  } else if (insightsFeatureFlag) {
    contentClasses.push(styles.Height)
  } else {
    contentClasses.push(styles.FullHeight)
  }

  return (
    <div className={styles.Root}>
      <FolderToolBar className={styles.Fixed} isPage={folder?.isPage} />
      <ScrollArea
        className={contentClasses.join(' ')}
        area={ScrollAreaContainer.FOLDER}
      >
        <Board
          boardId={boardId}
          isFolderEmpty={isFolderEmpty}
          folder={folder}
          isRoot={isRoot}
        />
      </ScrollArea>
    </div>
  )
}

export default Content
