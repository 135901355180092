import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { UserProfile } from '@models/UserProfile'
import { useSelectorFactory } from '@hooks/useSelectorFactory'

import { commonActions, profileInfoActions } from '../../../../actions'
import { selectProfileInformation } from '../../../../selectors'

export enum Feature {
  PAGES_HINT_SHOWN = 'pagesHintShown',
  FORMS_HINT_SHOWN = 'formsHintShown',
  VIDEO_HINT_SHOWN = 'videoHintShown',
  BUTTONS_HINT_SHOWN = 'buttonsHintShown',
  MONETIZATION_HINT_SHOWN = 'monetizationBannerShown',
  CHROME_EXTENSION_BANNER_SHOWN = 'chromeExtensionBannerShown',
  INSTALL_MOBILE_APP_HINT_SHOWN = 'installMobileAppHintShown',
  FREEMIUM_MESSAGES_HINT_SHOW = 'freemiumMessagesHintShown',
  PAGES_BOARD_HIDDEN_FROM_CHROME = 'pagesBoardHiddenFromChrome',
  CHECKOUT_HINT_SHOWN = 'checkoutHintShown',
  REVERSE_TRIAL_END_SHOW = 'showEndOfReverseTrialScreen',
}

type UseProfileInformationState = {
  profile: UserProfile
  loaded: boolean
  isGoogleAccountConnected: boolean
  isPayPalAccountConnected: boolean
  isSubscribed: boolean
  update: (info: UserProfile) => void
  hidePageHint: () => void
  hideFormsHint: () => void
  hideVideoHint: () => void
  hideButtonsHint: () => void
  hideCheckoutHint: () => void
  hideMonetizationBanner: () => void
  hideChromeExtensionBanner: () => void
  hideInstallMobileAppHintShown: () => void
  hideMessagesTooltip: () => void
  hideEndOfReverseTrialScreen: () => void
  hidePagesBoardFromChrome: () => void
  showPagesBoardFromChrome: () => void
}

export const useProfileInformation = (): UseProfileInformationState => {
  const dispatch = useDispatch()

  const { loaded, ...profile } = useSelectorFactory(selectProfileInformation)

  const isSubscribed = !(
    profile?.plan?.activations?.length > 0 &&
    profile.plan.activations[0].plan === 'free-plan'
  )

  const update = useCallback(
    (info: UserProfile) =>
      dispatch(profileInfoActions.updateProfileInformation(info)),
    [dispatch],
  )

  const hideHint = (feature: Feature, value = true) => {
    if (!profile.uiState[feature]) {
      dispatch(commonActions.setUiState(feature, true))
    }
    if (profile.uiState[Feature.PAGES_BOARD_HIDDEN_FROM_CHROME]) {
      dispatch(commonActions.setUiState(feature, value))
    }
  }

  const setUIStateExplicitly = (feature: Feature, value: boolean) => {
    dispatch(commonActions.setUiState(feature, value))
  }

  const isGoogleAccountConnected =
    profile.googleAuthorization?.status === 'authorized'

  const isPayPalAccountConnected =
    profile?.payPalAuthorization?.status === 'authorized'

  return {
    profile,
    loaded,
    isGoogleAccountConnected,
    isPayPalAccountConnected,
    isSubscribed,

    update,
    hidePageHint: () => hideHint(Feature.PAGES_HINT_SHOWN),
    hideFormsHint: () => hideHint(Feature.FORMS_HINT_SHOWN),
    hideVideoHint: () => hideHint(Feature.VIDEO_HINT_SHOWN),
    hideCheckoutHint: () => hideHint(Feature.CHECKOUT_HINT_SHOWN),
    hideButtonsHint: () => hideHint(Feature.BUTTONS_HINT_SHOWN),
    hideMessagesTooltip: () => hideHint(Feature.FREEMIUM_MESSAGES_HINT_SHOW),
    hideMonetizationBanner: () => hideHint(Feature.MONETIZATION_HINT_SHOWN),
    hideChromeExtensionBanner: () =>
      hideHint(Feature.CHROME_EXTENSION_BANNER_SHOWN),
    hideInstallMobileAppHintShown: () =>
      hideHint(Feature.INSTALL_MOBILE_APP_HINT_SHOWN),
    hidePagesBoardFromChrome: () =>
      hideHint(Feature.PAGES_BOARD_HIDDEN_FROM_CHROME),
    hideEndOfReverseTrialScreen: () =>
      setUIStateExplicitly(Feature.REVERSE_TRIAL_END_SHOW, false),
    showPagesBoardFromChrome: () =>
      hideHint(Feature.PAGES_BOARD_HIDDEN_FROM_CHROME, false),
  } as const
}
