import { useCallback } from 'react'
import { EventCategories } from './models/logEvents'
import { useLogEvents } from './useLogEvents'

const usePhoneVerificationEvents = () => {
  const { trigger } = useLogEvents()

  const verificationScreenViewEvent = useCallback(
    () => trigger(EventCategories.APP_PHONE_VERIFICATION_SCREEN_VIEW, {}),
    [trigger],
  )
  const verificationCountryClickEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_COUNTRY_CLICK, {})
  const verificationSendClickEvent = (countryCode?: string) =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_SEND_CLICK, {
      country_code: countryCode,
    })
  const verificationErrorEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_ERROR, {})
  const verificationSkipClickEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_SKIP_CLICK, {})

  const verificationCodeScreenViewEvent = useCallback(
    () => trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_SCREEN_VIEW, {}),
    [trigger],
  )
  const verificationCodeBackClickEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_BACK_CLICK, {})
  const verificationCodeSubmitedEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_SUBMITED, {
      type: 'manually',
    })
  const verificationCodeResendClickEvent = (tries: number) =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_RESEND_CLICK, {
      tries,
    })
  const verificationCodeSkipClickEvent = (mandatoryFlow: boolean) =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_SKIP_CLICK, {
      flow: mandatoryFlow ? 'mandatory' : 'optional',
    })
  const verificationCodeResendSkipViewEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_RESEND_SKIP_VIEW, {})
  const verificationCodeResendSkipClickEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_RESEND_SKIP_CLICK, {})
  const verificationCodeErrorEvent = () =>
    trigger(EventCategories.APP_PHONE_VERIFICATION_CODE_ERROR, {})
  const verificationCodeVerifiedSuccessfullyEvent = () =>
    trigger(
      EventCategories.APP_PHONE_VERIFICATION_PHONE_VERIFIED_SUCCESSFULLY,
      {},
    )

  return {
    verificationScreenViewEvent,
    verificationCountryClickEvent,
    verificationSendClickEvent,
    verificationErrorEvent,
    verificationSkipClickEvent,
    verificationCodeScreenViewEvent,
    verificationCodeBackClickEvent,
    verificationCodeSubmitedEvent,
    verificationCodeResendClickEvent,
    verificationCodeSkipClickEvent,
    verificationCodeResendSkipViewEvent,
    verificationCodeResendSkipClickEvent,
    verificationCodeErrorEvent,
    verificationCodeVerifiedSuccessfullyEvent,
  }
}

export default usePhoneVerificationEvents
