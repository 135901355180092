import { LazyImage, PdfOldIcon, Thumbnail } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import config from '../../config'

import styles from './NodePdf.module.css'

interface Props {
  title: string
  thumbnail?: string
}
/** @deprecated Prepare migration instead for "Node" component in "boards-web-ui" */
const NodePdf = ({ title, thumbnail }: Props) => {
  const titleHasOneLine =
    (title && title.length) <= config.titleBreakLineThreshold

  const nodeClasses = [styles.Root]
  if (titleHasOneLine) {
    nodeClasses.push(styles.OneLine)
  }

  return (
    <div className={nodeClasses.join(' ')}>
      <div className={styles.ImageWrapper}>
        <Thumbnail>
          <LazyImage
            spinnerStyleProps={{ size: '61px' }}
            src={thumbnail || ''}
            alt={title}
          />
        </Thumbnail>
      </div>
      <div className={styles.Content}>
        <IconWrapper>
          <PdfOldIcon className={styles.PdfIcon} width={21} height={24} />
        </IconWrapper>
        <span className={styles.Title}>{title}</span>
      </div>
    </div>
  )
}

export default NodePdf
