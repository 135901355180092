import { createContext } from 'react'
import { IListeners } from '../../app/models/listeners'

export default createContext<IListeners>({
  // eslint-disable-next-line
  startClientConfigListener: () => {},
  // eslint-disable-next-line
  startBoardFoldersListener: (uid, boardId) => {},
  // eslint-disable-next-line
  startBoardPermissionsListener: (uid, boardId) => {},
  // eslint-disable-next-line
  startBoardUpdatesMessagesListener: (uid: string, boardId: string) => {},
  // eslint-disable-next-line
  stopBoardUpdatesMessagesListener: (uid: string, boardId: string) => {},
})
