import { useTranslation } from 'react-i18next'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import VideoUsage from '../../../../ui/components/VideoUsage'

const useAddVideoStreamingDialogs = () => {
  const { t } = useTranslation()
  const confirmDialog = useConfirmDialog()

  const maxFileSizeDialog = () => {
    confirmDialog({
      title: t('file_too_big_dialog_title'),
      message: t('file_too_big_dialog_subtitle'),
      confirmText: t('action_ok'),
    })
  }

  const maxDurationDialog = () => {
    confirmDialog({
      title: t('video_too_long_dialog_title'),
      message: t('video_too_long_dialog_subtitle', { minutes: '10' }),
      confirmText: t('action_ok'),
    })
  }

  const limitReachedDialog = (usage: number, limit: string | number) => {
    confirmDialog({
      title: t('video_upload_limit_reached'),
      message: (
        <div style={{ width: '280px' }}>
          <VideoUsage limit={limit} used={usage} />
        </div>
      ),
      confirmText: t('action_ok'),
    })
  }

  const cancelUploadDialog = (onConfirm: () => void) => {
    confirmDialog({
      title: t('cancel_upload_dialog_title'),
      cancelText: t('action_no'),
      confirmText: t('action_yes'),
      onConfirm,
    })
  }

  return {
    maxFileSizeDialog,
    maxDurationDialog,
    limitReachedDialog,
    cancelUploadDialog,
  }
}

export default useAddVideoStreamingDialogs
