import React, { FC } from 'react'
import {
  Banner,
  ButtonNew,
  Icon,
  LogoIcon,
  NextIcon,
  PremiumCrownIcon,
} from 'boards-web-ui'

import { IconWrapper } from '@ui/components/IconWrapper'
import clsx from 'clsx'
import styles from './PreviewPageBanner.module.css'

type Props = {
  unbrandedPages?: boolean
  onDelete?: () => void
}

const PreviewPageBanner: FC<Props> = ({ unbrandedPages, onDelete }) => {
  return (
    <Banner
      absolute
      position="bottom"
      className={clsx(styles.Banner, unbrandedPages && styles.UnBranded)}
    >
      <p className={styles.MessageWrap}>
        <Icon size="64px" className={styles.Logo}>
          <LogoIcon className={styles.LogoIcon} />
        </Icon>

        <span className={styles.Message}>
          Easily create a page like this 🚀
        </span>
      </p>

      <ButtonNew href="/" target="_blank">
        <span>Start now</span>

        <IconWrapper className={styles.Icon}>
          <NextIcon />
        </IconWrapper>
      </ButtonNew>

      {!unbrandedPages ? (
        <button type="button" className={styles.Hover} onClick={onDelete}>
          <IconWrapper color={'white'} className={styles.KingIcon}>
            <PremiumCrownIcon />
          </IconWrapper>
          Upgrade to remove this banner
        </button>
      ) : null}
    </Banner>
  )
}

export default React.memo(PreviewPageBanner)
