interface ISubscriptions {
  [key: string]: () => void
}

export interface IReturnFunction<ValueType> {
  (): ValueType
}

const subscriptions: ISubscriptions = {}
export const createSubscription = () => {
  const addListener = (
    key: string,
    listener: IReturnFunction<IReturnFunction<void>>,
  ) => {
    if (!isListenerRunning(key)) {
      subscriptions[key] = listener()
    }
  }

  const isListenerRunning = (key: string) => {
    return Object.prototype.hasOwnProperty.call(subscriptions, key)
  }

  const stopListener = (key: string) => {
    const unsubscribe = subscriptions[key]
    delete subscriptions[key]
    if (unsubscribe) {
      unsubscribe()
    }
  }

  const stopAllListeners = () => {
    Object.keys(subscriptions).forEach((key) => {
      const unsubscribe = subscriptions[key]
      delete subscriptions[key]
      unsubscribe()
    })
  }

  return { addListener, stopListener, stopAllListeners }
}
