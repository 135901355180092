import { FC } from 'react'

import Spinner from '../../../../components/Spinner'
import config from '../../config'
import withImage from '../../../../hoc/withImage'
import { Node } from '../../../../models'

import styles from './NodeImage.module.css'

interface Props {
  node: Node
  thumbnail: string
  isLoading: boolean
  isUploading: boolean
  onError: () => void
  onLoad: () => void
}

/** @deprecated Prepare migration instead for "Node" component in "boards-web-ui" */
const NodeImage: FC<Props> = ({
  node,
  thumbnail,
  isLoading,
  isUploading,
  onError,
  onLoad,
}) => {
  const { title } = node
  return (
    <div className={styles.Root}>
      <Spinner spinning={isUploading || isLoading}>
        <>
          {!isUploading && (
            <img
              className={styles.Image}
              src={thumbnail}
              alt={title}
              draggable="false"
              loading="lazy"
              width={config.columnWidth}
              height={config.columnWidth}
              onError={onError}
              onLoad={onLoad}
            />
          )}
          {!isUploading && !isLoading && title && (
            <div className={styles.Title}>
              <span>{title}</span>
            </div>
          )}
        </>
      </Spinner>
    </div>
  )
}

export default withImage(NodeImage)
