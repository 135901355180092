import React, { PropsWithChildren } from 'react'

import { TooltipPositions } from '../../../../ui/models/tooltip'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'

import { useProfileTranslations } from '../hooks/useProfileTranslations'

type Props = PropsWithChildren<{
  isOpen?: boolean
  disabled?: boolean
}>
export const ProfileTooltip: React.FC<Props> = ({
  children,
  isOpen,
  disabled,
}) => {
  const { accountHoverTooltip } = useProfileTranslations()

  return (
    <Tooltip
      as="div"
      isOpen={isOpen}
      disabled={disabled}
      position={TooltipPositions.BOTTOM_LEFT}
      data={<TooltipContent>{accountHoverTooltip()}</TooltipContent>}
    >
      {children}
    </Tooltip>
  )
}
