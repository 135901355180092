import { useMemo, useRef } from 'react'
import ContainerReferenceContext, {
  ContainerReference,
} from './ContainerReferenceContext'

const ContainerReferenceProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>()

  const getPosition = () => {
    if (contentRef.current) {
      const { top, left } = contentRef.current.getBoundingClientRect()

      return { top, left }
    }

    return { top: 0, left: 0 }
  }

  const value = useMemo(
    () =>
      ({
        contentRef,
        getPosition,
      }) as ContainerReference,
    [contentRef],
  )

  return (
    <ContainerReferenceContext.Provider value={value}>
      {children}
    </ContainerReferenceContext.Provider>
  )
}

export default ContainerReferenceProvider
