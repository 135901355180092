import { useTranslation } from 'react-i18next'
import { useDialog } from 'boards-web-ui'

import EditHeadlineOrParagraph from '../components/EditHeadlineOrParagraph'
import { IUseAddNode } from '../models/IUseAddNode'

const useAddParagraph: IUseAddNode = (save) => {
  const { open } = useDialog()
  const { t } = useTranslation()

  const addParagraph = () => {
    open(
      <EditHeadlineOrParagraph
        initialText={''}
        placeholder={t('page_text_paragraph')}
        onSave={save}
        paragraph
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return addParagraph
}

export default useAddParagraph
