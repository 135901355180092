import ContentItem from './ContentItem'
import ContentItemCover, { CoverBackground } from './ContentItemCover'

interface Props {
  src: string
  text: string
  count: number
}

const ContentLink = ({ src, text, count }: Props) => {
  const textElement = text || src

  const url = `https://s2.googleusercontent.com/s2/favicons?sz=25&domain_url=${src}`

  return (
    <ContentItem text={textElement} count={count}>
      <ContentItemCover
        background={CoverBackground.WHITE}
        icon={<img src={url} alt={text} loading="lazy" />}
        alt={text}
      />
    </ContentItem>
  )
}

export default ContentLink
