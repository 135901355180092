import ContentItem from './ContentItem'
import { ReactComponent as AudioIcon } from '../icons/Audio.svg'

interface Props {
  title: string
  text: string
  count: number
}

const ContentAudio = ({ title, text, count }: Props) => {
  const hasTitle = !(title === undefined || title === '')
  const itemText = hasTitle ? title : text

  return (
    <ContentItem text={itemText} count={count} highlightText={hasTitle}>
      <AudioIcon />
    </ContentItem>
  )
}

export default ContentAudio
