export interface ISettings {
  nodePdfPlaceholder: string
  nodePdfErrorPlaceholder: string
  nodeImagePlaceholder: string
  nodeVimeoPlaceholder: string
  nodeYoutubePlaceholder: string
  nodePagePlaceholder: string
}

export default {
  nodePdfPlaceholder: '/placeholders/pdf.png',
  nodePdfErrorPlaceholder: '/placeholders/pdf-error.png',
  nodeImagePlaceholder: '/placeholders/image.png',
  nodeYoutubePlaceholder: '/placeholders/youtube.png',
  nodeVimeoPlaceholder: '/placeholders/vimeo.png',
  nodePagePlaceholder: '/placeholders/page.png',
} as ISettings
