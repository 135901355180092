import React, { FC, SyntheticEvent } from 'react'
import clsx from 'clsx'
import { Tooltip, TooltipContent, useDelayToggle } from 'boards-web-ui'

import { useLogEvents } from '@features/analytics/useLogEvents'

import { NodeSelectionProps, NodeSelectionVariants } from '../models/selection'

import NodeSelectionRadio from './NodeSelectionRadio'

import styles from './NodeSelection.module.css'

const variantClasses = {
  [NodeSelectionVariants.blue]: styles.BlueVariant,
  [NodeSelectionVariants.gray]: styles.GrayVariant,
  [NodeSelectionVariants.default]: styles.DefaultVariant,
}

const NodeSelection: FC<NodeSelectionProps> = ({
  isSelected,
  variant,
  withMargin,
  withOverlay,
  withDarkOverlay,
  withGrayGradient,
  opacity = '',
  onClick,
  onSelect,
  tooltipMessage,
  children,
}) => {
  const { trigger } = useLogEvents()
  const [tooltipState, setTooltipState] = useDelayToggle(false, 3000)

  const RadioClasses = [styles.Root]
  if (isSelected) RadioClasses.push(styles.Selected)
  if (withMargin) RadioClasses.push(styles.WithMargin)
  if (withOverlay) RadioClasses.push(styles.WithOverlay)
  if (withDarkOverlay) RadioClasses.push(styles.WithDarkOverlay)
  if (withGrayGradient) RadioClasses.push(styles.WithGrayGradient)
  if (!onClick) RadioClasses.push(styles.Disabled)

  RadioClasses.push(variantClasses[variant])

  const handleOnSelect = (e: SyntheticEvent) => {
    e.stopPropagation()
    if (onSelect) {
      onSelect()
    }
  }

  const handleClick = () => {
    if (tooltipMessage) {
      trigger('app_content_preview_blocked_toast')
      setTooltipState()
    }
    if (onClick) {
      onClick()
    }
  }

  return (
    <Tooltip
      disabled
      isOpen={tooltipState}
      data={<TooltipContent>{tooltipMessage}</TooltipContent>}
    >
      <div
        className={RadioClasses.join(' ')}
        onClick={handleClick}
        style={{ opacity }}
      >
        <div
          className={clsx(styles.Overlay)}
          onClick={(e) => {
            e.preventDefault()
          }}
        />
        {children}
        {onSelect &&
          [NodeSelectionVariants.blue, NodeSelectionVariants.gray].includes(
            variant,
          ) && (
            <NodeSelectionRadio
              className={styles.Radio}
              classNameUnselected={styles.RadioUnselected}
              classNameUnselectedHover={styles.RadioUnselectedHover}
              selected={isSelected}
              onClick={handleOnSelect}
            />
          )}
      </div>
    </Tooltip>
  )
}

export default React.memo(NodeSelection)
