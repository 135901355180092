import { ReactNode } from 'react'
import { useDialog } from 'boards-web-ui'

import ConfirmDialog from '../ui/components/ConfirmDialog'

interface ConfirmDialogProps {
  title: string
  message?: ReactNode | string
  cancelText?: string
  confirmText: string
  stacked?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  onEscape?: () => void
}

const useConfirmDialog = () => {
  const { open, close } = useDialog()

  const confirmDialog = ({
    title,
    message,
    cancelText,
    confirmText,
    stacked,
    onConfirm,
    onCancel,
    onEscape,
  }: ConfirmDialogProps) => {
    open(
      <ConfirmDialog
        title={title}
        message={message}
        cancelText={cancelText}
        confirmText={confirmText}
        stacked={stacked}
        onCancel={() => {
          close()
          if (onCancel) {
            onCancel()
          }
        }}
        onConfirm={() => {
          close()

          if (onConfirm) onConfirm()
        }}
      />,
      {
        onEscape,
        hideParents: false,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    )
  }

  return confirmDialog
}

export default useConfirmDialog
